import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentService } from "../../../services";

export const PerfectMoney = () => {
  const { paymentId } = useParams();

  const [params, setParams] = useState(null);
  const [isParamsLoaded, setIsParamsLoaded] = useState(false);

  const formBtn = useRef(null);

  useEffect(() => {
    PaymentService.perfectMoneyInfo(paymentId).then((req) => {
      if (req.data) {
        setParams(req.data);
        setIsParamsLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  // отправка данных для оплаты
  useEffect(() => {
    if (params && formBtn && isParamsLoaded) {
      formBtn.current.click();
    }
  }, [params, isParamsLoaded]);

  return (
    <>
      {isParamsLoaded && (
        <form
          id="pay"
          name="pay"
          method="POST"
          action={params.url}
          acceptCharset="UTF-8"
          style={{ opacity: 0, visibility: "hidden" }}
        >
          <p>
            <input type="hidden" name="NOPAYMENT_URL" value={params.noPayment_url} />
            <input type="hidden" name="NOPAYMENT_URL_METHOD" value={params.noPayment_url_method} />
            <input type="hidden" name="SUGGESTED_MEMO" value={params.suggestedMemo} />
            <input type="hidden" name="SUGGESTED_MEMO_NOCHANGE" value={params.suggestedMemoNoChange} />
            <input type="hidden" name="BAGGAGE_FIELDS" value={params.baggage_fields} />
            <input type="hidden" name="PAYMENT_ID" value={params.payment_id} />
            <input type="hidden" name="PAYMENT_SYSTEM_ID" value={params.payment_system_id} />
            <input type="hidden" name="PAYEE_ACCOUNT" value={params.payee_account} />
            <input type="hidden" name="PAYEE_NAME" value={params.payee_name} />
            <input type="hidden" name="PAYMENT_AMOUNT" value={params.payment_amount} />
            <input type="hidden" name="PAYMENT_UNITS" value={params.payment_units} />
            <input type="hidden" name="PAYMENT_URL" value={params.payment_url} />
            <input type="hidden" name="PAYMENT_URL_METHOD" value={params.payment_url_method} />
            <input type="hidden" name="STATUS_URL" value={params.status_url} />
          </p>
          <p>
            <button ref={formBtn} type="submit" value="submit" />
          </p>
        </form>
      )}
    </>
  );
};
