/* eslint-disable react-hooks/exhaustive-deps */
import { cloneElement, useEffect, useState } from "react";
import { BalanceService } from "../../../services";

export const SelectWithBalance = (props) => {

  const { options, children } = props;

  const hasBalance = !!options.find((opt) => opt.code === "balance");

  const [balanceValue, setBalanceValue] = useState(null);

  const renderChildSelect = () => cloneElement(children, {
    ...props,
    options: options.map((el) => (el.code === "balance" && balanceValue) ? ({ ...el, label: `${el.label}: ${balanceValue}$` }) : el)
  });

  useEffect(() => {
    if (hasBalance && !balanceValue) {
      BalanceService.getBalance().then((res) => {
        setBalanceValue(res.data.balance);
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
      });
    }
  }, [hasBalance]);

  return <>{ renderChildSelect() }</>;

};
