export const styles = {
  container: {},
  title: {
    marginBottom: "1.6rem",
    fontWeight: "400",
    lineHeight: "1.6rem",
    fontSize: "1.4rem",
    color: "rgba(var(--clr-text-100-rgb), 75%)"
  },
  list: {},
  item: {
    display: "flex",
    marginBottom: "2.6rem",
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "1.6rem",
    color: "rgba(var(--clr-text-100-rgb), 90%)"
  },
  link: {
    position: "relative",
    display: "inline-flex",

    "&:after": {
      content: `""`,
      position: 'absolute',
      left: '0',
      top: '100%',
      marginTop: '0.3rem',
      width: '0',
      height: '0.1rem',
      backgroundColor: 'rgba(var(--clr-default-100-rgb), 90%)',
      transition: 'width var(--transition)',
    },
    '&:hover::after': {
      width: '105%',
    }
  },
  linkWithIcon: {
    "&:after": {
      left: '4rem',
      right: 0
    },
    '&:hover::after': {
      width: "calc(105% - 4rem)",
    }
  },
  icon: {
    marginRight: "2.2rem",
    width: "1.8rem",
    height: "1.8rem",

    "& > svg": {
      fill: "var(--clr-default-100)"
    }
  }
};
