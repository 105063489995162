import React from "react";
import { Box } from "@mui/material";
import { style } from "./console.style";

const Console = (props) => {
  return (
    <Box sx={style.wrapper}>
      {props.children}
    </Box>
  );
};

export const ConsoleLabel = (props) => {
  return (
    <Box sx={style.label}>
      {props.children}
    </Box>
  );
}
export const ConsoleRow = (props) => {
  return (
    <Box sx={style.row}>
      {props.children}
    </Box>
  );
}
export default Console;
