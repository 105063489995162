import * as yup from "yup";
import { IP_V4_FULL_REG, IP_V6_FULL_REG } from "../constants";

export const ApiDevelopersSchema = (t) =>
  yup.object().shape({
    apiKey: yup.string().required(t.rules.apiKey.required),
    accessIpAddresses: yup.string().test(
      'ipAddresses',
      t.rules.accessIpAddresses.invalid,
      function(item) {
        const arr = item.split(',').map(e => e.trim()).filter(Boolean)

        return (arr.every(e => e.match(IP_V4_FULL_REG) || e.match(IP_V6_FULL_REG)));
      }
    ).test("ipAddressesCount", t.rules.accessIpAddresses.maxCount, (item) => item.split(',').map(e => e.trim()).filter(Boolean).length <= 3)
  });
