import { appTheme } from "../../theme";

// TODO Need compare with scss
export const styles = {
  formItem: {
    "&.form-item": {
      ".form-item": {
        "&__inner": {
          height: "100%",

          "&:focus-within": {
            "& .form-item__field": {
              borderColor: "var(--clr-primary-400)"
            }
          }
        },
        "&__label": {
          fontWeight: "400",
          fontSize: "1.4rem",
          whiteSpace: "nowrap",
          color: "var(--clr-text-900)",

          "&_outer": {
            display: "inline-block",
            marginBottom: "0.8rem",
            color: "var(--clr-text-400)"
          }
        },
        "&__field": {
          position: "relative",
          overflow: "hidden",
          border: "0.1rem solid transparent",
          padding: "1.1rem 2rem",
          width: "100%",
          transition: "border-radius var(--transition), border-color var(--transition)",

          "&:focus-within": {
            borderColor: "var(--clr-primary-400)"
          }
        },
        "&__input": {
          overflow: "hidden",
          outline: "none",
          border: "none",
          width: "100%",
          fontSize: "1.4rem",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          color: "var(--clr-text-900)",
          backgroundColor: "transparent",

          "&::-ms-reveal": {
            display: "none"
          },

          "&::placeholder": {

            color: "var(--clr-default-600)",
            // color: "var(--clr-text-900)"
          }
        },
        "&__post-word": {
          position: "absolute",
          left: "2rem",
          fontSize: "1.4rem",
          whiteSpace: "nowrap",
          color: "transparent",

          "& > span": {
            color: "var(--clr-default-900)"
          }
        },
        "&__actions": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",

          "& > svg": {
            padding: "0.4rem",
            width: "2.4rem",
            height: "2.4rem",
            objectFit: "contain",
            cursor: "pointer"
          }
        },
        "&__error": {
          marginTop: "1rem",
          fontWeight: "400",
          fontSize: "1.4rem",
          color: "red"
        }
      },
      "&_error": {
        "& .form-item__field": {
          borderColor: "red !important"
        }
      },
      "&_direction": {
        "&_horizontal": {
          "& .form-item": {
            "&__field": {
              display: "flex",
              alignItems: "center",
              borderRadius: "3rem",
              minHeight: "4.8rem",

              [appTheme.breakpoints.down("bp576")]: {
                minHeight: "4.8rem"
              }
            },

            "&__input": {
              fontWeight: "400"
            },

            "&__post-word": {
              fontWeight: "400"
            },

            "&__actions": {
              paddingLeft: "1rem"
            }
          }
        },
        "&_vertical": {
          "& .form-item": {
            "&__label": {
              "&_inner": {
                marginBottom: "1.2rem",
                color: "var(--clr-text-300)",

                [appTheme.breakpoints.down("bp576")]: {
                  marginBottom: "0.8rem"
                }
              }
            },

            "&__field": {
              display: "flex",
              flexDirection: "column",
              borderRadius: "2.2rem",
              minHeight: "7.4rem",

              [appTheme.breakpoints.down("bp576")]: {
                minHeight: "6.6rem"
              }
            },

            "&__input": {
              fontWeight: "600",

              [appTheme.breakpoints.down("bp576")]: {
                fontWeight: "500"
              }
            },

            "&__post-word": {
              top: "3.8rem",
              fontWeight: "600",
              fontSize: "1.4rem",

              [appTheme.breakpoints.down("bp576")]: {
                top: "3.4rem",
                fontWeight: "500",
                fontSize: "1.4rem"
              }
            }
          }
        }
      },
      "&_variant": {
        "&_outlined": {
          "& .form-item": {
            "&__field": {
              borderColor: "var(--clr-ui-border-200)",
              backgroundColor: "var(--clr-default-100)",
              // disable autofill styles
              "& input:-webkit-autofill, & input:-webkit-autofill:focus, & input:-webkit-autofill:hover": {
                "WebkitBoxShadow": "0 0 0px 1000px var(--clr-default-100) inset"
              }
            }
          }
        },
        "&_filled": {
          "& .form-item": {
            "&__field": {
              boxShadow: "inset 0 0 2rem rgba(197 202 213 / 20%)",
              backgroundColor: "var(--clr-default-200)",
              transition: "background-color var(--transition)",
              // disable autofill styles
              "& input:-webkit-autofill": {
                "WebkitBoxShadow": "0 0 0px 1000px var(--clr-default-200) inset",
                transition: "var(--transition)"
              },

              "@media (hover)": {
                " &:hover": {
                  backgroundColor: "#ebeff8",
                  transition: "background-color var(--transition)",
                  "& input:-webkit-autofill": {
                    "WebkitBoxShadow": "0 0 0px 1000px #ebeff8 inset"
                  }
                }
              }
            }
          }
        },
        "&_table": {
          "& .form-item": {
            "&__label": {
              color: "var(--clr-text-100)"
            },

            "&__field": {
              padding: 0
            },

            "&__input": {
              "&::placeholder": {
                color: "var(--clr-text-100)"
              }
            }
          }
        }
      }
    },
    "&.form-textarea": {
      "& .form-item__input": {
        height: "100%",
        whiteSpace: "revert",
        resize: "none",
        padding: "0 2rem",
        margin: "0 -2rem",
        width: "calc(100% + 4rem)",
        overflow: "auto",
        cursor: "auto",
        // marginBottom: "1rem",

        "&::-webkit-scrollbar": {
          borderRadius: "1rem",
          overflow: "hidden"

        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent"
        }
      },
      "& .form-item__field": {
        alignItems: "flex-start",
        height: "100%"
      }
    }
  }
};
