import { fluid } from '../../../assets/styles/functions/fluid'

export const style = {
  title: {
    marginBottom: '2rem',
    fontWeight: '500',
    color: 'var(--clr-text-900)',
  },

  content: {
    fontWeight: '400',
    fontSize: fluid(1.6, 1.4),
    color: 'var(--clr-text-400)',
  }
}