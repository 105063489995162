import { createSlice } from "@reduxjs/toolkit";

import { fetchFingerPrintOpensourse } from "./fingerprint.thunks";

const initialState = {
  data: {
    visitorId: "",
    confidence: {
      score: 0
    }
  },
  fetchError: null
};

const FingerprintSlice = createSlice({
  name: "fingerprint",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFingerPrintOpensourse.pending]: (state) => {
      state.fetchError = null;
    },
    [fetchFingerPrintOpensourse.fulfilled]: (state, action) => {
      state.data.visitorId = action.payload.visitorId;
      state.data.confidence.score = action.payload.confidence.score;
    },
    [fetchFingerPrintOpensourse.rejected]: (state, action) => {
      state.fetchError = action.payload;
    },
  }
});

export const FingerprintActions = { ...FingerprintSlice.actions, fetchFingerPrintOpensourse };

export default FingerprintSlice.reducer;
