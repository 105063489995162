import { $api } from "./axios.service";
import { setCaptchaTokenToRequestHeaders } from "../utils/helpers";

export class BalanceService {
  static addBalance(creds, cfToken) {
    return $api.post(
      "/api/front/cabinet/balance/replenishment",
      creds,
      { headers: setCaptchaTokenToRequestHeaders(cfToken) }
    );
  }

  static getBalance() {
    return $api.get("/api/front/balance");
  }

  static getHistory(creds = {page: 0, size: 10}) {
  // {
  //   page: number,
  //   size: number
  // }
    return $api.post("/api/front/cabinet/balance/transaction/history", creds);
  }

  static repay(paymentId) {
    return $api.get(`/api/front/cabinet/payment/repay`, { params: { paymentId } })
  }
}
