import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import useCurrentSeo from "../../../hooks/use-current-seo";
import { Container } from "../../common/container/container";
import HeadingWithSeo from "../../common/heading-with-seo/heading-with-seo";
import TrustpilotWidget from "../../common/widget/trustpilot-widget/trustpilot-widget";

import HeroDecor from "./_hero-decor";
import HeroPanel from "./hero-panel";
import { style } from "./hero.style";

export const Hero = (props) => {
  const { countryOrTypeName } = props;
  const seo = useCurrentSeo();

  const { t } = useTranslation();

  const hadingText = `${t("pages.app.buyProxy.hero.heading")} <span>${countryOrTypeName}</span>`;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Box sx={style.proxyHero} className="proxy-hero">
      <Container>
        <Box className="widgetBox" sx={style.widgetBox}>
          {isClient && <TrustpilotWidget />}
        </Box>

        <Box sx={style.wrapper} className="proxy-hero__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h1">
            {hadingText}
          </HeadingWithSeo>
          {seo["h2"] ? (
            <Box sx={style.caption} className="proxy-hero__caption">
              {seo["h2"]
                ? seo["h2"]
                : `${t("pages.app.buyProxy.hero.caption")} ${countryOrTypeName}`}
            </Box>
          ) : null}
          <Box sx={style.description} className="proxy-hero__description">
            {seo["h2Text"]
              ? seo["h2Text"]
              : t("pages.app.buyProxy.hero.description").replace(
                  "{{countryName}}",
                  countryOrTypeName
                )}
          </Box>
          <HeroPanel />
          <Box sx={style.decor} className="proxy-hero__decor">
            <HeroDecor />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
