import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import ProductMetaDate from "../../../../components/common/product-meta-date/product-meta-date";
import { useLang } from "../../../../hooks";
import useCurrentSeo from "../../../../hooks/use-current-seo";
import { getMobileState } from "../../../../store/mobile/mobile.selectors";
import { ROUTE } from "../../../../utils/constants";

const MobileProductMeta = () => {
  const [searchParams] = useSearchParams();
  const searchCountry = searchParams.get("country");

  const { t } = useTranslation();
  const lang = useLang();
  const seo = useCurrentSeo();

  const name = seo["h1"] ? seo["h1"] : `${t("pages.app.mobileProxy.hero.heading")}`;
  const description = seo["h1Text"] ? seo["h1Text"] : t("pages.app.mobileProxy.hero.description");

  const { countries } = useSelector(getMobileState);

  const country = countries?.find((country) => country.urlParam === searchCountry) ?? countries[0];
  const rentPeriod = country?.mobileOperators?.[0]?.pricesByRentPeriods?.[0];
  const countryName = country?.namesDependingOnLocale[lang] ?? "";

  return (
    <>
      <ProductMetaDate
        name={`${name} - ${countryName}`}
        description={description}
        url={`/${lang}${ROUTE.APP.MOBILE}/?country=${country?.urlParam ?? ""}`}
        price={rentPeriod?.value}
        // currency={country?.priceCurrencyCode}
      />
    </>
  );
};

export default MobileProductMeta;
