import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  cabinetNewOrder: {
    '.form-checkbox': {
      [appTheme.breakpoints.down('bp576')]: {
        justifyContent: 'center'
      }
    }
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3.5rem',

    '.tabs__header': {
      backgroundColor: 'var(--clr-default-100)',
    },

    [appTheme.breakpoints.down('bp767')]: {
      flexDirection: 'column',
      marginBottom: '3rem',

      '& > .heading': {
        marginBottom: '1rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      alignItems: 'flex-start',

      '& > .heading': {
        marginBottom: '3rem',
      },

      '.tabs': {
        width: 'min(45rem, 100%)',

        '&__header': {
          display: 'flex',
        },

        '&__item': {
          flexGrow: '1',
        }
      }
    }
  },

  body: {
    display: 'grid',
    gridGap: '2.2rem 3rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(25rem, 1fr))',
    marginBottom: '2.5rem',

    [appTheme.breakpoints.down('bp1200')]: {
      gridGap: '2rem',
    },

    [appTheme.breakpoints.down('bp576')]: {
      gridGap: '1.7rem',
      gridTemplateColumns: '1fr',
      marginBottom: '3rem',
    }
  },

  price: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
    fontWeight: '700',
    fontSize: fluid(3.6, 3),
    color: 'var(--clr-text-600)',

    discount: {
      paddingLeft: '2rem',
      fontWeight: '400',
      fontSize: '1.4rem',
      color: 'var(--clr-text-400)',

      span: {
        fontWeight: '600',
        color: 'var(--clr-primary-400)',
      },

      [appTheme.breakpoints.down('bp576')]: {
        paddingLeft: '0',
      }
    },

    unit: {
      fontWeight: '400',
      fontSize: '1.4rem',
      color: 'var(--clr-text-900)',

      span: {
        fontSize: fluid(1.6, 1.4),
      },

      [appTheme.breakpoints.down('bp576')]: {
        marginTop: '2rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '0',
    }
  },

  promocode: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    paddingLeft: '10px',
    textDecoration: "underline",
    fontWeight: 400,
    cursor: "pointer",
    marginTop: "2.2rem"
  },

  divider: {
    marginTop: '3rem',
    marginBottom: '4rem',
    height: '0.1rem',
    backgroundColor: 'var(--clr-default-500)',

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '2rem',
    }
  },

  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    info: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      paddingLeft: '2rem',

      [appTheme.breakpoints.down('bp576')]: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0',
      }
    },

    '.button': {
      maxWidth: '25rem',
      marginRight: '20px',
      [appTheme.breakpoints.down('bp576')]: {
        order: '1',
        marginTop: '3rem',
        maxWidth: '100%',
        marginRight: '0'
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      flexDirection: 'column',
      alignItems: 'center',
    }
  }
}
