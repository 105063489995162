import React, { useEffect, useState } from "react";

import { Box, Fade } from "@mui/material";
import { useRive } from "@rive-app/react-canvas";
import { useTranslation } from "react-i18next";

import anim from "../../../assets/anims/proxy-page-hero.riv";
import { useSeoBlock } from "../../../hooks";
import { SeoBlockField } from "../../common";

import { style } from "./hero.style";

const HeroDecor = () => {
  const { t } = useTranslation();
  const { data } = useSeoBlock(["proxyPageHeroDecorLabels"]);

  const { rive, RiveComponent } = useRive({ src: anim, autoplay: true });

  const [showDecorLabels, setShowDecorLabels] = useState(false);

  useEffect(() => {
    if (rive) {
      setShowDecorLabels(true);
    }
  }, [rive]);

  return (
    <Box sx={style.decorAnimation.container}>
      <RiveComponent />
      <Fade in={showDecorLabels}>
        <Box id="seoBlock-proxyPageHeroDecorLabels" sx={style.decorAnimation.labelContainer}>
          <Box
            sx={[
              style.decorAnimation.label,
              {
                top: "280px",
                left: "116px"
              }
            ]}
          >
            <SeoBlockField
              defaultValue={t("pages.app.buyProxy.hero.animationLabels.noLimits")}
              field={data?.proxyPageHeroDecorLabels?.noLimits}
            />
          </Box>
          <Box
            sx={[
              style.decorAnimation.label,
              {
                top: "190px",
                left: "260px"
              }
            ]}
          >
            <SeoBlockField
              defaultValue={t("pages.app.buyProxy.hero.animationLabels.support")}
              field={data?.proxyPageHeroDecorLabels?.support}
            />
          </Box>
          <Box
            sx={[
              style.decorAnimation.label,
              {
                top: "290px",
                left: "385px"
              }
            ]}
          >
            <SeoBlockField
              defaultValue={t("pages.app.buyProxy.hero.animationLabels.connection")}
              field={data?.proxyPageHeroDecorLabels?.connection}
            />
          </Box>
          <Box
            sx={[
              style.decorAnimation.label,
              {
                top: "198px",
                left: "476px"
              }
            ]}
          >
            <SeoBlockField
              defaultValue={t("pages.app.buyProxy.hero.animationLabels.anonymity")}
              field={data?.proxyPageHeroDecorLabels?.anonymity}
            />
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};

export default HeroDecor;
