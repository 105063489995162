import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../services";

export const getCountriesPricesByProxyType = createAsyncThunk(
  "seo/getCountriesPricesByProxyType",
  async (proxyType, { rejectWithValue, getState }) => {
    try {
      const { proxy } = getState();
      const proxyTypeId = proxy.proxies[proxyType]?.proxyTypeId;

      if (!proxyTypeId) {
        throw new Error("Proxy type ID is empty");
      }

      const response = await ApiService.getCountriesPricesByProxyTypeId(proxyTypeId);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
