import { Box, ButtonBase } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { CopyToClipboardIcon } from "../../../components/icons";
import { Button } from "../../../components/ui/button/button";
import { CopyToClipboard } from "../../../components/ui/copy-to-clipboard/copy-to-clipboard";
import { Heading } from "../../../components/ui/heading/heading";
import { Table } from "../../../components/ui/table/table";
import { useSetSeoPage } from "../../../hooks";
import { BalanceService } from "../../../services";
import { HistoryService } from "../../../services/history.service";
import { getCurrencySign } from "../../../utils/helpers/currency.helper";
import { orderProcessing } from "../../../utils/helpers/orderProcessing.helpers";
import { useTableData } from "../../../utils/hooks/useTableData";
import { useTableNavigation } from "../../../utils/hooks/useTableNavigation";

import { style } from "./paymentHistory.style";

const PaymentHistory = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  //state

  //custom hooks
  const [pageObj, pageChangeHandler, sizeSelectHandler] = useTableNavigation();
  const [tableData, tableLoading] = useTableData({
    proxyType: "IPv4",
    pageObj,
    asyncFunction: HistoryService.getPaymentHistory
  });

  const isTableDisabled = !tableData?.content?.length;

  const defineOrderStatus = (status) => {
    if (status) return t("cabinet.paymentHistory.success");
    else return t("cabinet.paymentHistory.notSuccess");
  };

  const repayHandler = (id) => {
    // eslint-disable-next-line no-console
    console.log("repay!");
    BalanceService.repay(id)
      .then((res) => {
        if (res.data) orderProcessing(res.data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
        enqueueSnackbar(t("repayError"), { variant: "error" });
      });
  };

  return (
    <Box sx={style.cabinetPaymentHistory} className="cabinet-payment-history">
      <Heading tag="h1" variant="h2">
        {t("pages.cabinet.paymentHistory.heading")}
      </Heading>

      <Table
        pagination={!!tableData?.totalElements}
        totalPages={tableData?.totalPages ? tableData?.totalPages - 1 : 0}
        totalElements={tableData?.totalElements || 0}
        elementsOnPage={tableData?.content?.length}
        currentPage={pageObj.page}
        onPageChange={pageChangeHandler}
        pageSize={pageObj.pageSize}
        outputCountHandler={sizeSelectHandler}
        loading={tableLoading}
        head={
          <Table.Head>
            {!isTableDisabled ? (
              <Table.Row>
                <Table.Cell width={"24rem"}>ID</Table.Cell>
                <Table.Cell width="17.5rem">
                  {t("pages.cabinet.paymentHistory.table.orderCompound")}
                </Table.Cell>
                <Table.Cell width="15rem">
                  {t("pages.cabinet.paymentHistory.table.amountCurrency")}
                </Table.Cell>
                <Table.Cell colSpan={2}>
                  {t("pages.cabinet.paymentHistory.table.status")}
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Cell></Table.Cell>
            )}
          </Table.Head>
        }
      >
        <Table.Body>
          {tableData && tableData?.content?.length ? (
            tableData.content.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell flex align={"center"} width={"24rem"}>
                  <CopyToClipboard position="left" text={row.paymentId}>
                    <ButtonBase style={style.copy} className="cabinet-balance__order-copy">
                      <CopyToClipboardIcon />
                    </ButtonBase>
                  </CopyToClipboard>
                  <Box component="span" sx={style.textOverflow} title={row.paymentId}>
                    {row.paymentId}
                  </Box>
                </Table.Cell>
                <Table.Cell width="17.5rem">
                  {t(`pages.cabinet.paymentHistory.paymentActions.${row.paymentAction}`)}
                </Table.Cell>
                <Table.Cell width="15rem">{`${getCurrencySign(row.currencyCode)}${
                  row.totalPrice
                }`}</Table.Cell>
                <Table.Cell>{defineOrderStatus(row.payed)}</Table.Cell>
                <Table.Cell width={165}>
                  <Box sx={style.table} className="cabinet-payment-history__table-action">
                    {!row.payed && (
                      <Box sx={style.fixedWidth}>
                        <Button size="small" onClick={() => repayHandler(row.paymentId)}>
                          {t("pages.cabinet.paymentHistory.table.buy")}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row customStyles={style.noData}>
              <Table.Cell>{t("cabinet.paymentHistory.noData")}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default PaymentHistory;
