
export const TelegramIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_249_10717)">
      <path d="M7.06289 11.3856L6.76514 15.5736C7.19114 15.5736 7.37564 15.3906 7.59689 15.1708L9.59414 13.2621L13.7326 16.2928C14.4916 16.7158 15.0264 16.4931 15.2311 15.5946L17.9476 2.86557L17.9484 2.86482C18.1891 1.74282 17.5426 1.30407 16.8031 1.57932L0.835634 7.69256C-0.254117 8.11556 -0.237617 8.72306 0.650384 8.99831L4.73264 10.2681L14.2149 4.33481C14.6611 4.03931 15.0669 4.20281 14.7331 4.49831L7.06289 11.3856Z" />
    </g>
    <defs>
      <clipPath id="clip0_249_10717">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);
