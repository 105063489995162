import { CF_TOKEN_FIELD_NAME, CF_TOKEN_HEADER_NAME } from "../constants";

export const getCaptchaTokenFromFormData = (data) =>  {
  if (!data?.hasOwnProperty(CF_TOKEN_FIELD_NAME)) {
    throw new Error('Captcha property do not exist in data');
  }

  return data[CF_TOKEN_FIELD_NAME]
};

export const setCaptchaTokenToRequestHeaders = (token, headers = {}) => {
  return {
    ...headers,
    [CF_TOKEN_HEADER_NAME]: token
  }
}
