import { useEffect } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container } from "../../../components/common/container/container";
import HeadingWithSeo from "../../../components/common/heading-with-seo/heading-with-seo";
import { Accordion } from "../../../components/ui/accordion/accordion";
import { FaqSkeleton } from "../../../components/ui/skeletons/faq-skeleton/faq-skeleton";
import { Tabs } from "../../../components/ui/tabs/tabs";
import { useActions, useLang, useSetSeoPage } from "../../../hooks";
import { getAllFaqByLocale } from "../../../store/faq/faq.thunks";
import { getFaqState } from "../../../store/selectors";

import { style } from "./faq.style";

const Faq = () => {
  useSetSeoPage();

  const lang = useLang();

  const { getAllFaqByLocale } = useActions();

  const { faqsFront, isLoading: loading } = useSelector(getFaqState);

  // Translation
  const { t } = useTranslation();

  const data = faqsFront[lang] ? Object.values(faqsFront[lang]).filter((e) => e.fags.length) : [];

  const faqOptions = data.map((e) => ({
    key: e.tag,
    label: e.namesByLocale[lang]
  }));

  useEffect(() => {
    if (!faqsFront[lang]) getAllFaqByLocale(lang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqsFront, lang]);

  return (
    <Box sx={style.faq} className="faq" itemScope itemType="https://schema.org/FAQPage">
      <Container>
        <HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">
          {t("shared.faq.heading")}
        </HeadingWithSeo>
        {
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <FaqSkeleton />
          ) : data.length ? (
            <>
              <Tabs tabs={faqOptions} direction="vertical" mobileViewBreakpoint={992}>
                {data.map((group) => (
                  <ul key={group.tag}>
                    {group.fags.map((faq, faqIdx) => (
                      <li
                        key={`${faqIdx}:${faq.question}`}
                        itemScope
                        itemProp="mainEntity"
                        itemType="https://schema.org/Question"
                      >
                        <Accordion defaultOpen={faqIdx === 0} label={faq.question}>
                          {faq.answer}
                        </Accordion>
                      </li>
                    ))}
                  </ul>
                ))}
              </Tabs>
            </>
          ) : (
            <Box sx={style.noData}>{t("shared.faq.noData")}</Box>
          )
        }
      </Container>
    </Box>
  );
};

Faq.getServerSideState = async (store, params) => {
  const lang = params.locale;
  const state = await store.getState();

  if (!state.faq.faqsFront[lang]) {
    await store.dispatch(getAllFaqByLocale(lang));
  }
};
export default Faq;
