const contentWidth = 120;

export const fluid = (maxValue, minValue, minVW = 32, maxVW = contentWidth) => {
  const factor = (1 / (maxVW - minVW)) * (maxValue - minValue);
  const calcValue = `${ minValue - ( minVW * factor ) }rem + ${ 100 * factor }vw`;

  // return `clamp(
  //   ${minValue > maxValue ? maxValue : minValue}rem,
  //   ${calcValue},
  //   ${minValue > maxValue ? minValue : maxValue}rem
  // )
  // `;

  return `min(max(${minValue > maxValue ? maxValue : minValue}rem, ${calcValue}), ${minValue > maxValue ? minValue : maxValue}rem)`
}