import { useEffect, useState } from "react";

import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import IpInputMask from "../../../components/common/ip-input-mask/ip-input-mask.js";
import { Form } from "../../../components/form/index.js";
import { Copy2Icon, DeleteIcon, PlusIcon } from "../../../components/icons/index.js";
import { Button } from "../../../components/ui/button/button.jsx";
import { Heading } from "../../../components/ui/heading/heading.jsx";
import { Modal } from "../../../components/ui/modal/modal.jsx";
import { ExtendService } from "../../../services/extend.service.js";
import { getActiveOrdersIpAddressCount } from "../../../store/selectors.js";
import { copyTextToClipboard } from "../../../utils/helpers/copyTextToClipboard.js";

import { style } from "./replace-authorization.style.js";
import { transformData, validateIp } from "./utils/index.js";

export default function AuthorizationModal({ openedModal, closeModal }) {
  const { t } = useTranslation();
  const { authorization } = t("pages.cabinet.orders.widgets");
  const methodsAuthorization = [`${t("other.login")}/${t("other.password")}`, authorization.ips];

  const [method, setMethod] = useState(methodsAuthorization[0] || "");
  const [objDataLogin, setObjDataLogin] = useState({});
  const [arrDataIps, setArrDataIps] = useState([]);
  const [loginAndPassword, setLoginAndPassword] = useState({ login: "", password: "" });
  const [inFocus, setInFocus] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const activeOrdersData = useSelector(getActiveOrdersIpAddressCount).data;
  const countActiveOrders = Object.values(activeOrdersData).reduce((acc, value) => acc + value, 0);

  const submitHandler = async () => {
    const updatedIps = arrDataIps.map((item) => {
      const { ip } = item;

      const isValid = validateIp(ip);
      return { ...item, error: isValid };
    });

    setArrDataIps(updatedIps);

    const hasError = updatedIps.some((item) => item.error === true);

    if (hasError) {
      enqueueSnackbar(t("shared.orderModal.ipValidationError"), {
        variant: "error"
      });
      return;
    }

    const req = transformData([objDataLogin, ...arrDataIps]);
    if (req.length === 0) {
      closeModal();
      return;
    }

    try {
      const res = await ExtendService.changeDataAuthentication(req);

      if (res.status === 200) {
        enqueueSnackbar(authorization.success, { variant: "success" });
        closeModal();
      } else {
        enqueueSnackbar(authorization.rejected, { variant: "error" });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
      enqueueSnackbar(authorization.rejected, { variant: "error" });
      closeModal();
    }
  };

  const fetchDataAuthentication = async () => {
    try {
      const { data } = await ExtendService.getDataAuthentication();
      const dataLogin = data.find(({ type }) => type.toLowerCase() === "login");
      setLoginAndPassword({ login: dataLogin?.login || "", password: dataLogin?.password || "" });
      setObjDataLogin(data.find(({ type }) => type.toLowerCase() === "login"));

      const ips = data.filter(({ type }) => type.toLowerCase() === "ip");
      setArrDataIps(ips);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchDataAuthentication();
  }, []);

  const handlerClickCopy = (value) => {
    copyTextToClipboard(value)
      .then(() => {
        enqueueSnackbar(t("copyMessageSuccess"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(t("copyMessageError"), { variant: "error" });
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const deleteIp = (indexToDelete) => {
    setArrDataIps((prevIps) => prevIps.filter((_, index) => index !== indexToDelete));
  };

  const addIp = () => {
    const newIp = {
      main: arrDataIps.length === 0,
      type: "IP",
      ip: ""
    };

    setArrDataIps((prevIps) => [prevIps[0], newIp, ...prevIps.slice(1)]);
  };

  const handleIpChange = (event, index) => {
    const updatedIps = [...arrDataIps];
    const ip = event.target.value;
    if (updatedIps.length === 0) {
      updatedIps.push({ ip, type: "IP", main: true });
    } else {
      updatedIps[index].ip = ip;
    }

    setArrDataIps(updatedIps);

    if (!validateIp(ip) && updatedIps[index]?.error) {
      updatedIps[index].error = false;
      setArrDataIps(updatedIps);
    }
  };

  return (
    <Modal
      customStyles={style.modalBlockAuthorization}
      visible={openedModal}
      cancelHandler={closeModal}
    >
      <Form>
        <Box sx={style.modal.upper}>
          <Heading tag="h4" variant="h4">
            {authorization.title}
          </Heading>

          <Box component="p" sx={style.modal.subTitle}>
            {authorization.descriptionIps}
          </Box>

          <RadioGroup
            sx={style.modal.methodsContainer}
            value={method}
            onChange={(e) => setMethod(e.target.value)}
          >
            {methodsAuthorization.map((el) => (
              <FormControlLabel
                key={el}
                value={el}
                control={<Radio sx={{ display: "none" }} />}
                label={
                  <Box sx={style.modal.radioGroup}>
                    <Box className={`radioIcon ${method === el ? "checked" : ""}`}>
                      {method === el && <Box className="innerCircle" />}
                    </Box>
                    <Box
                      component="span"
                      className={`radioLabel ${method === el ? "checked" : ""}`}
                    >
                      {el}
                    </Box>
                  </Box>
                }
              />
            ))}
          </RadioGroup>

          {methodsAuthorization[0] === method ? (
            Object.keys(loginAndPassword).map((key) => (
              <Box key={key}>
                <FormLabel sx={style.modal.titleBlock} id={key}>
                  {t(`other.${key}`)}
                </FormLabel>
                <Box sx={style.modal.inputContainer}>
                  <Form.Input
                    aria-labelledby={key}
                    readOnly={true}
                    labelType="outer"
                    variant="outlined"
                    value={loginAndPassword[key]}
                    actions={
                      <>
                        <Box
                          component={"button"}
                          type={"button"}
                          sx={style.actionButton}
                          onClick={() => handlerClickCopy(loginAndPassword[key])}
                        >
                          <Copy2Icon />
                        </Box>
                      </>
                    }
                  />
                </Box>
              </Box>
            ))
          ) : (
            <>
              <FormLabel sx={style.modal.titleBlock} id="mainIp">
                {authorization.yourIp}
              </FormLabel>
              <Box
                sx={[
                  style.modal.inputContainerIps,
                  style.modal.inputContainer,
                  arrDataIps.find((ip) => ip?.main)?.error && style.modal.errorInput
                ]}
              >
                <Form.Input
                  aria-labelledby="mainIp"
                  readOnly={false}
                  labelType="outer"
                  variant="outlined"
                  actions={
                    <>
                      <IpInputMask
                        autoComplete="off"
                        name="mainIp"
                        value={arrDataIps.find((ip) => ip?.main)?.ip || ""}
                        onChange={(e) =>
                          handleIpChange(
                            e,
                            arrDataIps.findIndex((ip) => ip.main)
                          )
                        }
                        useFocus={() => [inFocus, setInFocus]}
                      />
                    </>
                  }
                />
              </Box>

              <Button onClick={addIp} size="medium">
                <span style={style.modal.btnAddIPIcon}>
                  <PlusIcon color={"#193375"} />
                </span>
                {authorization.addIp}
              </Button>

              <Box sx={style.modal.boxAddedIps}>
                {arrDataIps.map((ip, index) => {
                  if (!ip.main) {
                    return (
                      <Box
                        key={index}
                        sx={[
                          style.modal.inputContainerIps,
                          style.modal.inputContainer,
                          ip.error && style.modal.errorInput
                        ]}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <Form.Input
                          aria-labelledby={`ip-${index}`}
                          readOnly={false}
                          labelType="outer"
                          variant="outlined"
                          actions={
                            <>
                              <IpInputMask
                                autoComplete="off"
                                name={`ip-${index}`}
                                value={ip.ip}
                                onChange={(e) => handleIpChange(e, index)}
                                useFocus={() => [inFocus, setInFocus]}
                              />
                              {hoveredIndex === index && (
                                <Box
                                  component={"button"}
                                  type={"button"}
                                  sx={style.actionButton}
                                  onClick={() => deleteIp(index)}
                                >
                                  <DeleteIcon />
                                </Box>
                              )}
                            </>
                          }
                        />
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </>
          )}
        </Box>
        <Box sx={style.modal.bottom}>
          <Button
            onClick={submitHandler}
            variant="primary"
            size="medium"
            disabled={countActiveOrders === 0}
          >
            {t("other.save")}
          </Button>
          <Button onClick={closeModal} size="medium">
            {t("other.cancel")}
          </Button>
        </Box>
      </Form>
    </Modal>
  );
}
