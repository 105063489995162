import { createSlice } from "@reduxjs/toolkit";

import { getPaymentOptions } from './payment.thunks';
/*
* if fingerprint == "" && email == "" -- show all PS
* if (fingerprint && email) == blocked -- show only PS with refound == false
* else -- show all PS
* */
const initialState = {
  loading: false,
  isDataLoaded: false,
  error: null,
  systems: {},
  systemsOptions: null
};

const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: {
    [getPaymentOptions.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
      state.isDataLoaded = true;
      const {email, fingerprint} = action.meta.arg;

      state.systems = action.payload;

      const options = action.payload;
      for (const key in options) {
        options[key] = options[key].map((system) => ({
          key: system.id,
          label: system.name,
          code: system.code,
          refund: system.refund,  // true == !showForUser || false == showForUser
          showForUser: !!fingerprint ? true : !system.refund
        }));

        // balance option will always appear last
        const balanceOption = options[key].find((opt) => opt.code === "balance");
        if (balanceOption) {
          options[key] = options[key].filter((opt) => opt.code !== "balance");
          options[key] = [...options[key], balanceOption];
        }
      }

      state.systemsOptions = options;
    },

    [getPaymentOptions.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
    },

    [getPaymentOptions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const PaymentActions = {
  ...PaymentSlice.actions,
  getPaymentOptions
};

export default PaymentSlice.reducer;
