import { ApiColoredIcon } from "./api-colored-icon";
import { CloudDataCloredIcon } from "./cloud-data-clored-icon";
import { FolderColoredIcon } from "./folder-colored-icon";
import { InfinityColoredIcon } from "./infinity-colored-icon";
import { PeopleColoredIcon } from "./people-colored-icon";
import { WifiColoredIcon } from "./wifi-colored-icon";

export const ColoredIcon = {
  api: ApiColoredIcon,
  cloudData: CloudDataCloredIcon,
  folder: FolderColoredIcon,
  infinity: InfinityColoredIcon,
  people: PeopleColoredIcon,
  wifi: WifiColoredIcon
}
