import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  container: {},
  links: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '500',
    fontSize: fluid(1.6, 1.4),
    color: 'var(--clr-primary-500)',

  },
  terms: {
    marginTop: '1.7rem',
    fontWeight: '400',
    fontSize: '1.4rem',
    color: 'var(--clr-text-400)',

    "& > a": {
      color: "var(--clr-primary-500)",
      textDecoration: "underline",
      textDecorationColor: "var(--clr-primary-500)",
      fontWeight: 400
    }
  }
}
