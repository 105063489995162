import { startTransition, useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import MediaQuery from "../../../components/common/media-query/media-query";
import { Note } from "../../../components/common/note/note";
import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { Table } from "../../../components/ui/table/table";
import { useLang, useSetSeoPage, useTranslateOptions } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { ToolsService } from "../../../services";
import { ADVANTAGES, WEB_PROTOCOL } from "../../../utils/constants";
import {
  getCaptchaTokenFromFormData,
  getPaginatedData,
  setCaptchaTokenToRequestHeaders
} from "../../../utils/helpers";
import { ProxyCheckSchema } from "../../../utils/validation/proxy-check.validation";

import { ProxyCheckerSeo } from "./proxy-checker-seo";
import { style } from "./proxyChecker.style";

const ProxyChecker = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();

  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const lang = useLang();

  const formContext = useForm({
    mode: "onChange",
    resolver: yupResolver(ProxyCheckSchema(t("form"))),
    defaultValues: {
      proxyList: "",
      httpOrHttps: true,
      socks4OrSocks5: true,
      nonWorking: true
    }
  });

  const formFields = formContext.watch();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // const [disabledCancel, setDisabledCancel] = useState(false);
  // const [loadingInitData, setLoadingInitData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkData, setCheckData] = useState(null);

  // helpers
  const filterDataFields = (element) => {
    if (
      (!formFields.httpOrHttps && element.webProtocol?.toUpperCase() === WEB_PROTOCOL.HTTP) ||
      (!formFields.httpOrHttps && element.webProtocol?.toUpperCase() === WEB_PROTOCOL.HTTPS)
    ) {
      return false;
    }

    if (
      (!formFields.socks4OrSocks5 &&
        (element.webProtocol?.toUpperCase() === WEB_PROTOCOL.SOCKS4 ||
          element.webProtocol?.toUpperCase() === WEB_PROTOCOL.SOCKS4H)) ||
      (!formFields.socks4OrSocks5 &&
        (element.webProtocol?.toUpperCase() === WEB_PROTOCOL.SOCKS5 ||
          element.webProtocol?.toUpperCase() === WEB_PROTOCOL.SOCKS5H))
    ) {
      return false;
    }

    if (!formFields.nonWorking && !element.state) {
      return false;
    }

    return true;
  };
  const advantages = useTranslateOptions(ADVANTAGES, t("advantages"));
  const filteredData = checkData?.filter(filterDataFields) ?? [];
  const paginatedData = getPaginatedData(filteredData, currentPage, pageSize);

  useEffect(() => {
    setCurrentPage(0);
  }, [pageSize, formFields.httpOrHttps, formFields.socks4OrSocks5, formFields.nonWorking]);

  const getProxyCheck = (init) =>
    ToolsService.getProxyCheck()
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject("Error");
        }
        if (typeof response.data !== "object") {
          // eslint-disable-next-line no-undef
          return Promise.resolve(false);
        }
        if (init) {
          response.data?.filters?.forEach((element) => formContext.setValue(element, false));
        }

        if (response.data?.proxyList?.length) {
          formContext.setValue("proxyList", response.data?.proxyList?.join("\n"));
        }

        if (response.data?.resultList?.length) {
          setCheckData(response.data.resultList);
        }

        // eslint-disable-next-line no-undef
        return Promise.resolve(response.data?.scanStatus === "CHECKING");
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        // eslint-disable-next-line no-undef
        return Promise.resolve(false);
      });

  useEffect(() => {
    // startTransition(() => setLoadingInitData(true));

    getProxyCheck(true).then((checking) => {
      startTransition(() => setLoading(checking));
      // startTransition(() => setLoadingInitData(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        getProxyCheck(false).then((checking) => {
          startTransition(() => setLoading(checking));
          if (!checking) {
            clearInterval(interval);
          }
        });
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // useEffect(() => {
  //   if (loading) {
  //     const timer = setTimeout(() => {
  //       setDisabledCancel(false);
  //     }, 1000);
  //
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   } else {
  //     setDisabledCancel(false);
  //   }
  // }, [loading]);

  const handlerSubmit = (data) => {
    if (loading) {
      ToolsService.getProxyCheckClear();
      setLoading(false);
    } else {
      setCheckData(null);
      // setDisabledCancel(true);
      setLoading(true);

      const filters = Object.entries(formFields).reduce(
        (acc, [key, value]) => (key === "proxyList" || value ? acc : [...acc, key]),
        []
      );

      const proxyList = formFields.proxyList
        .split(/\r?\n/)
        .filter(Boolean)
        .map((e) => e.trim());

      const cfToken = getCaptchaTokenFromFormData(data);

      ToolsService.getProxyCheck(
        {
          filters,
          proxyList
        },
        {
          headers: setCaptchaTokenToRequestHeaders(cfToken)
        }
      )
        .then(() => {
          captchaRef?.current?.reset();
        })
        .catch((error) => {
          // setDisabledCancel(false);
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log(error);

          if (error?.response?.status === 409) {
            captchaRef?.current?.reset();

            enqueueSnackbar(t("messages.captchaFailed"), { variant: "error" });
          }
        });
    }
  };

  const SubmitButton = () => (
    <Button
      type="submit"
      variant="primary"
      fullwidth
      loading={loading}
      // disabled={loadingInitData || disabledCancel}
    >
      {loading ? t("common.cancel") : t("form.actions.startScan")}
    </Button>
  );

  return (
    <>
      <Box sx={style.toolsProxyChecker} className="tools-proxy-checker">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.proxyChecker.heading")}
          </Heading>
        </ToolsHeader>

        <Box
          sx={style.description}
          className="tools-proxy-checker__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.proxyChecker.description")
          }}
        />
        <Box sx={style.formatNote}>
          <Note label={t("pages.tools.proxyChecker.formatNote.label")}>
            <Box
              component="span"
              dangerouslySetInnerHTML={{
                __html: t("pages.tools.proxyChecker.formatNote.publicText")
              }}
            />
            <Box
              component="span"
              dangerouslySetInnerHTML={{
                __html: t("pages.tools.proxyChecker.formatNote.privateText")
              }}
            />
            <Box component="span" sx={{ paddingTop: 1 }}>
              {t("common.postscriptShort")}: {t("pages.tools.proxyChecker.formatNote.psText")}
            </Box>
          </Note>
        </Box>
        <Form
          sx={style.panel}
          className="tools-proxy-checker__panel"
          context={formContext}
          onSubmit={handlerSubmit}
        >
          <Form.Input
            type="textarea"
            name="proxyList"
            label={t("form.labels.proxyList")}
            direction="vertical"
            rows={5}
            readOnly={loading}
          />
          <Box sx={style.panel.options} className="tools-proxy-checker__panel-options">
            <Box sx={style.panel.options.list} className="tools-proxy-checker__panel-options-list">
              <Form.Checkbox name="httpOrHttps" label="HTTP/S" />
              <Form.Checkbox name="socks4OrSocks5" label="Socks 4/5" />
              <Form.Checkbox name="nonWorking" label={t("form.labels.nonWorking")} />
            </Box>

            <Form.Captcha ref={captchaRef} sx={{ mb: "1rem" }} />
            <MediaQuery minWidth="bp576">
              <SubmitButton />
            </MediaQuery>
          </Box>

          <MediaQuery maxWidth="bp576">
            <SubmitButton />
          </MediaQuery>
        </Form>

        {checkData?.length ? (
          <Box sx={style.result} className="tools-proxy-checker__result">
            <Box sx={style.resultTitle}>
              <Heading tag="div" variant="h4">
                {t("shared.tools.result")}
              </Heading>
              <Box>
                {t("common.shown")}: {filteredData?.length ?? 0}/{checkData?.length ?? 0}
              </Box>
            </Box>
            <Table
              pagination={!!paginatedData?.length}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              totalElements={filteredData?.length}
              totalPages={Math.ceil(filteredData?.length / pageSize) - 1}
              pageSize={pageSize}
              elementsOnPage={pageSize}
              outputCountHandler={setPageSize}
            >
              <Table.Head>
                <Table.Row>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.ip")}</Table.Cell>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.port")}</Table.Cell>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.country")}</Table.Cell>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.speed")}</Table.Cell>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.type")}</Table.Cell>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.anonymity")}</Table.Cell>
                  <Table.Cell>{t("pages.tools.proxyChecker.table.state")}</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {paginatedData?.length ? (
                  paginatedData.map((row, key) => (
                    <Table.Row key={key}>
                      <Table.Cell>{row?.ip || t("common.noData")}</Table.Cell>
                      <Table.Cell>{row?.port || t("common.noData")}</Table.Cell>
                      <Table.Cell>
                        {row?.countryNamesByLocale?.[lang] || row?.country || t("common.noData")}
                      </Table.Cell>
                      <Table.Cell>
                        {row?.speedMS
                          ? `${row.speedMS} ${t("common.millisecondShort")}`
                          : t("common.noData")}
                      </Table.Cell>
                      <Table.Cell>{row?.webProtocol || t("common.noData")}</Table.Cell>
                      <Table.Cell>
                        {row?.anonymityPercent ? `${row.anonymityPercent}%` : t("common.noData")}
                      </Table.Cell>
                      <Table.Cell>
                        {row?.state ? t("common.active") : t("common.notActive")}
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row key={0}>
                    <Table.Cell>{t("common.noData")}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Box>
        ) : null}

        <Box component="ul" sx={style.advantages} className="tools-proxy-checker__advantages">
          {advantages.map((advantage, idx) => (
            <Box
              component="li"
              sx={style.advantage}
              key={`${idx}:${advantage.translation}`}
              className="tools-proxy-checker__advantage"
            >
              <Box sx={style.advantage.icon} className="tools-proxy-checker__advantage-icon">
                <img
                  src={`/images/icons/widgets/${advantage.icon}.svg`}
                  width={28}
                  height={28}
                  alt={advantage.icon}
                  loading="lazy"
                />
              </Box>
              <Box sx={style.advantage.text} className="tools-proxy-checker__advantage-text">
                {advantage.label}
              </Box>
            </Box>
          ))}
        </Box>

        <ProxyCheckerSeo />
      </Box>
    </>
  );
};

export default ProxyChecker;
