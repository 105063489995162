import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  blogCard: {
    // maxWidth: '37rem',
    display: "flex",
    flexDirection: "column",
    border: "0.1rem solid transparent",
    borderRadius: "3rem",
    backgroundColor: "var(--clr-default-100)",
    transition: "border-color var(--transition), box-shadow var(--transition)",
    borderColor: "transparent",
    boxShadow: "0 15px 25px rgba(179 184 202 / 22%)",
    overflow: "hidden",
    "& > a": {
      padding: "3rem 2.5rem",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      // @media (max-width: em(992)) {
      [appTheme.breakpoints.down("bp992")]: {
        padding: "2.2rem 1.7rem"
      }
    },

    ["@media (hover)"]: {
      ".blog-card__views": {
        opacity: "0",
        visibility: "hidden",
        transition: "opacity var(--transition), visibility var(--transition)"
      },

      "&:hover": {
        ".blog-card__views": {
          opacity: "1",
          visibility: "visible"
        },

        ".blog-card__link svg": {
          marginLeft: "1.5rem"
        }
      }
    }
  },

  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1.2rem",

    [appTheme.breakpoints.down("bp992")]: {
      // @media (max-width: em(992)) {
      marginBottom: "2rem"
    }
  },

  cardDate: {
    borderRadius: "0.7rem",
    padding: "0.6rem 1.5rem",
    fontWeight: "400",
    fontSize: fluid(1.4, 1.2),
    color: "var(--clr-primary-500)",
    backgroundColor: "rgba(var(--clr-primary-400-rgb) / 15%)"
  },

  cardViews: {
    fontWeight: "400",
    fontSize: fluid(1.4, 1.2),
    color: "var(--clr-text-400)",
    opacity: "1",
    visibility: "visible",
    svg: {
      marginRight: "0.5rem"
    }
  },

  cardTitle: {
    marginBottom: "1rem",
    minHeight: "12rem",
    fontWeight: "400",
    fontSize: fluid(2, 1.8),
    lineHeight: "120%",
    color: "var(--clr-text-800)"
  },

  cardLink: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    span: {
      fontWeight: "400",
      fontSize: "1.6rem",
      color: "var(--clr-primary-500)"
    },

    svg: {
      marginTop: "0.2rem",
      marginLeft: "1rem",
      transition: "margin-left var(--transition)"
    }
  }
};
