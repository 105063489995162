// import { Form } from "../../../components/form";
import { Box } from "@mui/material";
import { CustomCheckbox } from "../../common/checkbox/checkbox";

export const TableCheckbox = ({
  label,
  onChange,
  checked,
  color = "default"
}) => (
  <Box className="table-checkbox">
    {/* <Form.Checkbox
      label={label}
      onChange={(e) => onChange(e.target.checked)}
      checked={checked}
      color={color}
    /> */}
    <CustomCheckbox
      label={label}
      changeHandler={(e) => onChange(e.target.checked)}
      checked={checked}
      color={color}
    />
  </Box>
);
