import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ShortInfoBlock from "../../../components/common/tools/short-info-block/short-info-block";
import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Widget } from "../../../components/common/widget/widget";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { ToolsService } from "../../../services";
import { getToolsState } from "../../../store/tools/tools.selectors";
import {
  checkWebRtcEnabled,
  detectPlugin,
  formatCustomDate,
  formatDateTime,
  formatDateTimeParts,
  getWebProxyLeak
} from "../../../utils/helpers";

import { MyAnonymitySeo } from "./my-anonymity-seo";
import { style } from "./myAnonimity.style";

const MyAnonymity = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();

  const { t } = useTranslation();
  const ref = useRef(null);
  const { clientIpInfo, clientAnonymityPercent, clientBlackListsCheck } =
    useSelector(getToolsState);
  const { checkIp, getAnonymityPercent, getClientBlackListsCheck } = useActions();

  // const [turboMode, setTurboMode] = useState(false);
  const [plugins, setPlugins] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [webRtcEnabled, setWebRtcEnabled] = useState(false);
  // const [webRtcLeakIpList, setWebRtcLeakIpList] = useState(null);

  useEffect(() => {
    setPlugins({
      flash: detectPlugin.flash(),
      java: detectPlugin.java(),
      activeX: detectPlugin.activeX(),
      vBScript: detectPlugin.vBScript()
    });
  }, []);

  useEffect(() => {
    if (!clientBlackListsCheck.data) {
      getClientBlackListsCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientBlackListsCheck.data]);

  useEffect(() => {
    if (!clientAnonymityPercent.data) {
      getAnonymityPercent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAnonymityPercent.data]);

  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors || clientAnonymityPercent.error || clientBlackListsCheck.error) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors, clientAnonymityPercent.error, clientBlackListsCheck.error]);

  useEffect(() => {
    if (checkWebRtcEnabled()) {
      setWebRtcEnabled(true);

      // getWebRtcLeakIpList().then((e) => {
      //   setWebRtcLeakIpList(e);
      // }).catch((e) => {
      //   // eslint-disable-next-line no-console
      //   console.log(e);
      // });
    }

    // Check turbo mode
    // $api.get("/images/do-not-remove-calibration-for-testing-compression.jpg", { responseType: "arraybuffer" }).then((response) => {
    //   if (response.status !== 200) {
    //     // eslint-disable-next-line no-undef
    //     return Promise.reject(response);
    //   }
    //
    //   if (response.data.byteLength !== 46421) {
    //     setTurboMode(true);
    //   }
    // }).catch((error) => {
    //   // eslint-disable-next-line no-console
    //   console.log(error);
    // });
  }, []);

  // eslint-disable-next-line no-undef
  const timeZoneJs = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handlerClick = () => {
    if (loading) {
      if (ref.current) {
        ref.current.abort();
        ref.current = null;
      } else {
        setLoading(false);

        enqueueSnackbar(t("common.canceled"), { variant: "error" });
      }
      return;
    }

    // eslint-disable-next-line no-undef
    ref.current = new AbortController();

    setLoading(true);
    setData(null);

    ToolsService.getAnonymity(
      { timeZoneJs },
      {
        signal: ref.current.signal
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject("Error");
        }
        if (response.data.error) {
          // eslint-disable-next-line no-undef
          return Promise.reject({ response });
        }
        setData(response.data);
      })
      .catch((error) => {
        let message = t("serverError");

        if (error?.response?.data.error === "IpOrDomain not valid!") {
          message = t("form.rules.ipOrDomain.invalid");
        } else if (error.message === "canceled") {
          message = t("common.canceled");
        }

        enqueueSnackbar(message, { variant: "error" });

        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        ref.current = null;
      });
  };
  let dateIp = null;

  if (data?.dateIp) {
    dateIp = formatCustomDate(data.dateIp);
  }
  const dateJs = formatDateTimeParts(new Date());

  return (
    <>
      <Box className="tools-my-anonymity">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.myAnonymity.heading")}
          </Heading>

          <ShortInfoBlock
            ip={{
              loading: clientIpInfo.loading,
              v4: clientIpInfo.data?.ip?.ipv4,
              v6: clientIpInfo.data?.ip?.ipv6
            }}
            anonymity={{
              loading: clientAnonymityPercent.loading,
              percent: clientAnonymityPercent.data
            }}
          />
        </ToolsHeader>

        <Box sx={style.widgets} className="tools-my-anonymity__widgets">
          <Box
            sx={style.widgets.text}
            className="tools-my-anonymity__widgets-text"
            dangerouslySetInnerHTML={{
              __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.myAnonymity.widgets.caption")
            }}
          />
          <Box sx={style.widgets.list} className="tools-my-anonymity__widgets-list">
            <Widget.Copy
              copyDisabled={true}
              data={{
                image: "/images/icons/ip-info/ip.svg",
                title: t("shared.tools.yourIp"),
                text:
                  clientIpInfo.data?.ip?.ipv6 || clientIpInfo.data?.ip?.ipv4 || t("common.noData")
              }}
            />
            <Widget.Copy
              copyDisabled={true}
              data={{
                image: "/images/icons/widgets/country-ownership.svg",
                title: t("shared.tools.yourGeo"),
                text: clientIpInfo.data?.country ?? t("common.noData")
              }}
            />
            <Widget.Copy
              copyDisabled={true}
              data={{
                image: "/images/icons/ip-info/incognito.svg",
                title: t("shared.tools.yourAnonymity"),
                text: clientAnonymityPercent.data
                  ? `${clientAnonymityPercent.data}%`
                  : t("common.noData")
              }}
            />
          </Box>

          <Box sx={style.widgets.text} className="tools-my-anonymity__widgets-text">
            {t("pages.tools.myAnonymity.description")}
          </Box>

          <Button variant="primary" fullwidth loading={loading} onClick={handlerClick}>
            {loading ? t("common.cancel") : t("form.actions.startScan")}
          </Button>
        </Box>

        {data ? (
          <Box sx={style.result} className="tools-my-anonymity__result">
            <Heading tag="div" variant="h4">
              {t("shared.tools.result")}
            </Heading>

            <Widget.Table
              rows={[
                {
                  key: t("pages.tools.myAnonymity.table.httpProxyHeaders"),
                  value: data.headersHttp?.length
                    ? data.headersHttp.join(", ")
                    : t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.openHttpProxyPorts"),
                  value: data.openPortsHttp?.length
                    ? data.openPortsHttp
                        .map((item) => `${item.port} (${item.description})`)
                        .join(", ")
                    : t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.openWebProxyPorts"),
                  value: data.openPortsWeb?.length
                    ? data.openPortsWeb
                        .map((item) => `${item.port} (${item.description})`)
                        .join(", ")
                    : t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.openVpnPorts"),
                  value: data.openPortsVPN?.length
                    ? data.openPortsVPN
                        .map((item) => `${item.port} (${item.description})`)
                        .join(", ")
                    : t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.suspiciousHostname"),
                  value: data?.suspiciousHostname ?? t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.differenceInTimeZonesBrowserAndIp"),
                  value: `
              IP: ${formatDateTime(dateIp ?? dateJs)} (${data.timeZoneIp}),
               ${t("common.browser")}: ${formatDateTime(dateJs)}
               `
                },
                {
                  key: t("pages.tools.myAnonymity.table.ipAffiliationWithTheTorNetwork"),
                  value: data?.tor ? t("common.detected") : t("common.notDetected")
                },
                // {
                //   key: t.pages.tools.myAnonymity.table.browserTurboMode,
                //   value: turboMode ? t.common.detected : t.common.notDetected
                // },
                {
                  key: t("pages.tools.myAnonymity.table.affiliationWithIpHostingProvider"),
                  value: data?.ipHostingProvider ?? t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.webProxyDefinitionJsMethod"),
                  value: data?.hostname
                    ? (() => {
                        const result = getWebProxyLeak(window, data?.hostname);

                        return result.status
                          ? `${result.type ?? t("common.detected")} ${result.hostName}`
                          : t("common.notDetected");
                      })()
                    : t("common.noData")
                },
                {
                  key: t("pages.tools.myAnonymity.table.tunnelDefinitionTwoWayPing"),
                  value: data?.tunnelDefinition ? t("common.used") : t("common.notUsed")
                },
                {
                  key: t("pages.tools.myAnonymity.table.dnsLeak"),
                  value: data.dnsList?.length ? data.dnsList.join(", ") : t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.vpnFingerprint"),
                  value: data?.fingerprintVpn ? t("common.detected") : t("common.notDetected")
                },
                {
                  key: "WebRTC",
                  value: webRtcEnabled ? t("common.on") : t("common.off")
                },
                {
                  key: t("pages.tools.myAnonymity.table.ipLeakViaWebRTC"),
                  value: data?.webRtcIps?.join(", ") ?? t("common.notDetected")
                },
                {
                  key: t("pages.tools.myAnonymity.table.ipInBlacklists"),
                  value: clientBlackListsCheck.data?.length
                    ? clientBlackListsCheck.data.join(", ")
                    : t("common.notDetected")
                },

                {
                  key: "Flash",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.flash ? t("common.on") : t("common.off")
                },
                {
                  key: "Java",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.java ? t("common.on") : t("common.off")
                },
                {
                  key: "ActiveX",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.activeX ? t("common.on") : t("common.off")
                }
              ]}
              patch="right"
            />
          </Box>
        ) : null}

        <MyAnonymitySeo />
      </Box>
    </>
  );
};

export default MyAnonymity;
