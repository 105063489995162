import { appTheme } from "../../../theme";
import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  cabinetBalance: {
    position: "relative",
    ".form": {
      borderRadius: "3rem",
      padding: "4rem",
      maxWidth: "63.5rem",
      boxShadow: "8px 12px 50px rgba(185, 197, 222, 0.2)",
      backgroundColor: "var(--clr-default-100)",

      [appTheme.breakpoints.down("bp992")]: {
        maxWidth: "initial"
      },

      [appTheme.breakpoints.down("bp767")]: {
        padding: "1.5rem"
      }
    },

    ".heading_h4": {
      marginBottom: "2rem"
    },

    "div:not(.form-select)": {
      ".form-item__field": {
        position: "relative",
        padding: "1rem 1.5rem 1rem 5.5rem",

        ".form-item__post-word": {
          left: "5.5rem"
        },

        input: {
          color: "var(--clr-default-900)",
          "&::placeholder": {
            color: "var(--clr-text-400)"
          }
        },

        "&::before": {
          content: `""`,
          position: "absolute",
          left: "0.6rem",
          top: "50%",
          width: "3.4rem",
          height: "3.4rem",
          backgroundImage: `url("/images/icons/sum.svg")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          transform: "translateY(-50%)"
        }
      }
    },

    ".button": {
      minWidth: "25rem",
      [appTheme.breakpoints.down("bp576")]: {
        width: "100%"
      }
    }
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "3.5rem",

    [appTheme.breakpoints.down("bp767")]: {
      flexDirection: "column"
    },

    ".tabs": {
      marginLeft: "1.5rem",

      [appTheme.breakpoints.down("bp767")]: {
        marginTop: "1.5rem",
        marginLeft: "initial"
      }
    }
  },

  score: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "auto",
    marginLeft: "2rem",
    borderRadius: "2.5rem",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
    minHeight: "4rem",
    fontWeight: 600,
    fontSize: fluid(2, 1.6),
    lineHeight: "160%",
    color: "var(--clr-primary-400)",
    background: "var(--clr-default-300)",

    [appTheme.breakpoints.down("bp767")]: {
      marginTop: "1.5rem",
      marginRight: "initial",
      marginLeft: "initial"
    }
  },

  warning: {
    position: "relative",
    marginTop: "1.5rem",
    marginBottom: "3.5rem",
    borderRadius: "2rem",
    padding: "2.5rem 2.5rem 2.5rem 5.5rem",
    backgroundColor: "#f4f6fe",

    [appTheme.breakpoints.down("bp767")]: {
      marginBottom: "2rem",
      padding: "1.5rem 1.5rem 1.5rem 4.5rem"
    },

    "&::before": {
      content: `""`,
      position: "absolute",
      left: "2.4rem",
      top: "2.4rem",
      width: "2rem",
      height: "2rem",
      backgroundImage: `url("/images/icons/info.svg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",

      [appTheme.breakpoints.down("bp767")]: {
        left: "1.5rem",
        top: "1.5rem"
      }
    }
  },

  count: {
    list: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1rem",
      marginBottom: "3rem"
    },

    item: {
      ".MuiButtonBase-root": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2rem",
        paddingRight: "1.5rem",
        paddingLeft: "1.5rem",
        minHeight: "3.4rem",
        fontWeight: 500,
        fontSize: "1.6rem",
        lineHeight: "115%",
        color: "var(--clr-primary-400)",
        backgroundColor: "var(--clr-default-300)"
      },

      [appTheme.breakpoints.down("bp370")]: {
        ".MuiButtonBase-root": {
          paddingRight: "1rem",
          paddingLeft: "1rem"
        }
      },

      hideMod576: {
        [appTheme.breakpoints.down("bp576")]: {
          ".MuiButtonBase-root": {
            display: "none"
          }
        }
      },

      hideMod460: {
        [appTheme.breakpoints.down("bp460")]: {
          ".MuiButtonBase-root": {
            display: "none"
          }
        }
      },

      "& + $item": {
        ".MuiButtonBase-root": {
          marginLeft: "1rem",

          [appTheme.breakpoints.down("bp767")]: {
            marginLeft: "0.5rem"
          }
        }
      }
    }
  },

  order: {
    copy: {
      marginLeft: "1rem"
    },

    load: {
      marginRight: "1rem"
    }
  },

  fixedWidth: {
    maxWidth: "115px",
    boxSizing: "border-box",
    "button": {
      minWidth: "100px !important",
      paddingRight: "10px !important",
      paddingLeft: "10px !important",
      marginLeft: "15px"

    }
  },

  noDataLabel: {
    padding: "30px 0px",
    fontSize: "18px",
    display: "flex",
    margin: "0px auto",
    justifyContent: "center"
  },
};
