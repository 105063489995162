import React from "react";
import { Box } from "@mui/material";
import { style } from "./tools-header.style";

const ToolsHeader = (props) => {
  const {children} = props;

  return (
    <Box sx={style.container}>
      {children}
    </Box>
  );
};

export default ToolsHeader;
