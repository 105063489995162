import React from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLang } from "../../../hooks";
import { getReviewState } from "../../../store/review/review.selectors";
import { Button, Heading } from "../../ui";
import { Container } from "../container/container";
import { ReviewsList } from "../reviews-list/reviews-list";

// import { Button, Heading } from "../../ui";
// import { ReviewsList } from "../reviews-list/reviews-list";
import { style } from "./reviews-block.style";

const ReviewsBlock = () => {
  const lang = useLang();
  const { t } = useTranslation();

  const { reviewsPreview, reviewsPreviewLength } = useSelector(getReviewState);

  const reviews = reviewsPreview?.[lang];

  if (!reviews?.length) {
    return null;
  }

  return (
    <Box sx={style.reviews}>
      <Container>
        <Heading tag="div" variant="h2" center>
          {t("shared.reviews.heading")}
        </Heading>
        <ReviewsList reviews={reviews} view="preview" />
        {reviews?.length > reviewsPreviewLength ? (
          <Box sx={style.link}>
            <Button type="link" to={`/${lang}/reviews/`}>
              {t("shared.reviews.button")}
            </Button>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default ReviewsBlock;
