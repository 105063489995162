export const translationsUa = {
  translation: {
    header: {
      cabinet: "Особистий кабінет",
      logIn: "Вхід"
    },
    menu: {
      buyProxy: "Купити проксі",
      proxyFor: "Проксі для",
      prices: "Ціни",
      company: "Компанія",
      instruments: "Iнструменти"
    },
    notifications: {
      title: "Сповіщення",
      clear: "Очистити всі",
      new: "НОВЕ",
      expand: "Розгорнути",
      collapse: "Згорнути",
      noNotifications: "Немає сповіщень"
    },
    company: {
      titles: {
        aboutUs: "Про нас",
        information: "Інформація",
        other: "Інше"
      },
      links: {
        conditions: "Умови",
        faq: "FAQ",
        contacts: "Контакти",
        reviews: "Відгуки",
        promocodes: "Промокоди",
        blog: "Блог",
        cabinet: "Особистий кабінет",
        offer: "Публічна оферта",
        policy: "Політика конфіденційності",
        refundPolicy: "Політика повернення",
        cookiePolicy: "Політика Cookie",
        documentation: "Документація"
      }
    },
    tools: {
      myIp: { label: "Мій IP" },
      pingIp: { label: "Пінг IP" },
      checkEvercookie: { label: "Перевірка Evercookie" },
      proxyChecker: { label: "Проксі-чекер" },
      myAnonymity: { label: "Моя анонімність" },
      checkWebrtc: { label: "Перевірка WebRTC" },
      portScanner: { label: "Сканер портів" },
      fingerprintScanner: { label: "Сканер Fingerprint" },
      ipTracing: { label: "Трасування IP" }
    },
    cabinet: {
      profile: { label: "Профіль" },
      orders: {
        label: "Замовлення",
        sub: {
          ordersIpv4: "IPv4",
          ordersIpv6: "IPv6",
          ordersIsp: "ISP",
          ordersMobileProxy: "Мобільний проксі",
          ordersNotActive: "Не активні розділи"
        },
        status: {
          active: "Активний"
        },
        noData: "Немає заказiв",
        extendError: "Сталася помилка у продлiннi проксi"
      },
      newOrder: { label: "Нове замовлення" },
      balance: {
        label: "Баланс",
        noData: "Немає iсторiї поповнень"
      },
      paymentHistory: {
        label: "Історія платежів",
        success: "Оплачено",
        notSuccess: "Не оплачено",
        noData: "Немає платежів"
      },
      actionsHistory: {
        label: "Історія дій",
        noData: "Немає iсторiї дiй"
      },
      promocodes: {
        label: "Промокоди",
        noData: "Немає промокодiв"
      },
      subscription: { label: "Підписка" },
      faq: { label: "FAQ" },
      developersApi: { label: "API" },
      logout: { label: "Вихід" }
    },
    languages: {
      ru: "Рус",
      en: "Eng",
      uk: "Укр"
    },
    countries: {
      all: "Всі",
      RUS: "Росія",
      USA: "США",
      UKR: "Україна",
      KAZ: "Казахстан",
      GBR: "Англія",
      DEU: "Німеччина",
      BGR: "Болгарія",
      FRA: "Франція",
      NLD: "Нідерланди",
      BLR: "Білорусь",
      MDA: "Молдова",
      LVA: "Латвія",
      IND: "Індія",
      POL: "Польща",
      ITA: "Італія",
      LKA: "Шрі-Ланка",
      AUT: "Австрія",
      AUS: "Австралія",
      JPN: "Японія",
      THA: "Таїланд",
      CAN: "Канада",
      MEX: "Мексика",
      ESP: "Іспанія",
      GEO: "Грузія",
      EGY: "Єгипет"
    },
    authMethods: {
      ip: "Статичний IP",
      login: "Логін/Пароль"
    },
    proxySocials: {
      vk: "Вконтакте",
      classmates: "Однокласники",
      telegram: "Telegram",
      linkedin: "LinkedIn",
      facebook: "Facebook",
      instagram: "Instagram",
      twitter: "Twitter",
      youtube: "YouTube",
      periscope: "Periscope"
    },
    contacts: {
      skype: "YouProxy",
      telegram: "Telegram",
      vk: "Vkontakte",
      telegramChannel: "Telegram Channel",
      facebook: "Facebook",
      facebookGroup: "Facebook Group",
      mail: "support@youproxy.io",
      fields: {
        name: "Ваше iм'я",
        email: "Ваш email",
        text: "Ваше повiдомлення"
      },
      validateErrors: {
        name: {
          required: "Iм'я обов'язкове"
        },

        email: {
          required: "Email обов'язковий",
          invalid: "Неправильний email"
        },

        text: {
          required: "Повiдомлення обов'язкове"
        }
      },
      serverSuccess: {
        title: "Повiдомлення вiдправлено",
        description: "Найближчим часом наш менеджер відповість на всі питання, що Вас цікавлять.",
        action: "Повернутися назад"
      },
      serverError: {
        title: "Повiдомлення не вiдправлено",
        description: "Щось пішло не так, з незалежних від нас причин лист не був доставлений. ",
        action: "Повернутися назад"
      },
      serverNotRegistered: {
        title: "Цей email не зареєстрований",
        description: "Щоб надіслати лист із цим email, вам потрібно спочатку зареєструватися.",
        action: "Повернутися назад"
      },
      serverNotValidMail: {
        title: "Будь ласка, введіть наявний email",
        description:
          "Повідомлення не було надіслано, оскільки введеної адреси електронної пошти не існує.",
        action: "Повернутися назад"
      }
    },
    payments: {
      usd: "$",
      eur: "EUR",
      mbtc: "mBTC",
      uah: "UAN",
      rub: "RUB"
    },
    buyProxy: {
      ipv4: "IPv4",
      ipv6: "IPv6",
      isp: "ISP",
      mobile: "Мобільні проксі"
    },
    proxyFor: {
      socialNetworks: "Соціальні мережі",
      webScraping: "Парсинг сторінок",
      gamingProxies: "Ойлайн ігри",
      sneaker: "Проксі sneaker",
      onlineMarketplace: "Онлайн-маркет",
      other: "Інше",
      searchEngineParsing: "Парсинг пошукової системи"
    },
    mobileProxy: {
      beeline: "Білайн",
      vodaphone: "Водафон",
      kievstar: "Київстар",
      life: "Лайф",
      mts: "МТС"
    },
    faq: {
      generalQuestions: "Загальні питання",
      extendProxy: "Продовження проксі",
      technicalQuestions: "Тех. питання",
      security: "Безпека",
      authorizationSettings: "Авторизація/Налаштування"
    },
    advantages: {
      countryOwnership: "Перевірка IPv4, IPv6 на належність країни;",
      protocolDefinition: "Визначення типу протоколу HTTP/S, SOCKS5 та поточної швидкості;",
      anonymousChecking: "Перевірка анонімності адреси;",
      proxyChecking: "Групова, швидкісна багатопоточна перевірка проксі в 64 потоки;",
      downloadUpload: "Можливість закачати списком та вивантажити результат перевірки;",
      resultsFilter: "Зручне виведення результатів за допомогою фільтра відображення."
    },
    portType: {
      popularPorts: "Популярні порти",
      hiddenPorts: "Приховані порти",
      proxyPorts: "Проксі порти"
    },
    answers: {
      yes: "Так",
      no: "Ні"
    },
    proxyGoal: {
      goal1: "Мета проксі",
      goal2: "Мета проксі 2",
      goal3: "Мета проксі 3"
    },
    proxyServices: {
      service1: "Послуга проксі",
      service2: "Послуга проксі 2",
      service3: "Послуга проксі 3"
    },
    proxyRentalPeriod: {
      day: "Днів"
    },
    ipType: {
      all: "Всі",
      https: "HTTPS",
      socks5: "SOCKS5",
      http: "HTTP/S"
    },
    pages: {
      app: {
        home: {
          hero: {
            heading: "Купити високошвидкісні приватні <span>проксі</span>",
            totalSum: "Вартість складає: "
          },
          geos: {
            heading: "Купити проксі потрібної гео"
          },
          features: {
            heading: "Особливості",
            description:
              "У Youproxy ви купуєте виключно приватні проксі сервери з підтримкою обох протоколів: Socks 5 і HTTP(s). Також основні характеристики нашого сервісу ми перераховуємо нижче.",
            leftItems: {
              security: {
                label: "Захист",
                value: "100%"
              },
              uptime: {
                label: "Uptime",
                value: "99%"
              },
              delivery: {
                label: "Видача",
                value: "3 хв"
              },
              support: {
                label: "Підтримка",
                value: "24/7"
              }
            },
            rightItems: {
              coverage:
                "Можна купити проксі в <span>20+ країнах</span>, пул яких постійно оновлюється та розширюється",
              speed:
                "Одна з основних переваг наших проксі: <span>мінімальний пінг</span> та <span>висока швидкість</span>",
              networks:
                "Куплені проксі видаються з розкидом <span>мережами</span> та <span>підмережами</span>",
              fullPersonal: "До покупки пропонуються проксі на 100% <span>персональні</span>"
            },
            downloadListCard: {
              title: "Список завантажень (TXT)",
              description:
                "Насолоджуйтеся використанням сучасної панелі керування після завантаження списку проксі. Кожен проксі-план пропонує кілька цінних і корисних можливостей",
              button: {
                text: "Досліджуйте"
              }
            },
            apiCard: {
              title: "API",
              description:
                "Оптимізуйте свій робочий процес і бюджет за допомогою інтеграції з Webshare RESTful API. Webshare пропонує найнадійніші API проксі.",
              button: {
                text: "Переглянути API"
              }
            }
          },
          form: {
            minimal: "Мiнiмальна кiлькiсть проксi: "
          },
          seo: ""
        },
        proxyFor: {
          hero: {
            heading: "Проксі для",
            caption: "Проксі поштучно:"
          },
          packet: {
            heading: "Пакетні проксі",
            table: {
              goals: "Цілі:",
              countPrice: "Кількість/ціна:",
              actions: {
                order: "Замовити"
              }
            }
          },
          seo: ""
        },
        proxyByCountry: {
          prices: {
            title: "Пакетні проксі",
            description:
              "Проксі {{country}} видаються виключно в одні руки за допомогою обох протоколів: Socks5 та HTTP/A7 У поштучній формі можна оформити купити будь-яку кількість з урахуванням усіх знижок за кількість та термін оренди"
          },
          whatYouGet: {
            title: "Що ви отримуєте",
            content: [
              "Авторизація по API",
              "Вивантаження у будь-якому форматі",
              "Стабільний UP'time",
              "Високий траст показник",
              "Різноманітність мереж/підмереж"
            ]
          },
          apiIntegration: {
            title: "Інтегруй API",
            description:
              "Проксі {{country}} видаються виключно в одні руки з підтримкою обох протоколів: Socks5 та HTTP/A7 У поштучній формі можна оформити купити будь-яку кількість з урахуванням усіх знижок за кількість та термін оренди"
          }
        },
        buyProxy: {
          hero: {
            heading: "Проксі",
            caption: "Проксі поштучно:",
            description:
              "Проксі {{countryName}} видаються виключно в одні руки з підтримкою обох протоколів: Socks5 і HTTP/A7 У поштучній формі можна оформити купити будь-яку кількість з урахуванням усіх знижок за кількість та термін оренди",
            adv: {
              server: "<span>HTTP(S)</span> / SOCKS5",
              atom: "<span>170+</span> пiдмереж",
              support: "<span>Пiдтримка</span> <br /> 24/7/365"
            },
            animationLabels: {
              noLimits: "Без лімітів",
              support: "Підтримка 24/7",
              connection: "Стабільний зв'язок",
              anonymity: "Анонімність"
            }
          },
          packet: {
            heading: "Пакетні проксі:",
            table: {
              goals: "Цілі:",
              countPrice: "Кількість/ціна:",
              actions: {
                order: "Замовити"
              },
              searchLabel: "Проксі для {{proxyFor}} за ГЕО:"
            }
          }
        },
        mobileProxy: {
          hero: {
            heading: "Мобільні проксі",
            description:
              "Динамічні мобільні проксі – безперебійний серфінг будь-якої складності, без блокувань та капчу. IP адреси реальних GSM операторів, що забезпечує максимальну анонмність та безпеку.",
            caption: "ЦІНИ МОБІЛЬНИХ LTE ПРОКСІ:",
            support: "Онлайн підтримка",
            order: {
              info: {
                characteristics: "Характеристики:",
                country: "Країна:",
                proxyCount: "Кількість проксі:",
                dedicatedIp: "Виділений IP:",
                multipleSubnets: "Кілька підмереж:",
                note: {
                  label: "Примітка:",
                  content: ""
                }
              },
              result: {
                title: "Опції замовлення",
                proxy: "Проксі:",
                rentalPeriod: "Термін:"
              }
            }
          },
          seo: ""
        },
        blog: {
          heading: "Блог",
          noData: "Постiв у блозi не знайдено"
        },
        article: {
          sidebar: {
            new: "Нове",
            popular: "Популярне"
          },
          noData: "Вибачте, статтю не знайдено"
        },
        notFound: {
          title: "Упс... Здається, такої сторінки немає.",
          text: "Можливо, сторінка була видалена або недоступна тимчасово. Будь ласка, зверніться до наших менеджерів, якщо вам потрібна з чимось допомога.",
          btn: "Повернутися на головну"
        },
        reviews: {
          hero: {
            heading: "Відгуки",
            sources: "Джерела",
            showAll: "Показати всі відгуки",
            allReviews: "Всі відгуки"
          },
          noData: "Вiдгуки не знайденi"
        },
        promocodes: {
          heading: "Промокоди",
          more: "Показати ще",
          link: "Використати промокод",
          validDate: "Дійсний до",
          used: "Використан",
          times: "разів",
          noData: "Вибачте, промокоди не знайденi"
        },
        contacts: {
          heading: "Контакти",
          form: "Форма зворотного зв'язку",
          description:
            "Ми раді отримувати від вас відгуки, пропозиції та питання щодо нашого сервісу. Наш менеджер розгляне вашу заявку та дасть відповідь найближчим часом."
        },
        thanks: {
          hero: {
            heading: "Дякую за покупку!",
            subHeading: "Оплата пройшла успішно. Спасибі, що вибрали наш сервіс!",
            info: "Ми прийняли Ваше замовлення і наша система підключає IP адреси. В основному IP адреси доступні через 5-10 хвилин. Реєстрація відбувається автоматично, після покупки проксі - дані надсилаються на вказану в замовленні пошту та відображаються в особистому кабінеті.",
            description: `
            <p>
              Всю інформацію щодо вашого замовлення, можете переглянути в особистому кабінеті. Так
              само відстежувати статуси замовлень, час дії IP-адрес та керувати операціями.
            </p>
            <p>
              Якщо у Вас виникнуть проблеми з підключенням проксі, зверніться до оператора мережі
              отримання допомоги.
            </p>
          `
          },
          replenishmentHero: {
            heading: {
              success: "Дякуємо за оплату!",
              pending: "Ваш платіж обробляється!",
              cancel: "Ваш платіж було скасовано"
            },
            subHeading: "Поповнення на суму: {amount}",
            info: {
              success:
                "Оплата пройшла успішно. Кошти додадуться до вашого балансу протягом 5-10 хвилин.",
              pending:
                "Ваш платіж все ще обробляється. Зачекайте, поки баланс не оновиться в особистому кабінеті",
              cancel:
                "Схоже, що вашу оплату було скасовано. Будь ласка, зв'яжіться зі своїм банком і спробуйте ще ра."
            },
            description: `
            <p>
              Всю інформацію щодо вашого балансу, можете переглянути в особистому кабінеті.
            </p>
            <p>
              Якщо у Вас виникнуть проблеми з вашим балансом, зверніться до оператора отримання допомоги.
            </p>
          `
          },
          contacts: {
            heading: "Будемо вдячні за думку",
            description:
              "Залиште відгук на будь-якому з ресурсів про нашу роботу, вкажіть мінуси та плюси в роботі з нами. Ми враховуємо кожну думку, виправляємо помилки, щоб стати кращими для вас!"
          }
        },
        balanceThanks: {
          hero: {
            success: {
              heading: "Ваш баланс поповнено!",
              subHeading: "Ваш баланс поповнено на: {amount}$",
              info: "",
              description: `
              <p>
                Вся інформація про ваш баланс знаходиться в особистому кабінеті.
                Також ви можете подивитися вашу історію поповнень.
              </p>
              <p>
              Якщо у вас виникли проблеми, пов'язані з балансом, зверніться до нашого оператора за допомогою.
              </p>
            `
            },

            cancel: {
              heading: "Ваш платіж було скасовано!",
              subHeading: "Можливо, виникла проблема з вашим банком, спробуйте ще раз.",
              info: "",
              description: `
              <p>
                Вся інформація про ваш баланс знаходиться в особистому кабінеті.
                Також ви можете подивитися вашу історію поповнень.
              </p>
              <p>
              Якщо у вас виникли проблеми, пов'язані з балансом, зверніться до нашого оператора за допомогою.
              </p>
            `
            },

            pending: {
              heading: "Ваш платіж обробляється!",
              subHeading: "Новий баланс з'явиться через деякий час.",
              info: "",
              description: `
              <p>
                Вся інформація про ваш баланс знаходиться в особистому кабінеті.
                Також ви можете подивитися вашу історію поповнень.
              </p>
              <p>
              Якщо у вас виникли проблеми, пов'язані з балансом, зверніться до нашого оператора за допомогою.
              </p>
            `
            }
          }
        },
        price: {
          heading: "Ціни на проксі",
          location: "Локація"
        },
        offer: {
          heading: "",
          content: ""
        },
        conditions: {
          heading: "",
          content: ""
        },
        policy: {
          heading: "",
          content: ""
        },
        refundPolicy: {
          heading: "",
          content: ""
        },
        cookiePolicy: {
          heading: "",
          content: ""
        }
      },
      cabinet: {
        profile: {
          heading: "Профіль"
        },
        orders: {
          tags: {
            ipv4: {
              heading: "Проксі IPv4"
            },
            ipv6: {
              heading: "Проксі IPv6"
            },
            isp: {
              heading: "Проксі ISP"
            },
            "mobile-proxy": {
              heading: "Мобільні проксі"
            },
            "inactive-partitions": {
              heading: "Замовлення"
            }
          },
          widgets: {
            system: {
              title: "Активнi закази:",
              rowLabel: "Проксі"
            },
            export: {
              title: "Експорт:",
              chooseFormat: "Виберіть формат:",
              downloadList: "Вивантажити список",
              selectProxies: "Будь ласка, виберіть проксі для експорту",
              selectSOCKS: "Будь ласка, виберіть проксі з протоколом SOCKS5",
              selectHTTP: "Будь ласка, виберіть проксі з протоколом HTTPS"
            },
            autoRenewal: {
              title: "Автопродовження",
              description: "Виберіть зі списку IP для увімкнення або вимкнення автопродовження",
              selectedIp: "Вибрано IP",
              enable: "Увімкнути",
              disable: "Вимкнути",
              selectIpsWithSameSettings: "Виберіть IP з однаковими налаштуваннями",
              willCharged: "буде списано",

              alert: {
                priceError:
                  "Суми на балансі недостатньо для використання опції. Будь ласка, поповніть баланс.",
                notEnoughMoney: "Суми на балансі недостатньо для використання опції. Будь ласка,",
                topUpBalance: "поповніть баланс на",
                enabled: "Автопродовження увімкнене",
                notEnabled: "Не вдалося включити автопродовження",
                disabled: "Автопродовження відключено",
                notDisabled: "Не вдалося відключити автопродовження"
              }
            },
            autoRenewalModal: {
              title: "Увімкнення автопродовження",
              descriptionEnableAR:
                "Виберіть строк, на який будуть продовжуватися обрані IP адреси, та завершіть процедуру увімкнення опції.",
              term: "Термін",
              paymentMethod: "Спосіб оплати",
              confirm: "Підтвердити",
              cancel: "Скасувати",
              disableAR: "Вимкнути автопродовження для IP",
              descriptionDisableAR:
                "Проксі будуть активні до кінця терміну дії і не будуть продовжені на наступний період"
            },
            exportModal: {
              description: "Будь ласка, використовуйте шаблон для вивантаження вашого проксі."
            },
            replacement: {
              noun: "Заміна",
              title: "Запит на заміну IP",
              description: "Виберіть одну з причин або детально опишіть свій варіант.",
              question: "Чому ви хочете замінити IP?",
              reasons:
                "Не працює,Некоректна локація,Хочу змінити мережу,Низька швидкість,Ваш варіант",
              warning: "Напишіть причину заміни IP",
              success: "Заявку успішно надіслано",
              rejected: "Не вдалося надіслати заявку",
              selectIp: "Виберіть зі списку IP, які хочете замінити."
            },
            authorization: {
              noun: "Авторизація",
              title: "Авторизація проксі",
              descriptionLogin: "Ваш логін і пароль для авторизації до цих проксі.",
              descriptionIps:
                "Тут відображені дані авторизації проксі. За необхідності ви можете створювати та керувати своїм білим списком IP у будь-який час.",
              addIp: "Додати IP",
              yourIp: "Ваш IP",
              ips: "IP-адреси",
              success: "Зміни застосовані",
              rejected: "Зміни не збережені"
            }
          },
          table: {
            issueDate: "Дата видачі",
            expirationDate: "Дата закінчення",
            ip: "IP",
            activeFrom: "Активний з",
            activeBy: "Активний до",
            ipStatus: "Стаус IP",
            reboot: "Ротацiя",
            everyTimeMinute: "кожні {{time}} хв.",
            goal: "Цiль",
            protocol: "Протокол",
            port: "Порт",
            portSocks: "Socks порт",
            portProxy: "Proxy порт",
            status: "Статус IP",
            ACTIVE: "Активний"
          }
        },
        newOrder: {
          heading: "Нове замовлення"
        },
        balance: {
          heading: "Ваш рахунок",
          form: {
            heading: "Поповнення балансу",
            inputLabel: "Сума поповнення",
            inputPlaceholder: "Введіть суму",
            selectLabel: "Спосіб оплати",
            warning: "Деякі платіжні способи можуть стягувати додаткову комісію під час оплати",
            btn: "Продовжити"
          },
          table: {
            orderDate: "Дата",
            orderSum: "Сума",
            orderStatus: "Статус",
            payAction: "Сплатити",
            noData: "Транзакцій не знайдено"
          },
          options: {
            fillBalance: "Поповнення баланса",
            historyTransactions: "Історія транзакцій"
          },
          status: {
            success: "Успішно",
            failed: "Не успішно"
          }
        },
        paymentHistory: {
          heading: "Історія платежів",
          table: {
            orderCompound: "Склад замовлення:",
            amountCurrency: "Сума/Валюта:",
            status: "Статус:",
            buy: "Сплатити"
          },
          paymentActions: {
            ORDER_BUY: "Замовлення проксi",
            ORDER_EXTEND: "Продовження проксi",
            REPLENISHMENT: "Поповнення балансу"
          }
        },
        actionsHistory: {
          heading: "Історія дій",
          table: {
            replacementDate: "Дата заміни:",
            replacementTime: "Час заміни:",
            replacedWith: "Замінено на:"
          }
        },
        promocodes: {
          heading: "Промокоди",
          more: "Показати ще"
        },
        subscription: {
          heading: "Підписка",
          successMessage:
            "Пiдписки змiненi успiшно. Будь-ласка, Перевiрте свiй email для пiдтвердження",
          errorMessage: "Сталася помилка"
        },
        developersApi: {
          heading: "API",
          description: {
            apiKey: "Нікому не передавайте цей ключ.",
            accessIpAddresses:
              "Доступ через API можна надавати лише з указаних IP-адрес. Ви можете вказати до 3 IP-адрес, розділених комами. Зверніть увагу, що протягом 1 хвилини дозволяється надсилати не більше 60 запитів API. Якщо ліміт перевищено? додаткові запити призведуть до повідомлення про помилку."
          },
          documentation: {
            label: "Прочитати документацію",
            read: "Читати"
          }
        }
      },
      tools: {
        myIp: {
          heading: "Мій IP",
          description: `
          <p>
            Сховайте свою IP-адресу з персональними проксі від Proxy-IPv4! Захистіть особисті
            дані, залишайтеся анонімними, приховуйте своє місцезнаходження та обходьте блокування
            сайтів з проксі-серверами!
          </p>
          <p>І це лише мала частина інформації, яку можна відстежити за вашою IP-адресою:</p>
        `,
          seo: "",
          table: {
            yourComputerName: "Ім'я вашого комп'ютера:",
            operatingSystem: "Операційна система:",
            yourBrowser: "Ваш браузер:",
            yourLocation: "Ваше місцезнаходження:",
            yourProvider: "Ваш провайдер:",
            proxy: "Проксі:",
            dataProtection: "Захист даних:",
            ipInBlacklists: "IP в Blacklists:"
          }
        },
        pingIp: {
          heading: "Пінг IP",
          description: `
          <p>
            Натисніть на кнопку «Вставити мою IP-адресу» або використовуйте той IP або сайт, до
            якого необхідно перевірити пінг. Виберіть 3–5 тестових пакетів та натисніть кнопку
            «Пінг IP». Ви побачите потрібне значення у розділі «time=».
          </p>
          <p>
            Пінг вважається нормальним, коли у межах 40-110 мс. Від 110-210 мс ви почнете
            випробовувати проблеми в онлайн-іграх, але на відвідуванні сайтів це практично не
            позначиться. Якщо тайм-аут перевищує 110 мс, то це привід задуматися про якість роботи
            вашого провайдера.
          </p>
        `,
          note: "* Якщо результат - «Хост не працює», значить, виконується перевірка брандмауера або маршрутизатора та блокуючі IP-адреси.",
          seo: ""
        },
        checkEvercookie: {
          heading: "Перевірка Evercookie",
          description: `
          <p>
            Натисніть кнопку, щоб створити evercookie. Ваша анонімність не буде порушена: cookie -
            це випадкове число від 1 до 1000, яке ми використовуємо для тестування evercookies. Ми
            не відстежуємо вас за його допомогою.
          </p>
        `,
          createEvercookie: "Створити Evercookie",
          searchEvercookie: "Пошук Evercookie",
          seo: ""
        },
        proxyChecker: {
          heading: "Проксі-чекер",
          description: `
          <p>
            Проксі-чекер онлайн для перевірки валідності проксі-серверів. Аналіз проксі або групи
            адрес на доступність, країну, швидкість, анонімність, тип. Проксі чекер онлайн для
            перевірки валідності проксі-серверів Аналіз проксі або групи адрес на доступність,
            країну, швидкість, анонімність, тип.
          </p>
        `,
          proxyList: "Список проксі",
          table: {
            ip: "IP адреса:",
            port: "Порт:",
            country: "Країна:",
            speed: "Швидкість:",
            type: "Тип:",
            anonymity: "Анонімність:",
            state: "Стан:"
          },
          formatNote: {
            label: "У якому форматі додавати проксі в чекер?",
            publicText:
              "Якщо у вас є <b>публічні проксі</b> (без імені користувача та пароля), тоді <b>IP:PORT</b>  ",
            privateText:
              "Якщо у вас є <b>приватні проксі</b> (з авторизацією за іменем користувача та паролем), то <b>USER:PASS@IP:PORT</b>  ",
            psText: "Якщо ви купили проксі у нас, вони приватні!"
          },
          seo: ""
        },
        myAnonymity: {
          heading: "Моя анонімність",
          widgets: {
            caption: "Всі відвідувані вами сайти визначають ваші дані як:"
          },
          description:
            "Ми можемо перевірити точність цієї інформації, чи дійсно вона відповідає дійсності, чи не використовуєте проксі, анонімайзер, VPN сервер, Tor або інші засоби анонімізації.",
          seo: "",
          table: {
            httpProxyHeaders: "Заголовки HTTP proxy:",
            openHttpProxyPorts: "Відкриті порти HTTP proxy:",
            openWebProxyPorts: "Відкриті порти web proxy:",
            openVpnPorts: "Відкриті порти VPN:",
            suspiciousHostname: "Підозріла назва хоста:",
            differenceInTimeZonesBrowserAndIp: "Різниця в часових зонах (браузера та IP):",
            ipAffiliationWithTheTorNetwork: "Приналежність IP до мережі Tor:",
            browserTurboMode: "Режим браузера Turbo:",
            affiliationWithIpHostingProvider: "Приналежність IP хостинг-провайдеру:",
            webProxyDefinitionJsMethod: "Визначення web proxy (JS метод):",
            tunnelDefinitionTwoWayPing: "Визначення тунелю (двосторонній пінг):",
            dnsLeak: "Витік DNS:",
            vpnFingerprint: "VPN fingerprint:",
            ipLeakViaWebRTC: "Витік IP через WebRTC:",
            ipInBlacklists: "IP в Blacklists:"
          }
        },
        checkWebrtc: {
          heading: "Перевірка WebRTC",
          description: `
          <p>
            Перевірка на витік через WebRTC. Ваші дані можуть бути під загрозою, навіть якщо ви
            підключено до VPN.
          </p>
        `,
          widgets: {
            ip: "IP адреса:",
            type: "Тип:",
            status: "Статус:"
          },
          restartTest: "Перезапустити тест на витік WebRTC",
          table: {
            ip: "IP адреса:",
            type: "Тип:",
            status: "Статус:"
          },
          seo: ""
        },
        portScanner: {
          heading: "Сканер портів",
          description: `
          <p>
            Перевірка відкритих портів за допомогою сканера показує які порти відкриті на вашому або
            чужому комп&apos;ютері/сайті та за що вони відповідають. Перевірка проводиться через
            сканер nmap та показує, наскільки відкритий доступ до пк/сайту для зовнішнього
            світу.Перевірка проводиться через сканер nmap і показує, наскільки відкритий ваш доступ
            до ПК/сайту зовнішнього світу.
          </p>
        `,
          note: "* Якщо результат - «Хост не працює», значить, виконується перевірка брандмауера або маршрутизатора та блокуючі IP-адреси.",
          optionsLabel: "Типи портів, що скануються:",
          seo: ""
        },
        fingerprintScanner: {
          heading: "Сканер Fingerprint браузера",
          description: `
          <p>
            Відбитки браузера є унікальним зліпком з налаштувань вашого комп&apos;ютера, а
            спеціальні механізми сайтів можуть стежити за вами.
          </p>
        `,
          widgets: {
            fingerprint: "Відбиток браузера (Browser Fingerprint):"
          },
          seo: "",
          table: {
            location: "Місцезнаходження:",
            dns: "DNS:",
            provider: "Провайдер:",
            host: "Host:",
            os: "OS:",
            browser: "Браузер:",
            plugin: "Плагін:",
            status: "Статус:",
            userAgent: "User Agent:",
            userAgentJs: "User Agent (метод JS):"
          }
        },
        ipTracing: {
          heading: "Трасування IP",
          description: `
          <p>
            Завдяки сервісу, який розміщено на цій сторінці, вам доступна безкоштовна та практично
            моментальне трасування IP адреси або сайту. Вона допоможе вам виконати мережеву
            діагностику, що визначає швидкість надсилання запитів через сервери в інтернеті при
            відкритті веб-ресурсу у браузері. За допомогою трасування ви зможете визначити причини
            можливих проблем, пов&apos;язаних із повільним завантаженням сайту.
          </p>
        `,
          optionsLabel: "Показати маршрут пакету країнами:",
          seo: ""
        }
      },
      auth: {
        login: {
          heading: "Увійти в особистий кабінет",
          agreementPartOne: "Користуючись сайтом, Ви погоджуєтесь з",
          agreementPartSecond: "політикою конфіденційності",
          forgot: "Забули пароль?",
          recoverPassword: "Відновити пароль",
          register: "Реєстрація",
          login: "Увiйти"
        },
        register: {
          heading: "Реєстрація"
        },
        forgot: {
          heading: "Відновлення пароля"
        }
      },
      common: {
        error: {
          title: "Ой... Щось пішло не так.",
          description: `<p>Просимо вибачення за незручність, наші спеціалісти вже отримали повідомлення щодо цієї помилки!</p>
            <p>Не турбуйтесь, вони зараз швидко все виправлять:)</p>`
        }
      }
    },
    shared: {
      reviews: {
        heading: "Відгуки",
        button: "Подивитися всі відгуки",
        moreButton: "Показати бiльше",
        action: "Прочитати відгук"
      },
      hasPromocode: "Є промокод?",
      address: {
        title: "Де ми знаходимося"
      },
      contacts: {
        title: "Як зв'язатися з нами"
      },
      socials: {
        title: "Наші соціальні мережі"
      },
      workingHours: {
        title: "Годинник роботи",
        aroundTheClock: "Цілодобово",
        withoutWeekends: "Без вихідних"
      },
      cabinet: "Особистий кабінет",
      orderModal: {
        title: "Форма замовлення",
        description:
          "IP-адреси будуть підключені автоматично і будуть відображатися в особистому кабінеті після оплати.",
        ipTooltip: "Тiльки статичний IP",
        ipValidationError: "Ця IP-адреса не може використовуватися для авторизації"
      },
      faq: {
        heading: "Часті питання",
        noData: "Питання не знайденi"
      },
      tools: {
        yourIp: "Ваш IP:",
        yourAnonymity: "Ваша анонімність:",
        result: "Результат перевірки:",
        yourGeo: "Ваша геопозиція:",
        warning: "<span>WebRTC включений</span> і може розкрити вашу реальну IP-адресу!"
      },
      table: {
        options: {
          viewComment: {
            label: "Переглянути",
            title: "Перегляд коментаря",
            caption: "Коментар",
            error: "Сталася помилка при загрузцi коментаря",
            empty: "Немає коментаря для цого IP"
          },
          deleteComment: {
            label: "Видалити",
            title: "Видалення коментаря",
            text: "Ви впевнені, що хочете видалити коментар?",
            success: "Коментар успiшно видалений",
            error: "Сталася помилка при видаленнi коментаря"
          },
          addComment: {
            label: "Додати",
            title: "Додавання коментаря",
            editTitle: "Змiна коментаря",
            success: "Коментар успішно додано",
            error: "Сталася помилка при додаваннi коментаря"
          }
        },

        show: "Показано"
      }
    },
    currencies: {
      rub: "руб.",
      usdUpper: "$"
    },
    form: {
      labels: {
        proxyType: "Тип проксі:",
        country: "Країна:",
        rentalPeriod: "Термін оренди:",
        proxyCount: "Кількість:",
        proxyGoal: "Мета:",
        proxyService: "Послуга:",
        authType: "Спосіб авторизації:",
        paymentType: "Спосіб оплати:",
        name: "Логін:",
        email: "Email:",
        oldEmail: "Старий email:",
        newEmail: "Новий email:",
        confirmNewEmail: "Підтвердіть новий email:",
        password: "Пароль:",
        oldPassword: "Старий пароль:",
        newPassword: "Новий пароль:",
        confirmNewPassword: "Підтвердіть новий пароль:",
        promocode: "Промокод:",
        selectAll: "Вибрати всі",
        ipOrDomain: "Введіть IP адресу або домен",
        testPingPackages: "Тестові пакети ping",
        verificationResult: "Результат перевірки:",
        commentToIp: "Коментар до IP:",
        proxyProtocol: "Протокол:",
        emailLinkSuccess: "Ссилка для відновлення паролю відправлена",
        mobileOperator: "Оператор зв'язку:",
        modemRotationMin: "Оновлення IP:",
        proxyList: "Список проксі:",
        nonWorking: "Не робочий",
        domainsInIp: "Домени в IP"
      },
      placeholders: {
        promocode: "Введіть промокод",
        noOptions: "Варіанти відсутні",
        firstSelectCountry: "Виберіть країну",
        firstSelectMobileOperator: "Виберіть мобільного оператора",
        yourEmail: "Ваш e-mail",
        enterPassword: "Введіть пароль",
        enterEmail: "Введіть e-mail",
        newPassword: "Новий пароль",
        confirmNewPassword: "Підтвердьте новий пароль",
        selectPaymentMethod: "Оберіть спосіб оплати",
        paymentMethod: "Спосіб оплати",
        enterCustomGoal: "Ціль використання",
        selectOperator: "Виберіть оператора",
        selectRentPeriod: "Виберіть термін оренди",
        selectRotation: "Виберіть оновлення ip",
        selectProtocol: "Виберіть протокол",
        personalExportPattern: "Персональний формат вивантаження",
        yourName: "Ваше ім'я",
        companyName: "Назва компанії",
        emailOrOther: "E-mail чи інше"
      },
      actions: {
        login: "Увійти",
        register: "Зареєструватися",
        buyProxy: "Купити проксі",
        makeOrder: "Зробити замовлення",
        sendMessage: "Надіслати повідомлення",
        changePassword: "Змінити пароль",
        changeEmail: "Змінити email",
        applyChange: "Застосувати зміну",
        extend: "Продлити",
        startScan: "Почати сканування",
        cancel: "Скасування",
        delete: "Видалити",
        add: "Додати",
        restore: "Відновити",
        create: "Створити",
        edit: "Змiнити",
        goToMainPage: "Повернутись на головну",
        submit: "Надіслати",
        generateKey: "Згенерувати ключ"
      },
      rules: {
        name: {
          required: "Введіть логін",
          invalid: "Неправильний логін"
        },
        email: {
          required: "Пошта обов'язкова",
          invalid: "Некоректна пошта",
          match: "Пошти не збігаються"
        },
        password: {
          required: "Пароль обов'язковий",
          min: "Мінімум символів",
          match: "Паролі не однакові",
          notAllowed: "Паролі містять заборонені символи",
          invalid: "Некоректний пароль",
          passwordContainsSpaces: "Пароль містить пробіли"
        },
        apiKey: {
          required: "Необхідний ключ API"
        },
        accessIpAddresses: {
          invalid: "Неправильна IP-адреса",
          maxCount: "Ви можете вказати до 3 IP-адрес"
        },
        ipOrDomain: {
          required: "Потрібен IP або домен",
          invalid: "Неправильний IP або домен"
        },
        proxyList: {
          required: "Потрібен список проксі",
          invalid: "Неправильний список проксі",
          copiesFound: "Знайдено копії"
        },
        quantity: {
          minQuantity: "Мінімальна кількість {{quantity}}"
        },
        personalExportPattern: {
          required: "Персональний формат вивантаження обов'язковий",
          requiredVariables: "Обов'язкові змінні",
          atLeastOneVariableMustBeUsed: "Потрібно використовувати принаймні одну змінну",
          invalidVariables: "Недійсні змінні"
        },
        common: {
          hasUrl: "Поле містить URL-адресу",
          required: "Поле обов'язкове",
          min: "Мінімальна кількість символів у полі має бути {{min}}",
          max: "Поле не повинно мати більше {{max}} символів",
          oneOf: "Поле повинно мати одне з наступних значень"
        }
      },
      serverErrors: {
        balance: "Недостатньо коштів",
        promocode: "Промокод не є дійсним",
        default: "Сталася помилка",
        priceCalculation: "Помилка підрахунку ціни",
        minQuantityTen: "Мінімальна кількість для замовлення 10",
        ips: "Неможливо продовжити проксі зі спливним терміном дії",
        minPrice: "Мінімальна ціна для платіжної системи {{price}}",
        minAmountForPaymentSystem: "Мінімальна сума для платіжної системи {{amount}}",
        incorrectAmount: "Неправильна сума",
        incorrectPaymentSystem: "Неправильна система оплати",
        incorrectLanguageCode: "Неправильний код мови",
        incorrectOldPassword: "Старий пароль неправильний",
        incorrectNewPassword: "Новий пароль некоректний",
        incorrectGoal: "Неправильна мета використання",
        passwordEquals: "Новий пароль має відрізнятися від старого",
        incorrectEmail: "Будь ласка, введіть існуючий емейл"
      }
    },
    other: {
      piece: "IP",
      ips: "IPs",
      pieces: "штук",
      from: "Від",
      discount: "Знижка:",
      price: "Вартість:",
      totalSum: "Загальна сума:",
      countPiece: "Кількість:",
      selected: "Вибрано:",
      proxy: "проксі.",
      logout: "Вихід",
      showAll: "Показати все",
      byLink: "За посиланням",
      insertList: "Вставити списком",
      insertListDescription: "Напишіть перелік IP, які хочете продовжити",
      apply: "Застосувати",
      cancel: "Скасувати",
      send: "Надіслати",
      save: "Зберегти",
      login: "Логін",
      password: "Пароль",
      noMatchesIp: "Співпадінь не знайдено",
      foundIp: "Знайдено {{count}} IP",
      foundIpFrom: "Знайдено {{endCount}} з {{startCount}} введених IP"
    },
    export: {
      ownFormat: "Власний формат",
      txt: "txt",
      excel: "excel",
      window: "У новому вікні"
    },
    days: "днів",
    copyMessageSuccess: "Успішно скопійовано",
    copyMessageError: "Не вдалося скопіювати",
    passwordChangeSuccess: "Пароль успішно змінено",
    loginUnauthorized: "Неправильні дані для входу",
    serverError: "Помилка на сервері",
    userNotFound: "Користувач не знайдений",
    userExist: "Користувач с такою адресою вже зареєстрований",
    registerSuccess: "Реєстрація успішна",
    orderCreateError: "Помилка створення замовлення. Спробуйте ще раз",
    ipAddressesSaved: "IP-адреси збережені",
    repayError: "Виникла помилка при обробці платежу",
    order: {
      messages: {
        success: {
          title: "Замовлення оформлено",
          description: "Ви успішно оформили замовлення, а тут буде інформація на другий рядок."
        },
        error: {
          title: "Збій покупки",
          description:
            "Щось пішло не так, з незалежних від нас причин замовлення не було оформлено."
        }
      }
    },
    common: {
      goBack: "Повернутися назад",
      tryAgain: "Спробувати знову",
      iAgree: "Я погоджуюсь",
      toTheTerms: "з умовами",
      notUsed: "Не використовується",
      used: "Використовується",
      absent: "Відсутнє",
      noData: "Немає даних",
      active: "Активний",
      notActive: "Не активний",
      on: "Увімкнено",
      off: "Вимкнено",
      cancel: "Скасувати",
      canceled: "Скасовано",
      timeout: "Час вийшов",
      potentialLeak: "Потенційний витік",
      leakageThreat: "Загроза витоку",
      potentialThreat: "Потенційна загроза",
      threatsAreAbsent: "Загрози відсутні",
      browser: "Браузер",
      yes: "Так",
      no: "Ні",
      detected: "Виявлено",
      notDetected: "Не виявлено",
      copy: "Копіювати",
      millisecondShort: "мс.",
      shown: "Показано",
      postscriptShort: "P.S.",
      read: "Читати",
      order: "Замовити",
      quantity: "Кількість",
      export: "Експорт",
      example: "Приклад",
      availableVariables: "Доступні змінні",
      byLink: "За посиланням",
      byTime: "За часом",
      rotation: "Ротація",
      contactUs: "Зв'язатися з нами",
      enterprise: "Підприємство",
      other: "Інше",
      email: "Email",
      maxCharacters: "Макс. {{max}} символів"
    },
    discountOfferTooltip: {
      addButton: "Додайте",
      discountOfferText: "{{count}} IP, це дешевше на {{discountInPercent}}%"
    },
    messages: {
      logInWithYourPreviousCredentials: "Увійдіть, використовуючи свої попередні облікові дані",
      linkToTheChangeHasBeenSentByEmail: "Посилання на зміну було відправлено на емейл",
      emailAlreadyInUse: "E-mail вже використовується",
      emailChanged: "E-mail змінено",
      noUserFoundWithThisEmail: "Користувач з цією електронною поштою не знайдено",
      userNotFoundByHash: "Користувач не знайдений по хешу",
      newEmailEqualsUserEmail: "Нова електронна адреса відповідає електронній адресі користувача",
      templateSaved: "Шаблон збережено",
      failedToSaveTemplate: "Не вдалося зберегти шаблон",
      failedToLoadTemplate: "Не вдалося завантажити шаблон",
      captchaFailed: "Не вдалося завершити перевірку CAPTCHA",
      temporaryEmails: "Вибачте, використання тимчасових електронних адрес неможливе.",
      problemEmail:
        "Виникла помилка з вашим емейлом. Можливо, емейл не існує або використовується тимчасовий поштовий сервіс.",
      pleaseResubmitTheForm: "Будь ласка, повторіть надсилання форми",

      promoCodeNotExist: "Промокоду не існує",
      promoCodeNotActive: "Промокод не активний",
      promoCodeInvalid: "Промокод недійсний",
      promoCodeConditionInvalid: "Промокод не відповідає умовам"
    }
  }
};
