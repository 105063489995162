import { Box } from "@mui/material";
import { CopyIcon } from "../../../components/icons/index";

import { CopyToClipboard } from "../copy-to-clipboard/copy-to-clipboard";
import { style } from "./table.style";

export const TableLink = ({ to, children }) => (
  <Box sx={style.tableLink} className="table-link">
    <Box component="a" href={to} target="_blank" rel="noreferrer">
      {children}
    </Box>
    <CopyToClipboard text={children}>
      <Box sx={style.tableLink.icon}>
        <CopyIcon className="table-link__icon" />
      </Box>
    </CopyToClipboard>
  </Box>
);
