import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container } from "../../../../components/common/container/container";
import HeadingWithSeo from "../../../../components/common/heading-with-seo/heading-with-seo";
import { Tabs } from "../../../../components/ui/tabs/tabs";
import { useTranslateOptions } from "../../../../hooks/use-translate-options";
import { getProxyState } from "../../../../store/selectors";

import { Table } from "./components/table";
// import "./hero.scss";
import { style } from "./hero.style";

export const Hero = () => {
  const { proxyOptions } = useSelector(getProxyState);
  // Translations
  const { t } = useTranslation();
  const buyProxyOptions = useTranslateOptions(proxyOptions, t("buyProxy"));

  return (
    <Box sx={style.priceHero} className="price-hero">
      <Container>
        <Box sx={style.priceHero.wrapper} className="price-hero__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">
            {t("pages.app.price.heading")}
          </HeadingWithSeo>
          {proxyOptions.length ? (
            <Tabs name="priceTabs" tabs={buyProxyOptions} />
          ) : (
            <Skeleton variant="rounded" sx={style.tabsSkeleton} />
          )}
          <Table />
        </Box>
      </Container>
    </Box>
  );
};
