import * as yup from "yup";
import { PASSWORD_MIN, PASSWORD_REG } from "../constants";

export const RegistrationSchema = (t) =>
  yup.object().shape({
    registrationEmail: yup.string().required(t.rules.email.required).email(t.rules.email.invalid),
    registrationPassword: yup.string().required(t.rules.password.required)
      .matches(PASSWORD_REG, t.rules.password.passwordContainsSpaces)
      .min(PASSWORD_MIN, `${t.rules.password.min} ${PASSWORD_MIN}`)
  });
