export const style = {
  container: {
    backgroundColor: "#FCFCFE",
    padding: "32px",
    borderTop: "1px solid #E6EBF6",

    contain: "content",
    borderBottomLeftRadius: "3rem",
    borderBottomRightRadius: "3rem",

},
  title: {
    fontSize: "18px",
    fontWeight: "600",
    paddingBottom: "1.2rem",
    color: "var(--clr-text-500)"
  },
  description: {
    fontSize: "16px",
    fontWeight: "400",
    paddingBottom: "1.6rem",
    lineHeight: '140%',
  },
  button: {

  }
}
