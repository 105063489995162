import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ContactsList } from "../../../../components/common/contacts-list/contacts-list";
import { Container } from "../../../../components/common/container/container";
import { SocialsList } from "../../../../components/common/socials-list/socials-list";
import { WorkingHours } from "../../../../components/common/working-hours/working-hours";
import { Button } from "../../../../components/ui/button/button";
import { Heading } from "../../../../components/ui/heading/heading";

import { style } from "./contacts.style";

export const Contacts = () => {
  // Translations
  const { t } = useTranslation();

  return (
    <Box sx={style.thanksContacts} className="thanks-contacts">
      <Container>
        <Box sx={style.wrapper} className="thanks-contacts__wrapper">
          <Box sx={style.left} className="thanks-contacts__left">
            <Heading tag="h2" variant="h2">
              {t("pages.app.thanks.contacts.heading")}
            </Heading>
            <Box sx={style.text} className="thanks-contacts__text">
              {t("pages.app.thanks.contacts.description")}
            </Box>
            <Box sx={style.sites} className="thanks-contacts__sites">
              <Button type="link" to="https://google.com">
                zismo.biz
              </Button>
              <Button type="link" to="https://google.com">
                webmasters.ru
              </Button>
              <Button type="link" to="https://google.com">
                lolzteam.net
              </Button>
            </Box>
          </Box>
          <Box sx={style.right} className="thanks-contacts__right">
            <ContactsList />
            <SocialsList />
            <WorkingHours />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
