import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container, OrderModal, SeoBlockField } from "../../../../components/common";
import { SelectList } from "../../../../components/common/select-list/select-list";
import { Heading } from "../../../../components/ui";
import { useActions, useLang, useSeoBlock } from "../../../../hooks";
import useProxyCountryOrType from "../../../../hooks/use-proxy-country-or-type";
import { ApiService } from "../../../../services";
import { getProxyState } from "../../../../store/proxy/proxy.selectors";
import { getRentState } from "../../../../store/rent/rent.selectors";
import { getCountryFlagIso2 } from "../../../../utils/helpers/get-country-flag";

import CountriesPrices from "./countries-prices/countries-prices";
import { style } from "./prices.style";

const Prices = (props) => {
  const { proxyType } = props;
  const { t } = useTranslation();
  const { country: countrySlug } = useProxyCountryOrType();

  const lang = useLang();

  const { getCountriesPricesByProxyType } = useActions();
  const { data: seoblocks } = useSeoBlock(["proxyPageCountriesTable"]);

  const { proxies, countriesPrices } = useSelector(getProxyState);
  const { periods } = useSelector(getRentState);

  const [selectedRentPeriodIndex, setSelectedRentPeriodIndex] = useState(0);

  const [modalOrder, setModalOrder] = useState({
    open: false,
    data: null,
    quantityOptions: []
  });

  const proxyTypeName = proxyType?.toUpperCase();
  // Set Rent periods options
  const rentPeriodsOptions = useMemo(() => {
    if (periods[proxyTypeName]?.length) {
      const options = periods[proxyTypeName].map((e) => ({
        key: e.id,
        label: e.namesByLocale[lang]
      }));

      return options;
    } else {
      return [];
    }
  }, [periods, lang]);
  // Fetch CountriesPricesByProxyType
  useEffect(() => {
    if (!countriesPrices.data[proxyTypeName]) {
      getCountriesPricesByProxyType(proxyTypeName);
    }
  }, [proxies, proxyType]);

  const list = useMemo(() => {
    if (proxies[proxyTypeName]?.countries?.length && countriesPrices.data[proxyTypeName]?.length) {
      const rows = countriesPrices.data[proxyTypeName]
        ?.map(({ countryId, rentPeriods }) => {
          const country = proxies[proxyTypeName]?.countries.find(({ id }) => id === countryId);

          if (!country) return null;

          const pricesByQuantity = rentPeriods.find(
            (e) => e?.rentPeriodId === rentPeriodsOptions[selectedRentPeriodIndex]?.key
          )?.pricesByQuantity;

          return {
            id: countryId,
            code: country.code,
            name: country.namesDependingOnLocale[lang],
            image: getCountryFlagIso2(country.code),
            // Remove from list if quantity = 1
            info: pricesByQuantity ?? [],
            currency: country.priceCurrencyCode
          };
        })
        .filter(Boolean);

      return rows;
    } else {
      return [];
    }
  }, [
    proxies,
    lang,
    proxyType,
    countrySlug,
    countriesPrices,
    rentPeriodsOptions,
    selectedRentPeriodIndex
  ]);

  const getQuantityOptions = async (proxyTypeId, countryId) => {
    try {
      const response = await ApiService.getAllPackageProxyQuantity({ proxyTypeId, countryId });

      return response.data ?? null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const handlerBuy = async (data) => {
    const proxy = proxies[proxyType.toUpperCase()];

    const quantityOptionsValues = await getQuantityOptions(proxy?.proxyTypeId, data.country?.id);
    const quantityOptions = quantityOptionsValues
      ? quantityOptionsValues.map((e) => ({ key: e, label: `${e} ${"other.ips"}` }))
      : data.country?.info?.map((e) => ({
          key: e.quantity,
          label: `${e.quantity} ${t("other.ips")}`
        }));

    setModalOrder(() => ({
      open: true,
      data: {
        proxyType: proxy?.type ?? "",
        proxyTypeId: proxy?.proxyTypeId ?? "",
        proxyCountry: data.country?.id ?? "",

        proxyRentalPeriod: rentPeriodsOptions[selectedRentPeriodIndex]?.key,
        proxyCount: data?.quantity
      },
      quantityOptions: quantityOptions
    }));
  };

  if (!list?.length) {
    return null;
  }

  const proxyName = proxies[proxyType.toUpperCase()]?.proxyTypeName ?? proxyType.toUpperCase();

  return (
    <>
      <OrderModal
        id="proxy-prices"
        quantityOptions={modalOrder.quantityOptions}
        visible={modalOrder.open}
        initialData={modalOrder.data}
        cancelHandler={() => {
          setModalOrder((prevState) => ({ ...prevState, open: false }));
        }}
      />
      <Box sx={style.wrapper}>
        <Container>
          <Box sx={style.head}>
            <SeoBlockField
              defaultValue={t("pages.app.home.geos.heading")}
              field={seoblocks?.proxyPageCountriesTable?.title}
            >
              {(data) => (
                <Heading tag="h2" variant="h2">
                  {data.replace("{{proxyName}}", proxyName)}
                </Heading>
              )}
            </SeoBlockField>
            <Box sx={style.select}>
              <SelectList
                name={`rental-period`}
                selectedIndex={selectedRentPeriodIndex}
                options={rentPeriodsOptions}
                onChange={(data) => {
                  setSelectedRentPeriodIndex(
                    rentPeriodsOptions.findIndex((e) => e.key === data.key)
                  );
                }}
              />
            </Box>
          </Box>
          <CountriesPrices loading={countriesPrices.loading} list={list} onBuy={handlerBuy} />
        </Container>
      </Box>
    </>
  );
};

export default Prices;
