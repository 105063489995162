import { appTheme } from "../../../theme"

export const style = {
  cabinetSubscription: {
    position: 'relative',
    height: "100%",
    '.heading': {
      marginBottom: '4rem',
      // textAlign: 'center',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      },

      [appTheme.breakpoints.down('bp992')]: {
        textAlign: 'center'
      }
    },

    [appTheme.breakpoints.down('bp992')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },

  card: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '3rem',
    padding: '3rem',
    width: 'min(43.5rem, 100%)',
    backgroundColor: 'var(--clr-default-100)',

    '&:not(:last-child)': {
      marginBottom: '3rem',
    },

    '& h3': {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '160%'
    },

    '.form-checkbox': {
      marginBottom: '1rem',
    },

    list: {
      listStyle: 'disc',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '2.3rem',
    },

    item: {
      fontWeight: '400',
      fontSize: '1.4rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',
    },

    [appTheme.breakpoints.down('bp576')]: {
      paddingTop: '2.4rem',
      paddingRight: '3rem',
      paddingBottom: '2.4rem',
      paddingLeft: '3rem',
    }
  },

  actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 20px',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 0px 80px rgba(0, 0, 0, 0.07)',
    borderTop: 'solid 2px #517ff5',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    saveBtn: {
      width: '15rem',
      marginRight: '20px',
      [appTheme.breakpoints.down('bp576')]: {
        marginRight: '10px',
        width: '10rem',
        '.MuiButtonBase-root': { minHeight: '40px' }
      }
    },
    cancelBtn: {
      width: '15rem',
      [appTheme.breakpoints.down('bp576')]: {
        width: '10rem',
        '.MuiButtonBase-root': { minHeight: '40px', padding: '6px 24px' }
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      justifyContent: 'end',
      padding: '16px 20px'
    }
  }
}
