import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import useCurrentSeo from "../../../hooks/use-current-seo";
import { getReviewState } from "../../../store/selectors";
import MediaQuery from "../media-query/media-query";

import { style } from "./reviewList.style";
import { ReviewsCard } from "./reviews-card/reviews-card";
import { ReviewsSlider } from "./reviews-slider/reviews-slider";

import "./reviews-list.scss";

export const ReviewsList = ({ reviews, mobileView = "slider", view = "" }) => {
  const { reviewsPreviewLength } = useSelector(getReviewState);

  const isMobileViewSlider = mobileView === "slider";

  const { title } = useCurrentSeo();

  const dataItemReviewed = {
    name: "Youproxy.io",
    description: title
  };

  return (
    <>
      <MediaQuery {...(isMobileViewSlider ? { minWidth: "bp767" } : {})}>
        {!view ? (
          <Box component="ul" sx={style.reviewsList} className="reviews-list">
            {reviews?.map((review) => (
              <li key={review.id}>
                <ReviewsCard dataItemReviewed={dataItemReviewed} review={review} />
              </li>
            ))}
          </Box>
        ) : (
          <Box component="ul" sx={style.reviewsList} className="reviews-list">
            {reviews?.map((review, index) =>
              index > reviewsPreviewLength - 1 ? null : (
                <li key={review.id}>
                  <ReviewsCard dataItemReviewed={dataItemReviewed} review={review} />
                </li>
              )
            )}
          </Box>
        )}
      </MediaQuery>

      {isMobileViewSlider ? (
        <MediaQuery maxWidth="bp767">
          <ReviewsSlider dataItemReviewed={dataItemReviewed} reviews={reviews} />
        </MediaQuery>
      ) : null}
    </>
  );
};
