
export const UpDownIcon = ({ sortDirection = null }) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={sortDirection === "asc" ? 0.5 : 1}
      d="M11.4 1C11.4 0.779086 11.2209 0.6 11 0.6C10.7791 0.6 10.6 0.779086 10.6 1L11.4 1ZM10.7172 11.2828C10.8734 11.4391 11.1266 11.4391 11.2828 11.2828L13.8284 8.73726C13.9846 8.58105 13.9846 8.32778 13.8284 8.17157C13.6722 8.01536 13.419 8.01536 13.2627 8.17157L11 10.4343L8.73726 8.17157C8.58105 8.01536 8.32778 8.01536 8.17157 8.17157C8.01536 8.32778 8.01536 8.58105 8.17157 8.73726L10.7172 11.2828ZM10.6 1L10.6 11L11.4 11L11.4 1L10.6 1Z" />
    <path
      opacity={sortDirection === "desc" ? 0.5 : 1}
      d="M2.6 11C2.6 11.2209 2.77909 11.4 3 11.4C3.22091 11.4 3.4 11.2209 3.4 11L2.6 11ZM3.28284 0.717157C3.12663 0.560947 2.87337 0.560947 2.71716 0.717157L0.171573 3.26274C0.0153629 3.41895 0.0153629 3.67222 0.171573 3.82843C0.327782 3.98464 0.581048 3.98464 0.737258 3.82843L3 1.56569L5.26274 3.82843C5.41895 3.98464 5.67222 3.98464 5.82843 3.82843C5.98464 3.67222 5.98464 3.41895 5.82843 3.26274L3.28284 0.717157ZM3.4 11L3.4 1L2.6 1L2.6 11L3.4 11Z" />
  </svg>
);
