export const LockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path
      d="M1.33301 10.5C1.33301 8.61438 1.33301 7.67157 1.91879 7.08579C2.50458 6.5 3.44739 6.5 5.33301 6.5H10.6663C12.552 6.5 13.4948 6.5 14.0806 7.08579C14.6663 7.67157 14.6663 8.61438 14.6663 10.5V10.5C14.6663 13.3284 14.6663 14.7426 13.7877 15.6213C12.909 16.5 11.4948 16.5 8.66634 16.5H7.333C4.50458 16.5 3.09037 16.5 2.21169 15.6213C1.33301 14.7426 1.33301 13.3284 1.33301 10.5V10.5Z"
      stroke="#517FF5"
      strokeWidth="1.5"
    />
    <path
      d="M11.3327 5.66667V4.83333C11.3327 2.99239 9.8403 1.5 7.99935 1.5V1.5C6.1584 1.5 4.66602 2.99239 4.66602 4.83333V5.66667"
      stroke="#517FF5"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="7.99967" cy="11.4999" r="1.66667" fill="#517FF5" />
  </svg>
);
