import { Box, ButtonBase } from "@mui/material";
import { style } from "./message-card.style";

// type === "order || "feedback"

export const MessageCard = ({ type = "order", iconPath = "", title, description, actionLabel, actionEvent }) => {

  const styles = [];
  const iconStyles = [];
  if (type === "order") {
    styles.push(style.message);
    iconStyles.push(style.icon);
  } else {
    styles.push(style.feedbackMessage);
    iconStyles.push(style.feedbackIcon);
  }

  return (
    <Box sx={styles} className="message-card">
      <Box sx={iconStyles} className="message-card__icon">
        <img
          src={iconPath}
          alt=""
          width={50}
          height={50}
          aria-hidden={true}
          loading="lazy"
        />
      </Box>

      <Box sx={style.title} className="message-card__title">{title}</Box>
      <Box sx={style.description} className="message-card__description">{description}</Box>
      <ButtonBase sx={style.action} className="message-card__action" onClick={actionEvent}>
        {actionLabel}
      </ButtonBase>
    </Box>
  );
};
