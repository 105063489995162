import { Box } from "@mui/material";
import { Heading } from "../../../../components/ui/heading/heading";
import { Modal } from "../../../../components/ui/modal/modal";

import { PARTNERS_LIST } from "../../../../utils/constants/partners.constants";
import { style } from "./partners.style";

export const PartnersModal = ({ visible, cancelHandler }) => (
  <Modal visible={visible} cancelHandler={cancelHandler}>
    <Box sx={style.modal} className="reviews-partners__modal">
      <Heading variant="h2">Источники отзывов</Heading>
      <Box component="ul" sx={style.list} className="reviews-partners__list">
        {PARTNERS_LIST.map((partner) => (
          <Box component="li" sx={style.item} key={partner.name} className="reviews-partners__item">
            <a href={partner.link} target="_blank" rel="noreferrer">
              <img
                src={partner.image}
                width={170}
                height={110}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  </Modal>
);
