import { useState } from "react";

import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { XMark } from "../../../../assets/images/icons/XMark";
import { InfoIcon } from "../../../../assets/images/icons/info-icon";
// import { useTranslation } from "../../../../hooks";
import IpInputMask from "../../ip-input-mask/ip-input-mask";

export const IpInput = ({ maskProps, style, cancelHandler, error }) => {
  const { t } = useTranslation();

  const [inFocus, setInFocus] = useState(false);

  return (
    <Box sx={style.container}>
      <Box
        sx={!error ? style.inputField : [style.inputField, style.error]}
        onClick={() => {
          setInFocus(true);
        }}
      >
        <IpInputMask {...maskProps} useFocus={() => [inFocus, setInFocus]} />

        <Box sx={style.close} onClick={cancelHandler}>
          <XMark />
        </Box>
      </Box>

      <Tooltip
        title={t("shared.orderModal.ipTooltip")}
        arrow
        sx={style.tooltip}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: style.tooltip
          },

          arrow: {
            sx: { color: "#fff" }
          }
        }}
      >
        <Box sx={style.infoBadge}>
          <InfoIcon />
        </Box>
      </Tooltip>
    </Box>
  );
};
