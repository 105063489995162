import { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container } from "../../../../components/common/container/container";
// import "./hero.scss";
import HeadingWithSeo from "../../../../components/common/heading-with-seo/heading-with-seo";
import MediaQuery from "../../../../components/common/media-query/media-query";
import { ReviewsList } from "../../../../components/common/reviews-list/reviews-list";
import { Button } from "../../../../components/ui/button/button";
import { ReviewsSkeleton } from "../../../../components/ui/skeletons/reviews-skeleton/reviews-skeleton";
import { useActions, useLang } from "../../../../hooks";
import { getReviewState } from "../../../../store/selectors";

import { style } from "./hero.style";

export const Hero = ({ handleOpen }) => {
  const lang = useLang();
  const [currentPage, setCurrentPage] = useState(1);

  // Redux state
  const { allReviews, reviewsPreviewLength } = useSelector(getReviewState);

  // Dispatch
  const { getReviewsByPage, removeReviews } = useActions();

  // Translations
  const { t } = useTranslation();

  const getReviews = useCallback(() => {
    getReviewsByPage({ page: currentPage - 1, size: reviewsPreviewLength, localeCode: lang });
    setCurrentPage((prev) => ++prev);
  }, [getReviewsByPage, currentPage, reviewsPreviewLength, lang]);

  const handleShowMore = () => {
    getReviews();
  };

  const showMOreVisile = allReviews?.data.length < allReviews?.totalElements;
  const showBtnDesktop = () => {
    if (showMOreVisile && allReviews.isLoading) {
      return null;
    } else if (showMOreVisile) {
      return (
        <Box className="reviews-hero__btn">
          <Button onClick={handleShowMore}>{t("shared.reviews.moreButton")}</Button>
        </Box>
      );
    } else {
      return null;
    }
  };

  const showBtnsMobile = () => {
    if (showMOreVisile && allReviews.isLoading) {
      return null;
    } else if (showMOreVisile) {
      return (
        <Box sx={style.buttons} className="reviews-hero__buttons">
          <Button onClick={handleOpen}>{t("pages.app.reviews.hero.sources")}</Button>
          <Button onClick={handleShowMore} variant="primary">
            {t("shared.reviews.moreButton")}
          </Button>
        </Box>
      );
    } else {
      return (
        <Box sx={style.buttons} className="reviews-hero__buttons">
          <Button onClick={handleOpen}>{t("pages.app.reviews.hero.sources")}</Button>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (lang !== allReviews.localeCode) {
      setCurrentPage(1);
    }
  }, [lang, allReviews.localeCode]);

  useEffect(() => {
    if (currentPage === 1 && lang !== allReviews.localeCode) {
      removeReviews();
      getReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, lang, allReviews.localeCode]);

  return (
    <Box sx={style.reviewsHero} className="reviews-hero">
      <Container>
        <Box sx={style.wrapper} className="reviews-hero__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">
            {t("pages.app.reviews.hero.heading")}
          </HeadingWithSeo>

          {allReviews?.data?.length ? (
            <>
              <ReviewsList reviews={allReviews?.data} mobileView="list" />
              {allReviews.isLoading ? <ReviewsSkeleton reviewsCount={4} /> : null}
              <MediaQuery minWidth={"bp576"}>{showBtnDesktop()}</MediaQuery>
              <MediaQuery maxWidth={"bp576"}>{showBtnsMobile()}</MediaQuery>
            </>
          ) : (
            <>
              {allReviews.isLoading ? (
                <ReviewsSkeleton reviewsCount={4} />
              ) : (
                <Box sx={style.noData}>{t("pages.app.reviews.noData")}</Box>
              )}
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};
