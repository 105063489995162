import { useEffect, useRef } from "react";

import { useLang } from "../../../../hooks";

export default function TrustpilotWidget() {
  const lang = useLang();
  const widgetRef = useRef(null);
  const foundTrustpilotWidget = window.Trustpilot && window.Trustpilot.loadFromElement;

  useEffect(() => {
    if (foundTrustpilotWidget) {
      if (widgetRef.current) {
        window.Trustpilot.loadFromElement(widgetRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!foundTrustpilotWidget) {
    return null;
  }

  return (
    <div
      ref={widgetRef}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="625b2459e6f5342a627366cb"
      data-style-height="20px"
      data-style-width="240px"
      data-style-text-align="left"
      theme="light"
      style={{ marginLeft: lang === "ru" ? "2px" : "-9px" }}
    >
      <a
        href="https://www.trustpilot.com/review/youproxy.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
}
