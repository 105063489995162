import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  toolsProxyChecker: {},

  description: {
    marginBottom: '4.6rem',

    '& > p': {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
      "& > p": {
        fontSize: "1.4rem"
      }
    }
  },

  formatNote: {
    marginBottom: '2.5rem',
    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '1.5rem',
    }
  },

  panel: {
    position: "relative",
    display: 'flex',
    marginBottom: '5rem',
    borderRadius: '3.5rem',
    padding: '1.6rem 2rem 4rem',
    boxShadow: '0px 14px 30px rgba(165, 178, 208, 0.22)',
    backgroundColor: 'var(--clr-default-100)',

    '.form-textarea': {
      flexGrow: '1',
      flexBasis: '60%',
    },

    options: {
      flexGrow: '1',
      flexBasis: '40%',
      paddingTop: '3.4rem',
      paddingLeft: '3.6rem',

      list: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: '2.8rem',

        '.form-checkbox': {
          marginBottom: '1.5rem',
          width: 'auto',

          '&:not(:last-child)': {
            marginRight: '2rem',
          }
        },

        [appTheme.breakpoints.down('bp576')]: {
          marginBottom: '0',
        }
      },

      [appTheme.breakpoints.down('bp992')]: {
        flexBasis: '50%',
      },

      [appTheme.breakpoints.down('bp576')]: {
        order: '0',
        marginTop: '2rem',
        marginBottom: '1rem',
        paddingTop: '0',
        paddingLeft: '0',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      flexDirection: 'column',
      padding: '2rem',

      '& > .button': {
        order: '2',
      }
    }
  },

  result: {
    marginBottom: '8rem',
  },
  resultTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '2.6rem',
    alignItems: "flex-end",
    "& > div:first-child": {
      marginRight: "2rem"
    }
  },

  advantages: {
    display: 'grid',
    gridGap: '2.7rem 3rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(25rem, 1fr))',
    marginBottom: '5rem',
  },

  advantage: {
    borderRadius: '3rem',
    padding: '3.2rem 2.2rem',
    boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
    backgroundColor: 'var(--clr-default-100)',

    icon: {
      display: 'flex',
      flexShrink: '0',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2rem',
      borderRadius: '1.4rem',
      width: '5.4rem',
      height: '5.4rem',
      backgroundColor: 'var(--clr-default-400)',

      img: {
        width: '2.8rem',
        height: '2.8rem',
        objectFit: 'contain',
      },

      [appTheme.breakpoints.down('bp576')]: {
        order: '1',
        marginBottom: '0',
        marginLeft: '2.7rem',
      },

      [appTheme.breakpoints.down('bp370')]: {
        marginLeft: '2rem',
      }
    },

    text: {
      fontWeight: '500',
      fontSize: '1.6rem',
      lineHeight: '130%',
      color: 'var(--clr-text-600)',
    },

    [appTheme.breakpoints.down('bp576')]: {
      display: 'flex',
      alignItems: 'center',
    }
  },

  seo: {
    // paddingTop: fluid(8, 3),
  }
}
