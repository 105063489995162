import { useFormContext } from "react-hook-form";

import { CheckMarkIcon } from "../../../components/icons/index";

import "./checkbox.scss";

export const Checkbox = ({
  name,
  label,
  radio,
  size = "default",
  color = "default",
  onChange,
  checked,
  disabled = false,
  ...rest
}) => {
  const formCtx = useFormContext();

  const defineClasses = () => {
    let classes = "form-checkbox";

    switch (size) {
      case "large":
        classes += " form-checkbox_size_large";
        break;
      case "default":
        classes += " form-checkbox_size_default";
        break;
      default:
        break;
    }

    switch (color) {
      case "default":
        classes += " form-checkbox_color_default";
        break;
      case "white":
        classes += " form-checkbox_color_white";
        break;
      default:
        break;
    }

    if (disabled) classes += " form-checkbox_disabled";

    return classes;
  };

  return (
    <label className={defineClasses()}>
      {!name && (
        <input
          name={name}
          type={!radio ? "checkbox" : "radio"}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          {...rest}
        />
      )}
      {name && formCtx && (
        <input
          {...formCtx.register(name)}
          name={name}
          type={!radio ? "checkbox" : "radio"}
          checked={checked}
          disabled={disabled}
          {...rest}
        />
      )}
      <span className="form-checkbox__field">
        <CheckMarkIcon />
      </span>
      {label && <span className="form-checkbox__label">{label}</span>}
    </label>
  );
};
