import { createSlice } from "@reduxjs/toolkit";

import { fetchCaptchaInfo } from "./captcha.thunks";

const initialState = {
  loaded: false,

  loading: false,
  errors: null,

  active: false,
  type: null,
  key: null
};

const CaptchaSlice = createSlice({
  name: "captcha",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCaptchaInfo.pending]: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    [fetchCaptchaInfo.rejected]: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    [fetchCaptchaInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.loaded = true;

      state.active = action.payload.active;
      state.type = action.payload.type;
      state.key = action.payload.key;
    }
  }
});

export const CaptchaActions = {
  ...CaptchaSlice.actions,
  fetchCaptchaInfo
};

export default CaptchaSlice.reducer;
