import { useSelector } from "react-redux";
import { getSeoBlockState } from "../store/seo-block/seo-block.selectors";
import { useEffect } from "react";
import { useActions } from "./use-actions";
import { useLang } from "./use-lang";

export const useSeoBlock = (keys) => {
  const lang = useLang();
  const { fetchSeoBlocks } = useActions();
  const { loading, errors, data, loadingKeys } = useSelector(getSeoBlockState);

  useEffect(() => {
    const alreadyInProcess = keys.some(key => loadingKeys.includes(key));

    if (!alreadyInProcess) {
      const notExistKeys = keys.filter((key) => !data[lang]?.hasOwnProperty(key));

      if (notExistKeys.length) {
        fetchSeoBlocks({ keys: notExistKeys, languageCode: lang });
      }
    }

  }, [keys, lang, data, loadingKeys]);

  const searchedKeys = keys.reduce((acc, key) => {
    if (!data[lang] || !data[lang].hasOwnProperty(key)) {
      return acc;
    }

    return {
      ...acc,
      [key]: data[lang][key]
    };
  }, {})

  return {
    loading: loading,
    data: searchedKeys,
    errors
  };
};
