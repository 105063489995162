import * as yup from "yup";
import { PASSWORD_MIN, PASSWORD_REG } from "../constants";

export const PasswordChangeSchema = (t) =>
  yup.object().shape({
    passwordChangeOldPassword: yup.string()
      .required(t.rules.password.required)
      .matches(PASSWORD_REG, t.rules.password.passwordContainsSpaces)
      .min(PASSWORD_MIN, `${t.rules.password.min} ${PASSWORD_MIN}`)
    ,
    passwordChangeNewPassword: yup.string()
      .required(t.rules.password.required)
      .matches(PASSWORD_REG, t.rules.password.passwordContainsSpaces)
      .min(PASSWORD_MIN, `${t.rules.password.min} ${PASSWORD_MIN}`),
    passwordChangeConfirmNewPassword: yup.string()
      .required(t.rules.password.required)
      .matches(PASSWORD_REG, t.rules.password.passwordContainsSpaces)
      .min(PASSWORD_MIN, `${t.rules.password.min} ${PASSWORD_MIN}`)
      .oneOf([yup.ref("passwordChangeNewPassword"), null], `${t.rules.password.match}`)
  });
