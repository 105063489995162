import i18n from "i18next";
import { useParams } from "react-router-dom";

import { getCookie } from "../utils/helpers/cookie.helpers";
import { isSSR } from "../utils/helpers/isSSR";

export const useLang = () => {
  const { lang } = useParams();
  const cookieLang = !isSSR() ? getCookie("lang") : null;

  if (isSSR() && lang) {
    i18n.changeLanguage(lang);
  }

  const currentLang = i18n.language || i18n.options.fallbackLng;
  return lang || currentLang || cookieLang;
};
