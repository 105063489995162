import { useFormContext } from "react-hook-form";

import "./radio.scss";

export const RadioGroup = ({ name, options, ...rest }) => {
  const formCtx = useFormContext();

  // console.log(formCtx.formState.defaultValues[name]);

  return (
    <div className="form-radio-group">
      {options.map((option, idx) => (
        <label key={`${idx}:${option.key}`} className="form-radio-group__item">
          {!name && <input type="radio" name={name} value={option.key} {...rest} />}
          {name && formCtx?.register && (
            <input
              {...formCtx.register(name)}
              type="radio"
              name={name}
              value={option.key}
              defaultChecked={formCtx.formState.defaultValues
                ? option.key === formCtx.formState.defaultValues[name]
                : null }
              {...rest}
            />
          )}
          <span className="form-radio-group__item-field"></span>
          {option.label && <span className="form-radio-group__item-label">{option.label}</span>}
        </label>
      ))}
    </div>
  );
};
