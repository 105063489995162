import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ApiColoredIcon } from "../../../../assets/images/icons/colored/api-colored-icon";
import { FolderColoredIcon } from "../../../../assets/images/icons/colored/folder-colored-icon";
import { FeatureCard, SeoBlockField, ShiftedCard } from "../../../../components/common";
import { Container } from "../../../../components/common/container/container";
import { Heading } from "../../../../components/ui/heading/heading";
import { useSeoBlock } from "../../../../hooks";

import { style } from "./features.style";

export const Features = () => {
  // Translations
  const { t } = useTranslation();

  const { data } = useSeoBlock(["mainPageFeaturesBlock"]);

  const featureList = [
    {
      label: t("pages.app.home.features.leftItems.security.label"),
      content: t("pages.app.home.features.leftItems.security.value")
    },
    {
      label: t("pages.app.home.features.leftItems.uptime.label"),
      content: t("pages.app.home.features.leftItems.uptime.value")
    },
    {
      label: t("pages.app.home.features.leftItems.delivery.label"),
      content: t("pages.app.home.features.leftItems.delivery.value")
    },
    {
      label: t("pages.app.home.features.leftItems.support.label"),
      content: t("pages.app.home.features.leftItems.support.value")
    }
  ];

  return (
    <Box sx={style.features}>
      <Container>
        <Box sx={style.container}>
          <ShiftedCard sx={style.shiftedCard}>
            <SeoBlockField
              defaultValue={t("pages.app.home.features.heading")}
              field={data?.mainPageFeaturesBlock?.title}
            >
              {(data) => (
                <Heading tag="h2" variant="h2" color="light">
                  {data}
                </Heading>
              )}
            </SeoBlockField>
            <Typography component="p" sx={style.left.text}>
              <SeoBlockField
                defaultValue={t("pages.app.home.features.description")}
                field={data?.mainPageFeaturesBlock?.description}
              />
            </Typography>
            <Box component="ul" sx={style.left.list}>
              <SeoBlockField
                defaultValue={featureList}
                field={data?.mainPageFeaturesBlock?.featureList}
              >
                {(data) =>
                  data.map(({ label, content }, key) => (
                    <Box key={key} component="li" sx={style.left.item}>
                      <span>{content}</span>
                      {label}
                    </Box>
                  ))
                }
              </SeoBlockField>
            </Box>
          </ShiftedCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <SeoBlockField
              defaultValue={{
                ...t("pages.app.home.features.downloadListCard"),
                button: {
                  ...t("pages.app.home.features.downloadListCard.button"),
                  link: "#"
                }
              }}
              field={data?.mainPageFeaturesBlock?.downloadListCard}
            >
              {(data) => (
                <FeatureCard
                  icon={FolderColoredIcon}
                  {...data}
                  ButtonProps={{
                    type: "link",
                    variant: "outlined"
                  }}
                  customStyles={{ marginBottom: "2.4rem" }}
                />
              )}
            </SeoBlockField>

            <SeoBlockField
              defaultValue={{
                ...t("pages.app.home.features.apiCard"),
                button: {
                  ...t("pages.app.home.features.apiCard.button"),
                  link: "#"
                }
              }}
              field={data?.mainPageFeaturesBlock?.apiCard}
            >
              {(data) => (
                <FeatureCard
                  icon={ApiColoredIcon}
                  {...data}
                  ButtonProps={{
                    type: "link",
                    variant: "outlined"
                  }}
                />
              )}
            </SeoBlockField>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
