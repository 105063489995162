import { fluid } from '../../../../assets/styles/functions/fluid'
import { appTheme } from "../../../../theme"

const offset = {
  top: "8.4rem",
  bottom: "10rem"
}
export const style = {
  features: {
    paddingTop: fluid(7, 4),
    paddingBottom: fluid(7, 4),
  },
  container: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "72px",
    paddingTop: offset.top,
    paddingBottom: offset.bottom,

    [appTheme.breakpoints.down("bp992")]: {
      gridTemplateColumns: "1fr"
    }
  },
  shiftedCard: {
    paddingRight: fluid(6.5, 2.4, 99, 117.7),
    [appTheme.breakpoints.down('bp992')]: {
      padding: "6rem 3rem 6rem 0"
    },
    [appTheme.breakpoints.down('bp370')]: {
      padding: "6rem 0"
    },

    "&:before": {
      marginTop: `calc(${offset.top} * -1)`,
      marginBottom: `calc(${offset.bottom} * -1)`,

      [appTheme.breakpoints.down('bp992')]: {
        margin: 0,
      }
    },

    '& .heading': {
      marginBottom: '1.8rem',
    },
  },


  left: {

    text: {
      marginBottom: '4.9rem',
      fontWeight: '400',
      fontSize: '1.4rem',
    },

    list: {
      display: 'grid',
      gridGap: '4.4rem',
      gridTemplateColumns: 'repeat(2, 1fr)',
      paddingLeft: '4rem',

      [appTheme.breakpoints.down('bp1300')]: {
        paddingLeft: '7.5rem',
      },

      [appTheme.breakpoints.down('bp992')]: {
        paddingLeft: '6rem',
      },

      [appTheme.breakpoints.down('bp576')]: {
        paddingLeft: '5rem',
      }
    },

    item: {
      position: 'relative',
      fontWeight: '400',
      fontSize: fluid(1.4, 1.2),

      '&::before': {
        content: `""`,
        position: 'absolute',
        right: '100%',
        top: '0',
        width: '7.5rem',
        height: '7.5rem',
        backgroundImage: `url("/images/ui/list-point.svg")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',

        [appTheme.breakpoints.down('bp992')]: {
          width: '6rem',
          height: '6rem',
        },

        [appTheme.breakpoints.down('bp576')]: {
          width: '5rem',
          height: '5rem',
        }
      },

      '& > span': {
        display: 'block',
        fontWeight: '700',
        fontSize: fluid(5.4, 3.5),
      }
    },

    [appTheme.breakpoints.down('bp992')]: {
      position: 'relative',
      paddingTop: '6rem',
      paddingRight: '3rem',
      paddingBottom: '6rem',
      flex: '1 1 100%',
    },

    [appTheme.breakpoints.down('bp370')]: {
      paddingRight: 0,
    }
  },
}
