import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { translationsEn, translationsRu, translationsUa } from "../translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ru", "uk"],
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false
    },

    resources: {
      en: translationsEn,
      ru: translationsRu,
      uk: translationsUa
    },

    react: {
      useSuspense: false
    }
  });

const originalT = i18n.t;
i18n.t = (key, options = {}) => {
  return originalT(key, { ...options, returnObjects: true });
};

export default i18n;
