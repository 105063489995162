import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  promocodes: {
    position: 'relative',
    zIndex: '0',
    overflow: 'hidden',
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
    height: '100%',
    backgroundImage: 'linear-gradient(108.57deg, rgba(238 246 255 / 20%) 3.11%, rgba(81 127 245 / 35%) 100.73%)',
    '.button': {
      marginTop: '6rem',
      minHeight: '6rem',
      maxWidth: '30.2rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginTop: '4rem',
      }
    },

    '.loader_type_app': {
      minHeight: 'initial',
    }
  },

  wrapper: {
    textAlign: 'center',

    '& > .heading': {
      marginBottom: '5rem',
      textAlign: 'center',

      [appTheme.breakpoints.down('bp576')]: {
        textAlign: 'left',
      }
    }
  },

  list: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    textAlign: 'left',

    [appTheme.breakpoints.down('bp1200')]: {
      gridGap: '1.5rem',
    },

    [appTheme.breakpoints.down('bp850')]: {
      gridTemplateColumns: '1fr',
    }
  },

  noData: {
    fontSize: "2.4rem"
  }
}
