import React from "react";
import { style } from "./shifted-card.style";
import { Box } from "@mui/material";

/**
 *
 * @param {Object} props
 * @param {left | right} props.float
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 */
export const ShiftedCard = (props) => {
  const {float, children, sx} = props;


  return (
    <Box sx={[
      style.wrapper,
      style.float[float ?? "left"],
      ...(Array.isArray(sx) ? sx : [sx])
    ]}>
      {children}
    </Box>
  );
};
