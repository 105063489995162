import * as yup from "yup";
import { IP_V4_FULL_REG } from "../constants";

const ipOrUrlValidation = yup.string().test('ipOrUrl', 'Invalid input', (value) => {
  if (!value) {
    return true; // Return true for empty values
  }

  // Check if it's a valid IP address using a regular expression
  if (IP_V4_FULL_REG.test(value)) {
    return true;
  }

  // Check if it's a valid URL using a regular expression
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  const urlWithoutProtocolRegex = /^(?!(?:https?|ftp):\/\/)[^\s/$.?#].[^\s]*$/;
  const testReg = /^(?!(.*:\/\/))/;

  if (testReg.test(value)) {
    return true;
  }

  return false; // Return false for other values
});
export const PingIpSchema = (t) =>
  yup.object().shape({
    ipOrDomain: yup.string().required(t.rules.ipOrDomain.required),
    // testPingPackages: yup.string().required(t.rules.password.required)
  });
