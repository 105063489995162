import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

import { avatarPlaceholder } from "../utils";

import { style } from "./reviewsCard.style";

export const ReviewsCard = ({ dataItemReviewed, review }) => {
  // Translations
  const { t } = useTranslation();
  const { name, description } = dataItemReviewed;

  return (
    <Box
      sx={style.card}
      className="reviews-card"
      itemScope
      itemProp="review"
      itemType="https://schema.org/Review"
    >
      <Box sx={style.text} className="reviews-card__text">
        <p itemProp="reviewBody" dangerouslySetInnerHTML={{ __html: review.text }}></p>
      </Box>
      <Box sx={style.footer} className="reviews-card__footer">
        <Box
          sx={style.author}
          className="reviews-card__author"
          itemScope
          itemProp="author"
          itemType="http://schema.org/Person"
        >
          <Box sx={style.author.avatar} className="reviews-card__author-avatar">
            <img
              itemProp="image"
              src={review.avatarUrl ? review.avatarUrl : avatarPlaceholder}
              width={47}
              height={47}
              alt={review.userName}
              loading="lazy"
            />
          </Box>
          <Box sx={style.author.info} className="reviews-card__author-info">
            <Box sx={style.author.name} className="reviews-card__author-name" itemProp="name">
              {review.userName}
            </Box>
          </Box>
        </Box>
        <Link
          sx={style.link}
          href={review.siteUrl}
          target="_blank"
          rel="noreferrer"
          className="reviews-card__link"
        >
          {t("shared.reviews.action")}
        </Link>
      </Box>
      <Box
        sx={{ display: "none" }}
        itemScope
        itemProp="itemReviewed"
        itemType="https://schema.org/Organization"
      >
        <meta itemProp="name" content={name} />
        <meta itemProp="image" content={review.avatarUrl ? review.avatarUrl : avatarPlaceholder} />
        <meta itemProp="description" content={description} />
      </Box>
    </Box>
  );
};
