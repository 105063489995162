import { appTheme } from "../../../theme";

export const style = {
  reviewsList: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: '4.5rem',

    [appTheme.breakpoints.down('bp767')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  }
}