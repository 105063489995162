import React from "react";

export const InfinityColoredIcon = () => (
  <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.26797 4.11617C4.83464 4.24938 4.36891 4.66364 4.16752 5.09486L4.00141 5.45064L4.00071 13.8773L4 22.304H21.5H39L38.9993 13.8773L38.9986 5.45064L38.8325 5.09486C38.6252 4.65097 38.162 4.24818 37.7025 4.11202C37.189 3.96002 5.76305 3.96396 5.26797 4.11617ZM4 23.9086C4 24.6923 4.34224 25.2357 5.08556 25.6323L5.37601 25.7872L11.6033 25.8066L17.8306 25.826V27.9001V29.9741L16.3664 29.9747C14.7241 29.9753 14.168 30.041 13.6206 30.2992C12.7507 30.7096 12.0319 31.6856 11.9294 32.5954L11.8838 33H21.4903H31.0968L31.0959 32.8065C31.0919 31.8735 30.317 30.7416 29.3794 30.2992C28.832 30.041 28.2759 29.9753 26.6336 29.9747L25.1694 29.9741V27.9001V25.826L31.3967 25.8066L37.624 25.7872L37.9144 25.6323C38.6578 25.2357 39 24.6923 39 23.9086V23.4299H21.5H4V23.9086Z"
          fill="url(#paint0_linear_581_14916)" />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M15.2489 9.04607C13.097 9.36529 11.4242 10.8299 11.0654 12.7088C10.9339 13.3971 11.0041 14.2508 11.2455 14.9012C12.2006 17.475 15.5088 18.7006 18.2463 17.4948C18.9408 17.189 19.3658 16.8764 20.0754 16.1498L20.5877 15.6253L19.763 14.7727C19.3094 14.3038 18.9272 13.9173 18.9136 13.9138C18.9 13.9103 18.6863 14.1202 18.4388 14.3802C17.6486 15.2102 17.3401 15.4355 16.7753 15.5948C16.5458 15.6595 16.4124 15.6718 15.9534 15.6706C15.4607 15.6694 15.3789 15.6601 15.1416 15.5788C14.7698 15.4513 14.4764 15.2842 14.2098 15.0484C13.9256 14.7969 13.741 14.5291 13.601 14.1651C13.5078 13.923 13.4933 13.8307 13.4933 13.4822C13.4933 13.1338 13.5078 13.0414 13.601 12.7994C13.8618 12.1215 14.3879 11.6396 15.1416 11.3882C15.5004 11.2686 16.1478 11.2403 16.576 11.3256C16.9652 11.4031 17.3663 11.5857 17.6431 11.8115C17.7657 11.9114 18.8815 13.0421 20.1227 14.3241C21.3639 15.6062 22.4835 16.7389 22.6106 16.8412C23.9612 17.928 25.8415 18.28 27.5778 17.7709C29.3174 17.2609 30.6372 15.8695 30.9451 14.2209C31.0408 13.7083 31.0084 12.9345 30.8708 12.4486C30.6212 11.5673 30.1722 10.8758 29.4423 10.2485C27.7654 8.80739 25.1546 8.58806 23.2123 9.72516C22.7512 9.99519 22.4406 10.2511 21.8902 10.8147L21.378 11.3392L22.2026 12.1917C22.6562 12.6606 23.0384 13.0471 23.052 13.0506C23.0656 13.0542 23.2793 12.844 23.5269 12.5836C24.3148 11.7548 24.5544 11.5768 25.138 11.3866C25.4003 11.3011 25.4741 11.2931 25.9926 11.2938C26.5083 11.2944 26.5842 11.3028 26.824 11.3854C27.1964 11.5136 27.4895 11.6806 27.7558 11.9161C28.04 12.1676 28.2246 12.4354 28.3647 12.7994C28.4578 13.0414 28.4723 13.1338 28.4723 13.4822C28.4723 13.8307 28.4578 13.923 28.3647 14.1651C28.1039 14.8429 27.5782 15.3244 26.824 15.5764C26.4713 15.6943 25.7912 15.724 25.3871 15.6391C25.0015 15.5582 24.5887 15.3701 24.3253 15.1553C24.2012 15.0541 23.0827 13.9209 21.8398 12.6371C20.5489 11.3038 19.4654 10.2158 19.3127 10.0996C18.6379 9.58613 17.8915 9.2558 17.0199 9.08485C16.6307 9.00854 15.6472 8.98699 15.2489 9.04607Z"
          fill="white" />
    <g filter="url(#filter0_bi_581_14916)">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M31.9265 12.0996C31.6239 12.2158 31.2732 12.5477 31.1156 12.8672L31 13.1016V22.5V31.8984L31.1156 32.1328C31.2779 32.4617 31.6233 32.7844 31.9398 32.9027C32.1978 32.9991 32.2504 33 37.5 33C42.7496 33 42.8022 32.9991 43.0602 32.9027C43.3767 32.7844 43.7221 32.4617 43.8844 32.1328L44 31.8984V22.5V13.1016L43.8844 12.8672C43.7221 12.5383 43.3767 12.2156 43.0602 12.0973C42.8295 12.0111 42.6959 12 41.8907 12H40.9817L40.9656 12.457C40.9527 12.8239 40.9271 12.9595 40.8359 13.1445C40.696 13.4284 40.4282 13.6938 40.1372 13.8369L39.917 13.9453H37.5H35.083L34.852 13.8317C34.5579 13.6872 34.309 13.4386 34.1643 13.1448C34.0729 12.9595 34.0474 12.8241 34.0344 12.457L34.0183 12L33.0958 12.0024C32.2865 12.0046 32.143 12.0165 31.9265 12.0996ZM34.9658 12.2695C34.9659 12.6104 35.0773 12.8409 35.2942 12.9491C35.443 13.0234 35.6503 13.0311 37.5 13.0311C39.3497 13.0311 39.557 13.0234 39.7058 12.9491C39.9227 12.8409 40.0341 12.6104 40.0342 12.2695L40.0343 12H37.5H34.9657L34.9658 12.2695ZM39.8233 31.1444C40.0337 31.3545 40.0337 31.6455 39.8233 31.8556L39.71 31.9688H37.5H35.29L35.1767 31.8556C34.9663 31.6455 34.9663 31.3545 35.1767 31.1444L35.29 31.0312H37.5H39.71L39.8233 31.1444Z"
            fill="#BCE4F1" fillOpacity="0.5" />
    </g>
    <defs>
      <filter id="filter0_bi_581_14916" x="26" y="7" width="23" height="31" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_581_14916" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_581_14916" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feOffset dx="0.1" dy="0.2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_581_14916" />
      </filter>
      <linearGradient id="paint0_linear_581_14916" x1="4" y1="4" x2="44.2468" y2="10.7043"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor="#517FF5" />
        <stop offset="1" stopColor="#4BA7EA" />
      </linearGradient>
    </defs>
  </svg>
);
