import { appTheme } from "../../../theme";

export const style = {
  cabinetActionsHistory: {
    '& > .heading': {
      marginBottom: '4rem',
  
      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      }
    }
  },

  noData: {
    div: {
      padding: '20px',
    }
  }
}