import { appTheme } from "../../../theme"
import { fluid } from "../../../assets/styles/functions/fluid"

export const style = {
  notFound: {
    overflow: 'hidden',
    paddingTop: fluid(20, 12),
    paddingBottom: fluid(20, 12),
    minHeight: '100%',
    backgroundImage: `linear-gradient(108.57deg, rgba(238 246 255 / 20%) 3.11%, rgba(81 127 245 / 35%) 100.73%)`
  },

  wrapper: {
    position: 'relative',
    zIndex: '0',

    '& > .heading': {
      marginBottom: '3rem',
      maxWidth: '22ch',
    }
  },

  decor: {
    position: 'absolute',
    right: '-27%',
    top: '52%',
    zIndex: '-1',
    width: '89.5rem',
    height: 'auto',
    transform: 'translateY(-50%)',

    '& > svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },

    [appTheme.breakpoints.down('bp922')]: {
      right: '-36rem',
      top: '-38%',
      width: '52rem',
      transform: 'rotate(180deg)',
    },

    [appTheme.breakpoints.down('bp767')]: {
      right: '-42rem',
    },

    [appTheme.breakpoints.down('bp370')]: {
      right: '-46rem',
    }
  },

  description: {
    marginBottom: '3.5rem',
    maxWidth: '55ch',
    fontWeight: '400',
    fontSize: '1.4rem',
    lineHeight: '160%',
    color: 'var(--clr-text-400)',
  }
}