import { createAsyncThunk } from "@reduxjs/toolkit";

import { SeoBlockService } from "../../services";

export const fetchSeoBlocks = createAsyncThunk(
  "seo/fetchSeoBlocks",
  async ({keys, languageCode}, { rejectWithValue }) => {
    try {
      const response = await SeoBlockService.getSeoBlocksByLocale(keys, languageCode);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
