import { createSlice } from "@reduxjs/toolkit";

import { getPromocodesByPage } from "./promocode.thunks";

const initialState = {
  promocodesPreview: {},
  promocodesPreviewLength: 4,
  allPromocodes: {
    locale: null,
    data: [],
    totalElements: 0,
    isLoading: null,
    isFetchError: null
  }
};

const PromocodeSlice = createSlice({
  name: "promocode",
  initialState,
  reducers: {
    setPromocodes(state, action) {
      state.promocodesPreview = action.payload;
    },
    removePromocodes(state) {
      state.allPromocodes.data = [];
    }
  },
  extraReducers: {
    [getPromocodesByPage.pending]: (state) => {
      state.allPromocodes.isLoading = true;
    },
    [getPromocodesByPage.rejected]: (state, action) => {
      state.allPromocodes.isFetchError = action.payload;
      state.allPromocodes.isLoading = false;
    },
    [getPromocodesByPage.fulfilled]: (state, action) => {
      state.allPromocodes.locale = action.meta.arg.localeCode;
      state.allPromocodes.data = [...state.allPromocodes.data, ...action.payload.content];
      state.allPromocodes.totalElements = action.payload.totalElements;
      state.allPromocodes.isLoading = false;
    }
  }
});

export const PromocodeActions = { ...PromocodeSlice.actions, getPromocodesByPage };

export default PromocodeSlice.reducer;
