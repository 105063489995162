import React, { forwardRef } from "react";
import { m } from "framer-motion";
import { Box } from "@mui/material";

const Animated = forwardRef((props, ref) => {
  const { children, name, component, ...rest } = props;

  // console.count("Animated");

  return (
      <Box
        ref={ref}
        component={m[component ?? "div"]}
        key={name}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        {...rest}
      >
        {children}
      </Box>
  )
})

export default Animated;
