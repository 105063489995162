import { appTheme } from "../../../theme"
import { fluid } from "../../../assets/styles/functions/fluid"

export const style = {
  accHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '1.5rem',
    paddingRight: '4rem',
    paddingBottom: '1.5rem',
    cursor: 'pointer',

    '&::before, &::after': {
      content: `""`,
      position: 'absolute',
      backgroundColor: 'var(--clr-primary-400)',
    },

    '&::before': {
      right: '1.5rem',
      top: '2.5rem',
      width: '1.6rem',
      height: '0.2rem',
    },

    '&::after': {
      right: '2.2rem',
      top: '1.8rem',
      width: '0.2rem',
      height: '1.6rem',
      opacity: '1',
      visibility: 'visible',
      transition: 'hidden var(--transition), opacity var(--transition)',
    }
  },

  accLabel: {
    fontWeight: '600',
    fontSize: fluid(2.2, 1.6),
    color: 'var(--clr-text-500)',
  },

  accBody: {
    fontWeight: '400',
    fontSize: '1.6rem',
    lineHeight: '160%',
    color: 'var(--clr-text-400)',
    [appTheme.breakpoints.down("bp576")]: {
      fontSize: "1.4rem"
    }
  },

  accOpen: {
    '.accordion__header': {
      '&::after': {
        opacity: '0',
        visibility: 'hidden',
        transition: 'hidden var(--transition), opacity var(--transition)',
      }
    }
  }
}