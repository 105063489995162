export class Evercookie {
  document = document;
  options = {
    cacheCookieName: "evercookie_cache",
    pngCookieName: "evercookie_png",
    etagCookieName: "evercookie_etag",
    etagPath: "etag",
    baseurl: `${this.document.defaultView?.location.origin}/evercookie`,
    domain: ".",
    phpuri: "?type=",
    pngPath: "png",
    cachePath: "cache",
    name: "uid",
    expiresPast: "Thu, 01 Jun 2000 00:00:00 GMT",
    expires: "Sat, 01 Jun 2030 00:00:00 GMT"
  };

  refresh = true;
  refreshTimeInMs = 1000;
  timer = null;

  constructor(options = {}) {
    if (options.hasOwnProperty("refreshTimeInMs")) {
      this.refreshTimeInMs = options.refreshTimeInMs;
    }

    this.options.domain = `.${this.document.defaultView?.location.host.replace(/:\d+/, "")}`;

    // Check is exist GS
    if (!window.globalStorage) {
      window.globalStorage = {};
    }
    if (!window.globalStorage[document.domain]) {
      window.globalStorage[document.domain] = {};
    }
    this.globalStorage = window.globalStorage[document.domain];
    this.start();
  }

  start() {
    if (!this.timer) {
      this.timer = setTimeout(() => {
        if (!this.refresh) {
          this.timer= null;
          this.start()
        } else {
          this.get().then((result) => {
            if (result.mostFrequentElement) {
              this.set(result.mostFrequentElement).then(() => {
                this.timer= null;
                this.start();
              })
            } else {
              this.timer= null;
              this.start();
            }

          })
        }
      }, this.refreshTimeInMs)
    }
  }
  stop() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null;
    }
  }
  get() {
    this.refresh = false

    return Promise.all([
      this.getWindowName(),
      this.getLocalStorage(),
      this.getSessionStorage(),
      this.getIndexedDb(),
      this.getCache(),
      this.getEtag(),
      this.getPng(),
      this.getGlobalStorage(),
      this.getCookie()
    ]).then((result) => {
      let mostFrequentElement = null;
      const freqMap = {};
      let maxCount = 0;

      for (let i = 0; i < result.length; i++) {
        let currentElement = result[i];

        if (!currentElement) {
          continue;
        }

        if (freqMap[currentElement] == null)
          freqMap[currentElement] = 1;
        else
          freqMap[currentElement]++;

        if (freqMap[currentElement] > maxCount) {
          mostFrequentElement = currentElement;
          maxCount = freqMap[currentElement];
        }
      }

      this.refresh = true;
      return Promise.resolve({
        mostFrequentElement,
        list: {
          windowData: result[0],
          localData: result[1],
          sessionData: result[2],
          dbData: result[3],
          cacheData: result[4],
          etagData: result[5],
          pngData: result[6],
          globalData: result[7],
          cookieData: result[8]
        }
      });
    });
  }

  set(eid) {
    if (!eid) {
      return Promise.reject();
    }
    this.refresh = false;

    return Promise.all([
      this.setWindowName(eid),
      this.setLocalStorage(eid),
      this.setSessionStorage(eid),
      this.setIndexedDb(eid),
      this.setCache(eid),
      this.setPng(eid),
      this.setEtag(eid),
      this.setGlobalStorage(eid),
      this.setCookie(eid)
    ]).then((result) => {
      this.refresh = true;
      return Promise.resolve(result);
    });

  }

  // windowName
  getWindowName() {
    const eid = +this.getFromStr(this.options.name, window?.name);
    if (!isNaN(eid)) return eid;
  }

  setWindowName(value) {
    if (!value) return;
    window.name = this.replace(window?.name, this.options.name, value);
  }

  getCookie() {
    const uid = document.cookie.split(";")
      .find((e) => e.trim().split("=", 2)[0] === this.options.name)?.split("=", 2)[1];
    return uid && uid !== "" ? Number(uid) : undefined;
  }

  setCookie(value) {
    if (!value) return;

    document.cookie = `${this.options.name}=${value}; expires=${this.options.expires}; path=/; domain=${this.options.domain}`;
  }

  // globalStorage
  getGlobalStorage() {
    const eid = +this.globalStorage[this.options.name];
    if (!isNaN(eid)) return eid;
  }

  setGlobalStorage(value) {
    if (!value) return;

    this.globalStorage[this.options.name] = value;
  }

  // localStorage
  getLocalStorage() {
    const eid = +window?.localStorage.getItem(this.options.name);
    if (!isNaN(eid)) return eid;
  }

  setLocalStorage(value) {
    if (!value) return;
    window?.localStorage.setItem(this.options.name, value.toString());
  }

  // sessionStorage
  getSessionStorage() {
    const eid = +window?.sessionStorage.getItem(this.options.name);
    if (!isNaN(eid)) return eid;
  }

  setSessionStorage(value) {
    if (!value) return;
    window?.sessionStorage.setItem(this.options.name, value.toString());
  }

  // indexedDb
  getIndexedDb() {
    return new Promise((resolve, reject) => {
      try {
        if (this.indexedDB) {
          const request = this.indexedDB.open("idb_evercookie", 1);

          request.onupgradeneeded = (event) => {
            const db = request.result || event.target.result;

            db.createObjectStore("evercookie", {
              keyPath: "name"
            });
          };

          request.onsuccess = (event) => {
            const idb = request.result || event.target.result;

            if (!idb.objectStoreNames.contains("evercookie")) {
              resolve();
            } else {
              const tx = idb.transaction(["evercookie"]);
              const objst = tx.objectStore("evercookie");
              const qr = objst.get("uid");

              qr.onsuccess = () => {
                resolve(qr.result !== undefined ? qr.result.value : undefined);
              };

              qr.onerror = () => {
                resolve();
              };
            }
            idb.close();
          };

          request.onerror = () => {
            resolve();
          };
        }
      } catch (error) {
        resolve();
      }
    });
  }

  setIndexedDb(value) {
    if (!value) return;

    try {
      if (this.indexedDB) {
        const request = this.indexedDB.open("idb_evercookie", 1);

        request.onupgradeneeded = (event) => {
          const db = request.result || event.target.result;

          db.createObjectStore("evercookie", {
            keyPath: "name"
          });
        };

        request.onsuccess = (event) => {
          const idb = request.result || event.target.result;

          if (idb.objectStoreNames.contains("evercookie")) {
            try {
              const tx = idb.transaction(["evercookie"], "readwrite");
              const objst = tx.objectStore("evercookie");
              objst.put({ name: "uid", value: value });
            } catch (error) {
            }
          }
          idb.close();
        };
      }
    } catch (error) {
    }
  }

  // cache
  getCache() {
    const origvalue = this.getFromStr(this.options.cacheCookieName, document.cookie);
    document.cookie = this.prepareCookieValue(this.options.cacheCookieName, "", true, true)

    return this.request(
      this.prepareUrl(this.options.cachePath, this.options.cacheCookieName)
    )
      .then((response) => response.text())
      .then((response) => {
        document.cookie = `${this.options.cacheCookieName}=${origvalue}; expires=${this.options.expires}; path=/; domain=${this.options.domain}`;

        return Promise.resolve(response !== "" ? Number(response) : undefined);
      }).catch((error) => {
        return;
      });
  }

  setCache(value) {
    if (!value) return;
    document.cookie = this.prepareCookieValue(this.options.cacheCookieName, value, true, false)

    this.request(
      this.prepareUrl(this.options.cachePath, this.options.cacheCookieName)
    ).catch((error) => {
      return;
    });
  }

  // etag
  getEtag() {
    const origvalue = this.getFromStr(this.options.etagCookieName, document.cookie);
    document.cookie = this.prepareCookieValue(this.options.etagCookieName, "", true, true)

    return this.request(
      this.prepareUrl(this.options.etagPath, this.options.etagCookieName)
    )
      .then((response) => response.text())
      .then((response) => {
        document.cookie = `${this.options.etagCookieName}=${origvalue}; expires=${this.options.expires}; path=/; domain=${this.options.domain}`;

        return Promise.resolve(response !== "" ? Number(response) : undefined);
      }).catch((error) => {
        return;
      });
  }

  setEtag(value) {
    if (!value) return;
    document.cookie = this.prepareCookieValue(this.options.etagCookieName, value, false)

    this.request(
      this.prepareUrl(this.options.etagPath, this.options.etagCookieName)
    ).catch((error) => {
      return;
    });
  }

  // png
  getPng() {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      canvas.style.visibility = "hidden";
      canvas.style.position = "absolute";
      canvas.width = 200;
      canvas.height = 1;

      if (canvas && canvas.getContext) {
        // {{this.options.pngPath}} handles the hard part of generating the image
        // based off of the http cookie and returning it cached
        const img = new Image();
        img.style.visibility = "hidden";
        img.style.position = "absolute";

        const ctx = canvas.getContext("2d");
        const origvalue = this.getFromStr(this.options.pngCookieName, document.cookie);

        document.cookie = this.prepareCookieValue(this.options.pngCookieName, "", true, true)

        img.onload = () => {
          // eslint-disable-next-line max-len
          // document.cookie = `${this.options.pngCookieName}=${origvalue}; expires=${this.options.expires}; path=/; domain=${this.options.domain}`;
          document.cookie = this.prepareCookieValue(this.options.pngCookieName, origvalue, true, false)

          let png = "";

          if (ctx) {
            ctx.drawImage(img, 0, 0);

            // get CanvasPixelArray from  given coordinates and dimensions
            const pix = ctx.getImageData(0, 0, 200, 1).data;

            // loop over each pixel to get the "RGB" values (ignore alpha)
            for (let i = 0, n = pix.length; i < n; i += 4) {
              if (pix[i] === 0) {
                break;
              }
              png += String.fromCharCode(pix[i]);
              if (pix[i + 1] === 0) {
                break;
              }
              png += String.fromCharCode(pix[i + 1]);
              if (pix[i + 2] === 0) {
                break;
              }
              png += String.fromCharCode(pix[i + 2]);
            }
          }

          resolve(png !== "" ? Number(png) : undefined);
        };

        img.onerror = (error) => {
          // console.log(error);
          resolve();
        };
        img.src = this.prepareUrl(this.options.pngPath, this.options.pngCookieName)
        img.crossOrigin = "Anonymous";
      } else {
        resolve("null");
      }
    });
  }

  setPng(value) {
    if (!value) return;
    try {
      // document.cookie = this.options.pngCookieName + "=" + value + "; path=/; domain=" + this.options.domain;
      document.cookie = this.prepareCookieValue(this.options.pngCookieName, value, false)

      const img = new Image();
      img.style.visibility = "hidden";
      img.style.position = "absolute";
      // img.src = `${this.options.baseurl}${this.options.phpuri}${this.options.pngPath}&name=${this.options.name}&cookie=${this.options.pngCookieName}`;
      img.src = this.prepareUrl(this.options.pngPath, this.options.pngCookieName)
      img.crossOrigin = "Anonymous";
    } catch (e) {
      /* empty */
    }
  }

  prepareUrl(path, name) {
    return `${this.options.baseurl}${this.options.phpuri}${path}&name=${this.options.name}&cookie=${name}`
  }
  prepareCookieValue(name, value = "", hasExpires = true, isPast = false) {
    if (hasExpires) {
      return `${name}=${value}; expires=${isPast ? this.options.expiresPast : this.options.expires}; path=/; domain=${this.options.domain}`;
    } else {
      return `${name}=${value}; path=/; domain=${this.options.domain}`;
    }
  }
  get indexedDB() {
    return window?.indexedDB
      || window?.mozIndexedDB
      || window?.webkitIndexedDB
      || window?.msIndexedDB;
  }

  replace(str, key, value) {
    if (str.indexOf("&" + key + "=") > -1 || str.indexOf(key + "=") === 0) {
      // find start
      let idx = str.indexOf("&" + key + "=");
      if (idx === -1) {
        idx = str.indexOf(key + "=");
      }

      // find end
      const end = str.indexOf("&", idx + 1);

      return end !== -1 ? `${str.substr(0, idx)}${str.substr(end + (idx ? 0 : 1))}&${key}=${value}` : `${str.substr(0, idx)}&${key}=${value}`;
    }

    return `${str}&${key}=${value}`;
  }

  getFromStr(name, text) {
    if (typeof text !== "string") {
      return;
    }

    const nameEQ = name + "=";
    const ca = text.split(/[;&]/);
    let c;

    for (let i = 0; i < ca.length; i++) {
      c = ca[i];

      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return;
  }

  request = (url) => fetch(url, {
    method: "GET",
    mode: "no-cors",
    headers: {
      Accept: "text/javascript, text/html, application/xml, text/xml, */*"
    }
  });
}
