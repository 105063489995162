import { matchPath, useLocation } from "react-router-dom";

import * as routes from "../routes/index";

export const useMatchedRoute = () => {
  const { pathname } = useLocation();

  const allRoutes = Object.values(routes)
    .map((routeFn) => routeFn())
    .flat();

  return allRoutes.find((route) => matchPath({ path: route.path }, pathname));
};
