export const style = {
  title: {
    marginBottom: "2.6rem",
    fontWeight: "500",
    fontSize: "fluid(1.6rem, 1.4rem)",
    color: "var(--clr-text-800)"
  },
  link: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
    fontWeight: "400",
    fontSize: "fluid(1.6rem, 1.4rem)",
    color: "var(--clr-text-400)",

    "& > svg": {
      marginRight: "1rem",
      width: "2.4rem",
      height: "2.4rem",
      fill: "var(--clr-primary-400)",
      objectFit: "contain"
    }
  }
};
