import { Navigate, Route, generatePath, redirect } from "react-router-dom";

import { PerfectMoney } from "../components/payment/perfect-money/perfect-money";
import { AppLayout, IndexLayout, SidebarLayout } from "../layouts";
import { CABINET_ROUTES, ROUTE, TOOLS_ROUTES } from "../utils/constants";
import { LOCALS } from "../utils/constants/locals";
import { renderRoutes } from "../utils/helpers/renderRoutes";

import { appRoutes } from "./app.routes";
import { authRoutes } from "./auth.routes";
import { cabinetRoutes } from "./cabinet.routes";
import { exportRoutes } from "./export.routes";
import { toolsRoutes } from "./tools.routes";

export const getRoutes = ({ lang, isToolsActive = true, store = null }) => {
  const notFoundPage = `/${lang}${ROUTE.APP.NOT_FOUND}/`;

  return (
    <>
      <Route
        path="/"
        loader={({ params }) => {
          const t = Object.values(LOCALS);

          if (params.lang && t.indexOf(params.lang) === -1) {
            return redirect(generatePath(notFoundPage));
          }

          return null;
        }}
      >
        {renderRoutes(exportRoutes(), store)}
        <Route element={<IndexLayout />}>
          <Route element={<AppLayout />}>
            {renderRoutes(appRoutes(), store)}
            {renderRoutes(authRoutes(), store)}
          </Route>
          <Route element={<SidebarLayout routes={CABINET_ROUTES} withExit />}>
            {renderRoutes(cabinetRoutes(), store)}
          </Route>
          {isToolsActive ? (
            <Route element={<SidebarLayout routes={TOOLS_ROUTES} withFooter />}>
              {renderRoutes(toolsRoutes(), store)}
            </Route>
          ) : null}
        </Route>
        <Route path="/process/payment/perfectMoney/:paymentId" element={<PerfectMoney />} />
        <Route path="*" element={<Navigate to={notFoundPage} />} />
      </Route>
    </>
  );
};
