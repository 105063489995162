import { appTheme } from '../../../../theme'
import { fluid } from '../../../../assets/styles/functions/fluid'

export const style = {
  reviewsPartners: {
    paddingTop: fluid(7, 4),
    paddingBottom: fluid(7, 4),
  },

  modal: {
    padding: "40px 30px",
    '& > .heading': {
      marginBottom: '2.5rem',
      textAlign: 'center',
    }
  },

  list: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(17rem, 1fr))',
    [appTheme.breakpoints.down('bp576')]: {
      gridGap: '1.5rem',
      gridTemplateColumns: 'repeat(2, 1fr)',
    }
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    borderRadius: '1.5rem',
    width: '17rem',
    height: '11rem',
    boxShadow: '0 15px 25px rgba(179 184 202 / 22%)',
    overflow: "hidden",
    padding: "10px",


    a: {
      height: "100%",
      width: "100%"
    },

    img: {
      height: "100%",
      width: "100%",
      objectFit: 'contain',
      // filter: "grayscale(1)",
      transition: ".3s",
      "&:hover": {
        filter: "grayscale(0)"
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      borderRadius: '1rem',
      width: '100%',
      height: '9rem',
      boxShadow: 'none',
      backgroundColor: "#F9FAFE",
    },
  }
}
