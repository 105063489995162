import { appTheme } from "../../../theme";

export const style = {
  wrapper: {
    position: "relative",
    color: "var(--clr-text-100)",

    "&::before": {
      content: `""`,
      position: "absolute",

      top: "0",
      bottom: "0",
      zIndex: "-1",
      backgroundImage: "linear-gradient(90deg, #225ae6 0%, #1846bd 100%)",
      width: "100vw",
      borderRadius: "15rem",

      [appTheme.breakpoints.down('bp992')]: {
        borderRadius: '10rem'
      },

      [appTheme.breakpoints.down('bp370')]: {
        borderRadius: 0,
        left: "-1.5rem",
        right: "-1.5rem"
      }
    }
  },
  float: {
    left: {
      "&::before": {
        right: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }
    },
    right: {
      "&::before": {
        left: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }
    }
  },
  background: {
    backgroundImage: "linear-gradient(90deg, #225ae6 0%, #1846bd 100%)",
  }
};
