import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { CloseIcon } from "../../../../assets/images/icons/close-icon";
import { TextInput } from "../../../../components/common/text-input/text-input";
import HeroForm from "../../../../components/heroForm/heroForm";
import useOrder from "../../../../hooks/use-order";
import { defineErrorText } from "../../../../utils/helpers";

import { style } from "./hero.style";

const HeroPanel = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const order = useOrder();
  const { enqueueSnackbar } = useSnackbar();
  const [hasPromo, setHasPromo] = useState(false);

  const promocode = new URLSearchParams(search).get("promocode");

  const setOrderPromoCode = (promoCode) => {
    order.setData((prevState) => ({
      ...prevState,
      promoCode
    }));
  };
  // Set promocode if exist in SearchParams
  useEffect(() => {
    setHasPromo(!!promocode);
    setOrderPromoCode(promocode ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocode]);

  useEffect(() => {
    if (!order.price.success && order.price.error) {
      enqueueSnackbar(defineErrorText(order.price.error), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.price.success]);

  const handlePromoClose = () => {
    setOrderPromoCode("");
    setHasPromo(false);
  };

  const promocodeStyles = {
    borderRadius: "1.5rem",
    borderColor: "transparent"
  };

  return (
    <Box sx={style.panel}>
      <HeroForm order={order} isHomePage={true} />
      <Box sx={style.panel.info}>
        <Box sx={style.panel.price}>
          <Box component="span">
            <Box component="span">{t("pages.app.home.hero.totalSum")}</Box>

            <Box component="span">
              {t("currencies.usdUpper")}{order.price?.totalPrice || 0}
            </Box>

            {order.price?.totalPrice !== order.price?.priceForOneWithDiscount ? (
              <Box component="span">
                ( {t("currencies.usdUpper")}{order.price?.priceForOneWithDiscount || 0}/
                {t("other.piece")} )
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box sx={style.panel.promocode}>
          {hasPromo ? (
            <TextInput
              isOuterLabel={true}
              placeholder={t("form.placeholders.promocode")}
              action={
                <Box sx={style.panel.promocodeClose} onClick={handlePromoClose}>
                  <CloseIcon />
                </Box>
              }
              value={order.data.promoCode}
              onChange={(e) => setOrderPromoCode(e.target.value)}
              error={order.data.promoCode && !order.price.validPromoCode}
              errorText={order.dataWarnings.promoCode}
              errorFixed={true}
              customStyles={
                order.data.promoCode && !order.price.validPromoCode ? { marginBottom: "0" } : {}
              }
              inputStyles={promocodeStyles}
            />
          ) : (
            <span onClick={() => setHasPromo(true)}>{t("shared.hasPromocode")}</span>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(HeroPanel);
