export const style = {
  checkBox: {
    color: '#D7DBEC',
    '&.Mui-checked': {
      color: '#517ff5 !important',
    },
    '& .MuiSvgIcon-root': { fontSize: 24 }
  },

  label: {
    marginRight: 0,
    '& > span.MuiTypography-root': {
      fontFamily: "var(--font-family)",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '18px',
      color: '#63637C'
    }
  },

  error: {
    transition: 'border-color var(--transition) !important',
    color: '#ff0000',
    '& svg path': {

    }
  },

  white: {
    '&.Mui-checked': {
      color: '#517ff5',
      borderColor: '#fff',
      '& .MuiSvgIcon-root': {
        '& path': {
          fill: '#fff'
        }
      }
    },
  }
}
