import { $api } from "./axios.service";
import { setCaptchaTokenToRequestHeaders } from "../utils/helpers";

export class FeedbackService {
  static sendFeedback(payload, cfToken) {
    return $api.post(
      "/api/front/feedback",
      payload,
      { headers: setCaptchaTokenToRequestHeaders(cfToken) }
    );
  }
}
