import { createSlice } from "@reduxjs/toolkit";

import { getArticleByLangAndTag, getArticlesByPage, getArticlesSidebar } from "./blog.thunks";

const initialState = {
  articlesLocaleCode: null,
  articlesPage: null,

  articles: [],
  isLoading: false,
  isFetchError: null,
  totalPages: 0,
  totalElements: 0,
  article: {
    localeCode: null,
    tag: null,

    isLoading: false,
    isFetchError: null,
    content: {}
  },
  sidebar: {
    localeCode: null,
    articleTranslationId: null,

    isLoading: false,
    popular: [],
    new: []
  }
};

const BlogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    clearFetchError: (state) => {
      state.article.isFetchError = null;
    }
  },
  extraReducers: {
    [getArticlesByPage.pending]: (state) => {
      state.isLoading = true;
    },
    [getArticlesByPage.rejected]: (state, action) => {
      state.isFetchError = action.payload;
      state.isLoading = false;
    },
    [getArticlesByPage.fulfilled]: (state, action) => {
      state.articlesLocaleCode = action.meta.arg.localeCode;
      state.articlesPage = action.meta.arg.page + 1;

      state.articles = [...action.payload.content];
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    },

    [getArticleByLangAndTag.pending]: (state) => {
      state.article.localeCode = null;
      state.article.tag = null;

      state.article.isLoading = true;
    },
    [getArticleByLangAndTag.rejected]: (state, action) => {
      state.article.isFetchError = action.payload;
      state.article.isLoading = false;
    },
    [getArticleByLangAndTag.fulfilled]: (state, action) => {
      state.article.isFetchError = null;
      state.article.localeCode = action.meta.arg.localeCode;
      state.article.tag = action.meta.arg.tag;

      state.article.content = { ...action.payload };
      state.article.isLoading = false;
    },

    [getArticlesSidebar.pending]: (state) => {
      state.sidebar.localeCode = null;
      state.sidebar.articleTranslationId = null;

      state.sidebar.isLoading = true;
    },
    [getArticlesSidebar.rejected]: (state) => {
      state.sidebar.isLoading = false;
    },
    [getArticlesSidebar.fulfilled]: (state, action) => {
      state.article.isFetchError = null;
      state.sidebar.localeCode = action.meta.arg.localeCode;
      state.sidebar.articleTranslationId = action.meta.arg.articleTranslationId;

      state.sidebar.popular = [...action.payload.popularArticles];
      state.sidebar.new = [...action.payload.newArticles];
      state.sidebar.isLoading = false;
    }
  }
});

export const BlogActions = {
  ...BlogSlice.actions,
  getArticlesByPage,
  getArticleByLangAndTag,
  getArticlesSidebar
};

export default BlogSlice.reducer;
