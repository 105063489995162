export const ipv4Cols = [
  {
    key: "issueDate",
    label: "Дата выдачи"
  },
  {
    key: "expirationDate",
    label: "Дата окончания"
  },
  {
    key: "country",
    label: "Страна"
  },
  {
    key: "ip",
    label: "IP"
  },
  {
    key: "actions"
  }
];

export const ipv6Cols = [
  {
    key: "ip",
    label: "IP"
  },
  {
    key: "country",
    label: "Страна"
  },
  {
    key: "activeBy",
    label: "Активен до"
  },
  {
    key: "ipStatus",
    label: "Состояние IP"
  }
];

export const mobileCols = [
  {
    key: "ip",
    label: "IP"
  },
  {
    key: "link",
    label: "Ссылка"
  },
  {
    key: "country",
    label: "Страна"
  },
  {
    key: "activeBy",
    label: "Активен до"
  },
  {
    key: "ipStatus",
    label: "Состояние IP"
  }
];

export const allOption = [{ key: "all", translation: "all", code: "all" }];