import { FaqBlock } from "../../../components/common/faq-block/faq-block";
import ReviewsBlock from "../../../components/common/reviews-block/reviews-block";
import { useSetSeoPage } from "../../../hooks";
import { getFaqByParams } from "../../../store/faq/faq.thunks";
import { fetchSeoBlocks } from "../../../store/seo-block/seo-block.thunks";

import { Features } from "./features/features";
import { Geos } from "./geos/geos";
import { Hero } from "./hero/hero";
import { Seo } from "./seo/seo";

const Home = () => {
  useSetSeoPage();

  return (
    <>
      <Hero />
      <Geos />
      <Features />
      <ReviewsBlock />
      <FaqBlock location={"home"} />
      <Seo />
    </>
  );
};

Home.getServerSideState = async (store, params) => {
  const lang = params.locale;
  // const state = await store.getState();

  // Features Block
  await store.dispatch(fetchSeoBlocks({ keys: ["mainPageFeaturesBlock"], languageCode: lang }));
  // Faq Block
  await store.dispatch(
    getFaqByParams({
      type: "faq",
      location: "home",
      language: lang
    })
  );
};
export default Home;
