import { $api } from "./axios.service";

export class HistoryService {
  static getPaymentHistory(creds) {
    return $api.post("/api/front/cabinet/history/payments", creds);
  }

  static getActionsHistory(creds) {
    return $api.post("/api/front/cabinet/history/actions", creds);
  }
}