import { createSlice } from "@reduxjs/toolkit";

import { getAllFaqByLocale, getFaqByParams } from "./faq.thunks";

const initialState = {
  faqsServer: {},
  faqsFront: {},
  faqOptions: {},
  faqKeys: [],
  isLoading: true,
  isFetchError: null,

  faqs: {
    data: {},
    loading: false,
    errors: null
  }
};

const FaqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllFaqByLocale.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllFaqByLocale.rejected]: (state, action) => {
      state.isFetchError = action.payload;
      state.isLoading = false;
    },
    [getAllFaqByLocale.fulfilled]: (state, action) => {
      state.faqsServer = { ...action.payload.data };
      state.faqsFront = { ...state.faqsFront, [action.payload.lang]: { ...action.payload.data } };
      state.faqKeys = Object.keys(state.faqsServer);
      state.faqOptions = state.faqKeys?.map((key) => ({
        key,
        translation: key
      }));
      state.isLoading = false;
    },
    //getFaqByParams
    [getFaqByParams.pending]: (state) => {
      state.faqs.loading = true;
    },
    [getFaqByParams.rejected]: (state, action) => {
      state.faqs.errors = action.payload;
      state.faqs.loading = false;
    },
    [getFaqByParams.fulfilled]: (state, action) => {
      const data = typeof action.payload === "object" ? Object.values(action.payload)[0] : {};
      state.faqs.data = data;
      state.faqs.loading = false;
    }
  }
});

export const FaqActions = {
  ...FaqSlice.actions,

  getFaqByParams,
  getAllFaqByLocale
};

export default FaqSlice.reducer;
