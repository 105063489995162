import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container } from "../../../components/common/container/container";
import HeadingWithSeo from "../../../components/common/heading-with-seo/heading-with-seo";
import { PromoCard } from "../../../components/common/promo-card/promo-card";
import { Button } from "../../../components/ui/button/button";
import { PromocodesSkeleton } from "../../../components/ui/skeletons/promocodes-skeleton/promocodes-skeleton";
import { useActions, useLang, useSetSeoPage } from "../../../hooks";
import { getPromocodesByPage } from "../../../store/promocode/promocode.thunks";
import { getPromocodeState } from "../../../store/selectors";

import { style } from "./promocodes.style";

const Promocodes = () => {
  useSetSeoPage();

  const { t } = useTranslation();
  const lang = useLang();

  const [currentPage, setCurrentPage] = useState(1);
  const { allPromocodes, promocodesPreviewLength } = useSelector(getPromocodeState);
  const { getPromocodesByPage, removePromocodes } = useActions();

  const getPromocodes = () => {
    getPromocodesByPage({ page: currentPage - 1, size: promocodesPreviewLength, localeCode: lang });
    setCurrentPage((prev) => ++prev);
  };

  const handleShowMore = () => {
    getPromocodes();
  };

  const showBtn = () => {
    if (allPromocodes?.data.length < allPromocodes?.totalElements && allPromocodes.isLoading) {
      return <></>;
    } else if (allPromocodes?.data.length < allPromocodes?.totalElements) {
      return (
        <Button onClick={handleShowMore} variant="primary" fullwidth>
          {t("pages.app.promocodes.more")}
        </Button>
      );
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (allPromocodes.locale !== lang) {
      setCurrentPage(1);
      removePromocodes();
      getPromocodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, allPromocodes.locale]);

  return (
    <Box sx={style.promocodes} className="promocodes">
      <Container>
        <Box sx={style.wrapper} className="promocodes__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h1">
            {t("pages.app.promocodes.heading")}
          </HeadingWithSeo>
          {allPromocodes.isLoading ? (
            <PromocodesSkeleton />
          ) : (
            <>
              {allPromocodes.data.length ? (
                <>
                  <Box component="ul" sx={style.list} className="promocodes__list">
                    {allPromocodes.data.map((promo) => (
                      <PromoCard key={promo.id ?? promo.code} promocode={promo} />
                    ))}
                  </Box>
                  {showBtn()}
                </>
              ) : (
                <Box sx={style.noData}>{t("pages.app.promocodes.noData")}</Box>
              )}
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

Promocodes.getServerSideState = async (store, params) => {
  const lang = params.locale;
  const state = await store.getState();

  const currentPage = 1;
  const { promocodesPreviewLength } = state.promocode;

  await store.dispatch(
    getPromocodesByPage({ page: currentPage - 1, size: promocodesPreviewLength, localeCode: lang })
  );
};
export default Promocodes;
