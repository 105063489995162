import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Container } from "../../../../components/common/container/container";
import HeadingWithSeo from "../../../../components/common/heading-with-seo/heading-with-seo";

import HeroDecor from "./hero-decor";
import HeroPanel from "./hero-panel";
import { style } from "./hero.style";

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <Box sx={style.homeHero} className="circles">
      <Container>
        <Box sx={style.wrapper}>
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h1">
            {t("pages.app.home.hero.heading")}
          </HeadingWithSeo>
          <HeroPanel />
          <Box sx={style.decor}>
            <HeroDecor />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
