import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useActions } from "./hooks";
import { ApiService, UserService } from "./services";
import {
  getMobileState,
  getProxyState,
  getReviewState,
  getSeoContentLoadedState,
  getSeoDefaultLoadedState,
  isCaptchaLoadedState
} from "./store/selectors";
import "./utils/helpers/i18n";
import { collectDefaultContent } from "./utils/helpers/serverSideState.helpers";

export const App = ({ routerProvider: RouterProvider, routerProps }) => {
  const seoDefaultLoaded = useSelector(getSeoDefaultLoadedState);
  const seoContentLoaded = useSelector(getSeoContentLoadedState);
  const { isDataLoaded } = useSelector(getMobileState);
  const { reviewsPreviewLength } = useSelector(getReviewState);
  const { contentLoaded: proxyContentLoaded } = useSelector(getProxyState);
  const isCaptchaLoaded = useSelector(isCaptchaLoadedState);

  const {
    setProxies,
    setReviews,
    setPeriods,
    fetchFingerPrintOpensourse,
    getSeoDefault,
    fetchMobile,
    setUser,
    getSeoContent,
    setToolsActive,
    fetchCaptchaInfo
  } = useActions();

  useEffect(() => {
    (async () => {
      // Get user from storage
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        setUser(user);
      }

      let visitorId = "";
      // Get fingerprint
      try {
        const fingerprintResult = await fetchFingerPrintOpensourse();
        visitorId = fingerprintResult?.payload?.visitorId ?? "";
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
      // Check is user blocked
      if (visitorId !== "") {
        try {
          const payload = { fingerprint: visitorId };
          const isBlockedResult = await UserService.isBlocked(payload);

          if (isBlockedResult?.data) {
            ApiService.blockedPaymentSystemHistory(payload);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Collect default content
  useEffect(() => {
    if (!proxyContentLoaded) {
      ApiService.getContent({ sizeOfReviews: reviewsPreviewLength + 1 })
        .then((response) => {
          if (response.data) {
            const {
              proxies,
              reviews,
              rentPeriods,
              // goalGroups
              toolsClientApiEnable
            } = response.data;

            if (toolsClientApiEnable) {
              setToolsActive(toolsClientApiEnable);
            }

            if (Object.keys(proxies).length) {
              setProxies(proxies);
            }

            setReviews(reviews);
            setPeriods(rentPeriods);
            // setGroups(goalGroups);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyContentLoaded]);
  useEffect(() => {
    if (!seoDefaultLoaded) {
      getSeoDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoDefaultLoaded]);
  useEffect(() => {
    if (!seoContentLoaded) {
      getSeoContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoContentLoaded]);

  useEffect(() => {
    if (!isDataLoaded) {
      fetchMobile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  // Collect Captcha
  useEffect(() => {
    if (!isCaptchaLoaded) {
      fetchCaptchaInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaLoaded]);

  return <RouterProvider {...routerProps} />;
};

App.getServerSideState = async (store) => {
  await collectDefaultContent(store);
};
