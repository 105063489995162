export const style = {
  customButton: {
    lineHeight: 1,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3rem",
    cursor: "pointer",
    textDecoration: "none",
    fullwidthMod: {
      width: "100%"
    },

    primaryMod: {
      fontWeight: "700",
      color: "var(--clr-text-100)",
      backgroundImage: "linear-gradient(97.86deg, #517ff5 0%, #4ba7ea 103.85%)",
      transition: "box-shadow var(--transition)",

      "&:hover": {
        boxShadow: "0 10px 20px rgba(71 155 246 / 40%)"
      }
    },

    secondaryMod: {
      fontWeight: "600",
      color: "var(--clr-text-700)",
      backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
      transition: "background-color var(--transition)",

      "&:hover": {
        backgroundColor: "rgba(var(--clr-primary-400-rgb) / 45%)"
      }
    },

    outlinedMod: {
      padding: "0.6rem 2.4rem",
      fontWeight: "700",
      color: "var(--clr-text-600)",
      transition: "box-shadow var(--transition)",
      border: "1px solid var(--clr-default-600)",
      // outlineOffset: "-1px",

      "&:hover": {
        borderColor: "transparent",
        color: "var(--clr-text-100)",
        backgroundImage: "linear-gradient(97.86deg, #517ff5 0%, #4ba7ea 103.85%)",
        boxShadow: "0 10px 20px rgba(71 155 246 / 40%)"
      }
    },

    sizeMod: {
      default: {
        minHeight: "5rem",
        fontSize: "1.6rem",

        "&.button_variant_primary": {
          padding: "0.6rem 2.4rem"
        },

        "&.button_variant_secondary": {
          padding: "0.5rem 4.5rem"
        }
      },

      small: {
        minHeight: "3.6rem",
        fontSize: "1.4rem",

        "&.button_variant_primary": {
          padding: "0.6rem 2.4rem"
        },

        "&.button_variant_secondary": {
          padding: "0.5rem 4.5rem"
        }
      },
      medium: {
        minHeight: "4.4rem",
        fontSize: "1.6rem",

        "&.button_variant_primary": {
          padding: "0.6rem 2.4rem"
        },

        "&.button_variant_secondary": {
          padding: "0.5rem 4.5rem"
        }
      },
      modal: {
        minHeight: "3.6rem",
        fontSize: "1.4rem",
        "&.button_variant_primary": {
          padding: "0.7rem 3rem",
          fontWeight: "500"
        },

        "&.button_variant_secondary": {
          padding: "0.7rem 3rem",
          fontWeight: "500"
        }
      }
    }
  },

  disabled: {
    opacity: "0.7",
    pointerEvents: "none"
  },

  tooltip: {

    // '& .MuiTooltip-tooltip': {
    //   fontSize: "24px !important"
    // }
    "& .MuiTooltip-tooltip": {
      background: "#000"
    }
  },

  tooltipText: {
    fontSize: "14px !important"
  },

  progress: {
    marginRight: "10px"
  }
};
