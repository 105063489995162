import { Box } from "@mui/material";
import { useRef } from "react";

import { TablePagination } from "./pagination/pagination";
import { TableAction } from "./table-action";
import { TableBody } from "./table-body";
import { TableCell } from "./table-cell";
import { TableCheckbox } from "./table-checkbox";
import { TableHead } from "./table-head";
import { TableLink } from "./table-link";
import { TableRow } from "./table-row";
import { TableSearch } from "./table-search";
import { TableSelect } from "./table-select";
import { TableSort } from "./table-sort";

import { style } from "./table.style";
import { TableSkeleton } from "./table-skeleton";

export const Table = ({
  children,
  size = "default",
  pagination = false,
  totalElements = 10,
  totalPages = 10,
  currentPage = 1,
  onPageChange,
  outputCountHandler,
  pageSize,
  elementsOnPage = 10,
  layout = "auto",
  loading = false,
  head = null,
  hasGroupRow = false
}) => {
  const tableRef = useRef(null);

  const defineClasses = () => {
    let classes = "table";

    switch (size) {
      case "default":
        classes += " table_size_default";
        break;
      case "large":
        classes += " table_size_large";
        break;
      default:
        break;
    }

    switch (layout) {
      case "auto":
        classes += " table_layout_auto";
        break;
      case "fixed":
        classes += " table_layout_fixed";
        break;
      default:
        break;
    }

    return classes;
  };

  const defineStyles = () => {
    const styles = [style.tableStyle];

    switch (size) {
      case "default":
        styles.push(style.tableSize.default);
        break;
      case "large":
        styles.push(style.tableSize.large);
        break;
      default:
        break;
    }

    switch (layout) {
      case "auto":
        styles.push(style.tableLayout.auto);
        break;
      case "fixed":
        styles.push(style.tableLayout.fixed);
        break;
      default:
        break;
    }

    return styles;
  };

  return (
    <>
      <Box ref={tableRef} className={defineClasses()} sx={defineStyles()}>
        <Box sx={style.tableStyle.container}>
          <table cellSpacing="0">
            { head }
            {loading ? <tbody><TableSkeleton count={elementsOnPage} hasGroupRow={hasGroupRow} /></tbody> : (
              <>
                { children }
              </>
            )}
          </table>
        </Box>
      </Box>
      {pagination && (
        <TablePagination
          onPageChange={onPageChange}
          scrollRef={tableRef}
          elementsOnPage={elementsOnPage}
          totalElements={totalElements}
          totalPages={totalPages}
          currentPage={currentPage}
          outputCountHandler={outputCountHandler}
          pageSize={pageSize}
        />
      )}
    </>
  );
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Checkbox = TableCheckbox;
Table.Search = TableSearch;
Table.Select = TableSelect;
Table.Sort = TableSort;
Table.Link = TableLink;
Table.Action = TableAction;
