import { appTheme } from "../../../theme"

export const style = {
  infoContent: {
    offset: {
      paddingLeft: '1.2rem'
    },

    h1: {
      marginBottom: '4rem',
      fontWeight: '700',
      fontSize: '3.6rem',
      color: 'var(--clr-text-600)',
      lineHeight: 'normal',
    },

    h2: {
      marginTop: '3.5rem',
      marginBottom: '2.5rem',
      fontWeight: '600',
      fontSize: '2rem',
      color: 'var(--clr-text-500)',
    },

    h3: {
      marginTop: '3.5rem',
      marginBottom: '2rem',
      fontWeight: '500',
      fontSize: '1.8rem',
      color: 'var(--clr-text-400)',
    },

    p: {
      marginBottom: '2rem',
      fontWeight: '400',
      fontSize: '1.4rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      span: {
        color: 'var(--clr-primary-400)',
      }
    },

    'ul, ol': {
      marginBottom: '2rem',

      li: {
        position: 'relative',
        marginBottom: '1.5rem',
        paddingLeft: '2rem',
        fontWeight: '400',
        fontSize: '1.4rem',
        lineHeight: '157%',
        color: 'var(--clr-text-400)',
      },

      // @media (max-width: em(576)) {
      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '2.5rem',
      }
    },

    ul: {
      'li::before': {
        content: `""`,
        position: 'absolute',
        left: '0.5rem',
        top: '0.9rem',
        borderRadius: '50%',
        width: '0.3rem',
        height: '0.3rem',
        backgroundColor: 'var(--clr-primary-400)',
      }
    }
  },


}
