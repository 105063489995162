import React, { memo } from "react";
import { Heading } from "../../ui";
import useCurrentSeo from "../../../hooks/use-current-seo";

const HeadingWithSeo = (props) => {
  const { seoHeading, ...rest } = props;

  if(seoHeading !== "h1" && seoHeading !== "h2") {
    throw new Error(`Error: seoHeading muse be one of h1, h2.`)
  }
  const seo = useCurrentSeo();

  let children = rest.children;

  const seoH1 = seo[seoHeading];
  if (seoH1) {
    children = seoH1;
    const seoH2 = seo[`${seoHeading}Text`];

    if (seoH2) {
      children = children + seoH2;
    }
  }

  return (
    <Heading {...rest} children={children} />
  );
};

export default memo(HeadingWithSeo);
