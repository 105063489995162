import React, { forwardRef, memo } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import { Alert } from "@mui/material";

const ResponsiveSnackbar = forwardRef((props, forwardedRef) => {
  const { message, variant, id} = props;
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent
      ref={forwardedRef}
      style={{
        minWidth: "auto"
      }}
    >
      <Alert
        onClose={() => closeSnackbar(id)}
        severity={variant === "default" ? "info" : variant}
        sx={{
          fontSize: "16px",
          width: "100%",
          ".MuiAlert-action": {
            alignItems: "center",
            paddingTop: 0,
            "button svg": {
              width: "25px",
              height: "25px"
            }
          }
        }}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});

export default memo(ResponsiveSnackbar);
