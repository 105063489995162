import { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// import { OrdersService } from "../../../services/orders.service";
import { Heading } from "../../../components/ui/heading/heading";
import { useLang, useSetSeoPage } from "../../../hooks";
import { OrdersService } from "../../../services";
import { getProxyState } from "../../../store/selectors";
import { getProxyType } from "../../../utils/helpers/order.helpers";
import AutoRenewal from "../auto-renewal/auto-renewal";

import { Export } from "./export/export";
import { Ipv4 } from "./ipv4/ipv4";
import { Ipv6 } from "./ipv6/ipv6";
import { Isp } from "./isp/isp";
import { MobileProxy } from "./mobile-proxy/mobile-proxy";
import { style } from "./orders.style";

const Orders = ({ tag }) => {
  useSetSeoPage();

  // eslint-disable-next-line prefer-destructuring
  const proxyType = tag.split("/")[1];

  const lang = useLang();
  //translation
  const { t } = useTranslation();
  //state
  const [activeProxy, setActiveProxy] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [ipAddressesIds, setIpAddressesIds] = useState([]);

  //Redux
  const { proxyTypeIds } = useSelector(getProxyState);

  // set active orders
  useEffect(() => {
    if (proxyTypeIds.length) {
      (async () => {
        try {
          const res = await OrdersService.getActiveOrders(
            proxyTypeIds.find((el) => el.name === getProxyType(proxyType))?.id
          );
          if (res.data) setActiveProxy(res.data);
          if (res.status === 204) setActiveProxy([]);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyType, proxyTypeIds.length]);

  const handlerSelectIpAddreses = (payload) => {
    setIpAddressesIds(payload);
  };

  const handleUpdate = () => {
    setIpAddressesIds([]);
    setShouldUpdate(!shouldUpdate);
  };
  const pageContent = useMemo(() => {
    switch (proxyType) {
      case "ipv4":
        return (
          <Ipv4
            shouldUpdate={shouldUpdate}
            onChangeSelected={handlerSelectIpAddreses}
            onUpdateParentComponent={handleUpdate}
          />
        );
      case "ipv6":
        return (
          <Ipv6
            shouldUpdate={shouldUpdate}
            onChangeSelected={handlerSelectIpAddreses}
            onUpdateParentComponent={handleUpdate}
          />
        );
      case "isp":
        return (
          <Isp
            shouldUpdate={shouldUpdate}
            onChangeSelected={handlerSelectIpAddreses}
            onUpdateParentComponent={handleUpdate}
          />
        );
      case "mobile-proxy":
        return (
          <MobileProxy
            shouldUpdate={shouldUpdate}
            onChangeSelected={handlerSelectIpAddreses}
            onUpdateParentComponent={handleUpdate}
          />
        );
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyType, shouldUpdate]);

  return (
    <Box sx={style.cabinetOrders} className="cabinet-orders">
      <Heading tag="h1" variant="h2">
        {t(`pages.cabinet.orders.tags.${proxyType}.heading`)}
      </Heading>

      <Box sx={style.widgets} className="cabinet-orders__widgets">
        <Box sx={style.widget} className="cabinet-orders__widget">
          <Heading tag="h3" variant="h4">
            {t("pages.cabinet.orders.widgets.system.title")}
          </Heading>

          <Box component="ul">
            {activeProxy.map((el, i) => (
              <Box
                component="li"
                key={i}
                sx={style.widget.row}
                className="cabinet-orders__widget-row"
              >
                {t("pages.cabinet.orders.widgets.system.rowLabel")} {el.localeDependentNames[lang]}:{" "}
                <span>{el.count}</span>
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={style.widget} className="cabinet-orders__widget">
          <Export proxyType={proxyType} ipAddressesIds={ipAddressesIds.map(({ id }) => id)} />
        </Box>
        <Box sx={style.widget} className="cabinet-orders__widget">
          <AutoRenewal
            proxyType={proxyType}
            ipAddressesIds={ipAddressesIds}
            onUpdateParentComponent={handleUpdate}
          />
        </Box>
      </Box>

      {pageContent}
    </Box>
  );
};

export default Orders;
