import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  faq: {
    paddingTop: fluid(14, 8),
    paddingBottom: fluid(7, 4),

    ".heading": {
      paddingLeft: "29.6rem",

      [appTheme.breakpoints.down("bp992")]: {
        marginBottom: "2.7rem",
        paddingLeft: "0"
      }
    },

    ".loader_type_app": {
      minHeight: "initial"
    },

    ".tabs__header": {
      width: "min(100%, 25rem)"
    },

    [appTheme.breakpoints.down("bp992")]: {
      ".tabs__header": {
        marginBottom: "3.6rem"
      }
    }
  },

  skeleton: {
    display: "flex",
    alignItems: "flex-start",
    [appTheme.breakpoints.down("bp992")]: { flexDirection: "column" },

    tabs: {
      width: "min(100%, 25rem)",
      padding: "2rem 1.5rem 2rem 2rem",
      flexShrink: "0",
      [appTheme.breakpoints.down("bp992")]: { padding: "unset" },
      item: {
        width: "100%",
        height: "4rem",
        borderRadius: "3rem",
        [appTheme.breakpoints.down("bp992")]: { height: "4.4rem", marginBottom: "2rem" }
      }
    },

    content: {
      paddingTop: "2.8rem",
      paddingBottom: "2.8rem",
      paddingLeft: "4.6rem",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [appTheme.breakpoints.down("bp992")]: { padding: "unset" },

      item: {
        display: "flex",
        flexDirection: "column"
      },

      itemHeading: {
        marginTop: "1.5rem",
        // marginRight: "4rem",
        marginBottom: "1.5rem",
        height: "2.3rem"
        // width: "80%"
      },

      itemAnswer: {
        "& .string": {
          width: "60%",
          height: "1.6rem",
          "&:first-of-type": {
            width: "100%",
            marginBottom: "1.3rem"
          }
        }
      }
    }
  },

  noData: {
    fontSize: "2rem",
    // textAlign: "center",
    paddingLeft: "29.6rem",
    marginTop: "5rem"
  }
};
