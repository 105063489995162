import Forgot from "../pages/auth/forgot/forgot";
import Login from "../pages/auth/login/login";
import Register from "../pages/auth/register/register";
import { ROUTE } from "../utils/constants";

export const authRoutes = (ssr = false) => [
  {
    path: `/:lang?${ROUTE.AUTH.LOGIN}`,
    tag: "login",
    isSsr: ssr ?? false,
    component: ssr ? Login : () => import("../pages/auth/login/login")
  },
  {
    path: `/:lang?${ROUTE.AUTH.REGISTER}`,
    tag: "register",
    isSsr: ssr ?? false,
    component: ssr ? Register : () => import("../pages/auth/register/register")
  },
  {
    path: `/:lang?${ROUTE.AUTH.FORGOT}`,
    tag: "forgot",
    isSsr: ssr ?? false,
    component: ssr ? Forgot : () => import("../pages/auth/forgot/forgot")
  },
  {
    path: `/:lang?${ROUTE.AUTH.RECOVERY}/:hash`,
    tag: "restore",
    isSsr: ssr ?? false,
    component: ssr ? Forgot : () => import("../pages/auth/forgot/forgot")
  }
];
