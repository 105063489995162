
export const FONTS_LINK = [
  {
    rel: "prefetch",
    href: `/fonts/Gilroy-UltraLight.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/Gilroy-Regular.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/Gilroy-Medium.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/Gilroy-Semibold.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/Gilroy-Bold.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/SFPro-Medium.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/SFPro-Regular.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/FuturaNow-Bold.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/Gibson-Regular.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "prefetch",
    href: `/fonts/NotoSans-Regular.woff2`,
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }
];
