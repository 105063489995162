import { Box } from "@mui/material";
import { Container } from "../../../../components/common/container/container";
import { SeoBlock } from "../../../../components/common/seo-block/seo-block";

// import "./seo.scss";
import { style } from "./seo.style";

const data =
  "";

export const Seo = () => (
  <Box sx={style.priceSeo} className="price-seo">
    <Container>
      <SeoBlock data={data} />
    </Container>
  </Box>
);
