export const orderProcessing = (type) => {
  let newUrl = type

  // if interkassa
  if (type.includes("ik_sign=")) {
    const sign = encodeURIComponent(type.split("ik_sign=")[1]);
    const url = type.split("ik_sign=")[0];

    newUrl = url + "ik_sign=" + sign
  }

  window.location.replace(newUrl)
}
// https://sci.interkassa.com/?ik_desc=Order+payment+6430270293dae13a42ef9d85+%28gto200313%40gmail.com%29+from+youproxy&ik_suc_u=https%3A%2F%2Fstage.youproxy.io%2Fapi%2Fpayment%2Finterkassa%2Fsuccess&ik_pnd_m=POST&ik_co_id=null&ik_pm_no=6430270293dae13a42ef9d85&ik_suc_m=POST&ik_pnd_u=https%3A%2F%2Fstage.youproxy.io%2Fapi%2Fpayment%2Finterkassa%2Fpending&ik_am=1.30&ik_ia_m=POST&ik_fal_u=https%3A%2F%2Fstage.youproxy.io%2Fapi%2Fpayment%2Finterkassa%2Fcancel&ik_ia_u=https%3A%2F%2Fstage.youproxy.io%2Fapi%2Fpayment%2Finterkassa&ik_cur=USD&ik_fal_m=POST
