import React, { useState } from "react";

import { Box, Fade, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import { CloseIcon } from "../../../../../assets/images/icons/close-icon";
import { SearchIcon } from "../../../../../assets/images/icons/search-icon";
import { fluid } from "../../../../../assets/styles/functions/fluid";
import { Form } from "../../../../../components/form";
import { Button } from "../../../../../components/ui";
import Tooltip from "../../../../../components/ui/tooltip/tooltip";
// import { useTranslation } from "../../../../../hooks";
import useProxyCountryOrType from "../../../../../hooks/use-proxy-country-or-type";

import { style } from "./countries-prices.style";

const CountriesPrices = (props) => {
  const { loading, list, onBuy } = props;

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("bp928"));

  const { t } = useTranslation();

  const { type: proxyType } = useProxyCountryOrType();

  const [searchValue, setSearchValue] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);

  const filteredData = list.filter((e) => e.name.toLowerCase().includes(searchValue.toLowerCase()));

  const handlerSearch = (event) => {
    setSearchValue(event.target.value);
  };
  const handlerClearSearch = () => {
    setSearchValue("");
  };

  const handlerBuy = (index) => {
    onBuy({
      country: filteredData[index],
      quantity: filteredData[index].info[0].quantity
    });
  };

  const searchLabel = t("pages.app.buyProxy.packet.table.searchLabel").replace(
    "{{proxyFor}}",
    proxyType
  );

  const minWidth = isTablet ? 30 : 280;

  return (
    <Box className={"table-list"} sx={style.tableContainer}>
      <Stack spacing={1} sx={style.table}>
        <Box sx={{ ...style.tableHead, padding: activeSearch ? "1rem 1.4rem" : "2.48rem 1.4rem" }}>
          <Box
            sx={{
              paddingRight: "2rem",
              flexBasis: minWidth
            }}
          >
            {!activeSearch ? (
              <Box sx={style.startSearch.container} onClick={() => setActiveSearch(true)}>
                <Tooltip title={searchLabel}>
                  <Box component="span" sx={style.startSearch.label}>
                    {searchLabel}
                  </Box>
                </Tooltip>
                <SearchIcon />
              </Box>
            ) : (
              <Fade in={activeSearch}>
                <Box>
                  <Form.Input
                    // name="search-country-name"
                    value={searchValue}
                    onInput={handlerSearch}
                    onBlur={() => {
                      !searchValue && setActiveSearch(false);
                    }}
                    autoFocus={activeSearch}
                    actions={
                      searchValue ? (
                        <Box sx={style.closeIcon} onClick={handlerClearSearch}>
                          <CloseIcon />
                        </Box>
                      ) : (
                        <SearchIcon />
                      )
                    }
                  />
                </Box>
              </Fade>
            )}
          </Box>
          <Box>{t("pages.app.buyProxy.packet.table.countPrice")}</Box>
        </Box>
        <Stack
          spacing={1}
          sx={{
            minWidth: "40rem"
          }}
        >
          {filteredData.map((row, index) => {
            const handlerClick = () => {
              handlerBuy(index);
            };

            return (
              <Box key={row.id} sx={style.tableRow}>
                <Stack spacing={1.5} direction="row" alignItems="center" flexBasis={minWidth}>
                  <Box
                    component="img"
                    src={row.image}
                    width={isTablet ? 25 : 40}
                    height={isTablet ? 25 : 40}
                    alt={row.name}
                    aria-hidden={true}
                    loading="lazy"
                    sx={{
                      objectFit: "cover",
                      borderRadius: "50%"
                    }}
                  />
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "500",
                      fontSize: fluid(1.8, 1.6),
                      whiteSpace: "nowrap",
                      color: "var(--clr-text-900)"
                    }}
                  >
                    {row.name}
                  </Box>
                </Stack>

                <Box sx={style.priceContainer}>
                  {row.info
                    .map(({ amount, quantity }, idx) => (
                      <Box key={`${idx}:${quantity}:${amount}`}>
                        <Box sx={style.priceContainer.quantity}>
                          {quantity} {t("other.ips")}
                        </Box>
                        <Box sx={style.priceContainer.amount}>${amount}</Box>
                      </Box>
                    ))
                    .filter((item, index) => {
                      if (proxyType == "ISP") {
                        return isTablet
                          ? [0, 1, 3, 4].includes(index)
                          : [0, 1, 3, 4, 6].includes(index);
                      }
                      return isTablet ? [0, 1, 2, 3].includes(index) : true;
                    })}
                </Box>

                <Box>
                  <Button
                    variant="outlined"
                    size={isTablet ? "small" : "medium"}
                    onClick={handlerClick}
                  >
                    {t("common.order")}
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CountriesPrices;
