import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FaqBlock } from "../../../components/common/faq-block/faq-block";
import ReviewsBlock from "../../../components/common/reviews-block/reviews-block";
import { useLang, useSetSeoPage } from "../../../hooks";
import useProxyCountryOrType from "../../../hooks/use-proxy-country-or-type";
import { getFaqByParams } from "../../../store/faq/faq.thunks";
import { fetchMobile } from "../../../store/mobile/mobile.thunks";
import { getMobileState } from "../../../store/selectors.js";
import { fetchSeoBlocks } from "../../../store/seo-block/seo-block.thunks";
import { getSearchParams } from "../../../utils/helpers";

import { FeaturesBlock } from "./features-block/features-block";
import { Hero } from "./hero/hero";
import MobileProductMeta from "./mobile-product-meta/mobile-product-meta";
import { Seo } from "./seo/seo";

const MobileProxy = () => {
  useSetSeoPage();
  const lang = useLang();
  const dispatch = useDispatch();
  const { country: countrySlug } = useProxyCountryOrType();
  const { countries } = useSelector(getMobileState);
  const [countryUrl, setCountryUrl] = useState("");

  useEffect(() => {
    const loadCountries = async () => {
      if (countries.length === 0) {
        await dispatch(fetchMobile());
      }
      if (countries.length > 0) {
        setCountryUrl(countrySlug ?? countries[0].urlParam);
      }
    };

    loadCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length, countrySlug]);

  return (
    <>
      <MobileProductMeta />
      <Hero />
      {countryUrl && <FeaturesBlock lang={lang} countryName={countryUrl} />}
      <ReviewsBlock />
      {countryUrl && <FaqBlock location={"mobile"} country={countryUrl} />}
      <Seo />
    </>
  );
};

MobileProxy.getServerSideState = async (store, params) => {
  const lang = params.locale;
  const searchCountry = getSearchParams(params.location)?.country;

  // Hero
  await store.dispatch(fetchMobile());

  // Features Block
  await store.dispatch(
    fetchSeoBlocks({
      keys: ["proxyMobileProxyFeaturesBlock", "mobileProxyPageCharacteristicsNoteBlock"],
      languageCode: lang
    })
  );
  // Faq Block
  await store.dispatch(
    getFaqByParams({
      type: "faq",
      location: "mobile",
      language: lang,
      params: { country: searchCountry }
    })
  );
};
export default MobileProxy;
