import React, { useState } from "react";

import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

import { MessageCard, SeoBlockField } from "../../../../../components/common";
import { Button } from "../../../../../components/ui";
import { useLang } from "../../../../../hooks";
import { FeedbackService } from "../../../../../services/feedback.service";
import { FEEDBACK_TYPE } from "../../../../../utils/constants";
import { getCaptchaTokenFromFormData, htmlspecialchars } from "../../../../../utils/helpers";
import ContactUsModal from "../contact-us-modal/contact-us-modal";

import { style } from "./enterprise-block.style";

const EnterpriseBlock = ({ seoData }) => {
  const { t } = useTranslation();
  const lang = useLang();

  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    iconPath: "",
    title: "",
    description: "",
    visible: false,
    action: t("contacts.serverError.action")
  });

  const closeHandler = () => setModalMessage({ ...modalMessage, visible: false });

  const handlerSubmit = (data, resetForm, resetCaptcha) => {
    setLoading(true);

    const payload = {
      type: FEEDBACK_TYPE.ENTERPRISE,
      email: data.email,
      name: htmlspecialchars(data.name),
      text: htmlspecialchars(data.message),
      localeCode: lang,
      additionalFields: {
        other: htmlspecialchars(data.other),
        companyName: htmlspecialchars(data.companyName)
      }
    };

    FeedbackService.sendFeedback(payload, getCaptchaTokenFromFormData(data))
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(response);
        }

        setModalMessage({
          iconPath: "/images/icons/feedback/feedback-success.svg",
          title: t("contacts.serverSuccess.title"),
          description: t("contacts.serverSuccess.description"),
          visible: true,
          action: t("contacts.serverError.action")
        });

        setOpenModal(false);
        resetForm();
      })
      .catch((error) => {
        let messageObj = {
          title: t("contacts.serverError.title"),
          description: t("contacts.serverError.description"),
          action: t("contacts.serverError.action")
        };

        if (e.request.status === 403) {
          messageObj = {
            title: t("messages.temporaryEmails"),
            description: "",
            action: t("contacts.serverError.action")
          };
        } else if (error.response.status === 406) {
          messageObj = {
            title: t("contacts.serverNotValidMail.title"),
            description: t("contacts.serverNotValidMail.description"),
            action: t("contacts.serverNotValidMail.action")
          };
        } else if (error.response.status === 409) {
          messageObj = {
            title: t("messages.captchaFailed"),
            description: t("messages.pleaseResubmitTheForm"),
            action: t("contacts.serverError.action")
          };
        }

        setModalMessage({
          ...messageObj,
          iconPath: "/images/icons/feedback/feedback-error.svg",
          visible: true
        });
      })
      .finally(() => {
        setLoading(false);
        resetCaptcha();
      });
  };

  return (
    <>
      <ContactUsModal
        loading={loading}
        seoData={seoData}
        open={openModal}
        onSubmit={handlerSubmit}
        onClose={() => setOpenModal(false)}
      />
      {/* Success || error message */}
      <Modal open={modalMessage.visible} onClose={closeHandler}>
        <Box
          sx={(theme) => ({
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down("bp576")]: {
              width: "90%"
            }
          })}
        >
          <MessageCard
            type="feedback"
            iconPath={modalMessage.iconPath}
            title={modalMessage.title}
            description={modalMessage.description}
            actionLabel={modalMessage.action}
            actionEvent={closeHandler}
          />
        </Box>
      </Modal>

      <Box sx={style.container} className={"proxyCountryPageEnterprise"}>
        <Box sx={style.title}>
          <SeoBlockField defaultValue={t("common.enterprise")} field={seoData?.title} />
        </Box>
        {seoData?.description !== "" ? (
          <Box component="p" sx={style.description}>
            <SeoBlockField defaultValue={``} field={seoData?.description} />
          </Box>
        ) : null}
        <Button variant={"outlined"} onClick={() => setOpenModal(true)}>
          {t("common.contactUs")}
        </Button>
      </Box>
    </>
  );
};

export default EnterpriseBlock;
