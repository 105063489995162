import { appTheme } from "../../../theme";

export const style = {
  modalUpper: {
    borderTopRightRadius: "3rem",
    borderTopLeftRadius: "3rem",
    padding: "4rem 4rem 3.2rem",
    background: "#ffffff",
    '& > .heading': {
      marginBottom: '0.7rem',
      color: 'var(--clr-text-500)',
    },

    [appTheme.breakpoints.down("bp460")]: {
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "2rem"
    }
  },

  modalBottom: {
    borderBottomRightRadius: "3rem",
    borderBottomLeftRadius: "3rem",
    padding: "3.2rem 4rem 4rem",
    background: "#F9FAFE",
    [appTheme.breakpoints.down("bp460")]: {
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "2rem",
      paddingTop: "2rem"
    }
  },

  fieldGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "1.6rem",
    [appTheme.breakpoints.down("bp576")]: {
      gridTemplateColumns: "repeat(1, 1fr)"
    }
  },

  // modal: {
  //   '.order-modal__item': {
  //     '&:not(:last-child)': {
  //       marginBottom: '1.5rem',
  //     }
  //   },
  // },

  subtitle: {
    display: 'inline-block',
    marginBottom: '2.4rem',
    fontWeight: '400',
    fontSize: '1.4rem',
    color: 'var(--clr-text-400)',
  },

  promocode: {
    paddingTop: '1.7rem',
    fontWeight: '500',
    textDecoration: 'underline',
    color: 'var(--clr-text-900)',
    cursor: 'pointer',
    marginTop: '-1.5rem',
    maxWidth: 'min-content',
    whiteSpace: 'nowrap'
  },

  info: {
    padding: '2.4rem 0',

    group: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '2rem',

      '&:not(:last-child)': {
        marginBottom: '1.8rem',
      }
    },

    label: {
      fontWeight: '400',
    },

    value: {
      fontWeight: '500',
    },

    error: {
      color: '#AA1803',
      display: 'block',
      margin: '0 auto',
      marginBottom: '20px'
    }
  },
}