import React from "react";

export const WifiColoredIcon = () => (
  <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M28.3112 2.09042C27.9567 2.21796 27.5694 2.57595 27.3866 2.94509L27.2227 3.27595V17.4974C27.2227 30.3808 27.2332 31.7416 27.334 31.9611C27.5132 32.3512 27.8183 32.6612 28.1944 32.8353C28.5287 32.9901 28.6169 32.9978 30.0685 32.9984C31.0252 32.9988 31.692 32.9692 31.8614 32.9188C32.2307 32.8088 32.6421 32.4415 32.8373 32.0474L33 31.7188V17.4974C33 4.51782 32.9901 3.25436 32.8869 3.02868C32.7171 2.65734 32.3744 2.30439 32.0255 2.14135C31.736 2.00606 31.6012 1.99602 30.1295 2.00093C29.0723 2.00446 28.4674 2.03422 28.3112 2.09042ZM20.2685 8.67657C19.8548 8.83083 19.5837 9.06903 19.3555 9.47872L19.1485 9.85034V20.7846V31.7188L19.3112 32.0474C19.5064 32.4415 19.9178 32.8088 20.2871 32.9188C20.6507 33.0271 23.4236 33.0271 23.7871 32.9188C24.1565 32.8088 24.5678 32.4415 24.763 32.0474L24.9258 31.7188L24.9466 21.096C24.958 15.2535 24.9484 10.313 24.9251 10.1173C24.8651 9.61235 24.525 9.07795 24.0962 8.81477L23.7539 8.60467L22.1472 8.58993C20.8774 8.57823 20.4835 8.59636 20.2685 8.67657ZM12.1943 15.8762C11.7829 16.026 11.5103 16.2648 11.2813 16.6759L11.0742 17.0476V24.3832V31.7188L11.237 32.0474C11.4322 32.4415 11.8435 32.8088 12.2129 32.9188C12.3822 32.9692 13.0491 32.9988 14.0058 32.9984C15.4573 32.9978 15.5455 32.9901 15.8799 32.8353C16.256 32.6612 16.561 32.3512 16.7403 31.9611C16.84 31.744 16.8515 30.9575 16.8515 24.3832V17.0476L16.6445 16.6759C16.4098 16.2546 16.1419 16.025 15.7069 15.8725C15.2906 15.7265 12.5975 15.7293 12.1943 15.8762ZM4.32251 22.4036C3.82051 22.5319 3.4097 22.8614 3.14958 23.3442C3.00139 23.6193 3 23.6604 3 27.6704V31.7188L3.16274 32.0474C3.35791 32.4415 3.76928 32.8088 4.13861 32.9188C4.30796 32.9692 4.97485 32.9988 5.93151 32.9984C7.38306 32.9978 7.47125 32.9901 7.80564 32.8353C8.18172 32.6612 8.4868 32.3512 8.66603 31.9611C8.76383 31.7481 8.77726 31.2385 8.77726 27.7396C8.77726 23.3502 8.78575 23.4425 8.33861 22.9505C7.8497 22.4125 7.71919 22.3793 6.02784 22.3638C5.20476 22.3563 4.43735 22.3741 4.32251 22.4036Z"
          fill="url(#paint0_linear_581_14897)" />
    <g filter="url(#filter0_bi_581_14897)">
      <rect x="16" y="13" width="28.8" height="17.4857" rx="3" fill="#BCE4F1" fillOpacity="0.5" />
    </g>
    <g clipPath="url(#clip0_581_14897)">
      <path
        d="M30.5002 26.1875H30.5065M37.2543 19.438C35.475 17.8075 33.1038 16.8125 30.5002 16.8125C27.8966 16.8125 25.5254 17.8075 23.7461 19.438M25.9577 21.6519C27.169 20.5848 28.759 19.9375 30.5002 19.9375C32.2414 19.9375 33.8314 20.5848 35.0428 21.6519M32.8117 23.8594C32.1748 23.3602 31.3722 23.0625 30.5002 23.0625C29.6149 23.0625 28.8013 23.3692 28.1599 23.8822"
        stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter id="filter0_bi_581_14897" x="12" y="9" width="36.7998" height="25.4857" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_581_14897" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_581_14897" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feOffset dx="0.2" dy="0.1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_581_14897" />
      </filter>
      <linearGradient id="paint0_linear_581_14897" x1="3" y1="2" x2="37.8331" y2="6.65266"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor="#517FF5" />
        <stop offset="1" stopColor="#4BA7EA" />
      </linearGradient>
      <clipPath id="clip0_581_14897">
        <rect width="15" height="15" fill="white" transform="translate(23 14)" />
      </clipPath>
    </defs>
  </svg>

);
