import { createSlice } from "@reduxjs/toolkit";

import { filterCountriesByIpCode } from "../../utils/tools";

import { fetchMobile } from "./mobile.thunks";

export const initialMobileState = {
  loading: false,
  errors: [],
  countries: [],
  isDataLoaded: false
};

const MobileSlice = createSlice({
  name: "mobile",
  initialState: initialMobileState,
  reducers: {
    filterMobileCountries(state, action) {
      const countryIpCode = action.payload;
      const { countries } = JSON.parse(JSON.stringify(state));

      if (["UA", "RU"].includes(countryIpCode) && countries.length > 0) {
        state.countries = filterCountriesByIpCode(countries, countryIpCode);
      }
    }
  },
  extraReducers: {
    [fetchMobile.pending]: (state) => {
      state.loading = true;
    },
    [fetchMobile.rejected]: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    [fetchMobile.fulfilled]: (state, action) => {
      state.countries = Object.keys(action.payload).map((el) => ({ ...action.payload[el] }));
      state.loading = false;
      state.isDataLoaded = true;
    }
  }
});

export const MobileActions = { ...MobileSlice.actions, fetchMobile };

export default MobileSlice.reducer;
