import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import { ReviewsCard } from "../reviews-card/reviews-card";

import { style } from "./reviewsSlider.style";

import "./reviews-slider.scss";

export const ReviewsSlider = ({ reviews, dataItemReviewed }) => (
  <Box sx={style.slider}>
    <Swiper spaceBetween={15} slidesPerView={1.3} wrapperTag="ul" className="reviews-slider">
      {reviews?.map((review) => (
        <SwiperSlide key={review.id} tag="li">
          <ReviewsCard dataItemReviewed={dataItemReviewed} review={review} />
        </SwiperSlide>
      ))}
    </Swiper>
  </Box>
);
