import { useEffect, useRef, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Widget } from "../../../components/common/widget/widget";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
// eslint-disable-next-line import/named
import { Evercookie, getRandomNumberInRange } from "../../../utils/helpers";

import { CheckEvercookieSeo } from "./check-evercookie-seo";
import { style } from "./checkEvercookie.style";

const CheckEvercookie = () => {
  useSetSeoPage();
  const seo = useCurrentSeo();

  const { t } = useTranslation();
  const ref = useRef(null);
  const [id, setId] = useState(null);
  const [data, setData] = useState(null);
  const [loadingUid, setLoadingUid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ref.current = new Evercookie({
      refreshTimeInMs: 4000
    });
    setLoadingUid(true);

    const timer = setTimeout(() => {
      ref.current.get().then(({ mostFrequentElement }) => {
        if (mostFrequentElement) {
          setId(mostFrequentElement);
          ref.current.set(mostFrequentElement);
        } else {
          ref.current.stop();
        }

        setLoadingUid(false);
      });
    }, 2000);

    return () => {
      clearTimeout(timer);
      ref.current.stop();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        ref.current.get().then(({ list }) => {
          setData(list);

          setLoading(false);
        });
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [loading]);

  const handlerClick = () => {
    if (loading) {
      setLoading(false);
    } else if (!id) {
      const uid = getRandomNumberInRange(1, 1000);

      ref.current.set(uid);
      ref.current.start();
      setId(uid);
    } else {
      setLoading(true);
      setData(null);
    }
  };

  return (
    <Box sx={style.toolsCheckEvercookie} className="tools-check-evercookie">
      <ToolsHeader>
        <Heading tag="h1" variant="h2">
          {seo["h1"] ? seo["h1"] : t("pages.tools.checkEvercookie.heading")}
        </Heading>
      </ToolsHeader>

      <Box
        sx={style.description}
        className="tools-check-evercookie__description"
        dangerouslySetInnerHTML={{
          __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.checkEvercookie.description")
        }}
      />

      <Box sx={style.panel} className="tools-check-evercookie__panel">
        <Box
          sx={style.panel.text}
          className="tools-check-evercookie__panel-text"
          onClick={() => {
            ref.current.set(getRandomNumberInRange(1, 1000));
          }}
        >
          UID ={" "}
          {loadingUid ? (
            <CircularProgress color="inherit" size={20} sx={{ display: "inline-flex" }} />
          ) : (
            <span>{id ?? t("common.noData")}</span>
          )}
        </Box>

        <Button
          disabled={loadingUid}
          variant="primary"
          fullwidth
          onClick={handlerClick}
          loading={loading}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading
            ? t("common.cancel")
            : id
            ? t("pages.tools.checkEvercookie.searchEvercookie")
            : t("pages.tools.checkEvercookie.createEvercookie")}
        </Button>
      </Box>

      <Box sx={style.widgets} className="tools-check-evercookie__widgets">
        <Widget.Table
          rows={[
            {
              key: "userData:",
              value: data?.userData ?? t("common.noData")
            },
            {
              key: "cookieData:",
              value: data?.cookieData ?? t("common.noData")
            },
            {
              key: "localData:",
              value: data?.localData ?? t("common.noData")
            },
            {
              key: "globalData:",
              value: data?.globalData ?? t("common.noData")
            },
            {
              key: "sessionData:",
              value: data?.sessionData ?? t("common.noData")
            },
            {
              key: "windowData:",
              value: data?.windowData ?? t("common.noData")
            }
          ]}
          patch="right"
          size="small"
        />
        <Widget.Table
          rows={[
            {
              key: "pngData:",
              value: data?.pngData ?? t("common.noData")
            },
            {
              key: "etagData:",
              value: data?.etagData ?? t("common.noData")
            },
            {
              key: "cacheData:",
              value: data?.cacheData ?? t("common.noData")
            },
            {
              key: "lsoData:",
              value: data?.lsoData ?? t("common.noData")
            },
            {
              key: "dbData:",
              value: data?.dbData ?? t("common.noData")
            },
            {
              key: "slData:",
              value: data?.slData ?? t("common.noData")
            }
          ]}
          patch="right"
          size="small"
        />
      </Box>

      <CheckEvercookieSeo />
    </Box>
  );
};

export default CheckEvercookie;
