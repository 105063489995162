/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-destructuring */

/* eslint-disable no-empty-function */
import { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { HeadphonesIcon } from "../../../../assets/images/icons/headphones-icon";
import { SeoBlockField } from "../../../../components/common";
import MediaQuery from "../../../../components/common/media-query/media-query";
import { OrderModal } from "../../../../components/common/order-modal/order-modal";
import { PromocodeSection } from "../../../../components/common/promocode-section/promocode-section";
import { RadioGroupCustom } from "../../../../components/common/radio-group/radio-group";
import { Range } from "../../../../components/common/range/range";
import { SelectList } from "../../../../components/common/select-list/select-list";
import { Form } from "../../../../components/form";
import { Button } from "../../../../components/ui/button/button";
import { MobileProxySkeleton } from "../../../../components/ui/skeletons/mobile-proxy-skeleton/mobile-proxy-skeleton";
import { useActions, useLang, useSeoBlock } from "../../../../hooks";
import useOrder from "../../../../hooks/use-order";
import { getMobileState, getProxyState } from "../../../../store/selectors";
import { CALC_TYPES } from "../../../../utils/constants";
import { defineErrorText } from "../../../../utils/helpers";
import { getCountryFlagIso2 } from "../../../../utils/helpers/get-country-flag";
import {
  getMobileCountriesOptions,
  getOperatorOptions,
  getPeriodsForMobile
} from "../../../../utils/helpers/order.helpers";

import { style } from "./hero.style";

const customStyles = {
  select: {
    marginBottom: "1.5rem"
  }
};

export const HeroOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const order = useOrder();
  const { t } = useTranslation();
  const lang = useLang();
  const { fetchMobile } = useActions();
  const { data } = useSeoBlock(["mobileProxyPageCharacteristicsNoteBlock"]);

  //*Redux
  const { countries } = useSelector(getMobileState);
  const { proxyTypeIds } = useSelector(getProxyState);

  const countriesOptions = countries.length ? getMobileCountriesOptions(countries, lang) : [];
  const countryIndexFound = countriesOptions?.findIndex((e) => e.key === order.data.proxyCountry);
  const countryIndex = countryIndexFound !== -1 ? countryIndexFound : 0;

  const mobileProxyOptions = getOperatorOptions(
    countriesOptions[countryIndex]?.mobileOperators || []
  );
  const mobileProxyIndexFound = mobileProxyOptions?.findIndex(
    (e) => e.id === order.data.mobileOperatorId
  );
  const mobileProxyIndex = mobileProxyIndexFound !== -1 ? mobileProxyIndexFound : 0;

  const rentPeriodsOptions = mobileProxyOptions.length
    ? getPeriodsForMobile(mobileProxyOptions[mobileProxyIndex]?.pricesByRentPeriods, lang)
    : [];
  const rentPeriodsIndexFound = rentPeriodsOptions.findIndex(
    (e) => e.rentPeriodId === order.data.proxyRentalPeriod
  );
  const rentPeriodsIndex = rentPeriodsIndexFound !== -1 ? rentPeriodsIndexFound : 0;

  // country search param
  const [searchParams, setSearchParams] = useSearchParams();

  const searchCountry = searchParams.get("country");

  const [hasPromo, setHasPromo] = useState(false);
  const [country, setCountry] = useState(null);
  const [open, setOpen] = useState(false);

  // const [mobileOperatorCharacteristics, setMobileOperatorCharacteristics] = useState([]);
  const setOrder = (payload) => {
    order.setData((prev) => ({ ...prev, ...payload }));
  };
  const handleSelectCounty = (option) => {
    const currentCountry = option;
    setSearchParams({ country: option.urlParam });
    const mobileOperator = getOperatorOptions(currentCountry.mobileOperators)[0];
    const rentalPeriod = getPeriodsForMobile(
      mobileProxyOptions[mobileProxyIndex]?.pricesByRentPeriods,
      lang
    )[0];

    setOrder({
      proxyCountry: currentCountry.key,
      mobileOperatorId: mobileOperator?.id,
      proxyRentalPeriod: rentalPeriod.rentPeriodId,
      proxyCount: order.minCount
    });
    setCountry(currentCountry);
  };
  const handleSelectMobile = (option) => {
    setOrder({
      mobileOperatorId: option.id,
      proxyRentalPeriod: getPeriodsForMobile(
        mobileProxyOptions[mobileProxyIndex]?.pricesByRentPeriods,
        lang
      )[0].rentPeriodId
    });
  };
  const handlePeriodsChange = (e) => {
    setOrder({ proxyRentalPeriod: rentPeriodsOptions[e.target.value].rentPeriodId });
  };
  const handlePromoClose = () => {
    if (order.data.promoCode) {
      setOrder({ promoCode: "" });
    }
    setHasPromo(false);
  };
  const cancelHandler = () => setOpen(false);
  const submitHandler = () => {
    if (order.data.proxyCount) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!order.data.proxyCount) {
      const timer = setTimeout(() => {
        order.clearPrice();
      }, 1500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [order.data.proxyCount]);

  const mobileOperatorCharacteristics = useMemo(
    () => mobileProxyOptions[mobileProxyIndex]?.characteristics ?? [],
    [mobileProxyOptions, mobileProxyIndex]
  );

  // set initial values
  useEffect(() => {
    if (countries.length && proxyTypeIds.length) {
      let currentCountry = searchCountry
        ? countriesOptions.find((el) => el.urlParam === searchCountry)
        : countriesOptions[0];

      if (!currentCountry) {
        searchParams.delete("country");
        setSearchParams(searchParams);
        currentCountry = countriesOptions[0];
      }

      const mobileOperator = getOperatorOptions(currentCountry.mobileOperators)[0];
      const rentalPeriod = getPeriodsForMobile(
        mobileProxyOptions[mobileProxyIndex]?.pricesByRentPeriods,
        lang
      )[0];

      setOrder({
        proxyType: "MOBILE",
        proxyTypeId: proxyTypeIds.find((el) => el.name === "MOBILE").id,
        proxyCountry: currentCountry.key,
        mobileOperatorId: mobileOperator?.id,
        proxyRentalPeriod: rentalPeriod.rentPeriodId,
        currencyId: mobileOperator?.currencyId
      });
      setCountry(currentCountry);
    }
  }, [countries, proxyTypeIds, searchCountry]);
  // Calculate price
  useEffect(() => {
    order.calculatePrice(CALC_TYPES.MOBILE_BASIC);
  }, [order.data]);
  // Fetch mobile content
  useEffect(() => {
    if (!countries.length) {
      fetchMobile();
    }
  }, [order.data.proxyType]);

  // pop-up notification if we get an error
  useEffect(() => {
    if (!order.price.success && order.price.error) {
      enqueueSnackbar(defineErrorText(order.price.error), { variant: "error" });
    }
  }, [order.price.success]);

  const modalInitialData = {
    proxyType: order.data.proxyType,
    proxyTypeId: order.data.proxyTypeId,
    proxyCountry: order.data.proxyCountry,
    proxyRentalPeriod: order.data.proxyRentalPeriod,
    proxyCount: order.data.proxyCount,
    mobileOperatorId: order.data.mobileOperatorId
  };

  if (order.data.promoCode) {
    modalInitialData.promoCode = order.data.promoCode;
  }

  const getRotationCharacteristics = (rotationMinutes = []) => {
    const options = [];
    const byLink = rotationMinutes?.includes(0);
    const byTime = rotationMinutes?.some(Boolean);

    if (byLink) {
      options.push(t("common.byLink").toLowerCase());
    }
    if (byTime) {
      options.push(t("common.byTime").toLowerCase());
    }

    return options.join(", ");
  };

  return countries.length ? (
    <Box className="mobile-proxy-order">
      <Box sx={style.mobileProxyOrder.header} className="mobile-proxy-order__header">
        <Box sx={style.mobileProxyOrder.title} className="mobile-proxy-order__title">
          {t("pages.app.mobileProxy.hero.caption")}
        </Box>
        <Box
          sx={style.mobileProxyOrder.support}
          className="mobile-proxy-order__support"
          onClick={() => {
            // eslint-disable-next-line no-undef
            document.dispatchEvent(new Event("triggerOpenOnlineChat"));
          }}
        >
          <HeadphonesIcon /> <span>{t("pages.app.mobileProxy.hero.support")}</span>
        </Box>
      </Box>
      <form>
        <Box sx={style.mobileProxyOrder.body} className="mobile-proxy-order__body">
          <Box
            sx={[style.mobileProxyOrder.card, style.mobileProxyOrder.card.leftMod]}
            className="mobile-proxy-order__card mobile-proxy-order__card_left"
          >
            <SelectList
              name="country"
              onChange={handleSelectCounty}
              options={countriesOptions}
              selectedIndex={countryIndex}
              customStyles={customStyles.select}
              withIcon
              showIconInContainer
            />
            <SelectList
              name="proxy"
              options={mobileProxyOptions}
              selectedIndex={mobileProxyIndex}
              onChange={handleSelectMobile}
              customStyles={customStyles.select}
            />
            {/* <Tabs tabs={paymentsOptions} onChange={handleTabChange} size="small" fullwidth justify /> */}
            <Range
              name="count"
              value={order.data.proxyCount}
              label={t("other.countPiece")}
              onChange={(proxyCount) => {
                setOrder({ proxyCount });
              }}
            />

            <MediaQuery minWidth={"bp576"}>
              <>
                <Box
                  sx={style.mobileProxyOrder.price.cost}
                  className="mobile-proxy-order__price-cost"
                >
                  <span>${order.price.totalPrice ? order.price.totalPrice : 0}</span>/
                  {order.data.proxyCount || 0}{" "}
                  {order.data.proxyCount > 1 ? t("other.ips") : t("other.piece")}
                </Box>

                <Box
                  sx={style.mobileProxyOrder.price.description}
                  className="mobile-proxy-order__price-description"
                >
                  <Box className="mobile-proxy-order__price-unit">
                    {t("other.price")} 1 IP - ${order.price.priceForOne}
                  </Box>
                  <Box
                    sx={style.mobileProxyOrder.price.discount}
                    className="mobile-proxy-order__price-discount"
                  >
                    {t("other.discount")} <span>-{order.price.totalDiscountInPercent}%</span>
                  </Box>
                </Box>
              </>
            </MediaQuery>

            <Box sx={style.mobileProxyOrder.rate} className="mobile-proxy-order__rate">
              <RadioGroupCustom
                options={rentPeriodsOptions}
                onChange={handlePeriodsChange}
                selectedIndex={rentPeriodsIndex}
                currencySign={"$"}
              />
            </Box>
          </Box>
          <Box
            sx={[style.mobileProxyOrder.card, style.mobileProxyOrder.cardWithNote]}
            className="mobile-proxy-order__card"
          >
            <MediaQuery minWidth={"bp576"}>
              <Box
                component="ul"
                sx={style.mobileProxyOrder.info}
                className="mobile-proxy-order__info"
              >
                <Box
                  component="li"
                  sx={style.mobileProxyOrder.card.title}
                  className="mobile-proxy-order__card-title"
                >
                  {t("pages.app.mobileProxy.hero.order.info.characteristics")}
                </Box>
                <Box
                  component="li"
                  sx={style.mobileProxyOrder.info.row}
                  className="mobile-proxy-order__info-row"
                >
                  <Box
                    sx={style.mobileProxyOrder.info.label}
                    className="mobile-proxy-order__info-label"
                  >
                    {t("pages.app.mobileProxy.hero.order.info.country")}
                  </Box>
                  <Box
                    sx={style.mobileProxyOrder.info.value}
                    className="mobile-proxy-order__info-value"
                  >
                    <span>{country?.namesDependingOnLocale[lang]}</span>
                    <img
                      src={getCountryFlagIso2(country?.code)}
                      width={20}
                      height={20}
                      alt=""
                      aria-hidden={true}
                      loading="lazy"
                    />
                  </Box>
                </Box>
                <Box
                  component="li"
                  sx={style.mobileProxyOrder.info.row}
                  className="mobile-proxy-order__info-row"
                >
                  <Box
                    sx={style.mobileProxyOrder.info.label}
                    className="mobile-proxy-order__info-label"
                  >
                    {t("pages.app.mobileProxy.hero.order.info.proxyCount")}
                  </Box>
                  <Box
                    sx={style.mobileProxyOrder.info.value}
                    className="mobile-proxy-order__info-value"
                  >
                    {order.data.proxyCount || 0}
                  </Box>
                </Box>
                {mobileProxyOptions[mobileProxyIndex]?.rotationMinutes?.length ? (
                  <Box
                    component="li"
                    sx={style.mobileProxyOrder.info.row}
                    className="mobile-proxy-order__info-row"
                  >
                    <Box
                      sx={style.mobileProxyOrder.info.label}
                      className="mobile-proxy-order__info-label"
                    >
                      {t("common.rotation")}:
                    </Box>
                    <Box
                      sx={style.mobileProxyOrder.info.value}
                      className="mobile-proxy-order__info-value"
                    >
                      {getRotationCharacteristics(
                        mobileProxyOptions[mobileProxyIndex]?.rotationMinutes
                      )}
                    </Box>
                  </Box>
                ) : null}
                {mobileOperatorCharacteristics.map((characteristic) => {
                  if (!characteristic.namesByLocale[lang]) {
                    return null;
                  }

                  return (
                    <Box
                      key={characteristic.id}
                      component="li"
                      sx={style.mobileProxyOrder.info.row}
                      className="mobile-proxy-order__info-row"
                    >
                      <Box
                        sx={style.mobileProxyOrder.info.label}
                        className="mobile-proxy-order__info-label"
                      >
                        {characteristic.namesByLocale[lang]}:
                      </Box>
                      <Box
                        sx={style.mobileProxyOrder.info.value}
                        className="mobile-proxy-order__info-value"
                      >
                        {characteristic.type === "BOOLEAN" ? (
                          <Form.Checkbox
                            // name={`mobile-characteristic-${characteristic.id}`}
                            checked={characteristic.value}
                            disabled
                          />
                        ) : (
                          characteristic.value[lang]
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </MediaQuery>
            {data?.mobileProxyPageCharacteristicsNoteBlock?.content ? (
              <Box
                id="mobileProxyPageCharacteristicsNoteBlock"
                sx={style.mobileProxyOrder.note}
                className="mobile-proxy-order__note"
              >
                <Box
                  sx={style.mobileProxyOrder.note.title}
                  className="mobile-proxy-order__note-title"
                >
                  <SeoBlockField
                    defaultValue={t("pages.app.mobileProxy.hero.order.info.note.label")}
                    field={data?.mobileProxyPageCharacteristicsNoteBlock?.title}
                  />
                </Box>
                <Box
                  sx={style.mobileProxyOrder.note.content}
                  className="mobile-proxy-order__note-content"
                >
                  <SeoBlockField
                    defaultValue={t("pages.app.mobileProxy.hero.order.info.note.content")}
                    field={data?.mobileProxyPageCharacteristicsNoteBlock?.content}
                  />
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={[style.mobileProxyOrder.card, style.mobileProxyOrder.card.rightMod]}
            className="mobile-proxy-order__card mobile-proxy-order__card_right"
          >
            <MediaQuery minWidth={"bp576"}>
              <Box
                sx={style.mobileProxyOrder.card.title}
                className="mobile-proxy-order__card-title"
              >
                {t("pages.app.mobileProxy.hero.order.result.title")}
              </Box>
              {/*Result options*/}
              <Box sx={style.mobileProxyOrder.resultOptions.container}>
                <Box sx={style.mobileProxyOrder.resultOptions.itemRow}>
                  <Box component="span" sx={style.mobileProxyOrder.resultOptions.itemLabel}>
                    {t("pages.app.mobileProxy.hero.order.result.proxy")}
                  </Box>
                  <Box component="span" sx={style.mobileProxyOrder.resultOptions.itemSubContent}>
                    ({country?.namesDependingOnLocale[lang]} |{" "}
                    {mobileProxyOptions[mobileProxyIndex].label})
                  </Box>
                </Box>
                <Box sx={style.mobileProxyOrder.resultOptions.item}>
                  <Box component="span" sx={style.mobileProxyOrder.resultOptions.itemLabel}>
                    {t("other.countPiece")}
                  </Box>
                  <Box component="span" sx={style.mobileProxyOrder.resultOptions.itemContent}>
                    {order.data.proxyCount || 0}
                  </Box>
                </Box>
                {rentPeriodsOptions[rentPeriodsIndex] ? (
                  <Box sx={style.mobileProxyOrder.resultOptions.item}>
                    <Box component="span" sx={style.mobileProxyOrder.resultOptions.itemLabel}>
                      {t("pages.app.mobileProxy.hero.order.result.rentalPeriod")}
                    </Box>
                    <Box component="span" sx={style.mobileProxyOrder.resultOptions.itemContent}>
                      {rentPeriodsOptions[rentPeriodsIndex]?.label}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </MediaQuery>
            <>
              <Box sx={style.mobileProxyOrder.price} className="mobile-proxy-order__price">
                <Box
                  sx={style.mobileProxyOrder.price.cost}
                  className="mobile-proxy-order__price-cost"
                >
                  <span>
                    $
                    {order.price.totalPriceInMainCurrency
                      ? order.price.totalPriceInMainCurrency
                      : 0}
                  </span>
                </Box>
                <Box
                  sx={style.mobileProxyOrder.price.discount}
                  className="mobile-proxy-order__price-discount"
                >
                  {t("other.discount")} <span>-{order.price.totalDiscountInPercent || 0}%</span>
                </Box>
              </Box>
              <Box className="mobile-proxy-order__price-unit">
                {t("other.price")} 1 IP -{" "}
                {order.price.priceForOne !== order.price.priceForOneWithDiscount ? (
                  <s>${order.price.priceForOne}</s>
                ) : null}
                <span> ${order.price.priceForOneWithDiscount}</span>
              </Box>
            </>
            <Box sx={style.mobileProxyOrder.promocode} className="mobile-proxy-order__promocode">
              <PromocodeSection
                onChange={(e) => setOrder({ promoCode: e.target.value })}
                promo={order.data.promoCode}
                hasPromo={hasPromo}
                onClick={() => setHasPromo(true)}
                onClose={handlePromoClose}
                promoLinkStyles={style.promoLink}
                inputProps={{
                  error: !order.price?.validPromoCode,
                  errorText: order.dataWarnings.promoCode
                }}
              />
            </Box>
            <Button
              disabled={!order.data.proxyCount}
              variant="primary"
              ariaLabel={t("form.actions.makeOrder")}
              onClick={submitHandler}
            >
              {t("form.actions.makeOrder")}
            </Button>
          </Box>
        </Box>
      </form>

      <OrderModal
        id="mobile-proxy"
        visible={open}
        initialData={modalInitialData}
        cancelHandler={cancelHandler}
      />
    </Box>
  ) : (
    <MobileProxySkeleton />
  );
};
