import { appTheme } from "../../../../theme"

export const style = {
  item: {
    height: "19rem",
    width: "100%",
    mask: `radial-gradient(var(--mask-width) at 0 100%, #0000 98%, #000) 0 0,
      radial-gradient(var(--mask-width) at 100% 100%, #0000 98%, #000) 100% 0%,
      radial-gradient(var(--mask-width) at 0 0, #0000 98%, #000) 0 100%,
      radial-gradient(var(--mask-width) at 100% 0%, #0000 98%, #000) 100% 100%`,
    maskRepeat: 'no-repeat',
    maskSize: '51% 51%',
    "--mask-width": "3rem",
    borderRadius: "1.5rem"
  },

  grid: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [appTheme.breakpoints.down("bp1200")]: { gridGap: "1.5rem" },
    [appTheme.breakpoints.down("bp850")]: { gridTemplateColumns: 'repeat(1, 1fr)' },
  },

  cabinetGrid: {
    maxWidth: "57rem",
    [appTheme.breakpoints.down("bp850")]: { maxWidth: "100%" },
    "& .grid-item:not(:last-child)": {
      marginBottom: "3rem"
    }
  },

  button: {
    width: "30.2rem",
    height: "6rem",
    borderRadius: "3rem",
    margin: "0 auto",
    marginTop: "6rem",
    [appTheme.breakpoints.down("bp576")]: { width: "100%" },

  }
}