import React, { useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
// import { useTranslation } from "../../../../../hooks";
import { Box } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { XMark } from "../../../../../assets/images/icons/XMark";
import { SeoBlockField } from "../../../../../components/common";
import { Form } from "../../../../../components/form";
import { Button, Heading, Modal } from "../../../../../components/ui";
import { FeedbackEnterpriceSchema } from "../../../../../utils/validation/feedback.validation";

import { style } from "./contact-us-modal.styles";

const EmailOrOther = ({ placeholder, selectOptions, loading, onCancel }) => {
  const { t } = useTranslation();

  const formContext = useFormContext();

  const emailOrOther = formContext.watch("emailOrOther");

  const Actions = ({ fieldName }) => (
    <Box
      sx={{
        cursor: "pointer",
        height: "2.4rem",
        width: "2.4rem",
        marginLeft: "3rem",
        display: "grid",
        placeItems: "center"
      }}
      onClick={() => onCancel(fieldName)}
    >
      <XMark />
    </Box>
  );

  if (!emailOrOther) {
    return <Form.Select name="emailOrOther" options={selectOptions} placeholder={placeholder} />;
  }

  if (emailOrOther === "email") {
    return (
      <Form.Input
        name="email"
        placeholder={`${t("common.email")}:`}
        readOnly={loading}
        actions={<Actions fieldName={"email"} />}
      />
    );
  }

  if (emailOrOther === "other") {
    return (
      <Form.Input
        name="other"
        placeholder={`${t("common.other")}:`}
        readOnly={loading}
        actions={<Actions fieldName={"other"} />}
      />
    );
  }

  return null;
};
const ContactUsModal = (props) => {
  const { open, onClose, onSubmit, loading, seoData } = props;

  const captchaRef = useRef(null);

  const { t } = useTranslation();

  const formContext = useForm({
    mode: "onChange",
    resolver: yupResolver(FeedbackEnterpriceSchema(t("form"))),
    defaultValues: {
      name: "",

      emailOrOther: null,
      email: "",
      other: "",

      companyName: "",
      message: ""
    }
  });

  const selectOptions = [
    // { key: "emailOrOther", label: t.form.placeholders.emailOrOther },
    { key: "email", label: t("common.email") },
    { key: "other", label: t("common.other") }
  ];

  const handlerCancel = () => {
    formContext.reset();
    onClose();
  };

  const handleSubmit = (values) => {
    onSubmit(values, formContext.reset, captchaRef?.current?.reset ?? (() => {}));
  };

  const handlerEmailOrOtherCancel = (field) => {
    formContext.resetField(field, "");
    formContext.setValue("emailOrOther", null);
  };

  return (
    <>
      <SeoBlockField
        field={seoData?.modal}
        defaultValue={{
          title: t("common.contactUs"),
          description: "",
          messagePlaceholder: ""
        }}
      >
        {(data) => {
          return (
            <Modal visible={open} cancelHandler={handlerCancel} customStyles={style.modal}>
              <Heading tag="div" variant="h4">
                {data.title}
              </Heading>
              {data.description !== "" ? (
                <Box sx={style.description}>{data.description}</Box>
              ) : null}

              <Form onSubmit={handleSubmit} context={formContext}>
                <Form.Input
                  name="name"
                  placeholder={`${t("form.placeholders.yourName")}:`}
                  readOnly={loading}
                />
                <EmailOrOther
                  placeholder={`${t("form.placeholders.emailOrOther")}:`}
                  loading={loading}
                  selectOptions={selectOptions}
                  onCancel={handlerEmailOrOtherCancel}
                />
                <Form.Input
                  name="companyName"
                  placeholder={`${t("form.placeholders.companyName")}:`}
                  readOnly={loading}
                />
                <Box sx={style.textAreaContainer}>
                  <Form.Input
                    name="message"
                    placeholder={data.messagePlaceholder}
                    readOnly={loading}
                    type="textarea"
                    direction="horizontal"
                    rows={4}
                  />
                  <Box sx={style.textAreaExtra}>
                    {t("common.maxCharacters").replace("{{max}}", 500)}
                  </Box>
                </Box>
                <Form.Captcha ref={captchaRef} />
                <Button variant="primary" fullwidth type="submit" loading={loading}>
                  {t("form.actions.submit")}
                </Button>
              </Form>
            </Modal>
          );
        }}
      </SeoBlockField>
    </>
  );
};

export default ContactUsModal;
