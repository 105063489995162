import { appTheme } from "../../../../theme";

export const style = {
  topRow: {
    marginBottom: "3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    heading: {
      height: "2.3rem",
      width: "19rem",
      [appTheme.breakpoints.down("bp576")]: {
        height: "1.9rem",
        marginBottom: "2rem"
      }
    },

    button: {
      height: "4rem",
      width: "21rem",
      borderRadius: "1rem",
      [appTheme.breakpoints.down("bp576")]: {
        height: "4.4rem",
        width: "4.4rem"
      }
    }
  },

  content: {
    display: "grid",
    gridGap: "3rem",
    gridTemplateColumns: "27rem 2fr 27rem",
    [appTheme.breakpoints.down("bp992")]: { gridTemplateColumns: "repeat(2, 1fr)" },
    [appTheme.breakpoints.down("bp767")]: { gridGap: "2rem" },
    [appTheme.breakpoints.down("bp576")]: {
      gridGap: "3rem",
      gridTemplateColumns: "1fr",
      background: "rgba(0,0,0,0.03)",
      borderRadius: "3.5rem",
      padding: "4rem 2rem"
    },
    
  },

  form: {
    padding: "4rem 2rem",
    background: "rgba(0,0,0,0.03)",
    borderRadius: "3rem",
    [appTheme.breakpoints.down("bp576")]: {
      gridColumn: "span 2",
      padding: "0",
      backgroundColor: "transparent"
    }
  },

  select: {
    height: "4.4rem",
    width: "100%",
    marginBottom: "1.5rem",
    borderRadius: "3rem"
  },

  slider: {
    paddingBottom: "2rem",
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0.9rem"
    },

    label: {
      height: "1.6rem",
      width: "12rem"
    },

    field: {
      height: "3.9rem",
      width: "8.4rem",
      borderRadius: "3rem"
    },

    line: {
      margin: "1.3rem 0",
      height: "0.6rem",
      borderRadius: "0.3rem",
    }
  },

  formPrice: {
    display: "flex",
    flexDirection: "column",
    [appTheme.breakpoints.down("bp576")]: { display: "none" }
  },

  formPriceTop: {
    height: "3.5rem",
    width: "10rem",
    marginBottom: "0.6rem"
  },

  formPriceBottom: {
    height: "1.5rem",
    width: "100%"
  },

  radioGroup: {
    marginTop: "4rem",
    [appTheme.breakpoints.down("bp576")]: { marginTop: "2rem" },

    item: {
      height: "1.6rem",
      marginBottom: "1rem",
      "&:not(:last-child)": {
        marginBottom: "2rem"
      }
    }
  },

  specs: {
    padding: "4rem 2rem",
    background: "rgba(0,0,0,0.03)",
    borderRadius: "3rem",
    display: "flex",
    flexDirection: "column",
    [appTheme.breakpoints.down("bp576")]: {
      padding: "0",
      background: "transparent"
    },

    row: {
      height: "1.8rem",
      marginBottom: "1.6rem",
      [appTheme.breakpoints.down("bp576")]: { display: "none" },
    }
  },

  countryRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "1.6rem",
    [appTheme.breakpoints.down("bp576")]: { display: "none" },

    leftItem: {
      height: "1.8rem",
      width: "7rem",
    },

    rightItem: {
      height: "2rem",
      width: "11rem"
    }
  },

  note: {
    marginBottom: "-1rem",
    marginTop: "auto",
    [appTheme.breakpoints.down("bp576")]: { margin: "2.5rem 0" },
    heading: {
      height: "1.9rem",
      marginBottom: "0.8rem",
      width: "20rem"
    },
    paragraph: {
      "& .string": {
        height: "1.1rem",
        marginBottom: "1rem",
        width: "100%",
        "&:last-child": {
          width: "70%"
        }
      }
    }
  },

  title: {
    maxWidth: "20rem",
    width: "100%",
    height: "2.3rem",
    marginBottom: "2rem",
    [appTheme.breakpoints.down("bp576")]: { display: "none" },
  },

  summary: {
    padding: "4rem 2rem",
    background: "rgba(0,0,0,0.03)",
    borderRadius: "3rem",
    [appTheme.breakpoints.down("bp992")]: { gridColumn: "span 2" },
    [appTheme.breakpoints.down("bp576")]: {
      padding: "0",
      background: "transparent"
    },
    headLabel: {
      height: "1.8rem",
      width: "10rem"
    },

    mainLabel: {
      height: "3.2rem",
      width: "10rem"
    },

    value: {
      width: "5rem",
      height: "1.8rem"
    },

    row: {
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [appTheme.breakpoints.down("bp576")]: { display: "none" },

    }
  },

  summaryPrice: {
    paddingTop: "3.2rem",
    height: "auto !important",
    row: {
      marginBottom: "1.5rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },

    price: {
      height: "4.1rem",
      width: "6rem",
      marginRight: "0.8rem",

    },

    discount: {
      height: "1.6rem",
      width: "8rem"
    },

    bottomRow: {
      height: "1.8rem",
      marginBottom: "2rem"
    }
  },

  promoField: {
    height: "1.6rem",
    width: "11rem",
    marginBottom: "4.4rem"
  },

  button: {
    height: "5rem",
    width: "100%",
    borderRadius: "3rem"
  }
}