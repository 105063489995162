import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { style } from "./textInput.style";

// const Controller = ({ control, register, name, rules, render }) => {
//   const props = register(name);
//   return render();
// };

export const TextInput = ({
  name,
  label,
  action,
  placeholder,
  customStyles,
  inputStyles,
  value,
  onChange,
  isOuterLabel,
  startAdornment,
  isPassword,
  error,
  errorText,
  disabled = false,
  readOnly = false,
  onlyNumber = false,
  onKeyUp,
  isSearchInput = false,
  searchOptions = [],
  errorFixed = false,
  ...other
}) => {

  const inputRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [focus, setFocus] = useState();

  const styles = [];
  const controlStyles = [];

  if (!isOuterLabel) {
    styles.push(style.textInput, inputStyles);
    controlStyles.push(style.formControl);
    if (focus) controlStyles.push(style.formControl.focus);
    if (disabled) controlStyles.push({ pointerEvents: "none" });
  } else {
    styles.push(style.textInputOuter, inputStyles);
    controlStyles.push(style.formControlOuter);
  }

  if (error) styles.push(style.error);
  const changeHandler = (e) => {
    let string = e.target.value;
    if (isSearchInput && !e.target?.isSelected) {
      if (!dropdownOpen) setDropdownOpen(true);
      string = e.target.value.replace(/[^\w\sа-яіїєґ.]/gi, "");
    }
    if (onlyNumber) string = e.target.value.replace(/[^0-9]/g, "");
    const eventObj = { target: { value: string, toRequest: !e.target.isSelected } };
    onChange(eventObj);
  };

  const blurHandler = () => {
    if (isSearchInput) setDropdownOpen(false);
  };

  const searchSelect = (value) => {
    changeHandler({ target: { value, isSelected: true } });
  };

  return (
    <FormControl
      sx={{
        width: "100%"
      }}
      onClick={() => !isOuterLabel && inputRef.current.focus()} {...other}>
      <Box
        sx={[...controlStyles, customStyles]}
      >
      {!!label && <Box sx={!isOuterLabel ? style.label : style.labelOuter} component="span">{label}</Box>}
      <OutlinedInput
        // inputRef={inputRef}
        type={isPassword ? "password" : "text"}
        disabled={disabled}
        readOnly={readOnly}
        name={name}
        placeholder={placeholder}
        endAdornment={!!action && <InputAdornment sx={style.endAdornment} position="end">{action}</InputAdornment>}
        startAdornment={!!startAdornment && <InputAdornment sx={style.startAdornment} position="start">{startAdornment}</InputAdornment>}
        sx={styles}
        value={value ?? ""}
        onChange={changeHandler}
        onKeyUp={onKeyUp}
        inputRef={inputRef}
        // autoComplete={autocomplete}
        inputProps={{
          autoComplete: "new-password",
          onBlur: blurHandler
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {/* {!!searchOptions.length && dropdownOpen && ( */}
      {isSearchInput && (
        <Box sx={(!!searchOptions.length && dropdownOpen) ? [style.searchDropdown, style.searchDropdownOpen] : style.searchDropdown}>
          {searchOptions.map((el, i) => <Box onClick={() => searchSelect(el.displayName)} key={i} sx={style.searchItem}>{el.displayName}</Box>)}
        </Box>
      )}
      </Box>
      {error && !!errorText && errorText !== "" && <FormHelperText sx={!errorFixed ? style.errorText : style.errorTextAbsolute}>{errorText}</FormHelperText>}
    </FormControl>
  );
};
