import { fluid } from "../../../assets/styles/functions/fluid"

export const style = {
  sidebar: {
    '--transition': '0.15s',
    flexShrink: '0',
    overflow: 'hidden',
    borderRadius: '3rem',
    padding: '3.4rem 1.2rem 3.7rem 2.2rem',
    width: 'min(100%, 28rem)',
    // height: 52rem,
    boxShadow: 'inset 0px 0px 20px rgba(197, 202, 213, 0.2)',
    backgroundColor: 'var(--clr-default-200)',
    transition: 'height var(--transition)',
  },

  sidebarList: {
    paddingRight: '1rem',
    height: '100%',
  },

  sidebarItem: {
    borderRadius: '2.4rem',
    fontWeight: '400',
    fontSize: fluid(1.6, 1.4),
    color: 'var(--clr-text-900)',
    transition: 'color var(--transition), background-color var(--transition)',
    cursor: 'pointer',
    overflow: 'hidden',

    '& > a, & > span': {
      display: 'flex',
      alignItems: 'center',
      padding: '1.5rem 2.3rem',
      cursor: 'pointer',
    },

    '& > span': {
      transition: 'padding-bottom var(--transition)',
    },

    // '&-icon, &-arrow': {
    //   svg: {
    //     transition: 'stroke var(--transition)'
    //   }
    // }

    icon: {
      display: 'inline-block',
      alignSelf: 'flex-start',
      marginRight: '1rem',
      width: '1.8rem',
      height: '1.8rem',

      svg: {
        width: '100%',
        height: '100%',
        fill: 'var(--clr-text-900)',
        transition: 'fill var(--transition)',
      }
    },

    '.sidebar__item-arrow': {
      marginLeft: 'auto',
      width: '1.6rem',
      height: '1.6rem',
      stroke: 'var(--clr-text-900)',
      transition: 'transform var(--transition)',
    },

    sub: {
      paddingLeft: '5.1rem',
      transition: 'height var(--transition), transform var(--transition)',

      item: {
        display: 'block',
        fontWeight: '400',
        fontSize: '1.4rem',
        color: 'var(--clr-text-900)',
        transition: 'color var(--transition)',

        '&:not(:last-child)': {
          marginBottom: '0.6rem',
        }
      }
    },

    active: {
      color: 'var(--clr-text-100)',
      backgroundColor: 'var(--clr-primary-400)',
      transition: 'color var(--transition), background-color var(--transition)',

      '.sidebar__item-icon': {
        svg: {
          fill: 'var(--clr-text-100)',
          transition: 'fill var(--transition)',
        }
      },

      '.sidebar__item-arrow': {
        stroke: 'var(--clr-text-100)',
        transition: 'fill var(--transition)',
      },

      '.sidebar__item-sub': {
        transition: 'height var(--transition), transform var(--transition)',

        '&-item': {
          color: 'rgba(var(--clr-text-100-rgb), 0.8)',
          transition: 'color var(--transition)',

          '&_active': {
            color: 'var(--clr-text-100)',
            background: 'transparent'
          }
        }
      }
    },

    open: {
      '.sidebar__item-sub': {
        paddingBottom: '1rem',
      },

      '.sidebar__item-arrow': {
        transform: 'rotate(180deg)',
      }
    }
  }
}