import { appTheme } from "../../../theme";

export const style = {
  cabinetOrders: {
    "& > .heading": {
      marginBottom: "4rem",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "3rem"
      }
    }
  },

  widgets: {
    display: "grid",
    gridGap: "3rem",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    marginBottom: "4.5rem",
    [appTheme.breakpoints.down("bp767")]: {
      gridTemplateColumns: "1fr",
      "& > *": {
        width: "100%"
      }
    }
  },

  widget: {
    borderRadius: "3rem",
    padding: "2.2rem",
    boxShadow: "8px 12px 50px rgba(185, 197, 222, 0.2)",
    backgroundColor: "var(--clr-default-100)",

    "& > .heading": {
      marginBottom: "1.5rem",

      [appTheme.breakpoints.down("bp767")]: {
        marginBottom: "1rem"
      }
    },

    row: {
      fontWeight: "500",
      fontSize: "1.4rem",
      color: "var(--clr-text-400)",

      "& > span": {
        color: "var(--clr-primary-400)"
      },

      "&:not(:last-child)": {
        marginBottom: "1.2rem"
      }
    },

    ".form-select": {
      marginBottom: "1.8rem",
      maxWidth: "22rem"
    },

    [appTheme.breakpoints.down("bp767")]: {
      padding: "2rem 3rem 3.5rem"
    },

    [appTheme.breakpoints.down("bp576")]: {
      gridColumn: "span 2"
    }
  },

  inner: {
    ".cabinet-orders__content:not(:last-child)": {
      marginBottom: "10rem",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "4rem"
      }
    }
  },

  table: {
    marginBottom: "5.5rem",

    ".table": {
      boxShadow: "8px 12px 50px rgba(185, 197, 222, 0.2)"
    }
  },

  panel: {
    borderRadius: "3.5rem",

    boxShadow: "0px 14px 30px rgba(165, 178, 208, 0.22)",
    backgroundColor: "var(--clr-default-100)",

    addListBtnBlock: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.6rem 4.5rem",
      [appTheme.breakpoints.down("bp768")]: {
        padding: "1.6rem 1.8rem"
      },
      link: {
        color: "var(--clr-primary-400)",
        fontWeight: 500,
        fontSize: "1.4rem",
        display: "flex",
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
        "&:hover": {
          textDecorationLine: "underline"
        }
      }
    },

    options: {
      display: "grid",
      alignItems: "start",
      gridGap: "2rem",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      padding: "1.6rem 4.5rem",

      [appTheme.breakpoints.down("bp1200")]: {
        "& > *": {
          gridColumn: "span 2"
        },

        ".form-checkbox": {
          order: "2"
        },

        ".button": {
          order: "3"
        }
      },

      [appTheme.breakpoints.down("bp992")]: {
        "& > *": {
          gridColumn: "span 1"
        },

        ".form-checkbox": {
          order: "0"
        },

        ".button": {
          order: "4"
        }
      },

      [appTheme.breakpoints.down("bp767")]: {
        padding: "3rem 2rem",

        "& > *": {
          gridColumn: "span 2"
        },

        ".form-checkbox": {
          order: "2"
        },

        ".button": {
          order: "3"
        }
      },

      [appTheme.breakpoints.down("bp576")]: {
        gridTemplateColumns: "1fr"
      }
    },

    info: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.7rem 4.5rem",
      background: "#F9F9FB",
      borderRadius: "0 0 35px 35px",
      [appTheme.breakpoints.down("bp768")]: {
        padding: "0.7rem 1.8rem"
      },

      item: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "1.9rem",
        fontWeight: "500",
        fontSize: "1.4rem",
        textAlign: "center",
        color: "var(--clr-text-900)",

        span: {
          color: "var(--clr-primary-400)"
        }
      },

      promocode: {
        position: "relative",
        gridColumn: "5 / 7",
        minHeight: "1.9rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& > span": {
          display: "flex",
          alignItems: "center",
          minHeight: "1.9rem",
          fontWeight: "500",
          fontSize: "1.4rem",
          textDecoration: "underline",
          color: "var(--clr-text-900)",
          cursor: "pointer",

          "&:hover": {
            textDecoration: "none",
            cursor: "pointer"
          }
        },

        ".form-item": {
          position: "absolute",
          right: "0",
          top: "50%",
          overflow: "hidden",
          borderRadius: "1.5rem",
          width: "23.9rem",
          boxShadow: "0px 2px 22px rgba(95, 103, 128, 0.14)",
          transform: "translateY(-50%)",

          "&__field": {
            borderRadius: "1.5rem",

            "@media (hover)": {
              "&:hover": {
                backgroundColor: "var(--clr-default-200)"
              }
            }
          },

          [appTheme.breakpoints.down("bp576")]: {
            left: "50%",
            top: "0",
            transform: "translateX(-50%)"
          }
        },

        [appTheme.breakpoints.down("bp576")]: {
          gridColumn: "1 / 7"
        }
      }
    },
    modalBlock: { maxWidth: "48rem", overflow: "hidden" },
    modal: {
      upper: {
        padding: "3.2rem 3.6rem 2rem 3.6rem ",
        background: "#ffffff",
        "& > .heading": {
          marginBottom: "0.7rem",
          fontSize: "2.4rem",
          color: "var(--clr-text-900)"
        },

        [appTheme.breakpoints.down("bp460")]: {
          paddingRight: "2rem",
          paddingLeft: "2rem",
          paddingBottom: "2rem"
        }
      },
      textArea: {
        minHeight: "16rem",
        maxHeight: "16rem",
        fontSize: "1.6rem"
      },
      bottom: {
        padding: "2.4rem 3.6rem",
        backgroundColor: "var(--clr-default-200)",
        display: "flex",
        gap: "2.4rem",
        [appTheme.breakpoints.down("bp460")]: {
          gap: "0.4rem"
        },
        "& > .button": { flex: "1 1 calc(50% - 1.2rem)" },
        [appTheme.breakpoints.down("bp460")]: {
          padding: "2rem",
          gap: "1.5rem"
        }
      },

      subTitle: {
        marginBottom: "2.4rem",
        fontWeight: "400",
        fontSize: "1.4rem",
        color: "var(--clr-text-400)"
      }
    }
  },

  panelError: {
    paddingRight: "13px",
    paddingLeft: "13px",
    minHeight: "50px",
    height: "auto"
  },

  noData: {
    div: {
      padding: "20px"
    }
  },

  promocodeLink: {
    display: "flex",
    alignItems: "center",
    minHeight: "1.9rem",
    fontWeight: "500",
    fontSize: "1.4rem",
    textDecoration: "underline",
    color: "var(--clr-text-900)",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "none",
      cursor: "pointer"
    }
  },
  flexCenter: { display: "flex", alignItems: "center" },
  blockWSwitch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    title: {
      fontWeight: "500",
      fontSize: "1.4rem",
      color: "var(--clr-text-600)"
    },
    bold: {
      fontSize: "1.4rem",
      fontWeight: "500",
      color: "var(--clr-text-900)"
    },
    switch: {
      margin: "0 0rem 0.4rem 1rem",
      "& .MuiSwitch-root": {
        padding: "10px 9px"
      },

      "& .MuiSwitch-track": {
        borderRadius: 12,
        border: "1px solid #2055dd",
        backgroundColor: "transparent",
        height: 20,
        width: 40
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 12,
        height: 12,
        color: "#2055dd",
        marginLeft: "0.5rem",
        marginTop: "0.5rem"
      },
      "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
          "& + .MuiSwitch-track": {
            backgroundColor: "var(--clr-primary-400)"
          }
        }
      }
    }
  }
};
