import { createAsyncThunk } from "@reduxjs/toolkit";
import { CabinetService } from "../../services";

export const fetchUserActiveOrdersIpAddressCount = createAsyncThunk(
  "user/fetchUserActiveOrdersIpAddressCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CabinetService.getUserActiveOrdersIpAddressCount();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
