
export const VkIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_249_10719)">
      <path d="M14.9362 9.771C14.6452 9.4035 14.7285 9.24 14.9362 8.9115C14.94 8.90775 17.3422 5.58825 17.5897 4.4625L17.5912 4.46175C17.7142 4.0515 17.5912 3.75 16.9965 3.75H15.0285C14.5275 3.75 14.2965 4.00875 14.1727 4.29825C14.1727 4.29825 13.1707 6.69675 11.7532 8.2515C11.2957 8.70075 11.0842 8.84475 10.8345 8.84475C10.7115 8.84475 10.5202 8.70075 10.5202 8.2905V4.46175C10.5202 3.96975 10.38 3.75 9.96525 3.75H6.87075C6.5565 3.75 6.36975 3.9795 6.36975 4.19325C6.36975 4.65975 7.0785 4.767 7.152 6.0795V8.92725C7.152 9.55125 7.03875 9.666 6.7875 9.666C6.1185 9.666 4.49475 7.25775 3.5325 4.5015C3.33825 3.96675 3.1485 3.75075 2.64375 3.75075H0.675C0.11325 3.75075 0 4.0095 0 4.299C0 4.8105 0.669 7.35375 3.111 10.7138C4.7385 13.0073 7.0305 14.25 9.1155 14.25C10.3687 14.25 10.5217 13.974 10.5217 13.4993C10.5217 11.3078 10.4085 11.1008 11.0362 11.1008C11.3272 11.1008 11.8282 11.2448 12.9982 12.351C14.3355 13.6628 14.5552 14.25 15.3037 14.25H17.2717C17.8327 14.25 18.117 13.974 17.9535 13.4295C17.5792 12.2843 15.0502 9.9285 14.9362 9.771Z" />
    </g>
    <defs>
      <clipPath id="clip0_249_10719">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);
