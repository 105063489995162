import { appTheme } from "../../../theme";

export const style = {
  container: {
    width: "23.4rem",
    height: "6.7rem",

    [appTheme.breakpoints.down("bp992")]: {
      width: "18.4rem",
      height: "6.7rem"
    },
    [appTheme.breakpoints.down("bp576")]: {
      width: "14rem",
      height: "4rem"
    }
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
};
