import { Box } from "@mui/material";
// import { useTranslation } from "../../../hooks";
import { useTranslation } from "react-i18next";

import { MAIN_CONTACTS } from "../../../utils/constants";
import DynamicIcon from "../dynamic-icon/dynamic-icon";

import { style } from "./contacts-list.style";

export const ContactsList = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box sx={style.title}>{t("shared.contacts.title")}</Box>
      <Box component="ul">
        {MAIN_CONTACTS.map((contact, idx) => (
          <Box component="li" key={`${idx}:${contact.key}`}>
            <Box component="a" sx={style.link} href={contact.link}>
              <DynamicIcon iconName={contact.icon} />
              {t(`contacts.${contact.translation}`)}
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};
