import { appTheme } from "../../../../theme";

export const style = {

  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [appTheme.breakpoints.down("bp992")]: {
      alignItems: "center"
    }
  },

  block: {
    padding: "3rem",
    width: "min(43.5rem, 100%)",
    // maxWidth: "43.5rem",
    // width: "100%",
    background: "rgba(0,0,0,0.03)",
    borderRadius: "3rem",
    "&:not(:last-child)": {
      marginBottom: "3rem"
    }
  },

  heading: {
    margin: "0.7rem 0",
    marginBottom: "1.9rem",
    height: "1.8rem",
    maxWidth: "15rem"
  },

  list: {
    paddingLeft: "2.3rem"
  },
  item: {

    height: "1.8rem",
    "&:not(:first-of-type)": {
      marginTop: "2.4rem",
    },
    "&:last-child": {
      marginBottom: "1.2rem"
    }

  }
}
