import { fluid } from "../../../../assets/styles/functions/fluid"
import { appTheme } from "../../../../theme"

export const style = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: '0.1rem solid transparent',
    borderRadius: '3rem',
    padding: '3rem',
    height: '100%',
    minHeight: '26rem',
    backgroundColor: 'var(--clr-default-100)',
    transition: 'border-color var(--transition), box-shadow var(--transition)',
    boxShadow: '0 15px 25px rgba(179 184 202 / 22%)',
    [appTheme.breakpoints.down('bp576')]: {
      padding: '2.4rem 1.2rem',
    },

    [appTheme.breakpoints.down('bp576')]: {
      minHeight: '22rem',
    },
  },

  

  // [`@media (hover)`]: {
    
  // },

  

  text: {
    flexGrow: '1',
    marginBottom: '2.5rem',

    '& > p': {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3.2rem',
      "& > p": {
        fontSize: "1.4rem"
      }
    }
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [appTheme.breakpoints.down('bp576')]: {
      flexWrap: 'wrap',
    }
  },

  author: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: '1rem',
    wordBreak: 'break-all',

    avatar: {
      flexShrink: '0',
      overflow: 'hidden',
      borderRadius: '50%',
      width: '4.7rem',
      height: '4.7rem',

      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },

      [appTheme.breakpoints.down('bp576')]: {
        width: '3rem',
        height: '3rem',
      }
    },

    info: {
      paddingLeft: '1.6rem',

      [appTheme.breakpoints.down('bp576')]: {
        paddingLeft: '1rem',
      }
    },

    name: {
      fontWeight: '600',
      fontSize: fluid(1.8, 1.6),
      color: 'var(--clr-text-900)',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '0.2rem',
      }
    }
  },

  link: {
    position: 'relative',
    fontWeight: '500',
    fontSize: fluid(1.6, 1.4),
    whiteSpace: 'nowrap',
    color: 'var(--clr-primary-400)',
    textDecoration: 'none',

    '&::after': {
      content: `""`,
      position: 'absolute',
      left: '0',
      top: '100%',
      width: '100%',
      height: '0.1rem',
      backgroundColor: 'var(--clr-primary-400)',
      transition: 'width var(--transition)',
    },

    '&:hover::after': {
      width: '0',
    },

    [appTheme.breakpoints.down('bp370')]: {
      marginTop: '1rem',
    }
  },

  
}