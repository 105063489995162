import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  reviews: {
    paddingTop: fluid(7, 4),
    paddingBottom: fluid(7, 4),
    overflow: "hidden",

    '.heading': {
      marginBottom: '5rem',
    }
  },

  link: {
    textAlign: 'center',
    [appTheme.breakpoints.down('bp768')]: {
      paddingTop: fluid(7, 4)
    }
  }
}
