import { Box } from "@mui/material";

import useCurrentSeo from "../../../hooks/use-current-seo";
import { Container } from "../../common/container/container";

import { style } from "./seoBlock.style";

export const SeoBlock = ({ data, variant = "default" }) => {
  const { seo } = useCurrentSeo();

  const __html = seo !== "" ? seo : data;

  const defineClasses = () => {
    let classes;
    let classNames = "seo-block";

    switch (variant) {
      case "default":
        classNames += " seo-block_variant_default";
        classes = style.seoBlockDefault;
        break;
      case "tools":
        classNames += " seo-block_variant_tools";
        classes = style.seoBlockTools;
        break;
      case "article":
        classNames += " seo-block_variant_article";
        classes = style.seoBlockArticle;
        break;
      case "info-page":
        classNames += " seo-block_variant_info-page";
        classes = style.seoBlockInfoPage;
        break;
      default:
        break;
    }

    return { classes, classNames };
  };

  const hasContent = (html) => {
    const text = html.replace(/<[^>]*>/g, "").trim();
    return text !== "";
  };

  if (!hasContent(__html)) {
    return null;
  }

  return (
    <Box sx={style.proxySeo} className="proxy-seo">
      <Container>
        <Box
          sx={defineClasses().classes}
          className={defineClasses().classNames}
          dangerouslySetInnerHTML={{ __html }}
        ></Box>
      </Container>
    </Box>
  );
};
