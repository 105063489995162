import { useCallback, useState } from "react";

export const useSelection = (target, totalElements) => {
  const [selected, setSelected] = useState([]);

  const handleSelectAll = useCallback(
    async (idsArr = []) => {
      if (selected.length === totalElements) {
        setSelected([]);
        return;
      }
      setSelected(idsArr);
    },
    [target, selected?.length, setSelected]
  );
  const handleSelectMultiple = (idsArr = [], value) => {
    if (value) setSelected((prev) => [...prev, ...idsArr]);
    else setSelected((prev) => prev.filter((el) => !idsArr.some((item) => item.id === el.id)));
  };

  const handleSelect = useCallback(
    (id, autoExtend, checked) => {
      if (checked) setSelected((prev) => [...prev, { id, autoExtend }]);
      else setSelected((prev) => prev.filter((item) => item.id !== id));
    },
    [selected, setSelected]
  );

  const resetSelected = () => {
    setSelected([]);
  };

  return {
    selected,
    selectedLength: selected.length,
    handleSelectAll,
    handleSelect,
    resetSelected,
    handleSelectMultiple
  };
};
