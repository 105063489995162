import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  article: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
    ".loader_type_app": {
      minHeight: "initial"
    }
  },

  articleBody: {
    // @media (min-width: em(993)) {
    display: "flex",
    alignItems: "flex-start",
    [appTheme.breakpoints.down("bp992")]: {
      display: "flex"
    }
  },

  content: {
    flexBasis: "100%",
    paddingRight: "10rem",
    fontSize: "1.6rem",

    h1: {
      marginBottom: "2rem",
      fontWeight: "700",
      fontSize: fluid(3.6, 2.8),
      lineHeight: "115%",
      color: "var(--clr-text-700)"
    },

    h2: {
      marginBottom: "2rem",
      fontWeight: "600",
      fontSize: "2rem",
      color: "var(--clr-text-900)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "1.2rem"
      }
    },

    h3: {
      marginBottom: "1.5rem",
      fontWeight: "500",
      fontSize: "1.8rem",
      color: "var(--clr-text-900)"
    },

    "& p": {
      marginBottom: "1.5rem",
      fontWeight: "400",
      // fontSize: '1.4rem',
      lineHeight: "160%",
      color: "var(--clr-text-400)"
    },

    "& a": {
      color: "var(--clr-primary-500)"
    },

    "ul, ol": {
      marginBottom: "1.5rem",

      li: {
        position: "relative",
        marginBottom: "1rem",
        paddingLeft: "2rem",
        fontWeight: "400",
        // fontSize: '1.4rem',
        lineHeight: "140%",
        color: "var(--clr-text-400)"
      }
    },

    ul: {
      "li::before": {
        content: `""`,
        position: "absolute",
        left: "0.5rem",
        top: "50%",
        borderRadius: "50%",
        width: "0.3rem",
        height: "0.3rem",
        backgroundColor: "var(--clr-text-400)"
      }
    },

    ol: {
      counterReset: "section",

      "li::before": {
        counterIncrement: "section",
        content: 'counters(section, ".") "." !important',
        position: "absolute",
        left: "0",
        fontWeight: "400",
        // fontSize: '1.4rem',
        color: "var(--clr-text-400)"
      }
    },

    img: {
      marginTop: "1.5rem",
      marginBottom: "3rem",
      maxWidth: "100%",
      height: "auto",
      objectFit: "contain"
    },

    [appTheme.breakpoints.down("bp992")]: {
      paddingRight: "initial"
    }
  },

  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "3rem"
  },

  table: {
    wrapper: {
      overflowX: "auto",

      table: {
        overflow: "hidden",
        minWidth: "580px !important"
      }
    }
  },

  date: {
    marginRight: "1.5rem",
    borderRadius: "0.7rem",
    padding: "0.6rem 1.5rem",
    fontWeight: "400",
    fontSize: fluid(1.4, 1.2),
    color: "var(--clr-primary-500)",
    backgroundColor: "rgba(var(--clr-primary-400-rgb) / 15%)"
  },

  views: {
    fontWeight: 400,
    fontSize: fluid(1.4, 1.2),
    color: "var(--clr-text-400)",

    svg: {
      marginRight: "0.5rem"
    }
  },

  sidebar: {
    "--sidebar-width": "min(37rem, 100%)",
    position: "sticky",
    top: "2rem",
    flexShrink: 0,
    width: "var(--sidebar-width)",
    inner: {
      [appTheme.breakpoints.down("bp992")]: {
        overflowX: "hidden",
        overflowY: "auto",
        padding: "4rem var(--container-offset)",
        width: "100%",
        height: "100%"
      }
    },

    open: {
      [appTheme.breakpoints.down("bp992")]: {
        left: "calc(100% - var(--sidebar-width))"
      }
    },

    [appTheme.breakpoints.down("bp992")]: {
      position: "fixed",
      left: "100%",
      top: "0",
      zIndex: "3",
      height: "100%",
      boxShadow: "0 15px 25px rgba(179 184 202 / 22%)",
      backgroundColor: "var(--clr-default-100)",
      transition: "left var(--transition)"
    }
  },

  widget: {
    "&:not(:last-child)": {
      marginBottom: "6rem"
    },

    ".heading": {
      marginBottom: "4rem"
    },

    ".blog-card": {
      "&:not(:last-child)": {
        marginBottom: "3rem"
      }
    }
  },

  asideToggler: {
    position: "fixed",
    right: "0",
    top: "50%",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "0.6rem",
    borderBottomLeftRadius: "0.6rem",
    width: "2.5rem",
    height: "4.5rem",
    backgroundColor: "var(--clr-default-300)",
    transform: "translateY(-50%)",

    svg: {
      width: "1.5rem",
      height: "1.5rem",
      stroke: "var(--clr-primary-400)",
      objectFit: "contain",
      transition: "transform var(--transition)"
    },

    open: {
      svg: {
        transform: "rotate(180deg)"
      }
    },

    [appTheme.breakpoints.down("bp992")]: {
      display: "flex"
    }
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "6.5rem",

    prev: {
      marginRight: "10px",
      "&:hover": {
        svg: {
          transform: "translateX(-1rem)"
        }
      },

      svg: {
        marginRight: "3rem"
      }
    },

    next: {
      marginLeft: "10px",
      "&:hover": {
        svg: {
          transform: "translateX(1rem)"
        }
      },

      span: {
        textAlign: "right"
      },

      svg: {
        marginLeft: "3rem"
      }
    },

    a: {
      display: "flex",
      flex: "0 1 50%",
      alignItems: "center",
      textDecoration: "underline",

      span: {
        fontSize: fluid(2, 1.8),
        lineHeight: "120%",
        color: "var(--clr-text-900)"
      },

      "& > svg": {
        width: "1.6rem",
        height: "1.6rem",
        stroke: "var(--clr-text-900)",
        objectFit: "contain",
        transition: "transform var(--transition)"
      },

      [appTheme.breakpoints.down("bp767")]: {
        flexBasis: "100%",
        marginBottom: "3rem"
      }
    },

    [appTheme.breakpoints.down("bp767")]: {
      flexWrap: "wrap",
      marginTop: "4rem"
    }
  },

  noData: {
    fontSize: "24px",
    textAlign: "center",
    maxWidth: "300px",
    margin: "0 auto",
    paddingBottom: "180px",
    paddingTop: "80px"
  }
};
