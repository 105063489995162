import React, { memo, useRef, useState } from "react";

import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SelectList } from "../../../../../components/common/select-list/select-list";
import { Button } from "../../../../../components/ui";
import { useLang } from "../../../../../hooks";

import { style } from "./price-list-by-quantity.style";

const PriceItem = (props) => {
  const { quantity, rentPeriods, onBuy, onOpen } = props;

  const { t } = useTranslation();
  const lang = useLang();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handlerChange = (data) => {
    const rentPeriodIndex = rentPeriods.findIndex((e) => e.rentPeriodId === data.key);
    setSelectedIndex(rentPeriodIndex);
  };

  const totalPrice = rentPeriods[selectedIndex]?.amount ?? 0;
  const priceByOne = rentPeriods[selectedIndex]?.amountForOne ?? 0;

  return (
    <Box component="li" sx={style.item.wrapper}>
      <Box>
        <Box sx={style.item.quantity}>
          {t("common.quantity")} {quantity} {t("other.ips")}
        </Box>
        <Box sx={style.item.select}>
          <SelectList
            name={`rental-period-for-quantity-${quantity}`}
            isPortal={true}
            selectedIndex={selectedIndex}
            options={rentPeriods.map((e) => ({
              key: e.rentPeriodId,
              label: e.namesByLocale[lang]
            }))}
            onChange={handlerChange}
            onOpen={onOpen}
          />
        </Box>
      </Box>
      <Box>
        <Box sx={style.item.price}>
          <Box component="span">${totalPrice.toFixed(2)}</Box>
          <Box component="span">{`$${priceByOne.toFixed(2)}/1 IP`}</Box>
        </Box>
        <Box sx={style.item.button}>
          <Button
            variant={"outlined"}
            onClick={() => {
              onBuy({
                quantity,
                rentPeriodId: rentPeriods[selectedIndex].rentPeriodId
              });
            }}
          >
            {t("form.actions.buyProxy")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const PriceItemSkeleton = () => {
  return (
    <Box component="li" sx={style.item.wrapper}>
      <Box>
        <Box sx={style.item.quantity}>
          <Skeleton variant="text" sx={{ fontSize: "1.8rem" }} />
        </Box>
        <Box sx={style.item.select}>
          <Skeleton variant="rounded" height={37} />
        </Box>
      </Box>
      <Box>
        <Box sx={style.item.price}>
          <Skeleton variant="text" height={30} width={100} />
        </Box>
        <Box sx={style.item.button}>
          <Skeleton variant="rounded" height={37} width={100} />
        </Box>
      </Box>
    </Box>
  );
};
const PriceListByQuantity = (props) => {
  const { loading, items, onBuy, heightBlock } = props;
  if (!heightBlock) return;

  const openMenuRef = useRef(null);

  const handlerScroll = () => {
    if (openMenuRef.current) {
      openMenuRef.current(false);
    }
  };

  return (
    <Box
      sx={{ ...style.list, maxHeight: heightBlock ? heightBlock + "px" : "610px" }}
      component="ul"
      onScroll={handlerScroll}
    >
      {loading ? (
        <>
          <PriceItemSkeleton />
          <PriceItemSkeleton />
          <PriceItemSkeleton />
          <PriceItemSkeleton />
        </>
      ) : (
        items.map(([quantity, data], key) => (
          <PriceItem
            key={key}
            quantity={quantity}
            rentPeriods={data}
            onBuy={onBuy}
            onOpen={(e) => {
              openMenuRef.current = e;
            }}
          />
        ))
      )}
    </Box>
  );
};

export default memo(PriceListByQuantity);
