import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {

  description: {
    marginBottom: '3.5rem',

    '& > p': {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
      '& > p': { fontSize: "1.4rem" }
    }
  },

  panel: {
    display: 'flex',
    borderRadius: '3.5rem',
    padding: '1.6rem 2rem 4rem',
    boxShadow: '0px 14px 30px rgba(165, 178, 208, 0.22)',
    backgroundColor: 'var(--clr-default-100)',

    info: {
      display: 'flex',
      flexGrow: '1',
      flexBasis: '60%',
      flexDirection: 'column',

      [appTheme.breakpoints.down('bp992')]: {
        flexBasis: '50%',
      },

      [appTheme.breakpoints.down('bp576')]: {
        order: '1',
      }
    },

    result: {
      flexGrow: '1',
      borderRadius: '2.2rem',
      padding: '1rem 2rem 3rem',
      backgroundColor: 'var(--clr-default-400)',
    },

    note: {
      marginTop: 'auto',
      paddingTop: '2.3rem',
      fontWeight: '400',
      fontSize: '1.4rem',
      color: 'var(--clr-text-400)',

      [appTheme.breakpoints.down('bp576')]: {
        paddingTop: '1rem',
      }
    },

    options: {
      flexGrow: '1',
      flexBasis: '40%',
      paddingLeft: '3.6rem',

      '.form-item': {
        marginBottom: '2.5rem',

        '&__field': {
          boxShadow: 'none',
          backgroundColor: 'var(--clr-default-400)',
        },

        [appTheme.breakpoints.down('bp576')]: {
          marginBottom: '2rem',
        }
      },

      '.form-select': {
        marginBottom: '3.8rem',
      },

      [appTheme.breakpoints.down('bp992')]: {
        flexBasis: '50%',
      },

      [appTheme.breakpoints.down('bp767')]: {
        paddingLeft: '2.6rem',
      },

      [appTheme.breakpoints.down('bp576')]: {
        order: '0',
        paddingLeft: '0',

        '.form-select': {
          marginBottom: '2rem',
        }
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      flexDirection: 'column',
      padding: '2rem',

      '& > .button': {
        order: '2',
        marginTop: '2rem',
      }
    }
  },

  seo: {
    paddingTop: fluid(8, 3)
  }
}
