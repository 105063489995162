import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";

import { Range } from "../common/range/range";
import { Checkbox } from "./checkbox/checkbox";
import { Input } from "./input/input";
import { RadioGroup } from "./radio/radio";
import { ControlledSelect, Select } from "./select/select";
import { Captcha } from "./captcha/captcha";

// import "./form.scss";

const Form = ({ schema, onSubmit, onError, defaultValues, className, children, context, sx }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: schema ? yupResolver(schema) : null,
    defaultValues
  });
  const checkContext = context ? context : methods;

  const submit = (data) => {
    onSubmit(data, checkContext.reset);
  };

  return (
    <FormProvider {...checkContext}>
      <Box
        component="form"
        className={!className ? "form" : `form ${className}`}
        sx={sx}
        onSubmit={checkContext.handleSubmit(submit)}
        onError={onError}
        noValidate
      >
        {children}
      </Box>
    </FormProvider>
  );
};

Form.Captcha = Captcha;
Form.Input = Input;
Form.Select = Select;
Form.ControlledSelect = ControlledSelect;
Form.Range = Range;
Form.Checkbox = Checkbox;
Form.RadioGroup = RadioGroup;

export { Form };
