import { Box } from "@mui/material";
import { useCallback } from "react";
import { style } from "./heading.style";

export const Heading = ({ tag: Tag = "h2", variant = "h2", color = "dark", center, children }) => {
  const defineClasses = useCallback(() => {
    const classes = [style.heading];
    let classNames = "heading";

    switch (variant) {
      case "h1":
        classes.push(style.heading.h1mod);
        classNames += " heading_h1";
        break;
      case "h2":
        classes.push(style.heading.h2mod);
        classNames += " heading_h2";
        break;
      case "h3":
        classes.push(style.heading.h3mod);
        classNames += " heading_h3";
        break;
      case "h4":
        classes.push(style.heading.h4mod);
        classNames += " heading_h4";
        break;
      default:
        break;
    }

    switch (color) {
      case "light":
        classes.push(style.heading.colorMod.light);
        break;
      case "dark":
        classes.push(style.heading.colorMod.dark);
        break;
      default:
        break;
    }

    if (center) classes.push(style.heading.centerMod);

    return { classes, classNames };
  }, [variant, color, center]);

  return (
    <Box sx={defineClasses().classes} className={defineClasses().classNames} >
      <Tag dangerouslySetInnerHTML={{ __html: children }}/>
    </Box>
  );
};
