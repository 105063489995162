import { fluid } from "../../../../assets/styles/functions/fluid";
import { appTheme } from "../../../../theme";

export const style = {
  mobileProxyHero: {
    position: "relative",
    zIndex: "0",
    overflow: "hidden",
    paddingTop: fluid(14, 12),
    [appTheme.breakpoints.down("bp576")]: {
      paddingTop: fluid(8, 8)
    },
    paddingBottom: fluid(11, 6.5),
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238 246 255 / 20%) 3.11%,
      rgba(81 127 245 / 35%) 100.73%
    )`,

    wrapper: {
      "& > .heading": {
        marginBottom: "1rem"
      }
    },

    description: {
      marginBottom: "3rem",
      maxWidth: "95ch",
      fontWeight: "400",
      fontSize: "1.6rem",
      lineHeight: "160%",
      letterSpacing: "0.02em",
      color: "var(--clr-text-400)",
      [appTheme.breakpoints.down("bp576")]: {
        fontSize: "1.4rem"
      }
    }
  },
  titleAndWidgetBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
    [appTheme.breakpoints.down("bp576")]: {
      flexDirection: "column-reverse",
      justifyContent: "start",
      alignItems: "start"
    },
    widget: {
      [appTheme.breakpoints.down("bp576")]: {
        minHeight: "2rem",
        marginBottom: "2rem"
      }
    }
  },

  mobileProxyOrder: {
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "3rem"
    },

    title: {
      fontWeight: "600",
      fontSize: fluid(2, 1.6),
      textTransform: "uppercase",
      color: "var(--clr-text-600)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "2rem"
      }
    },

    support: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      borderRadius: "1rem",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      minHeight: "4rem",
      backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
      transition: "background-color var(--transition)",

      [`@media (hover)`]: {
        "&:hover": {
          backgroundColor: "var(--clr-primary-500)",
          transition: "background-color var(--transition)",

          "& > span": {
            color: "var(--clr-text-100)",
            transition: "color var(--transition)"
          },

          "& > svg": {
            fill: "var(--clr-text-100)",
            transition: "fill var(--transition)"
          }
        }
      },

      "& > span": {
        fontFamily: `"SF Pro", sans-serif`,
        fontWeight: "500",
        fontSize: fluid(1.6, 1.4),
        color: "var(--clr-primary-500)",
        transition: "color var(--transition)",

        [appTheme.breakpoints.down("bp576")]: {
          display: "none"
        }
      },

      "& > svg": {
        marginRight: "0.8rem",
        width: "2rem",
        height: "2.3rem",
        fill: "var(--clr-primary-500)",
        transition: "fill var(--transition)",

        [appTheme.breakpoints.down("bp576")]: {
          marginRight: "0"
        }
      },

      [appTheme.breakpoints.down("bp576")]: {
        padding: "1rem 1.2rem"
      }
    },

    body: {
      display: "grid",
      gridGap: "3rem",
      gridTemplateColumns: "27rem 2fr 27rem",

      [appTheme.breakpoints.down("bp992")]: {
        gridTemplateColumns: "repeat(2, 1fr)"
      },

      [appTheme.breakpoints.down("bp767")]: {
        gridGap: "2rem"
      },

      [appTheme.breakpoints.down("bp576")]: {
        gridGap: "3rem",
        gridTemplateColumns: "1fr",
        overflow: "hidden",
        borderRadius: "3.5rem",
        padding: "4rem 2rem",
        boxShadow: "0 15px 25px rgba(179 184 202 / 22%)",
        backgroundColor: "var(--clr-default-100)"
      }
    },

    cardWithNote: {
      [appTheme.breakpoints.down("bp576")]: {
        order: 1,
        marginBottom: "-4rem"
      }
    },

    card: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      borderRadius: "3.5rem",
      padding: "4rem 2rem",
      boxShadow: "0 15px 25px rgba(179 184 202 / 22%)",
      backgroundColor: "var(--clr-default-100)",

      "&:nth-of-type(2)": {
        padding: "0"
      },

      ".form-select": {
        marginBottom: "1.5rem"
      },

      ".tabs": {
        marginBottom: "3rem"
      },

      ".form-range": {
        marginBottom: "2rem"
      },

      title: {
        marginBottom: "2rem",
        fontWeight: "600",
        fontSize: fluid(2, 1.6),
        color: "var(--clr-text-500)"
      },

      leftMod: {
        // '.mobile-proxy-order__price-cost': {
        //   marginBottom: '0.5rem',
        // },
        ".mobile-proxy-order__price-cost": {
          fontSize: "1.2rem"
        }
      },

      rightMod: {
        ".mobile-proxy-order__price": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
          borderBottom: "0.1rem solid var(--clr-ui-border-300)",
          paddingBottom: "0.5rem"
        },

        ".mobile-proxy-order__price-cost": {
          marginRight: "1rem"
        },

        ".mobile-proxy-order__price-discount": {
          fontSize: "1.4rem"
        },

        ".mobile-proxy-order__price-unit": {
          marginBottom: "2rem",
          fontWeight: "400",
          fontSize: "1.4rem",
          color: "var(--clr-text-900)",

          span: {
            fontSize: fluid(1.6, 1.4)
          }
        },

        [appTheme.breakpoints.down("bp992")]: {
          gridColumn: "span 2"
        }
      },

      [appTheme.breakpoints.down("bp576")]: {
        gridColumn: "span 2",
        overflow: "initial",
        borderRadius: "0",
        padding: "0",
        boxShadow: "none",
        backgroundColor: "transparent"
      }
    },

    price: {
      cost: {
        display: "flex",
        alignItems: "center",
        fontWeight: "400",
        fontSize: "1.4rem",
        color: "var(--clr-text-900)",

        "& > span": {
          marginRight: "0.8rem",
          fontWeight: "700",
          fontSize: fluid(3.6, 2.4),
          color: "var(--clr-text-600)"
        }
      },

      description: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontWeight: "400",
        fontSize: "1.2rem",
        color: "var(--clr-text-400)"
      },

      discount: {
        fontWeight: "400",

        "& > span": {
          fontWeight: "600",
          whiteSpace: "nowrap",
          color: "var(--clr-primary-400)"
        }
      }
    },

    rate: {
      paddingTop: "4rem",

      ".form-checkbox:not(:last-child)": {
        marginBottom: "1.2rem"
      },

      [appTheme.breakpoints.down("bp576")]: {
        paddingTop: "2rem"
      }
    },

    info: {
      marginBottom: "1rem",
      padding: "4rem 2rem 0",

      row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "&:not(:last-child)": {
          marginBottom: "1.6rem"
        }
      },

      label: {
        fontWeight: "400",
        fontSize: fluid(1.6, 1.4),
        color: "var(--clr-text-900)"
      },

      value: {
        display: "flex",
        alignItems: "center",
        fontWeight: 400,
        fontSize: fluid(1.6, 1.4),
        color: "var(--clr-text-900)",

        "& > img": {
          marginLeft: "1rem",
          width: "2rem",
          height: "2rem",
          objectFit: "contain"
        },

        "& > svg": {
          marginLeft: "1rem"
        }
      },

      [appTheme.breakpoints.down("bp576")]: {
        padding: "0"
      }
    },

    note: {
      position: "relative",
      marginTop: "auto",
      padding: "3rem 2rem 3rem",
      backgroundColor: "var(--clr-default-200)",

      title: {
        marginBottom: "0.8rem",
        fontWeight: "600",
        fontSize: fluid(1.8, 1.6),
        color: "var(--clr-text-500)"
      },

      content: {
        fontWeight: "400",
        fontSize: "1.6rem",
        lineHeight: "150%",
        color: "var(--clr-text-400)",
        [appTheme.breakpoints.down("bp576")]: { fontSize: "1.4rem" }
      },

      [appTheme.breakpoints.down("bp576")]: {
        marginRight: "-2rem",
        // marginBottom: "0.5rem",
        marginLeft: "-2rem",
        padding: "2.5rem 2rem"
      }
    },

    resultOptions: {
      container: {
        marginBottom: "3.2rem"
      },
      itemRow: {
        marginBottom: "2rem"
      },
      item: {
        marginBottom: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      },
      itemLabel: {
        fontWeight: "400",
        fontSize: fluid(1.6, 1.4),
        color: "var(--clr-text-900)"
      },
      itemContent: {
        fontWeight: "400",
        fontSize: fluid(1.6, 1.4),
        color: "var(--clr-text-900)"
      },
      itemSubContent: {
        fontWeight: "400",
        display: "block",
        fontSize: "1.2rem",
        color: "var(--clr-text-400)"
      }
    },

    promocode: {
      marginBottom: "1rem",
      minHeight: "5rem",

      span: {
        fontSize: "1.4rem",
        textDecoration: "underline",
        color: "var(--clr-primary-400)",
        cursor: "pointer"
      }
    }
  },

  promoLink: {
    fontSize: "1.4rem",
    textDecoration: "underline",
    color: "var(--clr-primary-400)",
    cursor: "pointer"
  }
};
