import { appTheme } from "../../../theme"

export const style = {
  cabinetPaymentHistory: {
    '& > .heading': {
      marginBottom: '4rem',
  
      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      }
    }
  },

  table: {
    action: {
      textAlign: 'right',
    }
  },

  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  fixedWidth: {
    maxWidth: '115px',
    boxSizing: 'border-box',
    'button': {
      minWidth: '100px !important',
      paddingRight: '10px !important',
      paddingLeft: '10px !important',
      
    }
  },

  copy: {
    marginRight: "10px"
  }
}