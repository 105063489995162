export const COMPANY_ROUTES = [
  {
    translation: "aboutUs",
    routes: [
      {
        key: "conditions",
        translation: "conditions",
        tags: ["conditions"]
      },
      {
        key: "faq",
        translation: "faq",
        tags: ["faq"]
      },
      {
        key: "contact-us",
        translation: "contacts",
        tags: ["contacts"]
      }
    ]
  },
  {
    translation: "information",
    routes: [
      {
        href: "https://api.youproxy.io",
        key: "documentation",
        translation: "documentation",
        tags: []
      },
      {
        key: "reviews",
        translation: "reviews",
        tags: ["reviews"]
      },
      {
        key: "promo",
        translation: "promocodes",
        tags: ["promocodes"]
      },
      {
        key: "blog",
        translation: "blog",
        tags: ["blog", "article"]
      }
    ]
  },
  {
    translation: "other",
    routes: [
      {
        key: "cabinet/profile",
        translation: "cabinet",
        tags: []
        // tags: cabinetRoutes.map((route) => route.tag)
      },
      {
        key: "offer",
        translation: "offer",
        tags: ["offer"]
      },
      {
        key: "privacy-policy",
        translation: "policy",
        tags: ["policy"]
      },
      {
        key: "refund-policy",
        translation: "refundPolicy",
        tags: ["refund-policy"]
      },
      {
        key: "cookie-policy",
        translation: "cookiePolicy",
        tags: ["cookie-policy"]
      }
    ]
  }
];
