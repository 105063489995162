import * as yup from "yup";
import { URL_REG } from "../../constants";

yup.addMethod(yup.string, 'notUrl', function notUrl(message) {
  return this.test(
    "notUrl",
    (({ label, path }) => message ?? `${label ?? path} should not contain a URL address`),
    (value) => !value.match(URL_REG)?.length
  )
});
