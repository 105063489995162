import React from "react";

export const ArrowIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.70096 1.25C4.27831 0.25 5.72169 0.25 6.29904 1.25L9.76314 7.25C10.3405 8.25 9.6188 9.5 8.4641 9.5H1.5359C0.381197 9.5 -0.34049 8.25 0.23686 7.25L3.70096 1.25Z"
      fill="white" />
  </svg>

);
