import { renderToStaticMarkup } from "react-dom/server";

import { FAVICON_LINKS } from "../constants/favicon-links";
import { FONTS_LINK } from "../constants/fonts-link";
import { LOCALS_LINK } from "../constants/locals-link";

import { getRobotContent } from "./getRobotContent.helper";

export const generateMetaTagsAndLinks = (title, description, language = "en", asString = false) => {
  const metaTags = [
    <html lang={language} key="html" />,
    <title key="title">{title || "Youproxy"}</title>,
    <base href="/" key="baseUrl" />,
    <meta charSet="utf-8" key="charset" />,
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width"
      key="viewport"
    />,
    <meta name="description" content={description || "YouproxyDes"} key="description" />,
    <meta itemProp="name" content={title} key="itemprop-name" />,
    <meta itemProp="description" content={description} key="itemprop-description" />,

    <meta
      itemProp="image"
      content={`/images/favicons/mstile-144x144.png`}
      key="itemprop-image"
    />,
    <meta property="og:title" content={title} key="og-title" />,
    <meta property="og:description" content={description} key="og-description" />,
    <meta property="og:image:width" content="1200" key="og-image-width" />,
    <meta property="og:image:height" content="1200" key="og-image-height" />,
    <meta
      property="og:image"
      content={`/images/favicons/mstile-144x144.png`}
      key="og-image"
    />,

    <meta name="twitter:title" content={title} key="twitter-title" />,
    <meta name="twitter:description" content={description} key="twitter-description" />,
    <meta
      name="twitter:image"
      content={`/images/favicons/mstile-144x144.png`}
      key="twitter-image"
    />,

    <meta name="robots" content={getRobotContent()} key="robots" />,
    <meta name="msapplication-TileColor" content="#da532c" key="msapplication-TileColor" />,
    <meta name="theme-color" content="#517FF5" key="theme-color" />,
    <meta name="lava" content="6nfUDayi8eHu7J2wET9ClbQt3LcVk5WprG4YdsZFjho01zAP" key="lava" />,
    <meta
      name="interkassa-verification"
      content="16363c8a9821d848080875c98446ee81"
      key="interkassa-verification"
    />,

    <meta name="image" content={`/images/favicons/mstile-144x144.png`} key="image" />,

    <link rel="alternate" hrefLang="x-default" href="https://youproxy.io/" key="alternate-x-default" />
    //   <link rel="canonical" href={canonicalUrl} />
  ];

  const faviconLinks = FAVICON_LINKS.map(({ rel, sizes, href }, i) => (
    <link rel={rel} sizes={sizes} href={href} key={`favicon-${i}`} />
  ));

  const fontLinks = FONTS_LINK.map(({ rel, href, as, type, crossOrigin }, i) => (
    <link rel={rel} href={href} as={as} type={type} crossOrigin={crossOrigin} key={`font-${i}`} />
  ));

  const localLinks = LOCALS_LINK.map(({ rel, hrefLang, href }, i) => (
    <link rel={rel} hrefLang={hrefLang} href={href} key={`local-${i}`} />
  ));

  const allMetaTags = [...metaTags, ...faviconLinks, ...fontLinks, ...localLinks];

  if (asString) {
    return renderToStaticMarkup(<>{allMetaTags}</>);
  }

  return allMetaTags;
};
