import { appTheme } from "../../../../theme";

export const style = {
  item: {
    padding: "3rem 2.5rem",
    display: "flex",
    flexDirection: "column",
    height: "248.5px",
    background: "rgba(0,0,0,0.03)",
    borderRadius: "3rem",
    [appTheme.breakpoints.down("bp992")]: {
      padding: "2.2rem 1.7rem",
      height: "239px"
    },

    date: {
      marginBottom: "1.2rem",
      height: "2.8rem",
      width: "13rem"
    },

    paragraph: {
      display: "flex",
      flexDirection: "column",
      minHeight: "12rem",
      marginBottom: "1rem",
      "& .string": {
        height: "1.4rem",
        marginBottom: "0.8rem",
        width: "100%",
        "&:last-child": {
          marginBottom: "0",
          width: "60%"
        }
      }
    },

    link: {
      height: "1.8rem",
      width: "8rem"
    }
  },

}