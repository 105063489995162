import { appTheme } from "../../../theme";

export const style = {
  tabHeader: {
    position: "relative",
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "inset 0 0 2rem rgba(197 202 213 / 20%)",
    backgroundColor: "var(--clr-default-200)",

    [appTheme.breakpoints.down("bp370")]: {
      display: "flex"
    }
  },

  item: {
    position: "relative",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2.4rem",
    fontWeight: "400",
    fontSize: "1.4rem",
    whiteSpace: "nowrap",
    color: "var(--clr-text-900)",
    transition: "color var(--transition), background-color var(--transition)",
    textDecoration: "none",

    span: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },

    "&:hover": {
      // fontWeight: '500',
      color: "var(--clr-text-100)",
      backgroundColor: "var(--clr-primary-400)"
    },

    activeMod: {
      fontWeight: "500",
      color: "var(--clr-text-100)",
      backgroundColor: "var(--clr-primary-400)"
    }
  },

  dropdown: {
    position: "relative",
    width: "100%",
    marginBottom: "3rem",
    current: {
      width: "100%",
      minHeight: "3.2rem",
      padding: "1rem",
      borderRadius: "6rem",
      background: "#F9FAFE",
      boxShadow: "inset 0 0 2rem rgb(197 202 213 / 20%)",
      transition: "0.3s",
      "&:hover": {
        background: "#EEF2FC"
      },
      label: {
        overflow: "hidden",
        marginRight: "1rem",
        fontWeight: "400",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "var(--clr-text-900)"
      },

      arrow: {
        display: "flex",
        flexShrink: "0",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "auto",
        borderRadius: "50%",
        width: "2.4rem",
        height: "2.4rem",
        backgroundColor: "var(--clr-primary-400)",
        transition: "transform var(--transition)",

        "& > svg": {
          paddingTop: "0.2rem",
          width: "1rem",
          height: "1rem",
          stroke: "var(--clr-text-100)",
          objectFit: "contain"
        }
      }
    },

    item: {
      padding: "1rem 2rem",
      fontWeight: "400",
      textAlign: "left",
      color: "var(--clr-text-900)",
      width: "100%",
      active: {
        backgroundColor: "var(--clr-default-300)",
        pointerEvents: "none"
      }
    },

    ".tabs__dropdown-item, .tabs__dropdown-current": {
      display: "flex",
      alignItems: "center",
      fontSize: "1.4rem"
    },

    body: {
      position: "absolute",
      left: "50%",
      top: "115%",
      zIndex: "999",
      overflow: "hidden",
      borderRadius: "1.2rem",
      width: "100%",
      boxShadow: "0px 2px 22px rgba(95, 103, 128, 0.14)",
      backgroundColor: "var(--clr-default-100)",
      opacity: "0",
      visibility: "hidden",
      transform: "translate(-50%, 1rem)",
      transition: `opacity var(--transition), visibility var(--transition), transform var(--transition)`
    },

    list: {
      overflowY: "auto",
      maxHeight: "15rem"
    },

    activeMod: {
      ".tabs_dropdowm_current_arrow": {
        transform: "rotate(180deg)"
      },

      ".tabs_dropdown_body": {
        opacity: "1",
        visibility: "visible",
        transform: "translate(-50%, 0)"
      },

      "& > button": {
        background: "#EEF2FC"
      }
    }
  },

  // direction
  directionHorizontal: {
    "& .tabs__header": {
      borderRadius: "3rem"
      // min-height: 3.6rem,
    }
  },

  directionVertical: {
    display: "flex",
    alignItems: "flex-start",

    "& .tabs__header": {
      borderRadius: "3rem"
    },

    ".tabs__inner": {
      overflowY: "auto",
      // width: min(100%, 22.5rem),
      maxWidth: "24rem",
      maxHeight: "24rem"
    },

    ".tabs__item": {
      justifyContent: "flex-start",
      padding: "0.5rem 2rem",
      width: "100%",
      // min-height: 3.6rem,
      textAlign: "left"
    },

    ".tabs__content": {
      paddingTop: "2.8rem",
      paddingBottom: "2.8rem",
      paddingLeft: "4.6rem"
    }
  },

  // size
  sizeSmall: {
    "& .tabs__header": {
      padding: "0.6rem 1rem"
    },

    ".tabs__item": {
      borderRadius: "1.2rem",
      padding: "0.4rem 0.5rem",
      minHeight: "3.2rem"
    }
  },

  sizeDefault: {
    "&.tabs_direction_horizontal": {
      "& .tabs__header": {
        padding: "1rem",

        [appTheme.breakpoints.down("bp576")]: {
          padding: "0.8rem"
        }
      },

      ".tabs__item": {
        borderRadius: "2.4rem",
        padding: "0.4rem 3rem",
        minHeight: "3.2rem",

        [appTheme.breakpoints.down("bp576")]: {
          padding: "0.4rem 2rem"
        }
      }
    },

    "&.tabs_direction_vertical": {
      "& .tabs__header": {
        flexShrink: "0",
        overflow: "hidden",
        padding: "2rem 1.5rem 2rem 2rem"
      },

      ".tabs__inner": {
        paddingRight: "1rem",
        width: "100%"
      },

      ".tabs__item": {
        padding: "0.5rem 2rem",
        minHeight: "4rem"
      }
    }
  },

  sizeMedium: {
    "&.tabs_direction_horizontal": {
      "& .tabs__header": {
        padding: "1rem"
      },

      ".tabs__item": {
        borderRadius: "2.4rem",
        padding: "0.4rem 1.6rem",
        minHeight: "3.2rem",

        [appTheme.breakpoints.down("bp576")]: {
          padding: "0.4rem 2rem"
        }
      }
    },

    "&.tabs_direction_vertical": {
      "& .tabs__header": {
        flexShrink: "0",
        overflow: "hidden",
        padding: "2rem 1.5rem 2rem 2rem"
      },

      ".tabs__inner": {
        paddingRight: "1rem",
        width: "100%"
      },

      ".tabs__item": {
        padding: "0.5rem 2rem",
        minHeight: "4rem"
      }
    }
  },

  sizeLarge: {
    "& .tabs__header": {
      padding: "1rem"
    },

    "& .tabs__item": {
      borderRadius: "2.4rem",
      padding: "0.6rem 3rem",
      minHeight: "4.4rem"
    }
  },

  // align
  alignMod: {
    left: {
      textAlign: "left"
    },

    center: {
      textAlign: "center",

      ".tabs__content": {
        textAlign: "left"
      }
    }
  },

  justifyMod: {
    "&.tabs_direction_horizontal": {
      "& .tabs__header": {
        justifyContent: "flex-start",
        paddingBottom: "calc(1rem - 0.25rem)"
      },

      ".tabs__item": {
        marginBottom: "0.25rem"
      }
    }
  },

  fullwidthMod: {
    "& .tabs__header": {
      display: "flex"
    }
  },

  withContentMod: {
    "&.tabs_direction_horizontal": {
      "& .tabs__header": {
        marginBottom: "3rem"
      }
    },

    "&.tabs_mobile": {
      "& .tabs__header": {
        marginBottom: "2rem"
      }
    }
  },

  mobileMod: {
    "& .tabs__header": {
      borderRadius: "3rem",
      padding: "0.5rem 2rem",
      minHeight: "4.4rem",

      "&::after": {
        display: "none"
      }
    },

    ".tabs__dropdown": {
      item: {
        padding: "1rem 2rem"
      }
    }
  },
  hiddenElement: {
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
    height: "1px",
    overflow: "hidden",
    border: 0,
    padding: 0,
    clip: "rect(0, 0, 0, 0)",
    clipPath: "inset(50%)",
    margin: "-1px"
  }
};
