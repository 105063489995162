import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  breakpoints: {
    values: {
      bp1470: 1470,
      bp1300: 1300,
      bp1200: 1200,
      bp992: 992,
      bp928: 928,
      bp850: 850,
      bp767: 767,
      bp768: 769,
      bp576: 576,
      bp565: 565,
      bp460: 460,
      bp370: 370
    }
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "var(--font-family)"
        }
      }
    }
  }
});
