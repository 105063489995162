import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SeoBlock } from "../../../components/common/seo-block/seo-block";

import { style } from "./proxyChecker.style";

export const ProxyCheckerSeo = () => {
  const { t } = useTranslation();

  return (
    <Box sx={style.seo} className="tools-proxy-checker__seo">
      <SeoBlock data={t("pages.tools.proxyChecker.seo")} variant="tools" />
    </Box>
  );
};
