import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  toolsCheckWebrtc: {
    '& > .note': {
      marginBottom: '3rem',
    },

    '& > .button': {
      marginBottom: '5rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      }
    }
  },

  description: {
    marginBottom: '2.8rem',

    '& > p': {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      },
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '2rem',
      "& > p": {
        fontSize: "1.4rem"
      }
    }
  },

  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    borderRadius: '3rem',
    padding: '2rem 3rem',
    boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
    backgroundColor: 'var(--clr-default-100)',

    item: {
      '&:not(:last-child)': {
        marginRight: '1rem',
      },

      title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        fontWeight: '400',
        fontSize: '1.4rem',
        color: 'var(--clr-text-400)',

        img: {
          marginRight: '0.9rem',
          width: '1.8rem',
          height: '1.8rem',
          objectFit: 'contain',
        }
      },

      text: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        fontSize: '2.1rem',
        color: 'var(--clr-text-400)',

        img: {
          flexShrink: '0',
          alignSelf: 'flex-start',
          marginRight: '1rem',
          marginBottom: '0.2rem',
          width: '2.4rem',
          height: '2.4rem',
          objectFit: 'contain',
        }
      },

      [appTheme.breakpoints.down('bp767')]: {
        marginRight: '0',
        borderRadius: '3rem',
        padding: '2rem',
        width: '100%',
        boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
        backgroundColor: 'var(--clr-default-100)',

        '&:not(:last-child)': {
          marginBottom: '2rem',
        }
      }
    },

    [appTheme.breakpoints.down('bp767')]: {
      flexDirection: 'column',
      marginBottom: '3rem',
      padding: '0',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    }
  },

  widgets: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(2, 1fr)',

    [appTheme.breakpoints.down('bp767')]: {
      gridGap: '2rem',
      gridTemplateColumns: '1fr',
    }
  },

  warning: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '3rem',
    padding: '3.4rem 3rem',
    boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
    backgroundColor: 'var(--clr-default-100)',
    height: "fit-content",

    icon: {
      marginBottom: '1.5rem',
    },

    text: {
      fontWeight: '500',
      fontSize: fluid(1.8, 1.6),
      lineHeight: '133%',
      color: 'var(--clr-text-400)',

      '& > span': {
        color: '#dc9832',
      }
    }
  },

  seo: {
    paddingTop: fluid(8, 3),
  }

}
