import { setCaptchaTokenToRequestHeaders } from "../utils/helpers";

import { $api } from "./axios.service";

export class OrdersService {
  static getOrdersExportTemplate() {
    return $api.get("/api/front/cabinet/orders/file/export/template");
  }
  static setOrdersExportTemplate(payload, cfToken) {
    return $api.put("/api/front/cabinet/orders/file/export/template", payload, {
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static getOrdersByProxyType(creds) {
    return $api.post("/api/front/cabinet/orders", creds);
  }

  static getActiveOrders(proxyTypeId) {
    return $api.get(`/api/front/cabinet/orders/active`, { params: { proxyTypeId } });
  }

  static getDataForExport(proxyTypeId) {
    return $api.get(`/api/front/cabinet/orders/file`, { params: { proxyTypeId } });
  }

  static getDataForExportByIpAddresses(payload) {
    return $api.post(`/api/front/cabinet/orders/file`, payload);
  }

  static getOrderIds(creds) {
    return $api.post("/api/front/cabinet/orders/ids", creds);
  }

  static getOrdersData(creds) {
    return $api.post("/api/front/cabinet/orders/data", creds);
  }

  static getOrderGoals(searchCriteria) {
    return $api.get(`/api/front/goalSearch/search`, { params: { searchCriteria } });
  }

  static getIpsFromOrder(creds) {
    return $api.post("/api/front/cabinet/orders/proxyType/ipAddressIds", creds);
  }

  
}
