import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  toolsFingerprintScanner: {},

  description: {
    marginBottom: '2.8rem',

    '& > p': {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
      '& > p': {
        fontSize: '1.4rem',
      }
    }
  },

  widgets: {
    position: "relative",
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(8, 1fr)',

    '.widget-copy': {
      gridColumn: 'span 5',

      '&:first-of-type': {
        gridColumn: 'span 3',
      },

      [appTheme.breakpoints.down('bp767')]: {
        gridColumn: 'span 4',

        '&:first-of-type': {
          gridColumn: 'span 4',
        }
      },

      [appTheme.breakpoints.down('bp576')]: {
        gridColumn: 'span 8',
        marginBottom: '1rem',

        '&:first-of-type': {
          gridColumn: 'span 8',
          margin: '0',
        }
      }
    },

    '.widget-table, .widget-info': {
      gridColumn: 'span 4',

      [appTheme.breakpoints.down('bp1200')]: {
        gridColumn: 'span 8',
      },

      [appTheme.breakpoints.down('bp922')]: {
        gridColumn: 'span 4',
      },

      [appTheme.breakpoints.down('bp767')]: {
        gridColumn: 'span 8',
      }
    },

    [appTheme.breakpoints.down('bp922')]: {
      gridGap: '2.5rem',
    },

    [appTheme.breakpoints.down('bp767')]: {
      gridGap: '2rem',
    }
  },

  seo: {
    paddingTop: fluid(8, 3)
  }
}
