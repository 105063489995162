import { appTheme } from "../../../../theme";

export const style = {
  options: {
    position: "relative",
    "&.table-options_open": {
      ".table-options__body": {
        opacity: "1",
        visibility: "visible"
      },

      "&.table-options_position": {
        "&_bottom": {
          "& .table-options__body": {
            transform: "translateY(0)"
          }
        },

        "&_top": {
          "& .table-options__body": {
            transform: "translateY(0)"
          }
        },

        "&_forOne": {
          "& .table-options__body": {
            transform: "translateY(0)"
          }
        }
      }
    }
  },

  icon: {
    position: "relative",
    display: "flex",
    flexShrink: "0",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1rem",
    width: "3.5rem",
    height: "3.5rem",
    transition: "background-color var(--transition)",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
      transition: "background-color var(--transition)",

      "& > svg": {
        fill: "var(--clr-primary-400)"
      }
    },

    "& > svg": {
      width: "1.8rem",
      height: "1.8rem",
      fill: "var(--clr-text-400)",
      objectFit: "contain",
      transition: "fill var(--transition)"
    }
  },

  body: {
    position: "absolute",
    right: "-2rem",
    zIndex: "999",
    overflow: "hidden",
    borderRadius: "1.2rem",
    width: "14.5rem",
    boxShadow: "0px 2px 22px rgba(95, 103, 128, 0.14)",
    backgroundColor: "var(--clr-default-100)",
    opacity: "0",
    visibility: "hidden",
    transition:
      "opacity var(--transition), visibility var(--transition), transform var(--transition)"
  },

  list: {
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "15rem"
  },

  item: {
    display: "flex",
    alignItems: "center",
    padding: "0.7rem 2rem",
    width: "100%",
    fontWeight: "500",
    fontSize: "1.4rem",
    color: "var(--clr-text-400)",
    transition: "background-color var(--transition)",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
      transition: "background-color var(--transition)"
    },

    "& > svg": {
      flexShrink: "0",
      marginRight: "1.2rem",
      width: "1.2rem",
      height: "1.2rem",
      fill: "var(--clr-text-400)",
      objectFit: "contain"
    },

    disabled: {
      pointerEvents: "none",
      opacity: "0.5"
    }
  },

  comment: {
    "& > .heading": {
      lineHeight: "32px"
    },
    caption: {
      marginBottom: "1rem",
      fontWeight: "400",
      fontSize: "1.4rem"
    },

    text: {
      fontWeight: "400",
      fontSize: "1.6rem",
      lineHeight: "24px",
      color: "var(--clr-text-400)",
      maxHeight: "24rem",
      overflow: "auto",
      fontFamily: "Gilroy",
      paddingRight: "1rem",
      marginBottom: "1rem"
      // wordBreak: 'break-all'
    },

    buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "2rem"
    },

    "& > .form-textarea": {
      width: "100%",
      height: "15.5rem"
    },

    viewMod: {
      "& > .heading": {
        marginBottom: "2rem"
      }
    },

    addMod: {
      "& > .heading": {
        marginBottom: "2rem"
      }
    },
    deleteMod: {
      "& > .heading": {
        marginBottom: "1rem"
      }
    }
  },

  position: {
    bottom: {
      ".table-options__body": {
        top: "150%",
        transform: "translateY(1rem)"
      }
    },

    top: {
      ".table-options__body": {
        bottom: "150%",
        transform: "translateY(-1rem)"
      }
    },

    forOne: {
      ".table-options__body": {
        bottom: "10%",
        right: "5rem"
      }
    }
  },

  viewSkeleton: {
    fontSize: "16px",
    "&:not(:first-child)": {
      marginTop: "8px"
    }
  },

  modal: {
    maxWidth: "36rem",
    padding: "3rem 4rem",
    paddingTop: "3.5rem",

    "& .modal__close": {
      top: "24px",
      right: "24px",
      width: "2.4rem",
      height: "2.4rem",
      svg: {
        strokeWidth: 2,
        "&:hover": {
          strokeWidth: 4
        }
      }
    },

    [appTheme.breakpoints.down("bp460")]: {
      padding: "2rem 3rem",
      paddingTop: "2.5rem",

      "& .modal__close": {
        top: "34px",
        right: "21px"
      }
    }
  }
};
