import { useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Form } from "../../../components/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { Modal } from "../../../components/ui/modal/modal";

// import { useTranslation } from "../../../hooks";
import { style } from "./orders.style";

export default function ModalInsertIpList({
  openedModalIpList,
  closeModalIpList,
  allOrders,
  setSelectedIp
}) {
  const [inputValueIpList, setInputValueIpList] = useState("");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // лише цифри, крапки, двокрапки та пробіли
  const handleKeyPress = (e) => {
    if (e.key !== "Enter") {
      const allowedKeys = /^[0-9.:\s]$/;
      if (!allowedKeys.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  //  лише цифри, крапки, двокрапки та пробіли
  const handleChangeInputListIp = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^0-9.:\s]/g, "");
    setInputValueIpList(filteredValue);
  };

  // Валідація IP-адрес
  const isValidIP = (ip) => {
    const pattern =
      /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)(:\d{1,5})?$/;
    return pattern.test(ip);
  };

  // Функція для очищення та валідації  IP-s
  const validateIpList = () => {
    const validatedIps = Array.from(
      new Set(
        inputValueIpList
          .split(/[,\n\s]+/)
          .map(
            (line) =>
              line
                .trim()
                .replace(/^[.:]+|[.:]+$/g, "") // зайві крапки та двокрапки з початку та кінця
                .replace(/\s+/g, " ") //  всі на один пробіл
          )
          .filter((line) => line.length > 0 && isValidIP(line)) // валідні IP-адреси
      )
    );

    return validatedIps;
  };

  // Функція для видалення IPs зі строки
  const removeIpsFromString = (inputString, ipsToRemove) => {
    let cleanedString = inputString;

    ipsToRemove.forEach((ip) => {
      const regex = new RegExp(`\\b${ip}\\b\\s*`, "g");
      cleanedString = cleanedString.replace(regex, "\n");
    });

    cleanedString = cleanedString.trim().replace(/\s{2,}/g, "\n");
    return cleanedString.trim()
      ? cleanedString + "\n\n\n" + ipsToRemove.join("\n")
      : ipsToRemove.join("\n");
  };

  const handleSubmit = (_, reset) => {
    const startLengthIpList = inputValueIpList.trim().split(/\s+/).filter(Boolean).length;

    const validatedIps = validateIpList();
    const ordersIp = [];
    const foundIpsWithPorts = [];

    if (validatedIps.length === 0) {
      enqueueSnackbar(t("other.noMatchesIp"), { variant: "warning" });
      return;
    }

    const allOrdersMap = new Map();
    allOrders.forEach((order) => {
      if (!allOrdersMap.has(order.ip)) {
        allOrdersMap.set(order.ip, []);
      }
      allOrdersMap.get(order.ip).push(order);
    });

    // Перевіряємо валідні IP замовлень list
    validatedIps.forEach((ipWithPort) => {
      const [ip, port] = ipWithPort.split(":");

      if (port) {
        const matchedOrders = allOrders.filter(
          (order) => order.ip === ip && (order.portHttp === port || order.portSocks === port)
        );

        if (matchedOrders.length > 0) {
          ordersIp.push(matchedOrders[0]);
          foundIpsWithPorts.push(ipWithPort);
        }
      } else {
        const matchedOrders = allOrdersMap.get(ip);
        if (matchedOrders) {
          ordersIp.push(...matchedOrders);
          foundIpsWithPorts.push(ip);
        }
      }
    });

    if (ordersIp.length > 0) {
      setSelectedIp(ordersIp);

      if (ordersIp.length === startLengthIpList) {
        enqueueSnackbar(t("other.foundIp").replace("{{count}}", ordersIp.length), {
          variant: "success"
        });
        closeModalIpList();
        setInputValueIpList("");
        reset({ insertIpList: "" });
      } else {
        // Якщо не всі IP знайдені
        enqueueSnackbar(
          t("other.foundIpFrom")
            .replace("{{startCount}}", startLengthIpList)
            .replace("{{endCount}}", ordersIp.length),
          {
            variant: "success"
          }
        );
        closeModalIpList();
        //  залишаємо невірні IP для виправлення
        const cleanedString = removeIpsFromString(inputValueIpList, foundIpsWithPorts);
        setInputValueIpList(cleanedString);
        reset({ insertIpList: cleanedString });
      }
    } else {
      enqueueSnackbar(t("other.noMatchesIp"), { variant: "warning" });
    }
  };

  return (
    <Modal
      customStyles={style.panel.modalBlock}
      visible={openedModalIpList}
      cancelHandler={closeModalIpList}
    >
      <Form onSubmit={handleSubmit}>
        <Box sx={style.panel.modal.upper}>
          <Heading tag="h4" variant="h4">
            {t("other.insertList")}
          </Heading>
          <Box component="p" sx={style.panel.modal.subTitle}>
            {t("other.insertListDescription")}
          </Box>
          <Form.Input
            type="textarea"
            name="insertIpList"
            style={style.panel.modal.textArea}
            value={inputValueIpList}
            onChange={handleChangeInputListIp}
            onKeyPress={handleKeyPress}
          />
        </Box>
        <Box sx={style.panel.modal.bottom}>
          <Button
            type="submit"
            variant="primary"
            ariaLabel="submit"
            size="medium"
            disabled={inputValueIpList.trim().length < 7}
          >
            {t("other.apply")}
          </Button>
          <Button onClick={closeModalIpList} type="button" size="medium" ariaLabel="button">
            {t("other.cancel")}
          </Button>
        </Box>
      </Form>
    </Modal>
  );
}
