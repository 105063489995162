import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { XMark } from "../../../assets/images/icons/XMark";
import { TextInput } from "../text-input/text-input";

// import { useTranslation } from "../../../hooks";

export const PromocodeSection = (props) => {
  const {
    hasPromo,
    promo,
    onChange,
    onClose,
    onClick,
    promoLinkStyles = {},
    inputProps = {
      hasLabel: false,
      isOuterLabel: false,
      error: false,
      errorText: null,
      styles: {}
    }
  } = props;
  const { t } = useTranslation();

  const { hasLabel, isOuterLabel, styles, error, errorText } = inputProps;

  return (
    <>
      {hasPromo ? (
        <TextInput
          customStyles={styles}
          placeholder={t("form.placeholders.promocode")}
          isOuterLabel={isOuterLabel}
          action={
            <Box className="circle-button" onClick={onClose}>
              <XMark />
            </Box>
          }
          label={hasLabel ? t("form.labels.promocode") : null}
          value={promo}
          onChange={onChange}
          // error={!priceObj?.validPromoCode}
          error={error}
          errorText={errorText}
        />
      ) : (
        <Box sx={promoLinkStyles} className="order-modal__form-promocode" onClick={onClick}>
          {t("shared.hasPromocode")}
        </Box>
      )}
    </>
  );
};
