import { Box, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLang, useMatchedRoute } from "../../../../../hooks";
import { style } from "../../header.style";

export const Price = () => {
  const lang = useLang();
  const { t } = useTranslation();
  const matchedRoute = useMatchedRoute();

  const isActive = matchedRoute?.tag === "price";

  // console.count("Price");

  return (
    <Box sx={style.menu.item} className={`header__menu-item ${isActive ? "active" : ""}`}>
      <ButtonBase component={Link} to={`/${lang}/prices/`} className="header__menu-link">
        {t("menu.prices")}
      </ButtonBase>
    </Box>
  );
};
