import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Container } from "../../../../components/common/container/container";
import TrustpilotWidget from "../../../../components/common/widget/trustpilot-widget/trustpilot-widget";
import { Heading } from "../../../../components/ui/heading/heading";
import useCurrentSeo from "../../../../hooks/use-current-seo";

import { HeroOrder } from "./hero-order";
// import "./hero.scss";
import { style } from "./hero.style";

export const Hero = () => {
  // Translations
  const { t } = useTranslation();
  const seo = useCurrentSeo();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Box sx={style.mobileProxyHero} className="mobile-proxy-hero">
      <Container>
        <Box sx={style.mobileProxyHero.wrapper} className="mobile-proxy-hero__wrapper">
          <Box sx={style.titleAndWidgetBox}>
            <Heading tag="h1" variant="h2">
              {seo["h1"] ? seo["h1"] : t("pages.app.mobileProxy.hero.heading")}
            </Heading>
            <Box className="widgetBox" sx={style.titleAndWidgetBox.widget}>
              {isClient && <TrustpilotWidget />}
            </Box>
          </Box>
          <Box sx={style.mobileProxyHero.description} className="mobile-proxy-hero__description">
            {seo["h1Text"] ? seo["h1Text"] : t("pages.app.mobileProxy.hero.description")}
          </Box>
          <HeroOrder />
        </Box>
      </Container>
    </Box>
  );
};
