export const ROUTE = {
  EXPORT: "/export/new-window",
  PAYMENT: "/process/payment/perfectMoney",
  TOOLS: {
    ROOT: "/tools",
    MY_IP: "/tools/my-ip",
    PROXY_CHECKER: "/tools/proxy-checker",
    PORT_SCANNER: "/tools/port-scanner",
    PING_IP: "/tools/ping-ip",
    MY_ANONYMITY: "/tools/my-anonymity",
    FINGERPRINT_SCANNER: "/tools/fingerprint-scanner",
    CHECK_EVERY_COOKIE: "/tools/check-evercookie",
    CHECK_WEBRTC: "/tools/check-webrtc",
    IP_TRACING: "/tools/ip-tracing"
  },
  CABINET: {
    PROFILE: "/cabinet/profile",
    REDIRECT_CHANGE_EMAIL: "/api/emailAddress",
    ORDERS: "/cabinet/orders",
    NEW_ORDER: "/cabinet/new-order",
    BALANCE: "/cabinet/balance",
    PAYMENT_HISTORY: "/cabinet/payment-history",
    ACTIONS_HISTORY: "/cabinet/actions-history",
    PROMO_CODES: "/cabinet/promocodes",
    SUBSCRIPTION: "/cabinet/subscription",
    FAQ: "/cabinet/faq",
    DEVELOPERS_API: "/cabinet/developers-api"
  },
  AUTH: {
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    FORGOT: "/auth/forgot",
    RECOVERY: "/api/recovery"
  },
  APP: {
    REDIRECT_PAYMENT_STATUS: "/payment",
    HOME: "/",
    MOBILE: "/mobile",
    IPV6: "/ipv6",
    ISP: "/isp",
    PAYMENT_SUCCESS: "/payment/success",
    PAYMENT_REPLENISHMENT: "/payment/replenishment",
    FAQ: "/faq",
    PROMO_CODES: "/promo",
    REVIEWS: "/reviews",
    BLOG: "/blog",
    CONTACT_US: "/contact-us",
    CONDITIONS: "/conditions",
    OFFER: "/offer",
    PRIVACY_POLICY: "/privacy-policy",
    REFUND_POLICY: "/refund-policy",
    COOKIE_POLICY: "/cookie-policy",
    PRICES: "/prices",
    NOT_FOUND: "/not-found"
  }
};
