import { $api } from "./axios.service";
import { setCaptchaTokenToRequestHeaders } from "../utils/helpers";

export class DevelopersApiService {
  static getCredential() {
    return $api.get("/api/front/clientApiCredential");
  }
  static getApiKey() {
    return $api.get("/api/front/clientApiCredential/apiKey");
  }
  static updateCredential(payload, cfToken) {
    return $api.post(
      "/api/front/clientApiCredential",
      payload,
      { headers: setCaptchaTokenToRequestHeaders(cfToken) }
    );
  }
}
