import { $api } from "./axios.service";

export class ToolsService {
  static checkIp() {
    return $api.get("/api/tools/client/check/ip");
  }
  static getTraceRoute(payload, options = {}) {
    return $api.post("/api/tools/client/ip/traceroute", payload, options);
  }
  static portScan(payload, options = {}) {
    return $api.post("/api/tools/client/port/scan", payload, options);
  }
  static getFingerprint() {
    return $api.get("/api/tools/client/scanner/fingerprint");
  }
  static getAnonymityPercent(payload) {
    return $api.post("/api/tools/client/anonymity", payload);
  }
  static getAnonymity(payload, options = {}) {
    return $api.post("/api/tools/client/proxy/anonymity", payload, options);
  }

  static getProxyCheck(payload, options = {}) {
    return $api.post("/api/tools/client/proxy/checker/body/V2", payload, options);
  }
  static getProxyCheckClear() {
    return $api.get("/api/tools/client/proxy/checker/clear");
  }

  static getClientBlackListsCheck(countOfRequests = 1) {
    return $api.get(`/api/tools/client/blackList`, { params: { countOfRequests } });
  }
  static getPingIp(payload, options = {}) {
    return $api.post("/api/tools/client/ip/ping/body/V2", payload, options);
  }
  static getPingIpClear() {
    return $api.get("/api/tools/client/ping/ip/clear");
  }
}
