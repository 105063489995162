export const style = {
  sidebarDropdown: {
    position: 'relative',
    width: 'min(37rem, 100%)',
    active: {
      '.sidebar-dropdown__body': {
        opacity: '1',
        visibility: 'visible',
        transform: 'translateY(0)',
      },

      '.sidebar-dropdown__current-arrow': {
        transform: 'rotate(180deg)',
      }
    }
  },

  sidebarCurrent: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3rem',
    padding: '1rem 2rem',
    width: '100%',
    minHeight: '4.4rem',
    boxShadow: 'inset 0px 0px 20px rgba(197, 202, 213, 0.2)',
    backgroundColor: 'var(--clr-default-200)',
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      marginRight: '1rem',
      width: '2.4rem',
      height: '2.4rem',

      '& > svg': {
        // width: '100%',
        // height: '100%',
        fill: 'var(--clr-primary-400)',
        objectFit: 'contain',
      }
    },

    label: {
      marginRight: '1rem',
      fontWeight: '400',
      fontSize: '1.4rem',
      color: 'var(--clr-text-900)',
    },

    arrow: {
      display: 'flex',
      flexShrink: '0',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
      borderRadius: '50%',
      width: '2.4rem',
      height: '2.4rem',
      backgroundColor: 'var(--clr-primary-400)',
      transition: 'transform var(--transition)',

      '& > svg': {
        paddingTop: '0.2rem',
        width: '1rem',
        height: '1rem',
        stroke: 'var(--clr-text-100)',
        objectFit: 'contain',
      }
    },
  },

  body: {
    position: 'absolute',
    left: '0',
    top: '115%',
    zIndex: '5',
    overflow: 'hidden',
    borderRadius: '1.2rem',
    width: '100%',
    boxShadow: '0px 2px 22px rgba(95, 103, 128, 0.14)',
    backgroundColor: 'var(--clr-default-100)',
    opacity: '0',
    visibility: 'hidden',
    transform: 'translateY(2rem)',
    transition: 'visibility var(--transition), opacity var(--transition), transform var(--transition)',
  },

  list: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: "1.3rem 1.25rem"
  },

  sidebarItem: {
    display: "block",
    borderRadius: '2.4rem',

    '& > a, & > span': {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 2rem',
      width: '100%',
      fontWeight: '400',
      fontSize: '1.4rem',
      textAlign: 'left',
      color: 'var(--clr-text-900)',
      cursor: 'pointer',
    },

    icon: {
      marginRight: '1rem',
      width: '1.8rem',
      height: '1.8rem',

      '& > svg': {
        width: '100%',
        height: '100%',
        fill: 'var(--clr-text-900)',
        objectFit: 'contain',
      }
    },

    arrow: {
      marginTop: '0.2rem',
      marginLeft: 'auto',
      width: '1.2rem',
      height: '1.2rem',
      transition: 'transform var(--transition)',

      '& > svg': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      }
    },

    subItem: {
      display: 'block',
      padding: '0.5rem 2rem 0.5rem 4.8rem',
      fontWeight: '400',
      fontSize: '1.4rem',
      textAlign: 'left',
      color: 'var(--clr-text-900)',
    },

    active: {
      // backgroundColor: 'var(--clr-primary-400)',
      // color: "var(--clr-text-100)"
      color: 'var(--clr-text-100)',
      backgroundColor: 'var(--clr-primary-400)',
      transition: 'color var(--transition), background-color var(--transition)',

      '& > span, & > a': {
        color: "inherit"
      },
      '.sidebar-dropdown__item-icon': {
        svg: {
          fill: 'var(--clr-text-100)',
          transition: 'fill var(--transition)',
        }
      },

      '.sidebar-dropdown__item-arrow': {
        stroke: 'var(--clr-text-100)',
        transition: 'fill var(--transition)',
      },

      '.sidebar-dropdown__item-sub': {
        transition: 'height var(--transition), transform var(--transition)',

        '&-item': {
          color: 'rgba(var(--clr-text-100-rgb), 0.8)',
          transition: 'color var(--transition)',

          '&_active': {
            color: 'var(--clr-text-100)',
            background: 'transparent'
          }
        }
      }
    },

    open: {
      '.sidebar-dropdown__item-arrow': {
        transform: 'rotate(180deg)'
      },

      '.sidebar-dropdown__item-sub': {
        paddingBottom: '0.5rem'
      }
    },
  }
}
