import { useEffect, useState } from "react";

// hook for page and page size change
export const useTableNavigation = (defaultNav = { page: 0, pageSize: 10 }) => {
  // { page: 0, pageSize: 9 }
  const [pageObj, setPageObj] = useState(defaultNav);

  const pageChangeHandler = (value) => {
    setPageObj({ ...pageObj, page: value });
  };

  const sizeSelectHandler = (value) => {
    // setPageObj({ page: 0, pageSize: parseInt(value) });
    setPageObj(
      (prev) => prev.pageSize < parseInt(value) ? 
      ({ page: 0, pageSize: parseInt(value) }) : 
      ({ ...pageObj, pageSize: parseInt(value) })
    )
  };

  return [pageObj, pageChangeHandler, sizeSelectHandler]
}