export const style = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    padding: "32px",
    borderRadius: "30px",
    backgroundColor: "#F8F9FD",
    color: "#343435"
  },
  head: {
    display: "flex",
    fontSize: "2.4rem",
    fontWeight: 700,
    paddingBottom: "1.6rem",
    svg: {
      marginRight: "1.2rem"
    }
  },
  description:{
    paddingBottom: "2.4rem",
    lineHeight: "2.6rem",
  },
  footer: {

  }
}
