export const FolderColoredIcon = () => (
  <svg width="44" height="33" viewBox="0 0 44 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.2609 8.81614V26.4484C38.2609 28.6035 36.5893 30.1708 34.732 30.1708H3.52891C0 30.1708 0 31.1259 0 26.4484V3.72237C0 1.76323 1.48586 0 3.52891 0H15.4158L20.2448 5.09377H34.5462C36.5893 5.09377 38.2609 6.66108 38.2609 8.81614Z"
      fill="url(#paint0_linear_386_12931)" />
    <g filter="url(#filter0_bi_386_12931)">
      <path
        d="M37.896 29.7794C37.1651 31.3467 35.1553 32.5222 33.5109 32.5222H1.90164C0.439937 32.5222 -0.473625 30.759 0.257224 29.3876L5.92131 18.8082C6.83487 17.4368 8.84471 16.0654 10.4891 16.0654H42.0984C43.5601 16.0654 44.4736 17.8287 43.7428 19.2001L37.896 29.7794Z"
        fill="url(#paint1_linear_386_12931)" />
    </g>
    <defs>
      <filter id="filter0_bi_386_12931" x="-4" y="12.0654" width="52" height="24.457" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_386_12931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_386_12931" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feOffset dx="0.2" dy="0.1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_386_12931" />
      </filter>
      <linearGradient id="paint0_linear_386_12931" x1="0" y1="0" x2="43.8802" y2="7.66007"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor="#517FF5" />
        <stop offset="1" stopColor="#4BA7EA" />
      </linearGradient>
      <linearGradient id="paint1_linear_386_12931" x1="-4.88889" y1="4.27825" x2="31.1667" y2="44.6116"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
