import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme";

export const style = {
  conditions: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
  },
  wrapper: {
    '& .heading': {
      marginBottom: '5rem',
      textAlign: 'center',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '4rem',
        textAlign: 'left',
      }
    }
  },
}
