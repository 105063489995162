export const style = {
  modal: {
    padding: "3.6rem",
    "& .heading": {
      fontWeight: 700
    },
    "& .form": {
      display: "grid",
      gridRowGap: "1.6rem",
      marginTop: "3.2rem"
    }
  },
  description: {
    marginTop: "1.6rem",
    fontWeight: 400,
    lineHeight: "2.17rem",
  },
  textAreaContainer: {
    "& .form-textarea": {
      "& .form-item__field":{
        padding: "1.6rem 2rem",
        borderRadius: "2.4rem"
      }

    }
  },
  textAreaExtra: {marginTop: ".6rem", fontSize: "1.2rem", fontWeight: 400, textAlign: "right"}
}
