import { fluid } from '../../../assets/styles/functions/fluid'

export const style = {
  title: {
    marginBottom: '2.6rem',
    fontWeight: '500',
    fontSize: fluid(1.6, 1.4),
    color: 'var(--clr-text-800)',
  },

  list: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    // maxWidth: '15rem',
  },

  item: {
    '&:not(:last-child)': {
      marginRight: '3.6rem',
    },
    a: {


      svg: {
        // marginRight: '1rem',
        width: '2.4rem',
        height: '2.4rem',
        fill: 'var(--clr-primary-400)',
        objectFit: 'contain',
      }
    }
  }
}
