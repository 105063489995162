import { useSetSeoPage } from "../../../hooks";
import { Hero } from "./hero/hero";
import { Seo } from "./seo/seo";

const Price = () => {
  useSetSeoPage();

  // console.count("Price");
  return (
    <>
      <Hero />
      <Seo />
    </>
  );
};

export default Price;
