import React from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CircleIcon } from "../../../../../assets/images/icons/circle-icon";
import { SeoBlockField } from "../../../../../components/common";

// import { useTranslation } from "../../../../../hooks";
import { style } from "./what-you-get-block.styles";

const WhatYouGetBlock = ({ seoData }) => {
  const { t } = useTranslation();

  return (
    <Box sx={style.container}>
      <Box sx={style.title}>
        <SeoBlockField
          defaultValue={`${t("pages.app.proxyByCountry.whatYouGet.title")}:`}
          field={seoData?.whatYouGetTitle}
        />
      </Box>
      <Box component="ul" sx={style.list}>
        <SeoBlockField
          defaultValue={t("pages.app.proxyByCountry.whatYouGet.content")}
          field={seoData?.whatYouGetList}
        >
          {(data) =>
            data.map((item, id) => (
              <Box key={id} component="li" sx={style.listItem}>
                <Box component="span">
                  <CircleIcon />
                </Box>
                <Box component="span">{item}</Box>
              </Box>
            ))
          }
        </SeoBlockField>
      </Box>
    </Box>
  );
};

export default WhatYouGetBlock;
