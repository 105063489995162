export const style = {
  container: {
    backgroundColor: "#F8F9FD",
    padding: "32px",

    borderTopLeftRadius: "3rem",
    borderTopRightRadius: "3rem"
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    paddingBottom: "24px"
  },
  list: {},
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0",

    fontSize: "16px",
    fontWeight: "400",

    "&:not(:last-child)": {
      borderBottom: "1px solid #E6EBF6",
    },

    "& > span:first-of-type": {
      marginRight: "1.2rem"
    }

  }
};
