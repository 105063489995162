import { useState } from "react";
import { Box } from "@mui/material";

import { useFormContext } from "react-hook-form";

import { EyeClosedIcon, EyeOpenedIcon } from "../../../components/icons/index";
import { styles } from "../form.styles";
export const Input = ({
  type = "text",
  name,
  label,
  labelType = "inner", // inner | outer
  placeholder,
  variant = "filled", // outlined | filled
  direction = "horizontal", // horizontal | vertical
  postWord = "",
  actions,
  onlyNumber,
  onInput,
  value,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const formCtx = useFormContext();

  const handlerToggleViewPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const defineInputType = () => {
    if (type !== "password") return type;

    if (showPassword) return "text";
    if (!showPassword) return "password";
  };

  const handleNumericInput = (e) => {
    if (onInput) onInput(e);

    const newValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 5);

    formCtx.setValue(name, newValue);
  };

  const defineHandler = (e) => {
    if (onlyNumber) return handleNumericInput(e);
    if (!onlyNumber && onInput) return onInput(e);

    // eslint-disable-next-line no-empty-function
    return () => {};
  };

  const defineClasses = () => {
    let classes = "form-item";

    switch (variant) {
      case "outlined":
        classes += " form-item_variant_outlined";
        break;
      case "filled":
        classes += " form-item_variant_filled";
        break;
      case "table":
        classes += " form-item_variant_table";
        break;
      default:
        break;
    }

    switch (direction) {
      case "horizontal":
        classes += " form-item_direction_horizontal";
        break;
      case "vertical":
        classes += " form-item_direction_vertical";
        break;
      default:
        break;
    }

    if (type === "textarea") classes += " form-textarea";
    if (formCtx && formCtx.formState.errors[name]?.message) classes += " form-item_error";

    return classes;
  };

  return (
    <Box sx={styles.formItem} className={defineClasses()}>
      <label className="form-item__inner">
        {label && labelType === "outer" && (
          <span className="form-item__label form-item__label_outer">{label}</span>
        )}
        <div className="form-item__field">
          {label && labelType === "inner" && (
            <span className="form-item__label form-item__label_inner">{label}&nbsp;</span>
          )}

          {!name && type !== "textarea" && (
            <input
              className="form-item__input"
              type={defineInputType()}
              placeholder={placeholder}
              onInput={defineHandler}
              value={value}
              {...rest}
            />
          )}

          {name && formCtx && type !== "textarea" && (
            <input
              {...formCtx.register(name)}
              className="form-item__input"
              type={defineInputType()}
              name={name}
              placeholder={placeholder}
              onInput={defineHandler}
              value={value}
              {...rest}
            />
          )}

          {!name && type === "textarea" && (
            <textarea
              className="form-item__input"
              placeholder={placeholder}
              onInput={defineHandler}
              {...rest}
            />
          )}

          {name && formCtx && type === "textarea" && (
            <textarea
              {...formCtx.register(name)}
              className="form-item__input"
              name={name}
              placeholder={placeholder}
              onInput={defineHandler}
              {...rest}
            />
          )}

          {postWord && (
            <span className="form-item__post-word">
              {direction === "horizontal" && labelType === "inner" && <>{label}&nbsp;</>}
              {value}
              {value?.length > 0 && <span>&nbsp;{postWord}</span>}
            </span>
          )}

          {actions && (
            <div className="form-item__actions">
              {actions}
              {type === "password" && <Box onClick={handlerToggleViewPassword}>
                {showPassword ? <EyeClosedIcon/> : <EyeOpenedIcon/>}
              </Box>}
            </div>
          )}
        </div>
      </label>

      {formCtx && formCtx.formState.errors[name]?.message && (
        <div className="form-item__error">{formCtx.formState.errors[name].message}</div>
      )}
    </Box>
  );
};
