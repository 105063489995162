import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

import { ColoredIcon } from "../../../assets/images/icons/colored";
import { ApiService } from "../../../services";
import { Heading } from "../../ui";
import { Container } from "../container/container";
import { FeatureCard } from "../feature-card/feature-card";

import { style } from "./proxy-features-block.style";

const ProxyFeaturesBlock = (props) => {
  const { title, features, countryName, lang, replaceWithSpace } = props;

  const [processedFeatures, setProcessedFeatures] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    const processFeatures = async () => {
      const processed = await Promise.all(
        features.map(async (feature) => {
          const description = await getReplacedText(feature.description);
          return { ...feature, description };
        })
      );
      setProcessedFeatures(processed);
    };

    processFeatures();
  }, [countryName, lang]);

  const getReplacedText = async (text) => {
    if (!text.includes("#country_gen#") && !text.includes("#country_nom#")) return text;

    if (pathname.includes("isp")) {
      return text.replace("#country_gen#", "ISP").replace("#country_nom#", "ISP");
    }
    try {
      const { data } = await ApiService.getReplacedText({
        text,
        language: lang,
        params: {
          country: countryName
        },
        replaceWithSpace
      });
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return (
    <Box sx={style.wrapper}>
      <Container>
        <Heading tag="h2" variant="h2" center>
          {title}
        </Heading>

        <Box sx={style.list}>
          {processedFeatures.map((feature) => (
            <FeatureCard
              key={feature.title}
              icon={ColoredIcon[feature.icon]}
              title={feature.title}
              description={feature.description}
              button={feature.button}
              ButtonProps={{
                type: "link",
                variant: "outlined"
              }}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default ProxyFeaturesBlock;
