import { useEffect, useRef, useState } from "react";

export const useCollapse = ({ defaultValue, minHeight = 0 } = {}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(defaultValue);

  const collapseRef = useRef(null);

  // Первая загрузка компонента
  // Устанавливаем дефолтные стили и состояние
  useEffect(() => {
    if (!collapseRef.current) return;

    collapseRef.current.classList.add("collapse");

    if (!isCollapseOpen) {
      collapseRef.current.style.height = minHeight ? `${minHeight}px` : "0px";
    } else {
      collapseRef.current.style.height = "auto";
    }
  }, []);

  // Когда переключаем состояние, меняем высоту по окончанию анимации
  useEffect(() => {
    if (!collapseRef.current) return;

    const listener = () => {
      if (isCollapseOpen) {
        // collapseRef.current.style.height = "auto";
      } else {
        collapseRef.current.style.height = minHeight ? `${minHeight}px` : "0px";
      }
    };

    const collapseInstance = collapseRef.current;
    collapseInstance.addEventListener("transitionend", listener);

    return () => collapseInstance.removeEventListener("transitionend", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapseOpen, collapseRef]);

  // Хендлер для переключения состояния
  const handleCollapse = () => {
    if (!collapseRef.current) return;

    const height = collapseRef.current.scrollHeight;

    if (isCollapseOpen) {
      const elementTransition = collapseRef.current.style.transition;
      collapseRef.current.style.transition = "";

      requestAnimationFrame(() => {
        collapseRef.current.style.height = `${height}px`;
        collapseRef.current.style.transition = elementTransition;

        requestAnimationFrame(() => {
          collapseRef.current.style.height = minHeight ? `${minHeight}px` : "0px";
        });
      });

      setIsCollapseOpen(false);
    } else {
      collapseRef.current.style.height = `${height}px`;
      setIsCollapseOpen(true);
    }
  };

  return {
    collapseRef,
    isCollapseOpen,
    handleCollapse
  };
};
