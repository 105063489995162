import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useActions, useLang } from "../../hooks";
import { UserService } from "../../services";
import { getIsAuthenticatedUser } from "../../store/user/user.selectors";

const AuthorizationCheckWithRedirect = () => {
  const lang = useLang();
  const { token } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(getIsAuthenticatedUser);
  const { setUser, filterCountriesInProxy, filterMobileCountries } = useActions();

  const checkAuthorization = async () => {
    UserService.authorizationCheck().then(async (res) => {
      const { auth, countryIpCode } = res.data;
      if (!auth) {
        setUser(null);
      }
      filterCountriesInProxy(countryIpCode);
      filterMobileCountries(countryIpCode);
    });
  };

  useEffect(() => {
    if (pathname.includes("cabinet")) {
      if (!token) {
        checkAuthorization();
        if (!isAuthenticated) {
          // console.log(pathname);
          return navigate(`/${lang}/auth/login/`, { state: pathname });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isAuthenticated, lang]);
};

export default AuthorizationCheckWithRedirect;
