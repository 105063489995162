import { useCallback, useEffect } from "react";

import { debounce } from "../../../../utils/helpers/debounce";

export const useMenuHover = (ref, arrowRef) => {
  const setArrowPosition = useCallback(() => {
    if (ref.current && arrowRef?.current && arrowRef?.current?.offsetParent) {
      const position =
        ref.current.offsetWidth / 2 +
        (Math.abs(arrowRef.current.offsetParent.offsetLeft) -
          parseInt(arrowRef.current.offsetWidth) / 2);

      const compStyle = window.getComputedStyle(arrowRef.current.offsetParent);
      const matrix = new WebKitCSSMatrix(compStyle.transform);

      arrowRef.current.style.left = `${position + Math.abs(matrix.e)}px`;
    }
  }, [ref, arrowRef]);

  useEffect(() => {
    setArrowPosition();

    const debouncedArrowResize = debounce(setArrowPosition, 350);

    window.addEventListener("resize", debouncedArrowResize);

    return () => {
      window.removeEventListener("resize", debouncedArrowResize);
    };
  }, [setArrowPosition]);

  const menuHover = () => {
    if (ref?.current && !ref.current.classList.contains("hovered")) {
      ref.current.classList.add("hovered");
    }
  };

  const menuUnhover = () => {
    if (ref?.current) {
      ref.current.classList.remove("hovered");
    }
  };

  const disableMenuHover = () => {
    if (ref?.current) {
      menuUnhover();
      ref.current.style.pointerEvents = "none";
      const timer = setTimeout(() => {
        ref.current.style.pointerEvents = "";
      }, 600);

      return () => {
        clearTimeout(timer);
      };
    }
  };

  return { menuHover, menuUnhover, disableMenuHover };
};
