import { $api } from "./axios.service";

export class BlogService {
  static getArticlesByPage(values) {
    return $api.post("/api/front/article/all", values);
  }

  static getArticleByLangAndTag({ localeCode, tag }) {
    return $api.get(`/api/front/${localeCode}/article/${tag}`);
  }

  static getArticlesSidebar(values) {
    return $api.get("/api/front/article/sidebar", { params: values });
  }
}
