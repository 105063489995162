import React from "react";

import { Box } from "@mui/material";

import { useSeoBlock } from "../../../hooks";
import { parseSeoBlockField } from "../../../utils/helpers";
import ProxyFeaturesBlock from "../../common/proxy-features-block/proxy-features-block";

export const FeaturesBlock = (props) => {
  const { countryOrTypeName, countryName, lang } = props;

  const { data } = useSeoBlock(["proxyFeaturesBlock"]);

  if (
    !data?.proxyFeaturesBlock?.title ||
    !data?.proxyFeaturesBlock?.features ||
    !data?.proxyFeaturesBlock?.selectedCountries
  ) {
    return null;
  }
  const { type, content } = data?.proxyFeaturesBlock?.selectedCountries;

  const replaceWithSpaceTag = type !== "all" && !content.includes(countryName);
  return (
    <Box id="proxyFeaturesBlock">
      <ProxyFeaturesBlock
        replaceWithSpace={replaceWithSpaceTag}
        countryName={countryName}
        lang={lang}
        title={parseSeoBlockField(data?.proxyFeaturesBlock?.title).replace(
          "{{countryOrType}}",
          countryOrTypeName
        )}
        features={parseSeoBlockField(data?.proxyFeaturesBlock?.features)}
      />
    </Box>
  );
};
