import { ButtonBase, Link } from "@mui/material";
import { useContext } from "react";

import { Link as RouterLink } from "react-router-dom";

import { useLang } from "../../../hooks";
import { TabsContext } from "./tabs";
import { style } from "./tabs.style";

export const TabsItem = ({ tab, linkCallback }) => {
  const { activeTab, handleTab } = useContext(TabsContext);
  const lang = useLang();
  const handleClick = (key) => {
    handleTab(key);
  };

  return (
    <>
      {tab.type !== "link" && (
        <ButtonBase
          key={tab.key}
          // className={tab.key !== activeTab ? "tabs__item" : "tabs__item tabs__item_active"}
          className="tabs__item"
          sx={tab.key !== activeTab ? style.item : [style.item, style.item.activeMod]}
          onClick={() => handleClick(tab.key)}
          aria-label={tab.label}
          title={tab.label}
        >
          <span>{tab.label}</span>
        </ButtonBase>
      )}

      {tab.type === "link" && (
        <Link
          component={RouterLink}
          key={tab.key}
          to={`/${lang}/${tab.to}`}
          className="tabs__item"
          // className={tab.key !== activeTab ? "tabs__item" : "tabs__item tabs__item_active"}
          sx={tab.key !== activeTab ? style.item : [style.item, style.item.activeMod]}
          onClick={linkCallback}
          aria-label={tab.label}
          title={tab.label}
        >
          <span>{tab.label}</span>
        </Link>
      )}
    </>
  );
};
