import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: {},
  groupsOptions: null,
  goalsByGroup: {}
};

const GoalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {
    setGroups(state, action) {
      state.groups = action.payload;

      state.groupsOptions = Object.keys(action.payload).map((goal) => ({
        key: goal,
        translation: goal
      }));

      state.goalsByGroup = Object.keys(action.payload).reduce(
        (acc, group) => ({
          ...acc,
          [group]: action.payload[group].goals.map((goal) => ({
            id: goal.id,
            name: goal.name,
            tag: goal.tag,
            key: goal.tag,
            label: goal.name
          }))
        }),
        {}
      );
    }
  }
});

export const GoalActions = { ...GoalSlice.actions };

export default GoalSlice.reducer;
