import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { MAIN_SOCIALS } from "../../../utils/constants/contacts.constants";
import DynamicIcon from "../dynamic-icon/dynamic-icon";

import { style } from "./socialsList.style";

export const SocialsList = () => {
  // Translations
  const { t } = useTranslation();

  return (
    <Box className="socials-list">
      <Box sx={style.title} className="socials-list__title">
        {t("shared.socials.title")}
      </Box>
      <Box component="ul" sx={style.list} className="socials-list__list">
        {MAIN_SOCIALS.map((social, idx) => (
          <Box
            component="li"
            sx={style.item}
            key={`${idx}:${social.key}`}
            className="socials-list__item"
          >
            <a href={social.link}>
              <DynamicIcon iconName={social.icon} />
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
