export function swapLastTwoProperties(obj) {
  const entries = Object.entries(obj);
  if (entries.length < 2) return obj;

  const lastEntry = entries[entries.length - 1];
  const secondLastEntry = entries[entries.length - 2];

  entries[entries.length - 1] = secondLastEntry;
  entries[entries.length - 2] = lastEntry;

  return Object.fromEntries(entries);
}

export function filterCountriesByIpCode(countries, countryIpCode) {
  if (!Array.isArray(countries)) {
    return [];
  }
  switch (countryIpCode) {
    case "UA":
      return countries.filter((country) => country?.code !== "RUS");

    case "RU":
      return countries.filter((country) => country?.code !== "UKR");

    default:
      return countries;
  }
}
