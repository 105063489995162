import { fluid } from "../../../assets/styles/functions/fluid"

export const style = {
  cabinetButton: {
    '& > button': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '1rem',
      fontFamily: `"SF Pro", sans-serif`,
      fontWeight: '500',
      fontSize: fluid(1.6, 1.4),
      color: 'var(--clr-primary-500)',
      backgroundColor: 'rgba(var(--clr-primary-400-rgb) / 20%)',
      transition: 'background-color var(--transition)',
      textDecoration: 'none',

      [`@media (hover)`]: {
        '&:hover': {
          color: 'var(--clr-text-100)',
          backgroundColor: 'var(--clr-primary-500)',
          transition: 'background-color var(--transition)',

          svg: {
            fill: 'var(--clr-text-100)',
            transition: 'fill var(--transition)',
          }
        }
      },

      svg: {
        marginRight: '0.8rem',
        fill: 'var(--clr-primary-500)',
        transition: 'fill var(--transition)',
      }
    },

    sizeDefault: {
      '& > button': {
        padding: '0 1.8rem',
        minHeight: '4.2rem',

        svg: {
          width: '2.3rem',
          height: '2.3rem',
        }
      }
    },

    sizeSmall: {
      '& > button': {
        padding:' 0 2rem',
        minHeight: '3.8rem',

        svg: {
          width: '1.8rem',
          height: '1.8rem',
        }
      }
    },
  }
}
