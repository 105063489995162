import { useEffect } from "react";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import { Container } from "../../../components/common/container/container";
import { Accordion } from "../../../components/ui/accordion/accordion";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useLang } from "../../../hooks";
import { getFaqState } from "../../../store/selectors";

import { style } from "./faq-block.style";

export const FaqBlock = ({ location, country }) => {
  const { getFaqByParams } = useActions();

  const lang = useLang();
  const {
    faqs: { data: faqsFront }
  } = useSelector(getFaqState);

  useEffect(() => {
    const params = {
      type: "faq",
      location,
      language: lang,
      params: {}
    };
    if (country) {
      params.params.country = country;
    }

    getFaqByParams(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, country]);

  if (Object.values(faqsFront).length === 0) {
    return null;
  }
  const data = faqsFront;

  const { fags } = data;

  return (
    <Box className="faq-block" sx={style.container} itemScope itemType="https://schema.org/FAQPage">
      <Container>
        <Heading variant="h2" tag="div" center={true}>
          {data.namesByLocale[lang]}
        </Heading>

        <Box component="ul" sx={style.list}>
          {fags.map((faq, i) => (
            <Box
              component="li"
              key={`${i}:${faq.question}`}
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
            >
              <Accordion defaultOpen={i === 0} label={faq.question}>
                {faq.answer}
              </Accordion>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};
