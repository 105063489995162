import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "../../services";

export const getPaymentOptions = createAsyncThunk(
  "payment/getPaymentOptions",
  async (params, { rejectWithValue }) => {
    try {
      const res = await ApiService.getPaymentOptions(params);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)
