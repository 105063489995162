import { fluid } from '../../../assets/styles/functions/fluid'
import { appTheme } from "../../../theme";

export const style = {
  offer: {
  paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
  },
  wrapper: {
    '& .heading': {
      marginBottom: '5rem',
      textAlign: 'center',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '4rem',
        textAlign: 'left',
      }
    }
  },
  '.offer__container': {
    '--content-width': '78rem',

    margin: '0 auto',
    padding: '0 var(--container-offset)',
    width: 'min(100%, var(--content-width))',
  }
}
