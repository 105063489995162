import { createSlice } from "@reduxjs/toolkit";

import { filterCountriesByIpCode, swapLastTwoProperties } from "../../utils/tools";

import { getCountriesPricesByProxyType } from "./proxy.thunks";

export const initialProxyState = {
  contentLoaded: false,
  proxies: {},
  proxyOptions: [],
  proxyOptionsWithLink: [],
  proxySelectedType: "IPV4",
  proxyCountrySelected: "",
  proxySelectedTypeCountries: [],
  proxyOrderCount: "",
  proxyMinQuantity: {},
  countriesPrices: {
    loading: false,
    errors: null,
    data: {}
  },
  proxyTypeIds: [] // Array<{id, name}>
};

const ProxySlice = createSlice({
  name: "proxy",
  initialState: initialProxyState,
  reducers: {
    setProxies(state, action) {
      let proxyTypeIds = [];
      let proxies = {};
      let proxyMinQuantity = {};
      let proxyOptions = [];
      let proxyOptionsWithLink = [];

      for (const dataKey in swapLastTwoProperties(action.payload)) {
        const data = action.payload[dataKey];
        const proxyTypeUppercase = dataKey.toUpperCase();
        const proxyTypeLowercase = dataKey.toLowerCase();

        proxyTypeIds = [...proxyTypeIds, { id: data.proxyTypeId, name: data.proxyTypeName }];
        proxies = { ...proxies, [proxyTypeUppercase]: { ...data, type: proxyTypeUppercase } };
        proxyMinQuantity = { ...proxyMinQuantity, [proxyTypeUppercase]: data.minQuantity };
        proxyOptions = [
          ...proxyOptions,
          { key: proxyTypeUppercase, translation: proxyTypeLowercase }
        ];

        const proxyOptionWithLink =
          proxyTypeUppercase === "MOBILE"
            ? {
                key: `${proxyTypeLowercase}-proxy`,
                translation: proxyTypeLowercase,
                type: "link",
                to: "mobile/"
              }
            : { key: proxyTypeLowercase, translation: proxyTypeLowercase };

        proxyOptionsWithLink = [...proxyOptionsWithLink, proxyOptionWithLink];
      }

      state.proxyTypeIds = proxyTypeIds;
      state.proxies = proxies;
      state.proxyMinQuantity = proxyMinQuantity;
      state.proxyOptions = proxyOptions;
      state.proxyOptionsWithLink = proxyOptionsWithLink;

      state.contentLoaded = true;
    },

    filterCountriesInProxy(state, action) {
      const countryIpCode = action.payload;
      const { proxies } = JSON.parse(JSON.stringify(state));
      if (["UA", "RU"].includes(countryIpCode) && Object.entries(proxies).length > 0) {
        Object.keys(proxies).forEach((key) => {
          if (proxies[key].countries) {
            proxies[key].countries = filterCountriesByIpCode(proxies[key].countries, countryIpCode);
          }
        });
        state.proxies = proxies;
      }
    }
  },
  extraReducers: {
    [getCountriesPricesByProxyType.pending]: (state) => {
      state.countriesPrices.loading = true;
    },
    [getCountriesPricesByProxyType.rejected]: (state, action) => {
      state.countriesPrices.loading = false;
      state.countriesPrices.errors = action.payload;
    },
    [getCountriesPricesByProxyType.fulfilled]: (state, action) => {
      state.countriesPrices.loading = false;
      state.countriesPrices.data[action.meta.arg] = action.payload;
    }
  }
});

export const ProxyActions = {
  ...ProxySlice.actions,
  getCountriesPricesByProxyType: getCountriesPricesByProxyType
};

export default ProxySlice.reducer;
