import { useEffect, useState } from "react";

import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ViewIcon } from "../../../../../components/icons/index";
import { Heading } from "../../../../../components/ui/heading/heading";
import { Modal } from "../../../../../components/ui/modal/modal";
import { IpCommentService } from "../../../../../services/ipComment.service";
import { style } from "../tableOptions.style";

const ViewSkeleton = () => (
  <>
    <Skeleton sx={style.viewSkeleton} />
    <Skeleton sx={style.viewSkeleton} />
    <Skeleton sx={style.viewSkeleton} />
    <Skeleton sx={style.viewSkeleton} />
    <Skeleton sx={style.viewSkeleton} />
  </>
);

export const ViewComment = ({ ipId, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState(null);

  // Translations
  const { t } = useTranslation();

  const handleOpen = () => {
    if (isOpen) setLoading(true);
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      IpCommentService.getComment(ipId)
        .then((res) => {
          if (res.data) setComment(res.data);
          else setComment(null);
          setLoading(false);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, ipId]);

  return (
    <>
      <Box
        component="li"
        sx={!disabled ? style.item : [style.item, style.item.disabled]}
        className="table-options__item"
        onClick={handleOpen}
      >
        <ViewIcon />
        {t("shared.table.options.viewComment.label")}
      </Box>
      <Modal customStyles={style.modal} visible={isOpen} cancelHandler={handleOpen}>
        <Box
          sx={[style.comment, style.comment.viewMod]}
          className="table-options__comment table-options__comment_view"
        >
          <Heading tag="div" variant="h4">
            {t("shared.table.options.viewComment.title")}
          </Heading>
          <Box sx={style.comment.caption} className="table-options__comment-caption">
            {t("shared.table.options.viewComment.caption")}
          </Box>
          {!loading ? (
            <Box component="pre" sx={style.comment.text} className="table-options__comment-text">
              {comment}
            </Box>
          ) : (
            <ViewSkeleton />
          )}
        </Box>
      </Modal>
    </>
  );
};
