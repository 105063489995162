import { combineReducers, configureStore } from "@reduxjs/toolkit";

import BlogSlice from "./blog/blog.slice";
import captchaSlice from "./captcha/captcha.slice";
import FaqSlice from "./faq/faq.slice";
import fingerprintSlice from "./fingerprint/fingerprint.slice";
import GoalSlice from "./goal/goal.slice";
import mobileSlice from "./mobile/mobile.slice";
import PaymentSlice from "./payment/payment.slice";
import promocodeSlice from "./promocode/promocode.slice";
import ProxySlice from "./proxy/proxy.slice";
import RentSlice from "./rent/rent.slice";
import ReviewSlice from "./review/review.slice";
import seoBlockSlice from "./seo-block/seo-block.slice";
import seoSlice from "./seo/seo.slice";
import toolsSlice from "./tools/tools.slice";
import UserSlice from "./user/user.slice";

const RootReducer = combineReducers({
  user: UserSlice,
  fingerprint: fingerprintSlice,
  proxy: ProxySlice,
  review: ReviewSlice,
  payment: PaymentSlice,
  rent: RentSlice,
  goal: GoalSlice,
  blog: BlogSlice,
  faq: FaqSlice,
  promocode: promocodeSlice,
  mobile: mobileSlice,
  seo: seoSlice,
  tools: toolsSlice,
  seoBlock: seoBlockSlice,
  captcha: captchaSlice
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: RootReducer,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware({
    //     serializableCheck: {
    //       ignoredPaths: [""]
    //     }
    //   }),
    preloadedState
  });
