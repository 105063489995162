import { Box, InputBase, Slider } from "@mui/material";
// import { Range as RangeBase, getTrackBackground } from "react-range";

// import "./range.scss";
import { style } from "./range.style";

export const Range = (props) => {
  const {
    value = 0,
    label,
    onChange,
    step = 1,
    min = 0,
    max = 50,
    name
  } = props;
  const changeHandler = (fn, value) => {
    let newValue = min;

    if (min <= value <= max) {
      newValue = value.replace(/[^0-9]/g, "").slice(0, max.toString().length);
    }
    if (value < min) newValue = min;
    if (value > max) newValue = max;
    if (value?.length >= 2 && value[0].toString() === "0") {
      newValue = value.substring(1);
    }

    fn(newValue);
  };

  const handleBlur = (fn, value) => {
    if (value !== "") {
      fn(value);
      onChange(value);
      return;
    }

    fn(min);
  };

  return (
    <Box sx={style.formRange} className="form-range">
      {label && (
        <Box sx={style.header} className="form-range__header">
          <Box sx={style.label} className="form-range__label">{label}</Box>
          <InputBase
            name={`range-input-${name}`}
            className="form-range__value"
            sx={style.value}
            value={value}
            placeholder={`${min}`}
            onChange={(e) => changeHandler(onChange, e.target.value)}
            onBlur={(e) => handleBlur(onChange, e.target.value)}
          />
        </Box>
      )}
      <Slider
        aria-label={name}
        name={`range-slider-${name}`}
        step={step || 1}
        min={min}
        max={max}
        value={value ? Number(value) : 0}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        sx={style.range}
      />
    </Box>
  );
};
