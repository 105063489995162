import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Heading } from "../../../components/ui/heading/heading";
import { useSetSeoPage } from "../../../hooks";

import ProfileChangeEmailForm from "./common/_profile-change-email-form";
import ProfileChangePasswordForm from "./common/_profile-change-password-form";
import { style } from "./profile.style";

const Profile = () => {
  useSetSeoPage();

  const { t } = useTranslation();

  return (
    <Box sx={style.cabinetProfile} className="cabinet-profile">
      <Box sx={style.wrapper} className="cabinet-profile__wrapper">
        <Heading tag="h1" variant="h2">
          {t("pages.cabinet.profile.heading")}
        </Heading>
        <Stack gap={5}>
          <ProfileChangeEmailForm />
          <ProfileChangePasswordForm />
        </Stack>
      </Box>
    </Box>
  );
};

export default Profile;
