import { createSlice } from "@reduxjs/toolkit";

import { getReviewsByPage } from "./review.thunks";

const initialState = {
  contentLoaded: false,
  reviewsPreview: {},
  reviewsPreviewLength: 4,
  allReviews: {
    localeCode: null,
    data: [],
    totalElements: 0,
    isLoading: null,
    isFetchError: null
  }
};

const ReviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviews(state, action) {
      state.reviewsPreview = action.payload;
      state.contentLoaded = true;
    },
    removeReviews(state) {
      state.allReviews.data = [];
    }
  },
  extraReducers: {
    [getReviewsByPage.pending]: (state) => {
      state.allReviews.isLoading = true;
    },
    [getReviewsByPage.rejected]: (state, action) => {
      state.allReviews.isFetchError = action.payload;
      state.allReviews.isLoading = false;
    },
    [getReviewsByPage.fulfilled]: (state, action) => {
      state.allReviews.localeCode = action.meta.arg.localeCode;
      state.allReviews.data = [...state.allReviews.data, ...action.payload.content];
      state.allReviews.totalElements = action.payload.totalElements;
      state.allReviews.isLoading = false;
    }
  }
});

export const ReviewActions = { ...ReviewSlice.actions, getReviewsByPage };

export default ReviewSlice.reducer;
