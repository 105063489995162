import { useMemo } from "react";

import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { NOT_INDEXED_PROXY_TYPES } from "../utils/constants/proxy.constants";

export const ProxyCountryOrTypeMode = {
  PROXY: "PROXY",
  COUNTRY: "COUNTRY"
};
const useProxyCountryOrType = (defaultProxyType = "IPV4") => {
  const { proxyCountry } = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const searchCountry = searchParams.get("country");

  const foundProxyType = useMemo(
    () => NOT_INDEXED_PROXY_TYPES.find((type) => pathname.includes(type.toLowerCase())),
    [pathname]
  );

  return {
    mode:
      searchCountry || foundProxyType
        ? ProxyCountryOrTypeMode.PROXY
        : ProxyCountryOrTypeMode.COUNTRY,
    type: searchCountry || foundProxyType ? foundProxyType : defaultProxyType,
    country: searchCountry ?? proxyCountry
  };
};

export default useProxyCountryOrType;

export const getProxyCountryOrTypeForSsr = (searchCountry, proxyCountry, pathname) => {
  const defaultProxyType = "IPV4";
  const foundProxyType = NOT_INDEXED_PROXY_TYPES.find((type) =>
    pathname.includes(type.toLowerCase())
  );

  return {
    mode:
      searchCountry || foundProxyType
        ? ProxyCountryOrTypeMode.PROXY
        : ProxyCountryOrTypeMode.COUNTRY,
    type: searchCountry || foundProxyType ? foundProxyType : defaultProxyType,
    country: searchCountry ?? proxyCountry
  };
};
