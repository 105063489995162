import ExportLayout from "../layouts/export.layout";
import { ROUTE } from "../utils/constants";

export const exportRoutes = (ssr = false) => [
  {
    path: `/:lang?${ROUTE.EXPORT}`,
    tag: "export",
    isSsr: ssr ?? false,
    component: ssr ? ExportLayout : () => import("../layouts/export.layout")
  }
];
