import { createAsyncThunk } from "@reduxjs/toolkit";

import { BlogService } from "../../services";

export const getArticlesByPage = createAsyncThunk(
  "blog/getArticlesByPage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await BlogService.getArticlesByPage(values);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getArticleByLangAndTag = createAsyncThunk(
  "blog/getArticleByLangAndTag",
  async ({ localeCode, tag }, { rejectWithValue }) => {
    try {
      const response = await BlogService.getArticleByLangAndTag({ localeCode, tag });

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      if (response.status === 204)  {
        console.log("no content")
        return rejectWithValue("No content");
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getArticlesSidebar = createAsyncThunk(
  "blog/getArticlesSidebar",
  async (values, { rejectWithValue }) => {
    try {
      const response = await BlogService.getArticlesSidebar(values);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
