export const PAYMENTS = {
  paths: {
    visa: "/images/icons/payments/visa.svg",
    mastercard: "/images/icons/payments/mastercard.svg",
    bitcoin: "/images/icons/payments/bitcoin.svg",
    ethereum: "/images/icons/payments/ethereum.svg",
    tether: "/images/icons/payments/tether.svg",
    paypal: "/images/icons/payments/paypal.svg",
    payeer: "/images/icons/payments/payeer.svg"
  },
  defaultPath: "/images/icons/payments/default.svg"
};

export const PAYMENT_STATUS = {
  SUCCESS: "success",
  CANCEL: "cancel",
}
