import { appTheme } from "../../../../theme";

export const style = {

  container: {
    display: "flex",
    alignItems: "flex-start"
  },

  content: {
    flexBasis: "100%",
    paddingRight: "10rem",
    marginBottom: "1.6rem",

    [appTheme.breakpoints.down("bp992")]: { paddingRight: "0" },

    title: {
      height: "4.2rem",
      marginBottom: "2rem",
      width: "80%",
    },

    infoRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "3rem"
    },

    date: {
      width: "13.2rem",
      height: "3.5rem",
      marginRight: "1.5rem"
    },

    views: {
      width: "2.5rem",
      height: "2rem"
    }
  },

  articleSection: {
    marginBottom: "3rem"
  },

  image: {
    margin: "1.5rem 0 3rem",
    height: "24rem",
    width: "100%"
  },

  heading: {
    height: "2.2rem",
    marginBottom: "1.6rem",
    width: "90%"
  },

  paragraph: {
    "& .string": {
      height: "1.2rem",
      marginTop: "0.8rem",
      "&:nth-of-type(2n)": {
        width: "80%"
      }
    }
  },

  sidebar: {
    width: "min(37rem, 100%)",
    flexShrink: "0",
    [appTheme.breakpoints.down("bp992")]: { display: "none" },
  },

  sidebarEntity: {
    marginBottom: "6rem",
    card: {
      "&:not(:last-child)": {
        marginBottom: "3rem"
      }
    },

    heading: {
      marginBottom: "4rem",
      height: "4rem",
      width: "50%",
      minWidth: "12rem"
    }
  }
}
