import { useMemo } from "react";

import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { useLang } from "../../../../hooks";
import { getCountryFlagIso2 } from "../../../../utils/helpers/get-country-flag";

import { style } from "./geos.style";

export const GeosItem = ({ data, ratingCount, keyProxyType }) => {
  const lang = useLang();
  const { t } = useTranslation();

  const defineClasses = () => {
    const classes = [style.item];
    if (data.latest && !data.withDiscount) classes.push(style.item.tagMod.new);
    if (data.withDiscount && !data.latest) classes.push(style.item.tagMod.discount);
    return classes;
  };

  const getRouteLink = () => {
    const proxyType = keyProxyType.toLowerCase();
    return proxyType === "ipv4"
      ? `/${lang}/${data.urlParam}/`
      : `/${lang}/${proxyType}/?country=${data.urlParam}`;
  };

  // TODO after changes on api, use only priceForOne and remove data.pricesByQuantityV2[0]?.amount;
  const price =
    data?.priceForOne ??
    data.pricesByQuantityV2[0]?.amount ??
    data.pricesByRentPeriods[0]?.rentPeriodDays;

  // get next year for priceValidUntil param;
  const nextYearValue = useMemo(() => {
    const today = new Date();
    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);
    return nextYear.toISOString().split("T")[0];
  }, []);

  // generate random rating (range 4.5 - 4.9);
  const randomRating = useMemo(() => (Math.random() * 0.4 + 4.5).toFixed(1), []);

  const description = `${keyProxyType.toLowerCase()}  ${data?.urlParam.split("-").join(" ")}`;
  return (
    <Box component="li" sx={defineClasses()}>
      <Link
        component={RouterLink}
        to={getRouteLink()}
        itemScope
        itemType="https://schema.org/Product"
      >
        <Box sx={style.item.image}>
          <img
            itemProp="image"
            src={getCountryFlagIso2(data.code)}
            width={70}
            height={70}
            alt={data.namesDependingOnLocale[lang]}
            loading="lazy"
          />
        </Box>
        <Box sx={style.item.info}>
          <Box sx={style.item.name} itemProp="name">
            {data.namesDependingOnLocale[lang]}
          </Box>

          <Box sx={style.item.price}>{`${t("other.from")} $${price}  - 1 ${t("other.piece")}`}</Box>
          <span itemProp="offers" itemScope itemType="https://schema.org/Offer">
            <meta itemProp="price" content={price} />
            <meta itemProp="priceValidUntil" content={nextYearValue} />
            <meta itemProp="priceCurrency" content="USD" />
            <meta itemProp="availability" content="https://schema.org/InStock" />
            <meta itemProp="url" content={`https://youproxy.io${getRouteLink()}`} />
          </span>
          <div itemScope itemType="https://schema.org/AggregateRating" itemProp="aggregateRating">
            <meta itemProp="ratingValue" content={randomRating} />
            <meta itemProp="bestRating" content="5" />
            <meta itemProp="ratingCount" content={ratingCount} />
            <meta itemProp="itemReviewed" content={description} />
          </div>
          <meta itemProp="description" content={description} />
        </Box>
      </Link>
    </Box>
  );
};
