import * as yup from "yup";

export const ProxyCheckSchema = (t) =>
  yup.object().shape({
    proxyList: yup.string()
      .required(t.rules.proxyList.required)
      .test("invalidProxyList", t.rules.proxyList.invalid, (str) => {
        const arr = str.split("\n").filter(Boolean)

        return (arr.every(e =>
          e.match(/^(\S{1,}:\S{1,}@)?((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2}):[0-9]{1,}$/gm)
          || e.match(/^(\S{1,}:\S{1,}@)?(([a-fA-F]|[0-9]){1,4}:){7}([a-fA-F]|[0-9]){1,4}:[0-9]{1,}$/gm)
        ));
      }).test("copiesFount", t.rules.proxyList.copiesFound, (str) => {
        const arr = str.split("\n").filter(Boolean)

        return !arr.filter((item, index) => arr.indexOf(item) !== index).length
      })
  });
