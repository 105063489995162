import { useEffect, useRef, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { DownIcon } from "../../../components/icons/index";

import { useOnClickOutside } from "../../../hooks";

import "./select.scss";
import { styles } from "../form.styles";
// eslint-disable-next-line import/order
import { Box } from "@mui/material";

export const Select = ({
  name,
  label,
  labelType = "inner", // inner | outer
  placeholder,
  variant = "filled", // outlined | filled
  direction = "horizontal", // horizontal | vertical
  withLeftIcon = false,
  options,
  actions,
  onChange,
  disabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const formCtx = useFormContext();

  const value = (name && formCtx) ? formCtx.watch(name) : null;

  const [current, setCurrent] = useState(() => {
    let defaultOption = { key: "", label: "", icon: "" };

    if (name && options) {
      if (formCtx?.formState?.defaultValues && formCtx?.formState?.defaultValues[name]) {
        defaultOption = options.find((e) => e.key === formCtx?.formState?.defaultValues[name]) ?? defaultOption;
      } else if (!placeholder) {
        // eslint-disable-next-line prefer-destructuring
        defaultOption = options[0];
        formCtx?.setValue(name, options[0]?.key);
      }
    }

    return defaultOption;
  });

  useEffect(() => {
    const option = options.find((e) => e.key === value);
    if (option) {
      setCurrent(option);
    }
  }, [options, value]);

  const selectRef = useRef(null);

  useOnClickOutside(selectRef, () => setIsOpen(false));

  const handleOpen = (e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleClose = (e, key) => {
    if (name && formCtx) {
      formCtx.setValue(name, key);
    }

    if (onChange) {
      onChange(key);
    }

    setCurrent({
      ...options.find((option) => option.key === key),
      key
    });
    setIsOpen(false);
  };

  const defineClasses = () => {
    let classes = "form-item form-select";

    switch (variant) {
      case "outlined":
        classes += " form-item_variant_outlined";
        break;
      case "filled":
        classes += " form-item_variant_filled";
        break;
      case "table":
        classes += " form-item_variant_table";
        break;
      default:
        break;
    }

    switch (direction) {
      case "horizontal":
        classes += " form-item_direction_horizontal";
        break;
      case "vertical":
        classes += " form-item_direction_vertical";
        break;
      default:
        break;
    }

    switch (labelType) {
      case "inner":
        classes += " form-item_label_inner";
        break;
      case "outer":
        classes += " form-item_label_outer";
        break;
      default:
        break;
    }

    if (isOpen) classes += " form-select_active";
    if (formCtx && formCtx.formState.errors[name]?.message) classes += " form-item_error";

    return classes;
  };

  return (
    <Box ref={selectRef} className={defineClasses()} sx={styles.formItem} >
      <label className="form-item__inner">
        {label && labelType === "outer" && (
          <span className="form-item__label form-item__label_outer">{label}</span>
        )}
        <div className="form-item__field" onClick={handleOpen} title={current?.label}>
          {label && labelType === "inner" && (
            <span className="form-item__label form-item__label_inner">{label}&nbsp;</span>
          )}

          {withLeftIcon && ((label && labelType !== "inner") || !label) && (
            <div className="form-select__icon">
              <img
                src={current?.icon}
                width={16}
                height={16}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
            </div>
          )}

          {current?.label ? (
            <div className="form-select__current">{current?.label }</div>
          ) : (
            <div className="form-select__placeholder">{placeholder}</div>
          )}

          {!name && <input className="form-item__input" value={current?.key} readOnly />}

          {name && formCtx && (
            <input className="form-item__input" name={name} value={current?.key} readOnly />
          )}

          {actions && <div className="form-item__actions">{actions}</div>}
          <div className="form-select__arrow">
            <DownIcon />
          </div>
        </div>

        <div className="form-select__body">
          <div className="form-select__inner">
            <ul className="form-select__list">
              {options?.map((option, idx) => (
                <li
                  key={`${idx}:${option?.key}`}
                  className={
                    option.key !== current?.key
                      ? "form-select__item"
                      : "form-select__item form-select__item_active"
                  }
                >
                  <button
                    type="button"
                    title={option.label}
                    onClick={(e) => handleClose(e, option.key)}
                    aria-label={option.label}
                  >
                    {withLeftIcon && option.icon && (
                      <div className="form-select__item-icon">
                        <img
                          src={option.icon}
                          width={17}
                          height={17}
                          alt=""
                          aria-hidden={true}
                          loading="lazy"
                        />
                      </div>
                    )}
                    {withLeftIcon && !option.icon && <div className="form-select__item-plug"></div>}
                    <span className="form-select__item-text">{option.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </label>

      {formCtx && formCtx.formState.errors[name]?.message && (
        <div className="form-item__error">{formCtx.formState.errors[name].message}</div>
      )}
    </Box>
  );
};

export const ControlledSelect = (props) => {
  const { name, ...rest } = props;
  const { control } = useFormContext();
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => <Select onChange={onChange} {...rest} />}
      />
    </div>
  );
};
