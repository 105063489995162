import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  promoCard: {
    '--mask-width': '3rem',

    position: 'relative',
    display: 'flex',
    mask: `radial-gradient(var(--mask-width) at 0 100%, #0000 98%, #000) 0 0,
      radial-gradient(var(--mask-width) at 100% 100%, #0000 98%, #000) 100% 0%,
      radial-gradient(var(--mask-width) at 0 0, #0000 98%, #000) 0 100%,
      radial-gradient(var(--mask-width) at 100% 0%, #0000 98%, #000) 100% 100%`,
    maskRepeat: 'no-repeat',
    maskSize: '51% 51%',
    overflow: 'hidden',
    borderRadius: '1.5rem',
    width: 'min(100%, 57rem)',
    minHeight: '19rem',
    color: 'var(--clr-text-100)',
    backgroundColor: 'var(--clr-primary-500)',

    [appTheme.breakpoints.down('bp850')]: {
      width: '100%',
    },

    [appTheme.breakpoints.down('bp576')]: {
      '--mask-width': '2.2rem',
    },
  
    [appTheme.breakpoints.down('bp370')]: {
      mask: 'initial',
    },

    
  },

  left: {
    display: 'flex',
    flex: '1 1 40%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.7rem 1rem 2.2rem 4.5rem',

    [appTheme.breakpoints.down('bp576')]: {
      padding: '1.7rem 2rem 2.2rem 3rem',
    },

    [appTheme.breakpoints.down('bp370')]: {
      padding: '1.7rem 1rem 2.2rem 1.5rem',
    }
  },

  discount: {
    marginBottom: '1rem',
    fontFamily: `"Futura Now", sans-serif`,
    fontWeight: '700',
    fontSize: fluid(4.2, 3.2),
  },

  description: {
    flexGrow: '1',
    marginBottom: '1.7rem',
    maxWidth: '18ch',
    fontFamily: `"SF Pro", sans-serif`,
    fontWeight: '400',
    fontSize: fluid(1.4, 1.2),
    lineHeight: '135%',

    [appTheme.breakpoints.down('bp850')]: {
      maxWidth: 'initial',
    }
  },

  uses: {
    fontFamily: `"SF Pro", sans-serif`,
    fontWeight: '400',
    fontSize: fluid(1.6, 1.4),
  },

  right: {
    display: 'flex',
    flex: '1 1 60%',
    flexDirection: 'column',
    borderTopLeftRadius: '10rem',
    borderBottomLeftRadius: '10rem',
    padding: '1.7rem 4.5rem 2.2rem 4rem',
    maxWidth: '60%',
    textAlign: 'right',
    backgroundColor: 'var(--clr-default-100)',

    [appTheme.breakpoints.down('bp576')]: {
      padding: '2.8rem 3rem 1.6rem 2.5rem',
    },

    [appTheme.breakpoints.down('bp370')]: {
      padding: '2.8rem 1.5rem 1.6rem 2.5rem',
    }
  },

  code: {
    position: 'relative',
    width: '100%',
    textAlign: 'right',

    span: {
      display: 'inline-block',
      overflow: 'hidden',
      paddingRight: '3rem',
      maxWidth: '100%',
      fontFamily: `"Futura Now", sans-serif`,
      fontWeight: '700',
      fontSize: fluid(2.4, 1.8),
      whiteSpace: 'nowrap',
      textAlign: 'right',
      textOverflow: 'ellipsis',
      color: 'var(--clr-primary-500)',

      [appTheme.breakpoints.down('bp576')]: {
        maxWidth: '20ch',
        whiteSpace: 'normal',
      }
    },

    svg: {
      position: 'absolute',
      right: '0',
      top: '50%',
      flexShrink: '0',
      transform: 'translateY(-55%)',
    },

    [appTheme.breakpoints.down('bp576')]: {
      wordBreak: 'break-all',

      svg: {
        marginLeft: '1rem',
      }
    }
  },

  expired: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: '400',
    fontSize: fluid(1.4, 1.2),
    // white-space: nowrap,
    textTransform: 'uppercase',
    color: 'var(--clr-text-300)',
  },

  link: {
    display: 'inline-block',
    marginTop: 'auto',
    fontWeight: '500',
    fontSize: fluid(1.6, 1.4),
    textDecoration: 'underline',
    color: 'var(--clr-primary-500)',

    [appTheme.breakpoints.down('bp576')]: {
      marginLeft: 'auto',
      maxWidth: '18ch',
    }
  }
}