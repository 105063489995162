import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LogoutIcon } from "../../../components/icons/index";
import { useActions, useLang } from "../../../hooks";
import { UserService } from "../../../services";

import { style } from "./sidebar.style";

export const SidebarLogout = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const { setUser } = useActions();

  // Translations
  const { t } = useTranslation();

  const handleLogout = async () => {
    setUser(null);
    await UserService.logout();
    navigate(`/${lang}/`, { replace: true });
  };

  return (
    <Box component="li" sx={style.sidebarItem} className="sidebar__item" onClick={handleLogout}>
      <Box component="span">
        <Box component="span" sx={style.sidebarItem.icon} className="sidebar__item-icon">
          <LogoutIcon />
        </Box>
        {t("other.logout")}
      </Box>
    </Box>
  );
};
