export const style = {

  formControl: {
    padding: '1rem 2rem',
    // border: '0.1rem solid transparent',
    backgroundColor: 'var(--clr-default-200)',
    boxShadow: 'inset 0 0 2rem rgb(197 202 213 / 20%)',
    transition: 'background-color var(--transition)',
    borderRadius: '6rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    maxHeight: '4.8rem',
    minHeight: '4.8rem',
    '& input:-webkit-autofill':  {
      'WebkitBoxShadow': '0 0 0px 1000px var(--clr-default-200) inset !important',
    },
    '.MuiOutlinedInput-notchedOutline': {
      // borderRadius: '2.2rem',
      borderColor: 'transparent !important',
      // display: "none",
      // transition: 'border-color var(--transition)',
      borderWidth: '1px !important',
      transition: '0.3s',
      top: '-6px',
      right: '-1px',
      left: '-1px',
      bottom: '-1px',
      borderRadius: '6rem',
    },
    '&:hover': {
      backgroundColor: '#ebeff8',
      '& input:-webkit-autofill': {
        'WebkitBoxShadow': '0 0 0px 1000px #ebeff8 inset !important',
      }
    },

    focus: {
      backgroundColor: '#ebeff8',
      '& input:-webkit-autofill': {
        'WebkitBoxShadow': '0 0 0px 1000px #ebeff8 inset !important',
      }
    },
  },

  formControlOuter: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    justifyContent: 'flex-start',
    // justifyContent: 'space-between',
    '.MuiOutlinedInput-notchedOutline': {
      // borderRadius: '2.2rem',
      borderColor: 'transparent',
      // transition: 'border-color var(--transition)',
      borderWidth: '1px !important',
      transition: '0s',
      top: '-6px',
      right: '-1px',
      left: '-1px',
      bottom: '-1px',
      // borderRadius: '1.5rem',
    },

    '& input:-webkit-autofill':  {
      'WebkitBoxShadow': '0 0 0px 1000px #fff inset !important',
    },

  },

  labelOuter: {
    fontWeight: '400',
    fontSize: '1.4rem',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginBottom: '0.8rem',
    color: 'var(--clr-text-400)',
  },

  label: {
    color: 'var(--clr-text-900)',
    fontSize: '1.4rem',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginRight: '5px'
  },

  textInput: {
    fontFamily: "inherit",
    position: 'unset',
    // cursor: 'default',
    
    width: '100%',
    input: {
      backgroundColor: 'transparent',
      transition: 'var(--transition)',
      border: 'none',
      color: 'var(--clr-text-900)',
      fontSize: '1.4rem',
      fontWeight: '400',
      outline: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      "WebkitAppearance": "none",
      height: "2rem",
      padding: "0",
      margin: "0",
      // disable autofill styles
      '&:-webkit-autofill:focus': {
        'WebkitBoxShadow': '0 0 0px 1000px var(--clr-default-200) inset',
      },

      "&::placeholder": {
        color: "#75808F",

        opacity: "1"
      }


    },

    '.MuiInputAdornment-root': {
      cursor: 'pointer',
      zIndex: '0',
      div: {
        display: 'grid',
        placeItems: 'center',
      }
    },


    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: 'var(--clr-primary-400)',
    },
    '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--clr-primary-400)',
    },

    '&.Mui-disabled': {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent !important'
      },

      // "&:hover": {
      //   backgroundColor: 'var(--clr-default-200)',

      // }
    },
    '&.Mui-readOnly .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    }
  },

  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color var(--transition)',
      borderColor: '#ff0000 !important',
    },
  },

  errorText: {
    color: '#ff0000',
    fontSize: '1.2rem'
  },

  errorTextAbsolute: {
    color: '#ff0000',
    fontSize: '1.2rem',
    position: 'absolute',
    top: '100%',
    left: '0'
  },

  textInputOuter: {
    boxSizing: "border-box",
    fontFamily: "inherit",
    border: '1px solid var(--clr-ui-border-200)',
    backgroundColor: 'var(--clr-default-100)',
    borderRadius: '6rem',
    width: '100%',
    maxHeight: '4.8rem',
    minHeight: '4.8rem',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    input: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'var(--clr-text-900)',
      fontSize: '1.4rem',
      fontWeight: '400',
      outline: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      padding: '0 14px',
      "WebkitAppearance": "none",
      height: "2rem",
      margin: "0",
      "&::placeholder": {
        color: "#75808F",

        opacity: "1"
      }
    },

    '.MuiInputAdornment-root': {
      cursor: 'pointer',
      zIndex: '0',
      div: {
        display: 'grid',
        placeItems: 'center',
      }
    },

    '.MuiOutlinedInput-notchedOutline': {
      transition: '0.3s border-color'
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      // borderColor: 'transparent',
      borderWidth: '1px',
      borderColor: 'var(--clr-primary-400)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: 'var(--clr-primary-400)',
    },
    '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--clr-primary-400)',
    },

    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    '&.Mui-readOnly .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    }
  },

  startAdornment: {
    zIndex: '0',
    left: '0.6rem',
    position: 'absolute',
    cursor: 'initial !important'
  },

  endAdornment: {
    position: 'absolute',
    right: '10px',
    '.circle-button': {
      width: '24px',
      height: '24px',
      maxHeight: '24px',
      maxWidth: '24px',
      borderRadius: '50%',
      background: 'var(--clr-primary-400)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: "5px",
      svg: {
        width: "100%",
        height: "100%",
        path: {
          stroke: '#fff'
        }
      }
    }
  },

  searchDropdown: {
    position: 'absolute',
    right: '0',
    left: '0',
    bottom: '0',
    transform: 'translateY(calc(100% + 5px))',
    zIndex: '5',
    padding: '10px',
    borderRadius: '1.2rem',
    boxShadow: '0 2px 22px rgb(95 103 128 / 14%)',
    background: '#fff',
    visibility: 'hidden',
    opacity: '0',
    transition: '0.3s',
    overflowX: 'hidden',
    maxHeight: '200px'
  },

  searchDropdownOpen: {
    opacity: '1',
    visibility: 'visible'
  },

  searchItem: {
    alignItems: 'center',
    display: 'flex',
    padding: '1rem 2.1rem',
    textAlign: 'left',
    minHeight: 'unset',
    width: '100%',
    fontSize: '1.4rem',
    color: 'var(--clr-text-400)',
    fontWeight: '400',
    fontFamily: 'inherit',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--clr-default-300-hover)',
    }
  }
}
