import { fluid } from "../../../../assets/styles/functions/fluid";
import { appTheme } from "../../../../theme";

export const style = {
  wrapper: {
    textAlign: "left",
    paddingTop: fluid(14, 8),
    paddingBottom: fluid(7, 4),
    '.heading': {
      marginBottom: '4.6rem',
    }
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",

    [appTheme.breakpoints.down("bp576")]: {
      flexDirection: "column",
      alignItems: "center",
    }
  },
  select: {
    width: "20rem",
    paddingBottom: "3.5rem",
    [appTheme.breakpoints.down("bp767")]: {
      width: "30rem",
    },
    [appTheme.breakpoints.down("bp576")]: {
      width: "100%",
      paddingBottom: "1.5rem",
    }
  }

}
