import { appTheme } from "../../../../theme";

export const style = {
  container: {
    display: 'flex',
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: 'baseline',
    marginBottom: '2.6rem',

    [appTheme.breakpoints.down('bp1200')]: {
      display: "grid",
      gridTemplateColumn: "1fr",
      gridGap: "1rem"
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
    }
  }
}
