import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  toolsMyIp: {},

  description: {
    marginBottom: "4rem",

    "& > p": {
      fontWeight: "400",
      fontSize: "1.6rem",
      lineHeight: "160%",
      color: "var(--clr-text-400)",

      "&:not(:last-child)": {
        marginBottom: "1.5rem"
      }
    },

    [appTheme.breakpoints.down("bp576")]: {
      marginBottom: "3rem",
      "& > p": { fontSize: "1.4rem" }
    }
  },

  copyButton: {
    "& > svg": {
      width: "1.5rem",
      height: "1.5rem",
      marginRight: "0.5rem"
    }
  },

  panel2: {
    container: {
      display: "grid",
      gridTemplateColumns: "9.5fr 2.5fr",
      alignItems: "center",
      marginBottom: "4.7rem",

      [appTheme.breakpoints.down("bp1200")]: {
        gridTemplateColumns: "1fr"
      }
    },
    shortInfo: {
      container: {
        order: 0,
        display: "flex",
        flexDirection: "column",
        marginLeft: "3rem",
        alignItems: "flex-start",
        width: "100%",
        overflow: "hidden",
        wordWrap: "break-word",
        wordBreak: "break-word",

        [appTheme.breakpoints.down("bp1200")]: {
          order: -1,
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "3rem",
          marginLeft: "0"
        },
        [appTheme.breakpoints.down("bp767")]: {
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
          marginBottom: "3rem"
        }
      },
      ipAndCountry: {
        container: {
          display: "flex",
          flexDirection: "column",
          width: "100%",

          [appTheme.breakpoints.down("bp1200")]: {
            flexDirection: "row",
          },
          [appTheme.breakpoints.down("bp767")]: {
            justifyContent: "center",
            flexWrap: "wrap",
          },
          [appTheme.breakpoints.down("bp576")]: {
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }
        },
        ip: {
          container: {
            alignItems: "center",
            fontWeight: 400,
            flexDirection: "row",
            maxWidth: "46rem",
            width: "100%",
            marginBottom: "0.8rem",

            [appTheme.breakpoints.down("bp1200")]: {
              width: "auto",
              marginBottom: "0",
              marginRight: "1rem"
            },

            [appTheme.breakpoints.down("bp576")]: {
              flexDirection: "column",
              width: "100%",
              marginBottom: "0.6rem",
              marginRight: "0",
              "& > span": {
                marginBottom: ".6rem"
              }
            }
          },
          listContainer: {
            fontSize: "2.1rem",
            width: "100%"
          },
          singleIp: {
            [appTheme.breakpoints.down("bp576")]: {
              textAlign: "center"
            }
          },
          textWithEllipsis: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            wordWrap: "normal",
            wordBreak: "normal",

          }
        },
        country: {
          container: {
            display: "flex",
            fontWeight: 400,
            "& > img": { marginRight: "1rem" },

            [appTheme.breakpoints.down("bp1200")]: {
              justifyContent: "center",
            }
          }
        }
      },
      anonymity: {
        container: {
          alignItems: "center",
          fontWeight: 400,
          flexDirection: "row",

          [appTheme.breakpoints.down("bp767")]: {
            marginTop: "1rem"
          },

          [appTheme.breakpoints.down("bp576")]: {
            flexDirection: "column",
            marginTop: "0"
          }
        },
        content: {
          fontSize: "2.1rem",
          fontWeight: 500
        }
      },
      divider: {
        width: "100%",
        height: "0.15rem",
        backgroundColor: "#dfdfec",
        margin: "3rem 0",
        [appTheme.breakpoints.down("bp1200")]: {
          display: "none"
        },

        [appTheme.breakpoints.down("bp576")]: {
          display: "block",
          margin: "1rem"
        }
      }
    }
  },

  seo: {
    // paddingTop: fluid(8, 3)
  }
};
