import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence, domAnimation, LazyMotion } from "framer-motion";

const AnimatedOutlet = () => {
  useLocation();

  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence initial={false} mode="wait">
        <Outlet/>
      </AnimatePresence>
    </LazyMotion>
  );
}

export default AnimatedOutlet;
