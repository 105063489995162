import { lazy, Suspense } from "react";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "../../components/common/container/container";
import { Sidebar } from "../../components/common/sidebar/sidebar";
import Animated from "../../components/special/animated";
import { AuthorizationCheckWithRedirect } from "../../components/special";
import { style } from "./sidebarLayout.style";

const Footer = lazy(() => import("../../components/common/footer/footer"));

const SidebarLayout = (props) => {
  const { routes, withFooter, withExit } = props;
  const location = useLocation();

  // console.count("SidebarLayout");

  return (
    <>
      <AuthorizationCheckWithRedirect />
      <Box sx={style.layout} className="sidebar-layout">
        <Container>
          <Box sx={style.layoputInner} className="sidebar-layout__inner">
            <Suspense fallback={<></>}>
              <Animated
                name={routes.parent}
                sx={{
                  flex: 1,
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center"
              }}
              >
                <Sidebar
                  routes={routes.routes}
                  translation={routes.translation}
                  parentPage={routes.parent}
                  withExit={withExit}
                />
              </Animated>
            </Suspense>
            <Box componet="main" sx={style.layoutContent} className="sidebar-layout__content">
              <Animated name={location.pathname}>
                <Outlet />
              </Animated>
            </Box>
          </Box>
        </Container>
        {withFooter ? (
          <Suspense fallback={<></>}>
            <Footer />
          </Suspense>
        ) : null}
      </Box>
    </>
  );
};

export default SidebarLayout;
