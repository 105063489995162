export const style = {
  // table
  tableStyle: {
    overflow: "hidden",
    border: "0.1rem solid var(--clr-ui-border-200)",
    borderRadius: "3rem",
    width: "100%",
    "& table": {
      overflow: "hidden",
      minWidth: "max-content",
      position: "relative"
    },

    container: {
      overflowX: "auto",
      width: "100%",
      height: "100%",
      backgroundColor: "var(--clr-default-100)"
    }
  },

  tableSize: {
    default: {
      "& .table__head": {
        "& .table__cell": {
          height: "6rem",
          fontSize: "1.4rem"
        }
      },

      "& .table__body": {
        "& .table__cell": {
          height: "5rem"
        }
      }
    },

    large: {
      "& .table__head": {
        "& .table__cell": {
          height: "7rem",
          fontSize: "1.6rem"
        }
      },

      "& .table__body": {
        "& .table__cell": {
          height: "7rem"
        }
      }
    }
  },

  tableLayout: {
    fixed: {},
    auto: {}
  },

  //table cell
  tableCell: {
    fontWeight: "400",
    whiteSpace: "nowrap",
    // textAlign: 'left',
    span: {
      display: "block",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },

    "&:first-of-type": {
      paddingLeft: "4.5rem"
    },

    "&:last-child": {
      paddingRight: "4.5rem"
    },

    padding: {
      default: {
        padding: "0 1.8rem"
      },

      small: {
        padding: "0 1rem"
      },

      checkbox: {
        padding: "0"
      }
    },

    flexStyle: {
      display: "flex"
    },

    align: {
      center: {
        alignItems: "center"
      }
    },

    justify: {
      right: {
        justifyContent: "flex-end"
      }
    },

    textAlign: {
      right: {
        textAlign: "right"
      },

      center: {
        textAlign: "center"
      },

      left: {
        textAlign: "left"
      }
    }
  },

  //table row
  tableRow: {
    transition: "opacity 0.3s",
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },

    action: {
      position: "relative",
      display: "flex",
      flexShrink: "0",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "1rem",
      width: "3.5rem",
      height: "3.5rem",
      transition: "background-color var(--transition)",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
        transition: "background-color var(--transition)",

        "& > svg, & > div > svg": {
          fill: "var(--clr-primary-400)"
        }
      },

      "& > svg, & > div > svg": {
        width: "1.8rem",
        height: "1.8rem",
        fill: "var(--clr-text-400)",
        objectFit: "contain",
        transition: "fill var(--transition)"
      },

      collapse: {
        "& svg": {
          transform: "rotate(90deg)",
          transition: "transform var(--transition)"
        }
      },

      options: {
        position: "relative"
      }
    },

    collapsed: {
      boxSizing: "border-box",
      // transition: 'padding-bottom var(--transition), padding-top var(--transition)',
      inner: {
        display: "inline-flex",
        paddingRight: "2.5rem",
        paddingLeft: "4.5rem",
        paddingTop: "1rem",
        paddingBottom: "1rem"
      },

      grid: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 0fr)",
        gridGap: "1rem"
      },

      action: {
        marginRight: "1.4rem",
        width: "1.8rem",
        height: "1.8rem",

        svg: {
          width: "100%",
          height: "100%",
          objectFit: "contain"
        }
      },

      copy: {
        width: "2.2rem",
        height: "2.2rem",
        padding: "5px",
        margin: "-5px",
        marginLeft: "0px",
        borderRadius: "5px",
        cursor: "pointer",
        display: "grid",
        placeItems: "center",
        transition: "background-color var(--transition)",

        svg: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
          transition: "var(--transition)"
        },

        "&:hover": {
          backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
          transition: "background-color var(--transition)",

          "& > svg": {
            fill: "var(--clr-primary-400)"
          }
        }
      },

      item: {
        display: "flex",
        alignItems: "center",
        "&:not(:last-child)": {
          marginRight: "8rem"
        }
      },

      key: {
        fontWeight: "400",
        fontSize: "1.4rem",
        color: "var(--clr-text-600)"
      },

      val: {
        paddingLeft: "2.7rem",
        paddingRight: ".5rem",
        fontWeight: "500",
        fontSize: "1.4rem",
        color: "var(--clr-text-900)"
      },

      bold: {
        fontSize: "1.4rem",
        fontWeight: "500",
        color: "var(--clr-text-900)"
      },
      switch: {
        margin: "0 0 0.4rem 1rem",
        "& .MuiSwitch-root": {
          padding: "10px 8px"
        },

        "& .MuiSwitch-track": {
          borderRadius: 12,
          border: "1px solid #D9E2F8",
          backgroundColor: "transparent",
          height: 20
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          width: 12,
          height: 12,
          color: "#D9E2F8",
          marginLeft: "0.5rem",
          marginTop: "0.5rem"
        },
        "& .MuiSwitch-switchBase": {
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: "var(--clr-primary-400)"
            }
          }
        }
      }
    },

    even: {
      backgroundColor: "var(--clr-default-200)"
    },

    open: {
      "& .table__row-action_collapse svg": {
        transform: "rotate(0deg)"
      },

      "& + tr > .table__row-collapsed": {}
    },

    blocked: {
      pointerEvents: "none",
      opacity: ".6"
    }
  },

  //body
  tableBody: {
    "& .table__cell": {
      color: "var(--clr-text-900)"
    }
  },

  //head
  tableHead: {
    backgroundColor: "var(--clr-primary-400)",
    height: "60px",
    width: "100%",
    "& .table__cell": {
      color: "var(--clr-text-100)"
    }
  },

  //action
  tableAction: {
    position: "relative",

    "&:not(:last-child)": {
      marginRight: "2.6rem"
    },

    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0.7rem",
      padding: "0.5rem",
      width: "4.4rem",
      height: "4.4rem",
      backgroundColor: "rgba(var(--clr-default-100-rgb), 10%)",
      transition: "background-color var(--transition)",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "rgba(var(--clr-default-100-rgb), 30%)",
        transition: "background-color var(--transition)"
      },

      "& > img": {
        flexShrink: "0",
        width: "2.6rem",
        height: "2.6rem",
        objectFit: "contain"
      }
    },

    body: {
      position: "absolute",
      right: "0",
      top: "calc(100% + 1.3rem)",
      zindex: "1",
      overflow: "hidden",
      borderradius: "1.2rem",
      padding: "2rem 3rem",
      width: "28rem",
      height: "max-content",
      maxheight: "15rem",
      boxshadow: "0px 2px 22px rgba(95, 103, 128, 0.14)",
      whiteSpace: "break-spaces",
      backgoundColor: "var(--clr-default-100)",
      opacity: "0",
      visibility: "hidden",
      transition:
        "opacity var(--transition), visibility var(--transition), transform var(--transition)"
    },

    open: {
      "& .table-action__body": {
        opacity: "1",
        visibility: "visible"
      }
    },

    type: {
      default: {},
      tooltip: {}
    }
  },

  //sort
  tableSort: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& > svg": {
      marginLeft: "1rem",
      width: "1.6rem",
      height: "1.6rem",
      objectFit: "contain"
    },

    "&.disabled": {
      cursor: "not-allowed"
    }
  },

  //link
  tableLink: {
    display: "flex",
    alignItems: "center",

    "& > a": {
      fontWeight: "400",
      fontSize: "1.4rem",
      display: "block",
      width: "100%",
      maxWidth: "115px",
      textDecoration: "underline",
      color: "var(--clr-primary-400)",
      // wordBreak: 'break-all',
      overflow: "hidden",
      textOverflow: "ellipsis"
    },

    icon: {
      marginLeft: "0.8rem",
      objectFit: "contain",
      cursor: "pointer",
      "& svg": {
        width: "1.4rem",
        height: "1.4rem",
        fill: "var(--clr-default-600)"
      }
    }
  },

  //select
  tableSelect: {
    ".MuiFormControl-root .MuiInputBase-root": {
      ".MuiSelect-icon": {
        bottom: "unset"
      },

      "&.Mui-focused": {
        background: "transparent"
      }
    }
  },

  //search
  tableSearch: {},

  // skeleton
  skeletonHead: {
    height: "60px",
    width: "100%",
    background: "var(--clr-primary-400)"
  },

  skeletonItem: {
    height: "50px",
    // background: "red",
    padding: "1.6rem 4.5rem"
  },

  skeletonBlock: {
    "& .skeleton-item:nth-of-type(2n)": {
      background: "var(--clr-default-200)"
      // background: "#000"
    }
  }
};
