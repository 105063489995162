import { useRef } from "react";

import { Box, ButtonBase, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { ArrowIcon } from "../../../../../assets/images/icons/arrow-icon";
import { useLang, useMatchedRoute } from "../../../../../hooks";
import { COMPANY_ROUTES } from "../../../../../utils/constants";
import { getCompanyMenuLink } from "../../../../../utils/helpers";
import { style } from "../../header.style";
import { useMenuHover } from "../header-menu.hooks";

export const Company = () => {
  const lang = useLang();
  const { t } = useTranslation();
  const matchedRoute = useMatchedRoute();

  const menuRef = useRef(null);
  const arrowRef = useRef(null);

  const { menuHover, disableMenuHover } = useMenuHover(menuRef, arrowRef);

  const tags = COMPANY_ROUTES.reduce(
    (acc, el) => [...acc, ...el.routes.map((route) => route.tags).flat()],
    []
  );

  const isActive = tags.includes(matchedRoute?.tag);

  // console.count("Company");

  return (
    <Box
      sx={style.menu.item}
      className={`header__menu-item ${isActive ? "active" : ""}`}
      onMouseOver={menuHover}
      onMouseLeave={disableMenuHover}
      ref={menuRef}
    >
      <ButtonBase
        sx={style.menu.someButton}
        className="header__menu-button"
        aria-label={t("menu.company")}
      >
        {t("menu.company")}
      </ButtonBase>
      <Box sx={[style.dropdown, style.dropdown.company]} className="header__dropdown">
        <Box ref={arrowRef} sx={style.dropdownArrow}>
          <ArrowIcon />
        </Box>
        <Box sx={style.company} className="header__company">
          {COMPANY_ROUTES.map((item, idx) => (
            <Box key={`${idx}:${item.translation}`}>
              <Box sx={style.company.title} className="header__company-title">
                {t(`company.titles.${item.translation}`)}
              </Box>
              <Box sx={style.company.list} className="header__company-list">
                {item.routes.map((route) => (
                  <Box
                    sx={style.company.link}
                    key={route.translation}
                    className={`header__company-link ${
                      route.tags.includes(matchedRoute?.tag) ? "active" : ""
                    }`}
                  >
                    <Link
                      component={RouterLink}
                      to={getCompanyMenuLink(route, lang)}
                      onClick={disableMenuHover}
                    >
                      {t(`company.links.${route.translation}`)}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
