import { startTransition, useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Console, { ConsoleLabel, ConsoleRow } from "../../../components/common/console/console";
import MediaQuery from "../../../components/common/media-query/media-query";
import ShortInfoBlock from "../../../components/common/tools/short-info-block/short-info-block";
import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { ToolsService } from "../../../services";
import { getToolsState } from "../../../store/tools/tools.selectors";
import {
  getCaptchaTokenFromFormData,
  setCaptchaTokenToRequestHeaders
} from "../../../utils/helpers";
import { PingIpSchema } from "../../../utils/validation/ping-ip.validation";

import { PingIpSeo } from "./ping-ip-seo";
import { style } from "./pingIp.style";

const testPingPackageOptions = [
  {
    key: 1,
    label: "1"
  },
  {
    key: 2,
    label: "2"
  },
  {
    key: 3,
    label: "3"
  },
  {
    key: 4,
    label: "4"
  },
  {
    key: 5,
    label: "5"
  },
  {
    key: 6,
    label: "6"
  },
  {
    key: 7,
    label: "7"
  }
];

const PingIp = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();

  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const { clientIpInfo, clientAnonymityPercent } = useSelector(getToolsState);
  const { checkIp, getAnonymityPercent } = useActions();

  const formContext = useForm({
    mode: "onChange",
    resolver: yupResolver(PingIpSchema(t("form"))),
    defaultValues: {
      ipOrDomain: "",
      testPingPackages: 1
    }
  });
  // const [pingId, setPingId] = useState(null);
  const [pingData, setPingData] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [fetchCount, setFetchCount] = useState(0);

  useEffect(() => {
    if (!clientAnonymityPercent.data) {
      getAnonymityPercent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAnonymityPercent.data]);

  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors || clientAnonymityPercent.error) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors, clientAnonymityPercent.error]);

  const getPingIp = (init) =>
    ToolsService.getPingIp()
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject("Error");
        }
        if (typeof response.data !== "object") {
          // eslint-disable-next-line no-undef
          return Promise.resolve(false);
        }

        if (init) {
          if (response.data?.ipOrDomain) {
            formContext.setValue("ipOrDomain", response.data.ipOrDomain);
          }
          if (response.data?.number) {
            formContext.setValue("testPingPackages", response.data.number);
          }
        }

        if (response.data?.pingInfoResponse) {
          setPingData((p) => ({ ...p, pingInfoResponse: response.data?.pingInfoResponse }));
        }

        if (response.data?.pingIpResponseListV2) {
          setPingData((p) => ({ ...p, pingIpResponseList: response.data?.pingIpResponseListV2 }));
        }

        // eslint-disable-next-line no-undef
        return Promise.resolve(response.data?.scanStatus === "CHECKING");
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        // eslint-disable-next-line no-undef
        return Promise.resolve(false);
      });

  useEffect(() => {
    // startTransition(() => setLoadingInitData(true));

    getPingIp(true).then((checking) => {
      startTransition(() => setLoading(checking));
      // startTransition(() => setLoadingInitData(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        getPingIp(false).then((checking) => {
          startTransition(() => setLoading(checking));
          if (!checking) {
            clearInterval(interval);
          }
        });
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handlerSubmit = (data) => {
    if (loading) {
      ToolsService.getPingIpClear();
      setLoading(false);
    } else {
      setPingData(null);
      // setDisabledCancel(true);
      setLoading(true);

      const cfToken = getCaptchaTokenFromFormData(data);

      ToolsService.getPingIp(
        {
          ipOrDomain: data.ipOrDomain,
          number: data.testPingPackages
        },
        {
          headers: setCaptchaTokenToRequestHeaders(cfToken)
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            // eslint-disable-next-line no-undef
            return Promise.reject("Error");
          }

          if (response.data?.pingInfoResponse) {
            setPingData((p) => ({ ...p, pingInfoResponse: response.data?.pingInfoResponse }));
          }

          if (response.data?.pingIpResponseListV2) {
            setPingData((p) => ({ ...p, pingIpResponseList: response.data?.pingIpResponseListV2 }));
          }

          captchaRef?.current?.reset();
        })
        .catch((error) => {
          let message = t("serverError");

          const status = error?.response?.status;
          if (status === 406) {
            message = t("form.rules.ipOrDomain.invalid");
          } else if (status === 409) {
            captchaRef?.current?.reset();
            message = t("messages.captchaFailed");
          }
          enqueueSnackbar(message, { variant: "error" });

          setLoading(false);
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  return (
    <>
      <Box className="tools-ping-ip">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.pingIp.heading")}
          </Heading>

          <ShortInfoBlock
            ip={{
              loading: clientIpInfo.loading,
              v4: clientIpInfo.data?.ip?.ipv4,
              v6: clientIpInfo.data?.ip?.ipv6
            }}
            anonymity={{
              loading: clientAnonymityPercent.loading,
              percent: clientAnonymityPercent.data
            }}
          />
        </ToolsHeader>

        <Box
          sx={style.description}
          className="tools-ping-ip__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.pingIp.description")
          }}
        ></Box>

        <Form
          sx={style.panel}
          className="tools-ping-ip__panel"
          onSubmit={handlerSubmit}
          context={formContext}
        >
          <Box sx={style.panel.info} className="tools-ping-ip__panel-info">
            <Box sx={style.panel.info} className="tools-ping-ip__panel-result">
              <Console>
                <ConsoleLabel>{t("shared.tools.result")}</ConsoleLabel>
                {pingData?.pingInfoResponse && pingData?.pingIpResponseList ? (
                  <>
                    <ConsoleRow>
                      PING {pingData.pingInfoResponse.ip} 56(84) bytes of data
                    </ConsoleRow>

                    {pingData.pingIpResponseList.map((item, key) => (
                      <ConsoleRow key={key}>
                        {`${item.ttl} bytes from ${pingData.pingInfoResponse.ip}: icmp_seq=${item.icmp_seq} ttl=${item.ttl} time=${item.timeMS} ms`}
                      </ConsoleRow>
                    ))}
                    <ConsoleRow>--- {pingData.pingInfoResponse.ip} ping statistics ---</ConsoleRow>
                    <ConsoleRow>
                      {pingData.pingInfoResponse.transmittedPackets} packets transmitted,{" "}
                      {pingData.pingInfoResponse.receivedPackets} received,{" "}
                      {pingData.pingInfoResponse.lossPackets} packet loss, time{" "}
                      {pingData.pingInfoResponse.timeInMS}
                    </ConsoleRow>
                    <ConsoleRow>rtt {pingData.pingInfoResponse.rtt}</ConsoleRow>
                  </>
                ) : null}
              </Console>
            </Box>
            <Box sx={style.panel.note} className="tools-ping-ip__panel-note">
              {t("pages.tools.pingIp.note")}
            </Box>
          </Box>
          <Box sx={style.panel.options} className="tools-ping-ip__panel-options" schema={{}}>
            <Form.Input
              name="ipOrDomain"
              label={t("form.labels.ipOrDomain")}
              direction="vertical"
              readOnly={loading}
            />
            <Form.Select
              name="testPingPackages"
              options={testPingPackageOptions}
              label={t("form.labels.testPingPackages")}
              direction="vertical"
              disabled={loading}
            />

            <Form.Captcha ref={captchaRef} />
            <MediaQuery minWidth="bp576">
              <Button type="submit" variant="primary" fullwidth loading={loading}>
                {loading ? t("common.cancel") : t("form.actions.startScan")}
              </Button>
            </MediaQuery>
          </Box>

          <MediaQuery maxWidth="bp576">
            <Button type="submit" variant="primary" fullwidth>
              {t("form.actions.startScan")}
            </Button>
          </MediaQuery>
        </Form>
        <PingIpSeo />
      </Box>
    </>
  );
};

export default PingIp;
