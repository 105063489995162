export const AUTH_METHODS_OPTIONS = [
  {
    translation: "login",
    key: "login",
    tag: "login"
  },

  {
    translation: "ip",
    key: "ip",
    tag: "ip"
  }
];

export const ERROR_CODES = {
  balance: ["BALANCE_NOT_FOUND", "BALANCE_LOW"],
  promocode: ["PROMO_CODE_ERROR"],
  minQuantityTen: ["Minimal quantity for order for IPv6 is 10"],
  ips: ["ERROR_EXTEND_IP_ADDRESS_IDS"]
}

export const MODAL_ORDER_STATUS = {
  DEFAULT: "default",
  ERROR: "error"
};

export const CALC_TYPES = {
  BASIC: "basic",
  FULL: "full",
  MOBILE_BASIC: "mobileBasic",
};

export const PROMO_CODE_ERROR = {
  // promoCodeNotExist
  PROMO_CODE_NOT_FOUND: "PROMO_CODE_NOT_FOUND",
  PROMO_CODE_RESTRICTION_INVALID: "PROMO_CODE_RESTRICTION_INVALID",
  PROMO_CODE_USER_NOT_REGISTER: "PROMO_CODE_USER_NOT_REGISTER",
  PROMO_CODE_USER_INVALID: "PROMO_CODE_USER_INVALID",
  // promoCodeNotActive
  PROMO_CODE_NOT_ACTIVE: "PROMO_CODE_NOT_ACTIVE",
  PROMO_CODE_USER_ALREADY_USED: "PROMO_CODE_USER_ALREADY_USED",
  // promoCodeInvalid
  PROMO_CODE_NO_LONGER_VALID: "PROMO_CODE_NO_LONGER_VALID",
  // promoCodeConditionInvalid
  PROMO_CODE_CONDITIONS_INVALID: "PROMO_CODE_CONDITIONS_INVALID"
}

