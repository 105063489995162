import { useEffect, useState } from "react";

import { Box, Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { Table } from "../../../components/ui/table/table";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { getToolsState } from "../../../store/tools/tools.selectors";
import { IP_V4_FULL_REG } from "../../../utils/constants";
import { checkWebRtcEnabled, getWebRtcLeakIpList } from "../../../utils/helpers";

import { CheckWebrtcSeo } from "./check-webrtc-seo";
import { style } from "./checkWebrtc.style";

const CheckWebrtc = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();

  const { t } = useTranslation();
  const { clientIpInfo } = useSelector(getToolsState);
  const { checkIp } = useActions();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [webRtcEnabled, setWebRtcEnabled] = useState(false);

  useEffect(() => {
    setWebRtcEnabled(checkWebRtcEnabled());
  }, []);
  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors]);

  useEffect(() => {
    if (loading && webRtcEnabled) {
      const timer = setTimeout(() => {
        getWebRtcLeakIpList()
          .then((list) => {
            setData(
              list.map((ip, id) => ({
                id,
                ip,
                type: ip.trim().match(IP_V4_FULL_REG) ? "IPv4" : "IPv6",
                status: "leakageThreat"
              }))
            );
          })
          .catch((error) => {
            setData([]);
            // eslint-disable-next-line no-console
            console.log(error);
          });

        setLoading(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, webRtcEnabled]);

  useEffect(() => {
    if (webRtcEnabled) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webRtcEnabled]);

  const handlerClick = () => {
    if (loading) {
      setLoading(false);
    } else {
      setLoading(true);
      setData(null);
    }
  };

  return (
    <>
      <Box sx={style.toolsCheckWebrtc} className="tools-check-webrtc">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.checkWebrtc.heading")}
          </Heading>
        </ToolsHeader>

        <Box
          sx={style.description}
          className="tools-check-webrtc__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.checkWebrtc.description")
          }}
        />

        <Box sx={style.info} className="tools-check-webrtc__info">
          <Box sx={style.info.item} className="tools-check-webrtc__info-item">
            <Box sx={style.info.item.title} className="tools-check-webrtc__info-item-title">
              <img
                src="/images/icons/ip-info/location.svg"
                width={18}
                height={18}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
              {t("pages.tools.checkWebrtc.widgets.ip")}
            </Box>
            <Box sx={style.info.item.text} className="tools-check-webrtc__info-item-text">
              {loading ? (
                <Skeleton width={120} />
              ) : (
                <>
                  {clientIpInfo.data?.ip?.ipv4 || clientIpInfo.data?.ip?.ipv6 || t("common.noData")}
                </>
              )}
            </Box>
          </Box>

          <Box sx={style.info.item} className="tools-check-webrtc__info-item">
            <Box sx={style.info.item.title} className="tools-check-webrtc__info-item-title">
              <img
                src="/images/icons/ip-info/location.svg"
                width={18}
                height={18}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
              {t("pages.tools.checkWebrtc.widgets.type")}
            </Box>
            <Box sx={style.info.item.text} className="tools-check-webrtc__info-item-text">
              {loading ? (
                <Skeleton width={120} />
              ) : (
                <>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    clientIpInfo.data?.ip?.ipv4
                      ? "IPv4"
                      : clientIpInfo.data?.ip?.ipv6
                      ? "IPv6"
                      : t("common.noData")
                  }
                </>
              )}
            </Box>
          </Box>

          <Box sx={style.info.item} className="tools-check-webrtc__info-item">
            <Box sx={style.info.item.title} className="tools-check-webrtc__info-item-title">
              <img
                src="/images/icons/ip-info/location.svg"
                width={18}
                height={18}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
              {t("pages.tools.checkWebrtc.widgets.status")}
            </Box>
            <Box sx={style.info.item.text} className="tools-check-webrtc__info-item-text">
              {loading ? (
                <Skeleton width={120} />
              ) : (
                <>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    !data
                      ? t("common.noData")
                      : data.length
                      ? t("common.potentialLeak")
                      : t("common.threatsAreAbsent")
                  }
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Button
          variant="primary"
          loading={loading}
          disabled={!webRtcEnabled}
          onClick={handlerClick}
        >
          {loading ? t("common.cancel") : t("pages.tools.checkWebrtc.restartTest")}
        </Button>

        {webRtcEnabled ? (
          <Box sx={style.widgets} className="tools-check-webrtc__widgets">
            <Box sx={style.warning} className="tools-check-webrtc__warning">
              <Box sx={style.warning.icon} className="tools-check-webrtc__warning-icon">
                <img
                  src="/images/ui/warning.svg"
                  width={32}
                  height={30}
                  alt="warning"
                  loading="lazy"
                />
              </Box>
              <Box
                sx={style.warning.text}
                className="tools-check-webrtc__warning-text"
                dangerouslySetInnerHTML={{ __html: t("shared.tools.warning") }}
              />
            </Box>
            <Box
              sx={{
                height: "fit-content"
              }}
            >
              <Table loading={loading} elementsOnPage={1}>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>{t("pages.tools.checkWebrtc.table.ip")}</Table.Cell>
                    <Table.Cell>{t("pages.tools.checkWebrtc.table.type")}</Table.Cell>
                    <Table.Cell>{t("pages.tools.checkWebrtc.table.status")}</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {!data?.length ? (
                    <Table.Row>
                      <Table.Cell>{t("common.noData")}</Table.Cell>
                    </Table.Row>
                  ) : (
                    data?.map((row) => (
                      <Table.Row key={row.id} index={row.id}>
                        <Table.Cell>{row.ip}</Table.Cell>
                        <Table.Cell>{row.type}</Table.Cell>
                        <Table.Cell>{t(`common.${row.status}`)}</Table.Cell>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table>
            </Box>
          </Box>
        ) : null}
        <CheckWebrtcSeo />
      </Box>
    </>
  );
};

export default CheckWebrtc;
