import { createSlice } from "@reduxjs/toolkit";



import { initialProxyState } from "../../store/proxy/proxy.slice";


const initialState = {
  contentLoaded: false,
  periods: {},
  periodsOptions: []
};

const RentSlice = createSlice({
  name: "rent",
  initialState,
  reducers: {
    setPeriods(state, action) {
      const newRentObj = {};

      for (const proxyType in action.payload) {
        newRentObj[proxyType.toUpperCase()] = action.payload[proxyType].map((element) => ({
          ...element,
          proxyType: element.proxyTypeName.toUpperCase()
        }));
      }

      state.periods = newRentObj;
      state.periodsOptions = newRentObj[initialProxyState.proxySelectedType.toUpperCase()];
      state.contentLoaded = true;
    },

    changePeriodsByProxyType(state, action) {
      state.periodsOptions = state.periods[action.payload.type];
    }
  }
});

export const RentActions = { ...RentSlice.actions };

export default RentSlice.reducer;
