import { appTheme } from "../../theme"
import { fluid } from "../../assets/styles/functions/fluid"

export const style = {
  layout: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: '100%',
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238, 246, 255, 0.06) 3.11%,
      rgba(81, 127, 245, 0.105) 100.73%
    )`,
  },

  layoputInner: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(4, 3),

    [appTheme.breakpoints.down('bp992')]: {
      flexDirection: 'column',
      alignItems: 'center',

      '.sidebar-dropdown': {
        marginBottom: '3rem',
      }
    }
  },

  layoutContent: {
    paddingLeft: '5rem',
    width: 'calc(100% - 28rem)',
    alignSelf: "stretch",

    [appTheme.breakpoints.down('bp1200')]: {
      paddingLeft: '3.5rem',
    },

    [appTheme.breakpoints.down('bp992')]: {
      paddingLeft: '0',
      width: '100%',
    }
  }
}
