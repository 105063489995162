import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getIsAuthenticatedUser } from "../../../store/selectors";
import { Button } from "../../ui";

import { style } from "./feature-card.style";

export const FeatureCard = (props) => {
  const isAuthenticated = useSelector(getIsAuthenticatedUser);
  const { t } = useTranslation();

  const { icon, title, description, button, ButtonProps, customStyles = {} } = props;

  const [newBtnText, setNewBtnText] = useState(button?.text || "");

  useEffect(() => {
    if (button) {
      setNewBtnText(
        button.link.includes("register") && isAuthenticated
          ? t("header.cabinet")
          : button.text || ""
      );
    } else {
      setNewBtnText("");
    }
  }, [isAuthenticated, button, t]);

  return (
    <Box sx={[style.wrapper, customStyles]}>
      <Box>
        <Box sx={style.head}>
          <Box component={icon} />
          <Box>{title}</Box>
        </Box>
        <Box sx={style.description}>{description}</Box>
      </Box>
      <Box sx={style.footer}>
        {button ? (
          <Button to={button.link} size="medium" {...ButtonProps}>
            {newBtnText}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};
