import { Box, Skeleton } from "@mui/material";

import { style } from "./faq-skeleton.style";

export const FaqSkeleton = ({ isCabinet = false }) => (
  <Box sx={!isCabinet ? style.skeleton : style.cabinetSkeleton}>
    <Box className="skeletonTabs">
      <Skeleton variant="rounded" className="skeletonTabs__item" />
    </Box>

    <Box className="skeletonContent">
      {Array.from(Array(15).keys()).map((el) =>
        el === 0 ? (
          <Box key={el} sx={style.faqEntity}>
            <Skeleton sx={style.faqEntity.question} variant="rounded" />
            <Box sx={style.faqEntity.answer}>
              <Skeleton variant="rounded" className="string" />
              <Skeleton variant="rounded" className="string" />
            </Box>
          </Box>
        ) : (
          <Box key={el} sx={style.faqEntity}>
            <Skeleton sx={style.faqEntity.question} variant="rounded" />
          </Box>
        )
      )}
    </Box>
  </Box>
);
