import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProductMetaDate from "../../../../components/common/product-meta-date/product-meta-date";
// import { useTranslation } from "../../../../hooks";
import useCurrentSeo from "../../../../hooks/use-current-seo";
import { getProxyState } from "../../../../store/proxy/proxy.selectors";

const CountryProductMeta = (props) => {
  const { proxyType, countryName, countrySlug, locale } = props;

  const { t } = useTranslation();

  const seo = useCurrentSeo();

  const name = seo["h1"] ? seo["h1"] : `${t("pages.app.buyProxy.hero.heading")} ${countryName}`;
  const description = seo["h2Text"]
    ? seo["h2Text"]
    : t("pages.app.buyProxy.hero.description").replace("{{countryName}}", countryName);

  const { proxies } = useSelector(getProxyState);

  const country = proxies[proxyType]?.countries?.find(
    (country) => country.urlParam === countrySlug
  );

  return (
    <>
      <ProductMetaDate
        name={name}
        description={description}
        url={`/${locale}/${countrySlug}/`}
        price={country?.priceForOne}
        // currency={country?.priceCurrencyCode}
      />
    </>
  );
};

export default CountryProductMeta;
