import React from "react";

import { Box } from "@mui/material";

import ProxyFeaturesBlock from "../../../../components/common/proxy-features-block/proxy-features-block";
import { useSeoBlock } from "../../../../hooks";
import { parseSeoBlockField } from "../../../../utils/helpers";

export const FeaturesBlock = (props) => {
  const { countryName, lang } = props;

  const { data } = useSeoBlock(["proxyMobileProxyFeaturesBlock"]);

  if (
    !data?.proxyMobileProxyFeaturesBlock ||
    !data?.proxyMobileProxyFeaturesBlock?.selectedCountries
  ) {
    return null;
  }
  const { type, content } = data?.proxyMobileProxyFeaturesBlock?.selectedCountries;

  const replaceWithSpaceTag = type !== "all" && !content.includes(countryName);
  return (
    <Box id="proxyMobileProxyFeaturesBlock">
      <ProxyFeaturesBlock
        replaceWithSpace={replaceWithSpaceTag}
        countryName={countryName}
        lang={lang}
        title={parseSeoBlockField(data?.proxyMobileProxyFeaturesBlock?.title)}
        features={parseSeoBlockField(data?.proxyMobileProxyFeaturesBlock?.features)}
      />
    </Box>
  );
};
