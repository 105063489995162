export const formatDateTimeParts = (
  date,
  options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  }
) => {
  return new Intl.DateTimeFormat("en", options).formatToParts(date).reduce((acc, part) => {
    acc[part.type] = part.value;
    return acc;
  }, {});
};

export const formatDateTime = ({ year, month, day, hour, minute }) => {
  return `${year}-${month}-${day} ${hour}:${minute}`;
};

export function formatCustomDate(dateString) {
  const parts = dateString.split("T");
  const dateParts = parts[0].split("-");
  const timeParts = parts[1].split(":");

  return {
    month: dateParts[1],
    literal: ":",
    day: dateParts[2],
    year: dateParts[0],
    hour: timeParts[0],
    minute: timeParts[1]
  };
}
