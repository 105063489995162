import * as yup from "yup";
import "./methods";


export const FeedbackSchema = (t) =>
  yup.object().shape({
    name: yup.string().required(t.name.required),
    email: yup.string().required(t.email.required).email(t.email.invalid),
    text: yup.string().trim().required(t.text.required)
  });


export const FeedbackEnterpriceSchema = (t) =>
  yup.object().shape({
    name: yup.string()
      .trim()
      .notUrl(t.rules.common.hasUrl),
    emailOrOther: yup.string()
      .nullable()
      .oneOf(["email", "other"], t.rules.common.required),
    email: yup.string()
      .email(t.rules.email.invalid)
      .when("emailOrOther", {
        is: "email",
        then: (schema) => schema.required(t.rules.email.required)
      }),
    other: yup.string()
      .trim()
      .notUrl(t.rules.common.hasUrl)
      .when("emailOrOther", {
        is: "other",
        then: (schema) => schema.required(t.rules.common.required)
      }),
    companyName: yup.string()
      .trim()
      .notUrl(t.rules.common.hasUrl),
    message: yup.string()
      .trim()
      .notUrl(t.rules.common.hasUrl)
      .required(t.rules.common.required)
      .min(20, t.rules.common.min.replace("{{min}}", 20))
      .max(500, t.rules.common.max.replace("{{max}}", 500))
  });
