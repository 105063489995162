import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Logo from "../../../components/ui/logo/logo";

import { CabinetButton } from "../cabinet-button/cabinet-button";
import { Container } from "../container/container";
import Languages from "../languages/languages";

import { getIsToolsActive } from "../../../store/tools/tools.selectors";

import HeaderMenu from "./header-menu/header-menu";
import { style } from "./header.style";
import { HeaderTools } from "./header-tools";

const Header = () => {
  const isToolsActive = useSelector(getIsToolsActive);

  // console.count("Header");

  return (
    <Box component="header" sx={style.header} className="header locked">
      <Container>
        <Box sx={style.wrapper} className="header__wrapper">
          <Logo />
          <HeaderMenu />
          <Box sx={style.right} className="header__right">
            {isToolsActive ? (<HeaderTools />) : null}
            <CabinetButton />
            <Languages id={"languages-header"}/>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
