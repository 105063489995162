import { shallowEqual, useSelector } from "react-redux";

import {
  getSeoContentLoadedState,
  getSeoDataState,
  getSeoDefaultDataState,
  getSeoPageParamsState
} from "../store/seo/seo.selectors";
import { DEFAULT_SEO } from "../utils/constants";

const useCurrentSeo = () => {
  const contentLoaded = useSelector(getSeoContentLoadedState);
  const pageParams = useSelector(getSeoPageParamsState, shallowEqual);
  const defaultData = useSelector(getSeoDefaultDataState);
  const data = useSelector(getSeoDataState);

  if (
    contentLoaded &&
    Object.keys(pageParams).length &&
    data[pageParams.languageCode] &&
    data[pageParams.languageCode][pageParams.tag]
  ) {
    const page = data[pageParams.languageCode][pageParams.tag];

    return {
      title: page.title || defaultData.title[pageParams.languageCode],
      description: page.description || defaultData.description[pageParams.languageCode],
      h1: page.h1 || defaultData.h1,
      h1Text: page.h1Text || defaultData.h1Text,
      h2: page.h2 || defaultData.h2,
      h2Text: page.h2Text || defaultData.h2Text,
      seo: page.seo || defaultData.seo
    };
  } else {
    return {
      ...defaultData,
      title: defaultData.title[pageParams.languageCode] || DEFAULT_SEO.title,
      description: defaultData.description[pageParams.languageCode] || DEFAULT_SEO.description
    };
  }
};

export default useCurrentSeo;
