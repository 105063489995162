import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {

  widgets: {
    marginBottom: '5rem',

    text: {
      marginBottom: '3rem',
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '2rem',
        fontSize: "1.4rem"
      }
    },

    list: {
      display: 'grid',
      gridGap: '2.8rem',
      gridTemplateColumns: 'repeat(auto-fit, minmax(25rem, 1fr))',
      marginBottom: '2rem',

      [appTheme.breakpoints.down('bp992')]: {
        gridTemplateColumns: 'repeat(auto-fit, minmax(27rem, 1fr))',
      },

      [appTheme.breakpoints.down('bp767')]: {
        gridGap: '2rem',
      }
    },

    item: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3rem',
      padding: '2.2rem 2.4rem',
      boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
      backgroundColor: 'var(--clr-default-100)',

      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '1.4rem',
        width: '5.4rem',
        height: '5.4rem',
        backgroundColor: 'var(--clr-default-400)',

        img: {
          width: '2.4rem',
          height: '2.4rem',
          objectFit: 'contain',
        }
      },

      content: {
        paddingLeft: '1.4rem',
      },

      caption: {
        marginBottom: '0.5rem',
        fontWeight: '400',
        fontSize: '1.4rem',
        color: 'var(--clr-text-400)',
      },

      value: {
        fontWeight: '500',
        fontSize: fluid(1.6, 1.4),
        color: 'var(--clr-text-600)',
      }
    },

    '.button': {
      maxWidth: '25rem',

      [appTheme.breakpoints.down('bp576')]: {
        maxWidth: '100%',
      }
    }
  },

  result: {
    '& > .heading': {
      marginBottom: '2.6rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      }
    }
  },

  seo: {
    paddingTop: fluid(8, 3)
  }
}
