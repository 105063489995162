import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  description: {
    marginBottom: '3.5rem',

    '& > p': {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
      '& > p': {
        fontSize: "1.4rem"
      }
    }
  },

  panel: {
    container: {
      display: 'flex',
      borderRadius: '3.5rem',
      padding: '1.6rem 2rem 4rem',
      boxShadow: '0px 14px 30px rgba(165, 178, 208, 0.22)',
      backgroundColor: 'var(--clr-default-100)',

      [appTheme.breakpoints.down('bp576')]: {
        flexDirection: 'column',
        padding: '2rem',

        '& > .button': {
          order: '2',
          marginTop: '2rem',
        }
      }
    },

    info: {
      display: 'flex',
      flexGrow: '1',
      flexBasis: '60%',
      flexDirection: 'column',

      [appTheme.breakpoints.down('bp992')]: {
        flexBasis: '50%',
      },

      [appTheme.breakpoints.down('bp576')]: {
        order: '1',
      }
    },

    note: {
      marginTop: 'auto',
      paddingTop: '2.3rem',
      fontWeight: '400',
      fontSize: '1.4rem',
      color: 'var(--clr-text-400)',
    },

    options: {
      flexGrow: '1',
      flexBasis: '40%',
      paddingLeft: '3.6rem',

      '.form-item': {
        marginBottom: '2.5rem',

        '&__field': {
          boxShadow: 'none',
          backgroundColor: 'var(--clr-default-400)',
        },

        [appTheme.breakpoints.down('bp576')]: {
          marginBottom: '2rem',
        }
      },

      label: {
        marginBottom: '1.2rem',
        fontWeight: '400',
        fontSize: fluid(1.8, 1.6),
        color: 'var(--clr-text-500)',
      },

      '.form-radio-group': {
        marginBottom: '3.8rem',
      },

      [appTheme.breakpoints.down('bp922')]: {
        flexBasis: '50%',
      },

      [appTheme.breakpoints.down('bp576')]: {
        order: '0',
        paddingLeft: '0',

        '.form-radio-group': {
          marginBottom: '2rem',
        }
      }
    },

  },

  seo: {
    paddingTop: fluid(8, 3)
  }

}
