import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Heading } from "../../../components/ui/heading/heading";
import { Table } from "../../../components/ui/table/table";
import { useSetSeoPage } from "../../../hooks";
import { HistoryService } from "../../../services/history.service";
import { getDate } from "../../../utils/helpers";
import { useTableData } from "../../../utils/hooks/useTableData";
import { useTableNavigation } from "../../../utils/hooks/useTableNavigation";

import { style } from "./actionsHistory.style";

const ActionsHistory = () => {
  useSetSeoPage();

  const { t } = useTranslation();

  //custom hooks
  const [pageObj, pageChangeHandler, sizeSelectHandler] = useTableNavigation();
  const [tableData, tableLoading] = useTableData({
    pageObj,
    asyncFunction: HistoryService.getActionsHistory
  });

  const isTableDisabled = !tableData?.content?.length;

  return (
    <Box sx={style.cabinetActionsHistory} className="cabinet-actions-history">
      <Heading tag="h1" variant="h2">
        {t("pages.cabinet.actionsHistory.heading")}
      </Heading>
      <Table
        pagination={!!tableData?.totalElements}
        totalPages={tableData?.totalPages ? tableData?.totalPages - 1 : 0}
        totalElements={tableData?.totalElements || 0}
        elementsOnPage={tableData?.content?.length}
        currentPage={pageObj.page}
        onPageChange={pageChangeHandler}
        pageSize={pageObj.pageSize}
        outputCountHandler={sizeSelectHandler}
        loading={tableLoading}
        head={
          <Table.Head>
            {!isTableDisabled ? (
              <Table.Row>
                <Table.Cell tag="th">
                  {t("pages.cabinet.actionsHistory.table.replacementDate")}
                </Table.Cell>
                <Table.Cell tag="th">
                  {t("pages.cabinet.actionsHistory.table.replacementTime")}
                </Table.Cell>
                <Table.Cell tag="th" width="20rem">
                  IP:
                </Table.Cell>
                <Table.Cell tag="th" textAlign="right" width="22rem">
                  {t("pages.cabinet.actionsHistory.table.replacedWith")}
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Cell></Table.Cell>
            )}
          </Table.Head>
        }
      >
        <Table.Body>
          {tableData && tableData?.content?.length ? (
            tableData.content.map((row) => {
              // eslint-disable-next-line prefer-destructuring
              const time = row.replacementDate.split("T")[1].split(".")[0];
              return (
                <Table.Row key={row.id}>
                  <Table.Cell>{getDate(row.replacementDate)}</Table.Cell>
                  <Table.Cell>{time}</Table.Cell>
                  <Table.Cell width="20rem">{row.oldIp}</Table.Cell>
                  <Table.Cell textAlign="right" width="22rem">
                    {row.newIp}
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row customStyles={style.noData}>
              <Table.Cell>{t("cabinet.actionsHistory.noData")}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default ActionsHistory;
