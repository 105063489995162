import { useTranslation } from "react-i18next";

import "./working-hours.scss";

export const WorkingHours = () => {
  // Translations
  const { t } = useTranslation();

  return (
    <div className="working-hours">
      <div className="working-hours__title">{t("shared.workingHours.title")}</div>
      <div className="working-hours__text">{t("shared.workingHours.aroundTheClock")}</div>
      <div className="working-hours__text">{t("shared.workingHours.withoutWeekends")}</div>
    </div>
  );
};
