import React from "react";
import { styles } from "./menu-list.styles";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

/**
 *
 * @param {Object} props
 * @param {String} props.title
 * @param {Array.<{link: String, icon: undefined | React.ReactNode, content: String | React.ReactNode}>} props.elements
 * @returns {React.ReactNode}
 */
const MenuList = (props) => {
  const { title, elements } = props;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.title} dangerouslySetInnerHTML={{ __html: title}}/>
      <Box component="ul" sx={styles.list}>
        {elements?.map((element) => (
          <Box key={element.content} component="li" sx={styles.item}>
            <Box
              component={Link}
              to={element.link}
              sx={[styles.link, element?.icon ? styles.linkWithIcon : {}]}
            >
              {element?.icon ? (<Box component="span" sx={styles.icon}>{element.icon}</Box>) : null}
              {element.content}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MenuList;
