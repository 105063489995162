import { appTheme } from "../../../theme";

export const style = {
  modalBlock: { maxWidth: "48rem", overflow: "hidden" },
  modalBlockAuthorization: { maxWidth: "58rem", overflow: "hidden" },
  modal: {
    upper: {
      padding: "3.2rem 3.6rem 2rem 3.6rem ",
      background: "#ffffff",
      "& > .heading": {
        marginBottom: "0.7rem",
        fontSize: "2.4rem",
        fontWeight: "700",
        color: "var(--clr-text-900)"
      },

      [appTheme.breakpoints.down("bp460")]: {
        paddingRight: "2rem",
        paddingLeft: "2rem",
        paddingBottom: "2rem"
      }
    },
    bottom: {
      padding: "2.4rem 3.6rem",
      backgroundColor: "var(--clr-default-200)",
      display: "flex",
      gap: "2.4rem",
      [appTheme.breakpoints.down("bp460")]: {
        gap: "0.4rem"
      },
      "& > .button": { flex: "1 1 calc(50% - 1.2rem)" },
      [appTheme.breakpoints.down("bp460")]: {
        padding: "2rem",
        gap: "1.5rem"
      }
    },
    subTitle: {
      marginBottom: "2.4rem",
      fontWeight: "400",
      fontSize: "1.4rem",
      color: "var(--clr-text-400)"
    },
    titleBlock: {
      fontFamily: "Gilroy",
      color: "var(--clr-text-900)",
      fontSize: "1.8rem",
      fontWeight: 600,
      lineHeight: "2.4rem",
      marginBottom: "1.6rem"
    },

    radioGroup: {
      marginLeft: "1.1rem",
      display: "flex",
      alignItems: "center",
      fontSize: "1.6rem",
      fontWeight: 400,
      cursor: "pointer",
      marginBottom: "1rem",
      fontFamily: "Gilroy",
      color: "var(--clr-text-900)",

      "& .radioIcon": {
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        border: "2px solid #D7DBEC",
        marginRight: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      "& .radioIcon.checked": {
        border: "none",
        backgroundColor: "var(--clr-primary-400)"
      },
      "& .radioLabel.checked": {
        fontWeight: 500
      },
      "& .radioIcon.checked .innerCircle": {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#FFFFFF",
        fontWeight: 500
      }
    },
    methodsContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "2.4rem",
      marginBottom: "2rem"
    },

    textAreaContainer: {
      marginTop: "1.2rem",
      "& .form-textarea": {
        "& .form-item__field": {
          padding: "1.6rem 3rem",
          borderRadius: "2.4rem",
          height: "10rem "
        }
      }
    },
    inputContainer: {
      "& .form-item": {
        margin: "1rem 0 1.6rem 0 ",

        "&__field": {
          background: "var(--clr-default-400) !important",
          borderColor: "transparent !important"
        },
        "&  .form-item__inner:focus-within .form-item__field": {
          borderColor: "var(--clr-primary-400) !important"
        }
      }
    },
    inputContainerIps: {
      "& .form-item__input": {
        width: "0 !important"
      },

      "& .form-item__field": {
        "& .form-item__actions": {
          width: "100% !important",
          height: "1rem !important",
          "& .MuiInputBase-root": {
            width: "100% !important",
            justifyContents: "space-between !important",
            alignItems: "center !important"
          }
        }
      }
    },
    errorInput: {
      "& .form-item__field": {
        borderColor: "#ff0000 !important"
      }
    },

    btnAddIPIcon: {
      marginButton: "4.4rem",
      marginRight: "1rem"
    },
    boxAddedIps: {
      marginTop: "2rem"
    }
  }
};
