import { useEffect, useState } from "react";

import { Box, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ArrowSmallRightIcon, LockIcon, ReplaceIcon } from "../../../components/icons";
import { Heading } from "../../../components/ui/heading/heading";
import AuthorizationModal from "../replace-authorization/authorization-modal";
import ReplacementModal from "../replace-authorization/replacement-modal";

import { style } from "./auto-renewal.style.js";
import ModalsAR from "./modalsAR";
import TooltipCustom from "./tooltip";

export default function AutoRenewal({ proxyType, ipAddressesIds, onUpdateParentComponent }) {
  const { t } = useTranslation();
  const { autoRenewal, replacement, authorization } = t("pages.cabinet.orders.widgets");
  const zeroSelectedIp = ipAddressesIds.length === 0;
  const [checked, setChecked] = useState(false);
  const [disabledSwitch, setDisabledSwitch] = useState(zeroSelectedIp);
  const initialModalState = { enable: false, disable: false };
  const [modalType, setModalType] = useState(initialModalState);
  const [modalReplacement, setModalReplacement] = useState(false);
  const [modalAuthorization, setModalAuthorization] = useState(false);
  const [alertDiffIps, setAlertDiffIps] = useState(false);
  const [alertZeroSelectedIp, setAlertZeroSelectedIp] = useState(false);

  useEffect(() => {
    if (ipAddressesIds.length > 0) {
      const firstAutoExtend = ipAddressesIds[0].autoExtend;
      const allMatch = ipAddressesIds.every((ip) => ip.autoExtend === firstAutoExtend);

      if (allMatch) {
        setChecked(firstAutoExtend);
        setDisabledSwitch(false);
        setAlertDiffIps(false);
      } else {
        setDisabledSwitch(true);
        setAlertDiffIps(true);
        setTimeout(() => {
          setAlertDiffIps(false);
        }, 2000);
      }
    } else {
      setDisabledSwitch(true);
      setAlertDiffIps(false);
    }
  }, [ipAddressesIds]);

  const handleChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setModalType({ disable: false, enable: true });
    } else {
      setModalType({ disable: true, enable: false });
    }
    setChecked(checked);
  };

  const handleSwitchClick = (event) => {
    if (disabledSwitch) {
      setAlertDiffIps(true);
      setTimeout(() => {
        setAlertDiffIps(false);
      }, 1500);
      event.preventDefault();
    }
  };

  const closeModal = () => {
    setModalType(initialModalState);
    setModalReplacement(false);
    setModalAuthorization(false);
    setChecked(ipAddressesIds?.[0]?.autoExtend || false);
    setAlertDiffIps(false);
    setAlertZeroSelectedIp(false);
  };

  return (
    <>
      <Heading tag="h4" variant="h4">
        {autoRenewal.title}
      </Heading>
      <Box sx={style.count}>
        {autoRenewal.selectedIp}: <span>{ipAddressesIds.length}</span>
      </Box>

      <FormControlLabel
        sx={style.switch}
        control={<Switch />}
        checked={checked}
        disabled={disabledSwitch}
        onChange={handleChange}
        onClick={handleSwitchClick}
        label={
          ipAddressesIds.length > 0 &&
          autoRenewal[ipAddressesIds[0].autoExtend ? "disable" : "enable"]
        }
      />
      <TooltipCustom opened={alertDiffIps} title={autoRenewal.selectIpsWithSameSettings} />

      {modalType.enable || modalType.disable ? (
        <ModalsAR
          onUpdateParentComponent={onUpdateParentComponent}
          proxyType={proxyType.includes("mobile") ? "mobile" : proxyType}
          ipAddressIds={ipAddressesIds.map(({ id }) => id)}
          modalType={modalType}
          closeModal={closeModal}
        />
      ) : null}

      <Box
        component="button"
        sx={{
          ...style.linkToReplAuth,
          opacity: zeroSelectedIp ? 0.6 : 1,
          cursor: zeroSelectedIp ? "default" : "pointer"
        }}
        onClick={() => {
          if (zeroSelectedIp) {
            setAlertZeroSelectedIp(true);
            setTimeout(() => {
              setAlertZeroSelectedIp(false);
            }, 1800);
          } else {
            setModalReplacement(true);
          }
        }}
      >
        <ReplaceIcon /> {replacement.noun} <ArrowSmallRightIcon />
      </Box>
      <TooltipCustom opened={alertZeroSelectedIp} title={t(replacement.selectIp)} />
      <Box component="button" sx={style.linkToReplAuth} onClick={() => setModalAuthorization(true)}>
        <LockIcon /> {authorization.noun} <ArrowSmallRightIcon />
      </Box>

      {modalReplacement && (
        <ReplacementModal
          closeModal={closeModal}
          openedModal={modalReplacement}
          proxyType={proxyType}
          ipAddressIds={ipAddressesIds.map(({ id }) => id)}
        />
      )}
      {modalAuthorization && (
        <AuthorizationModal closeModal={closeModal} openedModal={modalAuthorization} />
      )}
    </>
  );
}
