import React from "react";
import { Box, CircularProgress } from "@mui/material";

const ShortInfoElement = (props) => {
  const {
    loading,
    imageUrl,
    label,
    children,
    ...rest
  } = props;
  return (
    <Box
      {...rest}
      sx={{
        display: "inline-flex",
        fontSize: "1.4rem",
        flexWrap: "wrap",
        fontWeight: 500,
        minWidth: "16.2rem",
        ...rest.sx
      }}
    >
      <Box
        component="span"
        sx={{
          display: "inline-flex",
          alignItems: "flex-end",
          marginRight: ".4rem"
        }}
      >
        <Box
          component="img"
          src={imageUrl}
          alt="privacy"
          loading="lazy"
          sx={{
            width: 18,
            height: 18,
            marginRight: ".8rem"
          }}
        />
        <Box component="span">{label}</Box>
      </Box>
      {loading ? <CircularProgress color="inherit" size={20} sx={{ display: "flex" }} /> : children}
    </Box>
  );
};

export default ShortInfoElement;
