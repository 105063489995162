
export const SkypeIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_249_10713)">
      <path d="M17.4818 10.91C18.7853 5.05254 13.6537 -0.268708 7.6275 0.752792C4.356 -1.27746 0 1.01679 0 4.90929C0 5.81454 0.24975 6.66204 0.684 7.39254C-0.52725 13.2635 4.64625 18.5083 10.6672 17.417C14.9235 19.6678 19.6267 15.179 17.4818 10.91V10.91ZM11.7532 14.4425C10.1722 15.0928 7.5705 15.0965 5.99625 14.2723C3.7515 13.0753 3.3525 10.3805 5.289 10.3805C6.7485 10.3805 6.2865 12.0823 7.76025 12.776C8.43675 13.0888 9.891 13.121 10.7362 12.551C11.571 11.9923 11.4945 11.1133 11.0407 10.6933C9.8385 9.58329 6.39825 10.0243 4.8255 8.08104C4.14225 7.23804 4.01325 5.75079 4.85325 4.67229C6.31725 2.78829 10.6133 2.67354 12.4605 4.09629C14.166 5.41629 13.7055 7.16829 12.3292 7.16829C10.674 7.16829 11.544 5.00229 8.87175 5.00229C6.9345 5.00229 6.1725 6.38004 7.53675 7.05054C9.38625 7.96929 14.0992 7.66254 14.0992 11.2528C14.0955 12.7468 13.1797 13.8605 11.7532 14.4425V14.4425Z" />
    </g>
    <defs>
    <clipPath id="clip0_249_10713">
      <rect width="18" height="18" />
    </clipPath>
    </defs>
  </svg>
);
