import { createAsyncThunk } from "@reduxjs/toolkit";

import { PromocodeService } from "../../services/promocode.service";

export const getPromocodesByPage = createAsyncThunk(
  "promocode/getPromocodesByPage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await PromocodeService.getPromocodesByPage(values);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
