import { appTheme } from "../../../theme"
import { fluid } from "../../../assets/styles/functions/fluid"

export const style = {
  message: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5rem 4.5rem 4.5rem',
    textAlign: 'center',
    backgroundColor: 'var(--clr-default-100)',
    borderRadius: "3rem"
  },

  feedbackMessage: {
    maxWidth: "370px",
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4.5rem',
    textAlign: 'center',
    backgroundColor: 'var(--clr-default-100)',
    borderRadius: '3.5rem',
    [appTheme.breakpoints.down('bp576')]: {
      padding: '2rem'
    }
  },

  icon: {
    marginBottom: '1.5rem',
    width: '5rem',
    height: '5rem',

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    }
  },

  feedbackIcon: {
    marginBottom: '1.5rem',
    width: '5.8rem',
    height: '5.8rem',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    }
  },

  title: {
    marginBottom: '1.2rem',
    fontWeight: '700',
    fontSize: fluid(2.4, 2),
    color: 'var(--clr-text-600)',
  },

  description: {
    marginBottom: '2.3rem',
    fontWeight: '400',
    fontWize: fluid(1.6, 1.4),
    lineHeight: '125%',
    color: 'var(--clr-text-400)',
  },

  action: {
    fontWeight: '500',
    fontSize: fluid(1.6, 1.4),
    color: 'var(--clr-text-900)',
  },
}