import React, { useEffect, useRef, useState } from "react";

import { Box, InputBase } from "@mui/material";
import InputMask from "react-input-mask";

import { style } from "./ip-input-mask.styles";

const checkIpValue = (value) => {
  const subIps = value.split(".");
  if (subIps.length > 4) {
    return false;
  }

  const invalidSubIps = subIps.filter((ip) => {
    const item = parseInt(ip);
    return item < 0 || item > 255;
  });

  if (invalidSubIps.length !== 0) {
    return false;
  }
  let emptyIpCount = 0;
  subIps.forEach((ip) => {
    if (ip === "") {
      emptyIpCount++;
    }
  });
  if (emptyIpCount > 1) {
    return false;
  }
  return true;
};
const beforeMaskedValueChange = (newState, oldState) => {
  const oldValue = oldState.value;
  let { value } = newState;
  let { selection } = newState;
  let cursorPosition = selection ? selection.start : null;
  const result = checkIpValue(value);

  if (!result) {
    value = value.trim();
    // try to add . before the last char to see if it is valid ip address
    const newValue = `${value.substring(0, value.length - 1)}.${value.substring(value.length - 1)}`;

    if (checkIpValue(newValue)) {
      cursorPosition++;
      selection = { start: cursorPosition, end: cursorPosition };
      value = newValue;
    } else {
      value = oldValue;
    }
  }

  return {
    value,
    selection
  };
};
const IpInputMask = (props) => {
  const { useFocus, sx = {}, ...rest } = props;

  const ref = useRef(null);

  const [focus, setFocus] = useFocus();

  useEffect(() => {
    if (focus && ref?.current) {
      ref?.current?.getInputDOMNode()?.focus();
    }
  }, [ref, focus]);

  return (
    <InputMask
      ref={ref}
      formatChars={{
        9: "[0-9.]"
      }}
      placeholder="___-___-___-___"
      mask="999999999999999"
      maskChar={null}
      alwaysShowMask={false}
      beforeMaskedValueChange={beforeMaskedValueChange}
      onBlur={() => setFocus(false)}
      sx={[style, sx]}
      {...rest}
    >
      {(inputProps) => <InputBase {...inputProps} />}
    </InputMask>
  );
};

export default IpInputMask;
