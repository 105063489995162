export const style = {
  chip: {
    boxSizing: 'border-box',
    borderRadius: '50%',
    background: '#dce5fd',
    width: '34px',
    height: '34px',
    zIndex: '0',
    '& svg': {
      fill: '#517ff5'
    }
  }
}