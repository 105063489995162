import { useEffect } from "react";

import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/ui/button/button";
import { useShowMore } from "../../../../hooks/use-show-more";
import { em } from "../../../../utils/helpers";

import { GeosItem } from "./geos-item";
import { style } from "./geos.style";

export const GeosList = ({ data, keyProxyType }) => {
  // Translations
  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width:${em(576)})`);
  const { shown, handleShow, isShownAll, setShown } = useShowMore({
    entries: Object.entries(data),
    limit: 8,
    breakpoint: 576
  });

  useEffect(() => {
    setShown(isBreakpoint ? Object.entries(data)?.slice(0, 8 + 1) : Object.entries(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, keyProxyType]);

  return (
    <>
      <Box component="ul" sx={style.list}>
        {shown.map(([key, vals]) => (
          <GeosItem
            key={`proxy-content-item-${key}`}
            data={vals}
            ratingCount={shown.length}
            keyProxyType={keyProxyType}
          />
        ))}
      </Box>

      {!isShownAll && (
        <Box sx={style.showAll}>
          <Button onClick={handleShow} fullwidth>
            {t("other.showAll")}
          </Button>
        </Box>
      )}
    </>
  );
};
