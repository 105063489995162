export const PARTNERS_LIST = [
  {
    name: "MyWOT",
    link: "https://www.mywot.com/scorecard/youproxy.io",
    image: "/images/pages/reviews/partners/wot.svg"
  },
  {
    name: "BlackHatWorld",
    link: "https://www.blackhatworld.com/seo/you-proxy-com-private-individual-high-quality-ipv4-proxies-https-socks-special-bhw-offer.1312865/",
    image: "/images/pages/reviews/partners/black-hat-world-3.png"
  },
  {
    name: "Facebook reviews",
    link: "https://www.facebook.com/official.youproxy/reviews",
    image: "/images/pages/reviews/partners/facebook.svg"
  },
  {
    name: "G2",
    link: "https://www.g2.com/products/you-proxy-com/reviews",
    image: "/images/pages/reviews/partners/g2.svg"
  },
  {
    name: "Trustpilot",
    link: "https://www.trustpilot.com/review/youproxy.io",
    image: "/images/pages/reviews/partners/trustpilot.svg"
  },
  {
    name: "Rating-proxy",
    link: "https://rating-proxy.info/proxy/youproxy-io/#reviews",
    image: "/images/pages/reviews/partners/rating-proxy.svg"
  }
];
