
export const FAVICON_LINKS = [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: `/images/favicons/apple-touch-icon.png`
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: `/images/favicons/favicon-32x32.png`
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: `/images/favicons/favicon-16x16.png`
  },
  {
    rel: "manifest",
    href: `/images/favicons/site.webmanifest`
  },
  {
    rel: "mask-icon",
    href: `/images/favicons/safari-pinned-tab.svg`,
    color: "#5bbad5"
  }
];
