// import * as yup from "yup";
import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import useOrder from "../../../hooks/use-order";
import { defineErrorText } from "../../../utils/helpers";
import HeroForm from "../../heroForm/heroForm";

import { style } from "./hero.style";

const HeroPanel = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const order = useOrder();

  useEffect(() => {
    if (!order.price.success && order.price.error) {
      enqueueSnackbar(defineErrorText(order.price.error), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.price.success]);

  return (
    <>
      <Box sx={style.panel} className="proxy-hero__panel">
        <HeroForm order={order} isHomePage={false} />
        <Box sx={style.panel.info}>
          <Box sx={style.panel.price}>
            <Box component="span">
              <Box component="span">{t("pages.app.home.hero.totalSum")}</Box>
              <Box component="span">
                {t("currencies.usdUpper")}{order.price?.totalPrice || 0}
              </Box>
              {order.price?.totalPrice !== order.price?.priceForOneWithDiscount ? (
                <Box component="span">
                  ( {t("currencies.usdUpper")}{order.price?.priceForOneWithDiscount || 0}/{t("other.piece")} )
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(HeroPanel);
