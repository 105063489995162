import { useState } from "react";

import { Box, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ArrowTopIcon, CopyIcon, QuestionFilledIcon } from "../../../components/icons/index";
import { CopyToClipboard } from "../../../components/ui/copy-to-clipboard/copy-to-clipboard";
// import { useTranslation } from "../../../hooks";
import { useCollapse } from "../../../hooks/use-collapse";
import ModalsAR from "../../../pages/cabinet/auto-renewal/modalsAR";

import { TableOptions } from "./options/table-options";
import { style } from "./table.style";

// import "./table.scss";

export const TableRow = ({
  children,
  collapsed,
  copyText,
  withActions,
  rowsLength,
  index,
  customStyles,
  ipId,
  hasComment,
  blocked = false,
  isDark = null,
  autoExtend,
  proxyType,
  onUpdateParentComponent,
  autoExtendPeriod
}) => {
  const initialModalState = { enable: false, disable: false };
  const [modalType, setModalType] = useState(initialModalState);
  const [checked, setChecked] = useState(autoExtend);

  const { collapseRef, handleCollapse, isCollapseOpen } = useCollapse();
  const { t } = useTranslation();
  const defineClasses = () => {
    let classes = "table__row";

    if (isCollapseOpen && collapsed) classes += " table__row_open";
    if (index % 2) classes += " table__row_even";

    return classes;
  };

  const defineStyles = () => {
    const styles = [style.tableRow];

    if (isCollapseOpen && collapsed) styles.push(style.tableRow.open);
    if (index % 2 && isDark === null) styles.push(style.tableRow.even);
    if (isDark) styles.push(style.tableRow.even);

    if (blocked) styles.push(style.tableRow.blocked);

    return styles;
  };

  const definePosition = () => {
    if (rowsLength <= 3 && index === 0) return "forOne";
    else if (rowsLength - index <= 3) return "top";
    else return "bottom";
  };

  const closeModal = () => {
    setModalType(initialModalState);
    setChecked(autoExtend);
  };
  const handleSwitсh = (e) => {
    const { checked } = e.target;

    if (checked) {
      setModalType({ disable: false, enable: true });
    } else {
      setModalType({ disable: true, enable: false });
    }
    setChecked(checked);
  };

  return (
    <>
      <Box component="tr" className={defineClasses()} sx={[...defineStyles(), customStyles]}>
        {children}
        {withActions && (
          <Box component="td" sx={style.tableCell} className="table__cell">
            <Box sx={style.tableRow.actions} className="table__row-actions">
              {collapsed && (
                <Box
                  sx={[style.tableRow.action, style.tableRow.action.collapse]}
                  className="table__row-action table__row-action_collapse"
                  onClick={handleCollapse}
                >
                  <ArrowTopIcon />
                </Box>
              )}
              <CopyToClipboard text={copyText}>
                <Box sx={style.tableRow.action} className="table__row-action">
                  <CopyIcon />
                </Box>
              </CopyToClipboard>
              <TableOptions ipId={ipId} hasComment={hasComment} position={definePosition()} />
            </Box>
          </Box>
        )}
      </Box>
      {collapsed && (
        <Box component="tr">
          <Box
            component="td"
            sx={style.tableRow.collapsed}
            className="table__row-collapsed"
            colSpan="100%"
          >
            <Box
              ref={collapseRef}
              sx={{ height: "0px", overflow: "hidden", transition: "0.3s height" }}
            >
              <Box
                component="span"
                sx={style.tableRow.collapsed.inner}
                className="table__row-collapsed-inner"
              >
                <Box
                  component="span"
                  sx={style.tableRow.collapsed.action}
                  className="table__row-collapsed-action"
                >
                  <QuestionFilledIcon />
                </Box>
                <Box sx={style.tableRow.collapsed.grid}>
                  {Object.entries(collapsed).map(([key, val]) => (
                    <Box
                      component="span"
                      key={key}
                      sx={style.tableRow.collapsed.item}
                      className="table__row-collapsed-item"
                    >
                      <Box
                        component="span"
                        sx={style.tableRow.collapsed.key}
                        className="table__row-collapsed-key"
                      >
                        {key}
                      </Box>
                      :
                      <Box
                        component="span"
                        sx={style.tableRow.collapsed.val}
                        className="table__row-collapsed-val"
                      >
                        {val}
                      </Box>
                      <CopyToClipboard text={val}>
                        <Box sx={style.tableRow.collapsed.copy} className="table__row-action">
                          <CopyIcon />
                        </Box>
                      </CopyToClipboard>
                    </Box>
                  ))}
                </Box>
                {proxyType !== "IPV6" && (
                  <Box>
                    <Box component="span" sx={style.tableRow.collapsed.key}>
                      {t("pages.cabinet.orders.widgets.autoRenewal.title")}
                    </Box>
                    :
                    <FormControlLabel
                      sx={style.tableRow.collapsed.switch}
                      control={<Switch />}
                      checked={checked}
                      onChange={handleSwitсh}
                    />
                    {autoExtendPeriod && (
                      <Box component="span" sx={style.tableRow.collapsed.bold}>
                        ({parseInt(autoExtendPeriod.match(/\d+/)[0])}m)
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {modalType.enable || modalType.disable ? (
        <Box component="tr">
          <Box component="td">
            <ModalsAR
              onUpdateParentComponent={onUpdateParentComponent}
              proxyType={proxyType}
              ipAddressIds={[ipId]}
              modalType={modalType}
              closeModal={closeModal}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );
};
