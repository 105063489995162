import { createAsyncThunk } from "@reduxjs/toolkit";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const fetchFingerPrintOpensourse = createAsyncThunk(
  "fingerprint/fetchFingerPrintOpensourse",
  async (_, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await FingerprintJS.load();
  
      const data = await response.get();
  
      if (!data?.visitorId) {
        throw response;
      }
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(false);
    }
  }
)