import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TextInput } from "../../../components/common/text-input/text-input";

import { SearchIcon } from "../../../components/icons/index";

import { useDebounce } from "../../../hooks/use-debounce";
import { style } from "./table.style";

export const TableSearch = ({ label, onInput, value }) => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onInput(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <Box sx={style.tableSearch} className="table-search">
      {show ? (
        // <Form.Input label={label} onInput={handleInput} variant="table" />
        <TextInput label={label} onChange={handleInput} value={value}/>
      ) : (
        <>
          label <SearchIcon onClick={handleShow} />
        </>
      )}
    </Box>
  );
};
