import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container, OrderModal, SeoBlockField } from "../../../../components/common";
import { Heading } from "../../../../components/ui";
import { useActions, useLang, useSeoBlock } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getProxyState } from "../../../../store/proxy/proxy.selectors";
import { getRentState } from "../../../../store/rent/rent.selectors";

import EnterpriseBlock from "./enterprise-block/enterprise-block";
import PriceListByQuantity from "./price-list-by-quantity/price-list-by-quantity";
import { style } from "./prices-for-country.style";
import WhatYouGetBlock from "./what-you-get-block/what-you-get-block";

const PricesForCountry = (props) => {
  const { country, proxyType } = props;
  const { t } = useTranslation();

  const lang = useLang();

  const { getCountriesPricesByProxyType } = useActions();

  const { proxies, countriesPrices } = useSelector(getProxyState);
  const { periods } = useSelector(getRentState);

  const [quantityOptions, setQuantityOptions] = useState(null);
  const [modalOrder, setModalOrder] = useState({
    open: false,
    data: null
  });

  const { data: seoblocks } = useSeoBlock([
    "proxyCountryPageBatchProxies",
    "proxyCountryPageEnterprise"
  ]);

  // adaptive height for block w/ prices
  const boxWhatYouGetBlock = useRef(null);
  const [boxWhatYouGetBlockHeight, setBoxWhatYouGetBlockHeight] = useState(null);

  const updateBoxHeight = useMemo(() => {
    return () => {
      if (boxWhatYouGetBlock.current) {
        const height = boxWhatYouGetBlock.current.clientHeight;
        setBoxWhatYouGetBlockHeight(height);
      }
    };
  }, [boxWhatYouGetBlock.current]);

  useEffect(() => {
    const waitForElement = () => {
      return new Promise((resolve) => {
        const checkElement = () => {
          if (boxWhatYouGetBlock.current) {
            resolve();
          } else {
            setTimeout(checkElement, 50);
          }
        };
        checkElement();
      });
    };

    const setupObserver = async () => {
      await waitForElement();
      updateBoxHeight();
      const observer = new ResizeObserver(updateBoxHeight);
      observer.observe(boxWhatYouGetBlock.current);
      return () => observer.unobserve(boxWhatYouGetBlock.current);
    };

    setupObserver();
  }, []);

  // Set country
  useEffect(() => {
    const proxy = proxies[proxyType.toUpperCase()];

    if (country) {
      ApiService.getAllPackageProxyQuantity({
        proxyTypeId: proxy.proxyTypeId,
        countryId: country.id
      })
        .then((response) => {
          if (response.data) {
            setQuantityOptions(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [country, proxies, lang, proxyType]);

  const list = useMemo(() => {
    if (!countriesPrices.data[proxyType.toUpperCase()] || !country) {
      return {};
    }
    const countryPrices = countriesPrices.data[proxyType.toUpperCase()].find(
      (e) => e.countryId === country.id
    );

    if (!countryPrices) {
      return {};
    }

    const prices = countryPrices.rentPeriods.reduce((acc, item) => {
      item.pricesByQuantity.forEach((e) => {
        // Remove from list if quantity <= 1
        if (e.quantity <= 1) {
          return;
        }

        acc[e.quantity] = [
          ...(acc[e.quantity] ?? []),
          {
            amount: e.amount,
            amountForOne: e.amountForOne,
            rentPeriodId: item.rentPeriodId,
            namesByLocale: periods[proxyType.toUpperCase()].find((e) => e.id === item.rentPeriodId)
              ?.namesByLocale
          }
        ];
      });
      return acc;
    }, {});

    return prices;
  }, [countriesPrices.data, country]);
  // Fetch CountriesPricesByProxyType
  useEffect(() => {
    if (proxies && proxies[proxyType.toUpperCase()]) {
      if (!countriesPrices.data[proxyType.toUpperCase()]) {
        getCountriesPricesByProxyType(proxyType.toUpperCase());
      }
    }
  }, [proxies, proxyType]);

  const handlerBuy = (data) => {
    const proxy = proxies[proxyType.toUpperCase()];

    setModalOrder(() => ({
      open: true,
      data: {
        proxyType: proxy?.type ?? "",
        proxyTypeId: proxy?.proxyTypeId ?? "",
        proxyCountry: country?.id ?? "",

        proxyRentalPeriod: data?.rentPeriodId,
        proxyCount: data?.quantity ? +data?.quantity : ""
      }
    }));
  };

  const countryName = country?.namesDependingOnLocale[lang] ?? "";
  const modalQuantityOptions = (quantityOptions ?? Object.keys(list)).map((e) => ({
    key: e,
    label: `${e} ${t("other.ips")}`
  }));

  if (!Object.keys(list)?.length) {
    return null;
  }

  return (
    <>
      <Box sx={style.wrapper}>
        <Container>
          <SeoBlockField
            defaultValue={`${t("pages.app.proxyByCountry.prices.title")}: {{country}}`}
            field={seoblocks?.proxyCountryPageBatchProxies?.title}
          >
            {(data) => (
              <Heading tag="h2" variant="h2" center={true}>
                {data.replace("{{country}}", countryName)}
              </Heading>
            )}
          </SeoBlockField>

          <Box sx={style.description}>
            <SeoBlockField
              defaultValue={t("pages.app.proxyByCountry.apiIntegration.description")}
              field={seoblocks?.proxyCountryPageBatchProxies?.description}
            >
              {(data) => data.replaceAll("{{country}}", countryName)}
            </SeoBlockField>
          </Box>
          <Box sx={style.content}>
            <PriceListByQuantity
              loading={countriesPrices.loading}
              items={Object.entries(list)}
              onBuy={handlerBuy}
              heightBlock={boxWhatYouGetBlockHeight}
            />
            <Box ref={boxWhatYouGetBlock}>
              <WhatYouGetBlock seoData={seoblocks?.proxyCountryPageBatchProxies} />
              <EnterpriseBlock seoData={seoblocks?.proxyCountryPageEnterprise} />
            </Box>
          </Box>
        </Container>
      </Box>

      <OrderModal
        id={`countries-group`}
        quantityOptions={modalQuantityOptions}
        visible={modalOrder.open}
        initialData={modalOrder.data}
        cancelHandler={() => {
          setModalOrder((prevState) => ({ ...prevState, open: false }));
        }}
      />
    </>
  );
};

export default PricesForCountry;
