import React from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useLang } from "../../../hooks";

const RedirectChangeEmail = () => {
  const lang = useLang();

  const { hash } = useParams();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";

  return (
    <Navigate to={`/${lang}/cabinet/profile/?email=${email}&emailHash=${hash}`} replace={true}/>
  )
}


export default RedirectChangeEmail;
