import { appTheme } from "../../../../theme";

export const styles = {
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-3rem',
    marginBottom: '-3rem',

    [appTheme.breakpoints.down('bp1200')]: {
      marginRight: '-1.6rem',
      marginBottom: '-1.6rem',
    },

    [appTheme.breakpoints.down('bp992')]: {
      flexWrap: 'wrap',
    }
  },
  item: {
    flexShrink: '0',
    marginRight: '3rem',
    marginBottom: '3rem',
    width: '8.4rem',
    height: '6rem',

    [appTheme.breakpoints.down('bp1200')]: {
      marginRight: '1.6rem',
      marginBottom: '1.6rem',
    },

    [appTheme.breakpoints.down('bp767')]: {
      width: '7.2rem',
      height: '5rem',
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}
