import React, { useEffect, useState } from "react";

import { Box, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Table } from "../../../../components/ui/table/table";
import { useLang, useTranslateOptions } from "../../../../hooks";
import { useSelection } from "../../../../hooks/use-selection";
import { OrdersService } from "../../../../services";
import { getProxyState } from "../../../../store/selectors";
import { getDate } from "../../../../utils/helpers";
import { useTableData } from "../../../../utils/hooks/useTableData";
import { useTableNavigation } from "../../../../utils/hooks/useTableNavigation";
import ModalsAR from "../../auto-renewal/modalsAR";
import { OrdersPanel } from "../orders-panel";
import { allOption } from "../orders.constants";
import {
  defineProtocol,
  getCountriesWithLocale,
  getSortDirection,
  selectAllHandle
} from "../orders.helpers";
import { useSortHandle } from "../orders.hooks";
import { style } from "../orders.style";

export const Ipv6 = ({ withoutPanel, onChangeSelected, shouldUpdate, onUpdateParentComponent }) => {
  const lang = useLang();

  // Translations
  const { t } = useTranslation();

  // state
  const [ipsLoading, setIpsLoading] = useState(false);

  //select state
  const [countryIndex, setCountryIndex] = useState(0);
  const initialModalState = { enable: false, disable: false };
  const [modalType, setModalType] = useState(initialModalState);
  const [switchChecked, setSwitchChecked] = useState({});
  const [initialChecked, setInitialChecked] = useState({});
  //*Redux
  const { proxyTypeIds } = useSelector(getProxyState);

  //custom hooks
  const [sort, sortHandler, filter, filterHandler] = useSortHandle();
  const [pageObj, pageChangeHandler, sizeSelectHandler] = useTableNavigation();
  const [tableData, tableLoading, ordersData] = useTableData({
    proxyTypeId: proxyTypeIds.find((el) => el.name === "IPv6")?.id,
    pageObj,
    sort,
    filter,
    asyncFunction: OrdersService.getOrdersByProxyType,
    isOrdersPage: true,
    shouldUpdate
  });
  const { selected, selectedLength, handleSelectMultiple, handleSelectAll, resetSelected } =
    useSelection(tableData?.content || []);
  const countriesOptions = [
    ...useTranslateOptions([...allOption], t("countries")),
    ...getCountriesWithLocale(ordersData, lang)
  ];

  const isTableDisabled = !tableData?.content?.length;

  // handlers
  const countryChangeHandler = (option) => {
    setCountryIndex(countriesOptions.indexOf(option));
    filterHandler(option.key);
  };

  const selectIpv6Handler = (id, value) => {
    setIpsLoading(true);

    const reqObj = {
      proxyTypeId: proxyTypeIds.find((el) => el.name === "IPv6")?.id,
      ipAddressId: id
    };

    OrdersService.getIpsFromOrder(reqObj)
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          handleSelectMultiple(res.data.ipAddressesIds, value);
        }
        setIpsLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        setIpsLoading(false);
      });
  };

  useEffect(() => {
    resetSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  useEffect(() => {
    onChangeSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  useEffect(() => {
    if (tableData?.content && tableData?.content?.length) {
      const uniqueChecked = tableData.content.reduce((acc, obj) => {
        if (!acc[obj.innerIp]) {
          acc[obj.innerIp] = obj.autoExtend;
        }
        return acc;
      }, {});
      setSwitchChecked(uniqueChecked);
      setInitialChecked(uniqueChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData.content]);

  const closeModal = () => {
    setModalType(initialModalState);
    resetSelected();
    setSwitchChecked(initialChecked);
    // onUpdateParentComponent();
  };

  const handleSwitch = (e, id, innerIp) => {
    const { checked } = e.target;

    selectIpv6Handler(id, true);

    if (checked) {
      setModalType({ disable: false, enable: true });
    } else {
      setModalType({ disable: true, enable: false });
    }

    setSwitchChecked({ ...switchChecked, [innerIp]: checked });
  };

  return (
    <Box className="cabinet-orders__content">
      <Box sx={style.table} className="cabinet-orders__table">
        <Table
          pagination={!!tableData?.totalElements}
          totalPages={tableData?.totalPages ? tableData?.totalPages - 1 : 0}
          totalElements={tableData?.totalElements || 0}
          elementsOnPage={tableData?.content?.length}
          currentPage={pageObj.page}
          onPageChange={pageChangeHandler}
          pageSize={pageObj.pageSize}
          outputCountHandler={sizeSelectHandler}
          loading={tableLoading}
          hasGroupRow={true}
          head={
            <Table.Head>
              {!isTableDisabled ? (
                <Table.Row>
                  <Table.Cell tag="th" type="checkbox">
                    <Table.Checkbox
                      onChange={() =>
                        selectAllHandle(
                          tableData,
                          ordersData,
                          selected.map((item) => item.id),
                          handleSelectAll
                        )
                      }
                      checked={
                        selectedLength === tableData?.totalElements &&
                        tableData?.content?.length !== 0
                      }
                      color="white"
                    />
                  </Table.Cell>
                  <Table.Cell tag="th" width="13.5rem">
                    <Table.Select
                      options={countriesOptions || []}
                      onChange={(option) => countryChangeHandler(option, "country")}
                      selectedIndex={countryIndex}
                      withLeftIcon
                    />
                  </Table.Cell>
                  <Table.Cell tag="th" width="12rem" type="small">
                    {t("pages.cabinet.orders.table.ip")}
                  </Table.Cell>
                  <Table.Cell tag="th" width="12rem" type="small">
                    <Table.Sort
                      onClick={() => sortHandler("dateStart")}
                      direction={getSortDirection(sort, "dateStart")}
                    >
                      {t("pages.cabinet.orders.table.activeFrom")}
                    </Table.Sort>
                  </Table.Cell>
                  <Table.Cell tag="th" width="12rem" type="small">
                    <Table.Sort
                      onClick={() => sortHandler("dateEnd")}
                      direction={getSortDirection(sort, "dateEnd")}
                    >
                      {t("pages.cabinet.orders.table.activeBy")}
                    </Table.Sort>
                  </Table.Cell>
                  <Table.Cell tag="th" width="12.5rem" type="small">
                    {t("pages.cabinet.orders.table.ipStatus")}
                  </Table.Cell>
                  {/* <Table.Cell textAlign="right">{t.pages.cabinet.orders.table.protocol}</Table.Cell> */}
                  <Table.Cell tag="th"></Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Head>
          }
        >
          <Table.Body>
            {tableData?.content && tableData?.content?.length ? (
              (() => {
                let groupIp;
                let isDarkRow = true;
                let hasTopRow = true;

                return tableData.content.map((row, idx) => {
                  let collapsedObj;
                  if (row.authType === "LOGIN") {
                    collapsedObj = {
                      Login: row.authLogin,
                      Port: row.port,
                      Password: row.authPassword,
                      Protocol: defineProtocol(row.proxyProtocol)
                    };
                  } else if (row.authType === "IP") {
                    collapsedObj = {
                      IP: row.authIp,
                      Port: row.port,
                      Protocol: defineProtocol(row.proxyProtocol)
                    };
                  }

                  if (!groupIp || groupIp !== row.innerIp) {
                    groupIp = row.innerIp;
                    isDarkRow = !isDarkRow;
                    hasTopRow = true;
                  } else if (hasTopRow) {
                    hasTopRow = false;
                  }

                  return (
                    <React.Fragment key={`row-${row.id}`}>
                      {hasTopRow && (
                        <Table.Row customStyles={{ background: "#dde6fd" }}>
                          <Table.Cell colSpan="100%">
                            <Box sx={style.blockWSwitch}>
                              <Box sx={{ fontWeight: "500" }}>ID: {row.innerIp}</Box>
                              <Box
                                sx={{
                                  ...style.flexCenter,
                                  marginRight: "1rem"
                                }}
                              >
                                <Box component="span" sx={style.blockWSwitch.title}>
                                  {t("pages.cabinet.orders.widgets.autoRenewal.title")}
                                </Box>
                                :
                                <Box sx={style.flexCenter}>
                                  <FormControlLabel
                                    sx={style.blockWSwitch.switch}
                                    control={<Switch />}
                                    checked={switchChecked[row.innerIp] || false}
                                    onChange={(e) => handleSwitch(e, row.id, row.innerIp)}
                                  />
                                  {row.rentPeriodNamesByLocaleExtend?.en && (
                                    <Box component="span" sx={style.blockWSwitch.bold}>
                                      (
                                      {parseInt(
                                        row.rentPeriodNamesByLocaleExtend?.en.match(/\d+/)[0]
                                      )}
                                      m)
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Table.Cell>
                        </Table.Row>
                      )}

                      <Table.Row
                        key={row.id}
                        ipId={row.id}
                        hasComment={!!row.comment}
                        index={idx}
                        rowsLength={tableData.content.length}
                        collapsed={collapsedObj}
                        withActions
                        blocked={ipsLoading}
                        isDark={isDarkRow}
                        copyText={
                          // collapsedObj ? getTableCollapsedText(collapsedObj) : getTableCopyText(cols, row)
                          row.innerIp
                        }
                        proxyType="IPV6"
                      >
                        <Table.Cell type="checkbox">
                          {/* {hasCheckbox && (
                          <Table.Checkbox
                            onChange={(value) => selectIpv6Handler(row.id, value)}
                            checked={selected?.includes(row.id)}
                          />
                        )} */}
                          <Table.Checkbox
                            onChange={(value) => selectIpv6Handler(row.id, value)}
                            checked={selected?.some((item) => item.id === row.id)}
                          />
                        </Table.Cell>
                        <Table.Cell width="13.5rem">{row.countryNamesByLocale[lang]}</Table.Cell>
                        <Table.Cell width="12rem" type="small">{`${row.innerIp}`}</Table.Cell>
                        <Table.Cell width="12rem" type="small">
                          {getDate(row.dateStart)}
                        </Table.Cell>
                        <Table.Cell width="12rem" type="small">
                          {getDate(row.dateEnd)}
                        </Table.Cell>
                        <Table.Cell width="12.5rem" type="small">
                          {t(`pages.cabinet.orders.table.${row.status}`)}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                });
              })()
            ) : (
              <Table.Row customStyles={style.noData}>
                <Table.Cell>{t("cabinet.orders.noData")}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Box>
      {!withoutPanel && !isTableDisabled && (
        <OrdersPanel
          proxyType="IPV6"
          selected={selected.map(({ id }) => id)}
          totalElements={tableData?.totalElements}
          selectAllHandle={() => selectAllHandle(tableData, ordersData, selected, handleSelectAll)}
          selectedLength={selectedLength}
          rowsLength={tableData?.content?.length || 0}
        />
      )}
      {modalType.enable || modalType.disable ? (
        <ModalsAR
          onUpdateParentComponent={onUpdateParentComponent}
          proxyType="IPV6"
          ipAddressIds={selected.map(({ id }) => id)}
          modalType={modalType}
          closeModal={closeModal}
        />
      ) : null}
    </Box>
  );
};
