import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import Select from "react-select";

import { getCountryFlagIso2 } from "../../utils/helpers/get-country-flag";

import { style } from "./heroForm.style";

export const HeroSelect = ({
  error = false,
  errorText = null,
  label,
  name,
  options = [],
  onChange,
  isCountry,
  selectedIndex,
  customStyles = {}
}) => {
  const selectRef = useRef();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuPortalTarget, setMenuPortalTarget] = useState(null);

  useEffect(() => {
    setMenuPortalTarget(document?.body);
  }, []);

  // styles for animated open/close state
  const selectStyles = (menuIsOpen) => ({
    menu: (provided) => ({
      ...provided,
      opacity: menuIsOpen ? 1 : 0, // Set opacity based on menu state
      transform: menuIsOpen ? "translateY(1rem)" : "translateY(0rem)",
      transition: "0.3s ease", // Apply transition to opacity
      visibility: menuIsOpen ? "visible" : "hidden",
      pointerEvents: menuIsOpen ? "unset" : "none",
      borderRadius: "1.2rem",
      boxShadow: "0 2px 22px rgb(95 103 128 / 14%)",
      overflow: "hidden"
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "20rem"
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      fontSize: "1.4rem",
      cursor: isSelected ? "pointer" : "pointer",
      // var(--clr-text-400)
      color: "#63637C",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
      padding: customStyles.optionPadding || "0.6rem 2rem",
      minHeight: "4.8rem",
      transition: "0.3s",
      backgroundColor: isSelected ? "#DCE5FD" : "#fff",
      pointerEvents: isSelected ? "none" : "unset",
      "&:hover": {
        backgroundColor: "#EEF2FC"
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "0.3s ease", // Apply transition to opacity
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)"
    })
  });
  // custom select option component
  const formatOptionLabel = ({ label, code }) => (
    <>
      {isCountry && (
        <Box sx={style.dropItemImg}>
          <img src={getCountryFlagIso2(code)} alt={code} />
        </Box>
      )}
      <span className="label">{label}</span>
    </>
  );

  return (
    <Box
      sx={
        error
          ? [style.selectWrapper, style.error, customStyles]
          : [style.selectWrapper, customStyles]
      }
      className={menuIsOpen ? "form-item open" : "form-item"}
      // onTouch={() => console.log("TOUCH")}
      onClick={() => {
        // if (menuIsOpen) return;
        setMenuIsOpen(() => !menuIsOpen);
        if (menuIsOpen) selectRef.current.blur();
        else selectRef.current.focus();
      }}
      onTouchEnd={() => {
        if (menuIsOpen) return;
        setMenuIsOpen(() => !menuIsOpen);
        if (menuIsOpen) selectRef.current.blur();
        else selectRef.current.focus();
      }}
    >
      <Box component="label" sx={style.selectWrapper.label}>
        {label}
      </Box>

      <Select
        aria-label={name}
        ref={selectRef}
        options={options}
        className="react-select__container"
        classNamePrefix="react-select"
        styles={selectStyles(menuIsOpen)}
        unstyled
        menuIsOpen
        menuPlacement="auto"
        isSearchable={false}
        getOptionValue={(opt) => opt.key}
        inputId={name}
        instanceId={name}
        onBlur={() => setMenuIsOpen(false)}
        value={options.length ? options[selectedIndex] : ""}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        placeholder={null}
        menuPortalTarget={menuPortalTarget}
        menuShouldScrollIntoView={false}
        suppressHydrationWarning
      />

      {error && (
        <Box component="span" sx={style.errorText}>
          {errorText}
        </Box>
      )}
    </Box>
  );
};
