import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AuthFormFooter from "../../../components/common/auth-form-footer/auth-form-footer";
import { Container } from "../../../components/common/container/container";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useLang, useSetSeoPage } from "../../../hooks";
import { UserService } from "../../../services";
import { getIsAuthenticatedUser } from "../../../store/selectors";
import { ROUTE } from "../../../utils/constants";
import { getCaptchaTokenFromFormData } from "../../../utils/helpers";
import { LoginSchema } from "../../../utils/validation";

import { style } from "./login.style";

const Login = () => {
  useSetSeoPage();

  const captchaRef = useRef(null);
  const captchaLoginRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const lang = useLang();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const isMobileBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("bp576"));

  const isAuthenticated = useSelector(getIsAuthenticatedUser);
  const { setUser } = useActions();

  const [isLoading, setIsLoading] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      setIsAdminUser(true);

      navigate(state ? state : `/${lang}${ROUTE.CABINET.PROFILE}/`, { replace: true });
    } else {
      UserService.adminUser()
        .then((result) => {
          setIsAdminUser(result.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("Error: ", err?.response?.response || err?.response || err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handlers
  const handleSubmit = async (data) => {
    let cfToken = getCaptchaTokenFromFormData(data);

    let tokenFromLoginCaptcha;

    if (!cfToken) {
      cfToken = await captchaRef.current?.getResponsePromise();
    }

    if (captchaLoginRef?.current) {
      tokenFromLoginCaptcha = await captchaLoginRef.current?.getResponsePromise();
    }

    setIsLoading(true);

    try {
      if (!isAdminUser) {
        const resp = await UserService.createDefaultUser(
          {
            email: data.loginEmail,
            password: data.loginPassword
          },
          cfToken
        );

        if (resp?.status === 201) window.location.reload();
        // if (resp.status === 401) console.log("status 401!!!");
        return;
      }

      await UserService.login(
        {
          username: data.loginEmail,
          password: data.loginPassword
        },
        tokenFromLoginCaptcha
      );

      const user = await UserService.getUser();
      setUser(user.data);

      navigate(state ? state : `/${lang}${ROUTE.CABINET.PROFILE}/`, { replace: true });
    } catch (err) {
      let message = t("serverError");
      // eslint-disable-next-line no-console
      console.error("Error: ", err?.response?.response || err?.response || err);

      const { status } = err.response;

      if (status === 401) {
        message = t("loginUnauthorized");
      } else if (status === 409) {
        captchaRef?.current?.reset();
        captchaLoginRef?.current?.reset();
        message = t("messages.captchaFailed");
      }

      enqueueSnackbar(message, { variant: "error" });
    } finally {
      captchaRef?.current?.reset();
      captchaLoginRef?.current?.reset();
      setIsLoading(false);
    }
  };

  return (
    <Box sx={style.login} className="login">
      <Container>
        <Box sx={style.wrapper} className="login__wrapper">
          <Heading tag="h1" variant="h2">
            {t("pages.auth.login.heading")}
          </Heading>
          <Box sx={style.inner} className="login__inner">
            <Form
              sx={style.form}
              className="login__form"
              schema={LoginSchema(t("form"))}
              onSubmit={handleSubmit}
            >
              <Form.Input
                name="loginEmail"
                label={isMobileBreakpoint ? null : t("form.labels.email")}
                placeholder={`${t("form.placeholders.yourEmail")}...`}
                labelType="outer"
                variant="outlined"
              />
              <Form.Input
                name="loginPassword"
                label={isMobileBreakpoint ? null : t("form.labels.password")}
                placeholder={`${t("form.placeholders.enterPassword")}...`}
                labelType="outer"
                variant="outlined"
                type="password"
                actions
                autoComplete="new-password"
              />
              <Form.Captcha ref={captchaRef} id="get-user-captcha" />
              <Form.Captcha
                id="login-captcha"
                ref={captchaLoginRef}
                // onSuccess={(token) => {
                //   setTokenFromLoginCaptcha(token);
                // }}
                sx={{
                  display: "none"
                }}
              />
              <Button type="submit" variant="primary" loading={isLoading} fullwidth>
                {isAdminUser ? t("form.actions.login") : t("form.actions.create")}
              </Button>
            </Form>

            <AuthFormFooter
              links={[
                { label: t("pages.auth.login.forgot"), link: ROUTE.AUTH.FORGOT },
                { label: t("pages.auth.login.register"), link: ROUTE.AUTH.REGISTER }
              ]}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
