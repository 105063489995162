import { ISO3_TO_ISO2 } from "../constants";

export const getCountryFlagIso2 = (iso3Code) => {
  if (iso3Code && ISO3_TO_ISO2[iso3Code]) {
    return `/images/icons/flags/${ISO3_TO_ISO2[iso3Code].toLowerCase()}.svg`;
  } else if (iso3Code === "SRB") {
    // TODO Need fix to excludes
    return "/images/icons/flags/rs.svg";
  } else {
    return "/images/icons/flags/world.svg";
  }
};
