export const style = {
  list: {
    listStyleType: "none",
    paddingTop: "40px"
  },

  container: {
    padding: "70px 0"
  }
}
