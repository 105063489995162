import { useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { MobileMenuContext } from "../../mobile-menu";
import { getProxyState } from "../../../../../store/selectors";

import { style } from "../../mobileMenu.style";
import { getCountryFlagIso2 } from "../../../../../utils/helpers/get-country-flag";
import { generateProxyLink } from "../../../../../utils/helpers/generate-proxy-link";
import { useLang } from "../../../../../hooks";

export const BuyProxy = () => {
  const { handleOpen, activeProxyType } = useContext(MobileMenuContext);

  const { proxies } = useSelector(getProxyState);

  // Translations
  const lang = useLang();

  return (
    <Box
      sx={[style.proxy, style.proxy.buy, style.item]}
      className="mobile-menu__proxy mobile-menu__proxy_buy mobile-menu__item"
    >
      <Box component="ul" sx={style.proxy.list} className="mobile-menu__proxy-list">
        {proxies[activeProxyType.toUpperCase()]?.countries.map((country, idx) => (
          <Box
            component="li"
            key={`${idx}:${country.code}`}
            className="mobile-menu__proxy-item"
            onClick={handleOpen}
          >
            <Box
              component={Link}
              to={generateProxyLink(lang, activeProxyType, country.urlParam)}
              sx={style.proxy.link}
              className="mobile-menu__proxy-link"
            >
              <Box sx={style.proxy.image} className="mobile-menu__proxy-image">
                <img
                  src={getCountryFlagIso2(country.code)}
                  width={28}
                  height={28}
                  alt={country.namesDependingOnLocale[lang]}
                  loading="lazy"
                />
              </Box>
              <Box
                component="span"
                sx={style.proxy.label}
                className="mobile-menu__proxy-label"
              >
                {country.namesDependingOnLocale[lang]}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
