export const TOOLS_ROUTES = {
  translation: "tools",
  parent: "tools",
  routes: [
    {
      key: "my-ip",
      translation: "myIp",
      icon: "MyIpIcon"
    },
    {
      key: "ping-ip",
      translation: "pingIp",
      icon: "PingIpIcon"
    },
    {
      key: "check-evercookie",
      translation: "checkEvercookie",
      icon: "CheckEvercookieIcon"
    },
    {
      key: "proxy-checker",
      translation: "proxyChecker",
      icon: "ProxyCheckerIcon"
    },
    {
      key: "my-anonymity",
      translation: "myAnonymity",
      icon: "MyAnonymityIcon"
    },
    {
      key: "check-webrtc",
      translation: "checkWebrtc",
      icon: "CheckWebrtcIcon"
    },
    {
      key: "port-scanner",
      translation: "portScanner",
      icon: "PortScannerIcon"
    },
    {
      key: "fingerprint-scanner",
      translation: "fingerprintScanner",
      icon: "FingerprintScannerIcon"
    },
    {
      key: "ip-tracing",
      translation: "ipTracing",
      icon: "IpTracingIcon"
    }
  ]
};
