import { useEffect, useState } from "react";

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

import { CustomCheckIcon } from "./common/custom-checkbox";
import { IpInput } from "./common/ip-input";
import { style } from "./modal-radio-group.style";

const CustomRadio = (props) => (
  <Radio
    checkedIcon={<CustomCheckIcon isChecked />}
    icon={<CustomCheckIcon />}
    sx={style.radio}
    {...props}
  />
);

export const ModalRadioGroup = ({
  label,
  name,
  selectedIndex,
  options,
  onChange,
  ipInputKey,
  onIpChange,
  ipValue,
  cancelHandler,
  error,
  ipError
}) => {
  const [showIp, setShowIp] = useState(true);

  const handleChange = (e) => {
    onIpChange({ target: { value: "" } });
    onChange(e);
  };

  useEffect(() => {
    if (options[selectedIndex]?.key === ipInputKey) setShowIp(true);
    else setShowIp(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, options]);

  useEffect(() => {
    onChange({ target: { value: options[0].key } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl>
      <FormLabel sx={style.label}>{label}</FormLabel>
      <RadioGroup
        id={name}
        value={options[selectedIndex]?.key || ""}
        onChange={handleChange}
        sx={style.group}
      >
        {options.map((opt, i) =>
          showIp && opt.key === ipInputKey ? (
            <IpInput
              key="ip-input"
              maskProps={{
                onChange: onIpChange,
                value: ipValue
              }}
              style={style.input}
              cancelHandler={cancelHandler}
              error={ipError}
            />
          ) : (
            <FormControlLabel
              key={i}
              label={opt.label}
              value={opt.key}
              control={<CustomRadio />}
              sx={error ? [style.radioItem, style.radioItem.error] : style.radioItem}
            />
          )
        )}
      </RadioGroup>
    </FormControl>
  );
};
