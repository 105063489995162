import { useRef } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { setCookie } from "../../../utils/helpers/cookie.helpers";
import { useMenuHover } from "../header/header-menu/header-menu.hooks";

import { style } from "./languages.style";

const Languages = ({ id, dark, position = "bottom" }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const locales = i18n.options.supportedLngs.filter((lng) => lng !== "cimode");

  const menuRef = useRef(null);
  const { menuHover, disableMenuHover } = useMenuHover(menuRef);

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang).then(() => {
      setCookie("lang", newLang);
      disableMenuHover();

      navigate({
        pathname: `/${newLang}/${location.pathname.slice(4)}`,
        hash: location.hash,
        search: location.search,
        state: location.state
      });
    });
  };

  const defineClasses = () => {
    let classNames = "languages";
    const classes = [style.languages];

    switch (position) {
      case "top":
        classNames += " languages_position_top";
        classes.push(style.positionTop);
        break;
      case "bottom":
        classNames += " languages_position_bottom";
        classes.push(style.positionBottom);
        break;
      default:
        break;
    }

    if (dark) classNames += " languages_dark";

    return { classes, classNames };
  };

  return (
    <Box
      id={id}
      sx={defineClasses().classes}
      className={defineClasses().classNames}
      onMouseOver={menuHover}
      onMouseLeave={disableMenuHover}
      ref={menuRef}
    >
      <Box sx={style.label} className="languages__label">
        {t(`languages.${lang}`)}
      </Box>
      <Box component="ul" sx={style.list} className="languages__list">
        {locales.map((language) => {
          const isCurrent = lang === language;
          const localeName = t(`languages.${language}`);

          const handlerClickLink = (e) => {
            e.preventDefault();
            changeLanguage(language);
          };

          return (
            <Box
              component="li"
              sx={isCurrent ? [style.item, style.item.active] : style.item}
              key={language}
              className={isCurrent ? "languages__item languages__item_active" : "languages__item"}
            >
              <a onClick={handlerClickLink} aria-label={localeName} href={`/${language}/`}>
                {localeName}
              </a>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Languages;
