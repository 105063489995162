import { Tooltip as MuiTooltip } from "@mui/material";

import { style } from "./tooltip.style";

const delay = 500;
const Tooltip = (props) => (
  <MuiTooltip
    enterDelay={delay}
    enterNextDelay={delay / 2}
    componentsProps={{ tooltip: { style } }}
    {...props}
  >
    {props.children}
  </MuiTooltip>
);

export default Tooltip;
