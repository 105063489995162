import React, { useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextInput } from "../../../components/common/text-input/text-input";
import { Form } from "../../../components/form";
import { Copy2Icon, Info2Icon } from "../../../components/icons";
import { Button, Heading } from "../../../components/ui";
import Tooltip from "../../../components/ui/tooltip/tooltip";
import { useSetSeoPage } from "../../../hooks";
import { DevelopersApiService } from "../../../services";
import { copyTextToClipboard, getCaptchaTokenFromFormData } from "../../../utils/helpers";
import { ApiDevelopersSchema } from "../../../utils/validation/developers-api.validation";

import { style } from "./developers-api.style";

const DevelopersApi = () => {
  const [documentationUrl, setDocumentationUrl] = useState("");
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const captchaRef = useRef(null);

  const { t } = useTranslation();

  const formContext = useForm({
    mode: "onChange",
    resolver: yupResolver(ApiDevelopersSchema(t("form"))),
    defaultValues: {
      apiKey: "",
      accessIpAddresses: ""
    }
  });
  const [loading, setLoading] = useState(false);
  const [isGenerateKey, setIsGenerateKey] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [ipAddresses, setIpAddresses] = useState("");

  const accessIpAddressesField = formContext.watch("accessIpAddresses");

  // Fetch Credential
  useEffect(() => {
    setLoading(true);

    DevelopersApiService.getCredential()
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject("");
        }

        const { apiKey, accessIpAddresses, documentUrl } = response.data;
        setDocumentationUrl(documentUrl);

        apiKey && formContext.setValue("apiKey", apiKey);

        if (accessIpAddresses) {
          const accessIpAddressesStr = accessIpAddresses.join(", ");
          setIpAddresses(accessIpAddressesStr);
          formContext.setValue("accessIpAddresses", accessIpAddressesStr);
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("serverError"), { variant: "error" });

        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlerClickCopyApiKey = () => {
    copyTextToClipboard(formContext.getValues(["apiKey"])[0])
      .then(() => {
        enqueueSnackbar(t("copyMessageSuccess"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(t("copyMessageError"), { variant: "error" });

        console.log(error);
      });
  };
  const handlerClickGenerateApiKey = () => {
    setIsGenerateKey(true);

    DevelopersApiService.getApiKey()
      .then((res) => {
        if (res.status !== 200) {
          return Promise.reject("");
        }
        formContext.setValue("apiKey", res.data);
        formContext.setFocus("apiKey");
      })
      .catch((error) => {
        enqueueSnackbar(t("serverError"), { variant: "error" });

        console.log(error);
      })
      .finally(() => {
        setIsGenerateKey(false);
      });
  };
  const handlerSubmit = (data) => {
    if (ipAddresses === accessIpAddressesField) {
      return;
    }
    setIsSubmit(true);

    const accessIpAddresses = data.accessIpAddresses
      .split(",")
      .map((e) => e.trim())
      .filter(Boolean);

    DevelopersApiService.updateCredential(
      {
        accessIpAddresses: accessIpAddresses.length ? accessIpAddresses : null
      },
      getCaptchaTokenFromFormData(data)
    )
      .then(() => {
        setIpAddresses(data.accessIpAddresses);
        enqueueSnackbar(t("ipAddressesSaved"), { variant: "success" });
        captchaRef?.current?.reset();
      })
      .catch((error) => {
        let message = t("form.serverErrors.default");

        if (error?.response?.status === 409) {
          captchaRef?.current?.reset();
          message = t("messages.captchaFailed");
        }
        enqueueSnackbar(message, { variant: "error" });
        console.log(error);
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Box sx={style.developersApi}>
      <Heading tag="h1" variant="h2">
        {t("pages.cabinet.developersApi.heading")}
      </Heading>
      <Form context={formContext} sx={style.form} onSubmit={handlerSubmit}>
        <Box sx={style.formRow}>
          <Box>
            <Form.Input
              readOnly={true}
              name="apiKey"
              labelType="outer"
              variant="outlined"
              actions={
                <>
                  <Tooltip
                    title={`${t("common.copy")} API Key`}
                    placement="top"
                    arrow
                    sx={{ fontSize: "20px" }}
                  >
                    <Box
                      component={"button"}
                      type={"button"}
                      sx={style.actionButton}
                      onClick={handlerClickCopyApiKey}
                    >
                      <Copy2Icon />
                    </Box>
                  </Tooltip>
                </>
              }
            />
            <Box style={style.description}>
              <Box component="span" style={style.descriptionIcon}>
                <Info2Icon />
              </Box>
              {t("pages.cabinet.developersApi.description.apiKey")}
            </Box>
          </Box>
          <Button
            disabled={isGenerateKey || loading}
            type="button"
            variant="primary"
            onClick={handlerClickGenerateApiKey}
          >
            {t("form.actions.generateKey")}
          </Button>
        </Box>
        <Box sx={style.formRow}>
          <Box>
            <Form.Input name="accessIpAddresses" labelType="outer" variant="outlined" />
            <Box style={style.description}>
              <Box component="span" style={style.descriptionIcon}>
                <Info2Icon />
              </Box>
              {t("pages.cabinet.developersApi.description.accessIpAddresses")}
            </Box>
          </Box>
          <Box>
            <Button
              fullwidth
              disabled={
                isSubmit ||
                // || Object.keys(formContext.formState.errors).length
                ipAddresses === accessIpAddressesField ||
                loading
              }
              type="submit"
              variant="primary"
            >
              {t("form.actions.submit")}
            </Button>

            <Form.Captcha ref={captchaRef} sx={{ marginTop: "1rem" }} />
          </Box>
        </Box>
      </Form>
      <Box sx={style.formRow}>
        <Box sx={style.documentation}>
          <Box component="span">{t("pages.cabinet.developersApi.documentation.label")}</Box>
          <TextInput fullWidth isOuterLabel={true} value={documentationUrl} readOnly={true} />
        </Box>
        <Box>
          <Button
            disabled={loading}
            component={"a"}
            href={documentationUrl}
            target="_blank"
            variant="primary"
            fullwidth
          >
            {t("pages.cabinet.developersApi.documentation.read")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DevelopersApi;
