import { useState } from "react";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";

import { ArrowAccordion, CloseIcon } from "../../../components/icons/index";
import { useLang } from "../../../hooks";
import { NotificationsService } from "../../../services/notifications.service";

import { style } from "./popup-notifications.style.js";
import { countBlocks, shortenMessage } from "./utils";

const Item = ({ item }) => {
  const [expanded, setExpanded] = useState(false);
  const stringMessage = item.data.html;
  const [read, setRead] = useState(item.read);
  const { date } = item;
  const { t } = useTranslation();
  const lang = useLang();

  const formatDate = (date) => moment(date).locale(lang).format("MMMM D, YYYY | HH:mm");

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const allowableNotificationLength = countBlocks(stringMessage) <= 3;

  const handleMouseEnter = (id) => {
    if (!item.read) {
      NotificationsService.readNotification(id);
      setRead(true);
    }
  };

  return (
    <Box component="li" onMouseEnter={() => handleMouseEnter(item.id)}>
      <Box sx={{ ...style.item, ...(!read ? style.itemNew : {}) }}>
        <Box sx={style.item.dateContainer}>
          {!read && (
            <Typography sx={style.item.dateContainer.new}>{t("notifications.new")}</Typography>
          )}
          <Typography sx={style.item.dateContainer.date}>{formatDate(date)}</Typography>
        </Box>

        {expanded ? (
          <>
            <Typography
              sx={style.item.message}
              dangerouslySetInnerHTML={{ __html: stringMessage }}
            />
            <Typography component="button" sx={style.item.btnCollapse} onClick={handleToggleExpand}>
              {t("notifications.collapse")} <ArrowAccordion />
            </Typography>
          </>
        ) : (
          <>
            {allowableNotificationLength ? (
              <Typography
                sx={style.item.message}
                dangerouslySetInnerHTML={{ __html: stringMessage }}
              />
            ) : (
              <>
                <Typography
                  sx={style.item.message}
                  dangerouslySetInnerHTML={{ __html: shortenMessage(stringMessage) }}
                />
                <Typography
                  component="button"
                  sx={style.item.btnExpand}
                  onClick={handleToggleExpand}
                >
                  {t("notifications.expand")} <ArrowAccordion />
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default function PopupNotifications({
  messages,
  closeModal,
  modalRef,
  deleteAllNotification
}) {
  const { t } = useTranslation();

  return (
    <Box sx={style.container} ref={modalRef}>
      <Box sx={style.modal}>
        <Box style={style.titleContainer}>
          <Typography sx={style.titleContainer.title}>{t("notifications.title")}</Typography>
          <IconButton
            sx={style.titleContainer.btnClose}
            aria-label="close-notifications"
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: "1.2rem" }} />
        {messages.length > 0 ? (
          <>
            <Box component="ul" sx={style.list}>
              {messages.map((item) => (
                <Item item={item} key={item.id} />
              ))}
            </Box>
            <Divider />
            <Box sx={style.container.footer}>
              <Typography
                sx={style.container.footer.btnClear}
                component="button"
                onClick={deleteAllNotification}
              >
                {t("notifications.clear")}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography sx={style.noNotifications}>{t("notifications.noNotifications")}</Typography>
        )}
      </Box>
    </Box>
  );
}
