import dayjs from "dayjs";

export const getTableCopyText = (cols, row) => {
  const cells = cols.map((col) => col.key);
  const rowCols = Object.entries(row).filter(([key]) => cells.includes(key));

  const copy = rowCols
    .map(([key, value]) => {
      const label = cols.find((col) => col.key === key)?.label;
      return `${label}: ${value}`;
    })
    .join("\n");

  return copy;
};

export const getTableCollapsedText = (collapsed) =>
  Object.entries(collapsed)
    .map(([_, val]) => val)
    .join(" : ");

export const getDate = (dateStr) => (dateStr ? dayjs(dateStr).format("DD.MM.YYYY") : null);

export const defineStatus = (status, t) => {
  switch (status) {
    case "ACTIVE":
      return t.cabinet.orders.status.active;
    default:
      break;
  }
};
