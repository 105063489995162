import { memo, useEffect } from "react";
import { useActions } from "../../hooks";
import { useSelector } from "react-redux";
import { getUserEmail, getFingerprintVisitorId } from "../../store/selectors";

const PaymentSystemsLoader = () => {
  const { getPaymentOptions } = useActions();
  const userEmail = useSelector(getUserEmail);
  const fingerprintVisitorId = useSelector(getFingerprintVisitorId);

  useEffect(() => {
    const timer = setTimeout(() => {
      getPaymentOptions({
        email: userEmail || "",
        fingerprint: fingerprintVisitorId
      });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userEmail, fingerprintVisitorId, getPaymentOptions]);

  return null;
};

export default memo(PaymentSystemsLoader);
