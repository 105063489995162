import { Tooltip } from "@mui/material";

export default function TooltipCustom({ opened, title }) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: "1.2rem !important",
            maxWidth: 150,
            "& .MuiTooltip-arrow": {
              transform: "translate(10px, 0px) !important"
            }
          }
        }
      }}
      arrow
      placement="bottom-start"
      open={opened}
      title={title}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10]
              }
            }
          ]
        }
      }}
    >
      <div></div>
    </Tooltip>
  );
}
