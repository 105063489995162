import { appTheme } from "../../../../theme";
import { fluid } from "../../../../assets/styles/functions/fluid";

export const style = {
  wrapper: {
    paddingTop: fluid(7, 4),
    paddingBottom: fluid(7, 4),

    "& .heading":{
    }
  },
  description: {
    paddingTop: "16px",

    fontWeight: '400',
    fontSize: '1.6rem',
    lineHeight: '160%',
    color: 'var(--clr-text-400)',
    [appTheme.breakpoints.down("bp576")]: {
      fontSize: "1.4rem"
    }
  },
  content: {
    paddingTop: "40px",

    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridGap: "24px",
    [appTheme.breakpoints.down('bp767')]: {
      gridTemplateColumns: "1fr",
    }
  }
};
