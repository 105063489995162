import React, { forwardRef, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import FormCaptcha from "../../common/form-captcha/form-captcha";
import { CF_TOKEN_FIELD_NAME } from "../../../utils/constants";
import { styles } from "../form.styles";

export const Captcha = forwardRef((props, ref) => {
  const formContext = useFormContext();


  useEffect(() => {
    formContext.register(CF_TOKEN_FIELD_NAME, { value: "" });
  });
  const handlerCaptchaSuccess = (token) => {
    if (token) {
      formContext.setValue(CF_TOKEN_FIELD_NAME, token);
    }
  };

  return (
    <FormCaptcha
      ref={ref}
      onSuccess={handlerCaptchaSuccess}
      className="form-item"
      {...props}
    />
  );
});
