import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Pagination } from "../../pagination/pagination";

import { PaginationDropdown } from "./pagination-dropdown";
// import "./pagination.scss";
import { style } from "./pagination.style";

export const TablePagination = ({
  scrollRef,
  totalElements,
  totalPages,
  currentPage,
  elementsOnPage,
  outputCountHandler,
  onPageChange,
  pageSize
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={style.pagination} className="table-pagination">
      <Pagination
        pageSize={pageSize}
        totalCount={totalElements}
        scrollRef={scrollRef}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={onPageChange}
      />
      <Box sx={style.paginationOptions} className="table-pagination__options">
        <PaginationDropdown
          pageSize={pageSize}
          totalElements={totalElements}
          handler={outputCountHandler}
        />
        <Box sx={style.paginationOptions.info} className="table-pagination__options-info">
          {/* сделть так чтобы цифра "1" менялась в зависимости от страницы */}
          {t("shared.table.show")}: {!currentPage ? 1 : currentPage * pageSize + 1}-
          {totalPages !== currentPage ? (currentPage + 1) * elementsOnPage : totalElements} из{" "}
          {totalElements}
        </Box>
      </Box>
    </Box>
  );
};
