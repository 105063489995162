import { Box, Skeleton } from "@mui/material";
import { style } from "./blog-card-skeleton.style";

export const BlogCard = ({ customStyles = {} }) => (
  <Box sx={[style.item, customStyles]}>
    <Skeleton variant="rounded" sx={style.item.date} />
    <Box sx={style.item.paragraph}>
      <Skeleton variant="rounded" className="string" />
      <Skeleton variant="rounded" className="string" />
    </Box>

    <Skeleton variant="rounded" sx={style.item.link} />
  </Box>
);
