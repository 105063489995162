import { appTheme } from "../../../theme";

export const style = {

  mobileMenu: {
    '.mobile-menu__content_anim_open': {
      '&-enter': {
        borderRadius: '3rem',
        transform: 'translateY(110%)',
      },

      '&-enter-active': {
        borderRadius: '0',
        transform: 'translateY(0)',
        transition: 'all var(--transition)',
      },

      '&-exit-active': {
        borderRadius: '3rem',
        transform: 'translateY(110%)',
        transition: 'all var(--transition)',
      }
    },

    '.mobile-menu__item_anim_in': {
      '&-enter': {
        transform: 'translateX(110%)',
      },

      '&-enter-active': {
        transform: 'translateX(0)',
        transition: 'all var(--transition) ease',
      },

      '&-exit': {
        transform: 'translateX(0)',
      },

      '&-exit-active': {
        transform: 'translateX(-110%)',
        transition: 'all var(--transition) ease,'
      }
    },

    '.mobile-menu__item_anim_out': {
      '&-enter': {
        transform: 'translateX(-110%)',
      },

      '&-enter-active': {
        transform: 'translateX(0)',
        transition: 'all var(--transition) ease',
      },

      '&-exit': {
        transform: 'translateX(0)',
      },

      '&-exit-active': {
        transform: 'translateX(110%)',
        transition: 'all var(--transition) ease',
      }
    },

    '.mobile-menu__footer_anim_in': {
      '&-enter': {
        opacity: '0',
        transform: 'translateX(110%)',
      },

      '&-enter-active': {
        opacity: '1',
        transform: 'translateX(0)',
        transition: 'all var(--transition) ease',
      },

      '&-exit': {
        opacity: '0',
        transform: 'translateX(0)',
      },

      '&-exit-active': {
        opacity: '1',
        transform: 'translateX(110%)',
        transition: 'all calc(var(--transition) - 0.15s) ease',
        // transition: 'all var(--transition) ease',
      }
    },

    '.mobile-menu__footer_anim_out': {
      '&-enter': {
        opacity: '0',
        transform: 'translateY(110%)',
      },

      '&-enter-active': {
        opacity: '1',
        transform: 'translateY(0)',
        transition: 'all var(--transition) ease',
      },

      '&-exit': {
        opacity: '0',
        transform: 'translateY(0)',
      },

      '&-exit-active': {
        opacity: '1',
        transform: 'translateY(110%)',
        transition: 'all calc(var(--transition) - 0.15s) ease',
        // transition: 'all var(--transition) ease',
      }
    }
  },

  button: {
    position: 'fixed',
    left: '1rem',
    bottom: '1rem',
    zIndex: '20',
    borderRadius: '50%',
    width: '5.2rem',
    height: '5.2rem',
    boxShadow: '0px 10px 20px rgba(71, 155, 246, 0.4)',
    backgroundImage: 'linear-gradient(97.86deg, #517ff5 0%, #4ba7ea 103.85%)',
    cursor: 'pointer',

    span: {
      display: 'block',
      borderRadius: '0.2rem',
      backgroundColor: 'var(--clr-default-100)',
    },

    burger: {
      position: 'absolute',
      width: '100%',
      height: '100%',

      span: {
        position: 'relative',
        left: '50%',
        top: 'calc(50% - 8px)',
        width: '2.2rem',
        height: '0.2rem',
        transform: 'translate(-50%, -50%)',
        transition: 'width var(--transition)',

        '&:not(:last-child)': {
          marginBottom: '0.7rem',
        },

        '&:nth-of-type(1)': {
          transitionDelay: '0.5s',
        },
        '&:nth-of-type(2)': {
          transitionDelay: '0.6s',
        },
        '&:nth-of-type(3)': {
          transitionDelay: '0.7s',
        }
      }
    },

    cross: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: 'rotate(45deg)',

      span: {
        transition: 'width var(--transition), height var(--transition)',

        '&:nth-of-type(1)': {
          position: 'absolute',
          left: '50%',
          top: '50%',
          width: '0.2rem',
          height: '0%',
          transform: 'translate(-50%, -50%)',
          transitionDelay: '0s',
        },
        '&:nth-of-type(2)': {
          position: 'absolute',
          left: '50%',
          top: '50%',
          width: '0%',
          height: '0.2rem',
          transform: 'translate(-50%, -50%)',
          transitionDelay: '0.3s',
        }
      }
    },

    open: {
      '.mobile-menu__button-burger': {
        span: {
          width: '0%',

          '&:nth-of-type(1)': {
            transitionDelay: '0s',
          },

          '&:nth-of-type(2)': {
            transitionDelay: '0.1s',
          },

          '&:nth-of-type(3)': {
            transitionDelay: '0.2s',
          }
        }
      },

      '.mobile-menu__button-cross': {
        span: {
          '&:nth-of-type(1)': {
            height: '50%',
            transitionDelay: '0.7s',
          },
          '&:nth-of-type(2)': {
            width: '50%',
            transitionDelay: '0.5s',
          }
        }
      }
    }
  },

  content: {
    position: 'fixed',
    left: '0',
    bottom: '0',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
  },

  body: {
    position: 'relative',
    flexGrow: '1',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
  },

  item: {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    padding: '3rem 2rem 3.7rem 2rem',
    width: '100%',
  },

  section: {
    '&:not(:last-child)': {
      marginBottom: '2.5rem',
    }
  },

  title: {
    display: 'inline-block',
    marginBottom: '1.6rem',
    fontWeight: '400',
    fontSize: '1.4rem',
    color: 'var(--clr-text-300)',
  },

  tab: {
    cursor: "pointer",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.8rem 0',
    fontWeight: '500',
    fontSize: '1.4rem',
    color: 'var(--clr-text-900)',

    '&::after': {
      content: '""',
      display: 'block',
      marginLeft: '1rem',
      width: '1rem',
      height: '1rem',
      backgroundImage: 'url("/images/ui/caret-right.svg")',
      backgroundRepeat: 'no-repeat',
    }
  },

  link: {
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      padding: '0.8rem 0',
      fontWeight: '500',
      fontSize: '1.4rem',
      color: 'var(--clr-text-900)',
    },

    icon: {
      marginRight: '1rem',
      width: '1.8rem',
      height: '1.8rem',

      '& > svg': {
        width: '100%',
        height: '100%',
        fill: 'var(--clr-text-900)',
        objectFit: 'contain',
      }
    }
  },

  proxy: {
    list: {
      display: 'grid',
      gridGap: '2rem',
    },

    link: {
      display: 'flex',
      alignItems: 'center',
    },

    image: {
      flexShrink: '0',
      marginRight: '1.4rem',

      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: "50%",
        boxShadow: "0 7px 15px 0px var(--clr-ui-shadow-100)"
      }
    },

    label: {
      overflow: 'hidden',
      fontWeight: '400',
      fontSize: '1.4rem',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: 'var(--clr-text-900)',
    },

    buy: {
      '.mobile-menu__proxy-list': {
        gridGap: '2rem',
        gridTemplateColumns: 'repeat(4, 1fr)',
        filter: 'drop-shadow(0 0.7rem 1.5rem rgba(167, 173, 183, 0.35))',

        // @media (max-width: em(767)) {
        [appTheme.breakpoints.down('bp767')]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(13rem, 1fr))',
        },

        // @media (max-width: em(576)) {
        [appTheme.breakpoints.down('bp576')]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(12.8rem, 1fr))',
        }
      },

      '.mobile-menu__proxy-image': {
        width: '2.8rem',
        height: '2.8rem',
      }
    },

    for: {
      '.mobile-menu__proxy-list': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },

      '.mobile-menu__proxy-image': {
        width: '1.8rem',
        height: '1.8rem',
      }
    }
  },

  footer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 2rem',
    minHeight: '7.2rem',

    anim: {

    }
  },

  back: {
    display: 'block',
    fontWeight: '500',
    fontSize: '1.6rem',
    color: 'var(--clr-text-900)',
  },

  right: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',

    '.cabinet-button': {
      marginRight: '1.5rem',
    }
  }
}
