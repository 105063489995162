import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { defineErrorText } from "../../../../utils/helpers";
import { style } from "../order-modal.style";

const SummarySection = (props) => {
  const { priceObj } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    if (!priceObj.success && priceObj.error) {
      enqueueSnackbar(defineErrorText(priceObj.error), { variant: "error" });
    }
  }, [priceObj.success]);

  //  const currencyCode =  priceObj?.currencyCodeInMainCurrency || priceObj?.currencyCode || "USD"
  return (
    <>
      {/* {!!priceObj?.error && <Box component="span" sx={style.info.error}>{priceObj?.error}</Box>} */}
      <Box sx={style.info.group}>
        <Box component="span" sx={style.info.label}>
          {t("other.price")}
        </Box>
        <Box component="span" sx={style.info.value}>
          1 {t("other.piece")}/$
          {priceObj?.priceForOneWithDiscountInMainCurrency ||
            priceObj?.priceForOneWithDiscount ||
            "0"}{" "}
        </Box>
      </Box>
      <Box sx={style.info.group}>
        <Box component="span" sx={style.info.label}>
          {t("other.totalSum")}
        </Box>
        <Box component="span" sx={style.info.value}>
          ${priceObj?.totalPriceInMainCurrency || priceObj?.totalPrice || "0"}
        </Box>
      </Box>
      <Box sx={style.info.group}>
        <span>{t("other.discount")}</span>
        <span>{priceObj?.totalDiscountInPercent || "0"}%</span>
      </Box>
    </>
  );
};

export default SummarySection;
