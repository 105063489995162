import { Children, createContext, useCallback, useMemo } from "react";

import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSearchParams } from "react-router-dom";

import { TabsDropdown } from "./tabs-dropdown";
import { TabsList } from "./tabs-list";
import { style } from "./tabs.style";

export const TabsContext = createContext();

export const Tabs = ({
  tabs,
  name = "tab",
  align = "center",
  direction = "horizontal",
  size = "default",
  mobileViewBreakpoint = 370,
  fullwidth,
  justify,
  onChange,
  linkCallback,
  children
}) => {
  const [params, setParams] = useSearchParams();

  const searchTab = params.get(name);

  const getActiveTab = useCallback(() => {
    const defaultTab = tabs?.find((tab) => tab.key === searchTab);

    if (defaultTab) {
      return defaultTab?.key;
    }

    return tabs[0]?.key;
  }, [tabs, searchTab]);

  const activeTab = getActiveTab();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`bp${mobileViewBreakpoint}`));

  const handleTab = (key) => {
    setParams({
      ...Object.fromEntries(params),
      [name]: key
    });

    if (onChange) {
      onChange(key);
    }
  };

  const currentContent = useMemo(() => {
    const currentContentIndex = tabs?.findIndex((tab) => tab.key === activeTab);
    const indexToUse = currentContentIndex >= 0 ? currentContentIndex : 0;
    const content = Children.toArray(children);
    return name === "geoTabs"
      ? content.map((child, i) => (
          <Box sx={i === indexToUse ? null : style.hiddenElement} key={child.key || i}>
            {child}
          </Box>
        ))
      : content[currentContentIndex] || content[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, children, tabs]);

  const renderClasses = () => {
    // let classes = "tabs";
    const classes = [];
    let classNames = "tabs";

    if (!isMobile) {
      switch (direction) {
        case "horizontal":
          classNames += " tabs_direction_horizontal";
          classes.push(style.directionHorizontal);
          break;
        case "vertical":
          classNames += " tabs_direction_vertical";
          classes.push(style.directionVertical);
          break;
        default:
          break;
      }
    }

    switch (size) {
      case "default":
        classNames += " tabs_size_default";
        classes.push(style.sizeDefault);
        break;
      case "small":
        classNames += " tabs_size_small";
        classes.push(style.sizeSmall);
        break;
      case "medium":
        classNames += " tabs_size_medium";
        classes.push(style.sizeMedium);
        break;
      case "large":
        classNames += " tabs_size_large";
        classes.push(style.sizeLarge);
        break;
      default:
        break;
    }

    switch (align) {
      case "left":
        classNames += " tabs_align_left";
        classes.push(style.alignMod.left);
        break;
      case "center":
        classNames += " tabs_align_center";
        classes.push(style.alignMod.center);
        break;
      default:
        break;
    }

    // if (children) classes += " tabs_with_content";
    if (children) {
      classes.push(style.withContentMod);
      classNames += " tabs_with_content";
    }
    if (fullwidth || isMobile) {
      classes.push(style.fullwidthMod);
      classNames += " tabs_fullwidth";
    }
    if (justify) {
      classes.push(style.justifyMod);
      classNames += " tabs_fullwidth";
    }
    if (isMobile) {
      classes.push(style.mobileMod);
      classNames += " tabs_mobile";
    }

    return { classes, classNames };
  };

  return (
    <TabsContext.Provider
      value={{
        tabs,
        direction,
        activeTab,
        handleTab
      }}
    >
      {tabs.length ? (
        <Box sx={renderClasses().classes} className={renderClasses().classNames}>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down(`bp${mobileViewBreakpoint}`)]: {
                display: "none"
              }
            })}
          >
            <Box sx={style.tabHeader} className="tabs__header">
              {direction !== "vertical" ? (
                <TabsList tabs={tabs} linkCallback={linkCallback} />
              ) : (
                <Box className="tabs__inner">
                  <TabsList tabs={tabs} linkCallback={linkCallback} />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up(`bp${mobileViewBreakpoint}`)]: {
                display: "none"
              }
            })}
          >
            <TabsDropdown tabs={tabs} />
          </Box>
          <Box sx={style.content} className="tabs__content">
            {currentContent}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </TabsContext.Provider>
  );
};
