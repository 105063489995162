import { PerfectMoney } from "../components/payment/perfect-money/perfect-money";
import { ROUTE } from "../utils/constants";

export const paymentRoutes = (ssr = false) => [
  {
    path: `${ROUTE.PAYMENT}/:paymentId`,
    isSsr: ssr ?? false,
    tag: "home",
    component: ssr
      ? PerfectMoney
      : () => import("../components/payment/perfect-money/perfect-money")
  }
];
