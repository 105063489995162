import { useEffect } from "react";

const ExportLayout = () => {

  const proxiesForExport = JSON.parse(localStorage.getItem("proxiesForExport"));

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("proxiesForExport");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main>
      <div style={{
          wordWrap: "break-word",
          whiteSpace: "pre-wrap",
          padding: "10px"
        }}>{proxiesForExport ? proxiesForExport.join("\n") : "No data"}</div>
    </main>
  );
};

export default ExportLayout;
