import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ArrowRightIcon, ViewIcon } from "../../../components/icons/index";
import { useLang } from "../../../hooks";
import { ROUTE } from "../../../utils/constants";

import { style } from "./blogCard.style";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export const BlogCard = (props) => {
  const { tag, createdDate, title, countOfViews } = props.data;

  const lang = useLang();
  const { t } = useTranslation();

  return (
    <Box component="li" sx={style.blogCard} className="blog-card">
      <Link to={`/${lang}${ROUTE.APP.BLOG}/${tag}/`}>
        <Box sx={style.cardHeader} className="blog-card__header">
          <Box sx={style.cardDate} className="blog-card__date">
            {dayjs(createdDate).utc(false).format("DD.MM.YYYY | HH:mm")}
          </Box>
          <Box sx={style.cardViews} className="blog-card__views">
            <ViewIcon />
            {countOfViews}
          </Box>
        </Box>
        <Box sx={style.cardTitle} className="blog-card__title">
          {title}
        </Box>
        <Box sx={style.cardLink} component="span" className="blog-card__link">
          <Box component="span">{t("common.read")}</Box>
          <ArrowRightIcon />
        </Box>
      </Link>
    </Box>
  );
};
