import React, { useMemo } from "react";

import { Helmet } from "react-helmet-async";

import { HTML_TAG_REG } from "../../../utils/constants";

const ProductMetaDate = (props) => {
  const { name = "", description = "", price = 0, currency = "USD", url = "/" } = props;

  const cleanFromHtmlTags = (str) => str.replace(HTML_TAG_REG, "");
  const homeUrl = "https://youproxy.io";

  // generate random rating (range 4.5 - 4.9);
  const randomRating = useMemo(() => {
    return (Math.random() * 0.4 + 4.5).toFixed(1);
  }, []);
  // increase the number of reviews depending on the start date;
  const reviewsCountByDate = useMemo(() => {
    let initReviewsCount = 104;
    const initTimestamp = new Date("2024-08-19T00:00:00Z").getTime();
    const currentTimestamp = Date.now();
    const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000;
    const monthsPassed = Math.floor((currentTimestamp - initTimestamp) / millisecondsIn30Days);
    if (monthsPassed <= 0) return initReviewsCount;
    initReviewsCount *= 1.03 ** monthsPassed;
    return Math.round(initReviewsCount);
  }, []);
  // get next year for priceValidUntil param;
  const nextYearValue = useMemo(() => {
    const today = new Date();
    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);
    return nextYear.toISOString().split("T")[0];
  }, []);

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
    {
     "@context":"http://schema.org",
     "@type":"Product",
     "image": ["${homeUrl}/images/favicons/apple-touch-icon.png"],
     "name": "${cleanFromHtmlTags(name)}",
     "description": "${cleanFromHtmlTags(description)}",
     "brand": {
       "@type": "Brand",
       "name": "Youproxy"
     },
     "sku": 54535,
     "mpn": 12575,
     "aggregateRating":{
       "@type":"AggregateRating",
       "ratingValue": ${randomRating},
       "reviewCount": ${reviewsCountByDate},
       "bestRating": 5
     },
     "review": {
       "@type": "Review",
       "reviewRating": {
         "@type": "Rating",
         "ratingValue": ${randomRating},
         "bestRating": 5
       },
       "author": {
         "@type": "Person",
         "name": "Smith ${cleanFromHtmlTags(name).split(" ")[1]}"
       }
     },
     "offers": {
       "@type": "Offer",
       "url": "${homeUrl}${url}",
       "priceCurrency": "${currency}",
       "price": ${price},
       "priceValidUntil": "${nextYearValue}",
       "availability": "https://schema.org/InStock",
       "itemCondition": "https://schema.org/NewCondition"
     }
    }
  `}
      </script>
    </Helmet>
  );
};

export default ProductMetaDate;
