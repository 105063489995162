import React, { forwardRef, useState } from "react";

import { Turnstile } from "@marsidev/react-turnstile";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import { useLang } from "../../../hooks";
import { getCaptchaState } from "../../../store/selectors";
import { CAPTCHA_TYPE } from "../../../utils/constants";

const testingOnLocal = false;

const FormCaptcha = forwardRef((props, ref) => {
  const { onSuccess, ...rest } = props;

  const lang = useLang();

  const { loaded, active, type, key } = useSelector(getCaptchaState);
  const [visible, setVisible] = useState(testingOnLocal);

  if (!loaded || !active || !type || !key) {
    return null;
  } else if (process.env.NODE_ENV === "development" && !testingOnLocal) {
    console.warn("Captcha is disabled in dev mode! This message will show only in dev mode.");

    return null;
  }

  const handlerError = (e) => {
    console.log("Captcha error: ", e);
    setVisible(true);
  };

  const handlerSuccess = (t) => {
    onSuccess(t);
  };

  if (type === CAPTCHA_TYPE.CLOUDFLARE) {
    const style = visible
      ? {
          ...(rest?.sx ?? {}),
          width: "auto!important",
          overflow: "hidden !important",

          "& > iframe": {
            position: "relative !important",
            width: "100% !important",
            height: "auto !important",
            visibility: "visible !important"
          }
        }
      : {
          display: "none"
        };

    return (
      <Box
        component={Turnstile}
        ref={ref}
        siteKey={key}
        onError={handlerError}
        onExpire={() => ref?.current?.reset()}
        onSuccess={handlerSuccess}
        options={{
          theme: "light",
          size: visible ? "normal" : "invisible",
          language: lang
        }}
        scriptOptions={{
          appendTo: "body"
        }}
        {...rest}
        sx={style}
      />
    );
  } else {
    return null;
  }
});

export default FormCaptcha;
