import { appTheme } from "../../../theme";

export const style = {
  description: {
    display: "flex",
    paddingTop: 8,
    fontSize: "1.6rem",
    [appTheme.breakpoints.down("bp576")]: {
      fontSize: "1.4rem"
    },
    "& > span": {
      marginRight: "0.8rem"
    },

    
  },
  descriptionIcon: {
    marginTop: -3,
    marginRight: ".5rem"
  },
  developersApi: {
    position: "relative",
    paddingBottom: "1rem",
    '.heading': {
      marginBottom: '4rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      }
    }
  },

  form: {
    marginBottom: "21px",
    "& .form-item__input": {
      fontSize: 16
    },
    button: {
      marginTop: 0,
      height: "fit-content"
    }
  },
  formRow: {
    display: "grid",
    gridGap: "12px",
    marginBottom: "15px",
    gridTemplateColumns: "8fr 4fr",

    [appTheme.breakpoints.down('bp576')]: {
      gridTemplateColumns: "1fr",
      marginBottom: "24px",
    }
  },
  actionButton: {
    display: "flex"
  },
  documentation: {
    display: "flex",
    alignItems: "center",
    textWrap: "nowrap",
    "& > span": {
      fontSize: "20px",
      fontWeight: 600,
      color: "var(--clr-text-500)",
      marginRight: "1.6rem"
    },
    [appTheme.breakpoints.down('bp576')]: {
      alignItems: "flex-start",
      flexDirection: "column",
      "& > span": {
        marginBottom: "1.6rem",
        marginRight: "0"
      }
    }
  }
}
