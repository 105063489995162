import { appTheme } from "../../../../theme"

export const style = {
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '4.5rem',
    '.pagination': {
      '&__item': {
        border: 'none',
        borderRadius: '0.4rem',
        width: '2.2rem',
        height: '2.2rem',
        fontSize: '1.4rem',
        color: 'var(--clr-text-400)',
  
        svg: {
          width: '5.5rem',
          height: '1.1rem',
          stroke: 'var(--clr-text-400)',
          objectFit: 'contain',
        },
  
        '&_active': {
          color: 'var(--clr-text-100)',
          backgroundImage: 'linear-gradient(97.86deg, #517ff5 0%, #4ba7ea 103.85%)',
        }
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },

  paginationOptions: {
    display: 'flex',
    alignItems: 'center',
    info: {
      marginLeft: '2.5rem',
      paddingTop: '0.2rem',
      fontSize: '1.4rem',
      color: 'var(--clr-text-400)',
    },

    [appTheme.breakpoints.down('bp565')]: {
      marginTop: '3rem'
    }
  },

  paginationDropdown: {
    position: 'relative',
    border: '0.1rem solid var(--clr-ui-border-300)',
    borderRadius: '0.7rem',
    width: '7rem',
    backgroundColor: 'var(--clr-default-400)',
    current: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.4rem 1.2rem',
      width: '100%',
      minHeight: '3rem',
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.4rem',
      color: 'var(--clr-text-400)',

      label: {
        overflow: 'hidden',
        fontWeight: '400',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: 'var(--clr-text-400)',
      },

      '& > svg': {
        marginTop: '0.2rem',
        marginLeft: 'auto',
        width: '0.8rem',
        height: '0.8rem',
        stroke: 'var(--clr-text-400)',
        objectFit: 'contain',
        transition: 'transform var(--transition)',
      }
    },

    item: {
      padding: '0.8rem 1.2rem',
      fontWeight: '400',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.4rem',
      color: 'var(--clr-text-400)',
      width: '100%',

      active: {
        backgroundColor: 'var(--clr-default-300)',
        pointerEvents: 'none',
      }
    },

    body: {
      position: 'absolute',
      left: '50%',
      top: '115%',
      zIndex: '999',
      overflow: 'hidden',
      borderRadius: '1.2rem',
      width: 'calc(100% + 0.2rem)',
      boxShadow: '0px 2px 22px rgba(95, 103, 128, 0.14)',
      backgroundColor: 'var(--clr-default-100)',
      opacity: '0',
      visibility: 'hidden',
      transform: 'translate(-50%, 1rem)',
      transition: 'opacity var(--transition), visibility var(--transition), transform var(--transition)',
    },

    list: {
      overflowX: 'hidden',
      overflowY: 'auto',
      maxHeight: '15rem',
    },

    active: {
      '.table-pagination__dropdown': {
        '&-current': {
          '& > svg': {
            transform: 'rotate(180deg),'
          }
        },

        '&-body': {
          opacity: '1',
          visibility: 'visible',
          transform: 'translate(-50%, 0)'
        }
      }
    }
  }
}