import React from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import ShortInfoElement from "../short-info-element/short-info-element";

import { style } from "./short-info-block.style";

// import { useTranslation } from "../../../../hooks";

const ShortInfoBlock = (props) => {
  const { ip, anonymity } = props;

  const { t } = useTranslation();

  const hasBothIps = !!(ip.v4 && ip.v6);

  return (
    <Box sx={style.container}>
      <ShortInfoElement
        loading={ip.loading}
        imageUrl="/images/icons/ip-info/ip.svg"
        label={t("shared.tools.yourIp")}
        sx={
          ip.loading
            ? {}
            : hasBothIps
            ? style.ip.container
            : {
                alignItems: "flex-end",
                "&:not(:last-child)": { marginRight: "5.6rem" }
              }
        }
      >
        {hasBothIps ? (
          <Box sx={ip.loading ? {} : style.ip.content}>
            <Box>IPv4: {ip.v4 ?? "-"}</Box>
            <Box>IPv6: {ip.v6 ?? "-"}</Box>
          </Box>
        ) : (
          ip.v4 || ip.v6 || t("common.noData")
        )}
      </ShortInfoElement>
      <ShortInfoElement
        loading={anonymity.loading}
        imageUrl="/images/icons/ip-info/incognito.svg"
        label={t("shared.tools.yourAnonymity")}
        sx={{
          alignItems: "flex-end"
        }}
      >
        {anonymity.percent ? `${anonymity.percent}%` : t("common.noData")}
      </ShortInfoElement>
    </Box>
  );
};

export default ShortInfoBlock;
