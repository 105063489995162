import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../services/api.service";

export const getReviewsByPage = createAsyncThunk(
  "reviews/getReviewsByPage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ApiService.getReviewsByPage(values);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
