import React from "react";
import * as Icons from "../../icons"

const DynamicIcon = ({ iconName, ...rest }) => {
  const Icon = Icons[iconName];

  return (Icon ? <Icon {...rest} /> : null)
};

export default DynamicIcon;
