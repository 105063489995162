import { Box } from "@mui/material";

export const CustomLabel = ({ htmlFor, children, ...other }) => {

  const onClick = () => {
    if (htmlFor) {
      document.getElementById(htmlFor).dispatchEvent(
        // eslint-disable-next-line no-undef
        new MouseEvent("mousedown", {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })
      );
    }
  };

  return <Box component="label" htmlFor={htmlFor} onClick={onClick} {...other}>{children}</Box>;
};
