import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";

const Link = forwardRef((props, ref) => {
  const { to, children, ...rest } = props;

  if (to.startsWith("http") || to.startsWith("https")) {
    return (
      <Box component="a" href={to} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </Box>
    );
  } else {
    return (
      <RouterLink ref={ref} to={to} {...rest}>
        {children}
      </RouterLink>
    );
  }
});

export default Link;
