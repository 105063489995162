import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  proxySeo: {
    paddingTop: fluid(7, 4),
    paddingBottom: fluid(7, 4)
  },
  seoBlockDefault: {
    "&": {
      fontSize: fluid(1.6, 1.5)
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: "2rem",
      fontWeight: "700",
      fontSize: fluid(3.6, 2.2),
      color: "var(--clr-text-600)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "2.5rem"
      }
    },

    "& p": {
      marginBottom: "1.5rem",
      fontWeight: "400",
      lineHeight: "160%",
      color: "var(--clr-text-400)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "2rem"
      }
    },

    "ul, ol": {
      marginBottom: "2rem",

      li: {
        position: "relative",
        marginBottom: "1rem",
        paddingLeft: "2rem",
        fontWeight: "400",
        lineHeight: "157%",
        color: "var(--clr-text-400)"
      },

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "2.5rem"
      }
    },

    ul: {
      "li::before": {
        content: `""`,
        position: "absolute",
        left: "0.5rem",
        top: "0.9rem",
        borderRadius: "50%",
        width: "0.3rem",
        height: "0.3rem",
        backgroundColor: "var(--clr-text-400)"
      }
    },

    ol: {
      counterReset: "section",

      "li::before": {
        counterIncrement: "section",
        content: 'counters(section, ".") "." !important',
        position: "absolute",
        left: "0.5rem",
        color: "var(--clr-text-400)"
      }
    }
  },

  seoBlockTools: {
    "&": {
      fontSize: fluid(1.6, 1.5)
    },
    "h1, h2": {
      marginBottom: "2rem",
      fontWeight: "600",
      fontSize: "2rem",
      color: "var(--clr-text-500)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "1.5rem"
      }
    },

    "h3, h4, h5, h6": {
      marginTop: "3rem",
      marginBottom: "1.5rem",
      fontWeight: "500",
      fontSize: "1.8rem",
      lineHeight: "133%",
      color: "var(--clr-text-400)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "1rem"
      }
    },

    "& p": {
      marginBottom: "1.5rem",
      fontWeight: "400",
      lineHeight: "160%",
      color: "var(--clr-text-400)",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "1rem"
      }
    },

    "ul, ol": {
      marginBottom: "1.5rem",

      li: {
        position: "relative",
        marginBottom: "1rem",
        paddingLeft: "2rem",
        fontWeight: "400",
        lineHeight: "157%",
        color: "var(--clr-text-400)"
      },

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "1rem"
      }
    },

    ul: {
      "li::before": {
        content: `""`,
        position: "absolute",
        left: "0.5rem",
        top: "0.9rem",
        borderRadius: "50%",
        width: "0.3rem",
        height: "0.3rem",
        backgroundColor: "var(--clr-text-400)"
      }
    },

    ol: {
      counterReset: "section",

      "li::before": {
        counterIncrement: "section",
        content: 'counters(section, ".") "." !important',
        position: "absolute",
        left: "0.5rem",
        color: "var(--clr-text-400)"
      }
    }
  },

  seoBlockArticle: {},

  seoBlockInfoPage: {}
};
