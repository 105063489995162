import { startTransition, useEffect } from "react";

import { useActions } from "../../hooks";
import { UserService } from "../../services";

const AuthorizationCheck = () => {
  const { setUser, filterCountriesInProxy, filterMobileCountries } = useActions();

  const checkAuthorization = async () => {
    UserService.authorizationCheck().then(async (res) => {
      const { auth, countryIpCode } = res.data;
      if (!auth) {
        setUser(null);
      }
      filterCountriesInProxy(countryIpCode);
      filterMobileCountries(countryIpCode);
    });
  };
  useEffect(() => {
    checkAuthorization();
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      startTransition(() => setUser(user));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AuthorizationCheck;
