import { Box, Skeleton } from "@mui/material";
import { BlogCard } from "../blog-card-skeleton/blog-card-skeleton";
import { style } from "./article-skeleton.style";

const ArticleParagraph = ({ rows = 6, hasHeading = true, hasImage = false }) => (
  <Box sx={style.articleSection}>
    {hasHeading && <Skeleton variant="rounded" sx={style.heading} />}
    <Box sx={style.paragraph}>
      {Array.from(Array(rows).keys()).map((el) => <Skeleton variant="rounded" key={el} className="string" />)}
    </Box>
    {hasImage && <Skeleton variant="rounded" sx={style.image} />}
  </Box>
);

const SidebarEntity = () => (
  <Box sx={style.sidebarEntity}>
    <Skeleton variant="runded" sx={style.sidebarEntity.heading} />
    <BlogCard customStyles={style.sidebarEntity.card} />
    <BlogCard customStyles={style.sidebarEntity.card} />
  </Box>
);

export const ArticleSkeleton = () => (
  <Box sx={style.container}>
    <Box sx={style.content}>
      <Skeleton variant="rounded" sx={style.content.title} />
      <Box sx={style.content.infoRow}>
        <Skeleton variant="rounded" sx={style.content.date} />
        <Skeleton variant="rounded" sx={style.content.views} />
      </Box>

      <ArticleParagraph hasHeading={false} />
      <ArticleParagraph hasImage={true} />
      <ArticleParagraph />

    </Box>
    <Box sx={style.sidebar}>
      <SidebarEntity />
      <SidebarEntity />
    </Box>
  </Box>
);
