import CheckEvercookie from "../pages/tools/check-evercookie/check-evercookie";
import CheckWebrtc from "../pages/tools/check-webrtc/check-webrtc";
import Fingerprint from "../pages/tools/fingerprint-scanner/fingerprint-scanner";
import IpTracing from "../pages/tools/ip-tracing/ip-tracing";
import MyAnonymity from "../pages/tools/my-anonymity/my-anonymity";
import MyIp from "../pages/tools/my-ip/my-ip";
import PingIp from "../pages/tools/ping-ip/ping-ip";
import PortScanner from "../pages/tools/port-scanner/port-scanner";
import ProxyChecker from "../pages/tools/proxy-checker/proxy-checker";
import { ROUTE } from "../utils/constants";

export const toolsRoutes = (ssr = false) => [
  {
    path: `/:lang?${ROUTE.TOOLS.MY_IP}`,
    tag: "my-ip",
    component: ssr ? MyIp : () => import("../pages/tools/my-ip/my-ip"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.PROXY_CHECKER}`,
    tag: "proxy-checker",
    component: ssr ? ProxyChecker : () => import("../pages/tools/proxy-checker/proxy-checker"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.PORT_SCANNER}`,
    tag: "port-scanner",
    component: ssr ? PortScanner : () => import("../pages/tools/port-scanner/port-scanner"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.PING_IP}`,
    tag: "ping-ip",
    component: ssr ? PingIp : () => import("../pages/tools/ping-ip/ping-ip"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.MY_ANONYMITY}`,
    tag: "my-anonymity",
    component: ssr ? MyAnonymity : () => import("../pages/tools/my-anonymity/my-anonymity"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.FINGERPRINT_SCANNER}`,
    tag: "fingerprint-scanner",
    component: ssr
      ? Fingerprint
      : () => import("../pages/tools/fingerprint-scanner/fingerprint-scanner"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.CHECK_EVERY_COOKIE}`,
    tag: "check-evercookie",
    component: ssr
      ? CheckEvercookie
      : () => import("../pages/tools/check-evercookie/check-evercookie"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.CHECK_WEBRTC}`,
    tag: "check-webrtc",
    component: ssr ? CheckWebrtc : () => import("../pages/tools/check-webrtc/check-webrtc"),
    isSsr: ssr ?? false
  },
  {
    path: `/:lang?${ROUTE.TOOLS.IP_TRACING}`,
    tag: "ip-tracing",
    component: ssr ? IpTracing : () => import("../pages/tools/ip-tracing/ip-tracing"),
    isSsr: ssr ?? false
  }
];
