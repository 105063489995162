export const CustomCheckIcon = ({ isChecked = false }) => !isChecked ? (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(.clip0_394_13615)">
      <rect x="0.5" width="20" height="20" rx="4" fill="white" />
      <rect x="1" y="0.5" width="19" height="19" rx="3.5" stroke="#BDBCDB" />
    </g>
    <defs>
      <clipPath className="clip0_394_13615">
        <rect x="0.5" width="20" height="20" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
) : (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="20" height="20" rx="4" fill="#517FF5" />
    <path d="M15.8334 6L8.50008 13.3333L5.16675 10" stroke="white" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round" />
  </svg>
);
