import { Box, ButtonBase } from "@mui/material";
import { useRef, useState } from "react";

import { useOnClickOutside } from "../../../hooks/use-on-click-outside";
import { style } from "./table.style";

export const TableAction = ({ children, onClick, icon, type = "default", ariaLabel }) => {
  const [isOpen, setIsOpen] = useState(false);

  const actionRef = useRef(null);

  const handleClick = () => {
    if (type === "tooltip") setIsOpen((prev) => !prev);

    if (onClick) onClick();
  };

  useOnClickOutside(actionRef, () => setIsOpen(false));

  const defineClasses = () => {
    let classes = "table-action";

    switch (type) {
      case "default":
        classes += " table-action_type_default";
        break;
      case "tooltip":
        classes += " table-action_type_tooltip";
        break;
      default:
        break;
    }

    if (isOpen) classes += " table-action_open";

    return classes;
  };

  const defineStyles = () => {
    const styles = [style.tableAction];

    switch (type) {
      case "default":
        styles.push(style.tableAction.type.default);
        break;
      case "tooltip":
        styles.push(style.tableAction.type.tooltip);
        break;
      default:
        break;
    }

    if (isOpen) styles.pudsh(style.tableAction.open);

    return styles;
  };

  return (
    <Box sx={defineStyles()} ref={actionRef} className={defineClasses()}>
      <ButtonBase sx={style.tableAction.icon} onClick={handleClick} className="table-action__icon" aria-label={ariaLabel}>
        {icon}
      </ButtonBase>

      {type === "tooltip" && children && <Box sx={style.tableAction.body} className="table-action__body">{children}</Box>}
    </Box>
  );
};
