import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  footer: {
    paddingTop: "6rem",
    paddingBottom: "6rem",
    backgroundImage: "linear-gradient(274.62deg, #131937 0.77%, #242c59 175.08%)",
    overflow: "hidden",
    ".languages": {
      paddingBottom: "1rem",
      color: "rgba(var(--clr-text-100-rgb), 90%)",

      "& .languages__label": {
        marginTop: 0
      }
    }
  },

  top: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: "7rem",

    [appTheme.breakpoints.down("bp576")]: {
      flexDirection: "column",
      marginBottom: "4rem"
    }
  },
  menu: {
    maxWidth: "50rem",
    wordBreak: "break-word",
    gridColumnGap: "6rem",
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
    marginRight: "3rem",
    [appTheme.breakpoints.down("bp1200")]: {
      gridGap: "3rem"
    },

    [appTheme.breakpoints.down("bp992")]: {
      gridGap: "4rem",
      gridTemplateColumns: "1fr",
      marginRight: "0"
    },

    [appTheme.breakpoints.down("bp576")]: {
      gridGap: "4rem 6rem",
      gridTemplateColumns: "repeat(2, 1fr)",
      marginBottom: "4rem",
      width: "100%"
    },
    [appTheme.breakpoints.down("bp370")]: {
      gridTemplateColumns: "1fr"
    }
  },

  contacts: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "9.6rem",
    [appTheme.breakpoints.down("bp1200")]: {
      columnGap: "3rem"
    },

    [appTheme.breakpoints.down("bp992")]: {
      columnGap: "2rem",
      gridTemplateColumns: "1fr"
    },

    [appTheme.breakpoints.down("bp576")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      marginBottom: "4rem",
      width: "100%",
      columnGap: "6rem"
    },

    [appTheme.breakpoints.down("bp370")]: {
      gridTemplateColumns: "1fr",
      columnGap: "4rem"
    }
  },

  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [appTheme.breakpoints.down("bp992")]: {
      display: "block"
    }
  },

  copyright: {
    marginRight: "3rem",
    fontWeight: "400",
    fontSize: fluid(1.6, 1.4),
    color: "var(--clr-text-100)",

    [appTheme.breakpoints.down("bp992")]: {
      marginRight: "0",
      marginBottom: "6rem",
      textAlign: "center"
    }
  }
};
