import { useContext } from "react";
import { CSSTransition } from "react-transition-group";

import { MobileMenuContext } from "./mobile-menu";

export const MobileMenuTransition = ({ menuKey, children }) => {
  const { activeMenu, isNext } = useContext(MobileMenuContext);

  return (
    <CSSTransition
      key={menuKey}
      in={activeMenu === menuKey}
      timeout={300}
      classNames={`mobile-menu__item_anim_${isNext ? "in" : "out"}`}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};
