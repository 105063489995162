import { fluid } from "../../../assets/styles/functions/fluid";
import { appTheme } from "../../../theme";

export const style = {
  proxyHero: {
    overflow: "hidden",
    paddingTop: fluid(14, 7),
    [appTheme.breakpoints.down("bp576")]: {
      paddingTop: fluid(16, 8)
    },
    paddingBottom: fluid(14, 6.5),
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238 246 255 / 20%) 3.11%,
      rgba(81 127 245 / 35%) 100.73%
    )`
  },
  widgetBox: {
    minHeight: "2rem",
    marginBottom: "4rem",
    [appTheme.breakpoints.down("bp576")]: {
      marginBottom: "2rem"
    }
  },
  wrapper: {
    position: "relative",
    zIndex: "0",

    "& > .heading": {
      marginBottom: "6rem",
      width: "22ch",

      [appTheme.breakpoints.down("bp576")]: {
        marginBottom: "4.4rem",
        width: "20ch"
      }
    }
  },

  decor: {
    position: "absolute",
    right: "-21%",
    top: "53%",
    zIndex: "-1",
    width: "77.2rem",
    height: "150%",
    transform: "translateY(-50%)",
    overflow: "hidden",

    "& > svg": {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      zIndex: "0"
    },

    [appTheme.breakpoints.down("bp992")]: {
      display: "none"
    },

    [appTheme.breakpoints.down("bp576")]: {
      right: "-50rem",
      display: "block",
      width: "55rem"
    }
  },

  decorAnimation: {
    container: {
      position: "relative",
      width: "100%",
      height: "557px"
    },
    labelContainer: {
      position: "absolute",
      top: 0,
      left: 0
    },
    label: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: "150px",
      height: "44px",
      textAlign: "center",
      color: "var(--clr-text-600)",
      fontWeight: 600
    }
  },

  decorBlock: {
    borderRadius: "35px",
    width: "180px",
    height: "135px",
    background: "#FFF",
    boxShadow: "0px 14px 30px 0px rgba(165, 178, 208, 0.22)",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    span: {
      marginTop: "15px",
      color: "#5F5F74",
      // fontFamily: SF Pro,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "590",
      lineHeight: "18px",
      textAlign: "center",
      "& > span": {
        color: "#517FF5"
      }
    },

    "&:first-of-type": {
      top: "200px",
      left: "135px"
    },
    "&:nth-of-type(2)": {
      top: "175px",
      left: "340px"
    },
    "&:nth-of-type(3)": {
      top: "355px",
      left: "250px"
    }
  },

  caption: {
    marginBottom: "0.8rem",
    fontWeight: "600",
    fontize: fluid(2, 1.6),
    color: "var(--clr-text-600)",

    [appTheme.breakpoints.down("bp576")]: {
      marginBottom: "1.2rem"
    }
  },

  description: {
    marginBottom: "3.5rem",
    maxWidth: "62ch",
    minHeight: "77px",
    fontWeight: "400",
    fontSize: "1.6rem",
    lineHeight: "160%",
    color: "var(--clr-text-400)",

    [appTheme.breakpoints.down("bp576")]: {
      marginBottom: "2.5rem",
      fontSize: "1.4rem"
    }
  },

  panel: {
    maxWidth: "73.5rem",
    info: {
      paddingTop: "2.5rem"
    },
    price: {
      fontWeight: 400,
      "& > span": {
        fontSize: fluid(1.4, 1.2),
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& > span:first-of-type": {
          marginRight: "0.25rem"
        }
      }
    }
  },

  formSkeleton: {
    height: "74px",
    maxWidth: 518,
    [appTheme.breakpoints.down("bp992")]: {
      height: 134,
      maxWidth: 350
    },

    // [appTheme.breakpoints.down('bp767')]: {
    //   height: 250,
    //   maxWidth: 382
    // },

    [appTheme.breakpoints.down("bp370")]: {
      height: 238,
      maxWidth: 340
    }
  }
};
