import React from "react";
import { Box } from "@mui/material";

/**
 *
 * @param {Object} props
 * @param {bp1300 | bp1200 | bp992 | bp850 | bp767 | bp576 | bp565 | bp460 | bp370} props.minWidth
 * @param {bp1300 | bp1200 | bp992 | bp850 | bp767 | bp576 | bp565 | bp460 | bp370} props.maxWidth
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 */
const MediaQuery = (props) => {
  const {minWidth, maxWidth, children, ...rest} = props;

  const styles = (theme) => {
    let style = {...rest.sx};

    if (maxWidth) {
      style = {
        ...style,
        [theme.breakpoints.up(maxWidth)]: {
          display: "none"
        }
      }
    }

    if (minWidth) {
      style = {
        ...style,
        [theme.breakpoints.down(minWidth)]: {
          display: "none"
        }
      }
    }

    return style;
  }

  return (
    <Box
      {...rest}
      sx={styles}
    >
      {children}
    </Box>
  );
};

export default MediaQuery;
