
export const FacebookIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_249_10721)">
      <path
        d="M0 8.33325C0 10.9552 1.308 13.2945 3.35325 14.8223V18L6.41775 16.3185C7.23525 16.5443 8.1015 16.6672 8.99925 16.6672C13.9695 16.6672 17.9993 12.9368 17.9993 8.334C18 3.73125 13.9703 0 9 0C4.02975 0 0 3.7305 0 8.33325H0ZM8.0505 5.99925L10.398 8.4435L14.8132 5.99925L9.89325 11.2208L7.602 8.77725L3.12975 11.2215L8.0505 5.99925Z" />
    </g>
    <defs>
      <clipPath id="clip0_249_10721">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);
