import { $api } from "./axios.service";

export class NotificationsService {
  static getAllNotifications(languageCode) {
    return $api.get("/api/front/notificationUser/all", { params: { languageCode } });
  }
  static readNotification(id) {
    return $api.get("/api/front/notificationUser/read", { params: { id } });
  }
  static deleteAllNotification() {
    return $api.delete(`/api/front/notificationUser`);
  }
}
