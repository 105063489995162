import { useEffect, useRef, useState } from "react";

import { Box, ButtonBase } from "@mui/material";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

import { BellOutlinedIcon } from "../../../components/icons/index";
import { useLang } from "../../../hooks";
import { NotificationsService } from "../../../services/notifications.service";
import { getCookie } from "../../../utils/helpers/cookie.helpers";

import PopupNotifications from "./popup-notifications";
import { style } from "./popup-notifications.style.js";
import { isValidJson } from "./utils";

export default function PopupContainer({ styleBtnNotification }) {
  const [messages, setMessages] = useState([]);
  const lang = useLang();
  const [openedModal, setOpenedModal] = useState(false);
  const token = getCookie("JSSESIONID");

  const openModal = () => {
    setOpenedModal(true);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = () => {
    setOpenedModal(false);
  };

  const getAllNotification = () => {
    NotificationsService.getAllNotifications(lang)
      .then(({ data }) => {
        const sortedArr = data.sort((a, b) => a.read - b.read);

        setMessages(sortedArr);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
      });
  };

  useEffect(() => {
    if (!openedModal) {
      getAllNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedModal, lang]);

  const stompClient = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const domain = window.location.hostname;
      const urlSocket = `https://${domain === "youproxy.io" ? "" : "stage."}youproxy.io/ws/inner`;
      const socket = new SockJS(urlSocket);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      stompClient.current = Stomp.over(socket);

      stompClient.current.debug = (msg) => {
        if (msg.includes("CONNECTED")) {
          // eslint-disable-next-line no-console
          console.log("WebSocket Connected...");
        }
      };

      stompClient.current.connect({ version: "1.2" }, () => {
        stompClient.current.subscribe(`/api/user/message/${token}`, ({ body }) => {
          const newMessage = isValidJson(body) ? !JSON.parse(body).read : "";
          if (newMessage) {
            getAllNotification();
          }
        });
      });

      return () => {
        if (stompClient.current !== null) {
          stompClient.current.disconnect();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const deleteAllNotification = () => {
    NotificationsService.deleteAllNotification().then((res) => {
      if (res.status === 200) {
        getAllNotification();
      }
    });
    closeModal();
  };
  const newMessagesAmount = messages.filter(({ read }) => !read).length;
  const btnRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !btnRef.current.contains(event.target)
      ) {
        closeModal();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closeModal, btnRef]);

  return (
    <Box sx={{ position: "relative" }}>
      <ButtonBase
        onClick={openModal}
        sx={styleBtnNotification}
        aria-label="Notifications"
        ref={btnRef}
      >
        {newMessagesAmount > 0 && (
          <Box sx={style.counter}>
            <span>{newMessagesAmount}</span>
          </Box>
        )}
        <BellOutlinedIcon />
      </ButtonBase>

      {openedModal && (
        <PopupNotifications
          messages={messages}
          closeModal={closeModal}
          deleteAllNotification={deleteAllNotification}
          modalRef={modalRef}
        />
      )}
    </Box>
  );
}
