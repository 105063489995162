import { appTheme } from "../../../../theme";
import { fluid } from "../../../../assets/styles/functions/fluid";

export const style = {
  homeHero: {
    overflow: "hidden",
    paddingTop: fluid(20, 12),
    paddingBottom: fluid(11, 6.5),
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238 246 255 / 20%) 3.11%,
      rgba(81 127 245 / 35%) 100.73%
    )`
  },

  wrapper: {
    position: 'relative',
    zIndex: 0,

    '& .heading': {
      marginBottom: '6rem',
      width: '25ch',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
        width: '16ch',
      }
    }
  },

  decor: {
    position: 'absolute',
    right: '-30%',
    top: '45%',
    zIndex: '-1',
    width: 'clamp(55rem, 63%, 72.2rem)',
    height: 'auto',
    transform: 'translateY(-50%)',

    '& > svg': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
    },

    option: {
      transformBox: 'fill-box',
      transformOrigin: 'center',
      animationName: 'rotate',
      animationDuration: '16s',
      animationDelay: '0.5s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',

      [appTheme.breakpoints.down('bp992')]: {
        animation: 'none',
      }
    },

    point: {
      transform: 'scale(0)',
      transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
      transitionDelay: '0.5s',

      visible: {
        transform: 'scale(1)',
        transformBox: 'fill-box',
        transformOrigin: 'center bottom',
      },

      active: {
        animationName: 'bounce',
        animationDuration: '1s',
        animationFillMode: 'both',
      },

      [appTheme.breakpoints.down('bp992')]: {
        transform: 'scale(1)',
        transformBox: 'fill-box',
        transformOrigin: 'center bottom',
      }
    },

    line: {
      strokeDasharray: '1000',
      strokeDashoffset: '1000',
      transition: 'stroke-dashoffset 2s ease-in',

      active: {
        strokeDashoffset: '0',
      },

      [appTheme.breakpoints.down('bp992')]: {
        strokeDashoffset: '0',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      right: '-40rem',
    },

    [appTheme.breakpoints.down('bp370')]: {
      right: '-46rem'
    }
  },

  panel: {
    maxWidth: '87.4rem',

    form: {
      display: 'inline-grid',
      alignItems: 'center',
      gridGap: '1rem',
      gridTemplateColumns: 'repeat(5, 16.6rem)',
      borderRadius: '3.5rem',
      padding: '1.6rem',
      backgroundColor: 'var(--clr-default-100)',

      [appTheme.breakpoints.down('bp992')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',

        '& .button': {
          gridColumn: '2 / 4',
        }
      },

      [appTheme.breakpoints.down('bp767')]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

        '& .button': {
          gridColumn: '1 / 3',
        }
      },

      [appTheme.breakpoints.down('bp576')]: {
        padding: '1.2rem',
      },

      [appTheme.breakpoints.down('bp370')]: {
        display: 'grid',
        gridTemplateColumns: '1fr',

        '& .form-item': {
          gridColumn: 'span 2',
        }
      }
    },

    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '2.5rem',
      paddingRight: '20.6rem',
      minHeight: '7.5rem',

      [appTheme.breakpoints.down('bp992')]: {
        paddingRight: 0,
      },

      [appTheme.breakpoints.down('bp370')]: {
        flexDirection: 'column',
        alignItems: 'center',
      }
    },

    [`$price span, $promocode > span`]: {
      fontSize: fluid(1.4, 1.2),
      color: 'var(--clr-text-900)',
    },

    price: {
      fontWeight: 400,
      '& > span': {
        fontSize: fluid(1.4, 1.2),
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& > span:first-of-type": {
          marginRight: "0.25rem"
        }
      },

      [appTheme.breakpoints.down('bp576')]: {
        flex: '1 1 40%',
      },

      [appTheme.breakpoints.down('bp370')]: {
        flex: '1 1 100%',
        marginBottom: '1rem',
      }
    },

    promocodeClose: {
      marginRight: "0.8rem",
      "& > svg": {
        fill: "var(--clr-primary-400)",
        width: "1.4rem",
        height: "1.4rem"
      }
    },
    promocode: {
      '& .form-item': {
        width: 'min(100%, 23.9rem)',

        '& .form-item__field': {
          borderRadius: '1.5rem',

          [`@media (hover)`]: {
            '&:hover': {
              backgroundColor: 'var(--clr-default-200)',
            }
          }
        },

        [appTheme.breakpoints.down('bp370')]: {
          maxWidth: '100%',
        }
      },

      '& > span': {
        fontWeight: '500',
        textDecoration: 'underline',
        color: 'var(--clr-text-900)',
        cursor: 'pointer',
        fontSize: fluid(1.4, 1.2),
      },

      [appTheme.breakpoints.down('bp576')]: {
        flex: '1 1 60%',
        paddingLeft: '0.5rem',
        textAlign: 'right',
      },

      [appTheme.breakpoints.down('bp370')]: {
        flex: '1 1 100%',
        paddingLeft: '0',
        width: '100%',
        minHeight: '4.8rem',
        textAlign: 'center',
      }
    }
  },

  formSkeleton: {
    height: '106px',
    [appTheme.breakpoints.down('bp992')]: {
      height: 166,
      maxWidth: 742
    },

    [appTheme.breakpoints.down('bp767')]: {
      height: 250,
      maxWidth: 382
    },

    [appTheme.breakpoints.down('bp370')]: {
      height: 410,
      maxWidth: 340
    }
  }
}
