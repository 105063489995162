export const CABINET_ROUTES = {
  translation: "cabinet",
  parent: "cabinet",
  routes: [
    { key: "profile", translation: "profile", icon: "UserIcon" },
    {
      key: "orders",
      translation: "orders",
      icon: "OrderIcon",
      sub: [
        {
          key: "ipv4",
          type: "IPV4",
          translation: "ordersIpv4"
        },
        {
          key: "ipv6",
          type: "IPV6",
          translation: "ordersIpv6"
        },
        {
          key: "isp",
          type: "ISP",
          translation: "ordersIsp"
        },
        {
          key: "mobile-proxy",
          type: "MOBILE",
          translation: "ordersMobileProxy"
        }
      ]
    },
    { key: "new-order", translation: "newOrder", icon: "OrderPlusIcon" },
    { key: "balance", translation: "balance", icon: "BalanceIcon" },
    { key: "payment-history", translation: "paymentHistory", icon: "ClockRoundedIcon" },
    { key: "actions-history", translation: "actionsHistory", icon: "ClockSquaredIcon" },
    { key: "promocodes", translation: "promocodes", icon: "PromocodeIcon" },
    { key: "subscription", translation: "subscription", icon: "ClickIcon" },
    { key: "faq", translation: "faq", icon: "QuestionIcon" },
    { key: "developers-api", translation: "developersApi", icon: "ApiIcon" }
  ]
};
