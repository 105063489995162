import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import { useTranslation } from "../../../hooks";
import { ExtendService } from "../../../services/extend.service";

import { style } from "./auto-renewal.style";

export default function AutoRenewaledList() {
  const { t } = useTranslation();
  const { autoRenewal } = t("pages.cabinet.orders.widgets");
  const [autoExtendedList, setAutoExtendedList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await ExtendService.getAutoExtendedList();
        setAutoExtendedList(res.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      }
    })();
  }, []);
  return (
    <>
      {autoExtendedList.length > 0 && (
        <Box component="ul" sx={style.autoExtendedList}>
          {autoExtendedList.map(({ ipAddressIds, amount, dateAutoExtend, quantity }) => (
            <Box component="li" key={ipAddressIds[0]}>
              <Box component="p">
                {" "}
                {dayjs(dayjs(dateAutoExtend).subtract(dayjs().utcOffset(), "minute")).format(
                  "DD.MM.YYYY"
                )}{" "}
                {autoRenewal.willCharged} ${amount} - {autoRenewal.title.toLowerCase()} {quantity}{" "}
                {quantity > 1 ? t("other.ips") : t("other.piece") }
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
