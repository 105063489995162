import { appTheme } from '../../../theme'
import { fluid } from '../../../assets/styles/functions/fluid'

export const style = {
  blog: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
  },

  wrapper: {
    '& > .heading': {
      marginBottom: '5rem',
      textAlign: 'center',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '4rem',
        textAlign: 'left',
      }
    }
  },

  list: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(37rem, 1fr))',
    marginBottom: '4.5rem',

    [appTheme.breakpoints.down('bp576')]: {
      gridTemplateColumns: '1fr',
    }
  },

  noData: {
    textAlign: 'center',
    fontSize: '24px'
  }
}
