import { Box } from "@mui/material";
import { style } from "./table.style";

export const TableCell = ({
  tag: Tag = "td",
  type = "default",
  width,
  colSpan,
  children,
  flex,
  align,
  justify,
  textAlign = "left"
}) => {
  const defineClasses = () => {
    let classes = "table__cell";

    switch (type) {
      case "default":
        classes += " table__cell_padding_default";
        break;
      case "small":
        classes += " table__cell_padding_small";
        break;
      case "checkbox":
        classes += " table__cell_padding_checkbox";
        break;
      default:
        break;
    }

    switch (align) {
      case "center":
        classes += " table__cell_align_center";
        break;
      default:
        break;
    }

    switch (justify) {
      case "right":
        classes += " table__cell_justify_right";
        break;
      default:
        break;
    }

    if (flex) classes += " table__cell_flex";

    return classes;
  };

  const defineStyles = () => {
    // const styles = [];
    const styles = [style.tableCell];

    switch (type) {
      case "default":
        styles.push(style.tableCell.padding.default);
        break;

      case "small":
        styles.push(style.tableCell.padding.small);
        break;

      case "checkbox":
        styles.push(style.tableCell.padding.checkbox);
        break;
      default:
        break;
    }

    switch (align) {
      case "center":
        styles.push(style.tableCell.align.center);
        break;
      default:
        break;
    }

    switch (justify) {
      case "right":
        styles.push(style.tableCell.justify.right);
        break;
      default:
        break;
    }

    switch (textAlign) {
      case "left":
        styles.push(style.tableCell.textAlign.left);
        break;
      case "right":
        styles.push(style.tableCell.textAlign.right);
        break;
      case "center":
        styles.push(style.tableCell.textAlign.center);
        break;
      default: break;
    }

    if (flex) styles.push(style.tableCell.flexStyle);

    const maxWidth = type !== "checkbox" ? width : "6rem";

    const widthStyles = {
      width: maxWidth,
      maxWidth
    };

    styles.push(widthStyles);
    return styles;
  };

  return (
    <Box
      component={Tag}
      sx={defineStyles()}
      className={defineClasses()}
      colSpan={colSpan}
    >
      {children}
    </Box>
  );
};
