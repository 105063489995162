import { appTheme } from "../../../theme"
import { fluid } from '../../../assets/styles/functions/fluid'

export const style = {
  contacts: {
    minHeight: "100%",
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238, 246, 255, 0.06) 3.11%,
      rgba(81, 127, 245, 0.105) 100.73%
    )`,
  },

  wrapper: {
    '& > .heading': {
      marginBottom: '4rem',

      [appTheme.breakpoints.down('bp576')]: {
        textAlign: 'left',
      }
    }
  },

  inner: {
    display: 'grid',
    gridGap: '6rem',
    gridTemplateColumns: '33.5rem 2fr 33.5rem',

    [appTheme.breakpoints.down('bp1200')]: {
      gridGap: '3rem',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    [appTheme.breakpoints.down('bp767')]: {
      gridTemplateColumns: '1fr',
    }
  },

  left: {
    '.heading_h4': {
      marginBottom: '1.2rem',
    },

    '.address': {
      marginBottom: '3.8rem',
    },

    [appTheme.breakpoints.down('bp1200')]: {
      gridColumn: 'span 1',
    }
  },

  description: {
    marginBottom: '3.5rem',
    fontWeight: '400',
    fontSize: '1.6rem',
    lineHeight: '160%',
    color: 'var(--clr-text-400)',
    [appTheme.breakpoints.down("bp576")]: {
      fontSize: "1.4rem",
      marginBottom: "1rem"
    }
  },

  form: {
    borderRadius: '3rem',
    padding: '4rem 6rem',
    boxShadow: '0 15px 25px rgba(179 184 202 / 22%)',
    backgroundColor: 'var(--clr-default-100)',

    '.form-item': {
      '&:not(:first-of-type)': {
        marginTop: '1.5rem',
      }
    },

    '.button': {
      marginTop: '3rem',
    },

    [appTheme.breakpoints.down('bp1200')]: {
      gridColumn: 'span 1',
    },

    [appTheme.breakpoints.down('bp576')]: { padding: '4rem' },
    [appTheme.breakpoints.down('bp370')]: { padding: '2rem' }


  },

  right: {
    '.contacts-list': {
      marginBottom: '6.4rem',
    },

    [appTheme.breakpoints.down('bp1200')]: {
      gridColumn: 'span 2',
    },

    [appTheme.breakpoints.down('bp767')]: {
      gridColumn: 'span 1',

      '.address, .socials-list, .contacts-list': {
        marginBottom: '3.5rem',
      }
    }
  },

  modal: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [appTheme.breakpoints.down('bp576')]: {
      width: '90%'
    }
  }
}
