import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  wrapper: {
    flexGrow: '1',
    borderRadius: '2.2rem',
    padding: '1rem 2rem 3rem',
    backgroundColor: 'var(--clr-default-400)',
  },
  label: {
    display: 'inline-block',
    marginBottom: '0.8rem',
    fontWeight: '400',
    fontSize: fluid(1.6, 1.4),
    color: 'var(--clr-text-300)',
  },
  row: {
    marginBottom: '0.8rem',
    fontWeight: '400',
    fontSize: '1.4rem',
    color: 'var(--clr-text-900)',
  }
};
