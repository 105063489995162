import { BlogActions } from "./blog/blog.slice";
import { CaptchaActions } from "./captcha/captcha.slice";
import { FaqActions } from "./faq/faq.slice";
import { FingerprintActions } from "./fingerprint/fingerprint.slice";
import { GoalActions } from "./goal/goal.slice";
import { MobileActions } from "./mobile/mobile.slice";
import { PaymentActions } from "./payment/payment.slice";
import { PromocodeActions } from "./promocode/promocode.slice";
import { ProxyActions } from "./proxy/proxy.slice";
import { RentActions } from "./rent/rent.slice";
import { ReviewActions } from "./review/review.slice";
import { SeoBlockActions } from "./seo-block/seo-block.slice";
import { SeoActions } from "./seo/seo.slice";
import { ToolsActions } from "./tools/tools.slice";
import { UserActions } from "./user/user.slice";

export const StoreActions = {
  ...UserActions,
  ...ProxyActions,
  ...ReviewActions,
  ...PaymentActions,
  ...RentActions,
  ...GoalActions,
  ...BlogActions,
  ...FaqActions,
  ...PromocodeActions,
  ...MobileActions,
  ...FingerprintActions,
  ...SeoActions,
  ...ToolsActions,
  ...SeoBlockActions,
  ...CaptchaActions
};
