import { useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import { MobileMenuContext } from "./mobile-menu";
import { style } from "./mobileMenu.style";

export const MobileMenuItem = ({ type = "link", to, icon, label }) => {
  const { handleOpen, setActiveMenu, depth, setDepth, setIsNext, setActiveProxyType } = useContext(MobileMenuContext);

  const handleActiveMenu = (to) => {
    setActiveMenu(to);
    const newDepth = [...depth, to];
    setDepth(newDepth);
    setIsNext(true);
    setActiveProxyType(label.toLowerCase());
  };

  // console.count("MobileMenuItem");

  return (
    <>
      {type === "tab" ? (
        <Box component="li" sx={style.tab} className="mobile-menu__tab" onClick={() => handleActiveMenu(to)}>
          {label}
        </Box>
      ) : (
        <Box component="li" sx={style.link} key={`mobile-menu-${label}`} className="mobile-menu__link" onClick={handleOpen}>
          <Link to={to}>
            {icon && <Box sx={style.link.icon} className="mobile-menu__link-icon">{icon}</Box>}
            <Box component="span">{label}</Box>
          </Link>
        </Box>
      )}
    </>
  );
};
