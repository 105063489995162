import { appTheme } from "../../../../../theme";

export const style = {
  list: {
    zIndex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",

    overflowY: "auto",
    // maxHeight: "585px",

    paddingRight: "12px",
    paddingBottom: "1px",

    "&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb": {
      transition: "background-color .3s",
      borderRadius: "100px",
    },
    "&:not(:hover)::-webkit-scrollbar": {
      backgroundColor: "#E1E3EE",
    },
    "&:not(:hover)::-webkit-scrollbar-track": {
      backgroundColor: "#F3F4F9",
    },
    "&:not(:hover)::-webkit-scrollbar-thumb": {
      backgroundColor: "#E1E3EE"
    },

    [appTheme.breakpoints.down('bp460')]: {
      maxHeight: "435px",
    },
    [appTheme.breakpoints.down('bp370')]: {
    },
  },
  item: {

    wrapper: {
      "&:not(:last-of-type)": {
        marginBottom: "1.2rem"
      },
      position: "relative",
      display: "flex",
      justifyContent: "space-between",

      padding: "27px 24px",
      border: "1px solid #DDDFE5",
      borderRadius: "30px",
      transition: "border .3s",

      color: "var(--clr-text-900)",
      "&:hover": {
        borderColor: "#517FF5",
        "& button": {
          borderColor: "transparent",
          color: "var(--clr-text-100)",
          backgroundImage: "linear-gradient(97.86deg, #517ff5 0%, #4ba7ea 103.85%)",
        }
      },

      [appTheme.breakpoints.down('bp460')]: {
        flexDirection: "column"
      },

      "& > *": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginRight: "3rem",
        [appTheme.breakpoints.down("bp460")]: {
          marginRight: "0",
          "&:not(:last-of-type)": {
            marginBottom: "3rem"
          }
        },

        "& > div:first-of-type": {
          marginBottom: "1.2rem"
        },
        "&:last-child": {
          marginRight: "0",
          textAlign: "right",
          [appTheme.breakpoints.down('bp460')]: {
            // flexWrap: "wrap",
            flexDirection: "row",

            "& > div:first-of-type": {
              marginBottom: "0"
            },
          },
          [appTheme.breakpoints.down('bp370')]: {
            // flexDirection: "column"
          },
        }
      }
    },
    quantity: {
      fontSize: "18px",
      fontWeight: "600"
    },
    price: {
      display: "flex",
      alignItems: "flex-end",
      rowGap: "4px",
      columnGap: "12px",
      flexWrap: "wrap",
      flex: 1,

      justifyContent: "flex-end",

      fontSize: "14px",
      fontWeight: "400",
      lineHeight: 1,

      [appTheme.breakpoints.down('bp460')]: {
        justifyContent: "flex-start"
      },

      "& > span:first-of-type": {
        fontSize: "24px",
        fontWeight: "500",
        // marginRight: "12px",
      },
      "& > span:last-of-type": {
        color: "var(--clr-text-300)",
      }
    },
    select: {
      minWidth: "160px",
      [appTheme.breakpoints.down('bp370')]: {
        minWidth: "unset"
      },
    },
    button: {
      "& > button.button": {
        minHeight: "44px",
        marginLeft: "1rem"
      }
    }
  }
}
