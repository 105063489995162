import { startTransition, useEffect, useState } from "react";

import { Box, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { UserIcon } from "../../../components/icons/index";
import { useLang } from "../../../hooks";
import { getIsAuthenticatedUser } from "../../../store/user/user.selectors";
import { ROUTE } from "../../../utils/constants";

import { style } from "./cabinetButton.style";

export const CabinetButton = ({ onClick, size = "default" }) => {
  const { t } = useTranslation();
  const lang = useLang();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsAuthenticatedUser);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    startTransition(() => {
      setIsAuth(isAuthenticated);
    });
  }, [isAuthenticated]);

  const defineClasses = () => {
    let classNames = "cabinet-button";
    const classes = [style.cabinetButton];

    switch (size) {
      case "small":
        classNames += " cabinet-button_size_small";
        classes.push(style.cabinetButton.sizeSmall);
        break;
      case "default":
        classNames += " cabinet-button_size_default";
        classes.push(style.cabinetButton.sizeDefault);
        break;
      default:
        break;
    }

    return { classes, classNames };
  };

  const handlerClick = () => {
    if (onClick) {
      onClick();
    }
    const route = isAuth ? `/${lang}${ROUTE.CABINET.PROFILE}/` : `/${lang}${ROUTE.AUTH.LOGIN}/`;

    navigate(route, { replace: true });
  };

  // console.count("CabinetButton");

  return (
    <>
      <Box
        sx={defineClasses().classes}
        className={defineClasses().classNames}
        onClick={handlerClick}
      >
        <ButtonBase>
          <UserIcon />
          {isAuth ? t("header.cabinet") : t("header.logIn")}
        </ButtonBase>
      </Box>
    </>
  );
};
