import { appTheme } from '../../../../theme'
import { fluid } from '../../../../assets/styles/functions/fluid'

export const style = {
  thanksContacts: {
    paddingTop: fluid(14, 8),
    paddingBottom: fluid(14, 8),
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    [appTheme.breakpoints.down('bp1200')]: {
      flexDirection: 'column',
    }
  },

  left: {
    flex: '1 1 50%',

    '& > .heading_h2': {
      marginBottom: '1.8rem',
    },

    [appTheme.breakpoints.down('bp1200')]: {
      marginBottom: '8rem',
    }
  },

  text: {
    marginBottom: '3.2rem',
    fontWeight: '400',
    fontSize: '1.4rem',
    lineHeight: '160%',
    color: 'var(--clr-text-400)',
  },

  sites: {
    display: 'flex',
    alignItems: 'center',

    '.button': {
      '&:not(:last-child)': {
        marginRight: '3rem',
      },

      [appTheme.breakpoints.down('bp767')]: {
        marginBottom: '1rem',

        '&:not(:last-child)': {
          marginRight: '1rem',
        }
      }
    },

    [appTheme.breakpoints.down('bp767')]: {
      flexWrap: 'wrap',
    }
  },

  site: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3rem',
    padding: '1rem 4.8rem',
    minHeight: '4.8rem',
    color: 'var(--clr-text-600)',
    backgroundColor: 'rgba(var(--clr-primary-400-rgb) / 20%)',
    cursor: 'pointer',

    '&:not(:last-child)': {
      marginRight: '3rem',
    },

    [appTheme.breakpoints.down('bp767')]: {
      marginBottom: '1rem',

      '&:not(:last-child)': {
        marginRight: '1rem',
      }
    }
  },

  right: {
    display: 'grid',
    flex: '1 1 50%',
    gridGap: '4rem',
    gridTemplate: '1fr 1fr / 1fr 1fr',
    paddingLeft: '13rem',

    '.contacts-list': {
      gridRow: '1 / 3',
    },

    [appTheme.breakpoints.down('bp1200')]: {
      gridGap: '2rem',
      gridTemplateColumns: 'repeat(2, 25rem)',
      paddingLeft: '0',
    },

    [appTheme.breakpoints.down('bp576')]: {
      gridTemplateColumns: '1fr',
    }
  }
}