export const getCountriesWithLocale = (ordersData, locale) =>
  ordersData
    ? ordersData.countries.map((el) => ({
        ...el,
        translation: el.code,
        key: el.id,
        label: el.localeDependentNames[locale]
      }))
    : [];

export const selectAllHandle = (tableData, ordersData, selected, handleSelectAll) => {
  if (tableData?.totalElements !== selected.length) {
    if (ordersData?.ipAddressesIds) handleSelectAll(ordersData?.ipAddressesIds);
    else handleSelectAll();
  } else handleSelectAll();
};

export const defineProtocol = (protocol) => {
  switch (protocol) {
    case "HTTP":
      return "HTTP/S";
    case "SOCKS":
      return "SOCKS5";
    default:
      return protocol;
  }
};

export const getSortDirection = (sort = {}, key) => {
  return sort.key === key ? sort?.value : null;
};
