import { createAsyncThunk } from "@reduxjs/toolkit";
import { ToolsService } from "../../services";
import axios from "axios";

export const checkIp = createAsyncThunk(
  "tools/checkIp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ToolsService.checkIp();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getAnonymityPercent = createAsyncThunk(
  "tools/getAnonymityPercent",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ToolsService.getAnonymityPercent(
        {
          timeZoneJs: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
export const getFingerprint = createAsyncThunk(
  "tools/getFingerprint",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ToolsService.getFingerprint();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
export const getClientDns = createAsyncThunk(
  "tools/getClientDns",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://edns.ip-api.com/json");

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
export const getClientBlackListsCheck = createAsyncThunk(
  "tools/getClientBlackListsCheck",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ToolsService.getClientBlackListsCheck();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
