/* eslint-disable prefer-destructuring */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { OrderModal } from "../../components/common/order-modal/order-modal";
import { Button } from "../../components/ui/button/button";
import { useLang, useTranslateOptions } from "../../hooks";
import useProxyCountryOrType, {
  ProxyCountryOrTypeMode
} from "../../hooks/use-proxy-country-or-type";
import { ApiService } from "../../services";
import { getProxyState, getRentState } from "../../store/selectors";
import { CALC_TYPES, MODAL_ORDER_STATUS, PAYMENT_STATUS } from "../../utils/constants";
import {
  getCountriesOptions,
  getIndexFromOptions,
  getPeriodsOptions
} from "../../utils/helpers/order.helpers";

// import DiscountOfferTooltip from "../common/discount-offer-tooltip/discount-offer-tooltip";
import { HeroInput } from "./hero-input";
import { HeroSelect } from "./hero-select";
import { style } from "./heroForm.style";

// eslint-disable-next-line no-empty-function
const HeroForm = (props) => {
  const { order, isHomePage = true } = props;

  const [params] = useSearchParams();
  const paymentStatus = params.get("paymentStatus");

  const lang = useLang();

  const { type, mode, country } = useProxyCountryOrType();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("bp370"));

  const inputRef = useRef(null);
  const quantityRef = useRef(null);
  const { t } = useTranslation();
  // *Redux
  const { proxies, proxyOptions, proxyTypeIds, proxyMinQuantity } = useSelector(getProxyState);
  const { periods } = useSelector(getRentState);

  // state
  const [discountOffer, setDiscountOffer] = useState({
    active: false,
    open: false,
    nextQuantity: 0,
    nextAmount: 0
  });
  const [open, setOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [hasCountrySelect, setHasCountrySelect] = useState(mode !== ProxyCountryOrTypeMode.COUNTRY);
  // options
  const proxyTypeOptions = useTranslateOptions(proxyOptions, t("buyProxy"));
  const periodsOptions = getPeriodsOptions(order.data.proxyType || type, periods, lang);
  const countryOptions = getCountriesOptions(order.data.proxyType || type, proxies, lang);

  const proxyTypeIndex = getIndexFromOptions(
    proxyTypeOptions,
    "key",
    order.data.proxyType || type,
    null
  );
  const countryIndex = getIndexFromOptions(
    countryOptions,
    "key",
    order.data.proxyCountry ||
      countryOptions[country ? countryOptions.findIndex((e) => e.urlParam === country) : 0]?.key,
    null
  );
  const rentalPeriodIndex = getIndexFromOptions(
    periodsOptions,
    "key",
    order.data.proxyRentalPeriod || periodsOptions[0]?.key,
    null
  );

  // TEMPORARY
  const [searchParams, setSearchParams] = useSearchParams();
  const searchCountry = searchParams.get("country");

  // helpers
  const closeDiscountOffer = () => setDiscountOffer((prevState) => ({ ...prevState, open: false }));
  const setOrder = (payload) => order.setData((prev) => ({ ...prev, ...payload }));
  const cancelHandler = () => setOpen(false);
  const setProxyType = (type, country = null) => {
    // const type = option?.key;\]

    let countryIndex = 0;
    const countriesOptions = getCountriesOptions(type, proxies, lang);

    if (country) countryIndex = countriesOptions.findIndex((e) => e.urlParam === country);

    if (proxyTypeIds.length) {
      setOrder({
        proxyType: type,
        proxyTypeId: proxyTypeIds.find((el) => el.name.toUpperCase() === type.toUpperCase()).id,
        proxyCountry: countriesOptions[countryIndex]?.key,
        proxyRentalPeriod: getPeriodsOptions(type, periods, lang)[0].key,
        proxyCount: proxyMinQuantity[type.toUpperCase()]
      });
    }
  };
  const proxyTypeChangeHandler = (option) => {
    order.clearData();
    setProxyType(option.key);
    setOrder({ promoCode: order.data.promoCode });
  };

  // handlers
  // *form submit and open modal
  const submitHandler = () => {
    if (inputError) return;
    setOpen(true);
  };

  const countryChangeHandler = (option) => {
    setOrder({ proxyCountry: option.key });
    if (searchCountry) setSearchParams({ country: option.urlParam });
  };

  // define form grid styles
  const defineFormClass = () => {
    let classNames = "";
    if (isHomePage) classNames += "grid-5";
    else if (hasCountrySelect) classNames += "grid-4";
    else classNames += "grid-3";

    return classNames;
  };

  const orderStatus = paymentStatus === PAYMENT_STATUS.CANCEL ? MODAL_ORDER_STATUS.ERROR : null;

  useEffect(() => {
    if (paymentStatus === PAYMENT_STATUS.CANCEL) {
      setOpen(true);
    }
  }, [paymentStatus]);
  // Set proxy type and country
  useEffect(() => {
    if (isHomePage) setProxyType(Object.keys(proxies)[0]);
    else if (mode === ProxyCountryOrTypeMode.COUNTRY) {
      setHasCountrySelect(false);
      setProxyType(type, country);
    } else {
      setHasCountrySelect(true);
      if (!country) setProxyType(type);
      else if (proxies[type]?.countries.find((el) => el.urlParam === searchCountry)) {
        setProxyType(type, country);
      } else {
        searchParams.delete("country");
        setSearchParams(searchParams);
        setProxyType(type);
      }
    }
  }, [type, country, isHomePage, proxies]);
  // Set error if count < minCount
  useEffect(() => {
    setInputError(order.canShowTooltip ? order.data.proxyCount < order.minCount : false);
  }, [order.data.proxyCount, order.minCount]);
  // Calculate price
  useEffect(() => {
    order.calculatePrice(CALC_TYPES.BASIC);
  }, [order.data, order.minCount]);
  // getDiscountOffer
  useEffect(() => {
    if (
      isHomePage &&
      !isMobile &&
      discountOffer.active &&
      order.data.proxyTypeId &&
      order.data.proxyCount &&
      order.price.loading
    ) {
      ApiService.getDiscountOffer({
        proxyTypeId: order.data.proxyTypeId,
        quantity: order.data.proxyCount
      })
        .then((response) => {
          if (response.data) {
            setDiscountOffer({
              open: true,
              currentQuantity: order.data.proxyCount,
              ...response.data
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [
    order.data.proxyTypeId,
    order.data.proxyCount,
    order.price,
    isMobile,
    isHomePage,
    discountOffer.active
  ]);
  // closeDiscountOffer
  useEffect(() => {
    closeDiscountOffer();
  }, [order.data.proxyCount]);

  const modalInitialData = {
    proxyType: order.data.proxyType,
    proxyTypeId: order.data.proxyTypeId,
    proxyCountry: order.data.proxyCountry,
    proxyRentalPeriod: order.data.proxyRentalPeriod,
    proxyCount: order.data.proxyCount
  };

  if (order.data.promoCode) {
    modalInitialData.promoCode = order.data.promoCode;
  }

  // const handlerCloseDiscountOffer = () => {
  //   closeDiscountOffer();
  // };

  return (
    <Box sx={style.heroForm} className="heroForm">
      <Box component="form" className={defineFormClass()}>
        {isHomePage ? (
          <HeroSelect
            name="proxyType"
            label={t("form.labels.proxyType")}
            options={proxyTypeOptions}
            onChange={proxyTypeChangeHandler}
            selectedIndex={proxyTypeIndex}
          />
        ) : null}
        {hasCountrySelect || isHomePage ? (
          <HeroSelect
            name="heroCountry"
            isCountry
            label={t("form.labels.country")}
            options={countryOptions}
            onChange={countryChangeHandler}
            selectedIndex={countryIndex}
          />
        ) : null}
        <HeroSelect
          name="HeroRentalPeriod"
          label={t("form.labels.rentalPeriod")}
          options={periodsOptions}
          onChange={(option) => setOrder({ proxyRentalPeriod: option.key })}
          selectedIndex={rentalPeriodIndex}
        />
        <HeroInput
          containerRef={quantityRef}
          name="quantity"
          label={t("form.labels.proxyCount")}
          value={order.data.proxyCount}
          onChange={(proxyCount) => {
            if (!discountOffer.active) {
              setDiscountOffer((prevState) => ({ ...prevState, active: true }));
            }
            setOrder({ proxyCount });
          }}
          isError={inputError}
          placeholder={order.minCount}
          ref={inputRef}
        />
        <Button
          tooltipText={`${t("pages.app.home.form.minimal")}${order.minCount}${t("other.piece")}`}
          tooltipOpen={inputError}
          variant="primary"
          color="white"
          ariaLabel={t("form.actions.buyProxy")}
          onClick={submitHandler}
        >
          {t("form.actions.buyProxy")}
        </Button>
      </Box>
      {/* тимчасово прихована пропозиція знижок, через погану заповнену адмінку discounts */}
      {/* {isHomePage && !isMobile ? (
        <DiscountOfferTooltip
          anchorEl={quantityRef.current}
          open={discountOffer.open}
          autoHideDuration={5000}
          data={discountOffer}
          onClose={handlerCloseDiscountOffer}
          onSubmit={(e, proxyCount) => {
            setOrder({ proxyCount });
            handlerCloseDiscountOffer();
          }}
        />
      ) : null} */}
      <OrderModal
        id="hero-form"
        visible={open}
        initialData={modalInitialData}
        cancelHandler={cancelHandler}
        orderStatus={orderStatus}
      />
    </Box>
  );
};

export default HeroForm;
