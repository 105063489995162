import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CustomCheckbox } from "../../../components/common/checkbox/checkbox";
import { PromocodeSection } from "../../../components/common/promocode-section/promocode-section";
import { SelectWithBalance } from "../../../components/common/select-with-balance/select-with-balance";
import { HeroSelect } from "../../../components/heroForm/hero-select";
import { OrderListIcon } from "../../../components/icons";
import { Button } from "../../../components/ui/button/button";
import { useActions, useLang } from "../../../hooks";
import { ExtendService } from "../../../services/extend.service";
import {
  getFingerprintState,
  getPaymentState,
  getProxyState,
  getRentState
} from "../../../store/selectors";
import { ERROR_CODES } from "../../../utils/constants";
import {
  getPeriodsOptions,
  getPromocodeWarning,
  getProxyTypeUpper
} from "../../../utils/helpers/order.helpers";
import { orderProcessing } from "../../../utils/helpers/orderProcessing.helpers";

import ModalInsertIpList from "./modal-insert-ipList";
import { style } from "./orders.style";

export const OrdersPanel = ({
  selectAllHandle,
  selectedLength,
  proxyType,
  selected,
  totalElements,
  allOrders,
  setSelectedIp
}) => {
  const { t } = useTranslation();
  const lang = useLang();
  const { enqueueSnackbar } = useSnackbar();

  //*Redux
  const { fetchUserActiveOrdersIpAddressCount } = useActions();
  const { periods } = useSelector(getRentState);
  const { systemsOptions } = useSelector(getPaymentState);
  const { proxyTypeIds } = useSelector(getProxyState);
  const { data } = useSelector(getFingerprintState);

  //state
  const [hasPromo, setHasPromo] = useState(false);
  const [promo, setPromo] = useState("");
  const [openedModalIpList, setOpenedModalIpList] = useState(false);
  // const [disableButton, setDisableButton] = useState(true);
  const [priceObj, setPriceObj] = useState({});
  const [errors, setErrors] = useState({});
  //select state
  const [periodIndex, setPeriodIndex] = useState(0);
  const [paymentIndex, setPaymentIndex] = useState(0);

  // select options
  const rentOptions = getPeriodsOptions(proxyType, periods, lang);
  // const paymentOptions = systemsOptions[lang];
  const systemOptionsForUser = systemsOptions
    ? systemsOptions[lang].filter((opt) => opt.showForUser)
    : [];

  // handlers
  const handlePromo = () => setHasPromo((prev) => !prev);

  const handlePromoClose = () => {
    setPromo("");
    setHasPromo(false);
  };

  const handlePromoChange = (e) => {
    setPromo(e.target.value);

    setPriceObj((prevState) => ({ ...prevState, validPromoCode: true }));
  };

  const handlePeriodChange = (option) => {
    setPeriodIndex(rentOptions.indexOf(rentOptions.find((opt) => opt.key === option.key)));
  };

  const handlePaymentChange = (option) => {
    setPaymentIndex(
      systemOptionsForUser.indexOf(systemOptionsForUser.find((opt) => opt.key === option.key))
    );
  };

  const submitHadler = () => {
    const reqObj = {
      proxyTypeId: proxyTypeIds.find((el) => el.name === getProxyTypeUpper(proxyType)).id,
      ipAddressIds: selected,
      rentPeriodId: rentOptions[periodIndex].key,
      paymentSystemId: systemOptionsForUser[paymentIndex].key,
      promoCode: promo,
      localeCode: lang,
      fingerprint: data?.visitorId || ""
    };

    ExtendService.orderExtend(reqObj)
      .then((res) => {
        if (res.status === 200) {
          orderProcessing(res.data);
          // Fetch Active orders count for show in sidebar
          fetchUserActiveOrdersIpAddressCount();
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        enqueueSnackbar(t("cabinet.orders.extendError"), { variant: "error" });
      });
    // eslint-disable-next-line no-console
    // eslint-disable-next-line no-console
    console.log("submit!!!");
  };

  const defineErrorText = (error) => {
    if (ERROR_CODES.minQuantityTen.some((err) => err === error)) {
      return t("form.serverErrors.minQuantityTen");
    } else if (ERROR_CODES.ips.some((err) => err === error)) return t("form.serverErrors.ips");
     else if (error === "ERROR_PRICE_CALCULATION") return t("form.serverErrors.priceCalculation");
    else return t("form.serverErrors.default");
  };

  // disable button when form is incomplete
  // useEffect(() => {
  //   if (periodIndex !== "" && paymentIndex !== "" && selectedLength > 0) setDisableButton(false);
  //   else if (disableButton === false) setDisableButton(true);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [periodIndex, paymentIndex, selectedLength]);

  //price calculte depends on data
  useEffect(() => {
    const calculatePrice = setTimeout(() => {
      if (
        periodIndex !== "" &&
        paymentIndex !== "" &&
        systemOptionsForUser?.length &&
        selected.length
      ) {
        const reqObj = {
          ipAddressIds: selected,
          proxyTypeId: proxyTypeIds.find((el) => el.name === getProxyTypeUpper(proxyType)).id,
          rentPeriodId: rentOptions[periodIndex].key,
          paymentSystemId: systemOptionsForUser[paymentIndex].key,
          promoCode: promo,
          fingerprint: data?.visitorId || ""
        };

        // console.log("reqObj", reqObj);
        ExtendService.priceCalculateExtend(reqObj)
          .then((res) => {
            if (res.data) setPriceObj(res.data);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.message);
          });
      } else {
        setPriceObj({});
      }
    }, 300);

    return () => clearTimeout(calculatePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodIndex, paymentIndex, selected, promo]);

  useEffect(() => {
    if (priceObj.promoCodeWarn) {
      const message = getPromocodeWarning(priceObj.promoCodeWarn, t);

      if (message) {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }, [enqueueSnackbar, priceObj.promoCodeWarn, t]);

  useEffect(() => {
    if (priceObj.warnings) {
      setErrors((prevState) => ({
        ...prevState,
        // paymentType: { type: "BALANCE_LOW", message: t.form.serverErrors.balance }
        paymentType: true
      }));

      if (priceObj.warnings?.indexOf("BALANCE_LOW") !== -1) {
        enqueueSnackbar(t("form.serverErrors.balance"), { variant: "error" });
      } else if (priceObj.warnings?.indexOf("PAYMENT_SYSTEM_MIN_PRICE") !== -1) {
        enqueueSnackbar(
          t("form.serverErrors.minPrice").replace("{{price}}", priceObj.minPriceForPaymentSystem),
          { variant: "error" }
        );
      }
    } else if (errors["paymentType"]) {
      setErrors((prevState) => ({
        ...prevState,
        paymentType: false
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceObj.warnings]);

  useEffect(() => {
    if (!priceObj.success && priceObj.error) {
      enqueueSnackbar(defineErrorText(priceObj.error), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceObj.success]);

  const closeModalIpList = () => setOpenedModalIpList(false);

  return (
    <>
      <Box sx={style.panel} className="cabinet-orders__panel">
        <Box sx={style.panel.addListBtnBlock}>
          <Box
            component="span"
            sx={style.panel.info.item}
            className="cabinet-orders__panel-info-item"
          >
            {t("other.selected")}&nbsp;<span>{selectedLength}</span>&nbsp;{t("other.proxy")}
          </Box>
          {proxyType !== "IPV6" && (
            <Box
              sx={style.panel.addListBtnBlock.link}
              onClick={() => {
                setOpenedModalIpList(true);
              }}
            >
              <OrderListIcon />
              <Box component="span">{t("other.insertList")}</Box>
            </Box>
          )}
        </Box>

        <Box sx={style.panel.options} className="cabinet-orders__panel-options">
          <Box sx={{ marginTop: "17px" }}>
            <CustomCheckbox
              label={`${t("form.labels.selectAll")} ${totalElements} ${t("other.pieces")}`}
              changeHandler={selectAllHandle}
              checked={selectedLength === totalElements && totalElements !== 0}
            />
          </Box>
          <HeroSelect
            options={rentOptions}
            name="rentalPeriod"
            label={t("form.labels.rentalPeriod")}
            selectedIndex={periodIndex}
            onChange={handlePeriodChange}
          />

          <SelectWithBalance
            options={systemOptionsForUser}
            name="paymentType"
            label={t("form.labels.paymentType")}
            selectedIndex={paymentIndex}
            onChange={handlePaymentChange}
            error={!!selectedLength && errors["paymentType"]}
          >
            <HeroSelect />
          </SelectWithBalance>

          <Box sx={{ marginTop: "11px" }}>
            <Button
              type="submit"
              variant="primary"
              fullwidth
              onClick={submitHadler}
              disabled={!priceObj?.success || !!priceObj?.warnings?.length}
            >
              {t("form.actions.extend")}
            </Button>
          </Box>
        </Box>

        <Box sx={style.panel.info} className="">
          <Box
            component="span"
            sx={style.panel.info.item}
            className="cabinet-orders__panel-info-item"
          >
            <Box>
              {t("other.price")}&nbsp;
              <span>
                {(!!selectedLength && priceObj?.totalPriceInMainCurrency) ||
                  priceObj.totalPrice ||
                  0}
              </span>
              &nbsp;{priceObj?.currencyCodeInMainCurrency || priceObj.currencyCode || ""}
            </Box>
          </Box>
          <Box sx={style.panel.info.promocode} className="cabinet-orders__panel-info-promocode">
            <PromocodeSection
              onChange={handlePromoChange}
              promo={promo}
              hasPromo={hasPromo}
              onClick={handlePromo}
              onClose={handlePromoClose}
              inputProps={{
                error:
                  promo && priceObj.hasOwnProperty("validPromoCode")
                    ? !priceObj?.validPromoCode
                    : false
              }}
              promoLinkStyles={style.promocodeLink}
            />
          </Box>
        </Box>
      </Box>

      {/* modal  add ip list */}

      <ModalInsertIpList
        openedModalIpList={openedModalIpList}
        closeModalIpList={closeModalIpList}
        allOrders={allOrders}
        setSelectedIp={setSelectedIp}
      />
    </>
  );
};
