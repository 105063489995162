import { useMemo, useRef, useState } from "react";

import { Box, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DownIcon } from "../../../../components/icons/index";
import { useMatchedRoute } from "../../../../hooks/use-matched-route";
import { useOnClickOutside } from "../../../../hooks/use-on-click-outside";
import DynamicIcon from "../../dynamic-icon/dynamic-icon";

import { SidebarDropdownItem } from "./sidebar-dropdown-item";
import { SidebarDropdownLogout } from "./sidebar-dropdown-logout";
// import "./sidebar-dropdown.scss";
import { style } from "./sidebar-dropdown.style";

export const SidebarDropdown = ({ routes, translation, parentPage, withExit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { path } = useMatchedRoute();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const currentPath = path.split("/").slice(2, path.split("/").length).join("/");

  const handleOpen = () => setIsOpen((prev) => !prev);

  const current = useMemo(() => {
    let route = routes.find((route) => currentPath === `${parentPage}/${route.key}`);
    if (route) {
      return {
        icon: <DynamicIcon iconName={route.icon} />,
        label: t(`${translation}.${route.translation}.label`)
      };
    }
    let sub = {};
    route = routes.find((route) =>
      route?.sub?.some((item) => {
        if (currentPath === `${parentPage}/${route.key}/${item.key}`) {
          sub = item;
          return true;
        }
      })
    );

    return {
      icon: <DynamicIcon iconName={route.icon} />,
      label: `${t(`${translation}.${route.translation}.label`)}
, ${t(`${translation}.${route.translation}.sub.${sub.translation}`)}
`
    };
  }, [currentPath, parentPage, routes, t, translation]);

  return (
    <Box
      ref={dropdownRef}
      sx={!isOpen ? style.sidebarDropdown : [style.sidebarDropdown, style.sidebarDropdown.active]}
      className={!isOpen ? "sidebar-dropdown" : "sidebar-dropdown sidebar-dropdown_active"}
    >
      <ButtonBase
        sx={style.sidebarCurrent}
        className="sidebar-dropdown__current"
        onClick={handleOpen}
      >
        <Box sx={style.sidebarCurrent.icon} className="sidebar-dropdown__current-icon">
          {current.icon}
        </Box>
        <Box sx={style.sidebarCurrent.label} className="sidebar-dropdown__current-label">
          {current.label}
        </Box>
        <Box sx={style.sidebarCurrent.arrow} className="sidebar-dropdown__current-arrow">
          <DownIcon />
        </Box>
      </ButtonBase>
      <Box sx={style.body} className="sidebar-dropdown__body">
        <Box component="ul" sx={style.list} className="sidebar-dropdown__list">
          {routes.map((route) => (
            <SidebarDropdownItem
              key={route.key}
              route={route}
              translation={translation}
              parentPage={parentPage}
              handleOpen={handleOpen}
            />
          ))}
          {withExit ? <SidebarDropdownLogout /> : null}
        </Box>
      </Box>
    </Box>
  );
};
