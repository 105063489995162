import { appTheme } from "../../../theme";

export const style = {
  header: {
    position: "absolute",
    left: "0",
    top: "0",
    zIndex: "3",
    width: "100%",
    backgroundColor: "var(--clr-default-100)",

    ".cabinet-button": {
      marginRight: "2.3rem",

      [appTheme.breakpoints.down("bp1200")]: {
        display: "none"
      }
    }
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "8.7rem",

    [appTheme.breakpoints.down("bp992")]: {
      minHeight: "7rem"
    },

    [appTheme.breakpoints.down("bp576")]: {
      minHeight: "6rem"
    },
    ".languages": {
      paddingTop: ".5rem",
      paddingBottom: ".5rem"
    }
  },

  menu: {
    list: {
      display: "flex",
      alignItems: "center"
    },

    item: {
      position: "relative",

      "&.active": {
        ".header__menu-link, .header__menu-button": {
          color: "var(--clr-primary-500)",
          "&::before": {
            opacity: "1",
            visibility: "visible"
          }
        }
      },
      "&.hovered, &:focus": {
        ".header__menu-link": {
          color: "var(--clr-primary-500)",

          "&::before": {
            opacity: "1",
            visibility: "visible"
          }
        },

        ".header__menu-button": {
          color: "var(--clr-primary-500)",

          "&::before": {
            opacity: "1",
            visibility: "visible"
          },

          "&::after": {
            transform: "rotate(180deg)"
          }
        },

        ".header__dropdown": {
          opacity: "1",
          visibility: "visible",
          transform: "translateY(0)"
        }
      }
    },

    someButton: {
      display: "flex",
      alignItems: "center",

      "&::after": {
        content: `""`,
        marginTop: "0.3rem",
        marginLeft: "0.6rem",
        width: "1rem",
        height: "0.5rem",
        backgroundImage: `url("/images/ui/caret-down.svg")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        transition: "transform var(--transition)",
        pointerEvents: "none"
      }
    },

    someLink: {
      display: "flex",
      alignItems: "center"
    },

    ".header__menu-link, .header__menu-button": {
      position: "relative",
      padding: "3.4rem 1.5rem",
      fontFamily: `"SF Pro", sans-serif`,
      fontWeight: "500",
      fontSize: "1.6rem",
      color: "var(--clr-text-400)",
      transition: "color var(--transition)",

      "&::before": {
        content: `""`,
        position: "absolute",
        left: "0",
        right: "0",
        top: "50%",
        zIndex: "-1",
        borderRadius: "1rem",
        height: "4.2rem",
        backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
        opacity: "0",
        visibility: "hidden",
        transform: "translateY(-50%)",
        transition: "opacity var(--transition), visibility var(--transition)"
      },

      "&:hover": {
        color: "var(--clr-primary-400)",
        "&::before": {
          visibility: "visible",
          opacity: "1"
        }
      }
    },

    link: {},

    [appTheme.breakpoints.down("bp1200")]: {
      display: "none"
    }
  },

  dropdownArrow: {
    position: "absolute",
    top: -10,
    "& > svg": {
      height: 22,
      width: 22
    }
  },
  dropdown: {
    position: "absolute",
    // maxHeight: '85vh',
    left: "-8rem",
    top: "calc(100% + 1px)",
    zIndex: "100",
    borderRadius: "3rem",
    padding: "5.2rem 3rem",
    // minWidth: 'max-content',
    boxShadow: "0px 20px 40px rgba(2, 40, 136, 0.2)",
    backgroundColor: "var(--clr-default-100)",
    opacity: "0",
    visibility: "hidden",
    transform: "translateY(1rem)",
    transition: "opacity 0.5s, visibility 0.5s, transform 0.5s",

    "&:before": {
      content: "''",
      display: "flex",
      position: "absolute",
      top: "-2.4rem",
      height: "2.4rem",
      width: "100%"
    },

    buy: {
      padding: "3rem 0 4.5rem",
      // paddingRight: '0 !important',
      // paddingLeft: '0 !important',
      left: "-30rem"
    },
    company: {
      padding: "4.1rem 4.1rem 4.2rem"
    },
    tools: {
      padding: "2.8rem 4.1rem"
    },

    for: {
      paddingRight: "1.5rem"
    },

    [appTheme.breakpoints.down("bp1200")]: {
      display: "none",
      width: "0",
      height: "0"
    }
  },

  proxy: {
    list: {
      display: "grid",
      overflowX: "hidden",
      height: "100%",
      width: "max-content"
    },

    link: {
      fontFamily: `"Gibson", "Noto Sans", sans-serif`,
      position: "relative",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      minHeight: "3.7rem",
      [`@media (hover)`]: {
        "&::before": {
          content: `""`,
          position: "absolute",
          left: "50%",
          top: "50%",
          zIndex: "-1",
          borderRadius: "1rem",
          width: "calc(100% + 2rem)",
          height: "calc(100% + 2rem)",
          backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
          opacity: "0",
          visibility: "hidden",
          transform: "translate(-50%, -50%)",
          transition: "opacity var(--transition), visibility var(--transition)"
        },

        "&:hover::before": {
          opacity: "1",
          visibility: "visible"
        }
      }
    },

    image: {
      flexShrink: "0",
      marginRight: "1rem",

      img: {
        display: "block",
        borderRadius: "50%",
        height: "auto",
        maxWidth: "100%",
        objectFit: "cover"
      }
    },

    label: {
      fontSize: "1.6rem",
      // white-space: nowrap,
      color: "var(--clr-text-900)",
      maxWidth: "11rem",
      minWidth: "11rem",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis"
    },

    buyMod: {
      ".header__proxy-list": {
        // width: "100%",
        gridGap: "4rem",
        gridTemplateColumns: "repeat(6, 1fr)",
        paddingTop: "2rem",
        paddingRight: "3rem",
        paddingLeft: "3rem",
        maxHeight: "370px",
        minHeight: "65px",
        marginRight: "5px",

        [appTheme.breakpoints.down("bp1470")]: {
          gridTemplateColumns: "repeat(5, 1fr)"
        }
      },

      ".header__proxy-image": {
        img: {
          minHeight: "3.2rem"
        }
      },

      ".header__proxy-label": {
        fontWeight: "400"
      },

      ".tabs__header": {
        marginBottom: "1rem !important"
      },

      ".header__dropdown": {
        paddingRight: "0 !important",
        paddingLeft: "0 !important"
      }
    },

    forMod: {
      ".header__proxy-list": {
        gridGap: "2rem",
        gridTemplateColumns: "repeat(2, minmax(17.5rem, 1fr))",
        paddingTop: "2.8rem",
        paddingRight: "1.5rem",
        paddingLeft: "4.6rem",
        paddingBottom: "2.8rem",
        maxHeight: "172px"
      },

      ".header__proxy-image": {
        borderRadius: "50%",
        width: "1.8rem",
        height: "1.8rem",
        backgroundColor: "var(--clr-default-600)"
      },

      ".header__proxy-link": {
        alignItems: "initial"
      },

      ".header__proxy-label": {
        fontWeight: "500"
      },

      ".tabs__content": {
        height: "100% !important",
        padding: "0 !important"
      }
    }
  },

  company: {
    display: "grid",
    gridGap: "5.9rem",
    gridTemplateColumns: "repeat(3, 1fr)",

    // &-title,
    // &-link {
    //   fontWeight: '400',
    //   fontSize: '1.4rem',
    //   whiteSpace: 'nowrap',

    //   &:not(:last-child) {
    //     marginBottom: '1.6rem',
    //   }
    // }

    title: {
      display: "inline-block",
      color: "var(--clr-text-300)",
      fontWeight: "400",
      fontSize: "1.4rem",
      whiteSpace: "nowrap",

      "&:not(:last-child)": {
        marginBottom: "1.6rem"
      }
    },

    link: {
      fontFamily: `"Gibson", "Noto Sans", sans-serif`,
      fontWeight: "400",
      fontSize: "1.6rem",
      whiteSpace: "nowrap",

      "&:not(:last-child)": {
        marginBottom: "1.6rem"
      },
      "& > a": {
        color: "var(--clr-text-900)",
        textDecoration: "none",
        [`@media (hover)`]: {
          position: "relative",

          "&::after": {
            content: `""`,
            position: "absolute",
            left: "0",
            top: "100%",
            width: "0",
            height: "0.1rem",
            backgroundColor: "var(--clr-default-900)",
            transition: "width var(--transition)"
          },

          "&:hover::after": {
            width: "105%"
          }
        }
      },

      "&.active": {
        "& > a::after": {
          width: "105%"
        }
      }
    }
  },

  right: {
    display: "flex",
    alignItems: "center"
  },
  toolContainer: {
    display: "flex",
    alignItems: "center"
  },
  tools: {
    position: "relative",
    padding: "2.25rem 0",

    buttonCommon: {
      display: "flex",
      flex: "0 0 4.2rem",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "1rem",
      width: "4.2rem",
      height: "4.2rem",
      backgroundColor: "rgba(var(--clr-primary-400-rgb) / 20%)",
      transition: "background-color var(--transition)",
      svg: {},
      "&:hover": {
        svg: {
          transition: "fill var(--transition)"
        }
      }
    },
    btnMenu: {
      marginRight: "2.4rem",
      "&:hover": {
        color: "var(--clr-text-100)",
        backgroundColor: "var(--clr-primary-500)",
        transition: "background-color var(--transition)",
        svg: {
          fill: "var(--clr-text-100)"
        }
      }
    },
    btnNotifications: {
      marginRight: "2.4rem",
      position: "relative",

      "&:hover": {
        backgroundColor: "var(--clr-primary-500)",
        transition: "background-color var(--transition)",
        svg: {
          stroke: "var(--clr-text-100)"
        }
      }
    },

    list: {
      display: "grid",
      // gridGap: '2.8rem',
      rowGap: "2.7rem",
      columnGap: "3.8rem",
      gridTemplateColumns: "repeat(3, 1fr)"
    },

    link: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",

      [`@media (hover)`]: {
        "&:hover": {
          ".header__tools-label::after": {
            width: "105%"
          }
        }
      }
    },

    icon: {
      marginRight: "1rem",
      width: "1.8rem",
      height: "1.8rem",

      svg: {
        width: "100%",
        height: "100%",
        fill: "var(--clr-text-900)"
      }
    },

    label: {
      position: "relative",
      fontWeight: "400",
      fontSize: "1.6rem",
      whiteSpace: "nowrap",
      color: "var(--clr-text-900)",

      "&::after": {
        content: `""`,
        position: "absolute",
        left: "0",
        top: "100%",
        width: "0",
        height: "0.1rem",
        backgroundColor: "var(--clr-default-900)",
        transition: "width var(--transition)"
      }
    },

    "& > .header__dropdown": {
      // left: '50%',
      transform: "translate(-50%, 1rem)"
    },

    "&.hovered, &:focus": {
      "& > .header__dropdown": {
        opacity: "1",
        visibility: "visible",
        transform: "translate(-50%, 0)"
      },
      ".header__tools-button": {
        backgroundColor: "var(--clr-primary-500)",
        transition: "background-color var(--transition)",

        svg: {
          fill: "var(--clr-text-100)",
          transition: "fill var(--transition)"
        }
      }
    },

    [appTheme.breakpoints.down("bp1200")]: {
      display: "none"
    }
  }
};
