import { fluid } from "../../../../assets/styles/functions/fluid"
import { appTheme } from "../../../../theme"

export const style = {
  homeGeos: {
    paddingTop: fluid(14, 8),
    paddingBottom: fluid(7, 4),
  },

  wrapper: {
    '& > .heading': {
      marginBottom: '3.5rem',
    },

    [appTheme.breakpoints.down('bp576')]: {
      '.tabs__header': {
        width: 'min(38rem, 100%)',
      },

      '.tabs__item': {
        flexGrow: '1',
      }
    }
  },

  list: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(27rem, 1fr))',

    [appTheme.breakpoints.down('bp767')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(20rem, 1fr))',
    },

    [appTheme.breakpoints.down('bp576')]: {
      gridGap: '1.5rem',
      gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
    }
  },

  item: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '4.5rem',
    boxShadow: '0 1.2rem 2rem rgba(197 202 213 / 20%)',
    backgroundColor: 'var(--clr-default-100)',
    transition: 'box-shadow var(--transition)',

    [`@media (hover)`]: {
      '&:hover': {
        boxShadow: '0 2rem 2rem rgba(179 184 202 / 22%)',
        transition: 'box-shadow var(--transition)',
      }
    },

    '& > a': {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 2rem',
      textDecoration: 'none',
      color: 'inherit',
      [appTheme.breakpoints.down('bp576')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      }
    },

    image: {
      flexShrink: '0',
      height: fluid(7, 3.8),
      maxWidth: fluid(7, 3.8),

      img: {
        borderRadius: '50%',
        height: 'auto',
        minHeight: fluid(7, 3.8),
        maxWidth: '100%',
        objectFit: 'cover',
      },

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '1rem',
      }
    },

    info: {
      paddingLeft: '2rem',
      textAlign: 'left',

      [appTheme.breakpoints.down('bp576')]: {
        paddingLeft: '0',
        textAlign: 'center',
      }
    },

    name: {
      marginBottom: '0.5rem',
      fontWeight: '600',
      fontSize: fluid(2, 1.5),
      color: 'var(--clr-text-900)',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom:' 0.2em',
      }
    },

    price: {
      fontWeight: '400',
      fontSize: fluid(1.4, 1.2),
    },

    tagMod: {
      new: {
        backgroundImage: `url("/images/icons/tags/new.svg")`,
        backgroundPosition: 'right top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',

        [appTheme.breakpoints.down('bp576')]: {
          backgroundImage: 'none',
        }
      },

      discount: {
        backgroundImage: `url("/images/icons/tags/discount.svg")`,
        backgroundPosition: 'right top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',

        [appTheme.breakpoints.down('bp576')]: {
          backgroundImage: 'none',
        }
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      borderRadius: '1rem',
      padding: '1rem',
      boxShadow: 'none',
      backgroundColor: 'var(--clr-default-200)',
    }
  },

  showAll: {
    marginTop: '3.5rem',
    textAlign: 'center',

    '.button': {
      maxWidth: '28rem',
    }
  }
}
