import { appTheme } from "../../../../theme";

export const style = {
  grid: {
    display: "grid",
    gridGap: "3rem",
    gridTemplateColumns: "repeat(auto-fill, minmax(37rem, 1fr))",
    marginBottom: "4.5rem",
    [appTheme.breakpoints.down("bp576")]: {
      gridTemplateColumns: "1fr",
    }
  },

  pagination: {
    height: "4.6rem",
    maxWidth: "30rem",
    width: "100%",
    margin: "0 auto"
  }
}