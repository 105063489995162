import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  popover: {
    padding: "1rem 0"
  },
  arrow: {
    position: "absolute", top: 0
  },
  content: {
    borderRadius: "0.5rem",
    backgroundColor: "var(--clr-default-100)",
    padding: "10px 18px",
    fontSize: "1.4rem",
    fontWeight: 400,
    boxShadow: "0 2px 22px -12px #5F6780",
  },
  button: {
    verticalAlign: "baseline",

    position: "relative",
    fontWeight: "500",
    fontSize: fluid(1.6, 1.4),
    whiteSpace: "nowrap",
    color: "var(--clr-primary-400)",
    textDecoration: "none",

    "&::after": {
      content: `""`,
      position: "absolute",
      left: "0",
      top: "100%",
      width: "100%",
      height: "0.1rem",
      backgroundColor: "var(--clr-primary-400)",
      transition: "width var(--transition)"
    },

    "&:hover::after": {
      width: "0"
    }
  }
}
