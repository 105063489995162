import { Box, Skeleton } from "@mui/material";
import { style } from "./reviews-skeleton.style";

const ReviewsItem = () => (
  <Box sx={style.item}>
    <Box sx={style.paragraph}>
      <Skeleton className="string" />
      <Skeleton className="string" />
      <Skeleton className="string" />
      <Skeleton className="string" />
    </Box>

    <Box sx={style.item.bottomRow}>
      <Box sx={style.profile}>
        <Skeleton variant="rounded" sx={style.profile.photo} />
        <Skeleton variant="rounded" sx={style.profile.name} />
      </Box>

      <Skeleton variant="rounded" sx={style.item.link} />
    </Box>
  </Box>
);

export const ReviewsSkeleton = ({ reviewsCount = 4, hasButton = true }) => (
  <Box sx={style.container}>
    <Box sx={style.grid}>
      {Array.from(Array(reviewsCount).keys()).map((el) => <ReviewsItem key={el} />)}
    </Box>

    {hasButton && (
      <Box sx={style.buttonRow}>
        <Skeleton variant="rounded" sx={style.button} />
        <Skeleton variant="rounded" sx={style.button} />
      </Box>
    )}
  </Box>
);
