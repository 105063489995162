import Faq from "../pages/app/faq/faq";
import Promocodes from "../pages/app/promocodes/promocodes";
import ActionsHistory from "../pages/cabinet/actions-history/actions-history";
import Balance from "../pages/cabinet/balance/balance";
import DevelopersApi from "../pages/cabinet/developers-api/developers-api";
import NewOrder from "../pages/cabinet/new-order/new-order";
import Orders from "../pages/cabinet/orders/orders";
import PaymentHistory from "../pages/cabinet/payment-history/payment-history";
import Profile from "../pages/cabinet/profile/profile";
import RedirectChangeEmail from "../pages/cabinet/redirect-change-email/redirect-change-email";
import Subscriptions from "../pages/cabinet/subscription/subscription";
import { ROUTE } from "../utils/constants";

export const cabinetRoutes = (ssr = false) => [
  {
    path: `/:lang?${ROUTE.CABINET.PROFILE}`,
    tag: "profile",
    isSsr: ssr ?? false,
    component: ssr ? Profile : () => import("../pages/cabinet/profile/profile")
  },
  {
    path: `/:lang?${ROUTE.CABINET.REDIRECT_CHANGE_EMAIL}/:hash`,
    tag: "redirect-change-email",
    isSsr: ssr ?? false,
    component: ssr
      ? RedirectChangeEmail
      : () => import("../pages/cabinet/redirect-change-email/redirect-change-email")
  },
  {
    path: `/:lang?${ROUTE.CABINET.ORDERS}/ipv4`,
    tag: "orders/ipv4",
    isSsr: ssr ?? false,
    component: ssr ? Orders : () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang?${ROUTE.CABINET.ORDERS}/ipv6`,
    tag: "orders/ipv6",
    isSsr: ssr ?? false,
    component: ssr ? Orders : () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang?${ROUTE.CABINET.ORDERS}/isp`,
    tag: "orders/isp",
    isSsr: ssr ?? false,
    component: ssr ? Orders : () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang?${ROUTE.CABINET.ORDERS}/mobile-proxy`,
    tag: "orders/mobile-proxy",
    isSsr: ssr ?? false,
    component: ssr ? Orders : () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang?${ROUTE.CABINET.ORDERS}/inactive-partitions`,
    tag: "inactive-partitions",
    isSsr: ssr ?? false,
    component: ssr ? Orders : () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang?${ROUTE.CABINET.NEW_ORDER}`,
    tag: "new-order",
    isSsr: ssr ?? false,
    component: ssr ? NewOrder : () => import("../pages/cabinet/new-order/new-order")
  },
  {
    path: `/:lang?${ROUTE.CABINET.BALANCE}`,
    tag: "balance",
    isSsr: ssr ?? false,
    component: ssr ? Balance : () => import("../pages/cabinet/balance/balance")
  },
  {
    path: `/:lang?${ROUTE.CABINET.PAYMENT_HISTORY}`,
    tag: "payment-history",
    isSsr: ssr ?? false,
    component: ssr
      ? PaymentHistory
      : () => import("../pages/cabinet/payment-history/payment-history")
  },
  {
    path: `/:lang?${ROUTE.CABINET.ACTIONS_HISTORY}`,
    tag: "actions-history",
    isSsr: ssr ?? false,
    component: ssr
      ? ActionsHistory
      : () => import("../pages/cabinet/actions-history/actions-history")
  },
  {
    path: `/:lang?${ROUTE.CABINET.PROMO_CODES}`,
    tag: "cabinet-promocodes",
    isSsr: ssr ?? false,
    component: ssr ? Promocodes : () => import("../pages/cabinet/promocodes/promocodes")
  },
  {
    path: `/:lang?${ROUTE.CABINET.SUBSCRIPTION}`,
    tag: "subscription",
    isSsr: ssr ?? false,
    component: ssr ? Subscriptions : () => import("../pages/cabinet/subscription/subscription")
  },
  {
    path: `/:lang?${ROUTE.CABINET.SUBSCRIPTION}/:token`,
    tag: "subscription",
    isSsr: ssr ?? false,
    component: ssr ? Subscriptions : () => import("../pages/cabinet/subscription/subscription")
  },
  {
    path: `/:lang?${ROUTE.CABINET.FAQ}`,
    tag: "cabinet-faq",
    isSsr: ssr ?? false,
    component: ssr ? Faq : () => import("../pages/cabinet/faq/faq")
  },
  {
    path: `/:lang?${ROUTE.CABINET.DEVELOPERS_API}`,
    tag: "developers-api",
    isSsr: ssr ?? false,
    component: ssr ? DevelopersApi : () => import("../pages/cabinet/developers-api/developers-api")
  }
];
