import React from "react";
import { styles } from "./payment-systems.style";
import { Box } from "@mui/material";

/**
 *
 * @param {Object} props
 * @param {Array.<{key: String, src: String}>} props.elements
 * @returns {React.ReactNode}
 */
const PaymentSystems = (props) => {
  const {elements} = props;

  return (
    <Box sx={styles.list} component="ul">
      {elements?.map((element) => (
        <Box key={element.key} sx={styles.item} component="li">
          <Box
            sx={styles.image}
            component="img"
            src={element.src}
            alt={element.key}
            loading="lazy"
          />
        </Box>
      ))}
    </Box>
  );
};

export default PaymentSystems;
