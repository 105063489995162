import React, { forwardRef } from "react";
import { SnackbarProvider as Provider } from "notistack";
import ResponsiveSnackbar from "./responsive-snackbar";

const SnackbarProvider = forwardRef((props, ref) => {

  return (
    <Provider
      ref={ref}
      classes={{
        containerRoot: "custom-snackbar"
      }}
      maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      Components={{
        default: ResponsiveSnackbar,
        success: ResponsiveSnackbar,
        error: ResponsiveSnackbar,
        warning: ResponsiveSnackbar
      }}

      {...props}
    />
  );
});


export default SnackbarProvider;
