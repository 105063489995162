// eslint-disable-next-line import/namespace
import { Helmet } from "react-helmet-async";

import useCurrentSeo from "../../../hooks/use-current-seo";
import { generateMetaTagsAndLinks } from "../../../utils/helpers";

// TODO Filling SEO from one point. Functionality is repeated in server.ssr.js
const Metadate = () => {
  const seo = useCurrentSeo();

  const title = seo?.title;
  const description = seo?.description;
  const language = seo?.language;

  // console.count("Metadate");

  return <Helmet>{generateMetaTagsAndLinks(title, description, language)}</Helmet>;
};

export default Metadate;
