import { Box, Skeleton } from "@mui/material";
import { style } from "./mobile-proxy-skeleton.style";

export const MobileProxySkeleton = () => (
  <Box sx={style.container}>
    <Box sx={style.topRow}>
      <Skeleton variant="rounded" sx={style.topRow.heading} />
      <Skeleton variant="rounded" sx={style.topRow.button} />
    </Box>

    <Box sx={style.content}>
      {/* form start */}
      <Box sx={style.form}>
        <Skeleton variant="rounded" sx={style.select} />
        <Skeleton variant="rounded" sx={style.select} />
        {/* range slider */}
        <Box sx={style.slider}>
          <Box sx={style.slider.row}>
            <Skeleton variant="rounded" sx={style.slider.label} />
            <Skeleton variant="rounded" sx={style.slider.field} />
          </Box>
          <Skeleton variant="rounded" sx={style.slider.line} />
        </Box>

        {/* form price block */}
        <Box sx={style.formPrice}>
          <Skeleton variant="rounded" sx={style.formPriceTop} />
          <Skeleton variant="rounded" sx={style.formPriceBottom} />
        </Box>

        {/* form radio group */}
        <Box sx={style.radioGroup}>
          <Skeleton variant="rounded" sx={style.radioGroup.item} />
          <Skeleton variant="rounded" sx={style.radioGroup.item} />
          <Skeleton variant="rounded" sx={style.radioGroup.item} />
          <Skeleton variant="rounded" sx={style.radioGroup.item} />
          <Skeleton variant="rounded" sx={style.radioGroup.item} />
        </Box>
      </Box>
      {/* form end */}

      {/* mobile proxy specs */}
      <Box sx={style.specs}>
        <Skeleton variant="rounded" sx={style.title} />
        <Box sx={style.countryRow}>
          <Skeleton variant="rounded" sx={style.countryRow.leftItem} />
          <Skeleton variant="rounded" sx={style.countryRow.rightItem} />
        </Box>
        <Skeleton variant="rounded" sx={style.specs.row} />
        <Skeleton variant="rounded" sx={style.specs.row} />
        <Skeleton variant="rounded" sx={style.specs.row} />
        <Skeleton variant="rounded" sx={style.specs.row} />

        <Box sx={style.note}>
          <Skeleton variant="rounded" sx={style.note.heading} />
          <Box sx={style.note.paragraph}>
            <Skeleton variant="rounded" className="string" />
            <Skeleton variant="rounded" className="string" />
          </Box>
        </Box>
      </Box>
      {/* mobile proxy specs end */}

      {/* mobile proxy summary */}
      <Box sx={style.summary}>
        <Skeleton variant="rounded" sx={style.title} />
        <Box sx={style.summary.row}>
          <Skeleton variant="rounded" sx={style.summary.mainLabel} />
        </Box>
        <Box sx={style.summary.row}>
          <Skeleton variant="rounded" sx={style.summary.headLabel} />
          <Skeleton variant="rounded" sx={style.summary.value} />
        </Box>

        <Box sx={style.summary.row}>
          <Skeleton variant="rounded" sx={style.summary.headLabel} />
          <Skeleton variant="rounded" sx={style.summary.value} />
        </Box>

        <Box sx={style.summaryPrice}>
          <Box sx={style.summaryPrice.row}>
            <Skeleton variant="rounded" sx={style.summaryPrice.price} />
            <Skeleton variant="rounded" sx={style.summaryPrice.discount} />
          </Box>
          <Skeleton variant="rounded" sx={style.summaryPrice.bottomRow} />
        </Box>

        <Skeleton variant="rounded" sx={style.promoField} />
        <Skeleton variant="rounded" sx={style.button} />
      </Box>
      {/* mobile proxy summary end */}
    </Box>
  </Box>
);
