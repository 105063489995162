
export const InfoIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1217_16590)">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 6.91406C10.6904 6.91406 11.25 6.37191 11.25 5.70312C11.25 5.03434 10.6904 4.49219 10 4.49219C9.30964 4.49219 8.75 5.03434 8.75 5.70312C8.75 6.37191 9.30964 6.91406 10 6.91406ZM10 7.8125C9.2665 7.8125 8.67188 8.40712 8.67188 9.14062V14.3359C8.67188 15.0694 9.2665 15.6641 10 15.6641C10.7335 15.6641 11.3281 15.0694 11.3281 14.3359V9.14062C11.3281 8.40712 10.7335 7.8125 10 7.8125Z" fill="#517FF5"/>
      </g>
      <defs>
        <clipPath id="clip0_1217_16590">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
