import { Box } from "@mui/material";
import { useCollapse } from "../../../hooks/use-collapse";
import { style } from "./accordion.style";

export const Accordion = ({ label, children, defaultOpen }) => {
  const { collapseRef, isCollapseOpen, handleCollapse } = useCollapse({ defaultValue: defaultOpen });

  return (
    <Box sx={isCollapseOpen ? style.accOpen : {}} className={!isCollapseOpen ? "accordion" : "accordion accordion_open"}>
      <Box sx={style.accHeader} className="accordion__header" onClick={handleCollapse}>
        <Box sx={style.accLabel} className="accordion__label" itemProp="name">
          {label}
        </Box>
        {/* <div className="accordion__icon"></div> */}
      </Box>
      <Box
        sx={style.accBody}
        className="accordion__body"
        ref={collapseRef}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <Box itemProp="text">{children}</Box>
      </Box>
    </Box>
  );
};
