export const LOCALS_LINK = [
  {
    rel: "alternate",
    hrefLang: "en",
    href: "https://youproxy.io/en/"
  },
  {
    rel: "alternate",
    hrefLang: "ru",
    href: "https://youproxy.io/ru/"
  },
  {
    rel: "alternate",
    hrefLang: "uk",
    href: "https://youproxy.io/uk/"
  }
];
