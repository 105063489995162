export const style = {
  selectWrapper: {
    cursor: "pointer",
    // transition: "0.3s",
    position: "relative",
    width: "100%",
    color: "var(--clr-text-900)",
    fontSize: "1.4rem",
    fontWeight: "400",
    fontFamily: "inherit",
    ".react-select": {
      "&__menu": {
        "&::before": {
          content: `""`,
          display: "block",
          position: "relative",
          marginLeft: "auto",
          marginRight: "1.9rem",
          zIndex: "1",
          background: `url("/images/icons/arrow-icon.svg")`,
          width: "1.5rem",
          height: "1.5rem",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          // boxShadow: "0 2px 22px rgb(95 103 128 / 14%)",
          transform: "translateY(50%)"
          // border: ""
          // filter: "drop-shadow(0 2px 22px rgb(95 103 128 / 14%))"
        },
        "&-list": {
          // maxHeight: "20rem",
          // borderRadius: "1.2rem",
          // background: "#fff",
          // boxShadow: "0 2px 22px rgb(95 103 128 / 14%)",
          // overflow: "hidden",
          // width: "100%",
          // left: "0",
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--clr-ui-scroll-thumb)",
            border: "3px solid transparent",
            // borderTop: "13px solid transparent",
            backgroundClip: "padding-box",
            borderRadius: "1rem"
          },
          "&::-webkit-scrollbar": {
            // transform: "translateX(-100%)",
            // marginLeft: "-10px"
            borderTop: "13px solid transparent",
            width: "1.2rem",
            background: "transparent"
          },

          "&::-webkit-scrollbar-track": {
            background: "transparent"
          }
        }
      },

      "&__container": {
        position: "unset",
        width: "100%"
      },

      "&__option": {
        // fontSize: "1.8rem",
        // cursor: "pointer",
        // // var(--clr-text-400)
        // color: "#63637C",
        // fontWeight: "400",
        // display: "flex",
        // alignItems: "center",
        // padding: "0.5rem 1.6rem",
        // minHeight: "1.8rem",
        // transition: "0.3s",
        // backgroundColor: "#fff",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        // width: "100%",
        // "&:hover": {
        //   // var(--clr-default-300)
        //   backgroundColor: "#EEF2FC",
        // },
        "&--is-selected": {
          // var(--clr-default-300)
          backgroundColor: "#DCE5FD",
          pointerEvents: "none",
          cursor: "default"
        }
      },

      "&__control": {
        cursor: "pointer"
      },

      "&__indicator": {
        height: "2.4rem",
        width: "2.4rem",
        display: "grid",
        placeItems: "center",
        background: "#517FF5",
        borderRadius: "50%",
        svg: {
          height: "1.5rem",
          width: "1.5rem",
          fill: "#fff"
        }
      },

      "&__single-value": {
        display: "flex",
        alignItems: "center"
      }
    }
  },

  //visual styles for isOuterLabel === true
  selectOuterLabel: {
    transition: "0.3s border-color",
    position: "relative",
    borderRadius: "6rem",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    minHeight: "4.8rem",
    maxHeight: "4.8rem",
    padding: "1rem 1.4rem",
    paddingRight: "1.4rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #D7DBEC",
    background: "#fff",
    ".react-select": {
      "&__control": {
        //var(--clr-ui-border-200)
        position: "unset"
      }
    },

    "&:hover": {
      borderColor: "#517FF5"
    },
    "&.MuiInputBase-root": {
      cursor: "text",
      "&:hover, &.Mui-focused": {
        borderColor: "#517FF5"
      }
    },

    "&.open": {
      borderColor: "#517FF5"
    }
  },

  //visual styles for isOuterLabel === false
  selectInnerLabel: {
    border: "1px solid transparent",
    transition: "0.3s background, 0.3s border-color",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    backgroundColor: "var(--clr-default-200)",
    boxShadow: "inset 0 0 2rem rgb(197 202 213 / 20%)",
    borderRadius: "6rem",
    padding: "1rem 1.4rem",
    paddingRight: "1.4rem",
    minHeight: "4.8rem",
    maxHeight: "4.8rem",
    "&:hover": {
      backgroundColor: "#ebeff8",
      "& input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
      }
    },

    "&.MuiInputBase-root": {
      cursor: "text",
      padding: "1rem 1.4rem",
      paddingRight: "1rem",
      "&:hover, &.Mui-focused": {
        backgroundColor: "#ebeff8",
        "& input:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
        }
      },

      "& .MuiInputBase-input": {
        paddingRight: "1rem"
        // paddingRight: "0",
      },

      label: {
        marginRight: "5px",
        cursor: "text"
      }
    },

    // ".react-select": {
    //   "&__control": {
    //     // display: "flex",
    //     backgroundColor: "var(--clr-default-200)",
    //     boxShadow: "inset 0 0 2rem rgb(197 202 213 / 20%)",
    //     "& > span": {
    //       marginRight: "10px",
    //     },
    //     "&:hover": {
    //       backgroundColor: "#ebeff8",
    //       "& input:-webkit-autofill": {
    //         "-webkit-box-shadow": "0 0 0px 1000px #ebeff8 inset",
    //       },
    //     },
    //   },
    // },

    "&.open": {
      backgroundColor: "#ebeff8",
      // borderColor: "#517FF5",
      "& input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
      }
    },

    textInput: {
      cursor: "text",
      padding: "0"
    }
  },

  focused: {
    backgroundColor: "#ebeff8",
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
    }
  },

  // error styles
  error: {
    borderColor: "#FF0000 !important"
  },

  errorText: {
    color: "#ff0000",
    fontSize: "1.2rem",
    margin: "3px 14px 0 14px"
  },

  // disabled styles
  disabled: {
    pointerEvents: "none",
    ".react-select": {
      "&__indicator": {
        background: "#B5B6B9"
      }
    }
  },

  //label styles
  label: {
    fontWeight: "400",
    fontSize: "1.4rem",
    whiteSpace: "nowrap"
  },

  outerLabel: {
    display: "inline-block",
    marginBottom: "0.8rem",
    //var(--clr-text-400)
    color: "#64647D"
  },

  innerLabel: {
    cursor: "pointer",
    color: "var(--clr-text-900)",
    fontSize: "1.4rem",
    fontWeight: "400",
    fontFamily: "inherit"
  },

  textLabel: {
    position: "absolute",
    zIndex: "2",
    top: "50%",
    transform: "translateY(-50%)",
    left: "1.4rem"
  },

  // close button for text input
  iconButton: {
    width: "2.4rem",
    height: "2.4rem",
    padding: "5px",
    background: "var(--clr-primary-400)",
    borerRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    svg: {
      path: {
        stroke: "#fff"
      }
    },
    "&:hover": {
      background: "var(--clr-primary-400)"
    }
  },

  // ip input

  ipInputV2: {
    width: "100%",
    marginLeft: "15px",
    background: "transparent",
    height: "22px",
    transition: "0.3s"
  },
  ipInput: {
    width: "100%",
    marginLeft: "15px",
    ".react-ip-input": {
      // background: "#000"
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "none",
      borderRadius: "unset",
      "&__item input": {
        background: "transparent",
        height: "22px",
        color: "var(--clr-text-900)",
        fontSize: "1.4rem",
        fontWeight: "400",
        transition: "0.3s",
        borderBottom: "1px solid var(--clr-ui-border-200)"
      }
    }
  },

  // table select styles
  tableSelect: {
    color: "#fff",
    background: "transparent",
    textAlign: "left",
    border: "none",
    boxShadow: "none",
    padding: "0px",
    paddingLeft: "0px",
    // maxHeight: "2.4rem",
    // minHeight: "2.4rem",
    "&:hover": {
      background: "none"
    },

    "&.open": {
      background: "none",
      border: "none"
    },

    ".react-select": {
      "&__indicator": {
        background: "transparent",
        svg: {
          fill: "#fff"
        }
      }
    }
  },

  dropItemImg: {
    width: "21px",
    minWidth: "21px",
    height: "21px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "10px",
    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  }
};
