import { Box } from "@mui/material";
import { UpDownIcon } from "../../../components/icons/index";
import { style } from "./table.style";

export const TableSort = ({ children, onClick, direction = null }) => (
  <Box sx={style.tableSort} className="table-sort" onClick={onClick}>
    {children}
    <UpDownIcon sortDirection={direction} />
  </Box>
);
