import { useEffect, useRef, useState } from "react";

import { Box, ButtonBase, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { ArrowIcon } from "../../../../../assets/images/icons/arrow-icon";
import { Tabs } from "../../../../../components/ui/tabs/tabs";
import { useLang, useMatchedRoute, useTranslateOptions } from "../../../../../hooks";
import { getProxyState } from "../../../../../store/selectors";
import { getCountryFlagIso2 } from "../../../../../utils/helpers/get-country-flag";
import { style } from "../../header.style";
import { useMenuHover } from "../header-menu.hooks";

export const BuyProxy = () => {
  const matchedRoute = useMatchedRoute();
  const { proxyOptionsWithLink, proxies } = useSelector(getProxyState);
  const [proxyType, setProxyType] = useState("");
  const { search } = useLocation();
  const proxyTab = new URLSearchParams(search).get("proxyTab");

  const menuRef = useRef(null);
  const arrowRef = useRef(null);

  // Translations
  const lang = useLang();
  const { t } = useTranslation();
  const buyProxyOptions = useTranslateOptions(proxyOptionsWithLink, t("buyProxy"));

  const { menuHover, disableMenuHover } = useMenuHover(menuRef, arrowRef);

  useEffect(() => {
    if (proxyOptionsWithLink.length) {
      if (!proxyTab) {
        setProxyType(buyProxyOptions[0].key);
      } else {
        setProxyType(proxyTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyTab, proxyOptionsWithLink]);

  const tags = ["countries", "ipv6-proxy", "isp-proxy", "mobile-proxy"];
  const isActive = tags.includes(matchedRoute?.tag);

  return (
    <Box
      sx={style.menu.item}
      className={`header__menu-item ${isActive ? "active" : ""}`}
      onMouseOver={menuHover}
      onMouseLeave={disableMenuHover}
      ref={menuRef}
    >
      <ButtonBase
        sx={style.menu.someButton}
        className="header__menu-button"
        aria-label={t("menu.buyProxy")}
      >
        {t("menu.buyProxy")}
      </ButtonBase>
      <Box sx={[style.dropdown, style.dropdown.buy]} className="header__dropdown">
        <Box ref={arrowRef} sx={style.dropdownArrow}>
          <ArrowIcon />
        </Box>
        <Box sx={[style.proxy, style.proxy.buyMod]} className="header__proxy header__proxy_buy">
          <Tabs linkCallback={disableMenuHover} name="proxyTab" tabs={buyProxyOptions} size="large">
            <Box component="ul" sx={style.proxy.list} className="header__proxy-list">
              {proxies[proxyType.toUpperCase()]?.countries.map((country, idx) => (
                <Box
                  component="li"
                  sx={style.proxy.item}
                  key={`${idx}:${country.code}`}
                  className="header__proxy-item"
                >
                  <Link
                    component={RouterLink}
                    sx={style.proxy.link}
                    className="header__proxy-link"
                    to={
                      proxyType.toUpperCase() === "IPV4"
                        ? `/${lang}/${country.urlParam}/`
                        : `/${lang}/${proxyType.toLowerCase()}/?country=${country.urlParam}`
                    }
                    onClick={disableMenuHover}
                  >
                    <Box sx={style.proxy.image} className="header__proxy-image">
                      <img
                        src={getCountryFlagIso2(country.code)}
                        alt={country.namesDependingOnLocale[lang]}
                        width={32}
                        height={32}
                        loading="lazy"
                      />
                    </Box>
                    <Box component="span" sx={style.proxy.label} className="header__proxy-label">
                      {country.namesDependingOnLocale[lang]}
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};
