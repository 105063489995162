import { Box, Skeleton } from "@mui/material";
import { style } from "./table.style";

export const SkeletonItem = () => (
  <Box className="skeleton-item" sx={style.skeletonItem}><Skeleton variant="rectangular" animation="wave" width="100%" height="100%" /></Box>
);

export const TableSkeleton = ({ count, hasGroupRow = false }) => {

  const countArray = [...Array(hasGroupRow ? count + 1 : count).keys()];

  return (
    <Box component="tr">
      {/* <h1>Table head!!!</h1> */}
      <Box component="td" colSpan="100%" sx={style.skeletonBlock}>
        {countArray.map((el, i) => <SkeletonItem key={`skeleton-item-${i}`} />)}
      </Box>
    </Box>
  );
};
