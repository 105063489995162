import { useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "../../../../../components/icons/index";
import { Button } from "../../../../../components/ui/button/button";
import { Heading } from "../../../../../components/ui/heading/heading";
import { Modal } from "../../../../../components/ui/modal/modal";
import { IpCommentService } from "../../../../../services/ipComment.service";
import { style } from "../tableOptions.style";

export const DeleteComment = ({ successFunc, disabled = false, ipId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // Translations
  const { t } = useTranslation();

  const handleOpen = () => setIsOpen((prev) => !prev);

  const handleDelete = () => {
    IpCommentService.deleteComment(ipId)
      .then(() => {
        enqueueSnackbar(t("shared.table.options.deleteComment.success"), { variant: "success" });
        successFunc(false);
        handleOpen();
      })
      .catch((e) => {
        enqueueSnackbar(t("shared.table.options.deleteComment.error"), { variant: "error" });
        // eslint-disable-next-line no-console
        console.log(e.message);
      });
  };

  return (
    <>
      <Box
        compponent="li"
        sx={!disabled ? style.item : [style.item, style.item.disabled]}
        className={!disabled ? "table-options__item" : "table-options__item--disabled"}
        onClick={handleOpen}
      >
        <TrashIcon />
        {t("shared.table.options.deleteComment.label")}
      </Box>
      <Modal customStyles={style.modal} visible={isOpen} cancelHandler={handleOpen}>
        <Box
          sx={[style.comment, style.comment.deleteMod]}
          className="table-options__comment table-options__comment_delete"
        >
          <Heading tag="div" variant="h4">
            {t("shared.table.options.deleteComment.title")}
          </Heading>
          <Box sx={style.comment.text} className="table-options__comment-text">
            {t("shared.table.options.deleteComment.text")}
          </Box>

          <Box sx={style.comment.buttons} className="table-options__comment-buttons">
            <Button onClick={handleOpen} size="modal">
              {t("form.actions.cancel")}
            </Button>
            <Button onClick={handleDelete} size="modal" variant="primary">
              {t("shared.table.options.deleteComment.label")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
