import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_SEO } from "../../utils/constants";

import { fetchPageSeo, getSeoContent, getSeoDefault } from "./seo.thunks";

const initialState = {
  defaultLoaded: false,
  defaultData: DEFAULT_SEO,
  data: {}, // {[localeCode]: {[tagName]: {...data}}}
  loading: false,
  errors: null,
  contentLoaded: false,
  pageParams: { tag: "", languageCode: "" },
  loadedPagesTagsByLocale: {}
};

const SeoSlice = createSlice({
  name: "seo",
  initialState,
  reducers: {
    setSeoPageParams(state, action) {
      state.pageParams = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchPageSeo.fulfilled, (state, action) => {
        const { languageCode, tag, tagPostfix } = action.payload;
        const tagWithPostfix = tag + tagPostfix;

        state.loadedPagesTagsByLocale = {
          ...state.loadedPagesTagsByLocale,
          [languageCode]: [...(state.loadedPagesTagsByLocale[languageCode] ?? []), tagWithPostfix]
        };

        if (action.payload.data) {
          state.data = {
            ...state.data,
            [languageCode]: {
              ...state.data[languageCode],
              [tagWithPostfix]: action.payload.data
            }
          };
        }

        state.loading = false;
      })
      .addCase(getSeoDefault.fulfilled, (state, action) => {
        state.defaultData = {
          ...state.defaultData,
          ...{
            title: action.payload.default_seo_title,
            description: action.payload.default_seo_description
          }
        };
        state.defaultLoaded = true;
      })
      .addCase(getSeoContent.fulfilled, (state, action) => {
        state.contentLoaded = true;

        const obj = {};
        Object.entries(action.payload).forEach(([locale, dataArr]) => {
          obj[locale] = {};
          dataArr.forEach((page) => {
            obj[locale][page.tag] = page;
          });
        });
        state.data = obj;
      })
      .addMatcher(
        (action) => action.type.endsWith("pending"),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("rejected"),
        (state, action) => {
          state.errors = action.payload;
          state.loading = false;
        }
      );
  }
});

export const SeoActions = {
  ...SeoSlice.actions,
  fetchPageSeo,
  getSeoDefault,
  getSeoContent
};

export default SeoSlice.reducer;
