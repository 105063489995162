import { useEffect } from "react";

import { ErrorIcon, SuccessIcon } from "../../../components/icons/index";

import "./toast.scss";

export const Toast = ({
  type,
  message,
  open,
  setOpen,
  duration = 1500,
  width,
  position = "right",
  children
}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open) {
        setOpen({ status: false, message, type });
      }
    }, duration);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, open, setOpen]);

  const defineClasses = () => {
    let classes = "toast";

    switch (type) {
      case "success":
        classes += " toast_type_success";
        break;
      case "error":
        classes += " toast_type_error";
        break;
      default:
        break;
    }

    switch (position) {
      case "left":
        classes += " toast_position_left";
        break;
      case "right":
        classes += " toast_position_right";
        break;
      default:
        break;
    }

    if (open) classes += " toast_open";

    return classes;
  };

  return (
    <div className={defineClasses()}>
      <div className="toast__target">{children}</div>
      <div className="toast__content" style={{ minWidth: width }}>
        {message}
        {type === "success" && <SuccessIcon />}
        {type === "error" && <ErrorIcon />}
      </div>
    </div>
  );
};
