
export const OptionsIcon = () => (
  <svg width="3" height="18" viewBox="0 0 3 18" fill="#343435" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.25" cy="1.25" r="1.25" />
    <path
      d="M2.5 9C2.5 9.69036 1.94036 10.25 1.25 10.25C0.559644 10.25 0 9.69036 0 9C0 8.30964 0.559644 7.75 1.25 7.75C1.94036 7.75 2.5 8.30964 2.5 9Z" />
    <path
      d="M2.5 16.75C2.5 17.4404 1.94036 18 1.25 18C0.559644 18 0 17.4404 0 16.75C0 16.0596 0.559644 15.5 1.25 15.5C1.94036 15.5 2.5 16.0596 2.5 16.75Z" />
  </svg>
);
