//hook for receiving table data
import { useEffect, useState } from "react";

import { OrdersService } from "../../services/orders.service";

//** proxyType === "IPv4" || "IPv6" || "MOBILE"
export const useTableData = ({
  proxyTypeId,
  pageObj,
  sort,
  filter,
  asyncFunction,
  isOrdersPage = false,
  shouldUpdate = false
}) => {
  const [tableData, setTableData] = useState({});
  const [ordersData, setOrdersData] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);

  //helpers
  const getSortAndFilter = (sort, filter) => ({
    sort: sort?.key && sort?.value ? [sort] : [],
    search: filter?.value && filter?.value !== "all" ? [filter] : []
  });

  // works only for orders tables
  useEffect(() => {
    if (isOrdersPage && proxyTypeId) {
      setTableLoading(true);
      const reqObj = {
        page: pageObj.page,
        size: pageObj.pageSize,
        proxyTypeId,
        filterCreatedDateStart: "",
        filterCreatedDateEnd: "",
        ...getSortAndFilter(sort, filter)
      };
      Promise.all([asyncFunction(reqObj), OrdersService.getOrdersData(reqObj)])
        .then((res) => {
          // console.log(res[0]?.data);
          // console.log(res[1]?.data);
          if (res[0].data) setTableData(res[0].data);
          if (res[1]?.data) setOrdersData(res[1].data);
          setTableLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
          setTableLoading(false);
        });
    }
  }, [pageObj.page, pageObj.pageSize, sort, filter, proxyTypeId, shouldUpdate]);

  // works for any tale except orders table
  useEffect(() => {
    if (!isOrdersPage && asyncFunction) {
      setTableLoading(true);

      const reqObj = {
        page: pageObj.page,
        size: pageObj.pageSize,
        filterCreatedDateStart: "",
        filterCreatedDateEnd: "",
        ...getSortAndFilter(sort, filter)
      };

      asyncFunction(reqObj)
        .then((res) => {
          if (res.data) {
            setTableData(res.data);
            setTableLoading(false);
          }
        })
        .catch((e) => {
          setTableLoading(false);
          console.log(e.message);
        });
    }
  }, [pageObj.page, pageObj.pageSize, sort, filter, asyncFunction]);

  return [tableData, tableLoading, ordersData];
};
