import { useRef, useState } from "react";

import { OptionsIcon } from "../../../../components/icons/index";

import { useOnClickOutside } from "../../../../hooks/use-on-click-outside";

import { AddComment } from "./add-comment/add-comment";
import { DeleteComment } from "./delete-comment/delete-comment";
import { ViewComment } from "./view-comment/view-comment";

// import "./table-options.scss";
import { style } from "./tableOptions.style";
// eslint-disable-next-line import/order
import { Box } from "@mui/material";

export const TableOptions = ({ ipId, className, position = "bottom", hasComment = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [newHasComment, setNewHasComment] = useState(hasComment);

  const optionsRef = useRef(null);

  const handleOpen = () => setIsOpen((prev) => !prev);

  useOnClickOutside(optionsRef, () => setIsOpen(false));

  const defineClasses = () => {
    let classes = "table-options";

    switch (position) {
      case "bottom":
        classes += " table-options_position_bottom";
        break;
      case "top":
        classes += " table-options_position_top";
        break;

      case "forOne":
        classes += " table-options_position_forOne";
        break;

      default:
        break;
    }

    if (className) classes += ` ${className}`;
    if (isOpen) classes += " table-options_open";

    return classes;
  };

  const defineStyles = () => {
    const styles = [style.options];

    switch (position) {
      case "bottom":
        styles.push(style.position.bottom);
        break;

      case "top":
        styles.push(style.position.top);
        break;

      case "forOne":
        styles.push(style.position.forOne);
        break;

      default:
        break;
    }

    return styles;
  };

  return (
    <Box ref={optionsRef} sx={defineStyles()} className={defineClasses()}>
      <Box sx={style.icon} className="table-options__icon" onClick={handleOpen}>
        <OptionsIcon />
      </Box>
      <Box sx={style.body} className="table-options__body">
        <Box component="ul" sx={style.list} className="table-options__list" onClick={handleOpen}>
          <ViewComment ipId={ipId} disabled={!newHasComment} />
          <AddComment successFunc={setNewHasComment} ipId={ipId} editMode={newHasComment} />
          <DeleteComment successFunc={setNewHasComment} disabled={!newHasComment} ipId={ipId} />
        </Box>
      </Box>
    </Box>
  );
};
