import { appTheme } from "../../../theme";

export const style = {
  modal: {
    position: 'fixed',
    left: '0',
    top: '0',
    zIndex: '20',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0 0 0 / 50%)',
    opacity: '0',
    visibility: 'hidden',
    '&.entering': {
      animation: 'show-modal var(--duration) forwards',

      '.modal__wrapper': {
        animation: 'show-modal-content var(--duration) forwards',
      }
    },

    '&.entered': {
      opacity: 1,
      visibility: 'visible',

      '.modal__wrapper': {
        opacity: '1',
        transform: 'translateY(0)',
      }
    },

    '&.exiting': {
      animation: 'show-modal var(--duration) reverse',

      '.modal__wrapper': {
        animation: 'show-modal-content var(--duration) reverse',
      }
    },

    '&.exited': {
      opacity: '0',
      visibility: 'hidden',

      '.modal__wrapper': {
        opacity: '0',
        transform: 'translateY(-100%)',
      }
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem',
    minHeight: '100%',
  },

  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '2.4rem',
    height: '2.4rem',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: "absolute",
    top:"24px",
    right: "24px",

    "&:hover": {
      backgroundColor: 'var(--clr-primary-400)',
      transition: 'background-color var(--transition)',
      "& > svg": {
        stroke: 'var(--clr-default-100)',
        strokeWidth: 4
      }
    },

    svg: {
      width: '1rem',
      height: '1rem',
      stroke: 'var(--clr-text-900)',
      objectFit: 'contain',
      transition: 'stroke-width var(--transition)',
    }
  },

  body: {
    position: 'relative',
    borderRadius: '3rem',
    // padding: '4.7rem 4rem',
    width: 'min(100%, 63rem)',
    boxShadow: '0 0 35px rgba(196 196 196 / 25%)',
    backgroundColor: 'var(--clr-default-100)',
  },

  '@keyframes show-modal': {
    from: {
      opacity: '0',
      visibility: 'hidden',
    },

    to: {
      opacity: '1',
      visibility: 'visible',
    }
  },

  '@keyframes show-modal-content': {
    from: {
      opacity: '0',
      transform: 'translateY(-100%)',
    },

    to: {
      opacity: '1',
      transform: 'translateY(0)',
    }
  }
}
