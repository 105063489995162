import { createAsyncThunk } from "@reduxjs/toolkit";

import { FaqService } from "../../services/faq.service";

export const getAllFaqByLocale = createAsyncThunk(
  "faq/getAllFaqByLocale",
  async (lang, { rejectWithValue }) => {
    try {
      const response = await FaqService.getFags({
        type: "faq",
        location: "faq",
        language: lang
      });

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { data: response.data, lang };
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getFaqByParams = createAsyncThunk(
  "faq/getFaqByParams",
  async (params, { rejectWithValue }) => {
    try {
      const response = await FaqService.getFags(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
