import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useActions } from "../../../hooks";
import { getActiveOrdersIpAddressCount, getIsAuthenticatedUser } from "../../../store/user/user.selectors";
import { SidebarDropdown } from "./sidebar-dropdown/sidebar-dropdown";
import { SidebarItem } from "./sidebar-item";
import { SidebarLogout } from "./sidebar-logout";

// import "./sidebar.scss";
import { style } from "./sidebar.style";

export const Sidebar = ({ routes, translation, parentPage, withExit }) => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("bp992"));

  let currentRoutes = [...routes];

  const { fetchUserActiveOrdersIpAddressCount } = useActions();
  const { data } = useSelector(getActiveOrdersIpAddressCount);
  const isAuthenticatedUser = useSelector(getIsAuthenticatedUser);

  // TODO Need to make normal flow for sidebar view
  // Fetch Active orders count for show in sidebar
  useEffect(() => {
    if (isAuthenticatedUser && !data && parentPage === "cabinet") {
      fetchUserActiveOrdersIpAddressCount();
    }
  }, [isAuthenticatedUser, data, parentPage, fetchUserActiveOrdersIpAddressCount]);

  if (data && parentPage === "cabinet") {
    currentRoutes = routes.map((route) => (
      (route.key === "orders" && route.sub?.length) ? (
        {
          ...route,
          sub: route.sub.map((subRoute) => {
            const activeOrderCount = data[subRoute?.type];

            if (activeOrderCount) {
              return {
                ...subRoute,
                extra: `(${activeOrderCount})`
              };
            }

            return subRoute;
          })
        }
      ) : route
    ));
  }

  return isTablet ? (
    <SidebarDropdown
      routes={currentRoutes}
      translation={translation}
      parentPage={parentPage}
      withExit={withExit}
    />
  ) : (
    <Box sx={style.sidebar} className="sidebar">
      <Box component="ul" sx={style.sidebarList} className="sidebar__list">
        {currentRoutes.map((route) => (
          <SidebarItem
            key={route.key}
            route={route}
            translation={translation}
            parentPage={parentPage}
            withSubRoutes={route.sub?.length}
          />
        ))}
        {withExit && <SidebarLogout />}
      </Box>
    </Box>
  );
};
