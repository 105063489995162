import { fluid } from "../../../../assets/styles/functions/fluid";
import { appTheme } from "../../../../theme";

export const style = {
  thanksHero: {
    overflow: "hidden",
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 6.5),
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238 246 255 / 20%) 3.11%,
      rgba(81 127 245 / 35%) 100.73%
    ),`,

    ".button": {
      marginBottom: "3.3rem",

      [appTheme.breakpoints.down("bp1200")]: {
        width: "100%"
      }
    }
  },

  wrapper: {
    position: "relative",
    zIndex: "0",

    "& > .heading": {
      "&_h1": {
        marginBottom: "2.4rem"
      },

      "&_h4": {
        marginBottom: "1.4rem",
        maxWidth: "50ch"
      }
    }
  },

  decor: {
    position: "absolute",
    right: "-23%",
    top: "55%",
    zIndex: "-1",
    width: "77.2rem",
    height: "auto",
    transform: "translateY(-50%)",

    "& > svg": {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },

    [appTheme.breakpoints.down("bp1200")]: {
      right: "-34rem"
    },

    [appTheme.breakpoints.down("bp992")]: {
      display: "none"
    }
  },

  text: {
    maxWidth: "62ch",
    fontWeight: "400",
    fontSize: "1.4rem",
    lineHeight: "160%",
    color: "var(--clr-text-400)",

    "&:not(:last-child)": {
      marginBottom: "3.7rem"
    },

    "& > p": {
      marginBottom: "1rem"
    },

    description: {
      [appTheme.breakpoints.down("bp576")]: {
        display: "none"
      }
    }
  },
  btnBox: {
    width: "fit-content",
    [appTheme.breakpoints.down("bp576")]: {
      width: "auto"
    }
  }
};
