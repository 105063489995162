import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  register: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(14, 8),
    height: '100%',
    backgroundImage: `linear-gradient(
      108.57deg,
      rgba(238, 246, 255, 0.06) 3.11%,
      rgba(81, 127, 245, 0.105) 100.73%
    )`,

    '.heading': {
      marginBottom: '3.4rem',
      textAlign: 'center',
    }
  },

  message: {
    textAlign: 'center',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inner: {
    width: 'min(37rem, 100%)',
  },

  form: {
    width: 'min(37rem, 100%)',

    '.form-item': {
      '&:not(:first-of-type)': {
        marginTop: '1.5rem',
      }
    },

    '.button': {
      marginTop: '3.5rem',
      marginBottom: '3.2rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: 0,
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
      borderRadius: '3rem',
      padding: '4rem',
      boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
      backgroundColor: 'var(--clr-default-100)',
    }
  },
  description: {
    marginBottom: '1.7rem',
    fontWeight: '400',
    fontSize: '1.4rem',
    color: 'var(--clr-text-400)',
  }
}
