import { useRef } from "react";

import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import AuthFormFooter from "../../../components/common/auth-form-footer/auth-form-footer";
import { Container } from "../../../components/common/container/container";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useLang, useSetSeoPage } from "../../../hooks";
import { UserService } from "../../../services";
import { ROUTE } from "../../../utils/constants";
import { getCaptchaTokenFromFormData } from "../../../utils/helpers";
import { ForgotSchema } from "../../../utils/validation";
import { PasswordRecoverSchema } from "../../../utils/validation/password-recovery.validation";

import { style } from "./forgot.style";

const Forgot = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const captchaPassRef = useRef(null);
  const captchaEmailRef = useRef(null);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const lang = useLang();

  const { hash } = useParams();
  const isMobileBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("bp576"));
  // handle email link send

  const handleSendEmailSubmit = async (data, reset) => {
    try {
      const res = await UserService.sendPasswordLink(
        data.forgotEmail,
        getCaptchaTokenFromFormData(data)
      );
      if (res.status === 200) {
        enqueueSnackbar(t("form.labels.emailLinkSuccess"), { variant: "success" });
      } else if (res.status === 204) {
        enqueueSnackbar(t("userNotFound"), { variant: "error" });
      }
    } catch (error) {
      let message = t("serverError");

      if (error.response.status === 409) {
        captchaEmailRef?.current?.reset();
        message = t("messages.captchaFailed");
      }

      enqueueSnackbar(message, { variant: "error" });
    } finally {
      reset();
      captchaEmailRef?.current?.reset();
    }
  };

  // handle set new password submit
  const handleSubmit = async (data, reset) => {
    try {
      const res = await UserService.restorePassword(
        {
          hash,
          newPassword: data.forgotNewPassword
        },
        getCaptchaTokenFromFormData(data)
      );

      if (res.status === 200) {
        // setMsg(t.form.passwordChangeSuccess);
        enqueueSnackbar(t("passwordChangeSuccess"), { variant: "success" });
        navigate(`/${lang}${ROUTE.APP.HOME}`);
      }
    } catch (error) {
      let message = t("serverError");

      if (error.response.status === 409) {
        captchaPassRef?.current?.reset();
        message = t("messages.captchaFailed");
      }

      enqueueSnackbar(message, { variant: "error" });
    } finally {
      reset();
      captchaPassRef?.current?.reset();
    }
  };

  return (
    <Box sx={style.forgot} className="forgot">
      <Container>
        <Box sx={style.wrapper} className="forgot__wrapper">
          <Heading tag="h1" variant="h2">
            {t("pages.auth.forgot.heading")}
          </Heading>
          <Box sx={style.inner} className="forgot__inner">
            {!hash ? (
              // form to send email link
              <Form
                sx={style.form}
                className="forgot__form"
                schema={ForgotSchema(t("form"))}
                onSubmit={handleSendEmailSubmit}
              >
                <Form.Input
                  name="forgotEmail"
                  label={isMobileBreakpoint ? null : t("form.labels.email")}
                  placeholder={`${t("form.placeholders.yourEmail")}...`}
                  labelType="outer"
                  variant="outlined"
                  autoComplete="off"
                />
                <Form.Captcha ref={captchaEmailRef} />
                <Button type="submit" variant="primary" fullwidth>
                  {t("form.actions.restore")}
                </Button>
              </Form>
            ) : (
              // form to send new password
              <Form
                sx={style.form}
                className="forgot__form"
                schema={PasswordRecoverSchema(t("form"))}
                onSubmit={handleSubmit}
              >
                <Form.Input
                  name="forgotNewPassword"
                  label={isMobileBreakpoint ? null : t("form.placeholders.newPassword")}
                  placeholder={`${t("form.placeholders.enterPassword")}...`}
                  labelType="outer"
                  variant="outlined"
                  type="password"
                  actions
                  autoComplete="new-password"
                />
                <Form.Input
                  name="forgotConfirmNewPassword"
                  label={isMobileBreakpoint ? null : t("form.placeholders.confirmNewPassword")}
                  placeholder={`${t("form.placeholders.enterPassword")}...`}
                  labelType="outer"
                  variant="outlined"
                  type="password"
                  actions
                  autoComplete="new-password"
                />

                <Form.Captcha ref={captchaPassRef} />
                <Button type="submit" variant="primary" fullwidth>
                  {t("form.actions.changePassword")}
                </Button>
              </Form>
            )}
            {isMobileBreakpoint ? (
              <Box sx={style.description} className="login__description">
                {t("pages.auth.login.agreement")}
              </Box>
            ) : null}

            <AuthFormFooter
              links={[
                { label: t("pages.auth.login.register"), link: ROUTE.AUTH.REGISTER },
                { label: t("pages.auth.login.login"), link: ROUTE.AUTH.LOGIN }
              ]}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Forgot;
