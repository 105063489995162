export const style = {
  fontWeight: "400",
  color: "var(--clr-text-900)",
  fontFamily: "'Gilroy', sans-serif",
  fontSize: "1.6rem",
  letterSpacing: ".1rem",
  "& input": {
    padding: 0
  }
}
