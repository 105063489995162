import { useEffect } from "react";

import { LeftIcon, RightIcon } from "../../../components/icons/index";

import { DOTS, usePagination } from "../../../hooks/use-pagination";

import "./pagination.scss";

export const Pagination = ({
  totalCount,
  pageSize,
  siblingCount,
  totalPages,
  currentPage,
  setCurrentPage
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    totalPages
  });

  /* 👇 little UX tweak when user clicks on any button we scoll to top of the page */

  useEffect(() => {
    // window.scrollTo({
    //   behavior: "smooth",
    //   top: "0px"
    // });
  }, [currentPage]);

  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent) - 1;
    setCurrentPage(pageNumber);
  };

  return (
    <div className="pagination">
      <div className="pagination__list">
        <button
          aria-label="Pagination button previous"
          className="pagination__item pagination__item_prev"
          onClick={onPrevious}
          disabled={currentPage === 0}
        >
          <LeftIcon />
        </button>
        {paginationRange?.map((item, index) => {
          if (item === DOTS) {
            return (
              <button key={index} className="pagination__item pagination__item_divider">
                &#8230;
              </button>
            );
          } else {
            return (
              <button
                key={index}
                onClick={changePage}
                className={
                  currentPage === item
                    ? "pagination__item pagination__item_active"
                    : "pagination__item"
                }
                aria-label={`Go to ${item + 1} page`}
              >
                <span>{item + 1}</span>
              </button>
            );
          }
        })}
        <button
          aria-label="Pagination button next"
          className="pagination__item pagination__item_next"
          onClick={onNext}
          disabled={currentPage === totalPages}
        >
          <RightIcon />
        </button>
      </div>
    </div>
  );
};
