// import { useTranslation } from "../../../hooks";
import { useTranslation } from "react-i18next";

import { SeoBlock } from "../../common/seo-block/seo-block";

export const Seo = () => {
  const { t } = useTranslation();

  return (
    <>
      <SeoBlock data={t("pages.app.proxyFor.seo")} />
    </>
  );
};
