/* eslint-disable prefer-destructuring */
import { useEffect, useMemo, useRef, useState } from "react";

import { Box, ButtonBase, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import FormCaptcha from "../../../components/common/form-captcha/form-captcha";
import { SelectList } from "../../../components/common/select-list/select-list";
import { TextInput } from "../../../components/common/text-input/text-input";
import { BalanceIcon, CopyToClipboardIcon } from "../../../components/icons/index";
import { Button } from "../../../components/ui/button/button";
import { CopyToClipboard } from "../../../components/ui/copy-to-clipboard/copy-to-clipboard";
import { Heading } from "../../../components/ui/heading/heading";
import { InputChip } from "../../../components/ui/input-chip/input-chip";
import { Table } from "../../../components/ui/table/table";
import { Tabs } from "../../../components/ui/tabs/tabs";
import { useLang, useSetSeoPage, useTranslateOptions } from "../../../hooks";
import { BalanceService } from "../../../services";
import { getFingerprintState, getPaymentState, getProxyState } from "../../../store/selectors";
import { getCurrencySign } from "../../../utils/helpers/currency.helper";
import { orderProcessing } from "../../../utils/helpers/orderProcessing.helpers";
import { useTableData } from "../../../utils/hooks/useTableData";
import { useTableNavigation } from "../../../utils/hooks/useTableNavigation";
import AutoRenewaledList from "../auto-renewal/auto-renewaled-list";

import { style } from "./balance.style";

// constants
const balanceTabsOptions = [
  {
    key: "fillBalance",
    translation: "fillBalance"
  },
  {
    key: "historyTransactions",
    translation: "historyTransactions"
  }
];

const customInputStyles = {
  paddingLeft: "55px",
  "& input": {
    paddingLeft: "0px",
    "&::placeholder": {
      color: "#63637c",
      opacity: "1",
      fontFamily: "'Gilroy', sans-serif"
    }
  }
};

const Balance = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const captchaRef = useRef(null);

  const lang = useLang();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || null;
  const { t } = useTranslation();

  // Redux state
  const { systemsOptions } = useSelector(getPaymentState);
  const { data } = useSelector(getFingerprintState);
  const { proxyTypeIds } = useSelector(getProxyState);
  // custom hooks
  const [cfToken, setCfToken] = useState("");

  const [pageObj, pageChangeHandler, sizeSelectHandler] = useTableNavigation();
  const [tableData, tableLoading] = useTableData({
    proxyTypeId: proxyTypeIds.find((el) => el.name === "IPv4")?.id,
    pageObj,
    sort: null,
    filter: null,
    asyncFunction: activeTab === "historyTransactions" ? BalanceService.getHistory : null
  });

  const tabsOptions = useTranslateOptions(balanceTabsOptions, t("pages.cabinet.balance.options"));
  const systemOptionsForUser = useMemo(
    () =>
      systemsOptions
        ? systemsOptions[lang].filter((opt) => opt.showForUser && opt.code !== "balance")
        : [],
    [systemsOptions, lang]
  );

  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(activeTab);
  const [sum, setSum] = useState("");
  const [paymentObj, setPaymentObj] = useState({ index: null, id: "" });
  const [balance, setBalance] = useState({});
  // type === error || warning || info || success
  const [disableButton, setDisableButton] = useState(true);

  const isTableDisabled = !tableData?.content?.length;

  const sumHandler = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    setSum(newValue);
  };

  const paymentChangeHandler = (option) =>
    setPaymentObj({
      index: systemOptionsForUser.indexOf(option),
      id: option.key
    });

  const submitHandler = () => {
    setDisableButton(true);

    BalanceService.addBalance(
      {
        paymentSystemId: paymentObj.id,
        amount: parseInt(sum),
        localeCode: lang,
        fingerprint: data?.visitorId || ""
      },
      cfToken
    )
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject(response.data.error);
        }
        if (response.data?.error) {
          // eslint-disable-next-line no-undef
          return Promise.reject(response.data);
        }

        orderProcessing(response.data.url);
        setSum("");
        captchaRef.current?.reset();
      })
      .catch((error) => {
        let message = t("serverError");

        if (error?.response?.status === 409) {
          captchaRef?.current?.reset();
          message = t("messages.captchaFailed");
        } else {
          switch (error?.error) {
            case "PAYMENT_SYSTEM_MIN_PRICE":
              message = t("form.serverErrors.minAmountForPaymentSystem").replace(
                "{{amount}}",
                `${error.minPriceForPaymentSystem}$`
              );
              break;
            case "INCORRECT_AMOUNT":
              message = t("form.serverErrors.incorrectAmount");
              break;
            case "INCORRECT_PAYMENT_SYSTEM":
              message = t("form.serverErrors.incorrectPaymentSystem");
              break;
            case "INCORRECT_LANGUAGE_CODE":
              message = t("form.serverErrors.incorrectLanguageCode");
              break;
            default:
              break;
          }
        }

        enqueueSnackbar(message, { variant: "error" });
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  const repayHandler = (id) => {
    BalanceService.repay(id)
      .then((res) => {
        if (res.data) orderProcessing(res.data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
        enqueueSnackbar(t("repayError"), { variant: "error" });
      });
  };

  // get balance on page load
  useEffect(() => {
    (async () => {
      setLoading(true);
      // const historyRes = await BalanceService.getHistory();
      try {
        const balanceRes = await BalanceService.getBalance();
        setBalance(balanceRes.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      }

      setLoading(false);
    })();
  }, []);

  // enable or disable button
  useEffect(() => {
    if (Number(sum) !== 0 && paymentObj.id !== "") setDisableButton(false);
    else setDisableButton(true);
  }, [sum, paymentObj.id]);

  // set payment system option in select on init
  useEffect(() => {
    if (systemOptionsForUser.length && paymentObj.index === null) {
      setPaymentObj({ index: 0, id: systemOptionsForUser[0].key });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemOptionsForUser]);

  return (
    <Box sx={style.cabinetBalance} className="cabinet-balance">
      <Box sx={style.header} className="cabinet-balance__header">
        <Heading tag="h1" variant="h2">
          {t("pages.cabinet.balance.heading")}
        </Heading>
        <Box sx={style.score} className="cabinet-balance__score">
          {loading ? (
            <CircularProgress color="inherit" size={20} sx={{ display: "flex" }} />
          ) : (
            `$${balance?.balance ? balance?.balance?.toFixed(2) : "0.00"}`
          )}
        </Box>
        <Tabs tabs={tabsOptions} onChange={setTabs} />
      </Box>

      {tabs !== "historyTransactions" ? (
        <Box className={"form"}>
          <Heading tag="h2" variant="h4">
            {t("pages.cabinet.balance.form.heading")}
          </Heading>
          <TextInput
            isOuterLabel={true}
            name="sum"
            label={t("pages.cabinet.balance.form.inputLabel")}
            startAdornment={<InputChip icon={<BalanceIcon />} />}
            onChange={sumHandler}
            value={sum}
            placeholder={t("pages.cabinet.balance.form.inputPlaceholder")}
            inputStyles={customInputStyles}
          />
          <Box component="ul" sx={style.count.list} className="cabinet-balance__count-list">
            <Box
              component="li"
              sx={[style.count.item, style.count.item.hideMod576]}
              className="cabinet-balance__count-item cabinet-balance__count-item--hide-576"
            >
              <ButtonBase type="button" onClick={() => setSum("5")}>
                $5
              </ButtonBase>
            </Box>
            <Box component="li" sx={style.count.item} className="cabinet-balance__count-item">
              <ButtonBase type="button" onClick={() => setSum("10")}>
                $10
              </ButtonBase>
            </Box>
            <Box
              component="li"
              sx={[style.count.item, style.count.item.hideMod460]}
              className="cabinet-balance__count-item cabinet-balance__count-item--hide-460"
            >
              <ButtonBase type="button" onClick={() => setSum("20")}>
                $20
              </ButtonBase>
            </Box>
            <Box component="li" sx={style.count.item} className="cabinet-balance__count-item">
              <ButtonBase type="button" onClick={() => setSum("50")}>
                $50
              </ButtonBase>
            </Box>
            <Box component="li" sx={style.count.item} className="cabinet-balance__count-item">
              <ButtonBase type="button" onClick={() => setSum("100")}>
                $100
              </ButtonBase>
            </Box>
            <Box
              component="li"
              sx={[style.count.item, style.count.item.hideMod576]}
              className="cabinet-balance__count-item cabinet-balance__count-item--hide-576"
            >
              <ButtonBase type="button" onClick={() => setSum("150")}>
                $150
              </ButtonBase>
            </Box>
            <Box
              component="li"
              sx={[style.count.item, style.count.item.hideMod460]}
              className="cabinet-balance__count-item cabinet-balance__count-item--hide-460"
            >
              <ButtonBase type="button" onClick={() => setSum("200")}>
                $200
              </ButtonBase>
            </Box>
            <Box component="li" sx={style.count.item} className="cabinet-balance__count-item">
              <ButtonBase type="button" onClick={() => setSum("500")}>
                $500
              </ButtonBase>
            </Box>
          </Box>
          <SelectList
            options={systemOptionsForUser}
            name="payment"
            label={t("pages.cabinet.balance.form.selectLabel")}
            isOuterLabel={true}
            onChange={paymentChangeHandler}
            selectedIndex={paymentObj.index}
          />
          <Box sx={style.warning} className="cabinet-balance__warning">
            {t("pages.cabinet.balance.form.warning")}
          </Box>
          <FormCaptcha
            ref={captchaRef}
            onSuccess={(token) => setCfToken(token)}
            sx={{
              mb: "1rem"
            }}
          />
          <Button disabled={disableButton} variant="primary" onClick={submitHandler}>
            {t("pages.cabinet.balance.form.btn")}
          </Button>
        </Box>
      ) : (
        <>
          <AutoRenewaledList />
          <Table
            pagination={!!tableData?.totalElements}
            totalPages={tableData?.totalPages ? tableData?.totalPages - 1 : 0}
            totalElements={tableData?.totalElements || 0}
            elementsOnPage={tableData?.content?.length}
            currentPage={pageObj.page}
            onPageChange={pageChangeHandler}
            pageSize={pageObj.pageSize}
            outputCountHandler={sizeSelectHandler}
            loading={tableLoading}
            head={
              <Table.Head>
                {!isTableDisabled ? (
                  <Table.Row>
                    <Table.Cell width="30rem">ID</Table.Cell>
                    <Table.Cell width="13rem">
                      {t("pages.cabinet.balance.table.orderDate")}
                    </Table.Cell>
                    <Table.Cell width="13rem">
                      {t("pages.cabinet.balance.table.orderSum")}
                    </Table.Cell>
                    <Table.Cell>{t("pages.cabinet.balance.table.orderStatus")}</Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Cell></Table.Cell>
                )}
              </Table.Head>
            }
          >
            <Table.Body>
              {tableData?.content?.length ? (
                tableData?.content?.map((row) => {
                  let date;
                  if (row.date) date = dayjs(row.date).format("DD.MM.YYYY");
                  else date = "-";
                  return (
                    <Table.Row key={row.paymentStamp}>
                      <Table.Cell flex align="center" width="30rem">
                        {row.paymentStamp}
                        <CopyToClipboard position="left" text={row.paymentId}>
                          <ButtonBase sx={style.order.copy} className="cabinet-balance__order-copy">
                            <CopyToClipboardIcon />
                          </ButtonBase>
                        </CopyToClipboard>
                      </Table.Cell>
                      <Table.Cell width="13rem">{date}</Table.Cell>
                      <Table.Cell width="13rem">
                        {getCurrencySign(row.currencyCode) + row.totalPrice}
                      </Table.Cell>
                      <Table.Cell flex align="center">
                        {row.payed ? (
                          t("pages.cabinet.balance.status.success")
                        ) : (
                          <>
                            {t("pages.cabinet.balance.status.failed")}{" "}
                            <Box sx={style.fixedWidth}>
                              <Button size="small" onClick={() => repayHandler(row.paymentId)}>
                                {t("pages.cabinet.balance.table.payAction")}
                              </Button>
                            </Box>
                          </>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row customStyles={style.noData}>
                  <Table.Cell>{t("cabinet.balance.noData")}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </>
      )}
    </Box>
  );
};

export default Balance;
