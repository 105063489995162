export function cleanHtmlString(htmlString) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  return tempElement.textContent || tempElement.innerText || "";
}

export function isValidJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
export function countBlocks(message) {
  const divRegex = /<div\b[^>]*>/gi;
  const liRegex = /<li\b[^>]*>/gi;

  const divMatches = (message.match(divRegex) || []).length;
  const liMatches = (message.match(liRegex) || []).length;

  return divMatches + liMatches;
}

export function shortenMessage(message) {
  const shortenedMessage = message.replace(/(<ul>(?:[\s\S]*?<li>[\s\S]*?<\/li>){3}).*/, "$1...");
  const index = shortenedMessage.indexOf("...");
  if (index !== -1) {
    return shortenedMessage.substring(0, index - 5) + "...";
  }
  return shortenedMessage;
}
