export const style = {
  formRange: {
    '.MuiSlider-thumb': {
      outline: 'none',
      border: '0.2rem solid var(--clr-border-100)',
      borderRadius: '50%',
      width: '1.4rem',
      height: '1.4rem',
      boxShadow: '0px 1px 4px rgba(165, 165, 177, 0.2)',
      backgroundColor: 'var(--clr-primary-400)',
    },

    '.MuiSlider-track': {
      borderRadius: '0.3rem',
      width: '100%',
      height: '0.6rem',
      background: '#517FF5',
      border: 'none'
    },

    '.MuiSlider-rail': {
      borderRadius: '0.3rem',
      width: '100%',
      height: '0.6rem',
      background: '#dce5fd',
      opacity: 1
    }
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.9rem'
  },

  value: {
    outline: 'none',
    border: '0.1rem solid var(--clr-ui-border-200)',
    borderRadius: '3rem',
    padding: '0.4rem 1.6rem',
    width: '8.4rem',
    minHeight: '3.2rem',
    fontWeight: 400,
    fontSize: '1.4rem',
    color: 'var(--clr-text-900)',
  },

  label: {
    fontWeight: '400',
    fontSize: '1.4rem',
    color: 'var(--clr-text-900)',
  },

  range: {}
}
