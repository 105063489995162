import { useRef, useState } from "react";

import { Box, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import { Address } from "../../../components/common/address/address";
import { ContactsList } from "../../../components/common/contacts-list/contacts-list";
import { Container } from "../../../components/common/container/container";
// import "./contacts.scss";
import HeadingWithSeo from "../../../components/common/heading-with-seo/heading-with-seo";
import { MessageCard } from "../../../components/common/message-card/message-card";
import { SocialsList } from "../../../components/common/socials-list/socials-list";
import { WorkingHours } from "../../../components/common/working-hours/working-hours";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useLang, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { FeedbackService } from "../../../services/feedback.service";
import { FEEDBACK_TYPE } from "../../../utils/constants";
import { getCaptchaTokenFromFormData, htmlspecialchars } from "../../../utils/helpers";
import { FeedbackSchema } from "../../../utils/validation/feedback.validation";

import { style } from "./contacts.style";

const Contacts = () => {
  useSetSeoPage();

  const captchaRef = useRef(null);
  // Translations
  const { t } = useTranslation();
  const seo = useCurrentSeo();

  const lang = useLang();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("bp767"));

  //state
  // const [snackData, setSnackData] = useState({ type: "", message: "", open: false });
  const [modalMessage, setModalMessage] = useState({
    iconPath: "",
    title: "",
    description: "",
    visible: false,
    action: t("contacts.serverError.action")
  });
  const [loading, setLoading] = useState(false);

  //handlers
  // const snackCloseHandler = () => setSnackData({ ...snackData, open: false });
  const closeHandler = () => setModalMessage({ ...modalMessage, visible: false });

  const handleSubmit = (data, reset) => {
    setLoading(true);

    const payload = {
      type: FEEDBACK_TYPE.CONTACTS,
      email: data.email,
      name: htmlspecialchars(data.name),
      text: htmlspecialchars(data.text),
      localeCode: lang
    };
    const cfToken = getCaptchaTokenFromFormData(data);

    FeedbackService.sendFeedback(payload, cfToken)
      .then((res) => {
        if (res.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject(res);
        }

        setModalMessage({
          ...modalMessage,
          iconPath: "/images/icons/feedback/feedback-success.svg",
          title: t("contacts.serverSuccess.title"),
          description: t("contacts.serverSuccess.description"),
          visible: true,
          action: t("contacts.serverError.action")
        });

        reset();
        captchaRef?.current?.reset();
      })
      .catch((e) => {
        let modalContent = {
          title: t("contacts.serverError.title"),
          description: t("contacts.serverError.description"),
          action: t("contacts.serverError.action")
        };

        if (e.request.status === 409) {
          captchaRef?.current?.reset();

          modalContent = {
            title: t("messages.captchaFailed"),
            description: t("messages.pleaseResubmitTheForm"),
            action: t("contacts.serverError.action")
          };
        } else if (e.request.status === 204) {
          modalContent = {
            title: t("contacts.serverNotRegistered.title"),
            description: t("contacts.serverNotRegistered.description"),
            action: ".contacts.serverError.action"
          };
        } else if (e.request.status === 403) {
          modalContent = {
            title: t("messages.temporaryEmails"),
            description: "",
            action: t("contacts.serverError.action")
          };
        } else if (e.request.status === 406) {
          modalContent = {
            title: t("contacts.serverNotValidMail.title"),
            description: t("contacts.serverNotValidMail.description"),
            action: t("contacts.serverNotValidMail.action")
          };
        }

        setModalMessage({
          ...modalContent,
          iconPath: "/images/icons/feedback/feedback-error.svg",
          visible: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={style.contacts} className="contacts">
      <Container>
        <Box sx={style.wrapper} className="contacts__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">
            {t("pages.app.contacts.heading")}
          </HeadingWithSeo>
          <Box sx={style.inner} className="contacts__inner">
            <Box sx={style.left} className="contacts__left">
              <Heading tag="h3" variant="h4">
                {seo["h2"] ? seo["h2"] : `${t("pages.app.contacts.form")}`}
              </Heading>
              <Box
                sx={style.description}
                className="contacts__description"
                dangerouslySetInnerHTML={{
                  __html: seo["h2Text"] ? seo["h2Text"] : `${t("pages.app.contacts.description")}`
                }}
              />
              {!isTablet ? (
                <>
                  <Address />
                  <SocialsList />
                </>
              ) : null}
            </Box>
            <Form
              sx={style.form}
              onSubmit={handleSubmit}
              className="contacts__form"
              schema={FeedbackSchema(t("contacts.validateErrors"))}
            >
              <Form.Input name="name" placeholder={t("contacts.fields.name")} />
              <Form.Input name="email" placeholder={t("contacts.fields.email")} />
              <Form.Input
                name="text"
                placeholder={t("contacts.fields.text")}
                type="textarea"
                direction="horizontal"
                rows={4}
              />
              <Form.Captcha ref={captchaRef} />
              <Button
                disabled={loading}
                type="submit"
                variant="primary"
                ariaLabel="Отправить сообщение"
                fullwidth
              >
                {t("form.actions.sendMessage")}
              </Button>
            </Form>
            <Box sx={style.right} className="contacts__right">
              {isTablet ? (
                <>
                  <Address />
                  <SocialsList />
                </>
              ) : null}
              <ContactsList />
              <WorkingHours />
            </Box>
          </Box>
        </Box>

        {/*/!* Success || error message *!/*/}
        <Modal open={modalMessage.visible} onClose={closeHandler}>
          <Box sx={style.modal}>
            <MessageCard
              type="feedback"
              iconPath={modalMessage.iconPath}
              title={modalMessage.title}
              description={modalMessage.description}
              actionLabel={modalMessage.action}
              actionEvent={closeHandler}
            />
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default Contacts;
