import { Box, Skeleton } from "@mui/material";
import { BlogCard } from "../blog-card-skeleton/blog-card-skeleton";
import { style } from "./blog-skeleton.style";

export const BlogSkeleton = ({ articleCount = 9 }) => (
  <>
    <Box sx={style.grid}>
      {Array.from(Array(articleCount).keys()).map((el) => <BlogCard key={el} />)}
    </Box>
    <Skeleton variant="rounded" sx={style.pagination} />
  </>
);
