import { useEffect } from "react";
import { useLang } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { PAYMENT_STATUS } from "../../../utils/constants";

const RedirectPaymentStatus = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const { status } = useParams();

  useEffect(() => {
    const storedLocale = localStorage.getItem("locale");
    let route;

    switch (status) {
      case PAYMENT_STATUS.SUCCESS:
        route = `/${storedLocale ?? lang}/payment/success/`;
        break;
      case PAYMENT_STATUS.CANCEL:
        route = `/${storedLocale ?? lang}/?paymentStatus=${status}`;
        break;
      default:
        route = `/${storedLocale ?? lang}/`;
        break;
    }

    navigate(route);
  }, []);

  return null;
};

export default RedirectPaymentStatus;
