import { Skeleton } from "@mui/material";
import "./table.scss";

export const Table = ({ rows, size = "default", patch = "left", heading, isLoading }) => {
  const defineClasses = () => {
    let classes = "widget-table";

    switch (patch) {
      case "left":
        classes += " widget-table_patch_left";
        break;
      case "right":
        classes += " widget-table_patch_right";
        break;
      default:
        break;
    }

    switch (size) {
      case "small":
        classes += " widget-table_size_small";
        break;
      case "default":
        classes += " widget-table_size_default";
        break;
      default:
        break;
    }

    return classes;
  };

  return (
    <div className={defineClasses()}>
      {heading && (
        <div key={heading.key} className="widget-table__row widget-table__row_heading">
          <div className="widget-table__key">{heading.key}</div>
          <div className="widget-table__value">{heading.value}</div>
        </div>
      )}
      {rows.map((row) => (
        <div key={row.key} className="widget-table__row">
          <div className="widget-table__key">
            {row.icon && (
              <img
                src={`/images/icons/${row.icon}.svg`}
                width={18}
                height={18}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
            )}
            {row.key}
          </div>
          <div className="widget-table__value">{isLoading ? <Skeleton width="100%" /> : row.value}</div>
        </div>
      ))}
    </div>
  );
};
