import React, { useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, ButtonBase } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { InfoIcon } from "../../../../assets/images/icons/info-icon";
import { Form } from "../../../../components/form";
import { Button, CopyToClipboard, Heading, Modal } from "../../../../components/ui";
// import { useTranslation } from "../../../../hooks";
import { OrdersService } from "../../../../services";
import { getIsAuthenticatedUser } from "../../../../store/selectors";
import { getCaptchaTokenFromFormData } from "../../../../utils/helpers";
import { OrderCustomExportShema } from "../../../../utils/validation";

import { style } from "./export-modal.styles";

const ExportModal = (props) => {
  const { open, onClose, exportFields, requiredFields, exportSubmit } = props;

  const { enqueueSnackbar } = useSnackbar();
  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const isAuthenticated = useSelector(getIsAuthenticatedUser);
  const [loading, setLoading] = useState(false);

  const formContext = useForm({
    mode: "onChange",
    resolver: yupResolver(OrderCustomExportShema(t, exportFields, requiredFields)),
    defaultValues: {
      exportPattern: ""
    }
  });

  const fetchTemplate = () => {
    setLoading(true);

    OrdersService.getOrdersExportTemplate()
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(response);
        }

        if (response?.data && response.data !== "") {
          formContext.setValue("exportPattern", response?.data);
        }
      })
      .catch((ignored) => {
        enqueueSnackbar(t("messages.failedToLoadTemplate"), { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated) fetchTemplate();
  }, []);

  const handlerCancel = () => {
    formContext.reset();
    onClose();
  };
  const handleSubmit = (values) => {
    exportSubmit(values.exportPattern);

    OrdersService.setOrdersExportTemplate(
      {
        orderExportData: values.exportPattern
      },
      getCaptchaTokenFromFormData(values)
    )
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(response);
        }
        enqueueSnackbar(t("messages.templateSaved"), { variant: "success" });

        fetchTemplate();
      })
      .catch((error) => {
        let message = t("messages.failedToSaveTemplate");

        if (error?.response?.status === 409) {
          captchaRef?.current?.reset();
          message = t("messages.captchaFailed");
        }
        enqueueSnackbar(message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal visible={open} cancelHandler={onClose} customStyles={style.modal}>
        <Form onSubmit={handleSubmit} context={formContext}>
          <Heading tag="div" variant="h4">
            {t("common.export")}
          </Heading>
          <Box sx={style.description}>
            {t("pages.cabinet.orders.widgets.exportModal.description")}
          </Box>
          <Box sx={style.input}>
            <Form.Input
              name="exportPattern"
              placeholder={t("form.placeholders.personalExportPattern")}
              readOnly={loading}
            />
          </Box>
          <Box sx={style.info}>
            <Box component="span" sx={style.infoIcon}>
              <InfoIcon />
            </Box>
            <Box sx={style.infoContent}>
              {exportFields?.length ? (
                <Box component="p">
                  <Box component="span">{t("common.availableVariables")}: </Box>
                  <Box sx={style.variablesList}>
                    {exportFields.map((e) => (
                      <CopyToClipboard key={e} position="left" text={`%${e}%`}>
                        <ButtonBase>%{e}%</ButtonBase>
                      </CopyToClipboard>
                    ))}
                  </Box>
                </Box>
              ) : null}
              <Box component="p">
                <Box component="span">{t("common.example")}: </Box>
                %ip%:%port%
              </Box>
            </Box>
          </Box>
          <Box sx={style.buttons}>
            <Button variant="primary" fullwidth type="submit" loading={loading}>
              {t("common.export")}
            </Button>
            <Button variant="secondary" fullwidth onClick={handlerCancel}>
              {t("common.cancel")}
            </Button>
          </Box>
          <Form.Captcha ref={captchaRef} sx={{ mt: "1rem" }} />
        </Form>
      </Modal>
    </>
  );
};

export default ExportModal;
