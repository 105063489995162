import { appTheme } from "../../../../theme";

export const style = {
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "26rem",
    padding: "3rem",

    [appTheme.breakpoints.down("bp576")]: { height: "22rem" },

    bottomRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap"
    },

    link: {
      height: "1.9rem",
      width: "10rem"
    }
  },

  profile: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
    photo: {
      width: "4.7rem",
      height: "4.7rem",
      borderRadius: "50%",
      marginRight: "16px",
      [appTheme.breakpoints.down("bp576")]: { height: "3rem", width: "3rem", marginRight: "1rem" },
    },

    name: {
      height: "2rem",
      width: "12rem"
    }
  },

  paragraph: {
    marginBottom: "3.2rem",
    "& .string": {
      width: "100%",
      fontSize: "1.6rem",
      marginBottom: "1rem",
      "&:last-of-type": {
        width: "60%",
        marginBottom: "0"
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      fontSize: "1.4rem",
    }
  },

  grid: {
    display: "grid",
    gridGap: "3rem",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginBottom: "4.5rem",
    [appTheme.breakpoints.down('bp767')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  },

  buttonRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  button: {
    width: "20rem",
    height: "5rem",
    borderRadius: "3rem",
    "&:last-child": {
      display: "none"
    },

    [appTheme.breakpoints.down('bp767')]: {
      width: "100%",
      "&:first-of-type": {
        marginRight: "1rem",
      },

      "&:last-child": {
        display: "block"
      }
    }
  }
}