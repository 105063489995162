import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Container } from "../../../components/common/container/container";
import HeadingWithSeo from "../../../components/common/heading-with-seo/heading-with-seo";
import { SeoBlock } from "../../../components/common/seo-block/seo-block";
import { useSetSeoPage } from "../../../hooks";

import { style } from "./refund-policy.style";

const RefundPolicy = () => {
  useSetSeoPage();

  const { t } = useTranslation();

  return (
    <Box sx={style.policy}>
      <Box sx={style.wrapper}>
        <Container>
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">
            {t("pages.app.refundPolicy.heading")}
          </HeadingWithSeo>

          <SeoBlock variant="tools" data={t("pages.app.refundPolicy.content")} />
        </Container>
      </Box>
    </Box>
  );
};

export default RefundPolicy;
