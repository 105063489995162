import React from "react";

export const CloseIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="#63637C" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M17.7628 1.23736C18.0556 1.53026 18.0556 2.00513 17.7628 2.29802L2.35759 17.7032C2.0647 17.9961 1.58982 17.9961 1.29693 17.7032V17.7032C1.00404 17.4103 1.00404 16.9354 1.29693 16.6425L16.7021 1.23736C16.995 0.944469 17.4699 0.944469 17.7628 1.23736V1.23736Z"
          fill="inherit" />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M17.7626 17.7032C17.4697 17.9961 16.9949 17.9961 16.702 17.7032L1.29682 2.29802C1.00393 2.00513 1.00393 1.53025 1.29682 1.23736V1.23736C1.58971 0.944468 2.06458 0.944468 2.35748 1.23736L17.7626 16.6425C18.0555 16.9354 18.0555 17.4103 17.7626 17.7032V17.7032Z"
          fill="inherit" />
  </svg>

);

