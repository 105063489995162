import React from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLang } from "../../../hooks";
import { ROUTE } from "../../../utils/constants";

import { style } from "./auth-form-footer.styles";

const AuthFormFooter = ({ links }) => {
  const lang = useLang();
  const { t } = useTranslation();

  return (
    <Box sx={style.container}>
      {links?.length ? (
        <Box sx={style.links}>
          {links?.map((e) => (
            <Link key={e.link} to={`/${lang}${e.link}/`}>
              {e.label}
            </Link>
          ))}
        </Box>
      ) : null}

      <Box sx={style.terms}>
        {t("pages.auth.login.agreementPartOne")}
        &nbsp;
        <Link to={`/${lang}${ROUTE.APP.PRIVACY_POLICY}/`}>
          {t("pages.auth.login.agreementPartSecond")}
        </Link>
      </Box>
    </Box>
  );
};

export default AuthFormFooter;
