import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Console, { ConsoleLabel, ConsoleRow } from "../../../components/common/console/console";
import MediaQuery from "../../../components/common/media-query/media-query";
import ShortInfoBlock from "../../../components/common/tools/short-info-block/short-info-block";
import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { useTranslateOptions } from "../../../hooks/use-translate-options";
import { ToolsService } from "../../../services";
import { getToolsState } from "../../../store/tools/tools.selectors";
import { PORT_TYPES_FOR_SCAN } from "../../../utils/constants";
import {
  getCaptchaTokenFromFormData,
  setCaptchaTokenToRequestHeaders
} from "../../../utils/helpers";
import { PortScannerSchema } from "../../../utils/validation/port-scanner.validation";

import { PortScannerSeo } from "./port-scanner-seo";
import { style } from "./portScanner.style";

const PortScanner = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();
  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const ref = useRef(null);
  const { clientIpInfo, clientAnonymityPercent } = useSelector(getToolsState);
  const { checkIp, getAnonymityPercent } = useActions();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!clientAnonymityPercent.data) {
      getAnonymityPercent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAnonymityPercent.data]);

  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors || clientAnonymityPercent.error) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors, clientAnonymityPercent.error]);

  const portTypeOptions = useTranslateOptions(
    [
      {
        key: PORT_TYPES_FOR_SCAN.POPULAR,
        translation: "popularPorts"
      },
      {
        key: PORT_TYPES_FOR_SCAN.HIDDEN,
        translation: "hiddenPorts"
      },
      {
        key: PORT_TYPES_FOR_SCAN.PROXY,
        translation: "proxyPorts"
      }
    ],
    t("portType")
  );

  const handlerSubmit = (data) => {
    if (loading) {
      if (ref.current) {
        ref.current.abort();
        ref.current = null;
      } else {
        setLoading(false);
        enqueueSnackbar(t("common.canceled"), { variant: "error" });
      }
      return;
    }

    // eslint-disable-next-line no-undef
    ref.current = new AbortController();

    setLoading(true);
    setData(null);

    const cfToken = getCaptchaTokenFromFormData(data);

    ToolsService.portScan(
      {
        ipOrDomain: data.ipOrDomain,
        portType: data.portType
      },
      {
        signal: ref.current.signal,
        headers: setCaptchaTokenToRequestHeaders(cfToken)
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject("Error");
        }
        if (response.data.error) {
          // eslint-disable-next-line no-undef
          return Promise.reject({ response });
        }
        setData(response.data);
        captchaRef?.current?.reset();
      })
      .catch((error) => {
        let message = t("serverError");

        if (error?.response?.status === 406) {
          message = t("form.rules.ipOrDomain.invalid");
        } else if (error?.response?.status === 409) {
          captchaRef?.current?.reset();
          message = t("messages.captchaFailed");
        } else if (error.message === "canceled") {
          message = t("common.canceled");
        }

        enqueueSnackbar(message, { variant: "error" });

        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        ref.current = null;
      });
  };

  return (
    <>
      <Box className="tools-port-scanner">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.portScanner.heading")}
          </Heading>

          <ShortInfoBlock
            ip={{
              loading: clientIpInfo.loading,
              v4: clientIpInfo.data?.ip?.ipv4,
              v6: clientIpInfo.data?.ip?.ipv6
            }}
            anonymity={{
              loading: clientAnonymityPercent.loading,
              percent: clientAnonymityPercent.data
            }}
          />
        </ToolsHeader>

        <Box
          sx={style.description}
          className="tools-port-scanner__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.portScanner.description")
          }}
        ></Box>

        <Form
          sx={style.panel}
          className="tools-port-scanner__panel"
          defaultValues={{
            ipOrDomain: "",
            portType: PORT_TYPES_FOR_SCAN.POPULAR
          }}
          schema={PortScannerSchema(t("form"))}
          onSubmit={handlerSubmit}
        >
          <Box sx={style.panel.info} className="tools-port-scanner__panel-info">
            <Console>
              <ConsoleLabel>{t("shared.tools.result")}</ConsoleLabel>
              {data ? (
                <>
                  <ConsoleRow>IP/Domain: {data?.ipOrDomain}</ConsoleRow>
                  <ConsoleRow>Not shown: {data?.notShown} closed ports</ConsoleRow>
                  <ConsoleRow>PORT STATE SERVICE</ConsoleRow>
                  {data?.ports?.map((element, key) => (
                    <ConsoleRow key={key}>{`${element.port} ${
                      element.status ? "opened" : "closed"
                    } ${element.description}`}</ConsoleRow>
                  ))}
                </>
              ) : null}
            </Console>
            <Box sx={style.panel.note} className="tools-port-scanner__panel-note">
              {t("pages.tools.portScanner.note}")}
            </Box>
          </Box>
          <Box sx={style.panel.options} className="tools-port-scanner__panel-options">
            <Form.Input
              name="ipOrDomain"
              label={t("form.labels.ipOrDomain")}
              direction="vertical"
            />
            <Box sx={style.panel.options.label} className="tools-port-scanner__panel-options-label">
              {t("pages.tools.portScanner.optionsLabel")}
            </Box>
            <Form.RadioGroup name="portType" options={portTypeOptions} />
            <Form.Captcha ref={captchaRef} />
            <MediaQuery minWidth="bp576">
              <Button type="submit" variant="primary" fullwidth loading={loading}>
                {loading ? t("common.cancel") : t("form.actions.startScan")}
              </Button>
            </MediaQuery>
          </Box>

          <MediaQuery maxWidth="bp576">
            <Button type="submit" variant="primary" fullwidth loading={loading}>
              {loading ? t("common.cancel") : t("form.actions.startScan")}
            </Button>
          </MediaQuery>
        </Form>

        <PortScannerSeo />
      </Box>
    </>
  );
};

export default PortScanner;
