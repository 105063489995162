import { Heading } from "../../../components/ui/heading/heading";

import "./note.scss";
// eslint-disable-next-line import/order
import { Box } from "@mui/material";

export const Note = ({ label, children }) => (
  <Box className="note">
    <Box className="note__head">
      <Box component="img" src="/images/ui/question-mark.svg" width={18} height={18} alt="note" loading="lazy" />
      <Heading tag="div" variant="h4">
        {label}
      </Heading>
    </Box>
    <p>{children}</p>
  </Box>
);
