import i18next from "i18next";

import { ApiService } from "../../services";
import { StoreActions } from "../../store/actions";
import { fetchCaptchaInfo } from "../../store/captcha/captcha.thunks";
import { fetchMobile } from "../../store/mobile/mobile.thunks";
import { getPaymentOptions } from "../../store/payment/payment.thunks";
import { getProxyState } from "../../store/proxy/proxy.selectors";
import { fetchPageSeo, getSeoContent, getSeoDefault } from "../../store/seo/seo.thunks";

import { isSSR } from "./isSSR";

export const collectDefaultContent = async (store) => {
  await Promise.all([
    store.dispatch(getSeoDefault()),
    store.dispatch(getSeoContent()),
    store.dispatch(getPaymentOptions({ email: "", fingerprint: "" })),
    store.dispatch(fetchCaptchaInfo()),
    store.dispatch(fetchMobile())
  ]);

  const state = store.getState();

  try {
    const content = await ApiService.getContent({
      sizeOfReviews: state.review.reviewsPreviewLength + 1
    });

    if (content.data) {
      const { proxies, reviews, rentPeriods } = content.data;

      await Promise.all([
        store.dispatch(StoreActions.setProxies(proxies)),
        store.dispatch(StoreActions.setReviews(reviews)),
        store.dispatch(StoreActions.setPeriods(rentPeriods))
      ]);
    }
  } catch (error) {
    console.error("Error collecting content state:", error);
  }
};

// SEO
export function prepareSeoRequestParams(state, tag, languageCode, params, tagPostfix, location) {
  let requestParams;

  if (tag === "article") {
    requestParams = {
      article: params?.tag
    };
  }

  if (tag === "countries") {
    const { proxies } = getProxyState(state);

    const proxy_type = "IPv4";
    const proxy = proxies[proxy_type.toUpperCase()];
    const country = proxy?.countries.find((el) => el.urlParam === params?.proxyCountry)?.urlParam;

    if (!proxy || !country) {
      return;
    }

    requestParams = {
      proxy_type,
      country
    };
  }

  if (tag === "ipv6-proxy" || tag === "isp-proxy") {
    const { proxies } = getProxyState(state);

    const proxy = proxies[params?.proxyType.toUpperCase()];

    if (!proxy) {
      return;
    }

    requestParams = {
      proxy_type: proxy?.proxyTypeName
    };
  }

  return {
    tag,
    location: location ? location : "/",
    tagPostfix,
    languageCode,
    params: requestParams
  };
}
export const collectPageSeoForSsr = async (store, params) => {
  if (!params?.tag) return;

  const state = store.getState();
  const langCurrent = i18next.language;
  const languageCode = params?.locale ?? langCurrent;

  const tag = params?.tag;
  let tagPostfix = "";

  if (tag === "article") {
    tagPostfix = `/${params?.params?.tag}`;
  }

  if (tag === "countries") {
    tagPostfix = `/IPV4/${params?.params?.proxyCountry}`;
  }

  if (tag === "ipv6-proxy" || tag === "isp-proxy") {
    const proxyType = params?.location.split("/")[1]?.toUpperCase();
    tagPostfix = `/${proxyType}`;

    params = {
      ...params,
      params: {
        ...params.params,
        proxyType
      }
    };
  }

  const tagWithPostfix = tag + tagPostfix;

  const seoPageParams = {
    tag: tagWithPostfix,
    languageCode
  };

  if (isSSR()) {
    await store.dispatch(
      StoreActions.setSeoPageParams({
        tag: tagWithPostfix,
        languageCode
      })
    );
  }

  if (
    state.seo.contentLoaded &&
    (!state.seo.data[languageCode] || !state.seo.data[languageCode][tagWithPostfix]) &&
    (state.seo.loadedPagesTagsByLocale[languageCode] === undefined ||
      state.seo.loadedPagesTagsByLocale[languageCode]?.indexOf(tagWithPostfix) === -1)
  ) {
    const requestParams = prepareSeoRequestParams(
      state,
      params?.tag,
      params?.locale,
      params?.params,
      tagPostfix,
      params?.location
    );

    requestParams && (await store.dispatch(fetchPageSeo(requestParams)));
  }

  return seoPageParams;
};
