import { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Box } from "@mui/material";

import { CloseIcon } from "../../../components/icons/index";

import { style } from "./modal.style";

export const Modal = (props) => {
  const {
    visible,
    animationDuration = 300,
    width = 80,
    cancelHandler,
    children,
    customStyles = {}
  } = props;

  const styles = {
    "--duration": `${animationDuration}ms`,
    "--width": `${width}rem`
  };

  const [portalContainer, setPortalContainer] = useState(null);

  useEffect(() => {
    setPortalContainer(document.body);
  }, []);

  const closeModal = (e) => {
    e.stopPropagation();
    cancelHandler(false);
  };

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const paddingOffset = `${window.innerWidth - document.body.offsetWidth}px`;
    const lockedElements = document.querySelectorAll(".locked");

    if (visible) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = paddingOffset;
      lockedElements.forEach((el) => {
        el.style.paddingRight = paddingOffset;
      });
    }

    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.paddingRight = "";
      lockedElements.forEach((el) => {
        el.style.paddingRight = "";
      });
    };
  }, [visible]);

  const content = (
    <Transition in={visible} timeout={animationDuration} mountOnEnter unmountOnExit={false}>
      {(state) => (
        <Box sx={[style.modal, styles]} className={`modal ${state}`} onClick={closeModal}>
          <Box sx={style.wrapper} className="modal__wrapper">
            <Box sx={[style.body, customStyles]} className="modal__body" onClick={(e) => e.stopPropagation()}>
              <Box sx={style.close} className="modal__close" onClick={closeModal}>
                <CloseIcon />
              </Box>
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </Transition>
  );

  if (portalContainer) {
    return ReactDOM.createPortal(
      content,
      portalContainer
    );
  } else {
    return (content);
  }

};
