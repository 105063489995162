import { useEffect, useState } from "react";

import { OrdersService } from "../../../services/orders.service";

// hook for sorting and filtering
export const useSortHandle = () => {
  const [sort, setSort] = useState({
    key: null,
    value: null
  });

  const [filter, setFilter] = useState({
    key: "countryId",
    value: null
  });

  const sortHandler = (key) => {
    if (key === sort.key) {
      switch (sort.value) {
        case "asc":
          setSort({ key, value: "desc" });
          break;

        case "desc":
          setSort({ key, value: "asc" });
          break;

        default:
          break;
      }
    } else {
      setSort({ key, value: "desc" });
    }
  };

  const filterHandler = (value) => setFilter({ ...filter, value });

  return [sort, sortHandler, filter, filterHandler];
};

// // hook for page and page size change
// export const useTableNavigation = (defaultNav = { page: 0, pageSize: 9 }) => {
//   // { page: 0, pageSize: 9 }
//   const [pageObj, setPageObj] = useState(defaultNav);

//   const pageChangeHandler = (value) => {
//     setPageObj({ ...pageObj, page: value });
//   };

//   const sizeSelectHandler = (value) => {
//     setPageObj({ ...pageObj, pageSize: parseInt(value) });
//   };

//   return [pageObj, pageChangeHandler, sizeSelectHandler]
// }

// //hook for receiving table data
// //** proxyType === "IPv4" || "IPv6" || "MOBILE"
// export const useTableData = (proxyType, pageObj, sort = {}, filter = {}) => {
//   const [tableData, setTableData] = useState({});

//   useEffect(() => {
//     (async () => {
//       const reqObj = {
//         page: pageObj.page,
//         size: pageObj.pageSize,
//         type: proxyType,
//         sort: (sort.key && sort.value) ? [sort] : [],
//         search: filter.value && filter.value !== "all" ? [filter] : []
//       };
//       try {
//         const res = await OrdersService.getOrdersByProxyType(reqObj);
//         if (res.data) {
//           setTableData(res.data);
//         }
//       } catch (error) {
//         // eslint-disable-next-line no-console
//         console.log(error.message);
//       }
//     })();
//   }, [pageObj.page, pageObj.pageSize, sort, filter]);

//   return [tableData];
// }
