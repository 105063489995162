import { useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Console, { ConsoleLabel, ConsoleRow } from "../../../components/common/console/console";
import MediaQuery from "../../../components/common/media-query/media-query";
import ShortInfoBlock from "../../../components/common/tools/short-info-block/short-info-block";
import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { useTranslateOptions } from "../../../hooks/use-translate-options";
import { ToolsService } from "../../../services";
import { getToolsState } from "../../../store/tools/tools.selectors";
import {
  getCaptchaTokenFromFormData,
  setCaptchaTokenToRequestHeaders
} from "../../../utils/helpers";
import { TraceIpSchema } from "../../../utils/validation/trace-ip.validation";

import { IpTracingSeo } from "./ip-tracing-seo";
import { style } from "./ipTracing.style";

const IpTracing = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();
  const captchaRef = useRef(null);

  const { t } = useTranslation();

  const ref = useRef(null);
  const { clientIpInfo, clientAnonymityPercent } = useSelector(getToolsState);
  const { checkIp, getAnonymityPercent } = useActions();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!clientAnonymityPercent.data) {
      getAnonymityPercent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAnonymityPercent.data]);

  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors || clientAnonymityPercent.error) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors, clientAnonymityPercent.error]);

  useEffect(() => {
    if (data?.error) {
      let message = t("serverError");

      if (data.error === "IpOrDomain not valid!") {
        message = t("form.rules.ipOrDomain.invalid");
      }
      enqueueSnackbar(message, { variant: "error" });

      setData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.error]);

  const packageRoutesOptions = useTranslateOptions(
    [
      {
        key: 1,
        translation: "yes"
      },
      {
        key: 0,
        translation: "no"
      }
    ],
    t("answers")
  );

  const handlerSubmit = (data) => {
    if (loading) {
      if (ref.current) {
        ref.current.abort();
        ref.current = null;
      } else {
        setLoading(false);
        enqueueSnackbar(t("common.canceled"), { variant: "error" });
      }
      return;
    }

    // eslint-disable-next-line no-undef
    ref.current = new AbortController();

    setLoading(true);
    setData(null);

    const cfToken = getCaptchaTokenFromFormData(data);

    ToolsService.getTraceRoute(
      {
        ipOrDomain: data.ipOrDomain,
        showByCountries: data.showByCountries
      },
      {
        signal: ref.current.signal,
        headers: setCaptchaTokenToRequestHeaders(cfToken)
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-undef
          return Promise.reject("Error");
        }
        setData(response.data);

        captchaRef?.current?.reset();
      })
      .catch((error) => {
        let message = t("serverError");

        if (error.message === "canceled") {
          message = t("common.canceled");
        } else if (error?.response?.status === 406) {
          message = t("form.rules.ipOrDomain.invalid");
        } else if (error?.response?.status === 409) {
          captchaRef?.current?.reset();
          message = t("messages.captchaFailed");
        }

        enqueueSnackbar(message, { variant: "error" });
        // eslint-disable-next-line no-console
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        ref.current = null;
      });
  };

  const countryAndIpMap = useMemo(
    () => (data?.countryAndIpMap ? Object.entries(data?.countryAndIpMap) : []),
    [data]
  );

  return (
    <>
      <Box className="tools-ip-tracing">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.ipTracing.heading")}
          </Heading>

          <ShortInfoBlock
            ip={{
              loading: clientIpInfo.loading,
              v4: clientIpInfo.data?.ip?.ipv4,
              v6: clientIpInfo.data?.ip?.ipv6
            }}
            anonymity={{
              loading: clientAnonymityPercent.loading,
              percent: clientAnonymityPercent.data
            }}
          />
        </ToolsHeader>

        <Box
          sx={style.description}
          className="tools-ip-tracing__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.ipTracing.description")
          }}
        />
        <Form
          sx={style.panel}
          className="tools-ip-tracing__panel"
          onSubmit={handlerSubmit}
          defaultValues={{
            ipOrDomain: "",
            showByCountries: 1
          }}
          schema={TraceIpSchema(t("form"))}
        >
          <Box sx={style.panel.container}>
            <Box sx={style.panel.info} className="tools-ip-tracing__panel-info">
              <Console>
                <ConsoleLabel>{t("shared.tools.result")}</ConsoleLabel>
                {data && !data?.error ? (
                  <>
                    <ConsoleRow>{data?.description}</ConsoleRow>
                    {!data?.routList?.length && !countryAndIpMap.length ? (
                      <ConsoleRow>{t("common.noData")}</ConsoleRow>
                    ) : null}

                    {data?.routList.map((route) => (
                      <ConsoleRow key={route.step}>
                        {`${route.step}. ${route.ip} ${route.speed1} ${route.speed2} ${route.speed3}`}
                      </ConsoleRow>
                    ))}
                    {countryAndIpMap.length ? (
                      <>
                        <ConsoleRow>Package route by countriesConstant</ConsoleRow>
                        {countryAndIpMap.map(([key, value]) => (
                          <ConsoleRow key={key}>{`${value}: ${key}`}</ConsoleRow>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </Console>
            </Box>
            <Box sx={style.panel.options} className="tools-ip-tracing__panel-options" schema={{}}>
              <Form.Input
                name="ipOrDomain"
                label={t("form.labels.ipOrDomain")}
                direction="vertical"
              />
              <Box sx={style.panel.options.label} className="tools-ip-tracing__panel-options-label">
                {t("pages.tools.ipTracing.optionsLabel")}
              </Box>
              <Form.RadioGroup name="showByCountries" options={packageRoutesOptions} />
              <Form.Captcha ref={captchaRef} />
              <MediaQuery minWidth="bp576">
                <Button type="submit" variant="primary" fullwidth loading={loading}>
                  {loading ? t("common.cancel") : t("form.actions.startScan")}
                </Button>
              </MediaQuery>
            </Box>

            <MediaQuery maxWidth="bp576">
              <Button type="submit" variant="primary" fullwidth loading={loading}>
                {loading ? t("common.cancel") : t("form.actions.startScan")}
              </Button>
            </MediaQuery>
          </Box>
        </Form>

        <IpTracingSeo />
      </Box>
    </>
  );
};

export default IpTracing;
