import { matchPath, useLocation } from "react-router-dom";

import { useSetSeoPage } from "../../../hooks";

import { Contacts } from "./contacts/contacts";
import { Hero, ReplenishmentHero } from "./hero/hero";

const Thanks = () => {
  useSetSeoPage();

  const { pathname } = useLocation();
  const condition = matchPath({ path: "/:lang?/payment/replenishment/" }, pathname);

  return (
    <>
      {condition ? <ReplenishmentHero /> : <Hero />}
      <Contacts />
    </>
  );
};

export default Thanks;
