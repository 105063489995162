import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ShortInfoElement from "../../../components/common/tools/short-info-element/short-info-element";
import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Widget } from "../../../components/common/widget/widget";
import { Copy2Icon } from "../../../components/icons";
import { Heading } from "../../../components/ui/heading/heading";
import Tooltip from "../../../components/ui/tooltip/tooltip";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { getToolsState } from "../../../store/tools/tools.selectors";
import { copyTextToClipboard } from "../../../utils/helpers";
import { getCountryFlagIso2 } from "../../../utils/helpers/get-country-flag";

import { MyIpSeo } from "./my-ip-seo";
import { style } from "./myIp.style";

const CopyButton = (props) => {
  const { title, value, onClick } = props;

  if (!value) {
    return null;
  }

  return (
    <>
      <Tooltip title={title}>
        <Box component="button" type="button" onClick={() => onClick(value)} sx={style.copyButton}>
          <Copy2Icon />
        </Box>
      </Tooltip>
    </>
  );
};
const MyIp = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();

  const { t } = useTranslation();

  const { clientIpInfo, clientAnonymityPercent, clientBlackListsCheck } =
    useSelector(getToolsState);
  const { checkIp, getAnonymityPercent, getClientBlackListsCheck } = useActions();

  const [autonomous, setAutonomous] = useState(null);

  useEffect(() => {
    setAutonomous(
      () =>
        window.self !== window.top ||
        window["_proxy_jslib_THIS_HOST"] ||
        typeof ginf !== "undefined" ||
        window["REAL_PROXY_HOST"]
    );
  }, []);

  useEffect(() => {
    if (!clientBlackListsCheck.data) {
      getClientBlackListsCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientBlackListsCheck.data]);

  useEffect(() => {
    if (!clientAnonymityPercent.data) {
      getAnonymityPercent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAnonymityPercent.data]);

  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors || clientAnonymityPercent.error || clientBlackListsCheck.error) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors, clientAnonymityPercent.error, clientBlackListsCheck.error]);

  let usedProxy = t("common.noData");

  if (clientIpInfo.data) {
    usedProxy = clientIpInfo.data.proxy ? t("common.used") : t("common.notUsed");
  }

  const handlerClickCopy = (value) => {
    copyTextToClipboard(value)
      .then(() => {
        enqueueSnackbar(t("copyMessageSuccess"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(t("copyMessageError"), { variant: "error" });
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <>
      <Box sx={style.toolsMyIp} className="tools-my-ip">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.myIp.heading")}
          </Heading>
          {/*<HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">*/}
          {/*  {t.pages.tools.myIp.heading}*/}
          {/*</HeadingWithSeo>*/}
        </ToolsHeader>

        <Box
          sx={style.description}
          className="tools-my-ip__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.myIp.description")
          }}
        />

        <Box
          sx={{
            position: "relative"
          }}
        >
          <Box sx={style.panel2.container}>
            <Widget.Table
              isLoading={clientIpInfo.loading}
              rows={[
                // Disabled at the request of Octanium91
                // {
                //   key: t.pages.tools.myIp.table.yourComputerName,
                //   value: 'yourComputerName',
                //   icon: "ip-info/computer"
                // },
                {
                  key: t("pages.tools.myIp.table.operatingSystem"),
                  value: clientIpInfo.data?.osFamily ?? t("common.noData"),
                  icon: "ip-info/os"
                },
                {
                  key: t("pages.tools.myIp.table.yourBrowser"),
                  value: clientIpInfo.data?.browser ?? t("common.noData"),
                  icon: "ip-info/browser"
                },
                {
                  key: t("pages.tools.myIp.table.yourLocation"),
                  value:
                    clientIpInfo.data?.country && clientIpInfo.data?.city
                      ? `${clientIpInfo.data.country}, ${clientIpInfo.data.city}`
                      : t("common.noData"),
                  icon: "ip-info/location"
                },
                {
                  key: t("pages.tools.myIp.table.yourProvider"),
                  value: clientIpInfo.data?.autonomousSystemOrganization ?? t("common.noData"),
                  icon: "ip-info/provider"
                },
                {
                  key: t("pages.tools.myIp.table.proxy"),
                  value: usedProxy,
                  icon: "ip-info/proxy"
                },
                {
                  key: t("pages.tools.myIp.table.dataProtection"),
                  value: autonomous ? t("common.used") : t("common.notUsed"),
                  icon: "ip-info/data-security"
                },
                {
                  key: t("pages.tools.myIp.table.ipInBlacklists"),
                  value: clientBlackListsCheck.data
                    ? clientBlackListsCheck.data.join(", ")
                    : t("common.notDetected"),
                  icon: "ip-info/user-blocked"
                }
              ]}
            />

            <Box sx={style.panel2.shortInfo.container}>
              <Box sx={style.panel2.shortInfo.ipAndCountry.container}>
                <ShortInfoElement
                  loading={clientIpInfo.loading}
                  imageUrl="/images/icons/ip-info/ip.svg"
                  label={t("shared.tools.yourIp")}
                  sx={style.panel2.shortInfo.ipAndCountry.ip.container}
                >
                  <Box
                    sx={
                      clientIpInfo.loading
                        ? {}
                        : style.panel2.shortInfo.ipAndCountry.ip.listContainer
                    }
                  >
                    {clientIpInfo.data?.ip?.ipv4 && clientIpInfo.data?.ip?.ipv6 ? (
                      <>
                        <Box sx={style.panel2.shortInfo.ipAndCountry.ip.textWithEllipsis}>
                          <CopyButton
                            title={`${t("common.copy")} IPv4`}
                            value={clientIpInfo.data?.ip?.ipv4}
                            onClick={handlerClickCopy}
                          />
                          <Tooltip
                            disbled={!clientIpInfo.data?.ip?.ipv4}
                            title={clientIpInfo.data?.ip?.ipv4}
                          >
                            <Box component="span" sx={{ fontSize: "1.2rem" }}>
                              IPv4:{" "}
                            </Box>
                            {clientIpInfo.data?.ip?.ipv4 ?? "-"}
                          </Tooltip>
                        </Box>
                        <Box sx={style.panel2.shortInfo.ipAndCountry.ip.textWithEllipsis}>
                          <CopyButton
                            title={`${t("common.copy")} IPv6`}
                            value={clientIpInfo.data?.ip?.ipv6}
                            onClick={handlerClickCopy}
                          />
                          <Tooltip
                            disbled={!clientIpInfo.data?.ip?.ipv6}
                            title={clientIpInfo.data?.ip?.ipv6}
                          >
                            <Box component="span" sx={{ fontSize: "1.2rem" }}>
                              IPv6:{" "}
                            </Box>
                            {clientIpInfo.data?.ip?.ipv6 ?? "-"}
                          </Tooltip>
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          ...style.panel2.shortInfo.ipAndCountry.ip.singleIp,
                          ...style.panel2.shortInfo.ipAndCountry.ip.textWithEllipsis
                        }}
                        className={"style.panel2.shortInfo.ipAndCountry.ip.singleIp"}
                      >
                        <CopyButton
                          title={t("common.copy")}
                          value={clientIpInfo.data?.ip?.ipv6 || clientIpInfo.data?.ip?.ipv4}
                          onClick={handlerClickCopy}
                        />

                        <Tooltip
                          disbled={!(clientIpInfo.data?.ip?.ipv6 || clientIpInfo.data?.ip?.ipv4)}
                          title={clientIpInfo.data?.ip?.ipv4 || clientIpInfo.data?.ip?.ipv6}
                        >
                          <>
                            {clientIpInfo.data?.ip?.ipv4 ||
                              clientIpInfo.data?.ip?.ipv6 ||
                              t("common.noData")}
                          </>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </ShortInfoElement>

                {clientIpInfo.data?.country ? (
                  <Box sx={style.panel2.shortInfo.ipAndCountry.country.container}>
                    {clientIpInfo.data.countryCode ? (
                      <Box
                        component="img"
                        src={getCountryFlagIso2(clientIpInfo.data.countryCode)}
                        alt={clientIpInfo.data.country}
                        loading="lazy"
                        sx={{
                          width: 24,
                          height: 24
                        }}
                      />
                    ) : null}
                    <Box
                      sx={{
                        paddingTop: "0.25rem"
                      }}
                    >
                      {clientIpInfo.data.country}
                      {clientIpInfo.data?.city ? `, ${clientIpInfo.data?.city}` : null}
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box sx={style.panel2.shortInfo.divider} />
              <ShortInfoElement
                loading={clientAnonymityPercent.loading}
                imageUrl="/images/icons/ip-info/incognito.svg"
                label={t("shared.tools.yourAnonymity")}
                sx={style.panel2.shortInfo.anonymity.container}
              >
                <Box component="span" sx={style.panel2.shortInfo.anonymity.content}>
                  {clientAnonymityPercent.data
                    ? `${clientAnonymityPercent.data}%`
                    : t("common.noData")}
                </Box>
              </ShortInfoElement>
            </Box>
          </Box>
        </Box>

        <MyIpSeo />
      </Box>
    </>
  );
};

export default MyIp;
