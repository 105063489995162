import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container } from "../../../../components/common/container/container";
import { Heading } from "../../../../components/ui/heading/heading";
import { Tabs } from "../../../../components/ui/tabs/tabs";
import { useTranslateOptions } from "../../../../hooks/use-translate-options";
import { getProxyState } from "../../../../store/selectors";

import { GeosList } from "./geos-list";
import { style } from "./geos.style";

export const Geos = () => {
  const { proxies, proxyOptionsWithLink } = useSelector(getProxyState);

  // Translations
  const { t } = useTranslation();

  const buyProxyOptions = useTranslateOptions(proxyOptionsWithLink, t("buyProxy"));

  return (
    <Box sx={style.homeGeos} className="home-geos">
      <Container>
        <Box sx={style.wrapper} className="home-geos__wrapper">
          <Heading tag="h2" variant="h2" center>
            {t("pages.app.home.geos.heading")}
          </Heading>
          <Tabs name="geoTabs" tabs={buyProxyOptions}>
            {Object.entries(proxies)?.map(([key, vals]) => (
              <GeosList key={`proxy-content-${key}`} data={vals.countries} keyProxyType={key} />
            ))}
          </Tabs>
        </Box>
      </Container>
    </Box>
  );
};
