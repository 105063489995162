import { Box, Skeleton } from "@mui/material";
import { style } from "./subscriptions-skeleton.style";

export const SubscriptionsSkeleton = () => (
  <Box sx={style.container}>
    <Box sx={style.block}>
      <Skeleton variant="rounded" sx={style.heading} />
      <Box sx={style.list}>
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
      </Box>
    </Box>

    <Box sx={style.block}>
      <Skeleton variant="rounded" sx={style.heading} />
      <Box sx={style.list}>
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
        <Skeleton variant="rounded" sx={style.item} />
      </Box>
    </Box>
  </Box>
);
