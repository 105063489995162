/* eslint-disable camelcase */
import { useState } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HeroSelect } from "../../../../components/heroForm/hero-select";
import { Button } from "../../../../components/ui/button/button";
import { Heading } from "../../../../components/ui/heading/heading";
import { useLang, useTranslateOptions } from "../../../../hooks";
import { OrdersService } from "../../../../services";
import { getProxyState } from "../../../../store/selectors";
import { EXPORT_FIELD_REG, PROXY_EXPORT_OPTIONS, ROUTE } from "../../../../utils/constants";
import { getProxyType } from "../../../../utils/helpers/order.helpers";
import ExportModal from "../export-modal/export-modal";

const exportFields = ["login", "password", "port_https", "port_socks5", "ip", "authIp"];
const requiredFields = [
  ["port_https", "port_socks5"]
  // "login",
  // "ip",
];
export const Export = ({ proxyType, ipAddressesIds }) => {
  // console.log("Export: ", ipAddressesIds);

  const { enqueueSnackbar } = useSnackbar();
  const [exportModalOpened, setExportModalOpened] = useState(false);
  const { t } = useTranslation();
  const lang = useLang();
  const formatOptions = useTranslateOptions(PROXY_EXPORT_OPTIONS, t("export")).map((opt) => ({
    ...opt,
    label: opt.rowFormat ? `${opt.label} (${opt.rowFormat})` : opt.label
  }));
  //Redux
  const { proxyTypeIds } = useSelector(getProxyState);
  //select state
  const [formatIndex, setFormatIndex] = useState(0);

  // helpers
  // * pass data to a selected string format
  const formatIpToString = (proxy, rowFormat, decor = "") => {
    // ** Temporary
    // login:password:port_https:ip
    // ip:port_https:login:password
    // login:password:port_socks5:ip
    // ip:port_socks5:login:password
    // **

    // check authType and replace entry (if needed)
    let newRowFormat = rowFormat;
    if (proxy.authIp) {
      newRowFormat = newRowFormat.replace(`${decor}authentication${decor}`, "authIp");
    } else newRowFormat = newRowFormat.replace(`${decor}authentication${decor}`, "login:password");

    const regExp = new RegExp(
      `(?:${decor}(login|password|port_https|port_socks5|ip|authIp)${decor})`,
      "gi"
    );
    // replace all keywords in a string with data
    return newRowFormat.replace(regExp, (matched) => proxy[matched.replaceAll(decor, "")] ?? "");
  };

  const getFormattedData = (data, option) => {
    const isIpV6 = data.proxyTypeName === "IPv6";
    const isLoginAuth = data.authType === "LOGIN";

    // get correct auth data
    const authData = isLoginAuth
      ? {
          login: data.authLogin,
          password: data.authPassword
        }
      : { authIp: data.authIp };

    // get final object with ip and ports
    const proxyData = !isIpV6
      ? {
          port_socks5: data.portSocks,
          port_https: data.proxyPort,
          ip: data.ip,
          ...authData
        }
      : {
          [option.ipv6Protocol === "HTTP" ? "port_https" : "port_socks5"]: data.port,
          ip: data.innerIp,
          ...authData
        };

    const decor = option.key === "ownFormat" ? "%" : "";

    return formatIpToString(proxyData, option.rowFormat, decor);
  };

  //handlers
  const formatChangeHandler = (option) => {
    if (option.key === "ownFormat") {
      if (!ipAddressesIds.length) {
        enqueueSnackbar(t("pages.cabinet.orders.widgets.export.selectProxies"), {
          variant: "error"
        });
        return;
      }

      setExportModalOpened(true);
      return;
    }

    setFormatIndex(formatOptions.indexOf(formatOptions.find((opt) => opt.key === option.key)));
  };

  const runExport = async (option) => {
    // display notification if no IPs selected
    if (!ipAddressesIds.length) {
      enqueueSnackbar(t("pages.cabinet.orders.widgets.export.selectProxies"), { variant: "error" });
      return;
    }

    try {
      // get IPs for export
      const res = await OrdersService.getDataForExportByIpAddresses({
        proxyTypeId: proxyTypeIds.find((el) => el.name === getProxyType(proxyType)).id,
        ipAddressesIds
      });

      if (res.data) {
        // filter proxies by proxyProtocol (only for IPv6 proxies)
        const dataForDownload =
          proxyType === "ipv6"
            ? res.data
                .filter((proxy) => proxy.proxyProtocol === option.ipv6Protocol)
                .map((proxy) => getFormattedData(proxy, option))
            : res.data.map((proxy) => getFormattedData(proxy, option));

        // download (if we have data after filtering)
        if (!dataForDownload.length) {
          // display notification if no data left after filtering

          enqueueSnackbar(t(`pages.cabinet.orders.widgets.export.select${option.ipv6Protocol}`), {
            variant: "error"
          });
        } else {
          // download data
          const a = document.createElement("a");

          if (formatOptions[formatIndex].key.includes("txt")) {
            a.setAttribute(
              "href",
              `data:text/json;charset=utf-8,${encodeURIComponent(dataForDownload.join("\n"))}`
            );

            a.setAttribute("download", "proxies.txt");
          } else if (formatOptions[formatIndex].key.includes("excel")) {
            const reg = /[:|@]/gm;

            a.setAttribute(
              "href",
              `data:text/json;charset=utf-8,${encodeURIComponent(
                dataForDownload.map((ip) => ip.replaceAll(reg, ";")).join("\n")
              )}`
            );

            a.setAttribute("download", "proxies.csv");
          } else if (formatOptions[formatIndex].key.includes("window")) {
            localStorage.setItem("proxiesForExport", JSON.stringify(dataForDownload));
            const newWin = window.open(`/${lang ? lang : "ru"}${ROUTE.EXPORT}`, "_blank");
            if (newWin === null) {
              setTimeout(() => {
                window.open(`/${lang ? lang : "ru"}${ROUTE.EXPORT}`, "_blank");
              });
            }
          }

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };
  const exportHandler = () => {
    const option = formatOptions[formatIndex];

    runExport(option);
  };

  const handlerCloseExportModal = () => {
    setExportModalOpened(false);
  };

  const handlerModalExportSubit = (str) => {
    const option = formatOptions.find((e) => e.key === "ownFormat");
    option["rowFormat"] = str;
    option["ipv6Protocol"] = str.match(EXPORT_FIELD_REG).find((e) => e === "%port_https%")
      ? "HTTP"
      : "SOCKS";

    runExport(option);
  };

  return (
    <>
      <ExportModal
        open={exportModalOpened}
        onClose={handlerCloseExportModal}
        exportFields={exportFields}
        requiredFields={requiredFields}
        exportSubmit={handlerModalExportSubit}
      />
      <Heading tag="h3" variant="h4">
        {t("pages.cabinet.orders.widgets.export.title")}
      </Heading>

      <HeroSelect
        options={formatOptions}
        label={t("pages.cabinet.orders.widgets.export.chooseFormat")}
        selectedIndex={formatIndex}
        onChange={formatChangeHandler}
        customStyles={{
          height: "auto !important",
          marginBottom: "1.8rem",
          optionPadding: "0.6rem"
        }}
      />

      <Button
        size="small"
        onClick={exportHandler}
        // disabled={!ipAddressesIds.length}
      >
        {t("pages.cabinet.orders.widgets.export.downloadList")}
      </Button>
    </>
  );
};
