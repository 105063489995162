import { createSlice } from "@reduxjs/toolkit";
import { fetchSeoBlocks } from "./seo-block.thunks";

const initialState = {
  loading: false,
  errors: null,
  data: {}, // {[localeCode]: {[tagName]: {...data}}}
  loadingKeys: []
};

const SeoBlockSlice = createSlice({
  name: "seoBlock",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSeoBlocks.pending]: (state, action) => {
      const { keys } = action.meta.arg;
      state.loadingKeys = [...state.loadingKeys, ...keys];
      state.loading = true;
    },
    [fetchSeoBlocks.rejected]: (state, action) => {
      const { keys, languageCode } = action.meta.arg;

      state.errors = action.payload;
      state.loading = false;

      const notFoundKeys = keys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: {}
        }
      }, {})

      state.data = {
        ...state.data,
        [languageCode]: {
          ...state.data[languageCode],
          ...notFoundKeys
        }
      };
      state.loadingKeys = state.loadingKeys.filter((key) => !keys.includes(key));
    },
    [fetchSeoBlocks.fulfilled]: (state, action) => {
      const data  = action.payload;
      const { keys, languageCode } = action.meta.arg;

      const notFoundKeys = keys.reduce((acc, key) => {
        return {
          ...acc,
          ...(data.hasOwnProperty(key) ? {} : {[key]: {}})
        }
      }, {})

      state.data = {
        ...state.data,
        [languageCode]: {
          ...state.data[languageCode],
          ...(data ? data : {}),
          ...notFoundKeys
        }
      };

      state.loading = false;
      state.loadingKeys = state.loadingKeys.filter((key) => !keys.includes(key));
    }
  }
});

export const SeoBlockActions = {
  ...SeoBlockSlice.actions,
  fetchSeoBlocks
};

export default SeoBlockSlice.reducer;
