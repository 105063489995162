import React from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Container } from "../../../components/common/container/container";
import { Heading } from "../../../components/ui";

import { style } from "./error.styles";

// import { useTranslation } from "../../../hooks";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Box sx={style.wrapper}>
      <Box component="main" sx={style.container}>
        <Container>
          <Heading center variant="h1">
            {t("pages.common.error.title")}
          </Heading>
          <Box
            sx={{ textAlign: "center", fontSize: "3rem" }}
            dangerouslySetInnerHTML={{ __html: t("pages.common.error.description") }}
          />
        </Container>
      </Box>
    </Box>
  );
};

export default ErrorPage;
