import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../services";

export const fetchMobile = createAsyncThunk(
  "mobile/fetchMobile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getMobileContent();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
