import { useEffect } from "react";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { style } from "./radio-group.style";

export const RadioGroupCustom = ({ options, onChange, selectedIndex, currencySign }) => {
  // € $ UAH
  // const currency =

  useEffect(() => {
    // console.log(options);
  }, [options]);

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <RadioGroup value={selectedIndex} onChange={handleChange}>
      {options.map((el, i) => (
        <FormControlLabel
          key={i}
          value={i}
          control={<Radio sx={style.dot} />}
          label={`${el.label} - ${currencySign}${el.value}`}
          sx={style.radioItem}
        />
      ))}
    </RadioGroup>
  );
};
