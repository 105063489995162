import { appTheme } from "../../../theme";

export const style = {
  container: {
    width: "440px",
    position: "absolute",
    left: "-10rem",
    top: "calc(100% + 12px)",

    borderRadius: "2.4rem",
    background: "white",
    boxShadow: "0px -1px 41.3px 0px rgba(185, 197, 222, 0.43)",
    [appTheme.breakpoints.down("bp1200")]: {
      left: "-35rem"
    },

    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "68px",

      btnClear: {
        fontSize: 14,
        fontWeight: 500,
        color: "var(--clr-primary-400)"
      }
    }
  },
  modal: {
    width: "440px",
    "& *": {
      fontFamily: "Gilroy !important"
    }
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1.6rem",

    title: {
      color: "var(--clr-text-900)",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "20px"
    },

    btnClose: {
      "& > svg": {
        stroke: "#343435",
        strokeWidth: 2
      }
    }
  },

  list: {
    maxHeight: "580px",
    overflowY: "auto"
  },

  item: {
    padding: "12px 20px",

    dateContainer: {
      display: "flex",
      alignItems: "center",
      gap: 1,
      marginBottom: "8px",

      new: {
        padding: "2px 8px",
        borderRadius: 1,
        background: "#66B5FE",
        color: "var(--clr-default-100)",
        fontSize: 12,
        fontWeight: 500
      },

      date: {
        color: "var(--clr-text-300)",
        fontSize: 12,
        textTransform: "capitalize"
      }
    },

    message: {
      color: "var(--clr-text-900)",
      fontSize: "14px",
      lineHeight: "20px"
    },

    btnExpand: {
      marginTop: "8px",
      fontSize: "14px",
      color: "var(--clr-default-600)",
      display: "flex",
      alignItems: "center",
      gap: 1,

      "& > svg": {
        stroke: "var(--clr-default-600)"
      }
    },
    btnCollapse: {
      marginTop: "8px",
      fontSize: "14px",
      color: "var(--clr-text-900)",
      display: "flex",
      alignItems: "center",
      gap: 1,
      textDecorationLine: "underline",

      "& > svg": {
        stroke: "var(--clr-default-900)",
        transform: "rotate(180deg)"
      }
    }
  },
  itemNew: {
    background: "#F5F8FF"
  },

  noNotifications: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "30px 0"
  },
  counter: {
    position: "absolute",
    backgroundColor: "var(--clr-primary-500)",
    color: "var(--clr-text-100)",
    width: "14px",
    height: "14px",
    fontSize: "8px",
    fontWeight: 700,
    transform: "translate(0.8rem, -0.8rem)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& >span": {
      lineHeight: "0"
    }
  }
};
