import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { useLang } from "../../../hooks";
import { PaymentSystemsLoader } from "../../special";
import { style } from "./logo.styles";

const Logo = () => {
  const lang = useLang();

  // console.count("Logo");

  return (
    <>
      <PaymentSystemsLoader/>
      <Box
        sx={style.container}
        className="logo"
      >
        <Link to={`/${lang}/`}>
          <Box
            sx={style.image}
            component="img"
            src="/images/ui/logo.svg"
            width={234}
            height={67}
            alt="youproxy"
            loading="lazy"
          />
        </Link>
      </Box>
    </>
  );
};

export default Logo;
