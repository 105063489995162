import { useEffect, useRef, useState } from "react";

import { Box, ButtonBase, Link, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { ArrowIcon } from "../../../assets/images/icons/arrow-icon";
import { MenuInfoIcon } from "../../../components/icons/index";
import { useLang } from "../../../hooks";
import { getIsAuthenticatedUser } from "../../../store/selectors";
import { TOOLS_ROUTES } from "../../../utils/constants";
import DynamicIcon from "../dynamic-icon/dynamic-icon";
import PopupContainer from "../popup-notifications/popup-container";

import { useMenuHover } from "./header-menu/header-menu.hooks";
import { style } from "./header.style";

export const HeaderTools = () => {
  const lang = useLang();
  const { t } = useTranslation();

  const menuRef = useRef(null);
  const arrowRef = useRef(null);

  const [isClient, setIsClient] = useState(false);
  const isMobileBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("bp576"));
  const isAuthenticated = useSelector(getIsAuthenticatedUser);

  const { menuHover, disableMenuHover } = useMenuHover(menuRef, arrowRef);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      <Box sx={style.toolContainer}>
        <Box
          sx={style.tools}
          className="header__tools"
          onMouseOver={menuHover}
          onMouseLeave={disableMenuHover}
          ref={menuRef}
        >
          <ButtonBase
            sx={{ ...style.tools.buttonCommon, ...style.tools.btnMenu }}
            className="header__tools-button"
            aria-label="Utils"
          >
            <MenuInfoIcon />
          </ButtonBase>

          <Box sx={[style.dropdown, style.dropdown.tools]} className="header__dropdown">
            <Box ref={arrowRef} sx={style.dropdownArrow}>
              <ArrowIcon />
            </Box>
            <Box component="ul" sx={style.tools.list} className="header__tools-list">
              {TOOLS_ROUTES.routes.map((route) => (
                <Box
                  component="li"
                  sx={style.tools.item}
                  key={route.key}
                  className="header__tools-item"
                >
                  <Link
                    component={RouterLink}
                    sx={style.tools.link}
                    to={`/${lang}/tools/${route.key}/`}
                    className="header__tools-link"
                    onClick={disableMenuHover}
                  >
                    {route.icon ? (
                      <Box sx={style.tools.icon} className="header__tools-icon">
                        <DynamicIcon iconName={route.icon} />
                      </Box>
                    ) : null}
                    <Box sx={style.tools.label} className="header__tools-label">
                      {t(`tools.${route.translation}.label`)}
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {isClient && !isMobileBreakpoint && isAuthenticated && (
          <PopupContainer
            styleBtnNotification={{
              ...style.tools.buttonCommon,
              ...style.tools.btnNotifications
            }}
          />
        )}
      </Box>
    </>
  );
};
