import { Box } from "@mui/material";
import { Container } from "../../../../components/common/container/container";

import { PARTNERS_LIST } from "../../../../utils/constants/partners.constants";

// import "./partners.scss";
import { style } from "./partners.style";

export const Partners = () => (
  <Box sx={style.reviewsPartners} className="reviews-partners">
    <Container>
      <Box component="ul" sx={style.list} className="reviews-partners__list">
        {PARTNERS_LIST.map((partner) => (
          <Box component="li" sx={style.item} key={partner.name} className="reviews-partners__item">
            <a href={partner.link} target="_blank" rel="noreferrer" aria-label={`Read about us on ${partner.name}`}>
              <img
                src={partner.image}
                width={170}
                height={110}
                alt=""
                aria-hidden={true}
                loading="lazy"
              />
            </a>
          </Box>
        ))}
      </Box>
    </Container>
  </Box>
);
