import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AuthFormFooter from "../../../components/common/auth-form-footer/auth-form-footer";
import { Container } from "../../../components/common/container/container";
import { Form } from "../../../components/form/form";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useLang, useSetSeoPage } from "../../../hooks";
import { UserService } from "../../../services";
import { getUserState } from "../../../store/selectors";
import { ROUTE } from "../../../utils/constants";
import { getCaptchaTokenFromFormData } from "../../../utils/helpers";
import { RegistrationSchema } from "../../../utils/validation";

import { style } from "./register.style";

const Register = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const captchaRegisterRef = useRef(null);
  const captchaLoginRef = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = useLang();
  const { user, isAuthenticated } = useSelector(getUserState);
  const { setUser } = useActions();
  const isMobileBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("bp576"));

  const [isLoading, setIsLoading] = useState(false);
  const [tokenFromLoginCaptcha, setTokenFromLoginCaptcha] = useState("");

  const handleSubmit = async (formObj) => {
    setIsLoading(true);

    try {
      const cfToken = getCaptchaTokenFromFormData(formObj);

      const { status } = await UserService.register(
        {
          email: formObj.registrationEmail,
          password: formObj.registrationPassword,
          localeCode: lang
        },
        cfToken
      );

      // status === 200 - OK
      // status === 208 - ALREADY EXIST
      if (status === 208) {
        // setMsg("User already exist!!!");
        enqueueSnackbar(t("userExist"), { variant: "error" });
      } else if (status === 200) {
        // setMsg("Registration complete!!!");
        await UserService.login(
          {
            username: formObj.registrationEmail,
            password: formObj.registrationPassword
          },
          tokenFromLoginCaptcha
        );
        const res = await UserService.getUser();
        setUser(res.data);
        enqueueSnackbar(t("registerSuccess"), { variant: "success" });
      }
    } catch (err) {
      let message = t("serverError");

      if (err.response.status === 406) {
        message = t("form.rules.email.invalid");
      } else if (err.response.status === 422) {
        message = t("form.rules.password.invalid");
      } else if (err.response.status === 403) {
        message = t("messages.temporaryEmails");
      } else if (err.response.status === 409) {
        captchaRegisterRef?.current?.reset();
        captchaLoginRef?.current?.reset();
        message = t("messages.captchaFailed");
      } else {
        // eslint-disable-next-line no-console
        console.error("Error: ", err?.response?.response || err?.response || err);
      }
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      captchaRegisterRef?.current?.reset();
      captchaLoginRef?.current?.reset();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${lang}${ROUTE.CABINET.PROFILE}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box sx={style.register} className="register">
      <Container>
        <Box sx={style.wrapper} className="register__wrapper">
          <Heading tag="h1" variant="h2">
            {t("pages.auth.register.heading")}
          </Heading>
          <Box sx={style.inner} className="register__inner">
            <Form
              sx={style.form}
              className="register__form"
              schema={RegistrationSchema(t("form"))}
              onSubmit={handleSubmit}
            >
              <Form.Input
                name="registrationEmail"
                label={isMobileBreakpoint ? null : t("form.labels.email")}
                placeholder={`${t("form.placeholders.yourEmail")}...`}
                labelType="outer"
                variant="outlined"
                autoComplete="off"
              />
              <Form.Input
                name="registrationPassword"
                label={isMobileBreakpoint ? null : t("form.labels.password")}
                placeholder={`${t("form.placeholders.enterPassword")}...`}
                labelType="outer"
                variant="outlined"
                type="password"
                actions
                autoComplete="new-password"
              />
              <Form.Captcha ref={captchaRegisterRef} id="register-captcha" />
              <Form.Captcha
                id="login-after-register-captcha"
                ref={captchaLoginRef}
                onSuccess={(token) => {
                  setTokenFromLoginCaptcha(token);
                }}
                sx={{
                  display: "none"
                }}
              />
              <Button type="submit" variant="primary" fullwidth loading={isLoading}>
                {t("form.actions.register")}
              </Button>
            </Form>
            {isMobileBreakpoint ? (
              <Box sx={style.description} className="login__description">
                {t("pages.auth.login.agreement")}
              </Box>
            ) : null}

            <AuthFormFooter
              links={[
                // {label: t.pages.auth.login.recoverPassword, link: ROUTE.AUTH.FORGOT},
                { label: t("pages.auth.login.login"), link: ROUTE.AUTH.LOGIN }
              ]}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Register;
