import { Box, ButtonBase, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { CopyIcon } from "../../../components/icons/index";
import { CopyToClipboard } from "../../../components/ui/copy-to-clipboard/copy-to-clipboard";
import { useLang } from "../../../hooks";
// import "./promo-card.scss";
import { formatDateTimeParts } from "../../../utils/helpers";

import { style } from "./promoCard.style";

export const PromoCard = (props) => {
  const { promocode } = props;

  const lang = useLang();
  const { t } = useTranslation();

  let endDate = null;

  if (promocode.endDate) {
    const date = formatDateTimeParts(new Date(promocode.endDate));

    endDate = `${date.day}.${date.month}.${date.year}`;
  }
  return (
    <Box component="li" sx={style.promoCard} className="promo-card">
      <Box sx={style.left} className="promo-card__left">
        <Box sx={style.discount} className="promo-card__discount">
          {promocode.amount}%
        </Box>
        <Box sx={style.description} className="promo-card__description">
          {promocode.descriptionsByLocale[lang]}
        </Box>
        <Box sx={style.uses} className="promo-card__uses">{`${t("pages.app.promocodes.used")} ${
          promocode.countUses
        } ${t("pages.app.promocodes.times")}`}</Box>
      </Box>
      <Box sx={style.right} className="promo-card__right">
        <CopyToClipboard text={promocode.code}>
          <ButtonBase sx={style.code} className="promo-card__code">
            <span title={promocode.code}>{promocode.code}</span>
            <CopyIcon />
          </ButtonBase>
        </CopyToClipboard>
        <Box sx={style.expired} className="promo-card__expired">
          {endDate ? `${t("pages.app.promocodes.validDate")}: ${endDate}` : null}
        </Box>

        <Link
          component={RouterLink}
          to={`/${lang}/?promocode=${promocode.code}`}
          sx={style.link}
          className="promo-card__link"
        >
          {t("pages.app.promocodes.link")}
        </Link>
      </Box>
    </Box>
  );
};
