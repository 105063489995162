import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ToolsHeader from "../../../components/common/tools/tools-header/tools-header";
import { Widget } from "../../../components/common/widget/widget";
import { Heading } from "../../../components/ui/heading/heading";
import { useActions, useSetSeoPage } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { getFingerprintVisitorId, getToolsState } from "../../../store/selectors";
import { detectPlugin } from "../../../utils/helpers";

import { FingerprintScannerSeo } from "./fingerprint-scanner-seo";
import { style } from "./fingerprintScanner.style";

const Fingerprint = () => {
  useSetSeoPage();

  const { enqueueSnackbar } = useSnackbar();
  const seo = useCurrentSeo();

  const { t } = useTranslation();

  const fingerprintVisitorId = useSelector(getFingerprintVisitorId);
  const { clientIpInfo, clientFingerprintInfo, clientDns } = useSelector(getToolsState);
  const { checkIp, getFingerprint, getClientDns } = useActions();
  const [plugins, setPlugins] = useState(null);
  const [userAgent, setUserAgent] = useState(null);

  useEffect(() => {
    setUserAgent(navigator?.userAgent);

    setPlugins({
      flash: detectPlugin.flash(),
      java: detectPlugin.java(),
      activeX: detectPlugin.activeX(),
      vBScript: detectPlugin.vBScript()
    });
  }, []);

  useEffect(() => {
    if (!clientDns.data) {
      getClientDns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDns.data]);

  useEffect(() => {
    if (!clientFingerprintInfo.data) {
      getFingerprint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFingerprintInfo.data]);

  useEffect(() => {
    if (!clientIpInfo.data) {
      checkIp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.data]);

  useEffect(() => {
    if (clientIpInfo.errors || clientFingerprintInfo.errors) {
      enqueueSnackbar(t("serverError"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIpInfo.errors, clientFingerprintInfo.errors]);

  return (
    <>
      <Box sx={style.toolsFingerprintScanner} className="tools-fingerprint-scanner">
        <ToolsHeader>
          <Heading tag="h1" variant="h2">
            {seo["h1"] ? seo["h1"] : t("pages.tools.fingerprintScanner.heading")}
          </Heading>
        </ToolsHeader>
        <Box
          sx={style.description}
          className="tools-fingerprint-scanner__description"
          dangerouslySetInnerHTML={{
            __html: seo["h1Text"] ? seo["h1Text"] : t("pages.tools.fingerprintScanner.description")
          }}
        ></Box>
        <Box sx={{ position: "relative" }}>
          <Box sx={style.widgets} className="tools-fingerprint-scanner__widgets">
            <Widget.Copy
              copyDisabled={true}
              isLoading={!(!clientIpInfo.loading && !clientFingerprintInfo.loading)}
              data={{
                image: "/images/icons/widgets/country-ownership.svg",
                title: t("shared.tools.yourIp"),
                text:
                  clientIpInfo.data?.ip?.ipv6 || clientIpInfo.data?.ip?.ipv4 || t("common.noData")
              }}
            />
            <Widget.Copy
              copyDisabled={true}
              isLoading={!(!clientIpInfo.loading && !clientFingerprintInfo.loading)}
              data={{
                image: "/images/icons/widgets/country-ownership.svg",
                title: t("pages.tools.fingerprintScanner.widgets.fingerprint"),
                text: fingerprintVisitorId ?? t("common.noData")
              }}
            />

            <Widget.Table
              isLoading={!(!clientIpInfo.loading && !clientFingerprintInfo.loading)}
              rows={[
                {
                  key: t("pages.tools.fingerprintScanner.table.location"),
                  value: (() => {
                    if (!clientFingerprintInfo.data?.country) {
                      return t("common.noData");
                    }
                    let str = clientFingerprintInfo.data?.country;

                    if (clientFingerprintInfo.data?.countryCode) {
                      str += ` (${clientFingerprintInfo.data.countryCode})`;
                    }

                    if (clientFingerprintInfo.data?.city) {
                      str += `, ${clientFingerprintInfo.data?.city}`;
                    }

                    return str;
                  })(),
                  icon: "ip-info/location"
                },
                {
                  key: t("pages.tools.fingerprintScanner.table.dns"),
                  value: clientDns.data?.dns?.ip ? clientDns.data.dns.ip : t("common.noData"),
                  icon: "ip-info/dns"
                },
                {
                  key: t("pages.tools.fingerprintScanner.table.provider"),
                  value: clientFingerprintInfo.data?.autonomousSystemNumber
                    ? clientFingerprintInfo.data.autonomousSystemNumber
                    : t("common.noData"),
                  icon: "ip-info/provider"
                },
                {
                  key: t("pages.tools.fingerprintScanner.table.host"),
                  value: clientFingerprintInfo.data?.host
                    ? clientFingerprintInfo.data.host
                    : t("common.noData"),
                  icon: "ip-info/host"
                },
                {
                  key: t("pages.tools.fingerprintScanner.table.os"),
                  value: clientFingerprintInfo.data?.osFamily
                    ? clientFingerprintInfo.data.osFamily
                    : t("common.noData"),
                  icon: "ip-info/os"
                },
                {
                  key: t("pages.tools.fingerprintScanner.table.browser"),
                  value: clientFingerprintInfo.data?.browser
                    ? clientFingerprintInfo.data.browser
                    : t("common.noData"),
                  icon: "ip-info/browser"
                }
              ]}
              patch="right"
              size="small"
            />
            <Widget.Table
              isLoading={!(!clientIpInfo.loading && !clientFingerprintInfo.loading)}
              rows={[
                // TODO Removed by request gorodiskiy4
                // {
                //   key: "JavaScript:",
                //   // eslint-disable-next-line no-nested-ternary
                //   value: isSSR() ? t.common.noData : window ? t.common.on : t.common.off,
                //   icon: "plugins/js"
                // },
                {
                  key: "Flash:",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.flash ? t("common.on") : t("common.off"),
                  icon: "plugins/flash"
                },
                {
                  key: "Java:",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.java ? t("common.on") : t("common.off"),
                  icon: "plugins/java"
                },
                {
                  key: "ActiveX:",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.activeX ? t("common.on") : t("common.off"),
                  icon: "plugins/ax"
                },
                {
                  key: "VBScript:",
                  // eslint-disable-next-line no-nested-ternary
                  value: plugins?.vBScript ? t("common.on") : t("common.off"),
                  icon: "plugins/vbscript"
                }
              ]}
              heading={{
                key: t("pages.tools.fingerprintScanner.table.plugin"),
                value: t("pages.tools.fingerprintScanner.table.status")
              }}
              patch="right"
              size="small"
            />

            <Widget.Info
              data={{
                title: t("pages.tools.fingerprintScanner.table.userAgent"),
                text: clientFingerprintInfo.data?.userAgent
                  ? clientFingerprintInfo.data.userAgent
                  : t("common.noData")
              }}
            />
            <Widget.Info
              data={{
                title: t("pages.tools.fingerprintScanner.table.userAgentJs"),
                text: userAgent ?? t("common.noData")
              }}
            />
          </Box>
        </Box>

        <FingerprintScannerSeo />
      </Box>
    </>
  );
};

export default Fingerprint;
