import { useState } from "react";

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form } from "../../../components/form";
import { Button } from "../../../components/ui/button/button.jsx";
import { Heading } from "../../../components/ui/heading/heading.jsx";
import { Modal } from "../../../components/ui/modal/modal.jsx";
import { useLang } from "../../../hooks";
import { ExtendService } from "../../../services/extend.service";
import { getProxyState } from "../../../store/selectors";
import { getProxyType } from "../../../utils/helpers/order.helpers";

import { style } from "./replace-authorization.style.js";

export default function ReplacementModal({ openedModal, closeModal, proxyType, ipAddressIds }) {
  const { t } = useTranslation();
  const { replacement } = t("pages.cabinet.orders.widgets");
  const reasons = replacement.reasons.split(",");
  const lastReason = reasons[reasons.length - 1];
  const [reasonReplacement, setReasonReplacement] = useState("");
  const choosenLastReason = lastReason === reasonReplacement;
  const [message, setMessage] = useState("");

  const { proxyTypeIds } = useSelector(getProxyState);
  const lang = useLang();
  const { enqueueSnackbar } = useSnackbar();

  const submitHandler = async () => {
    const emptyComment = choosenLastReason && message.trim().length < 3;
    if (emptyComment) {
      enqueueSnackbar(replacement.warning, {
        variant: "error"
      });
      return;
    }

    const req = {
      proxyTypeId: proxyTypeIds.find((el) => el.name === getProxyType(proxyType)).id,
      ipAddressIds,
      comment: choosenLastReason ? message.trim() : reasonReplacement,
      languageCode: lang
    };

    try {
      const res = await ExtendService.replaceIpAddress(req);
      if (res.status === 200) {
        enqueueSnackbar(replacement.success, { variant: "success" });
        closeModal();
      } else {
        enqueueSnackbar(replacement.rejected, { variant: "error" });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  return (
    <Modal customStyles={style.modalBlock} visible={openedModal} cancelHandler={closeModal}>
      <Form onSubmit={submitHandler}>
        <Box sx={style.modal.upper}>
          <Heading tag="h4" variant="h4">
            {replacement.title}
          </Heading>

          <Box component="p" sx={style.modal.subTitle}>
            {replacement.description}
          </Box>

          <FormControl>
            <FormLabel sx={style.modal.titleBlock} id="reasonReplacement">
              {replacement.question}
            </FormLabel>
            <RadioGroup
              aria-labelledby="reasonReplacement"
              value={reasonReplacement}
              onChange={(e) => setReasonReplacement(e.target.value)}
            >
              {reasons?.map((el) => (
                <FormControlLabel
                  key={el}
                  value={el}
                  control={<Radio sx={{ display: "none" }} />}
                  label={
                    <Box sx={style.modal.radioGroup}>
                      <Box className={`radioIcon ${reasonReplacement === el ? "checked" : ""}`}>
                        {reasonReplacement === el && <Box className="innerCircle" />}
                      </Box>
                      <Box
                        component="span"
                        className={`radioLabel ${reasonReplacement === el ? "checked" : ""}`}
                      >
                        {el}
                      </Box>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>

          {choosenLastReason && (
            <Box sx={style.modal.textAreaContainer}>
              <Form.Input
                value={message}
                name="message"
                type="textarea"
                direction="horizontal"
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Box>
          )}
        </Box>
        <Box sx={style.modal.bottom}>
          <Button
            disabled={!reasonReplacement}
            type="submit"
            variant="primary"
            ariaLabel="submit"
            size="medium"
          >
            {t("other.send")}
          </Button>
          <Button onClick={closeModal} type="submit" size="medium" ariaLabel="submit">
            {t("other.cancel")}
          </Button>
        </Box>
      </Form>
    </Modal>
  );
}
