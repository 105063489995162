export const style = {
  modal: {
    padding: "3.6rem 3.6rem 4rem",
    "& .heading": {
      fontWeight: 700
    }
  },
  description: {
    marginTop: "1.2rem",

    fontWeight: 400,
    lineHeight: "2.17rem",
  },
  input: {
    marginTop: "3.2rem"
  },
  info: {
    marginTop: "1.6rem",
    display: "flex",
    fontWeight: 400,
    fontSize: "1.4rem",
    lineHeight: "1.68rem",
  },
  infoIcon: {
    marginRight: "1rem"
  },
  infoContent: {
    "& > p": {
      "&:not(:last-child)": {
        marginBottom: "1rem",
      },
      "& > span": {
        color: "var(--clr-text-500)",
        fontWeight: 500
      }
    }
  },
  buttons: {
    marginTop: "3.2rem",
    display: "flex",

    "& > button:last-child": {
      marginLeft: "2.4rem"
    }
  },

  variablesList: {
    display: "inline-flex",
    flexWrap: "wrap",

    "& > *:not(:last-child)": {
      marginRight: "1rem"
    }
  }
};
