export const ADVANTAGES = [
  {
    icon: "country-ownership",
    translation: "countryOwnership"
  },
  {
    icon: "protocol-definition",
    translation: "protocolDefinition"
  },
  {
    icon: "anonymous-checking",
    translation: "anonymousChecking"
  },
  {
    icon: "proxy-checking",
    translation: "proxyChecking"
  },
  {
    icon: "download-upload",
    translation: "downloadUpload"
  },
  {
    icon: "results-filter",
    translation: "resultsFilter"
  }
];
