import * as yup from "yup";

import { EXPORT_FIELD_REG } from "../constants";

export const OrderCustomExportShema = (t, exportFields = [], requiredFields = []) => {
  const exportFieldsAsPatterns = exportFields.map((e) => `%${e}%`);

  return yup.object().shape({
    exportPattern: yup
      .string()
      .required(t("form.rules.personalExportPattern.required"))
      .test("checkPersonalExportPattern", "", (value, context) => {
        const patterns = value.match(EXPORT_FIELD_REG) ?? [];

        if (!patterns.length) {
          return context.createError({
            path: context.path,
            message: t("form.rules.personalExportPattern.atLeastOneVariableMustBeUsed")
          });
        }

        const invalidFields = patterns.filter((e) => exportFieldsAsPatterns.indexOf(e) === -1);

        if (invalidFields?.length) {
          return context.createError({
            path: context.path,
            message: `${t(
              "form.rules.personalExportPattern.invalidVariables"
            )}: ${invalidFields.join(", ")}.`
          });
        }

        const requiredFieldsArr = requiredFields
          .filter((requiredField) => {
            if (Array.isArray(requiredField)) {
              return !requiredField.some((e) => patterns.indexOf(`%${e}%`) !== -1);
            }

            return patterns.indexOf(`%${requiredField}%`) === -1;
          })
          .map((e) => (Array.isArray(e) ? `(${e.join("/")})` : e));

        if (requiredFieldsArr?.length) {
          return context.createError({
            path: context.path,
            message: `${t(
              "form.rules.personalExportPattern.requiredVariables"
            )}: ${requiredFieldsArr.join(", ")}.`
          });
        }

        return true;
      })
  });
};
