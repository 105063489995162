import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  heading: {
    [`h1, h2, h3, h4`]: {
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
    },

    'span': {
      position: 'relative',
      zIndex: 2,

      '&::before': {
        content: `""`,
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: '-1',
        width: '125%',
        height: '115%',
        backgroundImage: 'url("/images/ui/text-stroke.svg")',

        backgroundPosition: '50% 50%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transform: 'translate(-50%, -50%)',
      }
    },

    h1mod: {
      fontWeight: '700',
      fontSize: fluid(4.8, 3),
      lineHeight: '125%',
    },

    h2mod: {
      fontWeight: '700',
      fontSize: fluid(3.6, 2.4),
      lineHeight: '117%',
    },

    // &_h3 {
    // }

    h4mod: {
      fontWeight: '600',
      fontSize: '2rem',
      lineHeight: '120%',
    },

    centerMod: {
      textAlign: 'center',
    },

    lightMod: {
      '&$h4mod': {
        fontWeight: '400',
      },

      '&$colorMod': {
        '&$h4mod': {
          color: 'var(--clr-text-800)',
        }
      }
    },

    colorMod: {
      light: {
        color: 'var(--clr-text-100)',
      },

      dark: {
        '&.heading_h1': {
          color: 'var(--clr-text-700)',
        },

        '&.heading_h2': {
          color: 'var(--clr-text-600)',
        },

        '&.heading_h4': {
          color: 'var(--clr-text-800)',
        }
      }
    }
  }
}
