import Article from "../pages/app/article/article";
import Blog from "../pages/app/blog/blog";
import Conditions from "../pages/app/conditions/conditions";
import Contacts from "../pages/app/contacts/contacts";
import CookiePolicy from "../pages/app/cookie-policy/cookie-policy";
import CountryProxy from "../pages/app/country-proxy/country-proxy";
import Faq from "../pages/app/faq/faq";
import Home from "../pages/app/home/home";
import MobileProxy from "../pages/app/mobile-proxy/mobile-proxy";
import Offer from "../pages/app/offer/offer";
import Policy from "../pages/app/policy/policy";
import Price from "../pages/app/price/price";
import Promocodes from "../pages/app/promocodes/promocodes";
import Proxy from "../pages/app/proxy/proxy";
import RedirectPaymentStatus from "../pages/app/redirect-payment-status/redirect-payment-status";
import RefundPolicy from "../pages/app/refund-policy/refund-policy";
import Reviews from "../pages/app/reviews/reviews";
import Thanks from "../pages/app/thanks/thanks";
import NotFound from "../pages/common/not-found/not-found";
import { ROUTE } from "../utils/constants";

export const appRoutes = (ssr = false) => [
  {
    isSsr: ssr ?? false,
    path: `${ROUTE.APP.REDIRECT_PAYMENT_STATUS}/:status`,
    tag: "redirect-payment-status",
    component: ssr
      ? RedirectPaymentStatus
      : () => import("../pages/app/redirect-payment-status/redirect-payment-status")
  },
  {
    isSsr: ssr ?? false,
    path: ROUTE.APP.HOME,
    tag: "home",
    component: ssr ? Home : () => import("../pages/app/home/home")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.HOME}`,
    tag: "home",
    component: ssr ? Home : () => import("../pages/app/home/home")
  },
  // {
  //   path: "/:lang?/proxy/:proxyType/:social/",
  //   tag: "proxy-for-goal",
  //   component: ssr ?        :    (() => import("../pages/app/proxy/proxy"))
  // },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.MOBILE}`,
    tag: "mobile-proxy",
    component: ssr ? MobileProxy : () => import("../pages/app/mobile-proxy/mobile-proxy")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.IPV6}`,
    tag: "ipv6-proxy",
    component: ssr ? Proxy : () => import("../pages/app/proxy/proxy")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.ISP}`,
    tag: "isp-proxy",
    component: ssr ? Proxy : () => import("../pages/app/proxy/proxy")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.PAYMENT_SUCCESS}`,
    tag: "thanks",
    component: ssr ? Thanks : () => import("../pages/app/thanks/thanks")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.PAYMENT_REPLENISHMENT}`,
    tag: "balance-thanks",
    component: ssr ? Thanks : () => import("../pages/app/thanks/thanks")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.FAQ}`,
    tag: "faq",
    component: ssr ? Faq : () => import("../pages/app/faq/faq")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.PROMO_CODES}`,
    tag: "promocodes",
    component: ssr ? Promocodes : () => import("../pages/app/promocodes/promocodes")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.REVIEWS}`,
    tag: "reviews",
    component: ssr ? Reviews : () => import("../pages/app/reviews/reviews")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.BLOG}`,
    tag: "blog",
    component: ssr ? Blog : () => import("../pages/app/blog/blog")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.BLOG}/:tag`,
    tag: "article",

    component: ssr ? Article : () => import("../pages/app/article/article")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.CONTACT_US}`,
    tag: "contacts",
    component: ssr ? Contacts : () => import("../pages/app/contacts/contacts")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.CONDITIONS}`,
    tag: "conditions",
    component: ssr ? Conditions : () => import("../pages/app/conditions/conditions")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.OFFER}`,
    tag: "offer",
    component: ssr ? Offer : () => import("../pages/app/offer/offer")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.PRIVACY_POLICY}`,
    tag: "policy",
    component: ssr ? Policy : () => import("../pages/app/policy/policy")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.REFUND_POLICY}`,
    tag: "refund-policy",
    component: ssr ? RefundPolicy : () => import("../pages/app/refund-policy/refund-policy")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.COOKIE_POLICY}`,
    tag: "cookie-policy",
    component: ssr ? CookiePolicy : () => import("../pages/app/cookie-policy/cookie-policy")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.PRICES}`,
    tag: "price",
    component: ssr ? Price : () => import("../pages/app/price/price")
  },
  {
    isSsr: ssr ?? false,
    path: `/:lang?${ROUTE.APP.NOT_FOUND}`,
    tag: "not-found",
    component: ssr ? NotFound : () => import("../pages/common/not-found/not-found")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang?/:proxyCountry/",
    tag: "countries",
    component: ssr ? CountryProxy : () => import("../pages/app/country-proxy/country-proxy")
  }
];
