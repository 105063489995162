import { fluid } from "../../../../../assets/styles/functions/fluid";
import { appTheme } from "../../../../../theme";

export const style = {
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "4.5rem",
    padding: "1.4rem",
    boxShadow: "0px 4px 20px rgba(154, 162, 182, 0.12)",
    backgroundImage:
      "linear-gradient(96.2deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 51.56%, #ffffff 100%)",
    transition: "box-shadow var(--transition)",

    [appTheme.breakpoints.down("bp928")]: {
      flexDirection: "column",
      justifyContent: "start",
      gap: "1rem",
      paddingLeft: "2rem"
    },

    [`@media (hover)`]: {
      "&:hover": {
        boxShadow: "0 2rem 2rem rgba(179 184 202 / 22%)",
        transition: "box-shadow var(--transition)"
      }
    }
  },
  table: {
    minWidth: "40rem"
  },
  tableContainer: {
    // overflow: "auto",
    padding: "1rem",
    margin: "-1rem"
  },
  tableHead: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "4.5rem",
    // padding: "1.4rem",
    backgroundColor: "var(--clr-default-400)",

    fontWeight: "600",
    fontSize: "1.6rem",
    whiteSpace: "nowrap",
    color: "var(--clr-text-900)"
  },

  closeIcon: {
    "& > svg": {
      display: "flex",
      cursor: "pointer",
      padding: "0.4rem",
      width: "2.4rem",
      height: "2.4rem",
      objectFit: "contain"
    }
  },
  startSearch: {
    container: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      paddingRight: "2.45rem",
      "& > svg": {
        fill: "var(--clr-text-900)"
      }
    },
    label: {
      textOverflow: "ellipsis"
    }
  },
  priceContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 105px)",
    gridGap: "2.1rem",
    textAlign: "left",
    marginRight: "auto",
    [appTheme.breakpoints.down("bp928")]: {
      gridTemplateColumns: "repeat(4, 82px)",
      gridGap: "0.3rem"
    },

    quantity: {
      marginBottom: "0.44rem",
      fontWeight: "400",
      fontSize: "1.4rem",
      color: "var(--clr-text-400)",
      whiteSpace: "nowrap",
      [appTheme.breakpoints.down("bp928")]: {
        fontSize: "1.2rem"
      }
    },
    amount: {
      fontWeight: 500,
      fontSize: fluid(1.8, 1.6),
      color: "var(--clr-text-900)",
      whiteSpace: "nowrap",
      [appTheme.breakpoints.down("bp928")]: {
        fontSize: fluid(1.6, 1.4)
      }
    }
  }
};
