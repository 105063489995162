import { checkIpAgainstRanges } from "../../../../components/common/ip-input-mask/utils";
import { IP_REG } from "../../../../utils/constants";

function filterDuplicateIPs(data) {
  const seenIps = new Set();

  return data.filter((item) => {
    if (item && item.ip && !seenIps.has(item.ip)) {
      seenIps.add(item.ip);

      return true;
    }

    return !item?.ip;
  });
}

export const validateIp = (ip) => !IP_REG.test(ip) || checkIpAgainstRanges(ip);

export function transformData(arr) {
  const filteredData = filterDuplicateIPs(arr);
  return filteredData
    .map((item) => {
      const { id = null, main, type, ip, login, password } = item || {};
      const baseObject = { main, type };

      if (type === "IP") {
        return id ? { ...baseObject, id, ip } : { ...baseObject, ip };
      } else if (type === "LOGIN") {
        return id ? { ...baseObject, id, login, password } : { ...baseObject, login, password };
      }

      return null;
    })
    .filter(Boolean);
}
