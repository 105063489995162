export const style = {
  label: {
    fontSize: "1.4rem",
    color: "#64647D",
    paddingTop: "2rem",
    paddingBottom: "1.6rem",
    "&.Mui-focused": {
    color: "#64647D !important",
    }
  },

  group: {
    flexDirection: "row"
  },



  radioItem: {
    transition: "0.3s",
    margin: "0",
    "& span": {
      fontSize: "1.4rem"
    },

    "&:not(:last-child)": {
      marginRight: "3.5rem"
    },

    error: {
      color: "#FF0000"
    }
  },

  radio: {
    padding: "0",
    marginRight: "8px"
  },

  input: {
    container: {
      display: "flex",
      alignItems: "center"
    },

    inputField: {
      display: "flex",
      alignItems: "center",
      padding: "0.7rem 1.6rem",
      borderRadius: "1rem",
      border: "1px solid #517FF5",
      // border: "1px solid transparent",
      backgroundColor: "var(--clr-default-200)",
      transition: "0.3s",
      "&:hover": {
        background: "#ebeff8"
      }
    },
    infoBadge: {
      display: "flex",
      alignItems: "center",
      marginLeft: "0.7rem"
    },

    error: {
      borderColor: "#FF0000"
    },

    close: {
      cursor: "pointer",
      height: "2.4rem",
      width: "2.4rem",
      marginLeft: "3rem",
      display: "grid",
      placeItems: "center"
    },

    tooltip: {
      fontSize: "1.2rem",
      color: "#343435",
      background: "#fff",
      boxShadow: "0px 0px 11px 0px #878CBD38",
      padding: "1.2rem 1.6rem",
      borderRadius: "0.8rem"

    }
  }
}
