import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Container } from "../../../components/common/container/container";
import HeadingWithSeo from "../../../components/common/heading-with-seo/heading-with-seo";
import { Button } from "../../../components/ui/button/button";
import { useLang, useSetSeoPage } from "../../../hooks";

import { style } from "./notFound.style";

const NotFound = () => {
  useSetSeoPage();

  const lang = useLang();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToMainPage = () => {
    navigate(`/${lang}/`);
  };

  return (
    <Box sx={style.notFound} className="not-found">
      <Container>
        <Box sx={style.wrapper} className="not-found__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h1">
            {t("pages.app.notFound.title")}
          </HeadingWithSeo>
          <Box sx={style.description} className="not-found__description">
            {t("pages.app.notFound.text")}
          </Box>

          <Button onClick={goToMainPage} variant="primary">
            {t("pages.app.notFound.btn")}
          </Button>
          <Box sx={style.decor} className="not-found__decor">
            <Box component="img" src="/images/pages/not-found/hero-icon.svg" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
