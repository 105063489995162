export const style = {
  languages: {
    position: "relative",
    ".languages__label, .languages__item": {
      fontFamily: `"SF Pro", sans-serif`,
      fontWeight: "500",
      fontSize: "1.6rem",
      lineHeight: "2.7rem",
      cursor: "pointer"
    },
    "&.hovered": {
      ".languages__list": {
        opacity: "1",
        visibility: "visible",
        transform: "translate(-50%, 0)"
      },

      ".languages__label": {
        "&::after": {
          transform: "rotate(180deg)"
        }
      }
    }
  },

  label: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    userSelect: "none",
    margin: "7.5px 0",

    svg: {
      marginTop: "0.3rem",
      marginLeft: "0.6rem",
      width: "1rem",
      height: "0.5rem"
    },

    "&::after": {
      content: `""`,
      width: "1rem",
      height: "0.5rem",
      transition: "transform var(--transition)",
      pointerEvents: "none"
    }
  },

  list: {
    position: "absolute",
    left: "50%",
    borderRadius: "1.2rem",
    paddingTop: "0.9rem",
    paddingBottom: "0.9rem",
    backgroundColor: "var(--clr-default-100)",
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 0.5s, visibility 0.5s, transform 0.5s"
  },

  item: {
    color: "var(--clr-text-300)",
    "&:hover": {
      color: "var(--clr-text-900)"
    },

    "& > a": {
      padding: "0.9rem 1.8rem"
    },

    active: {
      color: "var(--clr-text-900)"
    }
  },

  positionTop: {
    ".languages__label": {
      "&::after": {
        marginLeft: "0.6rem",
        backgroundImage: `url("/images/ui/caret-up.svg")`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
      }
    },

    ".languages__list": {
      bottom: "150%",
      boxShadow: "0.5rem -0.5rem 3rem rgba(2, 40, 136, 0.1)",
      transform: "translate(-50%, -1rem)"
    }
  },

  positionBottom: {
    ".languages__label": {
      "&::after": {
        marginTop: "0.3rem",
        marginLeft: "0.6rem",
        backgroundImage: `url("/images/ui/caret-down.svg")`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
      }
    },

    ".languages__list": {
      top: "100%",
      boxShadow: "0 2rem 4rem rgba(2, 40, 136, 0.2)",
      transform: "translate(-50%, 1rem)"
    }
  }
};
