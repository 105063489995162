import { Box } from "@mui/material";
import { useRef, useState } from "react";

import { DownIcon } from "../../../../components/icons/index";

import { useOnClickOutside } from "../../../../hooks/use-on-click-outside";
// import "./pagination.scss";
import { style } from "./pagination.style";

const OPTIONS = ["10", "20", "50", "100"];

// eslint-disable-next-line no-unused-vars
export const PaginationDropdown = ({ pageSize, handler }) => {
  const options = [...OPTIONS]; // totalElements is the last element

  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(pageSize.toString());

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const handleOpen = () => setIsOpen((prev) => !prev);

  const handleSelect = (key) => {
    setIsOpen(false);
    setActiveOption(key);
    if (handler) handler(key);
  };

  const currentTab = options.find((option) => option === activeOption) || options[0];

  return (
    <Box
      ref={dropdownRef}
      className={
        !isOpen
          ? "table-pagination__dropdown"
          : "table-pagination__dropdown table-pagination__dropdown_active"
      }
      sx={!isOpen ? style.paginationDropdown : [style.paginationDropdown, style.paginationDropdown.active]}
    >
      <Box component="button" sx={style.paginationDropdown.current} className="table-pagination__dropdown-current" onClick={handleOpen}>
        <Box sx={style.paginationDropdown.current.label} className="table-pagination__dropdown-current-label">{currentTab}</Box>
        <DownIcon />
      </Box>
      <Box sx={style.paginationDropdown.body} className="table-pagination__dropdown-body">
        <Box sx={style.paginationDropdown.list} className="table-pagination__dropdown-list">
          {options.map((option) => (
            <Box
              component="button"
              key={option}
              className={
                option !== activeOption
                  ? "table-pagination__dropdown-item"
                  : "table-pagination__dropdown-item table-pagination__dropdown-item_active"
              }
              sx={
                option !== activeOption
                  ? style.paginationDropdown.item
                  : [style.paginationDropdown.item, style.paginationDropdown.item.active]
              }
              onClick={() => handleSelect(option)}
              aria-label={option}
              title={option}
            >
              {option}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
