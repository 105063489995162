import * as yup from "yup";
import { PASSWORD_REG } from "../constants";
// import { PASSWORD_MIN } from "../constants";

export const LoginSchema = (t) =>
  yup.object().shape({
    loginEmail: yup.string()
      .required(t.rules.email.required)
      .email(t.rules.email.invalid),
    loginPassword: yup.string()
      .required(t.rules.password.required)
      .matches(PASSWORD_REG, t.rules.password.passwordContainsSpaces)
      // .min(PASSWORD_MIN, `${t.rules.password.min} ${PASSWORD_MIN}`)
  });
