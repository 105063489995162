import { Box } from "@mui/material";
import { style } from "../header.style";
import { BuyProxy } from "./buy-proxy/buy-proxy";
import { Company } from "./company/company";
import { Price } from "./price/price";
// import { ProxyFor } from "./proxy-for/proxy-for";

const HeaderMenu = () => (
  <Box sx={style.menu} component="nav" className="header__menu">
    <Box sx={style.menu.list} className="header__menu-list">
      <BuyProxy />
      {/* <ProxyFor /> */}
      <Price />
      <Company />
    </Box>
  </Box>
);

export default HeaderMenu;
