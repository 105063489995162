
export const EmailIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8418 3.57617L12.3828 9.00004L17.8418 14.4239C17.9405 14.2176 18.0004 13.9896 18.0004 13.7461V4.25395C18.0004 4.01042 17.9405 3.78243 17.8418 3.57617Z" />
    <path
      d="M16.42 2.67188H1.58403C1.3405 2.67188 1.11251 2.73175 0.90625 2.83043L7.88346 9.7725C8.50038 10.3894 9.5036 10.3894 10.1205 9.7725L17.0977 2.83043C16.8915 2.73175 16.6635 2.67188 16.42 2.67188Z" />
    <path
      d="M0.158555 3.57617C0.0598711 3.78243 0 4.01042 0 4.25395V13.7461C0 13.9897 0.0598711 14.2177 0.158555 14.4239L5.61758 9.00004L0.158555 3.57617Z" />
    <path
      d="M11.6387 9.74561L10.8662 10.5181C9.83829 11.546 8.16566 11.546 7.13773 10.5181L6.36528 9.74561L0.90625 15.1695C1.11251 15.2682 1.3405 15.328 1.58403 15.328H16.42C16.6635 15.328 16.8915 15.2682 17.0977 15.1695L11.6387 9.74561Z" />
  </svg>
);
