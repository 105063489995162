import { appTheme } from "../../../../theme";

export const style = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    

    [appTheme.breakpoints.down('bp576')]: {
      display: 'none'
    }
  },
  ip: {
    container: {
      display: "inline-flex",
      flexDirection: "column",

    },
    content: {
      paddingLeft: "2.8rem"
    }
  }
};
