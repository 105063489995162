import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Container } from "../../../../components/common/container/container";
import HeadingWithSeo from "../../../../components/common/heading-with-seo/heading-with-seo";
import ThanksDecor from "../../../../components/common/thanks-decor/thanks-decor";
import { Button } from "../../../../components/ui/button/button";
import { Heading } from "../../../../components/ui/heading/heading";
import { useLang } from "../../../../hooks";

import { style } from "./hero.style";

export const Hero = () => {
  // Translations
  const { t } = useTranslation();
  const isMobileBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("bp576"));
  const lang = useLang();

  return (
    <Box sx={style.thanksHero} className="thanks-hero">
      <Container>
        <Box sx={style.wrapper} className="thanks-hero__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h1">
            {t("pages.app.thanks.hero.heading")}
          </HeadingWithSeo>
          <Heading tag="div" variant="h4">
            {t("pages.app.thanks.hero.subHeading")}
          </Heading>

          <Box sx={style.text} className="thanks-hero__text">
            {t("pages.app.thanks.hero.info")}
          </Box>
          <Box sx={style.btnBox}>
            <Button
              type="link"
              to={`/${lang}/cabinet/profile`}
              variant="primary"
              ariaLabel="Личный кабинет"
            >
              {t("shared.cabinet")}
            </Button>
          </Box>

          {!isMobileBreakpoint && (
            <Box
              sx={style.text}
              className="thanks-hero__text thanks-hero__text_description"
              dangerouslySetInnerHTML={{ __html: t("pages.app.thanks.hero.description") }}
            />
          )}
          <Box sx={style.decor} className="thanks-hero__decor">
            <ThanksDecor />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export const ReplenishmentHero = () => {
  // Translations
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const amount = searchParams.get("amount");
  const result = searchParams.get("result");

  const lang = useLang();

  return (
    <Box sx={style.thanksHero} className="thanks-hero">
      <Container>
        <Box sx={style.wrapper} className="thanks-hero__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h1">
            {t(`pages.app.thanks.replenishmentHero.heading.${result}`)}
          </HeadingWithSeo>
          <Heading tag="div" variant="h4">
            {t("pages.app.thanks.replenishmentHero.subHeading").replace(
              "{amount}",
              `${amount || 0}$`
            )}
          </Heading>

          <Box sx={style.text} className="thanks-hero__text">
            {t(`pages.app.thanks.replenishmentHero.info.${result}`)}
          </Box>
          <Box sx={style.btnBox}>
            <Button
              type="link"
              to={`/${lang}/cabinet/profile`}
              variant="primary"
              ariaLabel="Личный кабинет"
            >
              {t("shared.cabinet")}
            </Button>
          </Box>
          <Box
            sx={[style.text, style.text.description]}
            className="thanks-hero__text thanks-hero__text_description"
            dangerouslySetInnerHTML={{
              __html: t("pages.app.thanks.replenishmentHero.description")
            }}
          />
          <Box sx={style.decor} className="thanks-hero__decor">
            <ThanksDecor />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
