import { $api } from "./axios.service";

export class IpCommentService {
  static addComment(creds) {
    return $api.post("/api/front/cabinet/orders/ipAddress/comment", creds);
  };

  static getComment(ipAddressId) {
    return $api.get(`/api/front/cabinet/orders/ipAddress/comment`, { params: { ipAddressId } });
  }

  static deleteComment(ipAddressId) {
    return $api.delete(`/api/front/cabinet/orders/ipAddress/comment`, { params: { ipAddressId } });
  }
}
