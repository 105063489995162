import React, { useRef } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Form } from "../../../../components/form";
import { Button } from "../../../../components/ui";
// import { useTranslation } from "../../../../hooks";
import { UserService } from "../../../../services";
import { getCaptchaTokenFromFormData } from "../../../../utils/helpers";
import { PasswordChangeSchema } from "../../../../utils/validation/password-change.validation";
import { style } from "../profile.style";

const PasswordField = ({ name, label }) => (
  <Form.Input
    name={name}
    label={label}
    labelType="outer"
    variant="outlined"
    type="password"
    actions
    autoComplete="new-password"
  />
);
const ProfileChangePasswordForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const captchaRef = useRef(null);

  const handleSubmit = async (data, reset) => {
    try {
      const res = await UserService.changePassword(
        {
          oldPassword: data.passwordChangeOldPassword,
          newPassword: data.passwordChangeNewPassword
        },
        getCaptchaTokenFromFormData(data)
      );

      if (res.status === 200) {
        reset();
        enqueueSnackbar(t("passwordChangeSuccess"), { variant: "success" });
      }
    } catch (error) {
      let message = t("serverError");

      if (error?.response?.status === 409) {
        captchaRef?.current?.reset();
        message = t("messages.captchaFailed");
      } else if (error?.response?.data === "old password not matches") {
        message = t("form.serverErrors.incorrectOldPassword");
      } else if (
        error?.response?.data?.includes("New password") &&
        error?.response?.data?.includes("not valid")
      ) {
        message = t("form.serverErrors.incorrectNewPassword");
      } else if (error?.response?.data === "New password equals password")
        message = t("form.serverErrors.passwordEquals");
      // eslint-disable-next-line no-console
      console.log(error);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      captchaRef?.current?.reset();
    }
  };

  return (
    <>
      <Form
        sx={style.form}
        className="profile__form"
        schema={PasswordChangeSchema(t("form"))}
        onSubmit={handleSubmit}
      >
        <PasswordField name="passwordChangeOldPassword" label={t("form.labels.oldPassword")} />
        <PasswordField name="passwordChangeNewPassword" label={t("form.labels.newPassword")} />
        <PasswordField
          name="passwordChangeConfirmNewPassword"
          label={t("form.labels.confirmNewPassword")}
        />
        <Form.Captcha ref={captchaRef} id="change-password-captcha" />
        <Button type="submit" variant="primary" fullwidth>
          {t("form.actions.changePassword")}
        </Button>
      </Form>
    </>
  );
};

export default ProfileChangePasswordForm;
