import { useEffect } from "react";

import { useLoaderData } from "react-router-dom";

import { useActions } from "./use-actions";

export const useSetSeoPage = () => {
  const loaderData = useLoaderData();

  const { setSeoPageParams } = useActions();

  useEffect(() => {
    if (loaderData?.seoPageParams) {
      setSeoPageParams(loaderData.seoPageParams);
    }
  }, [loaderData]);

  return null;
};
