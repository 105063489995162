import { Box } from "@mui/material";
import { Button } from "../../../components/ui/button/button";
import { style } from "./subscription.style";

export const SubsciptionActions = ({ visible, saveHandler, cancelHandler }) => {
  if (visible) return (
    <Box sx={style.actions}>
      <Box sx={style.actions.saveBtn}>
        <Button variant="primary" fullwidth onClick={saveHandler}>Save</Button>
      </Box>
      <Box sx={style.actions.cancelBtn}>
        <Button fullwidth onClick={cancelHandler}>Cancel</Button>
      </Box>
    </Box>
  );
  else return <></>;
};
