
const contactsLinks = {
  skype: "skype:youproxy?chat",
  telegram: "https://t.me/youproxyio",
  vk: "https://vk.com/",
  telegramChannel: "https://t.me/youproxychannel",
  facebook: "https://www.facebook.com/messages/t/113000070099472/",
  facebookGroup: "https://www.facebook.com/official.youproxy/",
  mail: "mailto:support@youproxy.io"
}

export const MAIN_ADDRESS = "Perkūnkiemio g. 13-91, Vilnius, Lithuania, LT-12114";

export const MAIN_CONTACTS = [
  {
    key: "mail",
    translation: "mail",
    icon: "EmailIcon",
    link: contactsLinks.mail
  },
  {
    key: "telegram",
    translation: "telegram",
    icon: "TelegramIcon",
    link: contactsLinks.telegram
  },
  {
    key: "facebook",
    translation: "facebook",
    icon: "FacebookIcon",
    link: contactsLinks.facebook
  }
];

export const MAIN_SOCIALS = [
  {
    key: "telegramChannel",
    translation: "telegramChannel",
    icon: "TelegramIcon",
    link: contactsLinks.telegramChannel
  },
  {
    key: "facebook",
    translation: "facebookGroup",
    icon: "FacebookIcon",
    link: contactsLinks.facebookGroup
  },
];
