import { $api } from "./axios.service";

export class ExtendService {
  static priceCalculateExtend(creds) {
    return $api.post("/api/front/priceCalculate/extend", creds);
  }

  static orderExtend(creds) {
    return $api.post("/api/front/order/extend", creds);
  }
  static setAutoExtendIp(creds) {
    return $api.put("/api/front/ipAddress/autoExtend", creds);
  }
  static replaceIpAddress(creds) {
    return $api.post("/api/front/ipAddress/replace", creds);
  }
  static getAutoExtendedList() {
    return $api.get("/api/front/cabinet/autoExtend/data");
  }
  static getDataAuthentication() {
    return $api.get("/api/front/proxyAuthentication/all");
  }
  static changeDataAuthentication(creds) {
    return $api.put("/api/front/proxyAuthentication", creds);
  }
}
