import { appTheme } from "../../../theme";
import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  wrapper: {
    paddingTop: fluid(7, 4),
    paddingBottom: fluid(7, 4),
    "& .heading": {
      paddingBottom: "4rem"
    },


  },
  list: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "2.4rem",

    [appTheme.breakpoints.down('bp767')]: {
      gridTemplateColumns: "1fr",
    }
  }
}
