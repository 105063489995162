import { appTheme } from "../../../../theme";

export const style = {
  skeleton: {
    display: "flex",
    alignItems: "flex-start",
    [appTheme.breakpoints.down("bp992")]: { flexDirection: "column" },

    "& .skeletonTabs": {
      width: "min(100%, 25rem)",
      padding: "2rem 1.5rem 2rem 2rem",
      flexShrink: "0",
      [appTheme.breakpoints.down("bp992")]: { padding: "unset" },
      "&__item": {
        width: "100%",
        height: "4rem",
        borderRadius: "3rem",
        [appTheme.breakpoints.down("bp992")]: { height: "4.4rem", marginBottom: "2rem" }
      }
    },

    "& .skeletonContent": {
      paddingTop: "2.8rem",
      paddingBottom: "2.8rem",
      paddingLeft: "4.6rem",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [appTheme.breakpoints.down("bp992")]: { padding: "unset" }
    }
  },

  cabinetSkeleton: {
    "& .skeletonTabs": {
      width: "100%",
      marginBottom: "3rem",
      padding: "1rem",
      [appTheme.breakpoints.down("bp992")]: { marginBottom: "2rem", padding: "0" },
      "&__item": {
        width: "100%",
        height: "3.2rem",
        borderRadius: "3rem",
        [appTheme.breakpoints.down("bp992")]: { height: "4.4rem" }
      }
    }
  },

  faqEntity: {
    display: "flex",
    flexDirection: "column",
    question: {
      marginTop: "1.5rem",
      // marginRight: "4rem",
      marginBottom: "1.5rem",
      height: "2.3rem"
      // width: "80%"
    },

    answer: {
      "& .string": {
        width: "60%",
        height: "1.6rem",
        "&:first-of-type": {
          width: "100%",
          marginBottom: "1.3rem"
        }
      }
    }
  }
};
