import { createContext, useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { useLang } from "../../../hooks";
import { getIsToolsActive } from "../../../store/tools/tools.selectors";
import { COMPANY_ROUTES, ROUTE, TOOLS_ROUTES } from "../../../utils/constants";
import { getCompanyMenuLink } from "../../../utils/helpers";
import { Button } from "../../ui/button/button";
import { CabinetButton } from "../cabinet-button/cabinet-button";
import DynamicIcon from "../dynamic-icon/dynamic-icon";
import Languages from "../languages/languages";

import { BuyProxy } from "./menus/buy-proxy/buy-proxy";
import { MobileMenuItem } from "./mobile-menu-item";
import { MobileMenuMain } from "./mobile-menu-main";
import { MobileMenuSection } from "./mobile-menu-section";
import { MobileMenuTransition } from "./mobile-menu-transition";
// import "./mobile-menu.scss";
import { style } from "./mobileMenu.style";

export const MobileMenuContext = createContext();

const MobileMenu = () => {
  const isToolsActive = useSelector(getIsToolsActive);

  const lang = useLang();

  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [depth, setDepth] = useState(["main"]);
  const [isNext, setIsNext] = useState(true);
  const [activeProxyType, setActiveProxyType] = useState("ipv4");

  const { t } = useTranslation();

  useEffect(() => {
    const originalOverflow = window.getComputedStyle(document.body).overflow;

    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  const handleOpen = () => setIsOpen((prev) => !prev);

  const handleGoBack = () => {
    const newDepth = [...depth];
    newDepth.pop();
    setDepth(newDepth);
    const backMenu = newDepth[newDepth?.length - 1] || "main";
    setActiveMenu(backMenu);
    setIsNext(false);
  };

  const cabinetHandler = () => {
    handleOpen();
  };

  // console.count("MobileMenu");

  return (
    <MobileMenuContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        isOpen,
        handleOpen,
        depth,
        setDepth,
        setIsNext,
        isNext,
        activeProxyType,
        setActiveProxyType
      }}
    >
      <Box sx={style.mobileMenu} className="mobile-menu">
        <Box
          sx={!isOpen ? style.button : [style.button, style.button.open]}
          className={
            !isOpen ? "mobile-menu__button" : "mobile-menu__button mobile-menu__button_open"
          }
          onClick={handleOpen}
        >
          <Box sx={style.button.burger} className="mobile-menu__button-burger">
            <span></span>
            <span></span>
            <span></span>
          </Box>
          <Box sx={style.button.cross} className="mobile-menu__button-cross">
            <span></span>
            <span></span>
          </Box>
        </Box>

        <CSSTransition
          in={isOpen}
          timeout={500}
          classNames="mobile-menu__content_anim_open"
          unmountOnExit
        >
          <Box sx={style.content} className="mobile-menu__content">
            <Box sx={style.body} className="mobile-menu__body">
              <MobileMenuTransition menuKey="main">
                <MobileMenuMain>
                  <MobileMenuSection title={t("menu.buyProxy")}>
                    <MobileMenuItem type="tab" to="buy-proxy" label="IPv4" />
                    <MobileMenuItem type="tab" to="buy-proxy" label="IPv6" />
                    <MobileMenuItem type="tab" to="buy-proxy" label="ISP" />
                    <MobileMenuItem
                      type="link"
                      to={`/${lang}${ROUTE.APP.MOBILE}/`}
                      label={t("buyProxy.mobile")}
                    />
                  </MobileMenuSection>

                  {isToolsActive ? (
                    <MobileMenuSection title={t("menu.instruments")}>
                      {TOOLS_ROUTES.routes.map((route) => (
                        <MobileMenuItem
                          key={route.key}
                          type="link"
                          to={`/${lang}${ROUTE.TOOLS.ROOT}/${route.key}/`}
                          label={t(`tools.${route.translation}.label`)}
                          icon={<DynamicIcon iconName={route.icon} />}
                        />
                      ))}
                    </MobileMenuSection>
                  ) : null}

                  {COMPANY_ROUTES.map((item, idx) => (
                    <MobileMenuSection
                      key={`${idx}:${item.translation}`}
                      title={t(`company.titles.${item.translation}`)}
                    >
                      {item.routes.map((route) => (
                        <MobileMenuItem
                          key={route.translation}
                          type="link"
                          to={getCompanyMenuLink(route, lang)}
                          label={t(`company.links.${route.translation}`)}
                        />
                      ))}
                    </MobileMenuSection>
                  ))}
                </MobileMenuMain>
              </MobileMenuTransition>
              <MobileMenuTransition menuKey="buy-proxy">
                <BuyProxy />
              </MobileMenuTransition>
            </Box>
            <Box sx={style.footer} className="mobile-menu__footer">
              <CSSTransition
                in={activeMenu !== "main"}
                classNames="mobile-menu__footer_anim_in"
                timeout={90}
                unmountOnExit
              >
                <Button onClick={handleGoBack}>
                  <Box sx={style.back} className="mobile-menu__back">
                    {t("common.goBack")}
                  </Box>
                </Button>
              </CSSTransition>

              <CSSTransition
                in={activeMenu === "main"}
                classNames="mobile-menu__footer_anim_out"
                timeout={90}
                unmountOnExit
              >
                <Box sx={style.right} className="mobile-menu__right">
                  <CabinetButton onClick={cabinetHandler} size="small" />
                  <Languages id={"languages-mobile-menu"} position="top" />
                </Box>
              </CSSTransition>
            </Box>
          </Box>
        </CSSTransition>
      </Box>
    </MobileMenuContext.Provider>
  );
};

export default MobileMenu;
