import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { MAIN_ADDRESS } from "../../../utils/constants";

import { style } from "./address.style";

export const Address = () => {
  // Translations
  const { t } = useTranslation();

  return (
    <Box className="address">
      <Box sx={style.title} className="address__title">
        {t("shared.address.title")}
      </Box>
      <Box sx={style.content} className="address__content">
        {MAIN_ADDRESS}
      </Box>
    </Box>
  );
};
