/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { Box, Link } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

import { CustomCheckbox } from "../../../components/common/checkbox/checkbox";
import FormCaptcha from "../../../components/common/form-captcha/form-captcha";
import { PromocodeSection } from "../../../components/common/promocode-section/promocode-section";
import { SelectList } from "../../../components/common/select-list/select-list";
import { SelectWithBalance } from "../../../components/common/select-with-balance/select-with-balance";
import { TextInput } from "../../../components/common/text-input/text-input";
import { Button } from "../../../components/ui/button/button";
import { Heading } from "../../../components/ui/heading/heading";
import { Tabs } from "../../../components/ui/tabs/tabs";
import { useActions, useLang, useSetSeoPage, useTranslateOptions } from "../../../hooks";
import useOrder from "../../../hooks/use-order";
import { OrdersService } from "../../../services";
import {
  getFingerprintState,
  getMobileState,
  getPaymentState,
  getProxyState,
  getRentState,
  getUserState
} from "../../../store/selectors";
import { AUTH_METHODS_OPTIONS, IP_ORDER_OPTIONS } from "../../../utils/constants";
import {
  getCountriesOptions,
  getIndexFromOptions,
  getOperatorOptions,
  getOperatorRotations,
  getPeriodsOptions,
  getPriceCalculationType,
  getProxyTypeUpper,
  getRotationsOptions,
  makeOrder
} from "../../../utils/helpers/order.helpers";
import { orderProcessing } from "../../../utils/helpers/orderProcessing.helpers";

import { style } from "./newOrder.style";

const NewOrder = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const captchaRef = useRef(null);

  const order = useOrder();
  const { t } = useTranslation();
  const lang = useLang();
  const [searchParams] = useSearchParams();
  //*Redux
  const { fetchMobile } = useActions();
  const { countries } = useSelector(getMobileState);

  const { proxies, proxyOptions, proxyTypeIds } = useSelector(getProxyState);
  const { periods } = useSelector(getRentState);
  const { systemsOptions } = useSelector(getPaymentState);
  const { user } = useSelector(getUserState);
  const { data } = useSelector(getFingerprintState);
  //select state
  const [disableEmail, setDisableEmail] = useState(false);
  const [hasPromo, setHasPromo] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [submittable, setSubmittable] = useState(true);
  const [errors, setErrors] = useState({});

  const [searchOptions, setSearchOptions] = useState([]);
  const [customGoalRequest, setCustomGoalRequest] = useState(null);
  const [mobileOperators, setMobileOperators] = useState([]);

  const [trigeredField, setTrigeredField] = useState(null);

  const [cfToken, setCfToken] = useState("");

  const tabProxyType = searchParams.get("newOrderProxy");
  const proxyTabs = useTranslateOptions(
    proxyOptions.map((el) => ({ ...el, key: el.key.toLowerCase() })),
    t("buyProxy")
  );
  // select options
  const countryOptions = getCountriesOptions(order.data.proxyType, proxies, lang);
  const rentOptions = getPeriodsOptions(order.data.proxyType, periods, lang) || [];
  const systemOptionsForUser = systemsOptions
    ? systemsOptions[lang].filter((opt) => opt.showForUser)
    : [];
  const authTypeOptions = useTranslateOptions(AUTH_METHODS_OPTIONS, t("authMethods"));

  const protocolOptions = useTranslateOptions(IP_ORDER_OPTIONS, t("ipType"));
  const mobileOperatorOptions = getOperatorOptions(mobileOperators);

  const authTypeIndex = getIndexFromOptions(authTypeOptions, "key", order.data.proxyAuth, null);
  const mobileOperatorIndex = getIndexFromOptions(
    mobileOperatorOptions,
    "id",
    order.data.mobileOperatorId,
    null
  );
  const protocolIndex = getIndexFromOptions(protocolOptions, "key", order.data.proxyProtocol, null);
  const countryIndex = getIndexFromOptions(countryOptions, "key", order.data.proxyCountry, null);
  const rentalPeriodIndex = getIndexFromOptions(
    rentOptions,
    "key",
    order.data.proxyRentalPeriod,
    null
  );
  const paymentTypeIndex = getIndexFromOptions(
    systemOptionsForUser,
    "key",
    order.data.proxyPayment,
    null
  );

  const operatorRotations = getOperatorRotations(mobileOperators[mobileOperatorIndex]);
  const operatorRotationsOptions = getRotationsOptions(operatorRotations, t);
  const modemRotationMinIndex = getIndexFromOptions(
    operatorRotationsOptions,
    "value",
    order.data.modemRotationMin,
    null
  );

  const setOrder = (payload) => {
    order.setData((prev) => ({ ...prev, ...payload }));
  };
  const resetFormAll = () => {
    if (!proxyTypeIds.length) {
      return;
    }

    const foundProxyType = tabProxyType
      ? tabProxyType.toUpperCase()
      : proxyTypeIds[0].name.toUpperCase();

    order.clearData();
    setOrder({
      proxyTypeId: proxyTypeIds.find((el) => el.name === getProxyTypeUpper(foundProxyType)).id,
      proxyType: foundProxyType,
      proxyMail: user?.email ? user?.email : ""
    });
    setDisableEmail(!!user?.email);
    setMobileOperators([]);
    setErrors({});
    setHasPromo(false);
    setAgreement(false);
  };
  const errorFunction = ({ resetCaptcha }) => {
    let message = t("orderCreateError");
    if (resetCaptcha) {
      captchaRef.current?.reset();
      message = t("messages.captchaFailed");
    }
    enqueueSnackbar(message, { variant: "error" });
    // resetFormState();
    // getPaymentOptions({ email: user?.email, fingerprint: data?.visitorId || "" });
  };
  // Reset order if tab changed
  useEffect(() => {
    resetFormAll();
  }, [tabProxyType, proxyTypeIds]);
  // Set user email
  useEffect(() => {
    setOrder({ proxyMail: user?.email ? user?.email : "" });
    setDisableEmail(!!user?.email);
  }, [user?.email]);
  // Fetch custom goal options
  useEffect(() => {
    const dispatchGoal = setTimeout(() => {
      if (customGoalRequest && order.data.proxyCustomGoal) {
        OrdersService.getOrderGoals(
          encodeURIComponent(order.data.proxyCustomGoal).replace(/%20/g, "+")
        ).then((res) => {
          if (res.data) setSearchOptions(res.data);
          else setSearchOptions([]);
        });
      }
    }, 200);

    return () => clearTimeout(dispatchGoal);
  }, [order.data.proxyCustomGoal, customGoalRequest]);
  // Fetch mobile content
  useEffect(() => {
    if (order.data.proxyType === "MOBILE" && !countries.length) {
      fetchMobile();
    }
  }, [order.data.proxyType]);
  // Set setMobileOperators
  useEffect(() => {
    if (order.data.proxyType === "MOBILE" && order.data.proxyCountry) {
      const operators = countries.find((e) => e.id === order.data.proxyCountry)?.mobileOperators;
      setMobileOperators(operators ?? []);
    }
  }, [order.data.proxyCountry, order.data.proxyRentalPeriod, order.data.proxyType]);

  // Calculate price
  useEffect(() => {
    order.calculatePrice(getPriceCalculationType(order));
  }, [order.data]);

  const handlerClosePromocode = () => {
    setOrder({ promoCode: "" });
    setHasPromo(false);
  };
  const handlerChangeCountry = (option) => {
    setOrder({
      proxyCountry: option.key,
      mobileOperatorId: "",
      modemRotationMin: ""
    });
  };
  const handlerChangeCustomGoal = (e) => {
    if (!e.target.value) {
      order.clearPrice();
    }
    setOrder({ proxyCustomGoal: e.target.value });
    setCustomGoalRequest(e.target.toRequest);
  };
  const handlerChangeMobileOperator = (option) => {
    setOrder({
      mobileOperatorId: option.id,
      modemRotationMin: ""
    });
  };
  const handlerChangeModemRotationMin = (option) => {
    const modemRotationMin = option.value;

    setOrder({ modemRotationMin });
  };
  const handlerOpenMobileOperator = (setOpen) => {
    if (!order.data.proxyCountry) {
      setTrigeredField("country");
      setOpen(false);
    }
  };
  const handlerOpenRotationMin = (setOpen) => {
    if (!order.data.proxyCountry) {
      setTrigeredField("country");
      setOpen(false);
    } else if (!order.data.mobileOperatorId) {
      setTrigeredField("mobileOperator");
      setOpen(false);
    }
  };
  const handlerSubmit = async () => {
    const errors = order.validateData();
    if (errors.authIp) {
      enqueueSnackbar(t("shared.orderModal.ipValidationError"), {
        variant: "error"
      });
    }

    if (errors.customGoal.length > 0) {
      enqueueSnackbar(errors.customGoal, {
        variant: "error"
      });
    }

    errors.agreement = !agreement;

    setErrors(errors);

    if (!Object.values(errors).some(Boolean)) {
      setSubmittable(false);
      const resp = await makeOrder(
        order.data,
        order.price,
        lang,
        data?.visitorId,
        errorFunction,
        null,
        cfToken
      );
      if (resp) {
        orderProcessing(resp);
        resetFormAll();
      }

      setSubmittable(true);
      captchaRef.current?.reset();
    }
  };

  const priceForOneWithDiscount =
    order.price.priceForOneWithDiscountInMainCurrency || order.price.priceForOneWithDiscount || 0;
  const priceForOne = order.price.priceForOneInMainCurrency || order.price.priceForOne || 0;

  return (
    <Box sx={style.cabinetNewOrder} className="cabinet-new-order">
      <Box sx={style.header} className="cabinet-new-order__header">
        <Heading tag="h1" variant="h2">
          {t("pages.cabinet.newOrder.heading")}
        </Heading>
        <Tabs name="newOrderProxy" tabs={proxyTabs} />
      </Box>
      <Box sx={style.body} className="cabinet-new-order__body">
        <SelectList
          isOpen={trigeredField === "country"}
          onClose={() => {
            if (trigeredField === "country") {
              setTrigeredField(null);
            }
          }}
          name="country"
          label={t("form.labels.country")}
          placeholder={t("form.placeholders.firstSelectCountry")}
          isOuterLabel={true}
          options={countryOptions}
          selectedIndex={countryIndex}
          onChange={handlerChangeCountry}
          error={order.dataErrors["country"]}
          errorText={order.dataErrors["country"]}
          withIcon
        />

        {tabProxyType === "mobile" ? (
          <>
            <SelectList
              isOpen={trigeredField === "mobileOperator"}
              onClose={() => {
                if (trigeredField === "mobileOperator") {
                  setTrigeredField(null);
                }
              }}
              name="mobileOperator"
              label={t("form.labels.mobileOperator")}
              placeholder={t("form.placeholders.selectOperator")}
              isOuterLabel={true}
              options={mobileOperatorOptions}
              selectedIndex={mobileOperatorIndex}
              onChange={handlerChangeMobileOperator}
              onOpen={handlerOpenMobileOperator}
              error={order.dataErrors["mobileOperator"]}
              errorText={order.dataErrors["mobileOperator"]}
            />
            <SelectList
              name="modemRotationMin"
              label={t("form.labels.modemRotationMin")}
              placeholder={t("form.placeholders.selectRotation")}
              isOuterLabel={true}
              options={operatorRotationsOptions}
              onChange={handlerChangeModemRotationMin}
              onOpen={handlerOpenRotationMin}
              selectedIndex={modemRotationMinIndex}
              error={order.dataErrors["modemRotationMin"]}
              errorText={order.dataErrors["modemRotationMin"]}
            />
          </>
        ) : (
          <TextInput
            name="customGoal"
            label={t("form.labels.proxyGoal")}
            placeholder={t("form.placeholders.enterCustomGoal")}
            isOuterLabel={true}
            onChange={handlerChangeCustomGoal}
            value={order.data.proxyCustomGoal}
            error={order.dataErrors["customGoal"]}
            isSearchInput
            searchOptions={searchOptions}
          />
        )}
        <SelectList
          name="rentalPeriod"
          label={t("form.labels.rentalPeriod")}
          placeholder={t("form.placeholders.selectRentPeriod")}
          isOuterLabel={true}
          options={rentOptions}
          selectedIndex={rentalPeriodIndex}
          onChange={(option) => setOrder({ proxyRentalPeriod: option.key })}
          error={order.dataErrors["rentalPeriod"]}
        />
        <TextInput
          name="quantity"
          label={t("form.labels.proxyCount")}
          isOuterLabel={true}
          onlyNumber={true}
          onChange={(e) => setOrder({ proxyCount: e.target.value })}
          value={order.data.proxyCount}
          error={order.dataErrors["quantity"]}
          errorText={order.dataErrors["quantity"]}
          placeholder={`${order.minCount}`}
        />
        <SelectWithBalance
          name="paymentType"
          label={t("form.labels.paymentType")}
          placeholder={t("form.placeholders.selectPaymentMethod")}
          isOuterLabel={true}
          options={systemOptionsForUser}
          selectedIndex={paymentTypeIndex}
          onChange={(option) => setOrder({ proxyPayment: option.key })}
          error={order.dataErrors["paymentType"]}
          errorText={order.dataErrors["paymentType"]}
        >
          <SelectList />
        </SelectWithBalance>
        <SelectList
          name="authType"
          label={t("form.labels.authType")}
          isOuterLabel={true}
          options={authTypeOptions}
          selectedIndex={authTypeIndex}
          onChange={(option) => setOrder({ proxyAuth: option.key })}
          isTextInputKey={"ip"}
          cancelHandler={() =>
            setOrder({
              proxyAuth: "login",
              proxyIpAuth: ""
            })
          }
          textInputChangeHandler={(e) => setOrder({ proxyIpAuth: e.target.value })}
          textInputValue={order.data.proxyIpAuth}
          isIpInput={true}
          error={order.dataErrors["authType"] || order.dataErrors["authIp"]}
        />
        {tabProxyType === "ipv6" ? (
          <SelectList
            name="proxyProtocol"
            label={t("form.labels.proxyProtocol")}
            placeholder={t("form.placeholders.selectProtocol")}
            isOuterLabel={true}
            options={protocolOptions}
            selectedIndex={protocolIndex}
            onChange={(option) => setOrder({ proxyProtocol: option.key })}
            error={order.dataErrors["proxyProtocol"]}
          />
        ) : null}
        <TextInput
          name="email"
          label={t("form.labels.email")}
          isOuterLabel={true}
          value={order.data.proxyMail}
          onChange={(e) => setOrder({ proxyMail: e.target.value })}
          disabled={disableEmail}
          error={order.dataErrors["email"]}
        />
        {/* {hasPromo ? (
          <TextInput
            name="promoCode"
            label={t('form.labels.promocode}
            placeholder={t('form.placeholders.promocode}
            action={
              <Box className="circle-button" onClick={handlerClosePromocode}>
                <XMark />
              </Box>
            }
            isOuterLabel={true}
            value={order.data.promoCode}
            onChange={(e) => setOrder({ promoCode: e.target.value })}
            error={!order.price.validPromoCode}
            errorText={t('form.serverErrors.promocode}
          />
        ) : (
          <Box sx={style.promocode} onClick={() => setHasPromo(true)}>
            {t('shared.hasPromocode}
          </Box>
        )} */}
        <PromocodeSection
          onChange={(e) => setOrder({ promoCode: e.target.value })}
          promo={order.data.promoCode}
          hasPromo={hasPromo}
          onClick={() => setHasPromo(true)}
          onClose={handlerClosePromocode}
          promoLinkStyles={style.promocode}
          inputProps={{
            isOuterLabel: true,
            hasLabel: true,
            error: !order.price?.validPromoCode,
            errorText: order.dataWarnings.promoCode
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <CustomCheckbox
          label={`${t("common.iAgree")}`}
          changeHandler={(e) => setAgreement(e.target.checked)}
          checked={agreement}
          error={errors["agreement"]}
        />
        &nbsp;
        <Link
          component={RouterLink}
          to={`/${lang}/conditions/`}
          target="_blank"
          sx={{ fontWeight: 400 }}
        >
          {t("common.toTheTerms")}
        </Link>
      </Box>
      <Box sx={style.divider} className="cabinet-new-order__divider"></Box>
      <Box sx={style.footer} className="cabinet-new-order__footer">
        <Box>
          <Button variant="primary" fullwidth onClick={handlerSubmit} disabled={!submittable}>
            {t("form.actions.makeOrder")}
          </Button>
          <FormCaptcha
            ref={captchaRef}
            onSuccess={(token) => setCfToken(token)}
            sx={{ mt: "1rem" }}
          />
        </Box>

        <Box sx={style.footer.info} className="cabinet-new-order__footer-info">
          <Box sx={style.price} className="cabinet-new-order__price">
            ${order.price.totalPriceInMainCurrency || order.price.totalPrice || 0}
            <Box sx={style.price.discount} className="cabinet-new-order__price-discount">
              {t("other.discount")} <span>-{order.price.totalDiscountInPercent || 0}%</span>
            </Box>
          </Box>

          <Box sx={style.price.unit} className="cabinet-new-order__price-unit">
            {t("other.price")} 1 IP -{" "}
            {priceForOne && priceForOne !== priceForOneWithDiscount ? <s>${priceForOne}</s> : null}
            <span> ${priceForOneWithDiscount}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default NewOrder;
