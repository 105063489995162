export const OrderListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect
      x="4.16602"
      y="2.5"
      width="11.6667"
      height="15"
      rx="2"
      stroke="#517FF5"
      strokeWidth="1.5"
    />
    <path d="M7.5 6.66666H12.5" stroke="#517FF5" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M7.5 10H12.5" stroke="#517FF5" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M7.5 13.3333H10.8333" stroke="#517FF5" strokeWidth="1.5" strokeLinecap="square" />
  </svg>
);
