import { appTheme } from "../../theme";

export const style = {
  //select styles
  selectWrapper: {
    position: "relative",
    backgroundColor: "var(--clr-default-200)",
    boxShadow: "inset 0 0 2rem rgb(197 202 213 / 20%)",
    // transition: "background-color 0.3s",
    borderRadius: "2.2rem",
    display: "flex",
    flexDirection: "column",
    minHeight: "7.4rem",
    height: "100%",
    border: "1px solid transparent",
    // var(--transition)
    transition: "border-color var(--transition), background var(--transition)",
    [appTheme.breakpoints.down("bp576")]: {
      borderRadius: "1.4rem",
      minHeight: "6.6rem"
    },
    label: {
      // var(--clr-text-300)
      color: "#75808F",
      // paddingBottom: "1.2rem",
      fontSize: "1.6rem",
      fontWeight: "400",
      whiteSpace: "nowrap",
      transform: "unset",
      lineHeight: "2.7rem",
      cursor: "pointer",
      fontFamily: "inherit",
      padding: "1rem 2rem",
      paddingBottom: "0",
      position: "absolute",
      [appTheme.breakpoints.down("bp576")]: {
        fontSize: "1.4rem",
        lineHeight: "2.3rem"
      }
    },
    "&:hover": {
      backgroundColor: "#ebeff8",
      "& input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
      }
    },

    ".react-select": {
      "&__container": {
        flexGrow: 1,
        minHeight: "1.4375em",
        fontSize: "1.4rem",
        fontWeight: "600",
        fontFamily: "inherit",
        display: "flex",
        flexDirection: "column"
        // maxWidth: "17rem"
      },

      "&__control": {
        flexGrow: 1,
        padding: "0 2rem 1rem 2rem",
        minHeight: "7.4rem",
        alignItems: "flex-end",
        cursor: "pointer",
        [appTheme.breakpoints.down("bp576")]: {
          minHeight: "6.6rem"
        }
      },

      "&__menu": {
        borderRadius: "1.2rem",
        boxShadow: "0 2px 22px rgb(95 103 128 / 14%)",
        overflow: "hidden",
        "&-list": {
          maxHeight: "20rem"
        }
      },

      "&__option": {
        fontSize: "1.4rem",
        cursor: "pointer",
        // var(--clr-text-400)
        color: "#63637C",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        padding: "0.6rem 2rem",
        minHeight: "4.8rem",
        transition: "0.3s",
        backgroundColor: "#fff",

        "&:hover": {
          // var(--clr-default-300)
          backgroundColor: "#EEF2FC"
        },
        "&--is-selected": {
          // var(--clr-default-300)
          backgroundColor: "#DCE5FD",
          pointerEvents: "none",
          cursor: "default"
        }
      },

      "&__indicators": {
        alignItems: "center",
        height: "2.7rem",
        alignSelf: "unset",
        [appTheme.breakpoints.down("bp576")]: {
          height: "2.3rem"
        }
      },

      "&__single-value": {
        fontWeight: "500",
        color: "rgba(0, 0, 0, 0.87)",
        display: "flex",
        alignItems: "center",
        span: {
          overflow: "hidden",
          maxWidth: "100%",
          textOverflow: "ellipsis",
          display: "block",
          lineHeight: "2.7rem",
          [appTheme.breakpoints.down("bp576")]: {
            lineHeight: "2.3rem",
            fontSize: "1.2rem"
          }
        }
      },

      "&__indicator": {
        svg: {
          height: "2rem",
          width: "2rem",
          fill: "#89899D"
        }
      }
    },

    "&.open": {
      // var(--clr-primary-400)
      borderColor: "#507DF1",
      backgroundColor: "#ebeff8"
    }
  },

  dropItemImg: {
    width: "21px",
    minWidth: "21px",
    height: "21px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "10px",
    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  },

  error: {
    borderColor: "#FF0000"
  },

  inputError: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000"
    }
  },

  errorText: {
    position: "absolute",
    bottom: "0",
    transform: "translateY(100%)",
    left: "2rem",
    fontSize: "1.2rem",
    color: "#FF0000"
  },

  arrow: {
    position: "absolute",
    top: "unset",
    bottom: "20px",
    right: "20px",
    "&.custom-arrow": {
      animation: "rotateArrowReverse 0.3s forwards",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&.open": {
        animation: "rotateArrow 0.3s forwards"
      }
    }
  },

  heroForm: {
    form: {
      display: "inline-grid",
      alignItems: "center",
      gridGap: "2rem",
      borderRadius: "3.5rem",
      backgroundColor: "var(--clr-default-100)",
      padding: "1.6rem",
      "&.grid-5": { gridTemplateColumns: "repeat(5, 16.6rem)" },
      "&.grid-4": { gridTemplateColumns: "repeat(4, 16.6rem)" },
      "&.grid-3": { gridTemplateColumns: "repeat(3, 16.6rem)" },
      "& > .button": {
        height: "100%",
        borderRadius: "2.2rem"
        // [appTheme.breakpoints.down("bp992")]: {
        //   borderRadius: "3rem",
        //   height: "4.8rem"
        // }
      },
      [appTheme.breakpoints.down("bp576")]: {
        padding: "1.8rem",
        paddingBottom: "22px",
        gridGap: "1.8rem"
      }
    },

    [appTheme.breakpoints.down("bp992")]: {
      form: {
        "&.grid-5": {
          gridTemplateColumns: "repeat(4, 170px)",
          "& .button": {
            gridColumn: "2 / 4",
            borderRadius: "3rem",
            height: "4.8rem"
          }
        }
      }
    },

    [appTheme.breakpoints.down("bp767")]: {
      form: {
        "&.grid-5, &.grid-4": {
          gridTemplateColumns: "repeat(2, minmax(0, 170px))",

          "& .button": {
            gridColumn: "1 / 3",
            borderRadius: "3rem",
            height: "4.8rem"
          }
        }
      }
    },

    [appTheme.breakpoints.down("bp576")]: {
      form: {
        padding: "1.2rem",
        "&.grid-3": {
          gridTemplateColumns: "repeat(2, minmax(0, 170px))",
          ".button": {
            gridColumn: "1 / 3",
            borderRadius: "3rem",
            height: "4.8rem"
          }
        }
      }
    },

    [appTheme.breakpoints.down("bp370")]: {
      form: {
        width: "100%",
        "&.grid-5, &.grid-3, &.grid-4": {
          gridTemplateColumns: "1fr",

          "& .form-item": {
            gridColumn: "auto"
          },

          ".button": {
            gridColumn: "auto"
          }
        }
      }
    }
  },

  formInput: {
    backgroundColor: "var(--clr-default-200)",
    boxShadow: "inset 0 0 2rem rgb(197 202 213 / 20%)",
    borderRadius: "2.2rem",
    display: "flex",
    flexDirection: "column",
    minHeight: "7.4rem",
    transition: "background-color var(--transition)",
    position: "relative",
    cursor: "text",
    input: {
      lineHeight: "2.7rem",
      WebkitAppearance: "none",
      height: "2.7rem",
      padding: "0",
      margin: "0",
      transition: "var(--transition)",
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px var(--clr-default-200) inset"
      }
    },
    "&:hover": {
      backgroundColor: "#ebeff8",
      "& input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
      }
    },

    // 'svg': {
    //   transition: '0.3s',
    //   transform: 'rotate(0)'
    // },

    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "2.2rem",
      borderColor: "transparent",
      // transition: 'border-color var(--transition)',
      borderWidth: "1px !important",
      transition: "0.3s"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    ".Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "var(--clr-primary-400)"
    },
    "&:hover .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--clr-primary-400)"
    },
    // '.Mui-focused svg': {
    //   transform: 'rotate(180deg)'
    // },

    "&.Mui-focused": {
      backgroundColor: "#ebeff8"
    },

    [appTheme.breakpoints.down("bp576")]: {
      minHeight: "6.6rem",
      borderRadius: "1.4rem",
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "1.4rem"
      }
    }
  },

  focused: {
    backgroundColor: "#ebeff8",
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0px 1000px #ebeff8 inset"
    }
  },

  label: {
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "10px",
    color: "var(--clr-text-300)",
    // paddingBottom: '1.2rem',
    fontSize: "1.6rem",
    fontWeight: "400",
    whiteSpace: "nowrap",
    transform: "unset",
    position: "unset",
    lineHeight: "2.7rem",
    cursor: "pointer",
    fontFamily: "inherit",
    [appTheme.breakpoints.down("bp576")]: {
      lineHeight: "2.3rem",
      fontSize: "1.4rem"
    }
  },

  labelCursorText: {
    cursor: "text"
  },

  unsetPosition: {
    position: "unset",
    height: "100%",
    fontSize: "1.4rem",
    fontWeight: "600",
    fontFamily: "inherit",
    maxHeight: "100%",
    padding: "0",
    lineHeight: "2.7rem",
    [appTheme.breakpoints.down("bp576")]: {
      fontSize: "1.2rem"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },

    "& input": {
      padding: 0,
      transition: "none"
    },

    "& textarea": {
      minWidth: "100%",
      wordWrap: "break-word"
    }
  },

  textArea: {
    width: "100%"
  },

  inputFormControl: {
    position: "unset",
    display: "flex",
    flexDirection: "row",
    paddingTop: "0",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    "& > span": {
      // width: '100%'
      fontSize: "1.4rem",
      fontWeight: "600",
      fontFamily: "inherit",
      color: "rgba(0, 0, 0, 0.87)",
      lineHeight: "2.7rem"
    },
    [appTheme.breakpoints.down("bp576")]: {
      height: "3.3rem",
      lineHeight: "3.3rem"
    }
  },

  input: {
    paddingTop: "0",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingBottom: "15px",
    lineHeight: "2.7rem"
    // width: 'min-content'
  },

  tooltip: {
    fontSize: "24px"
  }
};
