const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

export const checkWebRtcEnabled = () => {
  return typeof RTCPeerConnection !== "undefined"
}
export const getWebRtcLeakIpList = () => {
  return new Promise((resolve, reject) => {
    const rtcConfig = { iceServers: [{ urls: "stun:stun.l.google.com:19302" }] };

    const pc = new RTCPeerConnection(rtcConfig);
    pc.createDataChannel('');
    pc.createOffer()
      .then(offer => pc.setLocalDescription(offer))
      .catch(reject);

    pc.onicecandidate = (ice) => {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;

      resolve(ice.candidate.candidate.match(ipRegex).filter(e => e !== "0.0.0.0"));
    };
  });
}

export const getWebProxyLeak = (window, host) => {
  let proxyType = null;
  const testElement = window.document.createElement("a");
  testElement.href = host;
  const elementHostName = testElement.hostname;

  if(window._proxy_jslib_SCRIPT_URL) {
    proxyType = "CGIProxy"
  } else if (window.REAL_PROXY_HOST) {
    proxyType = "Cohula"
  } else if ("undefined" != typeof ginf) {
    proxyType = "Glype"
  }

  return {
    status: window.location.hostname !== elementHostName,
    type: proxyType,
    hostName: window.location.hostname
  };
}

export const detectPlugin = {
  flash: () => typeof navigator?.plugins !== "undefined" && typeof navigator?.plugins["Shockwave Flash"] === "object",
  java: () => navigator?.javaEnabled(),
  activeX: () => typeof window?.ActiveXObject !== "undefined",
  vBScript: () => typeof window.ActiveXObject !== "undefined"
}
