import { ApiService } from "../../services/api.service";
import { CALC_TYPES, PROMO_CODE_ERROR } from "../constants";

export const getPriceCalculationType = (order) => {
  const incorrectMainFields = !order.data.proxyPayment || !order.data.proxyMail;
  let calcType;

  if (
    (order.data.proxyType === "IPV6" &&
      (incorrectMainFields || !order.data.proxyCustomGoal || !order.data.proxyProtocol)) ||
    (order.data.proxyType === "IPV4" && (incorrectMainFields || !order.data.proxyCustomGoal)) ||
    (order.data.proxyType === "ISP" && (incorrectMainFields || !order.data.proxyCustomGoal)) ||
    (order.data.proxyType === "MOBILE" && !order.data.mobileOperatorId)
  ) {
    calcType = CALC_TYPES.BASIC;
  } else if (
    order.data.proxyType === "MOBILE" &&
    (incorrectMainFields || order.data.modemRotationMin === "")
  ) {
    calcType = CALC_TYPES.MOBILE_BASIC;
  } else {
    calcType = CALC_TYPES.FULL;
  }

  // console.log(calcType);
  return calcType;
};
export const getIndexFromOptions = (array, key, value, defaultValue = 0) => {
  // console.log("getIndexFromOptions call");
  const indexFound = array.findIndex((e) => e[key] === value);
  return indexFound !== -1 ? indexFound : defaultValue;
};
export const getCountriesOptions = (proxyType = "IPV4", targetObj, lang) => {
  return targetObj[proxyType]
    ? targetObj[proxyType]?.countries.map((el) => ({
        key: el.id,
        label: el.namesDependingOnLocale[lang],
        code: el.code,
        icon: `/images/icons/flags/${el.code.toLowerCase()}.svg`,
        urlParam: el.urlParam,
        mobileOperators: proxyType === "MOBILE" ? el.mobileOperators : null
      }))
    : [];
};

export const getMobileCountriesOptions = (target, lang) =>
  target.map((el) => ({
    ...el,
    key: el.id,
    label: el.namesDependingOnLocale[lang],
    icon: `/images/icons/flags/${el.code.toLowerCase()}.svg`
  }));

export const getOperatorOptions = (targetObj) =>
  Object.keys(targetObj).map((el) => ({
    ...targetObj[el],
    key: targetObj[el].id,
    label: targetObj[el].name
  }));

export const getOperatorRotations = (operator) => operator?.rotationMinutes ?? [];
export const getRotationsOptions = (rotations, t) =>
  rotations.map((e) => ({
    label:
      e !== 0
        ? t("pages.cabinet.orders.table.everyTimeMinute").replace("{{time}}", e)
        : t("other.byLink"),
    key: e,
    value: e
  }));

export const getPeriodsForMobile = (targetObj, lang) =>
  Object.keys(targetObj).map((el) => ({
    ...targetObj[el],
    label: targetObj[el].namesByLocale[lang]
  }));

// export const getPeriodsForMobile = (targetObj, lang) => {
//   console.log(targetObj)
// }

export const getPeriodsOptions = (proxyType, targetObj, lang) =>
  targetObj[proxyType]
    ? targetObj[proxyType]?.map((el) => ({
        key: el.id,
        label: el.namesByLocale[lang]
      }))
    : [];

// export const getPeriodsOptions = (proxyType, targetObj, lang) => {
//   console.log(targetObj);
// }

export const getGoalsOptions = (proxyForName, targetObj, lang) => {
  return targetObj[proxyForName]?.goals?.map((el) => ({
    key: el.id,
    label: el.namesByLocale[lang],
    tag: el.tag
  }));
};

export const calculatePrice = async (
  finalOrderData,
  fingerprint,
  endFunction,
  setLoading = () => {}
) => {
  setLoading(true);

  let newProxyType;
  switch (finalOrderData.proxyType) {
    case "IPV4":
      newProxyType = "IPv4";
      break;
    case "IPV6":
      newProxyType = "IPv6";
      break;
    case "ISP":
      newProxyType = "ISP";
      break;

    default:
      newProxyType = finalOrderData.proxyType;
      break;
  }

  const finalObj = {
    goalCustomName: finalOrderData.proxyCustomGoal,
    // goalId: finalOrderData.proxyGoal,
    countryId: finalOrderData.proxyCountry,
    currencyId: "",
    quantity: parseInt(finalOrderData.proxyCount),
    proxyTypeId: finalOrderData.proxyTypeId,
    mobileOperatorId: finalOrderData.mobileOperatorId,
    promoCode: finalOrderData.promoCode,
    paymentSystemId: finalOrderData.proxyPayment,
    rentPeriodId: finalOrderData.proxyRentalPeriod,
    modemRotationMin: 0,
    fingerprint: fingerprint || "",
    clientEmail: finalOrderData.proxyMail
  };

  try {
    const res = await ApiService.priceCalculate(finalObj);

    // console.log(res.data);
    endFunction(res.data);
    setLoading(false);
  } catch (e) {
    console.log(e.message);
  }
};

export const makeOrder = async (
  finalOrderData,
  priceObj,
  lang,
  fingerprint,
  errorFunction = () => {},
  errorWithSnack = () => {},
  cfToken = ""
) => {
  if (priceObj) {
    const { priceForOne, priceForOneWithDiscount, totalDiscountInPercent, totalPrice } = priceObj;
    const {
      proxyType,
      proxyTypeId,
      proxyCountry,
      proxyRentalPeriod,
      proxyCount,
      promoCode,
      proxyMail,
      proxyPayment,
      proxyAuth,
      proxyIpAuth,
      proxyCustomGoal,
      proxyProtocol,
      mobileOperatorId,
      currencyId,
      modemRotationMin
    } = finalOrderData;

    // let newProxyType;
    // switch (proxyType) {
    //   case "IPV4":
    //     newProxyType = "IPv4";
    //     break;
    //   case "IPV6":
    //     newProxyType = "IPv6";
    //     break;

    //   default:
    //     newProxyType = proxyType;
    //     break;
    // }

    let newProxyProtocol = "http";
    switch (proxyProtocol) {
      case "http":
        newProxyProtocol = "http";
        break;
      case "socks5":
        newProxyProtocol = "socks";
        break;
      default:
        break;
    }

    const finalObj = {
      totalPrice,
      priceForOne,
      priceForOneWithDiscount,
      totalDiscountInPercent,
      currencyId: currencyId || null,
      // goalId: proxyGoal || null,
      goalCustomName: proxyCustomGoal || null,
      countryId: proxyCountry,
      quantity: parseInt(proxyCount),
      proxyTypeId,
      mobileOperatorId: mobileOperatorId || null,
      modemRotationMin: proxyType !== "MOBILE" ? -1 : modemRotationMin,
      promoCode: promoCode || null,
      paymentSystemId: proxyPayment,
      // paymentSystemId: "6422ff07842732365858ba5d",
      authType: proxyAuth.toUpperCase(),
      proxyProtocol: newProxyProtocol !== null ? newProxyProtocol.toUpperCase() : newProxyProtocol,
      authIp: proxyIpAuth || null,
      rentPeriodId: proxyRentalPeriod,
      clientEmail: proxyMail,
      localeCode: lang,
      captchaToken: null,
      fingerprint: fingerprint || null
    };

    try {
      const res = await ApiService.createOrder(finalObj, cfToken);
      // const res = { status: 400 };

      if (res.status === 400) {
        errorFunction({});
        return null;
      }

      return res.data;
    } catch (error) {
      if (error.response.status === 403) {
        errorWithSnack(403);
      } else if (error.response.status === 401) {
        errorWithSnack(401);
      } else if (error.response.status === 406) {
        errorWithSnack();
      } else if (error.response.status === 409) {
        errorFunction({ resetCaptcha: true });
      } else {
        errorFunction({});
      }
      return null;
    }
  }
};

export const getProxyType = (proxyType) => {
  switch (proxyType) {
    case "ipv4":
      return "IPv4";
    case "ipv6":
      return "IPv6";
    case "isp":
      return "ISP";
    case "mobile-proxy":
      return "MOBILE";
    default:
      return proxyType;
  }
};

export const getProxyTypeUpper = (proxyType) => {
  switch (proxyType) {
    case "IPV4":
      return "IPv4";
    case "IPV6":
      return "IPv6";
    case "ISP":
      return "ISP";
    case "MOBILE":
      return "MOBILE";
    default:
      return proxyType;
  }
};

export const getPromocodeWarning = (promoCodeWarnObj, t) => {
  let message;

  switch (promoCodeWarnObj?.warn) {
    case PROMO_CODE_ERROR.PROMO_CODE_NOT_FOUND:
    case PROMO_CODE_ERROR.PROMO_CODE_RESTRICTION_INVALID:
    case PROMO_CODE_ERROR.PROMO_CODE_USER_NOT_REGISTER:
    case PROMO_CODE_ERROR.PROMO_CODE_USER_INVALID:
      message = t("messages.promoCodeNotExist");
      break;
    case PROMO_CODE_ERROR.PROMO_CODE_NOT_ACTIVE:
    case PROMO_CODE_ERROR.PROMO_CODE_USER_ALREADY_USED:
      message = t("messages.promoCodeNotActive");
      break;
    case PROMO_CODE_ERROR.PROMO_CODE_NO_LONGER_VALID:
      message = t("messages.promoCodeInvalid");
      break;
    case PROMO_CODE_ERROR.PROMO_CODE_CONDITIONS_INVALID:
      message = t("messages.promoCodeConditionInvalid");
      break;
    default:
      break;
  }

  return message;
};
