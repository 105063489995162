import { Box } from "@mui/material";
import { SelectList } from "../../../components/common/select-list/select-list";
// import { Form } from "../../../components/form/form";
import { style } from "./table.style";

export const TableSelect = ({ options, label, onChange, withLeftIcon, selectedIndex }) => (
  <Box sx={style.tableSelect} className="table-select">
    {/* <Form.Select
      options={options}
      label={label}
      onChange={onChange}
      variant="table"
      withLeftIcon={withLeftIcon}
    /> */}
    <SelectList
      label={label}
      options={options}
      onChange={onChange}
      withIcon={withLeftIcon}
      selectedIndex={selectedIndex}
      isTableSelect={true}
    />
  </Box>
);
