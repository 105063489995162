import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { style } from "./checkbox.style";

export const CustomCheckbox = ({ changeHandler, label, checked, error, color }) => {

  const defineStyles = () => {
    const styles = [style.checkBox];

    switch (color) {
      case "default": break;

      case "white":
        styles.push(style.white);
        break;
      default:
        break;
    }

    if (error) styles.push(style.error);

    return styles;
  };

  return (
    <FormControl error={error}>
      <FormControlLabel
        sx={style.label}
        control={
          <Checkbox
            onChange={changeHandler}
            checked={checked}
            disableRipple={true}
            sx={defineStyles()}
            size="medium"
          />
        }
        label={label}
      />
    </FormControl>
  );
};
