import { Suspense } from "react";

import { ThemeProvider } from "@mui/material/styles";
import i18n from "i18next";
import { createRoot, hydrateRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { withSSR } from "react-i18next";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  matchRoutes
} from "react-router-dom";

import { App } from "./App";
import SnackbarProvider from "./components/ui/snackbar/snackbar-provider";
import { getRoutes } from "./routes/routes";
import { ApiService } from "./services";
import { setupStore } from "./store/index";
import { getIsToolsActive } from "./store/tools/tools.selectors";
import { appTheme } from "./theme";
import { LOCALS } from "./utils/constants/locals";
import { getCookie } from "./utils/helpers/cookie.helpers";

import "./assets/styles/globals.scss";

const ExtendedApp = withSSR()(App);
const rootEl = document.getElementById("root");

const getApp = async (initialState) => {
  const store = setupStore(initialState);
  const state = store.getState();
  const isToolsActive = getIsToolsActive(state);

  const pathnameLang = Object.values(LOCALS).includes(window.location.pathname.slice(1, 3))
    ? window.location.pathname.slice(1, 3)
    : null;

  const lang = pathnameLang || getCookie("lang") || i18n.language || "en";

  const routes = createRoutesFromElements(
    getRoutes({
      lang: lang,
      isToolsActive,
      store
    })
  );
  const router = createBrowserRouter(routes);

  let lazyMatches = matchRoutes(
    createRoutesFromElements(getRoutes({ lang: "en", store: null })),
    window.location
  )?.filter((m) => m.route.lazy);

  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        let routeModule = await m.route.lazy();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      })
    );
  }

  return {
    app: (
      <ThemeProvider theme={appTheme}>
        <Provider store={store}>
          <SnackbarProvider>
            <HelmetProvider>
              <Suspense fallback={null}>
                <ExtendedApp
                  initialLanguage={lang}
                  routerProvider={RouterProvider}
                  routerProps={{
                    router
                  }}
                />
              </Suspense>
            </HelmetProvider>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    ),
    locale: lang
  };
};

const optionRender = async (initialState = {}) => {
  const app = await getApp(initialState);
  if (rootEl.hasChildNodes()) {
    return hydrateRoot(rootEl, app.app, {
      identifierPrefix: "react-app-youproxy"
    });
  } else {
    const root = createRoot(rootEl);
    return root.render(app.app);
  }
};

const initStore = async () => {
  try {
    const contentId = document.querySelector(`meta[name="ssr-content-id"]`).content;

    if (contentId) {
      const req = await ApiService.cacheSSR(contentId);

      if (req.data.state) {
        return optionRender(req.data.state);
      }
    }

    return optionRender(window.__PRELOADED_STATE__);
  } catch (e) {
    return optionRender(window.__PRELOADED_STATE__);
  } finally {
    delete window.__PRELOADED_STATE__;
    document.getElementById("preloaded-state")?.remove();
  }
};

initStore();
