import { fluid } from "../../../assets/styles/functions/fluid"
import { appTheme } from "../../../theme"

export const style = {
  toolsCheckEvercookie: {},

  description: {
    marginBottom: '2.8rem',

    '& > p': {
      fontWeight: '400',
      fonSsize: '1.6rem',
      lineHeight: '160%',
      color: 'var(--clr-text-400)',

      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      }
    },

    [appTheme.breakpoints.down('bp576')]: {
      marginBottom: '3rem',
      '& > p': {
        fontSize: "1.4rem"
      }
    }
  },

  panel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    borderRadius: '3rem',
    padding: '3rem',
    boxShadow: '8px 12px 50px rgba(185, 197, 222, 0.2)',
    backgroundColor: 'var(--clr-default-100)',

    text: {
      fontWeight: '600',
      fontSize: fluid(2.4, 2),
      textTransform: 'uppercase',
      color: 'var(--clr-text-500)',

      '& > span': {
        color: 'var(--clr-primary-400)',
      },

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '1.6rem',
      }
    },

    '& > .button': {
      maxWidth: '25rem',
    },

    [appTheme.breakpoints.down('bp767')]: {
      marginBottom: '2rem',
    },

    [appTheme.breakpoints.down('bp576')]: {
      flexDirection: 'column',
      padding: '2rem',
    }
  },

  widgets: {
    display: 'grid',
    gridGap: '3rem',
    gridTemplateColumns: 'repeat(2, 1fr)',

    [appTheme.breakpoints.down('bp767')]: {
      gridGap: '2rem',
      gridTemplateColumns: '1fr',
    }
  },

  seo: {
    paddingTop: fluid(8, 3)
  }
}
