export const ReplaceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M7.27549 16.091C9.16822 16.9257 11.4225 16.8803 13.353 15.7725C16.5603 13.9318 17.6593 9.85518 15.8075 6.66707L15.6103 6.32751M4.19231 13.3328C2.34055 10.1447 3.43947 6.06804 6.64681 4.22739C8.57729 3.11951 10.8316 3.07418 12.7243 3.90884M2.5 13.3984L4.65537 13.9724L5.2329 11.83M14.7671 8.16943L15.3446 6.02699L17.5 6.60106"
      stroke="#517FF5"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
