import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Table } from "../../../../components/ui/table/table";
import { useLang, useTranslateOptions } from "../../../../hooks";
import { useSelection } from "../../../../hooks/use-selection";
import { OrdersService } from "../../../../services";
import { getProxyState } from "../../../../store/selectors";
import { getDate } from "../../../../utils/helpers";
import { useTableData } from "../../../../utils/hooks/useTableData";
import { useTableNavigation } from "../../../../utils/hooks/useTableNavigation";
import { OrdersPanel } from "../orders-panel";
import { allOption } from "../orders.constants";
import { getCountriesWithLocale, getSortDirection, selectAllHandle } from "../orders.helpers";
import { useSortHandle } from "../orders.hooks";
import { style } from "../orders.style";

export const Isp = ({ withoutPanel, onChangeSelected, shouldUpdate, onUpdateParentComponent }) => {
  const lang = useLang();

  // Translations
  const { t } = useTranslation();

  //Redux
  const { proxyTypeIds } = useSelector(getProxyState);

  //select state
  const [countryIndex, setCountryIndex] = useState(0);

  //custom hooks
  const [sort, sortHandler, filter, filterHandler] = useSortHandle();
  const [pageObj, pageChangeHandler, sizeSelectHandler] = useTableNavigation();
  const [tableData, tableLoading, ordersData] = useTableData({
    proxyTypeId: proxyTypeIds.find((el) => el.name === "ISP")?.id,
    pageObj,
    sort,
    filter,
    asyncFunction: OrdersService.getOrdersByProxyType,
    isOrdersPage: true,
    shouldUpdate
  });
  const { selected, selectedLength, handleSelect, handleSelectAll, resetSelected } = useSelection(
    tableData?.content || [],
    tableData?.totalElements || 0
  );
  const countriesOptions = [
    ...useTranslateOptions([...allOption], t("countries")),
    ...getCountriesWithLocale(ordersData, lang)
  ];
  const isTableDisabled = !tableData?.content?.length;

  // handlers
  const countryChangeHandler = (option) => {
    setCountryIndex(countriesOptions.indexOf(option));
    filterHandler(option.key);
  };

  useEffect(() => {
    resetSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  useEffect(() => {
    onChangeSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Box className="cabinet-orders__content">
      <Box sx={style.table} className="cabinet-orders__table">
        <Table
          pagination={!!tableData?.totalElements}
          totalPages={tableData?.totalPages ? tableData?.totalPages - 1 : 0}
          totalElements={tableData?.totalElements || 0}
          elementsOnPage={tableData?.content?.length}
          currentPage={pageObj.page}
          onPageChange={pageChangeHandler}
          pageSize={pageObj.pageSize}
          outputCountHandler={sizeSelectHandler}
          loading={tableLoading}
          head={
            <Table.Head blocked={tableLoading}>
              {!isTableDisabled ? (
                <Table.Row>
                  <Table.Cell width={60} tag="th" type="checkbox">
                    <Table.Checkbox
                      onChange={() =>
                        selectAllHandle(
                          tableData,
                          ordersData,
                          selected.map((item) => item.id),
                          handleSelectAll
                        )
                      }
                      checked={
                        selectedLength === tableData?.totalElements &&
                        tableData?.content?.length !== 0
                      }
                      color="white"
                    />
                  </Table.Cell>
                  <Table.Cell tag="th" width="17rem">
                    <Table.Select
                      options={countriesOptions}
                      onChange={(option) => countryChangeHandler(option, "country")}
                      selectedIndex={countryIndex}
                      withLeftIcon
                    />
                  </Table.Cell>
                  <Table.Cell tag="th" width="13rem">
                    {t("pages.cabinet.orders.table.ip")}
                  </Table.Cell>
                  <Table.Cell tag="th" width="15rem">
                    <Table.Sort
                      onClick={() => sortHandler("dateStart")}
                      direction={getSortDirection(sort, "dateStart")}
                    >
                      {t("pages.cabinet.orders.table.activeFrom")}
                    </Table.Sort>
                  </Table.Cell>
                  <Table.Cell tag="th" width="17rem">
                    <Table.Sort
                      onClick={() => sortHandler("dateEnd")}
                      direction={getSortDirection(sort, "dateEnd")}
                    >
                      {t("pages.cabinet.orders.table.activeBy")}
                    </Table.Sort>
                  </Table.Cell>
                  {/* status */}
                  <Table.Cell tag="th" width="15.8rem"></Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Head>
          }
        >
          <Table.Body>
            {tableData?.content && tableData?.content?.length ? (
              tableData.content.map((row, idx) => {
                let collapsedObj;
                if (row.authType === "LOGIN") {
                  collapsedObj = {
                    Login: row.authLogin,
                    SOCKS5: row.portSocks,
                    Password: row.authPassword,
                    "HTTP/S": row.proxyPort
                  };
                } else if (row.authType === "IP") {
                  collapsedObj = {
                    SOCKS5: row.portSocks,
                    IP: row.authIp,
                    "HTTP/S": row.proxyPort
                  };
                }
                return (
                  <Table.Row
                    key={row.id}
                    ipId={row.id}
                    index={idx}
                    hasComment={!!row.comment}
                    rowsLength={tableData?.content?.length}
                    collapsed={collapsedObj}
                    withActions
                    copyText={row.ip}
                    autoExtend={row.autoExtend}
                    proxyType="ISP"
                    onUpdateParentComponent={onUpdateParentComponent}
                    autoExtendPeriod={row.rentPeriodNamesByLocaleExtend?.en}
                  >
                    <Table.Cell type="checkbox" width={60}>
                      <Table.Checkbox
                        onChange={(value) => handleSelect(row.id, row.autoExtend, value)}
                        checked={selected?.some((item) => item.id === row.id)}
                      />
                    </Table.Cell>
                    <Table.Cell width="17rem">{row.countryNamesByLocale[lang]}</Table.Cell>
                    <Table.Cell width="13rem">{row.ip}</Table.Cell>
                    <Table.Cell width="15rem">{getDate(row.dateStart)}</Table.Cell>
                    <Table.Cell width="17rem">{getDate(row.dateEnd)}</Table.Cell>
                    {/* status */}
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row customStyles={style.noData}>
                <Table.Cell>{t("cabinet.orders.noData")}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Box>

      {!withoutPanel && !isTableDisabled && (
        <OrdersPanel
          selected={selected.map(({ id }) => id)}
          totalElements={tableData?.totalElements}
          selectAllHandle={() => selectAllHandle(tableData, ordersData, selected, handleSelectAll)}
          selectedLength={selectedLength}
          rowsLength={tableData?.content?.length || 0}
          proxyType="ISP"
          allOrders={ordersData?.ipAddressesIds}
          setSelectedIp={handleSelectAll}
        />
      )}
    </Box>
  );
};
