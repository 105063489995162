export const ApiIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6963 0.432921L8.76707 17.8963L7.30366 17.5671L11.2329 0.103653L12.6963 0.432921ZM6.56064 4.56214L2.06731 8.99999L6.56064 13.4379L5.50659 14.5051L0.472974 9.53361C0.330302 9.3927 0.25 9.20052 0.25 8.99999C0.25 8.79947 0.330302 8.60729 0.472974 8.46638L5.50659 3.49491L6.56064 4.56214ZM14.4934 3.49491L19.527 8.46638C19.6697 8.60729 19.75 8.79947 19.75 8.99999C19.75 9.20052 19.6697 9.3927 19.527 9.53361L14.4934 14.5051L13.4394 13.4379L17.9327 8.99999L13.4394 4.56214L14.4934 3.49491Z"
      fill="inherit"
    />
  </svg>
);
