import { useState } from "react";

import { CopyToClipboard as Copy } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { Toast } from "../toast/toast";

export const CopyToClipboard = ({ position, text, children }) => {
  const [copied, setCopied] = useState({});

  const { t } = useTranslation();

  const handleCopy = (_, result) => {
    if (result) {
      setCopied({
        status: true,
        type: "success",
        message: t("copyMessageSuccess")
      });
    } else {
      setCopied({
        status: true,
        type: "error",
        message: t("copyMessageError")
      });
    }
  };

  return (
    <Toast
      open={copied.status}
      type={copied.type}
      message={copied.message}
      setOpen={setCopied}
      position={position}
      width="24rem"
    >
      <Copy text={text} onCopy={handleCopy}>
        {children}
      </Copy>
    </Toast>
  );
};
