import { $api } from "./axios.service";

export class SubscriptionService {
  static getSubscriptions(creds) {
    return $api.post("/api/front/cabinet/subscription", creds);
  }

  static subscribe(creds) {
    return $api.put("/api/front/cabinet/subscription", creds);
  }
}