import { setCaptchaTokenToRequestHeaders } from "../utils/helpers";

import { $api } from "./axios.service";

export class ApiService {
  static getPricesByProxyTypeId(proxyTypeId) {
    return $api.post("/api/front/content/price", {}, { params: { proxyTypeId } });
  }
  static getContent(values) {
    return $api.post("/api/front/content", values);
  }

  static getMobileContent(values) {
    return $api.post("/api/front/content/mobile", values);
  }

  static getPaymentOptions(values) {
    return $api.post("/api/front/paymentSystem/all", values);
  }

  static getProxyTypeIds() {
    return $api.get("/api/front/proxyType/all");
  }

  static getReviewsByPage(values) {
    return $api.post("/api/front/review/all/localeCode", values);
  }

  static cacheSSR(id) {
    return $api.get(`/ssr/cache/${id}`);
  }

  static priceCalculateProvisional(values, options) {
    return $api.post("/api/front/priceCalculate/provisional", values, options);
  }

  static priceCalculate(values, options) {
    return $api.post("/api/front/priceCalculate", values, options);
  }

  static createOrder(values, cfToken) {
    return $api.post("/api/front/order/create", values, {
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static getPageSeoV2(values) {
    return $api.post("/api/front/page/seo/V2", values);
  }
  static getPageSeo(values) {
    return $api.post("/api/front/page/seo", values);
  }
  static getReplacedText(values) {
    return $api.post("/api/front/page/text", values);
  }

  static getSeoDefault() {
    return $api.get("/api/front/settings/seo/default");
  }

  static getSeoContent() {
    return $api.get("/api/front/content/seo");
  }

  static blockedPaymentSystemHistory(values) {
    return $api.post("/api/front/blockedPaymentSystemHistory", values);
  }

  static getCountriesPricesByProxyTypeId(proxyTypeId) {
    return $api.get(`/api/front/content/package/country/V2`, { params: { proxyTypeId } });
  }

  static getAllPackageProxyQuantity({ proxyTypeId, countryId }) {
    return $api.post(`/api/front/packageProxy/quantity`, { proxyTypeId, countryId });
  }

  static getDiscountOffer({ proxyTypeId, quantity }) {
    return $api.post(`/api/front/content/correction`, { proxyTypeId, quantity });
  }
}
