import { lazy, Suspense } from "react";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Animated from "../components/special/animated";
import { AuthorizationCheck } from "../components/special";

const Footer = lazy(() => import("../components/common/footer/footer"));
const AppLayout = () => {
  const location = useLocation();

  // console.count("AppLayout");

  return (
    <>
      <AuthorizationCheck/>
      <Box sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%"
      }}>

        <Animated
          name={location.pathname}
          component={"main"}
        >
          <Outlet/>
        </Animated>
        <Suspense fallback={<></>}>
          <Footer />
        </Suspense>
      </Box>
    </>
  );
};

export default AppLayout;
