export const DisableAutoExtendIcon = () => (
  <svg width="128" height="87" viewBox="0 0 128 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.568 33.2603C119.568 46.9066 108.436 57.9692 94.7038 57.9692C80.9718 57.9692 69.8398 46.9066 69.8398 33.2603C69.8398 19.6139 80.9718 8.55139 94.7038 8.55139C108.436 8.55139 119.568 19.6139 119.568 33.2603ZM94.7038 34.6701L84.3625 44.9469L82.9439 43.5371L93.2852 33.2603L82.9439 22.9835L84.3625 21.5737L94.7038 31.8505L105.045 21.5737L106.464 22.9835L96.1225 33.2603L106.464 43.5371L105.045 44.9469L94.7038 34.6701Z"
      fill="url(#paint0_linear_1193_20513)"
    />
    <g filter="url(#filter0_bi_1193_20513)">
      <path
        d="M6 51.4445C6 37.8828 17.0349 26.8889 30.6472 26.8889H72.6926C86.3049 26.8889 97.3398 37.8828 97.3398 51.4445C97.3398 65.0061 86.3049 76 72.6926 76H30.6472C17.0349 76 6 65.0061 6 51.4445Z"
        fill="#517FF5"
        fillOpacity="0.27"
      />
    </g>
    <g filter="url(#filter1_b_1193_20513)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6926 27.3222H30.6472C17.2752 27.3222 6.43495 38.1221 6.43495 51.4445C6.43495 64.7668 17.2752 75.5667 30.6472 75.5667H72.6926C86.0646 75.5667 96.9049 64.7668 96.9049 51.4445C96.9049 38.1221 86.0646 27.3222 72.6926 27.3222ZM30.6472 26.8889C17.0349 26.8889 6 37.8828 6 51.4445C6 65.0061 17.0349 76 30.6472 76H72.6926C86.3049 76 97.3398 65.0061 97.3398 51.4445C97.3398 37.8828 86.3049 26.8889 72.6926 26.8889H30.6472Z"
        fill="url(#paint1_linear_1193_20513)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6926 27.3222H30.6472C17.2752 27.3222 6.43495 38.1221 6.43495 51.4445C6.43495 64.7668 17.2752 75.5667 30.6472 75.5667H72.6926C86.0646 75.5667 96.9049 64.7668 96.9049 51.4445C96.9049 38.1221 86.0646 27.3222 72.6926 27.3222ZM30.6472 26.8889C17.0349 26.8889 6 37.8828 6 51.4445C6 65.0061 17.0349 76 30.6472 76H72.6926C86.3049 76 97.3398 65.0061 97.3398 51.4445C97.3398 37.8828 86.3049 26.8889 72.6926 26.8889H30.6472Z"
        fill="url(#paint2_radial_1193_20513)"
      />
    </g>
    <g filter="url(#filter2_d_1193_20513)">
      <ellipse cx="31.1909" cy="51.6249" rx="20.479" ry="20.4028" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_bi_1193_20513"
        x="-24"
        y="-3.11108"
        width="151.34"
        height="109.111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1193_20513" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1193_20513"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8.9" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.73 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1193_20513" />
      </filter>
      <filter
        id="filter1_b_1193_20513"
        x="-24"
        y="-3.11108"
        width="151.34"
        height="109.111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1193_20513" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1193_20513"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1193_20513"
        x="0.711914"
        y="25.2222"
        width="60.958"
        height="60.8055"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1193_20513" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1193_20513"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1193_20513"
        x1="69.8398"
        y1="8.55139"
        x2="127.497"
        y2="16.5593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#517FF5" />
        <stop offset="1" stopColor="#4BA7EA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1193_20513"
        x1="19.1448"
        y1="25.1349"
        x2="71.8258"
        y2="82.3415"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.188941" stopColor="#E7F0FF" stopOpacity="0.523483" />
        <stop offset="0.526042" stopColor="#88A4FF" />
        <stop offset="1" stopColor="#B4D2FF" stopOpacity="0.1" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_1193_20513"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.6504 74.3922) rotate(-33.5617) scale(54.2 83.4625)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
