import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useLang } from "../../../hooks";
import {
  COMPANY_ROUTES,
  MAIN_ADDRESS,
  MAIN_CONTACTS,
  MAIN_SOCIALS,
  PAYMENTS
} from "../../../utils/constants";
import { getCompanyMenuLink } from "../../../utils/helpers";
import { Container } from "../container/container";
import DynamicIcon from "../dynamic-icon/dynamic-icon";
import Languages from "../languages/languages";

import { style } from "./footer.style";
import MenuList from "./menu-list/menu-list";
import PaymentSystems from "./payment-systems/payment-systems";

const Footer = () => {
  const lang = useLang();
  const { t } = useTranslation();

  return (
    <Box sx={style.footer} className="footer">
      <Container>
        <Box sx={style.top} className="footer__top">
          <Box component="nav" sx={style.menu}>
            {COMPANY_ROUTES.map((item) => (
              <MenuList
                key={item.translation}
                title={t(`company.titles.${item.translation}`)}
                elements={item.routes.map((route) => ({
                  link: getCompanyMenuLink(route, lang),
                  content: t(`company.links.${route.translation}`)
                }))}
              />
            ))}
          </Box>
          <Box sx={style.contacts}>
            <MenuList
              title={t("company.links.contacts")}
              elements={MAIN_CONTACTS.map((e) => ({
                link: e.link,
                icon: <DynamicIcon iconName={e.icon} />,
                content: t(`contacts.${e.translation}`)
              }))}
            />

            <MenuList
              title={"&nbsp;"}
              elements={MAIN_SOCIALS.map((e) => ({
                link: e.link,
                icon: <DynamicIcon iconName={e.icon} />,
                content: t(`contacts.${e.translation}`)
              }))}
            />
          </Box>
          <Languages id={"languages-footer"} />
        </Box>
        <Box sx={style.bottom} className="footer__bottom">
          <Box sx={style.copyright} className="footer__copyright">
            youproxy © {new Date().getFullYear()} . All rights are reserved. {MAIN_ADDRESS}
          </Box>
          <PaymentSystems
            elements={Object.entries(PAYMENTS.paths).map(([key, src]) => ({ key, src }))}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
