import { appTheme } from '../../../../theme'
import { fluid } from '../../../../assets/styles/functions/fluid'

export const style = {
  reviewsHero: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),

    '.reviews-list + .reviews-hero__buttons, .reviews-list + .loader_type_app': {
      marginTop: '2.5rem',
    },

    '.reviews-list': {
      textAlign: 'left',
    },

    '.reviews-list + .reviews-hero__btn': {
      marginTop: '4.5rem',
    },

    '.reviews-hero__btn': {
      textAlign: 'center',
    },

    '.loader_type_app': {
      minHeight: 'initial',
    }
  },

  wrapper: {
    textAlign: 'center',

    '& > .heading': {
      marginBottom: '5rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '4rem',
        textAlign: 'left',
      }
    }
  },

  buttons: {
    [appTheme.breakpoints.down('bp576')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& > button': {
        flex: '0 1 50%',
        fontWeight: '600',

        '&:not(:last-child)': {
          marginRight: '1rem',
        }
      }
    }
  },

  noData: {
    fontSize: '24px'
  }
}