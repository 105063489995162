export const NOT_INDEXED_PROXY_TYPES = ["IPV6", "ISP"]

export const PROXY_FOR_OPTIONS = [
  { key: "socialNetwork", translation: "socialNetwork" },
  { key: "web-scraping", translation: "webScraping" },
  { key: "onlineGames", translation: "onlineGames" },
  { key: "sneaker", translation: "sneaker" },
  { key: "online-marketplace", translation: "onlineMarketplace" },
  { key: "other", translation: "other" },
  { key: "searchEngineParsing", translation: "searchEngineParsing" }
];

export const PROXY_EXPORT_OPTIONS = [
  // txt
  { key: "txt0", translation: "txt", rowFormat: "authentication:ip:port_https", ipv6Protocol: "HTTP" },
  { key: "txt1", translation: "txt", rowFormat: "ip:port_https:authentication", ipv6Protocol: "HTTP" },
  { key: "txt2", translation: "txt", rowFormat: "authentication:ip:port_socks5", ipv6Protocol: "SOCKS" },
  { key: "txt3", translation: "txt", rowFormat: "ip:port_socks5:authentication", ipv6Protocol: "SOCKS" },
  // excel
  { key: "excel0", translation: "excel", rowFormat: "authentication:ip:port_https", ipv6Protocol: "HTTP" },
  { key: "excel1", translation: "excel", rowFormat: "ip:port_https:authentication", ipv6Protocol: "HTTP" },
  { key: "excel2", translation: "excel", rowFormat: "authentication:ip:port_socks5", ipv6Protocol: "SOCKS" },
  { key: "excel3", translation: "excel", rowFormat: "ip:port_socks5:authentication", ipv6Protocol: "SOCKS" },
  // window
  { key: "window0", translation: "window", rowFormat: "authentication:ip:port_https", ipv6Protocol: "HTTP" },
  { key: "window1", translation: "window", rowFormat: "ip:port_https:authentication", ipv6Protocol: "HTTP" },
  { key: "window2", translation: "window", rowFormat: "authentication:ip:port_socks5", ipv6Protocol: "SOCKS" },
  { key: "window3", translation: "window", rowFormat: "ip:port_socks5:authentication", ipv6Protocol: "SOCKS" },
  // custom
  { key: "ownFormat", translation: "ownFormat", rowFormat: null, ipv6Protocol: null }
];
export const PROXY_SOCIALS = {
  paths: {
    vk: "/images/icons/socials/vk.svg",
    classmates: "/images/icons/socials/classmates.svg",
    telegram: "/images/icons/socials/telegram.svg",
    linkedin: "/images/icons/socials/linkedin.svg",
    facebook: "/images/icons/socials/facebook.svg",
    instagram: "/images/icons/socials/instagram.svg",
    twitter: "/images/icons/socials/twitter.svg",
    youtube: "/images/icons/socials/youtube.svg",
    periscope: "/images/icons/socials/periscope.svg"
  },
  defaultPath: "/images/icons/socials/default.svg"
};
