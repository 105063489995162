import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProductMetaDate from "../../../../components/common/product-meta-date/product-meta-date";
import { useLang } from "../../../../hooks";
import useCurrentSeo from "../../../../hooks/use-current-seo";
import { getProxyState } from "../../../../store/proxy/proxy.selectors";

const ProxyProductMeta = (props) => {
  const { proxyType, countrySlug } = props;

  const { proxies } = useSelector(getProxyState);
  const lang = useLang();

  const { t } = useTranslation();
  const seo = useCurrentSeo();

  const proxy = proxies[proxyType];

  const country =
    proxies[proxyType]?.countries?.find((country) => country.urlParam === countrySlug) ??
    proxies[proxyType]?.countries[0];

  const name = seo["h1"]
    ? seo["h1"]
    : `${t("pages.app.buyProxy.hero.heading")} ${proxy?.proxyTypeName}`;
  const description = seo["h2Text"]
    ? seo["h2Text"]
    : t("pages.app.buyProxy.hero.description").replace("{{countryName}}", proxy?.proxyTypeName);

  return (
    <>
      <ProductMetaDate
        name={`${name}`}
        description={description}
        url={`/${lang}/${proxyType.toLowerCase()}/`}
        price={country?.priceForOne}
        // currency={country?.priceCurrencyCode}
      />
    </>
  );
};

export default ProxyProductMeta;
