import { Box, Skeleton } from "@mui/material";
import { style } from "./promocodes-skeleton.style";

const PromocodeItem = () => (
  <Skeleton sx={style.item} variant="rounded" className="grid-item" />
);

export const PromocodesSkeleton = ({ isCabinet = false, promocodesCount = 4 }) => (
  <Box sx={style.container}>
    <Box sx={isCabinet ? style.cabinetGrid : style.grid}>
      {Array.from(Array(promocodesCount).keys()).map((el) => <PromocodeItem key={el} />)}
    </Box>

    <Skeleton variant="rounded" sx={isCabinet ? [style.button, { marginLeft: "0" }] : style.button} />
  </Box>
);
