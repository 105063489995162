import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Container } from "../../../../components/common/container/container";
import { SeoBlock } from "../../../../components/common/seo-block/seo-block";

// import "./seo.scss";
// import { useTranslation } from "../../../../hooks";
import { style } from "./seo.style";

export const Seo = () => {
  const { t } = useTranslation();

  return (
    <Box sx={style.mobileProxySeo} className="mobile-proxy-seo">
      <Container>
        <SeoBlock data={t("pages.app.mobileProxy.seo")} />
      </Container>
    </Box>
  );
};
