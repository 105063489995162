import { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

import { useLang } from "../../../../../hooks";
import { getProxyState } from "../../../../../store/selectors";
import { generateProxyLink } from "../../../../../utils/helpers/generate-proxy-link";
import { getCountryFlagIso2 } from "../../../../../utils/helpers/get-country-flag";
import { style } from "../hero.style";

import { TableSkeleton } from "./table-skeleton";

export const Table = () => {
  const lang = useLang();
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const proxyTab = params.get("priceTabs");

  const { proxies } = useSelector(getProxyState);

  const getActiveTab = () => {
    if (proxyTab && proxies[proxyTab.toUpperCase()]) {
      return proxyTab.toUpperCase();
    }

    return Object.keys(proxies)[0];
  };

  const [proxyType, setProxyType] = useState(() => {
    if (!Object.keys(proxies).length) {
      return "";
    }

    return getActiveTab();
  });

  useEffect(() => {
    if (proxyType === "") {
      setProxyType(getActiveTab());
    }

    if (proxyTab && proxyType !== proxyTab) {
      setProxyType(getActiveTab());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyTab, proxies, proxyType]);

  const priceKey = proxyType === "MOBILE" ? "pricesByRentPeriods" : "pricesByQuantityV2";
  const quantityKey = proxyType === "MOBILE" ? "rentPeriodDays" : "quantity";
  const valueKey = proxyType === "MOBILE" ? "value" : "amount";

  // get next year for priceValidUntil param;
  const nextYearValue = useMemo(() => {
    const today = new Date();
    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);
    return nextYear.toISOString().split("T")[0];
  }, []);

  // generate random rating (range 4.5 - 4.9);
  const randomRating = useMemo(() => (Math.random() * 0.4 + 4.5).toFixed(1), []);

  return (
    <>
      {!Object.keys(proxies).length ? (
        <TableSkeleton />
      ) : (
        <Box sx={style.priceTable.inner} className="price-table__inner">
          <Box sx={style.priceTable} className="price-table">
            <Box
              sx={[
                style.priceTable.head,
                {
                  gridTemplateColumns: `minmax(22rem, 4fr) repeat(${proxies[proxyType]?.countries[0][priceKey].length}, minmax(10rem, 3fr))`
                }
              ]}
              className="price-table__head"
            >
              <Box
                sx={[style.priceTable.cell, style.priceTable.cell.leftMod]}
                className="price-table__cell price-table__cell_left"
              >
                {t("pages.app.price.location")}
              </Box>
              {proxies[proxyType]?.countries[0][priceKey].map((el) => {
                const label =
                  proxyType === "MOBILE"
                    ? `${el.namesByLocale[lang]}`
                    : `${el[quantityKey]} ${t("other.pieces")}`;

                return (
                  <Box
                    key={el[quantityKey]}
                    sx={style.priceTable.cell}
                    className="price-table__cell"
                  >
                    {label}
                  </Box>
                );
              })}
            </Box>
            <Box className="price-table__list">
              {proxies[proxyType]?.countries.map((country) => (
                <Box
                  key={country.code}
                  className="price-table__item"
                  itemScope
                  itemType="https://schema.org/Product"
                >
                  <Box
                    component={RouterLink}
                    to={generateProxyLink(lang, proxyType.toLowerCase(), country.urlParam)}
                    className="price-table__link"
                    sx={[
                      style.priceTable.link,
                      {
                        gridTemplateColumns: `minmax(22rem, 4fr) repeat(${country[priceKey].length}, minmax(10rem, 3fr))`
                      }
                    ]}
                  >
                    <Box
                      sx={[style.priceTable.cell, style.priceTable.cell.left]}
                      className="price-table__cell price-table__cell_left"
                    >
                      <Box sx={style.priceTable.proxy} className="price-table__proxy">
                        <Box sx={style.priceTable.proxy.image} className="price-table__proxy-image">
                          <img
                            itemProp="image"
                            src={getCountryFlagIso2(country.code)}
                            width="40"
                            height="40"
                            alt={country.namesDependingOnLocale[lang]}
                            aria-hidden={true}
                            loading="lazy"
                          />
                        </Box>
                        <Box
                          sx={style.priceTable.proxy.text}
                          className="price-table__proxy-text"
                          itemProp="name"
                        >
                          {country.namesDependingOnLocale[lang]}
                        </Box>
                      </Box>
                    </Box>

                    {country[priceKey].map((item) => (
                      <Box
                        sx={style.priceTable.cell}
                        className="price-table__cell"
                        key={item[quantityKey]}
                      >
                        <Box sx={style.priceTable.col} className="price-table__col">
                          <Box
                            sx={style.priceTable.col.caption}
                            className="price-table__col-caption"
                          >
                            $
                          </Box>
                          <Box sx={style.priceTable.col.text} className="price-table__col-text">
                            {item[valueKey]}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  <span itemProp="offers" itemScope itemType="https://schema.org/Offer">
                    <meta itemProp="price" content={country[priceKey][0][valueKey]} />
                    <meta itemProp="priceCurrency" content="USD" />
                    <meta itemProp="priceValidUntil" content={nextYearValue} />
                    <meta itemProp="availability" content="https://schema.org/InStock" />
                    <meta
                      itemProp="url"
                      content={`https://youproxy.io${generateProxyLink(
                        lang,
                        proxyType.toLowerCase(),
                        country.urlParam
                      )}`}
                    />
                  </span>
                  <div
                    itemScope
                    itemType="https://schema.org/AggregateRating"
                    itemProp="aggregateRating"
                  >
                    <meta itemProp="ratingValue" content={randomRating} />
                    <meta itemProp="bestRating" content="5" />
                    <meta itemProp="ratingCount" content={proxies[proxyType]?.countries.length} />
                    <meta itemProp="itemReviewed" content={country.urlParam.split("-").join(" ")} />
                  </div>
                  <meta itemProp="description" content={country.urlParam.split("-").join(" ")} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
