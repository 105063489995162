import { CopyToClipboard } from "react-copy-to-clipboard";

import "./copy.scss";
import { Skeleton } from "@mui/material";

export const Copy = ({ data, copyDisabled, isLoading }) => (
  <div className="widget-copy">
    <div className="widget-copy__icon">
      <img src={data.image} width={24} height={24} alt={data.text} loading="lazy" />
    </div>
    <div className="widget-copy__content">
      <div className="widget-copy__title">{data.title}</div>
      {
        copyDisabled
        ? (<div className="widget-copy__text">{isLoading ? <Skeleton /> : data.text}</div>)
        : (<CopyToClipboard text={data.text}>
            <button className="widget-copy__text">{isLoading ? <Skeleton /> : data.text}</button>
        </CopyToClipboard>)
      }
    </div>
  </div>
);
