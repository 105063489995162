import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { BlogCard } from "../../../components/common/blog-card/blog-card";
import { Container } from "../../../components/common/container/container";
import HeadingWithSeo from "../../../components/common/heading-with-seo/heading-with-seo";
import { Pagination } from "../../../components/ui/pagination/pagination";
import { BlogSkeleton } from "../../../components/ui/skeletons/blog-skeleton/blog-skeleton";
import { useActions, useLang, useSetSeoPage } from "../../../hooks";
import { getArticlesByPage } from "../../../store/blog/blog.thunks";
import { getBlogState } from "../../../store/selectors";

import { style } from "./blog.style";

const Blog = () => {
  useSetSeoPage();

  const lang = useLang();

  // Redux State
  const { articlesPage, articlesLocaleCode, totalPages, articles, isLoading, totalElements } =
    useSelector(getBlogState);

  // Dispatch
  const { getArticlesByPage } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  // Translations
  const { t } = useTranslation();

  const pageSize = 9;

  useEffect(() => {
    if (lang !== articlesLocaleCode) {
      setCurrentPage(1);
      getArticlesByPage({ page: 0, size: pageSize, localeCode: lang });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, articlesLocaleCode]);

  useEffect(() => {
    if (currentPage !== articlesPage) {
      getArticlesByPage({ page: currentPage - 1, size: pageSize, localeCode: lang });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, articlesPage]);

  const handlerSetPage = (value) => {
    setCurrentPage(value + 1);
  };

  return (
    <Box sx={style.blog} className="blog">
      <Container>
        <Box sx={style.wrapper} className="blog__wrapper">
          <HeadingWithSeo seoHeading="h1" tag="h1" variant="h2">
            {t("pages.app.blog.heading")}
          </HeadingWithSeo>
          {isLoading ? (
            <BlogSkeleton articleCount={articles?.length || 9} />
          ) : (
            <>
              {articles?.length ? (
                <>
                  <Box component="ul" sx={style.list} className="blog__list">
                    {articles?.map((article) => (
                      <BlogCard key={article.id} data={article} />
                    ))}
                  </Box>

                  {totalPages !== 1 && (
                    <Pagination
                      pageSize={pageSize}
                      totalCount={totalElements}
                      siblingCount={1}
                      totalPages={totalPages - 1}
                      currentPage={currentPage - 1}
                      setCurrentPage={handlerSetPage}
                    />
                  )}
                </>
              ) : (
                <Box sx={style.noData}>{t("pages.app.blog.noData")}</Box>
              )}
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

Blog.getServerSideState = async (store, params) => {
  const lang = params.locale;
  const pageSize = 9;

  const currentPage = 1;
  await store.dispatch(
    getArticlesByPage({ page: currentPage - 1, size: pageSize, localeCode: lang })
  );
};
export default Blog;
