export const style = {
  slider: {
    ".reviews-slider": {
      overflow: "visible",

      ".swiper-slide-active > .reviews-card": {
        borderColor: "transparent",
        boxShadow: "0 15px 25px rgba(179 184 202 / 23%)"
      },

      ".swiper-slide": {
        height: "auto"
      }
    },

    "& + .proxy-reviews__link, & + .home-reviews__link": {
      marginTop: "4rem"
    }
  }
};
