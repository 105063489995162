export const HOME_DECOR_POINTS = [
  {
    d: "M189.203 260.894c0 10.429 7.243 20.279 13.519 20.279 5.312 0 12.554-9.657 12.554-20.279 0-7.242-5.794-12.554-11.105-12.554h-3.38c-5.311 0-11.588 5.312-11.588 12.554Z",
    fill: "url(#x)"
  },
  {
    d: "M136.094 317.869c0 10.429 7.242 20.279 13.519 20.279 5.311 0 12.554-9.657 12.554-20.279 0-7.243-5.794-12.554-11.105-12.554h-3.38c-5.311 0-11.588 5.311-11.588 12.554Z",
    fill: "url(#y)"
  },
  {
    d: "M157.336 476.919c0 8.589 5.901 16.701 11.016 16.701 4.327 0 10.229-7.953 10.229-16.701 0-5.964-4.721-10.338-9.049-10.338h-2.754c-4.328 0-9.442 4.374-9.442 10.338Z",
    fill: "url(#z)"
  },
  {
    d: "M310.875 230.448c0 9.202 6.438 17.893 12.017 17.893 4.721 0 11.159-8.521 11.159-17.893 0-6.391-5.15-11.077-9.871-11.077h-3.005c-4.721 0-10.3 4.686-10.3 11.077Z",
    fill: "url(#A)"
  },
  {
    d: "M414.203 258.509c0 11.656 8.047 22.665 15.022 22.665 5.901 0 13.948-10.793 13.948-22.665 0-8.095-6.438-14.031-12.339-14.031h-3.755c-5.902 0-12.876 5.936-12.876 14.031Z",
    fill: "url(#B)"
  },
  {
    d: "M379.438 471.552c0 11.349 7.778 22.068 14.52 22.068 5.705 0 13.484-10.509 13.484-22.068 0-7.882-6.223-13.662-11.928-13.662h-3.63c-5.705 0-12.446 5.78-12.446 13.662Z",
    fill: "url(#C)"
  },
  {
    d: "M211.414 208.635c0 5.521 4.024 10.736 7.511 10.736 2.95 0 6.974-5.113 6.974-10.736 0-3.835-3.219-6.646-6.169-6.646h-1.878c-2.951 0-6.438 2.811-6.438 6.646Z",
    fill: "url(#D)"
  },
  {
    d: "M118.711 277.197c0 5.522 4.024 10.736 7.511 10.736 2.95 0 6.974-5.112 6.974-10.736 0-3.834-3.219-6.646-6.17-6.646h-1.877c-2.951 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#E)"
  },
  {
    d: "M124.5 409.493c0 5.522 4.024 10.736 7.511 10.736 2.95 0 6.974-5.112 6.974-10.736 0-3.834-3.219-6.646-6.17-6.646h-1.877c-2.951 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#F)"
  },
  {
    d: "M180.516 421.082c0 5.521 4.023 10.736 7.51 10.736 2.951 0 6.975-5.113 6.975-10.736 0-3.834-3.219-6.646-6.17-6.646h-1.878c-2.95 0-6.437 2.812-6.437 6.646Z",
    fill: "url(#G)"
  },
  {
    d: "M224.938 463.571c0 5.521 4.023 10.736 7.51 10.736 2.951 0 6.975-5.112 6.975-10.736 0-3.834-3.219-6.646-6.17-6.646h-1.878c-2.95 0-6.437 2.812-6.437 6.646Z",
    fill: "url(#H)"
  },
  {
    d: "M187.273 556.275c0 5.521 4.024 10.736 7.511 10.736 2.951 0 6.974-5.112 6.974-10.736 0-3.834-3.218-6.646-6.169-6.646h-1.878c-2.95 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#I)"
  },
  {
    d: "M83.945 353.257c0 6.135 4.56 11.929 8.513 11.929 3.344 0 7.904-5.68 7.904-11.929 0-4.26-3.648-7.384-6.992-7.384H91.24c-3.344 0-7.296 3.124-7.296 7.384Z",
    fill: "url(#J)"
  },
  {
    d: "M265.492 215.394c0 5.521 4.024 10.736 7.511 10.736 2.951 0 6.974-5.112 6.974-10.736 0-3.834-3.219-6.646-6.169-6.646h-1.878c-2.951 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#K)"
  },
  {
    d: "M364.953 235.446c0 6.135 4.56 11.929 8.512 11.929 3.344 0 7.904-5.681 7.904-11.929 0-4.26-3.648-7.384-6.992-7.384h-2.128c-3.344 0-7.296 3.124-7.296 7.384Z",
    fill: "url(#L)"
  },
  {
    d: "M307.016 364.391c0 7.362 5.364 14.315 10.014 14.315 3.934 0 9.299-6.816 9.299-14.315 0-5.112-4.292-8.861-8.226-8.861h-2.504c-3.934 0-8.583 3.749-8.583 8.861Z",
    fill: "url(#M)"
  },
  {
    d: "M212.375 509.468c0 6.749 4.828 13.122 9.013 13.122 3.541 0 8.369-6.248 8.369-13.122 0-4.686-3.863-8.123-7.403-8.123H220.1c-3.54 0-7.725 3.437-7.725 8.123Z",
    fill: "url(#N)"
  },
  {
    d: "M373.648 409.777c0 7.362 5.365 14.315 10.015 14.315 3.934 0 9.299-6.817 9.299-14.315 0-5.112-4.292-8.861-8.226-8.861h-2.504c-3.934 0-8.584 3.749-8.584 8.861Z",
    fill: "url(#O)"
  },
  {
    d: "M429.648 418.099c0 7.055 5.097 13.719 9.514 13.719 3.738 0 8.834-6.533 8.834-13.719 0-4.899-4.077-8.492-7.815-8.492h-2.378c-3.738 0-8.155 3.593-8.155 8.492Z",
    fill: "url(#P)"
  },
  {
    d: "M438.344 319.232c0 6.748 5.096 13.122 9.513 13.122 3.738 0 8.834-6.249 8.834-13.122 0-4.686-4.077-8.123-7.814-8.123h-2.379c-3.737 0-8.154 3.437-8.154 8.123Z",
    fill: "url(#Q)"
  },
  {
    d: "M387.164 297.022c0 6.749 5.097 13.122 9.514 13.122 3.737 0 8.834-6.248 8.834-13.122 0-4.686-4.078-8.123-7.815-8.123h-2.378c-3.738 0-8.155 3.437-8.155 8.123Z",
    fill: "url(#R)"
  },
  {
    d: "M325.359 282.991c0 5.522 4.024 10.736 7.511 10.736 2.951 0 6.974-5.112 6.974-10.736 0-3.834-3.219-6.646-6.169-6.646h-1.878c-2.95 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#S)"
  },
  {
    d: "M395.852 352.519c0 5.521 4.023 10.736 7.51 10.736 2.951 0 6.975-5.113 6.975-10.736 0-3.835-3.219-6.646-6.17-6.646h-1.878c-2.95 0-6.437 2.811-6.437 6.646Z",
    fill: "url(#T)"
  },
  {
    d: "M349.5 343.828c0 5.521 4.024 10.736 7.511 10.736 2.95 0 6.974-5.112 6.974-10.736 0-3.834-3.219-6.646-6.17-6.646h-1.877c-2.951 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#U)"
  },
  {
    d: "M316.672 419.151c0 5.521 4.023 10.736 7.511 10.736 2.95 0 6.974-5.113 6.974-10.736 0-3.835-3.219-6.646-6.17-6.646h-1.877c-2.951 0-6.438 2.811-6.438 6.646Z",
    fill: "url(#V)"
  },
  {
    d: "M388.133 533.098c0 5.522 4.023 10.736 7.511 10.736 2.95 0 6.974-5.112 6.974-10.736 0-3.834-3.219-6.646-6.17-6.646h-1.877c-2.951 0-6.438 2.812-6.438 6.646Z",
    fill: "url(#W)"
  },
  {
    d: "M467.312 348.657c0 5.521 4.024 10.736 7.511 10.736 2.951 0 6.975-5.113 6.975-10.736 0-3.835-3.219-6.647-6.17-6.647h-1.878c-2.95 0-6.438 2.812-6.438 6.647Z",
    fill: "url(#X)"
  }
];
