import { useContext, useRef, useState } from "react";

import { Box, ButtonBase } from "@mui/material";
import { DownIcon } from "../../../components/icons/index";

import { useOnClickOutside } from "../../../hooks/use-on-click-outside";

import { TabsContext } from "./tabs";
import { style } from "./tabs.style";

export const TabsDropdown = ({ tabs }) => {
  const { handleTab, activeTab } = useContext(TabsContext);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const handleOpen = () => setIsOpen((prev) => !prev);

  const handleSelectTab = (key) => {
    setIsOpen(false);
    handleTab(key);
  };

  const currentTab = tabs?.find((tab) => tab.key === activeTab) || tabs?.[0];

  return (
    <Box
      ref={dropdownRef}
      className="tabs__dropdown"
      sx={!isOpen ? style.dropdown : [style.dropdown, style.dropdown.activeMod]}
    >
      <ButtonBase sx={style.dropdown.current} onClick={handleOpen}>
        <Box sx={style.dropdown.current.label}>{currentTab?.label}</Box>
        <Box sx={style.dropdown.current.arrow} className="tabs_dropdowm_current_arrow">
          <DownIcon />
        </Box>
      </ButtonBase>
      <Box sx={style.dropdown.body} className="tabs_dropdown_body">
        <Box sx={style.dropdown.list}>
          {tabs?.map((tab) => (
            <ButtonBase
              key={tab.key}
              // className={
              //   tab.key !== activeTab
              //     ? "tabs__dropdown-item"
              //     : "tabs__dropdown-item tabs__dropdown-item_active"
              // }
              sx={ tab.key !== activeTab ? style.dropdown.item : [style.dropdown.item, style.dropdown.item.active] }
              onClick={() => handleSelectTab(tab.key)}
              aria-label={tab.label}
              title={tab.label}
            >
              {tab.label}
            </ButtonBase>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
