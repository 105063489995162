import { appTheme } from "../../../theme"

export const style = {
  cabinetProfile: {
    display: 'flex',

    [appTheme.breakpoints.down('bp992')]: {
      justifyContent: 'center',
      textAlign: "center"
    }

  },

  wrapper: {
    width: 'min(37rem, 100%)',

    '& > .heading': {
      marginBottom: '4rem',

      [appTheme.breakpoints.down('bp576')]: {
        marginBottom: '3rem',
      }
    }
  },

  form: {
    '.form-item': {
      textAlign: "left",
      '&:not(:first-of-type)': {
        marginTop: '1.7rem',
      }
    },

    '.button': {
      marginTop: '5rem',
    }
  }
}
