import { fluid } from "../../../assets/styles/functions/fluid";

export const style = {
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%"
  },
  container: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(14, 7),
    ".heading": {
      paddingBottom: "2rem"
    }
  }
}
