import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { CustomCheckbox } from "../../../components/common/checkbox/checkbox";
import { Heading } from "../../../components/ui/heading/heading";
import { SubscriptionsSkeleton } from "../../../components/ui/skeletons/subscriptions-skeleton/subscriptions-skeleton";
import { useLang, useSetSeoPage } from "../../../hooks";
import { SubscriptionService } from "../../../services/subscription.service";
import { getUserState } from "../../../store/selectors";

import { SubsciptionActions } from "./subscription-actions";
import { style } from "./subscription.style";

const Subscriptions = () => {
  useSetSeoPage();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const lang = useLang();

  const { token } = useParams();
  const [searchParams] = useSearchParams();

  //*Redux
  const { user } = useSelector(getUserState);

  //local state
  const [loading, setLoading] = useState(false);
  const [subObj, setSubObj] = useState(null);
  const [checkedObj, setCheckedObj] = useState({});
  const [initChecked, setInitChecked] = useState({});
  const [actionsVisible, setActionsVisible] = useState(false);

  useEffect(() => {
    const reqObj = !token
      ? {
          email: user?.email,
          token: ""
        }
      : { email: searchParams.get("email"), token };

    setLoading(true);

    SubscriptionService.getSubscriptions(reqObj)
      .then((res) => {
        let entries = {};
        for (let i = 0; i < res.data.length; i++) {
          entries = {
            ...entries,
            ...res.data[i].notifications.reduce(
              (acc, value) => ({ ...acc, [value.id]: value.signed }),
              {}
            )
          };
        }
        setCheckedObj(entries);
        setInitChecked(entries);
        setSubObj(res.data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handlers
  const checkHandler = (key) => setCheckedObj({ ...checkedObj, [key]: !checkedObj[key] });

  const saveHandler = async () => {
    setLoading(true);

    try {
      const idArr = Object.keys(checkedObj).filter((el) => checkedObj[el]);

      const reqObj = !token
        ? {
            subscriptionIds: idArr,
            email: user?.email,
            token: ""
          }
        : {
            subscriptionIds: idArr,
            email: searchParams.get("email"),
            token: ""
          };

      const res = await SubscriptionService.subscribe(reqObj);
      if (res.status === 200) {
        setInitChecked(checkedObj);
        setActionsVisible(false);
        enqueueSnackbar(t("pages.cabinet.subscription.successMessage"), { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(t("pages.cabinet.subscription.errorMessage"), { variant: "error" });
      // eslint-disable-next-line no-console
      console.log(error.message);
    }

    setLoading(false);
  };

  const cancelHandler = () => {
    setCheckedObj(initChecked);
    setActionsVisible(false);
  };

  //hide/show save and cancel button depends on checked elements (with debounce)
  useEffect(() => {
    const getChecked = setTimeout(() => {
      if (Object.keys(checkedObj).length) {
        if (!(JSON.stringify(checkedObj) === JSON.stringify(initChecked))) setActionsVisible(true);
        else setActionsVisible(false);
      }
    }, 200);

    return () => clearTimeout(getChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedObj]);

  return (
    <Box sx={style.cabinetSubscription} className="cabinet-subscription">
      <Heading tag="h1" variant="h2">
        {t("pages.cabinet.subscription.heading")}
      </Heading>

      {loading ? (
        <SubscriptionsSkeleton />
      ) : (
        <>
          {subObj &&
            subObj.map((el) => (
              <React.Fragment key={el.subscriptionCategoryTag}>
                <Box
                  sx={style.card}
                  className="cabinet-subscription__card"
                  datadecor={el.subscriptionCategoryNames[lang]}
                >
                  <Box>
                    {/* <CustomCheckbox label={el.subscriptionCategoryNames[lang]} changeHandler={(e) => checkHandler(el.tag, e)} /> */}
                    <Box component="h3">{el.subscriptionCategoryNames[lang]}</Box>
                  </Box>
                  <Box
                    component="ul"
                    sx={style.card.list}
                    className="cabinet-subscription__card-list"
                  >
                    {el.notifications.map((el) => (
                      // <Box key={el.id} component="li" sx={style.card.item} className="cabinet-subscription__card-item">
                      //   {el.notificationNames[lang]}
                      // </Box>
                      <CustomCheckbox
                        key={el.id}
                        label={el.subscriptionNamesByLocale[lang]}
                        checked={checkedObj[el.id]}
                        changeHandler={() => checkHandler(el.id)}
                      />
                    ))}
                  </Box>
                </Box>
              </React.Fragment>
            ))}
        </>
      )}

      <SubsciptionActions
        visible={actionsVisible}
        saveHandler={saveHandler}
        cancelHandler={cancelHandler}
      />
    </Box>
  );
};

export default Subscriptions;
