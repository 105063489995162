import React, { useMemo } from "react";
import { parseSeoBlockField } from "../../../utils/helpers";

/**
 *
 * @param {Object} props
 * @param {String | Number | Array | Object} props.defaultValue
 * @param {Object} props.field
 * @param {String<"TEXT" | "JSON">} props.field.type
 * @param {String | JSON} props.field.content
 * @returns {Function}
 */
export const SeoBlockField = (props) => {
  const {defaultValue, field, children} = props;

  const data = useMemo(() => {
    if (field) {
      try {
        return parseSeoBlockField(field)
      } catch (e) {
        console.warn(e);
        return defaultValue
      }
    } else {
      return defaultValue
    }
  }, [field, defaultValue]);

  const renderChildren = () => {
    if (!children) {
      return data
    }

    switch (typeof children) {
      case "function":
        return children(data);
      default:
        return children
    }
  }

  return (
    <>
      {renderChildren()}
    </>
  );
};
