const arIpsWrong = [
  "0.0.0.0/8",
  "10.0.0.0/8",
  "100.64.0.0/10",
  "127.0.0.0/8",
  "169.254.0.0/16",
  "172.16.0.0/12",
  "192.0.0.0/24",
  "192.0.2.0/24",
  "192.88.99.0/24",
  "192.168.0.0/16",
  "198.18.0.0/15",
  "198.51.100.0/24",
  "203.0.113.0/24",
  "224.0.0.0/4",
  "240.0.0.0/4",
  "255.255.255.255/32"
];
const ipToLong = (ip) => {
  return (
    ip.split(".").reduce((acc, octet) => {
      return (acc << 8) + parseInt(octet, 10);
    }, 0) >>> 0
  ); // Use unsigned right shift to ensure we get a positive number
};

const ipInRange = (ip, range) => {
  if (!range.includes("/")) {
    range += "/32";
  }
  // The range is in IP/CIDR format eg 127.0.0.1/24
  const [rangeStart, netmask] = range.split("/");
  const rangeDecimal = ipToLong(rangeStart);
  const ipDecimal = ipToLong(ip);
  const wildcardDecimal = Math.pow(2, 32 - parseInt(netmask, 10)) - 1;
  const netmaskDecimal = ~wildcardDecimal;
  return (ipDecimal & netmaskDecimal) === (rangeDecimal & netmaskDecimal);
};

export const checkIpAgainstRanges = (ip) => {
  return arIpsWrong.some((range) => ipInRange(ip, range));
};
