export const style = {
  radioItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    '.MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#343435',
      opacity: '0.9',
    }
  },

  dot: {
    color: '#D5D7DC',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },

    '&.Mui-checked': {
      color: '#517ff5',
    },
  }
}